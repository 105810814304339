/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* istanbul ignore file */
import { RefObject, CSSProperties, useMemo, useEffect } from "react";
import { useInViewport, useScrollableContainer, useForceRerender } from "@hooks";
import { ScrollableParent, StickyEntry } from "./Sticky.types";

const stickies = new WeakMap<ScrollableParent, StickyEntry[]>();

const getStickies = (container: ScrollableParent): StickyEntry[] => {
  if (!container) {
    return [];
  }

  let entries = stickies.get(container);
  if (!entries) {
    stickies.set(container, (entries = []));
  }

  // We need these to be in order by position, so that the offsets are applied correctly.
  // This is roughly how UX determines the order of elements.
  return entries.sort((a, b) => (a.initialPosition ?? 0) - (b.initialPosition ?? 0));
};

const getTopOffset = (container: ScrollableParent, entry: StickyEntry) => {
  const all = getStickies(container);
  const index = all.indexOf(entry);
  if (index === -1) {
    return 0;
  }

  return (
    all
      // get entries above us
      .slice(0, index)
      .filter(
        // We only care about elements that are within the same parent
        // or have no parent.
        e => entry.parent === (e.parent ?? entry.parent)
      )
      // Get the height of all other sticky components above current element.
      // This will determine how much to offset the component by.
      .reduce((v, e) => v + e.height, 0)
  );
};

const triggerSelfAndOthers = (container: ScrollableParent, entry: StickyEntry) => {
  const entries = getStickies(container);
  const index = entries.indexOf(entry);
  if (index === -1) {
    return;
  }

  // Changes to an entry will only have an impact for those
  // after the element - if we are at the bottom, we don't
  // need to trigger any updates.
  const selfAndBelow = entries.slice(index);
  if (selfAndBelow.length > 1) {
    selfAndBelow.forEach(x => x.trigger());
  }
};

const addStickyIfNew = (container: ScrollableParent | null, entry: StickyEntry) => {
  if (!container) {
    return;
  }

  const entries = stickies.get(container) ?? [];
  if (!entries.includes(entry)) {
    entries.push(entry);
    if (entries.length === 1) {
      stickies.set(container, entries);
    }
  }
};

const removeSticky = (container: ScrollableParent, entry: StickyEntry) => {
  const entries = getStickies(container);
  const index = entries.indexOf(entry);
  if (index === -1) {
    return;
  }

  entries.splice(index, 1);
  if (entries.length === 0) {
    stickies.delete(container);
  }
};

// In test jsdom and lame browsers ResizeObserver will not
// be present - we will do a noop in those cases - no sticky
// headers for you!
const { ResizeObserver, IntersectionObserver } = window;
const hasObservers = !!ResizeObserver && !!IntersectionObserver;

export const useSticky = hasObservers
  ? (ref: RefObject<HTMLElement>, offset = 0): Partial<CSSProperties> => {
      const container = useScrollableContainer(ref);
      const trigger = useForceRerender();

      const entry = useMemo<StickyEntry>(
        () => ({ ref, trigger, height: ref.current?.getBoundingClientRect().height || 0 }),
        [ref, trigger]
      );
      entry.parent = entry.ref.current?.parentElement;

      const top = offset + getTopOffset(container!, entry);
      const visible = useInViewport({ ref, top, container: container! });

      useEffect(() => {
        let pendingUpdate: number;
        const setHeightAndUpdateAffected = (height: number) => {
          if (entry.height !== height) {
            entry.height = height;
            triggerSelfAndOthers(container!, entry);
          }
        };

        const node = ref.current;
        if (node) {
          const rect = node.getBoundingClientRect();
          entry.initialPosition = entry.initialPosition ?? (rect.top || 0);
          setHeightAndUpdateAffected(rect.height);
        } else if (!node || !visible) {
          setHeightAndUpdateAffected(0);
          return undefined;
        }

        const observer = new ResizeObserver(entries => {
          pendingUpdate = window.requestAnimationFrame(() => setHeightAndUpdateAffected(entries[0].contentRect.height));
        });

        observer.observe(node);
        return () => {
          window.cancelAnimationFrame(pendingUpdate);
          observer.disconnect();
        };
      }, [visible, ref, entry, container]);

      useEffect(() => {
        // cleanup
        return () => {
          removeSticky(container!, entry);
        };
      }, [entry, container]);

      addStickyIfNew(container, entry);
      return useMemo(() => ({ position: "sticky", top }), [top]);
    }
  : // noop for tests
    () => ({});
