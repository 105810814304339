import React, { ReactElement, SyntheticEvent, isValidElement } from "react";
import { returnTrue, chainHandlers } from "@utils";
import { useEventCallback } from "@hooks";
import { Comparer } from "../Common.types";
import { Sticky } from "../Sticky";
import {
  SelectionMode,
  IDataColumnDefProps,
  IDataTableDefProps,
  RowInfo,
  ITableCellProps,
  ITableHeaderCellProps,
  RowValueRenderer
} from "./DataTable.types";
import { createComparer } from "./data-sorting";
import {
  DataTableCell,
  DataTableHeaderCell,
  DataTableHeaderSelectionCell,
  DataTableSelectionCell
} from "./DataTableCell";
import { DataTable } from "./DataTable";
import { DataTableHeader } from "./DataTableHeader";
import { DataTableRow, DataTableHeaderRow } from "./DataTableRow";
import { DataTableCaption } from "./DataTableCaption";
import { DataTableBody } from "./DataTableBody";
import * as DataTablePlugins from "./Plugins";

const CLICKABLE_ELEMENTS = /^(?:a|input|select|textarea|button|option)$/i;

type ColumnMeta<T> = {
  props: ITableHeaderCellProps<T>;
  cell: ITableCellProps;
  render: RowValueRenderer<T>;
};

// eslint-disable-next-line func-style
function normalizeColumnProps<T>({
  // Header only props
  id,
  title,
  shortTitle,
  valueSelector,
  sortComparer,
  sortable,

  // Cell only props
  onClick,

  // Searchable only used within picker currently - will be part of
  // DataTable once search is in place
  searchable: _,
  children,
  ...props
}: IDataColumnDefProps<T>): ColumnMeta<T> {
  let comparer: Comparer<T> | undefined;

  if (sortable) {
    const getter = valueSelector || ((row: T) => String(children(row)));
    comparer = sortComparer || createComparer<T>(getter);
  }

  return {
    props: {
      id,
      title,
      shortTitle,
      sortable,
      sortComparer: comparer,
      ...props
    },
    cell: { onClick, ...props },
    render: children
  };
}

/** Dummy component used to provider configuration to DataTableRef component. */
// eslint-disable-next-line func-style
export function DataColumnDef<T>(_: IDataColumnDefProps<T>): ReactElement | null {
  // this component is only used to read props and configure the grid
  return null;
}

/**
 * Component which uses the provided props as configuration to render a
 * DataTable component.
 */
// eslint-disable-next-line func-style
export function DataTableDef<T>({
  actionSection,
  emptyText,
  data,
  keySelector,
  selectionMode = SelectionMode.none,
  canSelectRow = returnTrue,
  selected,
  onSelectionChanged,
  virtualized,
  virtualizedRowCount,
  children,
  ...other
}: IDataTableDefProps<T>) {
  const childProps = React.Children.map(
    children,
    c => isValidElement(c) && normalizeColumnProps(c.props as IDataColumnDefProps<T>)
  )!.filter(Boolean) as Array<ColumnMeta<T>>;

  const hasData = Array.isArray(data) && data.length > 0;
  const renderRows = useEventCallback((row: T, rowIndex: number, rowInfo: RowInfo) => {
    const { key, selectionState } = rowInfo;
    const selectable = selectionMode !== SelectionMode.none && canSelectRow(row);
    const isSelected = selectable && selectionState.selected;
    const toggleSelection =
      selectable &&
      ((e: SyntheticEvent) => {
        if (CLICKABLE_ELEMENTS.test((e.target as HTMLElement).nodeName)) {
          return;
        }

        selectionState.toggle();
      });

    return (
      <DataTableRow key={key} alt={rowIndex % 2 !== 0} selectable={selectable} selected={isSelected}>
        {selectionMode === SelectionMode.multiple && <DataTableSelectionCell disabled={!selectable} />}
        {childProps.map((col, i) => {
          const { onClick, ...colProps } = col.cell;
          return (
            <DataTableCell
              data-testid="plex-grid-row-cell"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={chainHandlers(onClick, toggleSelection)}
              {...colProps}
            >
              {col.render(row, rowIndex, rowInfo)}
            </DataTableCell>
          );
        })}
      </DataTableRow>
    );
  });

  return (
    <DataTable<T>
      actionSection={actionSection}
      data={data}
      keySelector={keySelector}
      plugins={[
        DataTablePlugins.Loader<T>(),
        DataTablePlugins.Sorter<T>(),
        DataTablePlugins.Selections<T>({ mode: selectionMode, selected, canSelectRow, onSelectionChanged }),
        !!virtualized && DataTablePlugins.Virtualization<T>({ rowCount: virtualizedRowCount }),
        DataTablePlugins.FixedLayout<T>(),
        DataTablePlugins.ColumnResizer<T>()
      ]}
      {...other}
    >
      {!hasData && <DataTableCaption captionText={emptyText} />}
      <Sticky>
        <DataTableHeader data-testid="plex-grid-header">
          <DataTableHeaderRow>
            {selectionMode === SelectionMode.multiple && <DataTableHeaderSelectionCell />}
            {childProps.map((col, i) => (
              // unless we plan on letting users rearrange columns, this should be
              // a safe warning to ignore...
              // eslint-disable-next-line react/no-array-index-key
              <DataTableHeaderCell key={i} {...col.props} />
            ))}
          </DataTableHeaderRow>
        </DataTableHeader>
      </Sticky>
      <DataTableBody<T>>{renderRows}</DataTableBody>
    </DataTable>
  );
}

DataTableDef.Column = DataColumnDef;
