import React, { FC, useState, useCallback, useMemo } from "react";
import { Form, Dropdown, InputSize } from "@plex/react-components";
import { Node, useReactFlow } from "reactflow";

import { BaseInputOutput, MathExpression } from "../NodeTypes/TypeDefinitions";

export type MathInputOutputConditionProp = BaseInputOutput & {
  node: Node;
};

type Selection = { key: string; value: string };
type MathUIExpression = MathExpression & {
  leftInputSelected: Selection[];
  rightInputSelected: Selection[];
  operatorSelected: Selection[];
  suffixCriteria: Selection[];
};

export const MathExpressionCondition: FC<MathInputOutputConditionProp> = (params) => {
  const defaultExpression: MathUIExpression = useMemo(() => {
    return {
      leftInputName: "",
      rightInputName: "",
      operator: "",
      leftInputSelected: [{ key: "", value: "" }],
      rightInputSelected: [{ key: "", value: "" }],
      operatorSelected: [{ key: "", value: "" }],
      suffixCriteria: [{ key: "", value: "" }]
    };
  }, []);

  const reactFlowInstance = useReactFlow();
  const [currentNode, setCurrentNode] = useState(params.node);
  const [defExpressions, setDefExpressions] = useState<MathUIExpression[]>(
    (params.expressions as MathUIExpression[]) ?? []
  );

  const uiConditions = useCallback(() => {
    let mapedUI: MathUIExpression[] | undefined = defExpressions?.map((x) => {
      const mymap: MathUIExpression = { ...x };
      return mymap;
    });
    if (mapedUI.length == 0) {
      return [defaultExpression];
    }
    return mapedUI;
  }, [defExpressions, defaultExpression]);

  const updateConditions = (condition: MathUIExpression, indx: number) => {
    var conditions = uiConditions();
    conditions.splice(indx, 1, condition);
    const mynewCon = conditions.map((x) => {
      return x;
    });
    const updateNodesWithConditions = (tConditions: MathUIExpression[]) => {
      //update current node property conditions
      let currentNodes = reactFlowInstance.getNodes();
      const forceUpdate: any[] = [];
      currentNodes.forEach((findNode) => {
        if (findNode.id == currentNode?.id) {
          findNode!.position.x += 0.0001;
          if (findNode.data.nodeProperties.outputs) {
            findNode.data.nodeProperties.outputs.forEach((curOutput: BaseInputOutput) => {
              if (curOutput.uniqueId == params.uniqueId) {
                curOutput.expressions = [];
                tConditions.forEach((cond: MathUIExpression) => {
                  curOutput.expressions?.push(cond);
                });
              }
            });
          }
        }
        forceUpdate.push(findNode);
      });
      reactFlowInstance.setNodes(forceUpdate);
      setCurrentNode({ ...currentNode });
    };
    updateNodesWithConditions(mynewCon);
    setDefExpressions(mynewCon);
  };

  return (
    <Form className="switch-input-properties-form">
      <div> &nbsp;</div>
      <div className="container">
        <div className="containerItem">Name:</div>
        <div className="containerItem">{params.name}</div>
      </div>
      <div className="inputContainer">
        {uiConditions().map((property: MathUIExpression, indx: number) => {
          return (
            <Form.Row key={"cond_" + indx}>
              <div style={{ display: "inline-block", paddingRight: "10px" }}>
                <Dropdown
                  size={InputSize.xlarge}
                  items={currentNode?.data.nodeProperties?.inputs.map((input: BaseInputOutput) => {
                    return { key: input.name, value: input.name };
                  })}
                  keySelector={(item: { [key: string]: string }) => item.key}
                  displaySelector={(item: { [key: string]: string }) => item.value}
                  selected={property.leftInputSelected}
                  onSelectionChanged={(values: any) => {
                    property.leftInputSelected = [{ key: values[0].key, value: values[0].value }];
                    property.leftInputName = values[0].value;
                    updateConditions(property, indx);
                  }}
                  includeEmpty={true}
                />
              </div>
              <div style={{ display: "inline-block", paddingRight: "10px" }}>
                <Dropdown
                  items={[
                    { key: "+", value: "+" },
                    { key: "-", value: "-" },
                    { key: "*", value: "*" },
                    { key: "/", value: "/" },
                    { key: "^", value: "*" }
                  ]}
                  keySelector={(item: { [key: string]: string }) => item.key}
                  displaySelector={(item: { [key: string]: string }) => item.value}
                  selected={property.operatorSelected}
                  onSelectionChanged={(values: any) => {
                    property.operatorSelected = [{ key: values[0].key, value: values[0].value }];
                    property.operator = values[0].value;
                    updateConditions(property, indx);
                  }}
                  includeEmpty={true}
                />
              </div>
              <div style={{ display: "inline-block", paddingRight: "10px" }}>
                <Dropdown
                  items={currentNode?.data.nodeProperties?.inputs.map((input: BaseInputOutput) => {
                    return { key: input.name, value: input.name };
                  })}
                  keySelector={(item: { [key: string]: string }) => item.key}
                  displaySelector={(item: { [key: string]: string }) => item.value}
                  selected={property.rightInputSelected}
                  onSelectionChanged={(values: any) => {
                    property.rightInputSelected = [{ key: values[0].key, value: values[0].value }];
                    property.rightInputName = values[0].value;
                    updateConditions(property, indx);
                  }}
                  includeEmpty={true}
                />
              </div>
            </Form.Row>
          );
        })}
      </div>
    </Form>
  );
};
