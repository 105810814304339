import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { ComparisonLabel } from "./ComparisonLabel";
import { NumberCompareOperation } from "../../FlowDocument/FlowDocumentModel";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CompareNumberNode",
  category: NodeTypeCategory.logic,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.DECIMAL, hideLabel: true },
    { name: "right", type: DataType.DECIMAL, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  nodeConfigProperties: [
    {
      name: "operation",
      label: "Comparison",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: NumberCompareOperation }
    },
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [DataType.DECIMAL, DataType.INTEGER],
        dataProperties: ["left", "right"]
      }
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any, nodeProperties: any) => {
    let result = false;
    switch (nodeProperties.operation as NumberCompareOperation) {
      case NumberCompareOperation.Equal:
        result = input.left === input.right;
        break;
      case NumberCompareOperation.NotEqual:
        result = input.left !== input.right;
        break;
      case NumberCompareOperation.Less:
        result = input.left < input.right;
        break;
      case NumberCompareOperation.LessOrEqual:
        result = input.left <= input.right;
        break;
      case NumberCompareOperation.Greater:
        result = input.left > input.right;
        break;
      case NumberCompareOperation.GreaterOrEqual:
        result = input.left >= input.right;
        break;
    }
    return {
      output: { result: result },
      activeControlHandleIds: []
    };
  }
});

export const CompareNumberNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return (
    <BasicNodeLayout id={id} data={data}>
      <ComparisonLabel nodeId={id} comparisonOperator={data.nodeProperties.operation} />
    </BasicNodeLayout>
  );
};
