import React, { forwardRef, PropsWithChildren } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ITableRowProps } from "./DataTable.types";
import styles from "./DataTable.module.scss";

/** Component which renders a row in a DataTable header section. */
const HtmlDataTableHeaderRow = forwardRef<HTMLTableRowElement, ITableRowProps>(({ className, ...others }, ref) => {
  return <tr data-testid="plex-grid-header-row" className={clsx(styles.row, className)} {...others} ref={ref} />;
});
HtmlDataTableHeaderRow.displayName = "DataTableHeaderRow";

const XmlDataTableHeaderRow = ({ children }: PropsWithChildren<ITableRowProps>) => {
  return React.createElement("grid-table-row", {}, children);
};

export const DataTableHeaderRow = withPrinting(HtmlDataTableHeaderRow, XmlDataTableHeaderRow);

/** Component which renders a row in a DataTable. */
const HtmlDataTableRow = forwardRef<HTMLTableRowElement, ITableRowProps>(
  ({ className, alt, selectable, selected, ...other }, ref) => {
    return (
      <tr
        data-testid="plex-grid-row"
        ref={ref}
        className={clsx(
          styles.row,
          alt && styles.rowAlt,
          selectable && styles.selectable,
          selected && styles.rowSelected,
          className
        )}
        {...other}
      />
    );
  }
);
HtmlDataTableRow.displayName = "DataTableRow";

const XmlDataTableRow = ({ children, style }: PropsWithChildren<ITableRowProps>) => {
  let layout = {};
  layout = { ...layout, "background-color": style?.backgroundColor ?? "" };

  return React.createElement("grid-table-row", { ...layout }, children);
};

export const DataTableRow = withPrinting(HtmlDataTableRow, XmlDataTableRow);
