import { useEffect, EffectCallback, DependencyList } from "react";

/**
 * Hook that is a wrapper around `useEffect`. This is meant to be
 * more explicit and prevents linting warnings and fixes that can
 * cause issues when attempting to use the `useEffect` hook.
 */
export const useWhenChanged = (effect: EffectCallback, deps: DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, deps);
};
