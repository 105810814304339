import React, { useRef, FunctionComponent, ReactElement, CSSProperties } from "react";
import { useSticky } from "./use-sticky";
import { IStickyProps } from "./Sticky.types";

/**
 * Component which wraps a single child and handles "stickiness" for that component.
 */
export const Sticky: FunctionComponent<IStickyProps> = ({ offset = 0, children }) => {
  const ref = useRef<HTMLElement>(null);
  const stickyStyle = useSticky(ref, offset);

  const child = React.Children.only(children);

  if (React.isValidElement(child)) {
    const style: CSSProperties = { ...child.props.style, ...stickyStyle };
    return React.cloneElement(child, { style, ref });
  }

  return child as ReactElement;
};
