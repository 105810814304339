import React, { FunctionComponent } from "react";
import styles from "./ActionBar.module.scss";

export interface IDividerProps {
  /**
   * Element id
   */
  id?: string;
}

const ActionBarDivider: FunctionComponent<IDividerProps> = ({ id }) => {
  return <li data-testid="plex-action-divider" id={id} className={styles.divider} />;
};

export { ActionBarDivider };
