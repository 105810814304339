import React, {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from "react";
import { DropdownContext } from "./ActionBar.Dropdown";
import { IButtonProps } from "./ActionBar.Button";
import { IDividerProps } from "./ActionBar.Divider";
import styles from "./ActionBar.module.scss";

type MenuTypes = ReactElement<IButtonProps> | ReactElement<IDividerProps>;

interface IActionBarDropdownMenuItemsProps {
  children?: MenuTypes[] | MenuTypes;
}

export const ActionBarDropdownItems: FunctionComponent<IActionBarDropdownMenuItemsProps> = ({ children }) => {
  const { visible, level } = useContext(DropdownContext);

  const ref = useRef<HTMLUListElement>(null);
  const [style, setStyle] = useState<CSSProperties>({});
  const [initialPosition, setInitialPosition] = useState<number>();

  useEffect(() => {
    if (level === 1 && visible && ref.current) {
      setInitialPosition(ref.current.getBoundingClientRect().left);
    }
  }, [visible, level]);

  const checkPosition = useCallback(() => {
    if (level === 1 && ref.current) {
      const fullWidth = document.body.parentElement
        ? document.body.parentElement.getBoundingClientRect().width
        : undefined;
      const { top, right, left } = ref.current.getBoundingClientRect();
      const base: CSSProperties = {};

      if (fullWidth && right >= fullWidth) {
        base.right = 0;
      }
      if (initialPosition && left > initialPosition) {
        setStyle({ maxHeight: `calc(95vh - ${String(top)}px)` });
        return;
      }

      if (style.right === 1) {
        return;
      }
      setStyle({ ...base, maxHeight: `calc(95vh - ${String(top)}px)` });
    }
  }, [style, initialPosition, level]);

  useEffect(() => {
    if (level === 1 && visible && ref.current) {
      const observer = new MutationObserver(checkPosition);
      observer.observe(ref.current, {
        attributes: true,
        subtree: true
      });

      return () => observer.disconnect();
    }
    return undefined;
  }, [level, initialPosition, visible, checkPosition]);

  useEffect(() => {
    if (!visible || !ref.current) {
      setStyle({});
      return;
    }
    const { top, right } = ref.current.getBoundingClientRect();
    const base: CSSProperties = {};
    if (level === 1 && document.body.parentElement) {
      const fullWidth = document.body.parentElement.getBoundingClientRect().width;
      if (fullWidth && right > fullWidth) {
        base.right = 0;
      }
    }

    setStyle({ ...base, maxHeight: `calc(95vh - ${String(top)}px)` });
  }, [visible, level]);

  return (
    <ul className={styles.menuBase} style={style} ref={ref} role="menu" hidden={!visible}>
      {children}
    </ul>
  );
};
ActionBarDropdownItems.displayName = "ActionBarDropdownItems";
