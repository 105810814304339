import React, { FunctionComponent } from "react";
import { DateInput } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface IDateInputProps extends INodeConfigPropertiesProps {
  label: string;
}

export const DateFormField: FunctionComponent<IDateInputProps> = (props) => {
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  return (
    <DateInput
      key={`${props.node.data.nodeProperties.type} Input`}
      value={props.node?.data.nodeProperties[props.name] || ""}
      onValueChange={(date: any) => {
        if (date) {
          props.node!.data.nodeProperties[props.name] = date;
          setState({ value: props.node!.data.nodeProperties[props.name] });
        } else {
          props.node!.data.nodeProperties[props.name] = null;
          setState({ value: null });
        }
        props.forceNodeUpdate();
      }}
    />
  );
};
