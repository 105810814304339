import React, { FunctionComponent } from "react";
import { useLocalization } from "@plex/culture-react";
import { OkButton } from "../Button";
import styles from "./Dialog.module.scss";
import { Dialog } from "./Dialog";

export interface INotifyDialogProps {
  /** Indicates whether the dialog should be rendered */
  show: boolean;
  /** The dialog title */
  title?: string;
  /** Notification Message */
  message: string;
  /** Callback executed when the dialog is being closed */
  onHide: () => void;
}

export const NotifyDialog: FunctionComponent<INotifyDialogProps> = ({ show, title, message, onHide }) => {
  const { t } = useLocalization();
  const minWidth = 360;
  const minHeight = 215;

  return (
    <Dialog
      data-testid="plex-notify-modal"
      closeButton
      show={show}
      minHeight={minHeight}
      minWidth={minWidth}
      title={title || t("ui-common-dialog-title-notify")}
      backdrop="static"
      onHide={onHide}
    >
      <Dialog.Body>
        <div className={styles.notificationContent}>
          <span className={styles.notificationMessage} data-testid="plex-notify-message">
            {message}
          </span>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <OkButton onClick={onHide} data-testid="plex-ok-button" size="sm" />
      </Dialog.Footer>
    </Dialog>
  );
};
