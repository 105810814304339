import React, { ReactElement, FunctionComponent, useState } from "react";
import { useKeypressDelete } from "../../Hooks/useKeypressDelete";
import { NodeSize } from "./NodeTypeDefinitions";
import { useReactFlow } from "reactflow";
import { getAnchorDepth } from "../../Util/AnchorUtil";

export interface INodeContainerProps {
  id: string;
  data: any;
  size?: NodeSize;
  className?: string;
  children: ReactElement;
}

export const NodeContainer: FunctionComponent<INodeContainerProps> = ({ id, data, children, size, className }) => {
  const [nodeId] = useState(id);
  const [isOver, setIsOver] = useState(false);
  const nodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(data.nodeProperties.type);
  const reactFlowInstance = useReactFlow();

  const getClassName = () => {
    let resultClassName = "api-node base-node-properties-clickable-div";

    if (data.nodeProperties.isEvaluating) {
      resultClassName += " evaluating";
    }

    if (data.nodeProperties.isCurrentTrace) {
      className += " current-node-trace";
    }

    if (size === NodeSize.Small) {
      resultClassName += " small-node-size";
    }

    if (size === NodeSize.Large) {
      resultClassName += " large-node-size";
    }

    if (nodeTypeDefinition && data.nodeProperties.anchoredToNodeId && nodeTypeDefinition.hideLabelOnAnchor) {
      className += " anchored-node-container-hide-label";
    }

    if (data.nodeProperties.anchoredToNodeId) {
      if (
        Object.keys(data.nodeProperties.inputs).length === 0 &&
        Object.keys(data.nodeProperties.outputs).length === 0
      ) {
        resultClassName += " bottom-anchor-position";
      }
      resultClassName += " anchored-node";
    }

    if (data.nodeProperties.anchoredToNodeId && getAnchorDepth(id, reactFlowInstance) % 2 === 1) {
      resultClassName += " anchored-node-odd-depth";
    }

    if (data.nodeProperties.isBreakpoint) {
      className += " breakpoint-node-trace";
    }

    return `${resultClassName} ${className}`;
  };

  useKeypressDelete(nodeId, isOver, true);

  return (
    <div
      id={id}
      data-testid={"nodeInstance-" + id}
      className={getClassName()}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      onDoubleClick={() => globalThis.setPropertiesPopupState((current: boolean) => (current = !current))}
    >
      {children}
    </div>
  );
};
