import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "OrNode",
  category: NodeTypeCategory.logic,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.BOOLEAN, hideLabel: true },
    { name: "right", type: DataType.BOOLEAN, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return { output: { result: input.left || input.right }, activeControlHandleIds: [] };
  }
});

export const OrNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
