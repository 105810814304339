import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { CloseIcon } from "@plex/icons";
import styles from "./Dialog.module.scss";

export interface IDialogHeaderProps {
  closeButton?: boolean;
  onHide?: () => void;
  className?: string;
}

export const DialogHeader: FunctionComponent<IDialogHeaderProps> = ({
  closeButton,
  onHide,
  className,
  children,
  ...props
}) => {
  const modalHeader = clsx(styles.modalHeader, className);
  const headerWrapper = clsx(styles.modalHeaderWrapper, className);

  return (
    <div className={headerWrapper}>
      <div {...props} className={modalHeader}>
        {children}
      </div>
      {closeButton && (
        <a onClick={onHide} className={styles.closeIcon} data-testid="plex-dialog-close-icon">
          <CloseIcon width={13} height={13} />
        </a>
      )}
    </div>
  );
};
