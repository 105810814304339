import React, { FC, MouseEventHandler, createElement, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { withPrinting, usePrintAwaiter } from "@plex/react-xml-renderer";
import { ImageResizerWidget } from "@components/FileUpload";
import { ICommonProps } from "../Common.types";
import styles from "./Image.module.scss";

interface IImageProps extends ICommonProps {
  /** The image url */
  src: string;
  /** The image alternate text */
  alt?: string;
  /** The image width */
  width?: number | string;
  /** The image height */
  height?: number | string;
  /** The image title */
  title?: string;
  /** The image's printPath path */
  printPath?: string;
  /** The click event handler for the image */
  onClick?: MouseEventHandler;
  /** The show resizer widget image */
  showResizeWidget?: boolean;
  /** Flag to disable show/hide resize widget image control */
  disableResizeWidget?: boolean;
}

type Dimensions = {
  height: number | null;
  width: number | null;
  loaded: boolean | null;
};

type UpdateHandler = () => void;
const DIMENSION_MAP = new Map<string, Dimensions>();
const PENDING_IMGS = new Map<string, UpdateHandler[]>();

const awaitPendingLoad = (src: string, handler: UpdateHandler) => {
  const imgKey = src.toLowerCase();
  let pendingLoads = PENDING_IMGS.get(imgKey);
  if (!pendingLoads) {
    PENDING_IMGS.set(imgKey, (pendingLoads = []));
  }
  pendingLoads.push(handler);
};

const invokePendingLoads = (src: string) => {
  const imgKey = src.toLowerCase();
  const pendingLoads = PENDING_IMGS.get(imgKey);
  pendingLoads?.forEach(x => x());
  PENDING_IMGS.delete(imgKey);
};

/** Component which renders an image */
const HtmlImage: FC<IImageProps> = ({
  id,
  src,
  alt,
  className,
  showResizeWidget,
  disableResizeWidget,
  printPath: _,
  ...other
}) => {
  const ref = useRef<HTMLImageElement>(null);
  const classes = clsx(styles.base, className);
  const altValue = alt ?? src;

  useEffect(() => {
    if (id && ref.current) {
      DIMENSION_MAP.set(id, { height: ref.current.naturalHeight, width: ref.current.naturalWidth, loaded: false });
      return () => {
        DIMENSION_MAP.delete(id);
      };
    }

    return undefined;
  }, [id]);

  if (showResizeWidget) {
    return (
      <div>
        <ImageResizerWidget imageRef={ref} disabled={disableResizeWidget} />
        <img src={src} ref={ref} />
      </div>
    );
  }

  return <img id={id} className={classes} src={src} alt={altValue} ref={ref} {...other} />;
};

const millimeterConversion = 0.264583333;
const XmlImage: FC<IImageProps> = ({ id, src, height, width, printPath }) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    height: typeof height === "number" ? height : null,
    width: typeof width === "number" ? width : null,
    loaded: false
  });

  usePrintAwaiter(ready => {
    const img = ((id && document.getElementById(id)) || document.createElement("img")) as HTMLImageElement;
    const update = () => {
      setDimensions({ height: img.naturalHeight, width: img.naturalWidth, loaded: true });
      ready();
    };

    if (dimensions.height || dimensions.width) {
      ready();
      return;
    }

    if ((img.naturalHeight > 0 && img.naturalWidth > 0) || dimensions.loaded) {
      update();
      return;
    }

    if (id && DIMENSION_MAP.has(id)) {
      const dim = DIMENSION_MAP.get(id)!;
      if (dim.loaded) {
        setDimensions(dim);
        ready();
        return;
      }
    }

    img.onload = img.onerror = () => {
      invokePendingLoads(src);
    };

    img.src = src;
    awaitPendingLoad(src, update);
  });

  const renderHeight = dimensions.height || 0;
  const renderWidth = dimensions.width || 0;
  if (renderHeight === 0 && renderWidth === 0) {
    return null;
  }

  return createElement("plex-control-image", {
    src,
    contentheight: `${String(Math.floor(renderHeight * millimeterConversion))}mm`,
    contentwidth: `${String(Math.floor(renderWidth * millimeterConversion))}mm`,
    imagepath: printPath
  });
};

export const Image = withPrinting(HtmlImage, XmlImage);
