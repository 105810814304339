import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../../../components/NodeTypes/Base";
import { DataType } from "../../../components/NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const itemInputName = "item";
const listOutputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConvertToListNode",
  label: "To List",
  category: NodeTypeCategory.lists,
  controlOutputs: [],
  dataInputs: [{ name: itemInputName, hideLabel: true, type: DataType.STRING }],
  dataOutputs: [{ name: listOutputName, label: "List", type: DataType.STRINGLIST }],
  documentDataInputListName: "items",
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [itemInputName, listOutputName] }
    },
    {
      name: "itemCount",
      label: "Items",
      propertyType: NodeConfigPropertyType.DataPropertyCount,
      options: { cloneFrom: itemInputName }
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return {
      output: { list: [input.item] },
      activeControlHandleIds: []
    };
  }
});

export const ConvertToListNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
