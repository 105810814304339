import React, { forwardRef } from "react";
import { Temporal } from "js-joda";
import { ICommonDatePickerProps, ITemporalInputProps, DateChangeHandler } from "./DatePicker.types";
import { DateTimeInput } from "./DateTimeInput";

export interface IDateInputProps extends ITemporalInputProps, ICommonDatePickerProps {}

/**
 * This component renders an input with a calendar button that a user can use to
 * enter or select individual date values.
 */
export const DateInput = forwardRef<HTMLInputElement, IDateInputProps>(({ value, onValueChange, ...others }, ref) => {
  return (
    <DateTimeInput
      showTime={false}
      ref={ref}
      value={value as Temporal}
      onValueChange={onValueChange as DateChangeHandler<Temporal>}
      {...others}
    />
  );
});

DateInput.displayName = "DateInput";
