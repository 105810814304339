import React, { FunctionComponent, useRef, useLayoutEffect, PropsWithChildren } from "react";
import { HandleContainer, NodeContainer, NodeHeader, NodeSize } from "../Base";
import { useReactFlow } from "reactflow";
import { AnchorArea, AnchorProperties } from "../../Util/AnchorUtil";

export interface ISimpleNodeContainerProps extends PropsWithChildren {
  id: string;
  data: any;
  className?: string;
  label?: string;
}

export const BasicNodeLayout: FunctionComponent<ISimpleNodeContainerProps> = ({
  id,
  data,
  className,
  label,
  children
}) => {
  const labelContainerRef = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const nodeType = data.nodeProperties.type;
  const nodeTypeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(nodeType);
  let experimentialLabelPadding = 8;
  if (nodeTypeDefinition.size === NodeSize.Large) {
    experimentialLabelPadding = 40;
  } else if (nodeTypeDefinition.size === NodeSize.Normal) {
    experimentialLabelPadding = 20;
  }

  useLayoutEffect(() => {
    if (labelContainerRef.current) {
      data.nodeProperties.labelContainerHeight = (labelContainerRef.current as any).clientHeight;
    }
  });

  const renderTopAnchorSpacing = () => {
    return (
      <div>
        {(data.nodeProperties.anchoredNodes ?? [])
          .filter((anchorProperties: AnchorProperties) => anchorProperties.area === AnchorArea.Top)
          .map((anchorProperties: AnchorProperties, index: number) => {
            const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId)!;
            return <div style={{ width: anchoredNode.width ?? 0, height: (anchoredNode.height ?? 0) + 10 }} />;
          })}
      </div>
    );
  };

  const renderBottomAnchorSpacing = () => {
    return (
      <div>
        {(data.nodeProperties.anchoredNodes ?? [])
          .filter((anchorProperties: AnchorProperties) => anchorProperties.area === AnchorArea.Bottom)
          .map((anchorProperties: AnchorProperties, index: number) => {
            const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId)!;
            return <div style={{ width: anchoredNode.width ?? 0, height: (anchoredNode.height ?? 0) + 10 }} />;
          })}
      </div>
    );
  };

  const getChildrenContainerStyle = () => {
    const style: any = {};

    const anchoredNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(data.nodeProperties.type);
    if (anchoredNodeDefinition) {
      style.maxWidth = anchoredNodeDefinition.size as number;
    } else {
      style.maxWidth = NodeSize.Normal;
    }

    return style;
  };

  const getClassName = (paramClassName: string) => {
    let className = `${paramClassName} ${data.nodeProperties.type}-node-container`;

    if (data.nodeProperties.anchoredToNodeId) {
      className += " anchored-node";
    }

    return className;
  };

  return (
    <NodeContainer id={id} data={data} size={nodeTypeDefinition.size} className={getClassName(className ?? "")}>
      <div>
        <div ref={labelContainerRef}>
          {renderTopAnchorSpacing()}
          <NodeHeader
            nodeId={id}
            nodeProperties={data.nodeProperties}
            iconClassName={"base-node-icon " + nodeTypeDefinition.id + "-node-icon"}
            label={label}
          />
          <div className="basic-layout-children-container" style={getChildrenContainerStyle()}>
            {children}
          </div>
          {nodeTypeDefinition.isExperimental && (
            <label style={{ paddingLeft: `${experimentialLabelPadding}px`, fontWeight: "bold" }}>
              **Experimental**
            </label>
          )}
        </div>
        <HandleContainer id={id} data={data} />
        {renderBottomAnchorSpacing()}
      </div>
    </NodeContainer>
  );
};
