import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import { useLocalization } from "@plex/culture-react";
import styles from "./ProgressBar.module.scss";

export enum ProgressBarColors {
  primary = "primary",
  alternate = "alternate",
  info = "info",
  success = "success",
  warn = "warn",
  danger = "danger"
}

type ProgressValue = number | string;
type ProgressTextGetter = (progressValue: ProgressValue) => string;

interface IProgressBarContainerProps {
  /**
   * The title for the progress bar container.
   */
  title?: string | ReactNode;

  /**
   * The message for the progress bar container
   */
  message?: string | ReactNode;

  /**
   * className for the container
   */
  containerClassName?: string;
}

export interface IProgressBarProps extends ICommonProps {
  /**
   * Current progress value.
   * @default 0
   */
  progressValue?: ProgressValue;

  /**
   * Text value describing current progress.
   */
  progressText?: string | ProgressTextGetter;

  /**
   * Determines if progress text is displayed or not.
   * @default true
   */
  displayText?: boolean;

  /**
   * Progress value considered as an indeterminate value.
   * @default "indeterminate"
   */
  indeterminateValue?: ProgressValue;

  /**
   * Text describing indeterminate value.
   * Overridden by `progressText`.
   * @default "Loading..."
   */
  indeterminateText?: string;

  /**
   * Sets custom value at which progress bar is hidden.
   * Only applicable when `containerProps` are skipped.
   * @default "hide"
   */
  hideValue?: ProgressValue;

  /**
   * Text describing progress completion.
   * Overridden by `progressText`.
   * @default "Complete!"
   */
  completeText?: string;

  /**
   * Color variant for the progress bar.
   */
  color?: ProgressBarColors;

  /**
   * Props for progress-bar's container element.
   * Container is only rendered if `containerProps` are passed in.
   */
  containerProps?: IProgressBarContainerProps;

  /**
   * Whether the progress bar is initialized.
   * When false and when `containerProps` are passed, container is rendered with `message` contents.
   * When false and when `containerProps` are skipped, progress bar is hidden.
   * @default true
   */
  initialized?: boolean;
}

const ProgressIndicator: FunctionComponent<IProgressBarProps> = ({
  progressValue = 0,
  displayText,
  progressText,
  indeterminateValue,
  indeterminateText,
  completeText,
  color,
  className
}) => {
  const isIndeterminate = progressValue === indeterminateValue;
  const { t } = useLocalization();

  let text = `${String(progressValue)}%`;
  if (isIndeterminate) {
    text = indeterminateText ?? t("ui-common-progressBar-loading");
  }

  if (progressValue === 100) {
    text = completeText ?? t("ui-common-progressBar-complete");
  }

  if (progressText) {
    if (typeof progressText === "function") {
      text = progressText(progressValue);
    } else {
      text = progressText;
    }
  }

  return (
    <div data-testid="plex-progress-bar" className={clsx(styles.progressBar, className)}>
      <div
        data-testid="plex-progress-bar-completed"
        style={{ width: `${String(progressValue)}%` }}
        className={clsx(
          styles.progressBarCompleted,
          color && styles[color],
          isIndeterminate && styles.progressBarStriped
        )}
      >
        {displayText && <span data-testid="plex-progress-bar-text">{text}</span>}
      </div>
    </div>
  );
};

export const ProgressBar: FunctionComponent<IProgressBarProps> = ({
  progressValue = 0,
  progressText,
  displayText = true,
  indeterminateValue = "indeterminate",
  indeterminateText,
  hideValue = "hide",
  completeText,
  color,
  containerProps,
  initialized = true,
  className
}) => {
  // Render within a container
  if (containerProps) {
    const { message, title, containerClassName } = containerProps;
    const classes = clsx(styles.base, containerClassName);

    if (initialized) {
      return (
        <div data-testid="plex-progress-bar-container" className={classes}>
          <span data-testid="plex-progress-bar-title" className={styles.progressBarTitle}>
            {title}
          </span>
          <ProgressIndicator
            progressValue={progressValue}
            displayText={false}
            progressText={progressText}
            indeterminateValue={indeterminateValue}
            indeterminateText={indeterminateText}
            completeText={completeText}
            color={color}
            className={className}
          />
          <span data-testid="plex-progress-bar-message">{message}</span>
        </div>
      );
    }

    // Display only the message contents, skip title, progress bar before initialization
    return (
      <div data-testid="plex-progress-bar-container" className={classes}>
        <span data-testid="plex-progress-bar-message">{message}</span>
      </div>
    );
  }

  if (!initialized || progressValue === hideValue || progressValue == null) {
    return null;
  }

  // Render without a container
  return (
    <ProgressIndicator
      progressValue={progressValue}
      displayText={displayText}
      progressText={progressText}
      indeterminateValue={indeterminateValue}
      indeterminateText={indeterminateText}
      completeText={completeText}
      color={color}
      className={className}
    />
  );
};

ProgressBar.displayName = "ProgressBar";
