import { Comparer, ValueAccessor } from "../Common.types";

export const reverseComparer = <T>(comparer: Comparer<T>): Comparer<T> => {
  return (a, b) => comparer(b, a);
};

export const reduceComparer = <TRecord, TValue>(
  comparer: Comparer<TValue>,
  selector: ValueAccessor<TRecord, TValue>
) => {
  return (a: TRecord, b: TRecord) => comparer(selector(a), selector(b));
};

const genericComparer = (a: unknown, b: unknown) => {
  if (typeof a === "string") {
    return a.localeCompare(String(b ?? ""));
  }

  // Since sorting is visual, we want to allow things like "1" == 1.
  // eslint-disable-next-line eqeqeq
  if (a == b) {
    return 0;
  }

  // this will work with numbers, dates, and booleans
  return Number(a) - Number(b);
};

// eslint-disable-next-line func-style
export function createComparer<T>(getter: ValueAccessor<T>): Comparer<T> {
  return (a, b) => genericComparer(getter(a), getter(b));
}

// eslint-disable-next-line func-style
export function combineComparers<T>(...comparers: Array<Comparer<T>>): Comparer<T> {
  return (a, b) => {
    for (let i = 0; i < comparers.length; i++) {
      const result = comparers[i](a, b);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}
