import React, {
  useState,
  KeyboardEvent,
  FocusEvent,
  useEffect,
  forwardRef,
  ChangeEvent,
  createElement,
  FunctionComponent
} from "react";
import { LocalDate, nativeJs, LocalDateTime } from "js-joda";
import { CalendarIcon } from "@plex/icons";
import { useLocalization } from "@plex/culture-react";
import { withPrinting } from "@plex/react-xml-renderer";
import { useToggle } from "../../hooks";
import { IconButton, OkButton } from "../Button";
import { CancelLink } from "../Link";
import { Chip, ChipInput } from "../Chip";
import { Popover } from "../Popover";
import { AdornedInput } from "../Input";
import { IDateRangePickerProps, ITemporalInputProps, ITimeProps } from "./DatePicker.types";
import { CalendarInterval } from "./CalendarInterval";
import { DateRangePicker } from "./DateRangePicker";
import styles from "./DatePicker.module.scss";

interface IDateTimeRangeInputProps extends ITemporalInputProps, IDateRangePickerProps, ITimeProps {
  // for internal use
  showTime?: boolean;
}

/**
 * This component renders an input with a calendar button that a user can use to
 * enter or select individual date values.
 */
const HtmlDateTimeRangeInput = forwardRef<HTMLInputElement, IDateTimeRangeInputProps>(
  // eslint-disable-next-line complexity
  (
    {
      // date props
      weekStart,
      // time props
      use24HourTime,
      precision,
      minuteStep,
      // range props
      range: inputRange,
      onRangeChange,
      rangeFilters,
      // internal
      showTime = true,
      // input props
      disabled,
      onKeyDown,
      formatOptions,
      ...other
    },
    ref
  ) => {
    const { locale, t } = useLocalization();
    const [popoverVisible, togglePopover, hidePopover, showPopover] = useToggle(false);
    const [pickerValue, setPickerValue] = useState<CalendarInterval | null>(inputRange || CalendarInterval.today());
    const [inputValue, setInputValue] = useState("");

    const clearPicker = () => {
      hidePopover();
      setInputValue("");
    };

    const cancelPick = () => {
      setPickerValue(inputRange || null);
      clearPicker();
    };

    const handleValueChange = (value: CalendarInterval | null) => {
      onRangeChange(value);
      clearPicker();
    };

    const handleUserText = (e: FocusEvent<HTMLInputElement>) => {
      if (!e.currentTarget.value) {
        return;
      }

      const parts = e.currentTarget.value.split(" - ");
      const start = locale.dates.parse(parts[0], formatOptions);
      const end = locale.dates.parse(parts[1], formatOptions);

      const parsedRange = showTime
        ? CalendarInterval.of(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start && LocalDateTime.from(nativeJs(start)),
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end && LocalDateTime.from(nativeJs(end))
          )
        : CalendarInterval.of(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            start && LocalDate.from(nativeJs(start)),
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            end && LocalDate.from(nativeJs(end))
          );

      handleValueChange(parsedRange);

      if (parsedRange.isZero()) {
        showPopover();
      }
    };

    useEffect(() => {
      if (popoverVisible) {
        setPickerValue(inputRange && !inputRange.isEmpty() ? inputRange : CalendarInterval.today());
      }
    }, [popoverVisible, inputRange, inputValue]);

    const displayRange = inputRange || CalendarInterval.empty();
    const startDateText = displayRange.isUnboundedEnd()
      ? t("ui-common-datePicker-heading-open")
      : locale.dates.format(displayRange.start(), formatOptions) || "";
    const endDateText = displayRange.isUnboundedStart()
      ? t("ui-common-datePicker-heading-open")
      : locale.dates.format(displayRange.end(), formatOptions) || "";

    const displayText = `${startDateText || ""} - ${endDateText || ""}`;

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (popoverVisible) {
        return;
      }

      if (e.key === "Enter" && e.currentTarget.value) {
        e.currentTarget.blur();
        e.preventDefault();
        return;
      }

      if (e.key === "Backspace" && !e.currentTarget.value && displayText) {
        setInputValue(displayText);
        onRangeChange(null);
        e.preventDefault();
        return;
      }

      onKeyDown?.(e);
    };

    const calendarButton = (
      <IconButton disabled={disabled} onClick={togglePopover}>
        <CalendarIcon />
      </IconButton>
    );

    const buttonWithPopover = disabled ? (
      calendarButton
    ) : (
      <Popover visible={popoverVisible} anchor={calendarButton} hideOnClick onHidden={hidePopover}>
        <Popover.Body>
          <DateRangePicker
            range={pickerValue!}
            onRangeChange={setPickerValue}
            rangeFilters={rangeFilters}
            weekStart={weekStart}
            use24HourTime={use24HourTime}
            precision={precision}
            minuteStep={minuteStep}
            showing={popoverVisible}
            showTime={showTime}
          />
        </Popover.Body>
        <Popover.Footer date-testid="plex-datetimepicker-buttons">
          <CancelLink onClick={cancelPick} data-testid="plex-datetimepicker-cancel-link" />
          <OkButton onClick={() => handleValueChange(pickerValue)} data-testid="plex-datetimepicker-ok-button" />
        </Popover.Footer>
      </Popover>
    );

    return (
      <AdornedInput
        value={inputValue}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
        onBlur={handleUserText}
        onKeyDown={handleKeyDown}
        adornment={buttonWithPopover}
        as={ChipInput}
        ref={ref}
        {...other}
      >
        {displayRange.isEmpty() ? null : (
          <Chip title={displayText} closable={!disabled} onClose={() => handleValueChange(null)}>
            <p className={styles.rangeLine1}>{showTime ? `${startDateText || ""} -` : displayText}</p>
            {showTime && endDateText && <p className={styles.rangeLine2}>{endDateText}</p>}
          </Chip>
        )}
      </AdornedInput>
    );
  }
);
HtmlDateTimeRangeInput.displayName = "DateTimeRangeInput";

const XmlDateTimeRangeInput: FunctionComponent<IDateRangePickerProps> = ({ range }) => {
  return createElement("plex-control-datepicker", {}, range?.toString());
};

export const DateTimeRangeInput = withPrinting<IDateTimeRangeInputProps, HTMLInputElement>(
  HtmlDateTimeRangeInput,
  XmlDateTimeRangeInput
);
