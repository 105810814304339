import { useRef } from "react";

let counter = 0;
export type uid = number;

/** Hook that returns a consistent unique value whenever called. */
export const useUid = (): uid => {
  const ref = useRef(0);
  if (!ref.current) {
    ref.current = ++counter;
  }

  return ref.current;
};
