import ReactReconciler, { HostConfig } from "react-reconciler";

const setAttributes = (
  node: HTMLElement,
  attrs: {
    [key: string]: string;
  }
) => {
  Object.keys(attrs)
    .filter(attr => attr !== "children" && attrs[attr] !== undefined)
    .forEach(attr => node.setAttribute(attr, attrs[attr]));
};

const removeAttributes = (
  node: HTMLElement,
  attrs: {
    [key: string]: string;
  }
) => {
  Object.keys(attrs).forEach(attr => node.removeAttribute(attr));
};

export type Props = {};
const reconcilerConfig: HostConfig<
  string,
  Props & { [key: string]: string },
  HTMLElement,
  Node,
  Node,
  {},
  {},
  {},
  {},
  {},
  {},
  number,
  {}
> = {
  getPublicInstance(instance) {
    return instance;
  },
  getRootHostContext(_nextRootInstance) {
    return null;
  },
  getChildHostContext(_parentContext, _fiberType, _rootInstance) {
    return _parentContext;
  },

  prepareForCommit(_rootContainerInstance) {
    return null;
  },
  resetAfterCommit(_rootContainerInstance) {
    // noop
  },
  clearContainer(container) {
    let node;
    let nextNode: Node | null = container.firstChild;
    while (nextNode) {
      node = nextNode;
      nextNode = nextNode.nextSibling;
      container.removeChild(node);
    }
  },
  createInstance(type, props, rootContainerInstance, _hostContext, _internalInstanceHandle) {
    const node = rootContainerInstance.ownerDocument!.createElement(type);
    setAttributes(node, props);
    return node;
  },
  appendInitialChild(parent, child) {
    parent.appendChild(child);
  },
  finalizeInitialChildren(_instance, _type, _newProps, _rootContainerInstance, _currentHostContext) {
    return false;
  },

  prepareUpdate(_instance, _type, _oldProps, _newProps, _rootContainerInstance, _currentHostContext) {
    return null;
  },

  shouldSetTextContent(_type, _nextProps) {
    return false;
  },
  createTextInstance(text, rootContainerInstance, _hostContext, _internalInstanceHandle) {
    return rootContainerInstance.ownerDocument!.createTextNode(text);
  },
  scheduleTimeout(handler: TimerHandler, timeout: number | undefined) {
    return setTimeout(handler, timeout);
  },
  cancelTimeout(handle: number) {
    clearTimeout(handle);
  },
  noTimeout: -1,

  isPrimaryRenderer: false,

  supportsMutation: true,
  supportsPersistence: false,
  supportsHydration: false,

  // MUTATION
  appendChild(parent, child) {
    parent.appendChild(child);
  },
  appendChildToContainer(container, child) {
    container.appendChild(child);
  },
  insertBefore(parent, child, beforeChild: Node) {
    parent.insertBefore(child, beforeChild);
  },
  insertInContainerBefore(container, child, beforeChild: Node) {
    container.insertBefore(child, beforeChild);
  },
  removeChild(parent, child: Node) {
    parent.removeChild(child);
  },
  removeChildFromContainer(container, child: Node) {
    container.removeChild(child);
  },
  resetTextContent(_instance) {
    // noop
  },
  commitTextUpdate(textInstance, _oldText, newText) {
    textInstance.nodeValue = newText;
  },
  commitMount(_instance, _type, _newProps, _internalInstanceHandle) {
    // noop
  },
  commitUpdate(instance, _updatePayload, _type, oldProps, newProps, _finishedWork) {
    removeAttributes(instance as HTMLElement, oldProps);
    setAttributes(instance as HTMLElement, newProps);
  },
  hideInstance(_instance) {
    // noop
  },
  hideTextInstance(_textInstance) {
    // noop
  },
  unhideInstance(_instance, _props) {
    // noop
  },
  unhideTextInstance(_textInstance, _text) {
    // noop
  },
  preparePortalMount() {
    // noop
  },
  getCurrentEventPriority() {
    return 0;
  },
  getInstanceFromNode() {
    return null;
  },
  beforeActiveInstanceBlur() {
    // noop
  },
  afterActiveInstanceBlur() {
    // noop
  },
  prepareScopeUpdate() {
    // noop
  },
  getInstanceFromScope() {
    return null;
  },
  detachDeletedInstance() {
    // noop
  }
};

export const xmlReconciler = ReactReconciler(reconcilerConfig);
