import React, { KeyboardEvent, ChangeEvent, forwardRef, useCallback, createElement, FunctionComponent } from "react";
import clsx from "clsx";
import { sizeStyle } from "@components/inputs-styles";
import { withPrinting } from "@plex/react-xml-renderer";
import { IInputProps, InputSize } from "./Input.types";
import styles from "./Input.module.scss";

const HtmlTextInput = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      disabled,
      keyboard = "text",
      onEnterKey,
      onKeyPress,
      type = "text",
      size = InputSize.dynamic,
      onBlur,
      onChange,
      onValueChange,
      ...other
    },
    ref
  ) => {
    const keyPressHandler = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          onEnterKey?.(e);
        }

        onKeyPress?.(e);
      },
      [onEnterKey, onKeyPress]
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      onValueChange?.(e.currentTarget.value);
    };

    const classes = clsx(styles.base, sizeStyle(size), className);

    return (
      <input
        type={type}
        className={classes}
        disabled={disabled}
        inputMode={keyboard}
        onKeyPress={keyPressHandler}
        ref={ref}
        onBlur={onBlur}
        onChange={handleChange}
        {...other}
      />
    );
  }
);
HtmlTextInput.displayName = "TextInput";

const XmlTextInput: FunctionComponent<IInputProps> = ({ value }) => {
  return createElement("plex-control-input", {}, String(value || ""));
};

export const TextInput = withPrinting(HtmlTextInput, XmlTextInput);
