import { ReactFlowInstance, Node, Connection, UpdateNodeInternals } from "reactflow";
import {
  IDocumentControlInput,
  IDocumentDataInput,
  IDocumentNode,
  IFlowDocumentModel,
  IDocumentSchema,
  IDocumentFlowInput,
  IDocumentControlOutput,
  IDocumentFlowOutput,
  IDocumentDataOutput,
  IDocumentNodeConnection,
  DocumentNodeConnectionType
} from "./FlowDocumentModel";
import {
  EdgeDetail,
  IEdgeInfo,
  getDesignerControlEdge,
  getDesignerDataEdge,
  handleEdgeConnections,
  updateLinkedSchemas
} from "../Util/EdgeUtil";
import { BaseInputOutput, DataType, getDataType } from "../NodeTypes/TypeDefinitions";
import { FlowInputManagementController, IFlowInputProperties } from "../FlowInputManagement";
import { IControlFlowParam, IDataParam, IDataTypeOptions, INodeProperty, INodeTypeDefinition } from "../NodeTypes/Base";
import {
  IDesignerSchema,
  emptyObjectSchemaId,
  getChildSchemaIdsRecursive,
  getDataTypeFromSchema,
  getEmptyObjectDocumentSchema,
  getEmptySchemaId,
  getListOfEmptyObjectDocumentSchema,
  getPrimitiveSchemas,
  getSchemas,
  listOfEmptyObjectSchemaId,
  setSchemas,
  updateSchemaSources,
  getSchemaSource
} from "../NodeTypes/DataSchemas";
import { createNewNodeMeta } from "../DesignerContainer/DesignerContainer";
import { AnchorProperties, handleAnchorDestinationLayoutChanged, handleAnchorNodeDrop } from "../Util/AnchorUtil";
import { NodeConfigPropertyType, writesDocument } from "../FlowDocument/PropertyTypeDefinitions";
import { BannerStatus, IBannerContext } from "@plex/react-components";
import { getStandardObjectSchemaIds } from "../NodeTypes/SchemaSystem";

export const upperSnakeToCamelCase = (str: string) =>
  str.toLowerCase().replace(/_[a-z]/g, (letter) => `${letter.replace("_", "").toUpperCase()}`);

export const upperSnakeToReadable = (str: string) => {
  const spaced = str.toLowerCase().replace(/_[a-z0-9]/g, (letter) => `${letter.replace("_", " ").toUpperCase()}`);
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
};

export const camelToUpperSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();

interface IConvertToDocumentContext {
  reactFlowInstance: ReactFlowInstance;
  outDocumentNodeConnections: IDocumentNodeConnection[];
  outReferencedSchemas: string[];
}

interface ILoadDocumentContext {
  documentNodeConnections: IDocumentNodeConnection[];
  outDesignerEdges: Connection[];
}

export const convertToDocument = (
  designerFlowInputs: IFlowInputProperties[],
  reactFlowInstance: ReactFlowInstance
): IFlowDocumentModel => {
  const documentSchemas: IDocumentSchema[] = getSchemas().map((designerSchema: IDesignerSchema) => {
    var schema = { ...designerSchema };
    delete (schema as any).source;
    return schema;
  });
  let designerNodes: Node<any>[] = reactFlowInstance.getNodes();
  let documentNodeConnections: IDocumentNodeConnection[] = [];

  const referencedSchemaIds: string[] = [];
  const documentNodes: IDocumentNode[] = designerNodes.map((designerNode: Node<any>) =>
    convertToDocumentNode(designerNode, {
      reactFlowInstance: reactFlowInstance,
      outDocumentNodeConnections: documentNodeConnections,
      outReferencedSchemas: referencedSchemaIds
    })
  );

  const documentFlowInputs = designerFlowInputs.map<IDocumentFlowInput>((input: IFlowInputProperties) => {
    return {
      name: input.propertyName,
      schema: input.propertyType,
      required: false
    };
  });

  const documentFlowOutputs: IDocumentFlowOutput[] = getDocumentOutputs(reactFlowInstance);

  const standardObjectNodes = reactFlowInstance
    .getNodes()
    .filter(
      (n: Node<any>) =>
        globalThis.nodeTypeDefinitions
          .getDefinition(n.type!)
          ?.nodeConfigProperties.some((p: INodeProperty) => p.propertyType === NodeConfigPropertyType.StandardObject)
    );

  standardObjectNodes.forEach((n: Node<any>) =>
    getStandardObjectSchemaIds(n).forEach((schemaId: string) => referencedSchemaIds.push(schemaId))
  );

  const referencedDocumentSchemas = documentSchemas
    .filter((schema: IDocumentSchema) => {
      const isReferenced = referencedSchemaIds.includes(schema.id);
      return isReferenced;
    })
    .sort((a: IDocumentSchema, b: IDocumentSchema) => (a.id < b.id ? -1 : 1));

  return {
    inputs: documentFlowInputs,
    outputs: documentFlowOutputs,
    nodes: documentNodes,
    connections: documentNodeConnections,
    schemas: referencedDocumentSchemas
  };
};

const convertToDocumentNode = (designerNode: Node<any>, context: IConvertToDocumentContext): IDocumentNode => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(designerNode.type!)!;

  let documentNode: IDocumentNode = {
    id: designerNode.id,
    nodeType: camelToUpperSnakeCase(designerNode.type!),
    name: designerNode.data.nodeProperties.name,
    designerProperties: {
      position: { x: designerNode.position.x, y: designerNode.position.y },
      dockedNodeIds:
        designerNode.data.nodeProperties.anchoredNodes?.map(
          (anchorProperties: AnchorProperties) => anchorProperties.nodeId
        ) ?? []
    }
  };

  nodeDefinition.controlInputs.forEach((controlInput: IControlFlowParam) => {
    (documentNode as any)[controlInput.name] = { name: controlInput.name } as IDocumentControlInput;
  });

  const controlOutConnections = getDocumentControlOutputs(documentNode, nodeDefinition, context.reactFlowInstance);
  controlOutConnections.forEach((connection: IDocumentNodeConnection) =>
    context.outDocumentNodeConnections.push(connection)
  );

  const configProperties = getDocumentConfigProperties(designerNode, nodeDefinition);
  Object.keys(configProperties).forEach((propName: string) => {
    (documentNode as any)[propName] = configProperties[propName];
  });

  const documentDataInputs = getDocumentDataInputs(designerNode, nodeDefinition, context);
  Object.keys(documentDataInputs).forEach((inputName: string) => {
    (documentNode as any)[inputName] = documentDataInputs[inputName];
  });

  const documentDataOutputs = getDocumentDataOutputs(designerNode, nodeDefinition, context);
  Object.keys(documentDataOutputs).forEach((outputName: string) => {
    (documentNode as any)[outputName] = documentDataOutputs[outputName];
  });

  return documentNode;
};

const getDocumentOutputs = (reactFlowInstance: ReactFlowInstance): IDocumentFlowOutput[] => {
  const stopNodes = reactFlowInstance.getNodes().filter((n) => n.type === "stop");
  const documentFlowOutputs: IDocumentFlowOutput[] = [];
  stopNodes.forEach((n: Node<any>) => {
    Object.keys(n.data.nodeProperties.inputs).forEach((inputName: string) => {
      const input: BaseInputOutput = n.data.nodeProperties.inputs[inputName];
      if (documentFlowOutputs.filter((o) => o.name === input.name).length === 0) {
        documentFlowOutputs.push({ name: input.name, schema: input.type });
      }
    });
  });
  return documentFlowOutputs;
};

const getDocumentControlOutputs = (
  documentNode: IDocumentNode,
  nodeDefinition: INodeTypeDefinition,
  reactFlowInstance: ReactFlowInstance
) => {
  const documentNodeConnections: IDocumentNodeConnection[] = [];

  if (nodeDefinition.controlOutputOnly) {
    const controlOutName = nodeDefinition.controlOutputs[0]!.name;
    (documentNode as any)[controlOutName] = {
      name: controlOutName
    } as IDocumentControlOutput;

    const edgeDetail = new EdgeDetail(documentNode.id, reactFlowInstance);
    const controlEdge = edgeDetail.getOutput("FlowOutHandle" + controlOutName);
    if (controlEdge) {
      documentNodeConnections.push(getDocumentNodeControlConnection(controlEdge));
    }
  }

  if (nodeDefinition.controlOutputs.length > 0) {
    nodeDefinition.controlOutputs.forEach((controlOutput) => {
      (documentNode as any)[controlOutput.name] = {
        name: controlOutput.name
      } as IDocumentControlInput;
      const edgeDetail = new EdgeDetail(documentNode.id, reactFlowInstance);
      const controlEdge = edgeDetail.getOutput("FlowOutHandle" + controlOutput.name);
      if (controlEdge && !nodeDefinition.controlOutputOnly) {
        documentNodeConnections.push(getDocumentNodeControlConnection(controlEdge));
      }
    });
  }

  return documentNodeConnections;
};

const getDocumentConfigProperties = (designerNode: Node<any>, nodeDefinition: INodeTypeDefinition) => {
  const configProperties: any = {};

  nodeDefinition.nodeConfigProperties.forEach((nodeProperty) => {
    if (writesDocument(nodeProperty.propertyType)) {
      const designerNodeProperty = designerNode.data.nodeProperties[nodeProperty.name];
      if (
        designerNodeProperty?.length === 1 &&
        Object.keys(designerNodeProperty[0]).length === 2 &&
        designerNodeProperty[0].key !== undefined &&
        designerNodeProperty[0].value !== undefined
      ) {
        configProperties[nodeProperty.name] = designerNodeProperty[0].key;
      } else {
        configProperties[nodeProperty.name] = designerNodeProperty;
      }
    }
  });

  return configProperties;
};

const getDocumentDataInputs = (
  designerNode: Node<any>,
  nodeDefinition: INodeTypeDefinition,
  context: IConvertToDocumentContext
) => {
  const dataInputs: any = {};

  if (nodeDefinition.documentDataInputListName) {
    dataInputs[nodeDefinition.documentDataInputListName] = [];
  }

  Object.keys(designerNode.data.nodeProperties.inputs).forEach((inputName: any) => {
    const input: BaseInputOutput = designerNode.data.nodeProperties.inputs[inputName];
    input.name = inputName;
    const edgeDetail = new EdgeDetail(designerNode.id, context.reactFlowInstance).getInput(inputName);

    if (edgeDetail) {
      context.outDocumentNodeConnections.push(getDocumentNodeDataConnection(edgeDetail));
    }

    const documentInput = getDocumentNodeInput(input);
    getChildSchemaIdsRecursive(documentInput.schema, getSchemas(), context.outReferencedSchemas);
    if (nodeDefinition.documentDataInputListName) {
      dataInputs[nodeDefinition.documentDataInputListName].push(documentInput);
    } else {
      dataInputs[inputName] = documentInput;
    }
  });

  const schemaSource = getSchemaSource(designerNode);
  if (schemaSource) {
    getSchemas()
      .filter((schema) => schema.source)
      .filter(
        (schema) =>
          schema.source.sourceId === schemaSource.sourceId && schema.source.sourceSystem === schemaSource.sourceSystem
      )
      .forEach((schema) => {
        if (!context.outReferencedSchemas.includes(schema.id)) {
          context.outReferencedSchemas.push(schema.id);
        }
      });
  }

  return dataInputs;
};

const getDocumentDataOutputs = (
  designerNode: Node<any>,
  nodeDefinition: INodeTypeDefinition,
  context: IConvertToDocumentContext
) => {
  const dataOutputs: any = {};

  if (nodeDefinition.documentDataOutputList) {
    dataOutputs[nodeDefinition.documentDataOutputList] = [];
  }

  Object.keys(designerNode.data.nodeProperties.outputs).forEach((outputName: any) => {
    const output = designerNode.data.nodeProperties.outputs[outputName];

    output.name = outputName;
    const documentOutput = getDocumentNodeOutput(designerNode, output);
    getChildSchemaIdsRecursive(documentOutput.schema, getSchemas(), context.outReferencedSchemas);
    if (nodeDefinition.documentDataOutputList) {
      dataOutputs[nodeDefinition.documentDataOutputList].push(documentOutput);
    } else {
      dataOutputs[outputName] = documentOutput;
    }
  });

  return dataOutputs;
};

const extractAndRemoveNodesWithoutDefinition = (documentModel: IFlowDocumentModel): any[] => {
  const nodesMissingDefinition: any[] = [];

  documentModel.nodes = documentModel.nodes.filter((node) => {
    if (!globalThis.nodeTypeDefinitions.getDefinition(upperSnakeToCamelCase(node.nodeType))) {
      nodesMissingDefinition.push(node);
      return false;
    }
    return true;
  });

  documentModel.connections = documentModel.connections.filter((connection) => {
    return !nodesMissingDefinition.some((node) => node.id === connection.sourceId || node.id === connection.targetId);
  });

  return nodesMissingDefinition;
};

export const verifyAndLoadDocument = (
  documentModel: IFlowDocumentModel,
  designerInputController: FlowInputManagementController,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  showCanvas: () => void,
  hideCanvas: () => void,
  bannerContext: IBannerContext
): void => {
  const nodesMissingDefinition = extractAndRemoveNodesWithoutDefinition(documentModel);

  loadDocument(documentModel, designerInputController, reactFlowInstance, updateNodeInternals, showCanvas, hideCanvas);

  if (nodesMissingDefinition && nodesMissingDefinition.length > 0) {
    const missingNodeTypes = Array.from(new Set(nodesMissingDefinition.map((node) => node.nodeType)));
    const missingNodeTypesErrorString = missingNodeTypes.join(`, `);

    bannerContext.addMessage(
      `Some nodes could not be loaded as they are not supported in the current designer version. These nodes and any direct connections have been removed. Types:  ${missingNodeTypesErrorString}`,
      BannerStatus.error
    );

    nodesMissingDefinition.forEach((node) => {
      console.warn(
        `Could not load node '${node.id}'  due to unsupported node type '${node.nodeType}'. The node and its connections have been removed.`
      );
    });
  }
};

export const loadDocument = (
  documentModel: IFlowDocumentModel,
  designerInputController: FlowInputManagementController,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  showCanvas: () => void,
  hideCanvas: () => void,
  onComplete?: () => void
): void => {
  hideCanvas();
  const designerEdges: Connection[] = [];

  const missingPrimitives = getPrimitiveSchemas().filter(
    (primitiveSchema: IDocumentSchema) =>
      !documentModel.schemas.some((documentSchema: IDocumentSchema) => documentSchema.id === primitiveSchema.id)
  );
  const loadingSchemas = documentModel.schemas.concat(missingPrimitives);
  if (!loadingSchemas.some((schema: IDocumentSchema) => schema.id === emptyObjectSchemaId)) {
    loadingSchemas.push(getEmptyObjectDocumentSchema());
  }
  if (!loadingSchemas.some((schema: IDocumentSchema) => schema.id === listOfEmptyObjectSchemaId)) {
    loadingSchemas.push(getListOfEmptyObjectDocumentSchema());
  }
  setSchemas(loadingSchemas);

  const designerFlowInputs: IFlowInputProperties[] = documentModel.inputs.map((documentInput: IDocumentFlowInput) => {
    return {
      propertyName: documentInput.name,
      propertyType: documentInput.schema,
      propertyValue: ""
    };
  });

  designerInputController.intialize(designerFlowInputs);

  const designerNodes: Node<any>[] = documentModel.nodes.map((documentNode: IDocumentNode) =>
    getDesignerNode(documentModel, documentNode, {
      documentNodeConnections: documentModel.connections,
      outDesignerEdges: designerEdges
    })
  );

  updateSchemaSources(designerNodes);

  updateLinkedSchemas(designerNodes, designerEdges, updateNodeInternals, reactFlowInstance);

  initReactFlow(documentModel, designerNodes, designerEdges, reactFlowInstance, updateNodeInternals, () => {
    showCanvas();
    if (onComplete) {
      onComplete();
    }
  });
};

const getDesignerNode = (
  documentModel: IFlowDocumentModel,
  documentNode: IDocumentNode,
  context: ILoadDocumentContext
) => {
  const designerNodeType = upperSnakeToCamelCase(documentNode.nodeType);
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(designerNodeType)!;

  const designerNode = createNewNodeMeta(
    designerNodeType,
    null,
    null,
    null,
    null,
    null,
    documentNode.designerProperties.position.x,
    documentNode.designerProperties.position.y,
    documentNode.id
  );

  designerNode.data.nodeProperties.name = documentNode.name;

  createDesignerControlEdges(designerNode, nodeDefinition, context);

  const designerDataInputs = getDesignerDataInputs(documentNode, nodeDefinition);
  designerDataInputs.forEach((dataInput: IDataParam) => {
    addDesignerDataInput(documentModel, dataInput, documentNode, designerNode, nodeDefinition, context);
  });

  const designerDataOutputs = getDesignerDataOutputs(documentNode, nodeDefinition);
  designerDataOutputs.forEach((dataOutput: IDataParam) => {
    addDesignerDataOutput(dataOutput, documentNode, designerNode, nodeDefinition);
  });

  nodeDefinition.nodeConfigProperties.forEach((property: INodeProperty) => {
    if (!designerNode.data.nodeProperties[property.name]) {
      designerNode.data.nodeProperties[property.name] = (documentNode as any)[property.name];
    }
  });

  return designerNode;
};

const getDesignerDataInputs = (documentNode: IDocumentNode, nodeDefinition: INodeTypeDefinition) => {
  let designerDataInputs: IDataParam[];
  const dynamicTypedInputProperties = nodeDefinition.nodeConfigProperties.filter(
    (prop: INodeProperty) =>
      prop.propertyType === NodeConfigPropertyType.DataType &&
      (prop.options as IDataTypeOptions).dataProperties.some((inputName: string) =>
        nodeDefinition.dataInputs.some((dataInput: IDataParam) => dataInput.name === inputName)
      )
  );
  const inputsMatchNodeDefinition =
    !nodeDefinition.documentDataInputListName && dynamicTypedInputProperties.length === 0;
  if (inputsMatchNodeDefinition) {
    designerDataInputs = nodeDefinition.dataInputs;
  } else {
    // Inputs may differ from node definition, so build completely from document inputs.
    let documentDataInputs: IDocumentDataInput[] = [];

    if (dynamicTypedInputProperties.length > 0) {
      documentDataInputs = [];
      dynamicTypedInputProperties.forEach((nodeProperty: INodeProperty) => {
        (nodeProperty.options as IDataTypeOptions).dataProperties.forEach((inputName: string) => {
          const definedInput = nodeDefinition.dataInputs.find((i: IDataParam) => i.name === inputName);
          if (definedInput) {
            documentDataInputs.push({ ...documentNode[inputName], hideLabel: definedInput.hideLabel });
          }
        });
      });
    }
    let hideAllInputLabels: boolean | undefined;
    if (nodeDefinition.documentDataInputListName) {
      // The first input hide label flag applies to all inputs, if the inputs are a list of inputs.
      hideAllInputLabels = nodeDefinition.dataInputs[0]?.hideLabel;
      documentDataInputs = (documentNode as any)[nodeDefinition.documentDataInputListName];
    } else {
      // Add any other inputs that may not be dynamic typed.
      const otherDefinedInputNames = nodeDefinition.dataInputs
        .filter(
          (definitionDataInput: IDataParam) =>
            !documentDataInputs.some(
              (documentInput: IDocumentDataInput) => documentInput.name === definitionDataInput.name
            )
        )
        .map((definedInput: IDataParam) => definedInput.name);
      documentDataInputs = documentDataInputs.concat(
        otherDefinedInputNames.map((inputName: string) => documentNode[inputName])
      );
    }

    designerDataInputs = documentDataInputs.map((documentDataInput: IDocumentDataInput) => {
      return {
        name: documentDataInput.name ?? "",
        type: getDataTypeFromSchema(documentDataInput.schema),
        schemaId: documentDataInput.schema,
        required: documentDataInput.required,
        enabled: documentDataInput.designerProperties.enabled,
        hideLabel: hideAllInputLabels || (documentDataInput as any).hideLabel
      } as IDataParam;
    });
  }

  return designerDataInputs;
};

const getDesignerDataOutputs = (documentNode: IDocumentNode, nodeDefinition: INodeTypeDefinition) => {
  let designerDataOutputs: IDataParam[];
  const dynamicTypedOutputProperties = nodeDefinition.nodeConfigProperties.filter(
    (prop: INodeProperty) =>
      prop.propertyType === NodeConfigPropertyType.DataType &&
      (prop.options as IDataTypeOptions).dataProperties.some((outputName: string) =>
        nodeDefinition.dataOutputs.some((dataOutput: IDataParam) => dataOutput.name === outputName)
      )
  );
  const outputsMatchNodeDefinition =
    !nodeDefinition.documentDataOutputList && dynamicTypedOutputProperties.length === 0;
  if (outputsMatchNodeDefinition) {
    designerDataOutputs = nodeDefinition.dataOutputs.map((designerOutput: IDataParam) => {
      return {
        ...designerOutput,
        schemaId: ((documentNode as any)[designerOutput.name] as unknown as IDocumentDataOutput)?.schema
      };
    });
  } else {
    let hideAllOutputLabels: boolean | undefined;
    let documentDataOutputs: IDocumentDataOutput[];
    if (nodeDefinition.documentDataOutputList) {
      // The first output hide label flag applies to all outputs, if the outputs are a list of outputs.
      hideAllOutputLabels = nodeDefinition.dataOutputs[0]?.hideLabel;
      documentDataOutputs = Object.keys((documentNode as any)[nodeDefinition.documentDataOutputList!]).map(
        (outputName: string) => (documentNode as any)[nodeDefinition.documentDataOutputList!][outputName]
      );
    } else {
      documentDataOutputs = (documentNode as any).outputs;
    }

    if (dynamicTypedOutputProperties.length > 0) {
      documentDataOutputs = [];
      dynamicTypedOutputProperties.forEach((nodeProperty: INodeProperty) => {
        (nodeProperty.options as IDataTypeOptions).dataProperties.forEach((outputName: string) => {
          const definedOutput = nodeDefinition.dataOutputs.find((i: IDataParam) => i.name === outputName);
          if (definedOutput) {
            documentDataOutputs.push({ ...documentNode[outputName], hideLabel: definedOutput.hideLabel });
          }
        });
      });

      // Add any other outputs that may not be dynamic typed.
      const otherDefinedOutputNames = nodeDefinition.dataOutputs
        .filter(
          (definitionDataOutput: IDataParam) =>
            !documentDataOutputs.some(
              (documentOutput: IDocumentDataOutput) => documentOutput.name === definitionDataOutput.name
            )
        )
        .map((definedOutput: IDataParam) => definedOutput.name);
      documentDataOutputs = documentDataOutputs.concat(
        otherDefinedOutputNames.map((outputName: string) => documentNode[outputName])
      );
    }

    designerDataOutputs = documentDataOutputs.map((documentDataOutput: IDocumentDataOutput) => {
      return {
        name: documentDataOutput.name ?? "",
        type: getDataTypeFromSchema(documentDataOutput.schema),
        schemaId: documentDataOutput.schema,
        required: false,
        enabled: documentDataOutput.designerProperties.enabled,
        hideLabel: hideAllOutputLabels || (documentDataOutput as any).hideLabel
      } as IDataParam;
    });
  }

  return designerDataOutputs;
};

const addDesignerDataInput = (
  documentModel: IFlowDocumentModel,
  dataInput: IDataParam,
  documentNode: IDocumentNode,
  designerNode: Node<any>,
  nodeDefinition: INodeTypeDefinition,
  context: ILoadDocumentContext
) => {
  let documentInput: IDocumentDataInput;
  if (nodeDefinition.documentDataInputListName) {
    documentInput = (documentNode as any)[nodeDefinition.documentDataInputListName].find(
      (i: IDocumentDataInput) => i.name === dataInput.name
    );
  } else {
    documentInput = (documentNode as any)[dataInput.name];
  }

  designerNode.data.nodeProperties.inputs[dataInput.name] = {
    type: dataInput.type,
    schemaId: documentInput.schema,
    required: documentInput.required,
    enabled: documentInput.designerProperties.enabled,
    hideLabel: dataInput.hideLabel,
    label: documentInput.label
  };

  const connection = context.documentNodeConnections.find(
    (conn: IDocumentNodeConnection) => conn.targetId === designerNode.id && conn.targetName === dataInput.name
  );
  if (connection) {
    let documentSourceNodeOutputListName: string | undefined = undefined;
    const sourceDocumentNode = documentModel.nodes.find((n: IDocumentNode) => n.id === connection.sourceId);
    if (sourceDocumentNode) {
      const sourceNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(
        upperSnakeToCamelCase(sourceDocumentNode.nodeType)
      );
      documentSourceNodeOutputListName = sourceNodeDefinition?.documentDataOutputList;
    }

    const connectedDocumentNode = documentModel.nodes.find((n: IDocumentNode) => n.id === connection.sourceId);
    let outputType: DataType = dataInput.type;
    let documentOutput = (connectedDocumentNode as any)[connection.sourceName];
    if (documentSourceNodeOutputListName) {
      documentOutput = (connectedDocumentNode as any)[documentSourceNodeOutputListName]?.find(
        (o: IDocumentDataOutput) => o.name === connection.sourceName
      );
    }
    if (documentOutput) {
      const connectedOutputType = getDataType(getDataTypeFromSchema(documentOutput?.schema))!;
      if (connectedOutputType) {
        outputType = connectedOutputType;
      }
    }

    context.outDesignerEdges.push(
      getDesignerDataEdge(dataInput, outputType, documentNode.id, connection.sourceName, connection.sourceId) as any
    );
  }
};

const addDesignerDataOutput = (
  dataOutput: IDataParam,
  documentNode: IDocumentNode,
  designerNode: Node<any>,
  nodeDefinition: INodeTypeDefinition
) => {
  let documentOutput: IDocumentDataOutput;
  if (nodeDefinition.documentDataOutputList) {
    let dynamicOutputs = (documentNode as any)[nodeDefinition.documentDataOutputList];
    documentOutput = dynamicOutputs.find((o: IDocumentDataOutput) => o.name === dataOutput.name);
  } else {
    documentOutput = (documentNode as any)[dataOutput.name];
  }

  designerNode.data.nodeProperties.outputs[dataOutput.name] = {
    type: dataOutput.type,
    schemaId: dataOutput.schemaId,
    enabled: documentOutput?.designerProperties?.enabled ?? false,
    hideLabel: dataOutput.hideLabel,
    label: documentOutput?.label
  };
};

const createDesignerControlEdges = (
  designerNode: Node<any>,
  nodeDefinition: INodeTypeDefinition,
  context: ILoadDocumentContext
) => {
  const hasControlOutput = nodeDefinition.controlOutputOnly || nodeDefinition.controlOutputs.length > 0;
  if (hasControlOutput) {
    nodeDefinition.controlOutputs.forEach((output: IControlFlowParam) => {
      const connection = context.documentNodeConnections.find(
        (conn: IDocumentNodeConnection) => conn.sourceId === designerNode.id && conn.sourceName === output.name
      );
      if (connection) {
        context.outDesignerEdges.push(
          getDesignerControlEdge(
            connection.targetName,
            connection.targetId,
            connection.sourceName,
            connection.sourceId
          ) as any
        );
      }
    });
  }
};

const initReactFlow = (
  documentModel: IFlowDocumentModel,
  designerNodes: Node<any>[],
  designerEdges: Connection[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  onComplete?: () => void
) => {
  reactFlowInstance.setNodes([]);
  reactFlowInstance.setEdges([]);

  const resetCanvasInterval = setInterval(() => {
    if (reactFlowInstance.getNodes().length === 0 && reactFlowInstance.getEdges().length === 0) {
      clearInterval(resetCanvasInterval);
      setTimeout(() => {
        reactFlowInstance.setNodes(designerNodes);

        const setNodesInterval = setInterval(() => {
          if (reactFlowInstance.getNodes().length > 0 || designerNodes.length === 0) {
            const viewport = reactFlowInstance.getViewport();
            reactFlowInstance.fitView({
              nodes: designerNodes,
              minZoom: viewport.zoom,
              maxZoom: viewport.zoom
            });

            clearInterval(setNodesInterval);
            setTimeout(() => {
              handleEdgeConnections(designerEdges, true);
              designerNodes.forEach((n: Node<any>) => (n.position.x -= 0.0001 * Math.random()));

              const handleConnectionsInterval = setInterval(() => {
                if (reactFlowInstance.getEdges().length > 0 || designerEdges.length === 0) {
                  clearInterval(handleConnectionsInterval);
                  setTimeout(() => {
                    const updateNodes: Node<any>[] = reactFlowInstance.getNodes();
                    updateNodes.forEach((n: Node<any>) => {
                      n.position.x += 0.0001 * Math.random();
                      updateNodeInternals(n.id);
                    });
                    reactFlowInstance.setNodes(updateNodes);
                    let latestDesignerEdges = reactFlowInstance.getEdges();
                    designerNodes.forEach((n: Node<any>) => {
                      const dockParent = documentModel.nodes.find((pn: IDocumentNode) =>
                        pn.designerProperties.dockedNodeIds.some((dockedNodeId: string) => dockedNodeId === n.id)
                      );
                      if (dockParent) {
                        const designerDockParent = designerNodes.find((n: Node<any>) => n.id === dockParent.id);
                        n.position = { ...dockParent.designerProperties.position };
                        latestDesignerEdges = handleAnchorNodeDrop(
                          n,
                          latestDesignerEdges,
                          reactFlowInstance,
                          updateNodeInternals,
                          false,
                          designerDockParent
                        );
                        handleAnchorDestinationLayoutChanged(n.id, reactFlowInstance, updateNodeInternals);
                      }
                    });
                    setTimeout(() => {
                      const updateNodes: Node<any>[] = reactFlowInstance.getNodes();
                      updateNodes.forEach((n: Node<any>) => {
                        handleAnchorDestinationLayoutChanged(n.id, reactFlowInstance, updateNodeInternals);
                        n.position.x -= 0.0001 * Math.random();
                        updateNodeInternals(n.id);
                      });

                      setTimeout(() => {
                        updateNodes.forEach((n: Node<any>) => {
                          const documentNode = documentModel.nodes.find((dn: IDocumentNode) => dn.id === n.id);
                          n.position.x = documentNode?.designerProperties.position.x ?? 0;

                          if (!n.data.nodeProperties.lastPosition) {
                            n.data.nodeProperties.lastPosition = { x: 0, y: 0 };
                          }
                          n.data.nodeProperties.lastPosition.x = n.position.x;
                          n.data.nodeProperties.lastPosition.y = n.position.y;

                          updateNodeInternals(n.id);
                        });
                      }, 600);

                      reactFlowInstance.setEdges(latestDesignerEdges);

                      if (onComplete) {
                        onComplete();
                      }
                    }, 100);
                  }, 30);
                }
              }, 20);
            }, 30);
          }
        }, 20);
      }, 30);
    }
  }, 20);
};

const getDocumentNodeInput = (designerInput: BaseInputOutput): IDocumentDataInput => {
  const schemaId = designerInput.schemaId;
  const label = designerInput.label;
  return {
    name: designerInput.name,
    schema: schemaId ?? getEmptySchemaId(designerInput.type),
    required: designerInput.required === true ?? false,
    designerProperties: { enabled: designerInput.enabled === true ?? false },
    ...(label && { label })
  };
};

const getDocumentNodeOutput = (designerNode: Node<any>, designerOutput: BaseInputOutput): IDocumentDataOutput => {
  const schemaId = designerOutput.schemaId;
  const label = designerOutput.label;
  return {
    name: designerOutput.name,
    schema: schemaId ?? getEmptySchemaId(designerOutput.type),
    designerProperties: { enabled: designerOutput.enabled === true ?? false },
    ...(label && { label })
  };
};

const getDocumentNodeDataConnection = (edge: IEdgeInfo) => {
  return {
    type: DocumentNodeConnectionType.data,
    sourceId: edge!.targetHandle.node.id,
    sourceName: edge!.targetHandle.name,
    targetId: edge!.sourceHandle.node.id,
    targetName: edge!.sourceHandle.name
  };
};

const getDocumentNodeControlConnection = (edge: IEdgeInfo) => {
  let sourceName = edge!.targetHandle.name.replace("FlowOutHandle", "");
  let targetName = edge!.sourceHandle.name.replace("FlowInHandle", "");

  if (sourceName.length === 0) {
    sourceName = "control";
  }

  if (targetName.length === 0) {
    targetName = "control";
  }

  return {
    type: DocumentNodeConnectionType.control,
    sourceId: edge!.targetHandle.node.id,
    sourceName: sourceName,
    targetId: edge!.sourceHandle.node.id,
    targetName: targetName
  };
};
