import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { isPromise } from "@utils";
import { GridState, DataTablePluginFactory, GridAction, DataTablePluginComponentProps } from "../DataTable.types";
import { useDataTableDispatcher } from "../DataTableStateProvider";
import { load, loading, handleLoad, handleLoading } from "./DataLoader.actions";

export const LOADER_PLUGIN = "Loader";

// eslint-disable-next-line func-style
function DataLoaderRender<T = unknown>({
  data,
  keySelector,
  children
}: PropsWithChildren<DataTablePluginComponentProps<T>>) {
  const dispatch = useDataTableDispatcher<T>();
  const keySelectorRef = useRef(keySelector);

  useEffect(() => {
    if (isPromise<T[]>(data)) {
      dispatch(loading());
      data.then(
        results => dispatch(load({ data: results, keySelector: keySelectorRef.current })),
        () => dispatch(load({ data: [], keySelector: keySelectorRef.current }))
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      dispatch(load({ data: data || [], keySelector: keySelectorRef.current }));
    }
  }, [dispatch, data]);

  return children;
}

export const DataLoader = <T>() => {
  const factory: DataTablePluginFactory<T> = () => {
    return {
      component: DataLoaderRender as FC<DataTablePluginComponentProps<T>>,

      reducer: (state: GridState<T>, action: GridAction<T>) => {
        switch (action.type) {
          case "DataTable/load":
            return handleLoad<T>(state, action);

          case "DataTable/loading":
            return handleLoading(state, action);

          default:
            return state;
        }
      }
    };
  };

  factory.pluginName = LOADER_PLUGIN;
  return factory;
};
