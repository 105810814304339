import React, { useCallback, ReactNode } from "react";
import clsx from "clsx";
import { Checkbox } from "@components/Checkbox";
import { Image } from "@components/Image";
import { LocalDate } from "js-joda";
import { useLocalization } from "@plex/culture-react";
import { ICalendarDayData } from "@components/Calendar/Calendar.types";
import styles from "./Calendar.module.scss";

export interface ICalendarDayInterface {
  /**
   * Date of cell
   */
  date: LocalDate;
  /**
   * Icon path if is image
   */
  iconPath?: string;
  /**
   * Any additional data that can be used in component. Will be a parameter for custom template
   */
  data?: ICalendarDayData;
  /**
   * A set of overlays to be applied to the cell. Most likely you're going to use @see customTemplate to have control over entire rendering
   */
  overlays?: ReactNode[];
  /**
   * Renders image inside a cell, image src should be provided
   */
  isImage?: boolean;
  /**
   * Whether component disabled
   */
  disabled?: boolean;
  /**
   * Indicated if component is selected
   */
  selected?: boolean;
  /**
   * Callback on select components.
   */
  onSelected?: (par: { date: LocalDate; selected: boolean }) => void;
  /**
   * Indicates whether component is allowed to be selected
   */
  selectable?: boolean;
  /**
   * On click callback
   */
  onClick?: (date: LocalDate) => {};
  /**
   * A custom template to allow consumer display any additional data.
   * @example:
   * // render a list of events withing single day
   * const customTemplate = data => {
   *  return <span>I'm a unique content </span>
   * }
   * <CalendarDay customTemplate={customTemplate} />
   */
  customTemplate?: (data: LocalDate) => React.ReactElement;
}

export const CalendarDay = React.forwardRef<HTMLDivElement, ICalendarDayInterface>(
  (
    { date, iconPath, overlays, isImage, selected, disabled, onSelected, selectable, onClick, data, customTemplate },
    ref
  ) => {
    const { locale } = useLocalization();
    const day = date.dayOfMonth();
    const monthName = locale.dates.getMonthNames()[date.monthValue() - 1];
    const showMonthName = day === 1 || day === date.lengthOfMonth();

    const renderImage = useCallback(() => {
      if (!selected) {
        return null;
      }

      return (
        <div className={clsx(styles.calendarElements, styles.calendarImage)}>
          {iconPath && <Image src={iconPath} />}
        </div>
      );
    }, [selected, iconPath]);

    const renderElements = useCallback(() => {
      const hack = data?.date;

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return <div className={styles.calendarElements}>{customTemplate?.(date || hack)}</div>;
    }, [data, date, customTemplate]);

    const renderOverlays = useCallback(() => {
      return (
        <div className={clsx(styles.overlayContainer, selected && styles.selected)}>
          {overlays?.map(overlay => {
            return <div className="calendar-overlay">{overlay}</div>;
          })}
        </div>
      );
    }, [selected, overlays]);

    const onClickHandler = useCallback(() => {
      if (disabled) {
        return;
      }

      onClick?.(date);
      if (!selectable) {
        return;
      }
      onSelected?.({ date, selected: !selected });
    }, [disabled, onClick, date, selectable, onSelected, selected]);

    return (
      <div ref={ref} onClick={onClickHandler}>
        {renderOverlays()}
        {isImage && renderImage()}
        {data && renderElements()}
        <div>
          {showMonthName && <span>{monthName}</span>}
          <span>{day}</span>
        </div>
        {selectable && <Checkbox checked={selected} />}
      </div>
    );
  }
);

CalendarDay.displayName = "CalendarDay";
