import React, { FunctionComponent } from "react";
import { useLocalization } from "@plex/culture-react";
import { Button } from "../Button";
import styles from "./Dialog.module.scss";
import { Dialog } from "./Dialog";

export interface IConfirmDialogProps {
  /** Indicates whether the dialog should be rendered */
  show: boolean;
  /** The dialog title */
  title?: string;
  /** Question text */
  question: string;
  /** Possible answers */
  answers: string[];
  /** Default answer */
  defaultAnswer: string;
  /** Callback executed when the dialog is being closed */
  onHide: (answer: string) => void;
}

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = ({
  show,
  title,
  question,
  answers,
  defaultAnswer,
  onHide
}) => {
  const { t } = useLocalization();
  const minWidth = 360;
  const minHeight = 215;

  const onCloseHandler = () => {
    onHide(defaultAnswer);
  };

  const onButtonSelect = (value: string) => {
    onHide(value);
  };

  return (
    <Dialog
      data-testid="plex-confirm-modal"
      closeButton
      show={show}
      minHeight={minHeight}
      minWidth={minWidth}
      title={title || t("ui-common-dialog-title-question")}
      backdrop="static"
      onHide={onCloseHandler}
    >
      <Dialog.Body>
        <div className={styles.notificationContent}>
          <span className={styles.notificationMessage} data-testid="plex-confirm-question">
            {question}
          </span>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <div className={styles.confirmFooterActions}>
          {answers.map(item => {
            return (
              <Button key={item} onClick={() => onButtonSelect(item)} data-testid="plex-confirm-button" size="sm">
                {item}
              </Button>
            );
          })}
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};
