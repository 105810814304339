import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../../../components/NodeTypes/Base";
import { DataType, primitiveTypes } from "../../../components/NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const listInputName = "list";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ListCountNode",
  label: "List Count",
  category: NodeTypeCategory.lists,
  controlOutputs: [],
  dataInputs: [{ name: listInputName, type: DataType.OBJECTLIST }],
  dataOutputs: [{ name: "count", type: DataType.INTEGER }],
  size: NodeSize.Small,
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataTypes: [DataType.OBJECT].concat(primitiveTypes), dataProperties: [listInputName] }
    }
  ],
  evaluate: (input: any) => {
    return { output: { count: input.list?.length ?? 0 }, activeControlHandleIds: [] };
  }
});

export const ListCountNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
