import React, { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { withNoPrinting } from "@plex/react-xml-renderer";
import { ActionSection } from "../ActionSection";
import { ICommonProps } from "../Common.types";
import styles from "./Form.module.scss";

export interface IFooterProps extends PropsWithChildren<ICommonProps> {
  /** Action Container Class Name */
  actionClassName?: string;
}

export const HtmlFormFooter: FC<IFooterProps> = ({ children, className, actionClassName, ...other }) => {
  return (
    <ActionSection
      className={clsx(styles.formActionContainer, className)}
      actionClassName={clsx(styles.formAction, actionClassName)}
      includeBorder
      data-testid="plex-form-footer"
      {...other}
    >
      {children}
    </ActionSection>
  );
};

export const FormFooter = withNoPrinting(HtmlFormFooter);
