import React, { useState, FunctionComponent, useEffect } from "react";
import { Form } from "@plex/react-components";
import { INodeTypeDefinition, NodeTypeCategory } from "../NodeTypes/Base";
import { useViewController } from "../ViewContext";
import "./NodeSelectionList.scss";

const nodeTypeCategories = Object.values(NodeTypeCategory).filter((c) => Number(c) >= 0 === false);

const nodeAllTypeCategories = [...nodeTypeCategories];

export const NodeSelectionList: FunctionComponent = () => {
  const { experimentalModeState } = useViewController();

  const [filteredNodeCategories, setFilteredNodeCategories] =
    useState<(string | NodeTypeCategory)[]>(nodeAllTypeCategories);

  const [filteredNodes, setFilteredNodes] = useState<(INodeTypeDefinition | undefined)[]>(
    globalThis.nodeTypeDefinitions.getDefinitions()
  );

  const createItem = (
    nodeType: string,
    displayName: string | null = null,
    category: string | null = null,
    api: string | null = null,
    apiAction: string | null = null,
    savedGroupName: string | null = null,
    baseNode: boolean | null = null,
    apiFamily: string | null = null,
    isExperimentalNode: boolean = false,
    index: number = 0
  ) => {
    if (displayName === null) {
      displayName = nodeType;
    }

    // Make the items in the categories Draggable
    return (
      <div
        key={"nodeList" + nodeType + (apiFamily ?? "") + index}
        data-testid={"nodeSelectionListItem" + nodeType}
        draggable
        className={`draggable plex-fd-nsl-node-list-item ${isExperimentalNode ? "experimental" : ""}`}
        onDragStart={(e) => {
          e.dataTransfer.setData("nodeType", nodeType);
        }}
      >
        <div className="plex-fd-nsl-node-contents-experimental-mode-off">
          <div
            key={"node-icon-" + index}
            className={
              baseNode
                ? "base-node-icon " + "plex-fd-nsl-node-list-item-icon " + nodeType + "-node-icon"
                : "node-list-item-" + nodeType.toLowerCase() + "-icon " + nodeType.toLowerCase() + "-node-icon"
            }
          />

          <div
            key={"node-display-" + index}
            className={`plex-fd-nsl-node-list-item-name ${displayName.length > 11 ? "multiline" : ""}`}
            title={displayName}
          >
            {displayName}
          </div>

          <div className="plex-fd-nsl-node-list-item-draggable-icon" />
        </div>

        {isExperimentalNode ? (
          <div key={"experiment-label-container"} className="plex-fd-nsl-experimental-label-container">
            <label key={"experiment-label"} className="plex-fd-nsl-experimental-label-text">
              {" "}
              **Experimental**{" "}
            </label>
          </div>
        ) : null}
      </div>
    );
  };

  const renderGeneralNodesSelection = (nodeComponent: INodeTypeDefinition, index: number): JSX.Element => {
    return createItem(
      nodeComponent.id,
      nodeComponent.label,
      null,
      null,
      null,
      null,
      true,
      null,
      nodeComponent.isExperimental,
      index
    );
  };

  const [searchText, setSearchText] = useState("");

  const searchDropdown = () => {
    // Filter nodes based on the current state of the text box
    const filteredNodes = globalThis.nodeTypeDefinitions.getDefinitions().filter((node) => {
      const labelIncludesFilter = node?.label.toUpperCase().includes(searchText.toUpperCase());
      if (!experimentalModeState) {
        return labelIncludesFilter && !node?.isExperimental;
      }
      return labelIncludesFilter;
    });

    setFilteredNodes(filteredNodes);

    const nodeCategories = filteredNodes.map((node) => node?.category) as (string | NodeTypeCategory)[];
    const mappedArraySet = new Set<number>(nodeCategories.map((index) => nodeTypeCategories[index]));
    const mappedCategories = Array.from(mappedArraySet);

    setFilteredNodeCategories(mappedCategories);
  };

  useEffect(() => {
    searchDropdown();
  }, [experimentalModeState, searchText]);

  return (
    // container for the whole left panel
    <div className="plex-fd-nsl-node-list-container">
      {/** Container for the search box */}
      <div className="plex-fd-nsl-search-box-container">
        {/** Search Box */}
        <input
          type="text"
          id="plex-nodes-panel-search"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search all nodes..."
          autoFocus={true}
          className="plex-fd-nsl-node-list-container-search-box"
        />

        <div className="plex-fd-nsl-magnifying-glass-icon" />
      </div>

      <Form className="plex-fd-nsl-form">
        {filteredNodeCategories.map((categoryName, index) => (
          <Form.Section
            key={categoryName.toString()}
            id={categoryName.toString()}
            title={categoryName.toString()}
            isCollapsed={false}
          >
            {/** This div handles the white space in the form sections */}
            <div key={"general-nodes-container"} className="plex-fd-nsl-general-nodes-container">
              {filteredNodes
                .filter((d: any) => {
                  return (
                    ((!experimentalModeState && !d.isExperimental) || experimentalModeState) &&
                    (d.category as any) === NodeTypeCategory[categoryName as number]
                  );
                })
                .map((d: any, index) => renderGeneralNodesSelection(d, index))}
            </div>
          </Form.Section>
        ))}
      </Form>
    </div>
  );
};
