import React, { ReactElement, useState, useRef, Children, FormEventHandler, forwardRef } from "react";
import clsx from "clsx";
import { useLocalization } from "@plex/culture-react";
import { sizeStyle } from "@components/inputs-styles";
import { ICommonInputProps, InputSize } from "../Input";
import { IChipProps, Chip } from "./Chip";
import styles from "./ChipInput.module.scss";
import { forkRef } from "../../utils";

interface IChipInputProps extends ICommonInputProps {
  /** The input value */
  value?: string;
  /** Event triggered when the value changes */
  onChange?: FormEventHandler<HTMLInputElement>;
  /**
   * The maximum number of chips to display. If the number of
   * chips exceeds this value the final chip will display the
   * count of hidden chips. This chip can be clicked to show
   * the hidden chips.
   * @default 1
   */
  maxDisplay?: number;
  children?: Array<ReactElement<IChipProps>> | ReactElement<IChipProps>;
}

export const ChipInput = forwardRef<HTMLInputElement, IChipInputProps>(
  (
    {
      className,
      placeholder,
      size = InputSize.dynamic,
      children,
      style,
      disabled,
      maxDisplay = 1,
      maxLength = 200,
      ...other
    },
    userRef
  ) => {
    const classes = clsx(styles.base, sizeStyle(size), disabled && styles.disabled, className);

    const inputRef = useRef<HTMLInputElement>(null);
    const [showAll, setShowAll] = useState(false);
    const { t } = useLocalization();
    const childCount = Children.count(children);

    const clickHandler = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const displayChildren = (amount: number) => {
      if (childCount > amount && !showAll) {
        return [
          ...Children.toArray(children).slice(0, amount - 1),
          <Chip key="__more" onClick={() => setShowAll(true)}>
            {t("ui-common-picker-info-more", { count: childCount - amount + 1 })}
          </Chip>
        ];
      }

      return children;
    };

    const placeholderText = childCount > 0 ? "" : placeholder;
    return (
      <div className={classes} onClick={clickHandler}>
        <div className={styles.chipWrapper} data-testid="plex-picker-selected-items">
          {displayChildren(maxDisplay)}
        </div>
        <input
          type="text"
          ref={forkRef(userRef, inputRef)}
          className={clsx(styles.input, childCount > 0 && styles.hasChildren)}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholderText}
          style={style}
          {...other}
        />
      </div>
    );
  }
);

ChipInput.displayName = "ChipInput";
