/* istanbul ignore file */
import { RefObject, useEffect } from "react";
import { getScrollableContainer } from "../utils";

export const useScrollIntoView = (targetRef: RefObject<HTMLElement>, scroll = true, offset = 0) => {
  useEffect(() => {
    if (!scroll || !targetRef.current) {
      return;
    }

    const rect = targetRef.current.getBoundingClientRect();
    const visible = rect.top - offset >= 0 && rect.bottom <= window.innerHeight;

    if (!visible) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });

      if (offset) {
        let scrollableContainer: HTMLElement | Document | Window = getScrollableContainer(
          targetRef.current.parentElement!
        );

        scrollableContainer = scrollableContainer === document ? window : scrollableContainer;
        (scrollableContainer as Element | Window).scroll({
          behavior: "smooth",
          top: targetRef.current.offsetTop - offset
        });
      }
    }
  }, [targetRef, scroll, offset]);
};
