import { useState, useEffect } from "react";
import { MaybeAsync } from "../components/Common.types";
import { isPromise } from "../utils";
import { useEventCallback } from "./use-event-callback";

export const useAsync = <T>(
  value: MaybeAsync<T>,
  defaultValue?: T,
  onError?: (reason: unknown) => void
): [T, boolean] => {
  const isAsync = isPromise<T>(value);
  const [state, setState] = useState(isAsync ? defaultValue : value || defaultValue);
  const [loading, setLoading] = useState(isAsync);
  const errorHandler = useEventCallback(onError!);

  useEffect(() => {
    if (isPromise<T>(value)) {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      value.then(setState, errorHandler).finally(() => setLoading(false));
    } else {
      setState(value);
      setLoading(false);
    }
  }, [value, errorHandler]);

  return [state as T, loading];
};
