import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, DocumentSchemaSource, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DataType } from "../../NodeTypes/TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CallDataSourceNode",
  label: "Data Source",
  category: NodeTypeCategory.services,
  size: NodeSize.Small,
  controlInputs: [{ name: "call" }],
  controlOutputs: [{ name: "completed", label: "" }],
  dataInputs: [],
  documentDataInputListName: "inputs",
  dataOutputs: [
    { name: "outputs", type: DataType.OBJECT, enabled: false },
    { name: "rows", type: DataType.OBJECTLIST, enabled: false }
  ],
  nodeConfigProperties: [
    {
      name: "dataSourceId",
      label: "Data Source",
      propertyType: NodeConfigPropertyType.DataSource,
      schemaSourceSystemType: DocumentSchemaSource.dataSource
    },
    {
      name: "dataSourceName",
      label: "Data Source Name",
      propertyType: NodeConfigPropertyType.NoInput,
      schemaSourceSystemType: DocumentSchemaSource.dataSource
    },
    {
      name: "viewDataSourceDetails",
      label: "",
      propertyType: NodeConfigPropertyType.ViewDataSourceDetails
    },
    {
      name: "inputs",
      sectionName: "Input",
      propertyType: NodeConfigPropertyType.EnabledInputs
    },
    {
      name: "outputs",
      sectionName: "Output",
      propertyType: NodeConfigPropertyType.EnabledOutputs
    }
  ],
  skipEvaluate: true,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CallDataSourceNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const getLabel = () => {
    let label = data.nodeProperties.dataSourceName?.replaceAll("_", " ") ?? "Data Source";
    const parenBegin = label.indexOf("(");
    const parenEnd = label.indexOf(")");
    if (parenBegin >= 0 && parenEnd > 0) {
      label = label.replace(label.slice(parenBegin, parenEnd + 1), "");
    }

    return label;
  };

  const label = getLabel();
  data.nodeProperties.label = label;
  return <BasicNodeLayout id={id} data={data} label={label} />;
};
