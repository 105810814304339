import React, { FunctionComponent, useMemo } from "react";
import { ViewControllerContext } from "./ViewControllerContext";

export interface ViewControllerProviderProps {
  controller: any;
  children: React.ReactNode;
}

// This component could be used as higher level component that could be used to share/update exposed state(both ways) to underlying child components
export const ViewControllerProvider: FunctionComponent<ViewControllerProviderProps> = ({ controller, children }) => {
  const value = useMemo(() => {
    return {
      ...controller
    };
  }, [controller]);

  return <ViewControllerContext.Provider value={value}>{children}</ViewControllerContext.Provider>;
};
