import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { loopBreak } from "../../Runtime/RuntimeExecution";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ForEachBreakNode",
  category: NodeTypeCategory.lists,
  innerLoop: true,
  label: "Loop Exit",
  controlInputs: [{ name: "break" }],
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [],
  size: NodeSize.Small,
  evaluate: () => {
    loopBreak();
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const ForEachBreakNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
