import React, { FC } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { AnchorArea } from "../../Util/AnchorUtil";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "FlowInputsNode",
  category: NodeTypeCategory.general,
  unconnectedAnchorLocation: AnchorArea.Output,
  label: "Flow Inputs",
  isExperimental: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [],
  documentDataOutputList: "outputs",
  nodeConfigProperties: [
    {
      name: "outputs",
      sectionName: "Inputs",
      propertyType: NodeConfigPropertyType.DataOutputs
    }
  ],
  size: NodeSize.Normal,
  evaluate: (input, nodeProperties) => {
    const outputData: any[any] = [];
    Object.keys(nodeProperties.outputs).forEach((outputName: string) => {
      const output = nodeProperties.outputs[outputName];
      if (!outputData[outputName] && output.enabled) {
        outputData[outputName] = output.propertyValue;
      }
    });

    return { output: outputData, activeControlHandleIds: [] };
  }
});

export const FlowInputsNode: FC<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
