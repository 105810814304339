import React, {
  FC,
  forwardRef,
  ReactElement,
  ForwardRefExoticComponent,
  RefAttributes,
  PropsWithChildren
} from "react";
import { withNoPrinting, withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps } from "../Common.types";
import { WizardPageList } from "./WizardPageList";
import { WizardPageLink } from "./WizardPageLink";
import { WizardButtons } from "./WizardButtons";
import { WizardPageContainer } from "./WizardPageContainer";
import { WizardContent } from "./WizardContent";

interface IWizardComposition
  extends ForwardRefExoticComponent<PropsWithChildren<ICommonProps> & RefAttributes<HTMLDivElement>> {
  PageList: typeof WizardPageList;
  PageLink: typeof WizardPageLink;
  Buttons: typeof WizardButtons;
  Page: typeof WizardPageContainer;
  Content: typeof WizardContent;
}

const HtmlWizard = forwardRef<HTMLDivElement, ICommonProps>((props, ref) => {
  return <div data-testid="plex-wizard" ref={ref} {...props} />;
});
HtmlWizard.displayName = "HtmlWizard";

/** Simple helper just to pass along children */
const ReturnChildren: FC = ({ children }) => children as ReactElement;

/** Wizard component which provides sub-components for layout. */
export const Wizard = withPrinting(HtmlWizard, ReturnChildren) as IWizardComposition;
Wizard.PageLink = WizardPageLink;
Wizard.PageList = withNoPrinting(WizardPageList);
Wizard.Buttons = WizardButtons;
Wizard.Page = withPrinting(WizardPageContainer, ReturnChildren);
Wizard.Content = withPrinting(WizardContent, ReturnChildren);
