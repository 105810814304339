import React, { ReactNode, ReactElement, FunctionComponent, ForwardRefExoticComponent } from "react";
import clsx from "clsx";
import { IWithChildren, ICommonProps } from "@components/Common.types";
import { withPrinting } from "@plex/react-xml-renderer";
import { IDataGridItemProps, DataGridItem } from "./DataGridItem";
import styles from "./DataGrid.module.scss";

export interface IDataGridProps extends ICommonProps, IWithChildren {
  /**
   * The cell count per row.
   * @default 3
   */
  cellCount?: number;
}

export interface IDataGrid extends ForwardRefExoticComponent<IDataGridProps> {
  Item: typeof DataGridItem;
}

const renderChildren = (children: ReactNode, columnsCount: number) => {
  const elements = React.Children.toArray(children).filter(React.isValidElement);

  let emptyColumnsCount = columnsCount;
  const table = [];
  let row = [];
  let i = 0;

  while (i < elements.length) {
    let elementSpan = (elements[i] as ReactElement<IDataGridItemProps>).props.cellSpan || 1;
    elementSpan = Math.min(elementSpan, columnsCount);

    if (elementSpan <= emptyColumnsCount) {
      row.push(elements[i++]);
      emptyColumnsCount -= elementSpan;
    } else {
      row.push(<DataGridItem key="__filler" cellSpan={emptyColumnsCount} />);
      emptyColumnsCount = 0;
    }

    if (emptyColumnsCount === 0) {
      table.push(
        <div key={table.length} className={styles.tableRow}>
          {row}
        </div>
      );

      row = [];
      emptyColumnsCount = columnsCount;
    } else if (i === elements.length) {
      row.push(<DataGridItem key="__filler" cellSpan={emptyColumnsCount} />);
      table.push(
        <div key={table.length} className={styles.tableRow}>
          {row}
        </div>
      );
    }
  }

  return table;
};

const HtmlDataGrid: FunctionComponent<IDataGridProps> = ({ cellCount = 3, children, className, ...other }) => {
  return (
    <div className={clsx(styles.base, className)} {...other}>
      {renderChildren(children, cellCount)}
    </div>
  );
};

const XmlDataGrid: FunctionComponent<IDataGridProps> = ({ children }) => {
  const row = React.createElement("grid-table-row", {}, children);
  const body = React.createElement("grid-table-body", {}, row);
  const table = React.createElement("plex-grid-table", {}, body);
  return React.createElement("plex-readonlygrid", {}, table);
};

export const DataGrid = withPrinting(HtmlDataGrid, XmlDataGrid) as IDataGrid;
DataGrid.Item = DataGridItem;
