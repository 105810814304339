import { IWithChildren, ICommonProps } from "../Common.types";

export interface ITabProps extends IWithChildren, ICommonProps {
  /**
   * When true the style of the item is adjusted to indicate it is disabled.
   * @default false;
   */
  disabled?: boolean;
}

export interface ITabPanelProps extends IWithChildren, ICommonProps {
  /**
   * When true a tab panel will be kept in the DOM.
   * @default false;
   */
  keepMounted?: boolean;
}

export interface ITabListProps extends IWithChildren, ICommonProps {}

export interface ITabsProps extends IWithChildren {
  /**
   * When true all tab panels will be kept in the DOM.
   * @default false;
   */
  keepMounted?: boolean;
  /** When true the style of the item is adjusted to indicate it is selected. */
  selectedIndex: number;
  /** Event handler is called every time when tab was changed. */
  onSelect: (index: number, prevIndex: number, event: Event) => boolean | void;
}

export enum TabsRole {
  Tab = "Tab",
  TabPanel = "TabPanel",
  TabList = "TabList"
}

export interface ITabsElement {
  (props: ITabProps | ITabPanelProps | ITabListProps): JSX.Element;
  /**
   * Determines the role of a component inside Tabs component.
   * @description Required for custom components that are added to Tabs. This value is used inside react-tabs to check the role of a component.
   */
  tabsRole: TabsRole;
  /** Display name. */
  displayName?: string;
}
