import { createContext } from "react";

export type FilterState = {
  collapsed: boolean;
  searched: boolean;
  timestamp?: number;
};

export const FilterContext = createContext<FilterState>({
  collapsed: false,
  searched: false
});
