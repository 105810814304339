import React, { FunctionComponent, ReactElement, isValidElement, ReactNode, useState } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import { FilterItemLabel } from "@components/Filter/FilterItemLabel";
import clsx from "clsx";
import { ICommonProps, RequiredState } from "../Common.types";
import { IFilterItemFieldProps } from "./FilterItemField";
import * as globalStyles from "../../global-styles";
import { useSearch } from "./use-search";
import styles from "./Filter.module.scss";

export interface IFilterItemProps extends ICommonProps {
  /**
   * The field ID that should be associated to the label
   * @description By default the first Filter Field item id is used
   */
  primaryFieldId?: string;
  /** The label text or component */
  labelText?: string | ReactNode;
  /** Indicates whether element should be marked as required */
  required?: RequiredState;
}

const isFilterItemField = (element: ReactElement): element is ReactElement<IFilterItemFieldProps> => {
  return ["value", "originalValue", "shouldCollapse"].some(key => key in element.props);
};

const hasValue = (props: IFilterItemFieldProps) => {
  if (Array.isArray(props.value)) {
    return props.value.length > 0;
  }

  if (props.value === false) {
    return !!props.originalValue;
  }

  return props.value === 0 || !!props.value;
};

const findFilterItemField = (child: ReactNode): ReactElement<IFilterItemFieldProps> | null => {
  if (!isValidElement(child)) {
    return null;
  }

  if (isFilterItemField(child)) {
    return child;
  }

  return null;
};

const shouldCollapse = (children: ReactElement[]) => {
  const validChildren = children.map(findFilterItemField).filter(Boolean) as Array<ReactElement<IFilterItemFieldProps>>;

  if (validChildren.length === 0) {
    return true;
  }

  return validChildren.every(child => child.props.shouldCollapse?.() ?? !hasValue(child.props));
};

const getFirstValidChild = (children: ReactNode): ReactElement | null => {
  const validChildren: ReactElement[] = React.Children.toArray(children).filter(isValidElement);
  if (validChildren.length === 0) {
    return null;
  }

  const childWithId = validChildren.find(x => !!x.props.id);
  if (childWithId) {
    return childWithId;
  }

  for (let i = 0; i < validChildren.length; i++) {
    const child = getFirstValidChild(validChildren[i].props.children);
    if (child) {
      return child;
    }
  }
  return null;
};

const getRequiredClasses = (required: RequiredState | undefined) => {
  switch (required) {
    case RequiredState.required:
      return globalStyles.isRequiredAfter;
    case RequiredState.group:
      return globalStyles.isRequiredGroupAfter;
    default:
      return undefined;
  }
};

const HtmlFilterItem: FunctionComponent<IFilterItemProps> = ({
  children,
  primaryFieldId,
  labelText,
  required,
  className,
  ...other
}) => {
  const [canCollapse, setCanCollapse] = useState(false);
  const { collapsed, searched } = useSearch(() => {
    const validChildren: ReactElement[] = React.Children.toArray(children).filter(isValidElement);
    setCanCollapse(
      validChildren.length === 0 || (required !== RequiredState.required && shouldCollapse(validChildren))
    );
  });

  let hidden = false;
  if (searched) {
    hidden = collapsed && canCollapse;
  } else {
    // If unsearched, all filters currently collapse when a collapse
    // is invoked by the user.
    hidden = collapsed;
  }

  if (hidden) {
    return null;
  }

  const classes = clsx(styles.filterItem, className);
  const requiredClasses = getRequiredClasses(required);
  const labelFor = primaryFieldId || getFirstValidChild(children)?.props.id;

  return (
    <div data-testid="plex-control-group" className={classes} {...other}>
      <FilterItemLabel requiredClasses={requiredClasses} labelFor={labelFor} labelText={labelText} />
      <div data-testid="plex-controls" className={styles.filterItemControls}>
        {children}
      </div>
    </div>
  );
};

const XmlFilterItem: FunctionComponent<IFilterItemProps> = ({ children, labelText, primaryFieldId, required }) => {
  const [canCollapse, setCanCollapse] = useState(false);
  const { collapsed, searched } = useSearch(() => {
    const validChildren: ReactElement[] = React.Children.toArray(children).filter(isValidElement);
    setCanCollapse(
      validChildren.length === 0 || (required !== RequiredState.required && shouldCollapse(validChildren))
    );
  });

  let hidden = false;
  if (searched) {
    hidden = collapsed && canCollapse;
  } else {
    // If unsearched, all filters currently collapse when a collapse
    // is invoked by the user.
    hidden = collapsed;
  }

  if (hidden) {
    return null;
  }

  const requiredClasses = getRequiredClasses(required);
  const labelFor = primaryFieldId || getFirstValidChild(children)?.props.id;

  const labelElement = (
    <FilterItemLabel key="__label" requiredClasses={requiredClasses} labelFor={labelFor} labelText={labelText} />
  );
  return React.createElement("plex-filter", {}, [labelElement, children]);
};

export const FilterItem = withPrinting(HtmlFilterItem, XmlFilterItem);
