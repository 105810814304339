import { useContext, useRef, useEffect } from "react";
import { FilterContext } from "./FilterState";

/**
 * A hook which returns the current FilterState and allows a callback to
 * be supplied which will be invoked whenever a search is invoked. (Can only
 * be used within a @see {@link FilterSection}.)
 *
 * @param callback An optional callback that will execute whenever a search is invoked
 */
export const useSearch = (onSearch?: () => void) => {
  const context = useContext(FilterContext);
  const callbackRef = useRef(onSearch);

  useEffect(() => {
    callbackRef.current = onSearch;
  });

  useEffect(() => {
    if (context.searched) {
      callbackRef.current?.();
    }
    // The timestamp will be updated whenever a search is invoked,
    // which will cause this effect to be triggered.
  }, [context.timestamp, context.searched]);

  return context;
};
