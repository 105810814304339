import React, { useContext } from "react";
import clsx from "clsx";
import { ArrowDown2Icon } from "@plex/icons";
import { ICommonProps, IWithChildren } from "../Common.types";
import { Clickable } from "../Link";
import { ActionBarIcon } from "./ActionBar.Icon";
import { DropdownContext } from "./ActionBar.Dropdown";
import styles from "./ActionBar.module.scss";

export interface IActionBarDropdownButton extends ICommonProps, IWithChildren {
  /** If the dropdown is disabled */
  disabled?: boolean;
  /** Display text */
  text?: string;
  /** Tooltip text */
  title?: string;
}

const ActionBarDropdownButton = React.forwardRef<HTMLAnchorElement, IActionBarDropdownButton>(
  ({ disabled, text, className, children, ...other }, ref) => {
    const { toggle } = useContext(DropdownContext);

    return (
      <Clickable
        onClick={toggle}
        className={clsx(disabled && styles.disabled, className)}
        disabled={disabled}
        ref={ref}
        role="link"
        {...other}
      >
        {children}
        {!!text && text}
        <ActionBarIcon alignment="right">
          <ArrowDown2Icon height="1rem" width="1rem" />
        </ActionBarIcon>
      </Clickable>
    );
  }
);

ActionBarDropdownButton.displayName = "ActionBarDropdownButton";
export { ActionBarDropdownButton };
