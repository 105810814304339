import { useCallback } from "react";
import { useDataTableSelector } from "../DataTableStateProvider";
import { GridStateWithRanking } from "./DataRanking";
import { GridState } from "../DataTable.types";

export const useIsRankingRecord = (key: string) => {
  const selector = useCallback((s: GridState<unknown>) => (s as GridStateWithRanking).rankingKey === key, [key]);
  return useDataTableSelector(selector);
};

const RANKING_SUPPORTED_SELECTOR = (s: GridState<unknown>) => !!(s as GridStateWithRanking).rankingSupported;
export const useIsRankingEnabled = () => useDataTableSelector(RANKING_SUPPORTED_SELECTOR);
