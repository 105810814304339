import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import styles from "./Dialog.module.scss";

export const DialogBody: FunctionComponent<ICommonProps> = ({ children, className, ...other }) => {
  const classes = clsx(styles.modalBody, className);
  return (
    <section {...other} className={classes}>
      {children}
    </section>
  );
};
