import { MutableRefObject, Ref } from "react";

// eslint-disable-next-line func-style
export function setRef<T>(node: T, ref: Ref<T>) {
  if (typeof ref === "function") {
    ref(node);
  } else if (ref != null) {
    // eslint-disable-next-line no-param-reassign
    (ref as MutableRefObject<T>).current = node;
  }
}
