import { FocusEventHandler, FormEventHandler, KeyboardEventHandler, FunctionComponent, ReactNode } from "react";
import { INumericFormatOptions } from "@plex/culture-core";
import { ICommonProps, ValueChangeHandler } from "../Common.types";

export enum InputSize {
  dynamic = "dynamic",
  tiny = "tiny",
  mini = "mini",
  small = "small",
  medium = "medium",
  large = "large",
  xlarge = "xlarge"
}

export interface ICommonInputProps extends ICommonProps {
  /**
   * Sets the input as disabled
   * @default false
   */
  disabled?: boolean;
  /** The name attribute */
  name?: string;
  /** The max-length attribute */
  maxLength?: number;
  /** Event triggered on blur */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** Event triggered when [ENTER] key is pressed */
  onEnterKey?: KeyboardEventHandler<HTMLInputElement>;
  /** Event triggered when a key is down */
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  /** Event triggered when any key is pressed */
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  /** The placeholder text to display when the input is empty */
  placeholder?: string;
  /**
   * The size of the textbox
   * @default "dynamic"
   */
  size?: InputSize;
}

export interface IInputProps extends ICommonInputProps {
  /**
   * The type of onscreen keyboard used for the text field. (Maps to the "inputmode" attribute.)
   * @default "text"
   */
  keyboard?: "none" | "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url";
  /** Event triggered when the value changes */
  onChange?: FormEventHandler<HTMLInputElement>;
  /**
   * The type attribute
   * @default "text"
   */
  type?: "text" | "password";
  /** The value */
  value?: string;
  /** Sets the autofocus attribute on the input */
  autoFocus?: boolean;
  /** Event triggered when the value changes */
  onValueChange?: ValueChangeHandler;
}

export interface INumericInputProps extends ICommonInputProps {
  /** The current numeric value for the component */
  numericValue?: number;
  /** The formating options to apply to the numeric value */
  numericOptions?: string | INumericFormatOptions;
  /** Callback which is called when the numeric value is changed */
  onValueChange?: ValueChangeHandler<number>;
}

export interface IAdornedInputProps extends IInputProps {
  /** The component instance to adorn the input */
  adornment: ReactNode;
  /** The component to use to render the input (Default will be TextInput) */
  as?: string | FunctionComponent<IInputProps>;

  // allow additional types, which will be passed to input
  [key: string]: unknown;
}

export interface IPasswordInputProps extends ICommonInputProps {
  /** Indicates whether the password input has a visibility toggle feature. */
  allowVisibilityToggle?: boolean;
  /** Event triggered when the value changes */
  onChange?: FormEventHandler<HTMLInputElement>;
  /** The value */
  value?: string;
  /** Sets the autofocus attribute on the input */
  autoFocus?: boolean;
  /** Event triggered when the value changes */
  onValueChange?: ValueChangeHandler;
}
