import React, { forwardRef, MouseEventHandler, MouseEvent } from "react";
import { ICommonProps, IWithChildren } from "../Common.types";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ClickableComponentType =
  | "a"
  | "button"
  | ((props: any, context?: any) => any)
  | (new (props: any, context?: any) => any);
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface IClickableProps extends ICommonProps, IWithChildren {
  /**
   * A URL to navigate to when the link is clicked. An empty link will not self navigate.
   */
  href?: string;
  /**
   * Sets the react component for rendering this  button. Most common values are 'button' and 'a'
   * @default button
   */
  as?: ClickableComponentType;
  /**
   * When disabled navigation to the given href and the onClick will no longer work.
   * @default false
   */
  disabled?: boolean;
  /**
   * The type attribute to use when rendering as a HTML button element
   */
  type?: "button" | "submit" | "reset";
  /**
   * The action performed when the element is clicked
   */
  onClick?: MouseEventHandler<HTMLElement>;
  /**
   * The action performed when the element is double-clicked
   */
  onDoubleClick?: MouseEventHandler<HTMLElement>;
  /** The role attribute to apply to the HTML element */
  role?: string;
}

/**
 * Component which renders as a link or button depending on the attributes provided.
 */
export const Clickable = forwardRef<HTMLElement, IClickableProps>(
  ({ as: preferredComponent, href, disabled, type, onClick, onDoubleClick, children, ...other }, ref) => {
    const Component = preferredComponent || (href ? "a" : "button");

    let extraProps: object;
    switch (Component) {
      case "a":
        extraProps = {
          href: disabled ? null : href
        };
        break;
      case "button":
        extraProps = {
          type: type || "button",
          disabled
        };
        break;
      default:
        // if rendered as any other component, pass all props as is
        extraProps = {
          disabled,
          type,
          href,
          as: preferredComponent
        };
        break;
    }

    const clickHandler = onClick
      ? (e: MouseEvent<HTMLElement>) => {
          if (!disabled) {
            onClick(e);
          }
        }
      : undefined;

    const doubleClickHandler = onDoubleClick
      ? (e: MouseEvent<HTMLElement>) => {
          if (!disabled) {
            onDoubleClick(e);
          }
        }
      : undefined;

    return (
      <Component onClick={clickHandler} onDoubleClick={doubleClickHandler} ref={ref} {...extraProps} {...other}>
        {children}
      </Component>
    );
  }
);
Clickable.displayName = "Clickable";
