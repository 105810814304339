import {
  decodeDocument,
  encodeDocument
} from "../FlowDocument/DocumentEncode";
import {
  convertToDocument,
  verifyAndLoadDocument
} from "../FlowDocument/DocumentProcessor";
import { IFlowDocumentModel } from "../FlowDocument/FlowDocumentModel";
import { ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { IBannerContext, BannerStatus } from "@plex/react-components";

const openFile = (callback: (content: any) => void) => {
  var input = document.createElement("input");
  input.type = "file";

  input.onchange = (e) => {
    var file = (e.target! as any).files[0];
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");

    reader.onload = (readerEvent) => {
      let content = readerEvent.target!.result as string;
      callback(content);
    };
  };

  input.click();
};

export const openFlow = (
  flowInputController: any,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  showCanvas: () => void,
  hideCanvas: () => void,
  bannerContext: IBannerContext
) => {
  openFile((content) => {
    if (content !== undefined) {
      decodeDocument(content)
        .then((decodedDocument: IFlowDocumentModel) => {
          verifyAndLoadDocument(
            decodedDocument,
            flowInputController,
            reactFlowInstance,
            updateNodeInternals,
            showCanvas,
            hideCanvas,
            bannerContext
          );
        })
        .catch((reason: any) => bannerContext?.addMessage("Flow cannot be loaded.", BannerStatus.error));
    } else {
      console.log("No content returned from openFile");
    }
  });
};

export const download = (flowInputController: any, reactFlowInstance: ReactFlowInstance) => {
  const flowDocument = convertToDocument(flowInputController?.flowInputPool, reactFlowInstance);

  encodeDocument(flowDocument).then((encodedDocument: string) => {
    downloadJson(encodedDocument, document.title + ".txt");
  });
};

const downloadJson = (json: string, title: string) => {
  var file = new Blob([json], { type: "txt" });
  var a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  a.download = title;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
