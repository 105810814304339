import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../../../components/NodeTypes/Base";
import { DataType } from "../../../components/NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const sourceInputName = "value";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "IsNullOrEmptyNode",
  label: "Is Null/Empty",
  category: NodeTypeCategory.logic,
  controlOutputs: [],
  dataInputs: [{ name: sourceInputName, type: DataType.STRING, hideLabel: true }],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [
          DataType.STRING,
          DataType.BOOLEAN,
          DataType.INTEGER,
          DataType.DECIMAL,
          DataType.DATE,
          DataType.DATETIME,
          DataType.TIME,
          DataType.UUID,
          DataType.OBJECT,
          DataType.STRINGLIST,
          DataType.BOOLEANLIST,
          DataType.INTEGERLIST,
          DataType.DECIMALLIST,
          DataType.DATELIST,
          DataType.DATETIMELIST,
          DataType.TIMELIST,
          DataType.UUIDLIST,
          DataType.OBJECTLIST
        ],
        dataProperties: [sourceInputName]
      }
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    let result = true;
    if (input !== undefined && input !== null) {
      if (typeof input === "string") {
        result = input.length === 0;
      } else if (Array.isArray(input)) {
        result = input.length === 0;
      } else {
        result = false;
      }
    }
    return { output: { result: result }, activeControlHandleIds: [] };
  }
});

export const IsNullOrEmptyNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
