import React, { FC } from "react";
import { ConstantLabel } from "./ConstantLabel";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConstantNullNode",
  category: NodeTypeCategory.text,
  isExperimental: true,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: "result", type: DataType.STRING, enabled: true, hideLabel: true }],
  size: NodeSize.Small,
  evaluate: (input, nodeProperties) => {
    return { output: { result: nodeProperties.literal }, activeControlHandleIds: [] };
  }
});

export const ConstantNullNode: FC<NodeProps> = ({ id, data }) => {
  const kind = "null";
  return (
    <BasicNodeLayout id={id} data={data} className="single-type-constant-node">
      <ConstantLabel data={data} kind={kind} />
    </BasicNodeLayout>
  );
};
