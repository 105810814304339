import React, { SyntheticEvent, PropsWithChildren, FC, useRef, useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import Draggable from "react-draggable";
import { CssLength, ICommonProps } from "@components/Common.types";
import styles from "./Dialog.module.scss";
import { DialogHeader } from "./DialogHeader";
import { DialogTitle } from "./DialogTitle";
import { DialogBody } from "./DialogBody";
import { DialogFooter } from "./DialogFooter";
import { Portal } from "./Portal";

export interface IDialogProps extends ICommonProps {
  /**
   * Indicates whether the dialog has a backtrop
   * @default true
   */
  backdrop?: "static" | boolean;
  /** initial dialog height */
  initialHeight?: number;
  /** initial dialog height */
  initialWidth?: number;
  /** Minimum dialog height */
  minHeight?: CssLength;
  /** Maximum dialog height */
  maxHeight?: CssLength;
  /** Minimum dialog width */
  minWidth?: CssLength;
  /** Maximum dialog width */
  maxWidth?: CssLength;
  /** Indicates whether the dialog should be rendered */
  show: boolean;
  /**
   * Indicates whether the close button is visible
   * @default true
   */
  closeButton?: boolean;
  /** The dialog title */
  title?: string;
  /**
   * Indicates whether the dialog can be closed on escape
   * @default true
   */
  closeOnEscape?: boolean;
  /** Callback executed when the dialog is being closed */
  onHide?: () => void;

  disableDrag?: boolean;
}

enum Direction {
  S = 1,
  E,
  W,
  SW,
  SE
}

interface ISize {
  height?: number;
  width?: number;
}

export interface IDialogComposition {
  Header: typeof DialogHeader;
  Title: typeof DialogTitle;
  Body: typeof DialogBody;
  Footer: typeof DialogFooter;
}

const isMouseEvent = (e: unknown): e is React.MouseEvent => {
  return !!e && typeof e === "object" && (e as SyntheticEvent).type === "mousedown";
};

const isTouchEvent = (e: unknown): e is React.TouchEvent => {
  return !!e && typeof e === "object" && (e as SyntheticEvent).type === "touchmove";
};

export const Dialog: FC<PropsWithChildren<IDialogProps>> & IDialogComposition = ({
  backdrop = true,
  show,
  onHide,
  closeOnEscape,
  closeButton = true,
  title,
  initialHeight,
  initialWidth,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  disableDrag = false,
  children,
  id,
  className
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const previousPositionRef = useRef({ x: 0, y: 0 });

  const [size, setSize] = useState<ISize>({
    width: initialWidth,
    height: initialHeight
  });
  const [isResizing, setResizing] = useState(false);
  const directionRef = useRef<Direction>();

  useEffect(() => {
    if (!onHide || !closeOnEscape || !show) {
      return undefined;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onHide();
      }
    };

    document.body.addEventListener("keydown", onKeyDown);
    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
    };
  }, [closeOnEscape, onHide, show]);

  const onMouseMove = useMemo(() => {
    const checkSize = (currentSize: ISize): ISize => {
      const container = containerRef.current!;
      if (typeof minHeight === "number" && container.offsetHeight < minHeight) {
        currentSize.height = minHeight;
      }

      if (typeof maxHeight === "number" && container.offsetHeight > maxHeight) {
        currentSize.height = maxHeight;
      }

      if (typeof minWidth === "number" && container.offsetWidth < minWidth) {
        currentSize.width = minWidth;
      }

      if (typeof maxWidth === "number" && container.offsetWidth > maxWidth) {
        currentSize.width = maxWidth;
      }

      return currentSize;
    };

    return (e: MouseEvent | TouchEvent) => {
      let movementX = 0;
      let movementY = 0;

      if (isTouchEvent(e)) {
        movementX = e.touches[0].screenX - previousPositionRef.current.x;
        movementY = e.touches[0].screenY - previousPositionRef.current.y;
        previousPositionRef.current = {
          x: e.touches[0].screenX,
          y: e.touches[0].screenY
        };
      } else {
        const event = e as MouseEvent;
        movementX = event.screenX - previousPositionRef.current.x;
        movementY = event.screenY - previousPositionRef.current.y;
        previousPositionRef.current = {
          x: event.screenX,
          y: event.screenY
        };
      }

      switch (directionRef.current) {
        case Direction.S:
          setSize(currentSize =>
            checkSize({
              ...currentSize,
              height: currentSize.height ? currentSize.height + movementY : movementY
            })
          );

          return;
        case Direction.E:
          setSize(currentSize =>
            checkSize({ ...currentSize, width: currentSize.width ? currentSize.width + movementX : movementX })
          );
          return;
        case Direction.W:
          setSize(currentSize =>
            checkSize({
              ...currentSize,
              width: currentSize.width ? currentSize.width - movementX : -movementX
            })
          );
          return;
        case Direction.SW:
          setSize(currentSize =>
            checkSize({
              width: currentSize.width ? currentSize.width - movementX : -movementX,
              height: currentSize.height ? currentSize.height + movementY : movementY
            })
          );
          return;
        case Direction.SE:
          setSize(currentSize =>
            checkSize({
              width: currentSize.width ? currentSize.width + movementX : movementX,
              height: currentSize.height ? currentSize.height + movementY : movementY
            })
          );
          break;

        default:
      }
    };
  }, [maxHeight, maxWidth, minHeight, minWidth]);

  useEffect(() => {
    if (isResizing) {
      const cancelResize = () => setResizing(false);
      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("touchmove", onMouseMove);
      document.body.addEventListener("mouseup", cancelResize);
      document.body.addEventListener("touchend", cancelResize);
      document.body.addEventListener("mouseleave", cancelResize);

      return () => {
        document.body.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("touchmove", onMouseMove);
        document.body.removeEventListener("mouseup", cancelResize);
        document.body.removeEventListener("touchend", cancelResize);
        document.body.removeEventListener("mouseleave", cancelResize);
      };
    }

    return undefined;
  }, [isResizing, onMouseMove]);

  const onMouseDown = (e: Direction, event: React.MouseEvent | React.TouchEvent) => {
    const { height, width } = containerRef.current!.getBoundingClientRect();
    if (isMouseEvent(event)) {
      previousPositionRef.current.x = event.screenX;
      previousPositionRef.current.y = event.screenY;
    } else {
      previousPositionRef.current.x = event.touches[0].screenX;
      previousPositionRef.current.y = event.touches[0].screenY;
    }

    directionRef.current = e;

    setSize({ height, width });
    setResizing(true);

    // prevent the the browser from treating this as a normal drag (select text, drag image, etc.)
    event.preventDefault();
  };

  const onCloseHandler = () => {
    if (!isResizing && backdrop !== "static" && onHide) {
      onHide();
    }
  };

  if (!show) {
    return null;
  }

  const style = {
    ...size,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth
  };

  const resizableE = clsx(styles.resizableE, styles.resizable);
  const resizableS = clsx(styles.resizableS, styles.resizable);
  const resizableW = clsx(styles.resizableW, styles.resizable);
  const resizableSE = clsx(styles.resizableSe, styles.resizable);
  const resizableSW = clsx(styles.resizableSw, styles.resizable);

  return (
    <Portal>
      <div className={clsx(styles.modal, className)} data-testid="modal-dialog" id={id}>
        <Draggable
          handle={`.${styles.modalHeaderWrapper}`}
          bounds={`.${styles.modal}`}
          defaultClassNameDragging={styles.disableSelect}
          disabled={disableDrag}
          nodeRef={containerRef}
        >
          <div
            data-testid="modal-content"
            ref={containerRef}
            className={styles.modalContent}
            style={style}
            role="dialog"
          >
            <DialogHeader onHide={onHide} closeButton={closeButton}>
              <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            {children}
            <div
              onMouseDown={e => onMouseDown(Direction.E, e)}
              onTouchStart={e => onMouseDown(Direction.E, e)}
              className={resizableE}
            />
            <div
              onMouseDown={e => onMouseDown(Direction.S, e)}
              onTouchStart={e => onMouseDown(Direction.S, e)}
              className={resizableS}
            />
            <div
              onMouseDown={e => onMouseDown(Direction.W, e)}
              onTouchStart={e => onMouseDown(Direction.W, e)}
              className={resizableW}
            />
            <div
              onMouseDown={e => onMouseDown(Direction.SE, e)}
              onTouchStart={e => onMouseDown(Direction.SE, e)}
              className={resizableSE}
            />
            <div
              onMouseDown={e => onMouseDown(Direction.SW, e)}
              onTouchStart={e => onMouseDown(Direction.SW, e)}
              className={resizableSW}
            />
          </div>
        </Draggable>
        {backdrop && <div className={styles.backdrop} onClick={onCloseHandler} />}
      </div>
    </Portal>
  );
};

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
Dialog.Title = DialogTitle;
