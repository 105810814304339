/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable no-implicit-coercion */
/* eslint-disable prettier/prettier */
(function () {
  const RESERVED_PROPS = {
    key: true,
    ref: true,
    __self: true,
    __source: true
  };

  function jsx(type, config, maybeKey) {
    let propName;

    // Reserved names are extracted
    const props = {};

    let key = null;
    let ref = null;

    // Currently, key can be spread in as a prop. This causes a potential
    // issue if key is also explicitly declared (ie. <div {...props} key="Hi" />
    // or <div key="Hi" {...props} /> ). We want to deprecate key spread,
    // but as an intermediary step, we will use jsxDEV for everything except
    // <div {...props} key="Hi" />, because we aren't currently able to tell if
    // key is explicitly declared to be undefined or not.
    if (maybeKey !== undefined) {
      key = "" + maybeKey;
    }

    if (config.key !== undefined) {
      key = "" + config.key;
    }

    if (config.ref !== undefined) {
      ref = config.ref;
    }

    // Remaining properties are added to a new props object
    for (propName in config) {
      if (Object.prototype.hasOwnProperty.call(config, propName) && !RESERVED_PROPS.hasOwnProperty(propName)) {
        props[propName] = config[propName];
      }
    }

    // Resolve default props
    if (type && type.defaultProps) {
      const defaultProps = type.defaultProps;
      for (propName in defaultProps) {
        if (props[propName] === undefined) {
          props[propName] = defaultProps[propName];
        }
      }
    }

    return React.createElement(type, Object.assign({ key, ref }, props));
  }

  window.jsxRuntime = { jsx, jsxs: jsx, Fragment: React.Fragment };
})();
