import React, { FunctionComponent, useState } from "react";
import { useLocalization } from "@plex/culture-react";
import { CancelLink } from "../Link";
import { ICommonProps, MaybeAsync } from "../Common.types";
import { useToggle } from "../../hooks/use-toggle";
import { Image } from "../Image";
import { Dialog } from "../Dialog";
import { OkButton } from "../Button";
import { ImageModalBody } from "./ImageModalBody";
import { IImage } from "./ImagePicker.types";
import styles from "./ImagePicker.module.scss";

const DIALOG_MIN_WIDTH = 400;
const DIALOG_MIN_HEIGHT = 275;

export interface ISelectImageModalProps extends ICommonProps {
  /** The currently selected image */
  selectedImage?: IImage;
  /**
   * Callback which is executed when the image
   * selection has changed
   */
  onSelectionChanged: (image: IImage) => void;
  /** The collection of images to select from */
  data: MaybeAsync<IImage[]>;
}

const EMPTY_IMAGE: IImage = { key: "", alternateText: "", imageUrl: "" };

export const ImagePicker: FunctionComponent<ISelectImageModalProps> = ({
  selectedImage,
  onSelectionChanged,
  data,
  ...other
}) => {
  const [openDialog, toggleOpen] = useToggle(false);
  const [uncommitedImage, setUncommitedImage] = useState(selectedImage || EMPTY_IMAGE);
  const currentImage = selectedImage || EMPTY_IMAGE;

  const handleDialogOk = () => {
    onSelectionChanged(uncommitedImage);
    toggleOpen();
  };

  const handleOpenDialog = () => {
    setUncommitedImage(currentImage);
    toggleOpen();
  };

  const { t } = useLocalization();

  return (
    <div {...other}>
      <div className={styles.imageSelectRow}>
        <div className={styles.imageSelectContainer}>
          <div className={styles.imageSelect} onClick={handleOpenDialog} data-testid="plex-icon-picker-image">
            {currentImage.imageUrl && <Image src={currentImage.imageUrl} alt={currentImage.alternateText} />}
            {currentImage.name && <span>{currentImage.name}</span>}
          </div>
        </div>
      </div>
      {openDialog && (
        <Dialog
          onHide={toggleOpen}
          title={t("ui-common-imagePicker-title-dialog")}
          backdrop="static"
          closeOnEscape
          show
          minHeight={DIALOG_MIN_HEIGHT}
          minWidth={DIALOG_MIN_WIDTH}
        >
          <Dialog.Body>
            <ImageModalBody selectedImage={uncommitedImage} data={data} onSelectionChanged={setUncommitedImage} />
          </Dialog.Body>
          <Dialog.Footer>
            <CancelLink onClick={toggleOpen} />
            <OkButton onClick={handleDialogOk} data-testid="select-image-ok" />
          </Dialog.Footer>
        </Dialog>
      )}
    </div>
  );
};
