import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { areSame } from "@arrays-immutable";
import { mergeIfDifferent } from "@utils";
import { DataTablePluginComponentProps, DataTablePluginFactory, GridAction, GridState } from "../DataTable.types";
import { useDataTableDispatcher, useDataTableSelector } from "../DataTableStateProvider";
import { getSampleRow, GridStateWithSizes, updateColumnWidths, reset } from "./DataColumnResizer.actions";
import { DataTableFixedLayoutProps } from "./Plugins.types";

export const FIXED_LAYOUT_PLUGIN = "FixedLayout";
const STATE_SELECTOR = <S>(s: GridStateWithSizes<S>) => s;

// eslint-disable-next-line func-style
function DataTableFixedLayoutRender<T = unknown>({
  initialWidths,
  children,
  tableRef
}: PropsWithChildren<DataTableFixedLayoutProps & DataTablePluginComponentProps<T>>) {
  const dispatch = useDataTableDispatcher<T>();
  const { resized, data } = useDataTableSelector(STATE_SELECTOR);
  const cellCountRef = useRef(0);
  const initialWidthsRef = useRef(initialWidths);

  // During every render, resize colgroups - however if the user has applied
  // a manual size, they now control the column sizes...
  useEffect(() => {
    const table = tableRef.current;
    if (!table || data.length === 0) {
      return;
    }

    const refRow = getSampleRow(table);
    if (!refRow) {
      return;
    }

    const priorCellCount = cellCountRef.current;
    const currentCellCount = (cellCountRef.current = refRow.cells.length);

    if (priorCellCount === currentCellCount || priorCellCount === 0) {
      if (resized) {
        return;
      }

      const sizes = Array.from(refRow.cells).map(x => Math.ceil(x.offsetWidth));
      dispatch(updateColumnWidths({ sizes, resized: false }));
    } else {
      dispatch(reset(true));
    }
  });

  useEffect(() => {
    const table = tableRef.current;
    const resetHandler = (e: Event) => {
      const cell = (e.target as HTMLImageElement).closest("th,td") as HTMLTableCellElement;

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (cell?.offsetWidth > 0 && cell.offsetWidth < (e.target as HTMLImageElement).naturalWidth) {
        dispatch(reset());
      }
    };

    if (!table || resized) {
      return undefined;
    }

    const images = Array.from(table.getElementsByTagName("img"));
    images.forEach(img => {
      img.addEventListener("load", resetHandler);
    });

    return () => {
      images.forEach(img => {
        img.removeEventListener("load", resetHandler);
      });
    };
    // Only resize when data is changed.
  }, [data, dispatch, resized, tableRef]);

  useEffect(() => {
    if (resized || !initialWidthsRef.current) {
      return;
    }

    dispatch(updateColumnWidths({ sizes: initialWidthsRef.current, resized: true }));
  }, [resized, dispatch]);

  useEffect(() => {
    if (initialWidths) {
      initialWidthsRef.current = initialWidths;
      dispatch(updateColumnWidths({ sizes: initialWidthsRef.current, resized: false }));
    }
  }, [initialWidths, dispatch]);

  return children;
}

export const DataTableFixedLayout = <T>(args: DataTableFixedLayoutProps = {}) => {
  const factory: DataTablePluginFactory<T, DataTableFixedLayoutProps> = () => ({
    name: FIXED_LAYOUT_PLUGIN,

    args,
    component: DataTableFixedLayoutRender as FC<DataTableFixedLayoutProps & DataTablePluginComponentProps<T>>,

    reducer: (gridState: GridState<T>, action: GridAction<T>) => {
      const state = gridState as GridStateWithSizes<T>;
      switch (action.type) {
        case "DataTable/updateColumnWidths": {
          const { tableStyle, columnSizes: priorSizes = [] } = state;
          const { sizes: columnSizes, resized, tableWidth } = action;

          return areSame(priorSizes, columnSizes)
            ? state
            : {
                ...state,
                resized,
                columnSizes,
                tableStyle: mergeIfDifferent(tableStyle, { tableLayout: "fixed", width: tableWidth })
              };
        }
        case "DataTable/load": {
          const { tableStyle, resized, columnSizes = [] } = state;
          // reset size array when new data is loaded (and user hasn't manually resized)
          return resized || columnSizes.length === 0
            ? state
            : // switch to auto layout to get a natural size for the new data
              { ...state, columnSizes: [], tableStyle: mergeIfDifferent(tableStyle, { tableLayout: "auto" }) };
        }
        case "DataTable/reset": {
          const { tableStyle, columnSizes = [], resized } = state;
          const { clearResizing } = action;

          if (columnSizes.length === 0) {
            return state;
          }

          // reset size array when new data is loaded (and user hasn't manually resized) - for Images
          return {
            ...state,
            resized: resized && !clearResizing,
            columnSizes: [],
            tableStyle: mergeIfDifferent(tableStyle, { tableLayout: "auto" })
          };
        }
        default:
          return state;
      }
    }
  });
  factory.pluginName = FIXED_LAYOUT_PLUGIN;
  return factory;
};
