import React, { forwardRef, FunctionComponent } from "react";
import clsx from "clsx";
import { Button, IButtonProps } from "./Button";
import styles from "./Button.module.scss";

export const IconButton: FunctionComponent<IButtonProps> = forwardRef<HTMLAnchorElement, IButtonProps>(
  ({ className, ...other }, ref) => {
    return <Button className={clsx(styles.icon, className)} ref={ref} {...other} />;
  }
);
IconButton.displayName = "IconButton";
