import React, { FC, useCallback } from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { DataTablePluginFactory, GridAction, GridState, RankingRecord } from "../DataTable.types";
import { SORTER_PLUGIN } from "./DataSorter";
import { VIRTUALIZATION_PLUGIN } from "./DataVirtualization";
import { assertCompatiblePlugins, assertPrerequisitePlugins } from "./plugin-utils";
import { useDataTableDispatcher } from "../DataTableStateProvider";
import { FIXED_LAYOUT_PLUGIN } from "./DataTableFixedLayout";
import { COLUMN_RESIZER_PLUGIN } from "./DataColumnResizer";
import { DataRankingProps } from "./Plugins.types";
import { useEventCallback } from "../../../hooks";

export const RANKING_PLUGIN = "Ranking";

export type GridStateWithRanking = GridState<unknown> & {
  rankingSupported: boolean;
  rankingKey: string | null;
};

const rank = (key: string | null): RankingRecord => ({ type: "DataTable/rankingRecord", key });

const DataRankingComponent: FC<DataRankingProps> = ({ onRankingChanged, children }) => {
  const dispatch = useDataTableDispatcher();
  const handleRankChanged = useEventCallback(onRankingChanged);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      dispatch(rank(null));
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }

      handleRankChanged({ oldIndex: result.source.index, newIndex: result.destination.index });
    },
    [dispatch, handleRankChanged]
  );

  const handleDragStart = useCallback(
    (start: DragStart) => {
      dispatch(rank(start.draggableId));
    },
    [dispatch]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={handleDragStart}>
      {children}
    </DragDropContext>
  );
};

// eslint-disable-next-line func-style
export function DataRanking<T>(args: DataRankingProps) {
  const factory: DataTablePluginFactory<T, DataRankingProps> = ({ plugins }) => {
    assertCompatiblePlugins(plugins!, [SORTER_PLUGIN, VIRTUALIZATION_PLUGIN], RANKING_PLUGIN);
    assertPrerequisitePlugins(plugins!, [FIXED_LAYOUT_PLUGIN], COLUMN_RESIZER_PLUGIN);

    return {
      component: DataRankingComponent,
      args,

      reducer: (state: GridState<T>, action: GridAction<T>) => {
        switch (action.type) {
          case "DataTable/rankingRecord":
            return { ...state, rankingKey: action.key };
          case "DataTable/init":
            return { ...state, rankingSupported: true };
          default:
            return state;
        }
      }
    };
  };
  factory.pluginName = RANKING_PLUGIN;
  return factory;
}
