import React, { FunctionComponent } from "react";
import { Edge, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Checkbox, Form, RequiredState } from "@plex/react-components";
import { camelCaseToSpacesRegEx } from "./FormConstants";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { IDataParam, INodeProperty } from "../NodeTypes/Base";
import "./NodePropertiesForm.scss";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { IDocumentObjectSchema } from "../FlowDocument/FlowDocumentModel";
import { getSchema, getSchemaId } from "../NodeTypes/DataSchemas";
import { DataType } from "../NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../FlowDocument/PropertyTypeDefinitions";

export interface IInputsFormSectionProps extends INodeConfigPropertiesProps {
  onEdgeDelete: (edge: Edge) => void;
}

export const EnableInputsFormSection: FunctionComponent<IInputsFormSectionProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  let inputs = props.node?.data.nodeProperties.inputs;

  // Check if groups are defined for the form section, by having multiple enabled input sections.
  // If defined, filter the inputs based on the schema for the group.
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(props.node.type!)!;
  const enabledInputSections: INodeProperty[] = nodeDefinition.nodeConfigProperties.filter(
    (p: INodeProperty) => p.propertyType === NodeConfigPropertyType.EnabledInputs
  );
  let groups: {
    sectionName: string;
    schemaId: string;
  }[] = [];
  if (enabledInputSections.length > 1) {
    const sectionSchemaIds = enabledInputSections.map((p: INodeProperty) => {
      return { sectionName: p.name, schemaId: getSchemaId(DataType.OBJECT, props.node, [p.name]) };
    });
    if (sectionSchemaIds.every((section: { sectionName: string; schemaId: string }) => section.schemaId)) {
      groups = sectionSchemaIds;
    }
  }

  if (groups?.length > 0) {
    let schemaProperties = {};
    const group = groups.find((g: { sectionName: string; schemaId: string }) => g.sectionName === props.name)!;
    const schema = getSchema(group.schemaId) as unknown as IDocumentObjectSchema;
    if (schema) {
      schemaProperties = { ...schemaProperties, ...schema.properties };
      inputs = Object.keys(inputs)
        .filter((input) => Object.hasOwn(schemaProperties, input))
        .reduce((result, input) => Object.assign(result, { [input]: inputs[input] }), {});
    }
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Form.Section title={props.sectionName} key={props.sectionName}>
          <div className="input-select-section flowinput-scroll-container enable-io-formsection">
            {Object.keys(inputs).map((inputName: string) => {
              const input: IDataParam = inputs[inputName];
              let readableInputName = inputName;
              readableInputName = readableInputName.charAt(0).toUpperCase() + readableInputName.slice(1);
              readableInputName = readableInputName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
              let readableType = input.type.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5").toLowerCase();

              readableInputName = readableInputName.replaceAll("_", " ");

              // Use label if available
              if (input.label) {
                readableInputName = input.label;
              }

              return (
                <Form.FieldPair
                  labelText={readableInputName}
                  key={inputName}
                  required={input.required ? RequiredState.required : undefined}
                >
                  <div style={{ display: "flex", marginTop: "8px" }}>
                    <Checkbox
                      checked={inputs[inputName].enabled === true}
                      disabled={input.required}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.node!.data.nodeProperties.inputs[inputName].enabled = e.currentTarget.checked;
                        if (e.currentTarget.checked === false) {
                          let deletedEdge = reactFlowInstance
                            .getEdges()
                            .filter((e: Edge<any>) => e.sourceHandle === inputName + "-" + input.type + "-input")[0]!;
                          reactFlowInstance.setEdges(
                            reactFlowInstance
                              .getEdges()
                              .filter((e: Edge<any>) => e.sourceHandle !== inputName + "-" + input.type + "-input")
                          );
                          if (deletedEdge) {
                            props.onEdgeDelete(deletedEdge);
                            forceNodeUpdate(
                              reactFlowInstance.getNode(deletedEdge.target)!,
                              updateNodeInternals,
                              reactFlowInstance
                            );
                          }
                        }
                        setState({ value: props.node!.data.nodeProperties.inputs[inputName].enabled });
                        props.forceNodeUpdate();
                      }}
                    />
                    <div className="enable-io-formsection-readabletype">{readableType}</div>
                  </div>
                </Form.FieldPair>
              );
            })}
          </div>
        </Form.Section>
      </div>
    </div>
  );
};
