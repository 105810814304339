import React, { FC, ReactNode } from "react";
import { Info2Icon } from "@plex/icons";
import { withNoPrinting } from "@plex/react-xml-renderer";
import { ICommonProps } from "../Common.types";
import { Popover } from "../Popover";
import styles from "./Form.module.scss";

export interface IFormRowInfoProps extends ICommonProps {
  /** Info Header Content */
  header?: ReactNode;
}

const HtmlFormRowInfo: FC<IFormRowInfoProps> = ({ id, children, header, className }) => {
  const [isVisible, setVisible] = React.useState(false);
  const openDataId = `rowInfo-open-icon-${id ?? ""}`;
  const closeDataId = `rowInfo-close-x-${id ?? ""}`;

  const anchor = (
    <div className={styles.formInfoAnchor}>
      <a onClick={() => setVisible(v => !v)} data-testid={openDataId}>
        <Info2Icon className={styles.formInfoIcon} />
      </a>
    </div>
  );

  return (
    <div id={id} className={className} data-testid="plex-form-row-info">
      <div className={styles.formInfo}>
        <Popover
          anchor={anchor}
          visible={isVisible}
          arrow={false}
          theme="info"
          maxWidth="120vw"
          placement="top"
          distance={-15}
          hideOnClick
          onHidden={() => setVisible(false)}
        >
          <Popover.Body>
            <div className={styles.formInfoContentContainer}>
              <div data-testid={closeDataId} onClick={() => setVisible(false)} className={styles.formInfoAnchorClose}>
                ×
              </div>
              <div className={styles.formInfoContent}>
                <div className={styles.formInfoContentHeader}>{header}</div>
                <div className={styles.formInfoContentText}>{children}</div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </div>
    </div>
  );
};

export const FormRowInfo = withNoPrinting(HtmlFormRowInfo);
