import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { useDataTableSelector } from "@components/DataTable/DataTableStateProvider";
import { SortingProps } from "@components/DataTable/Plugins/Plugins.types";
import { DataTablePluginComponentProps, DataTablePluginFactory, GridAction, GridState } from "../DataTable.types";
import { handleToggleSort, handleInitialSort, GridStateWithSort, interceptLoadForSort } from "./DataSorter.actions";

export const SORTER_PLUGIN = "Sorter";
const STATE_SELECTOR = <T>(s: GridState<T>) => s as GridStateWithSort<T>;

// eslint-disable-next-line func-style
function DataSorterRender<T>({
  onSortChange,
  children
}: PropsWithChildren<SortingProps<T> & DataTablePluginComponentProps<T>>) {
  const { sort } = useDataTableSelector(STATE_SELECTOR);

  const callbackRef = useRef(onSortChange);

  useEffect(() => {
    callbackRef.current = onSortChange;
  }, [onSortChange]);

  useEffect(() => {
    if (typeof callbackRef.current === "function") {
      callbackRef.current(sort.sorts);
    }
  }, [sort]);

  return children;
}

export const DataSorter = <T>(args: SortingProps<T> = {}) => {
  const factory: DataTablePluginFactory<T, SortingProps<T>> = () => {
    return {
      name: SORTER_PLUGIN,
      args,

      component: DataSorterRender as FC<SortingProps<T> & DataTablePluginComponentProps<T>>,

      reducer: (gridState: GridState<T>, action: GridAction<T>) => {
        const state = gridState as GridStateWithSort<T>;
        switch (action.type) {
          case "DataTable/init":
            return { ...state, sort: { sorts: [], fixed: false, unsorted: [] } };
          case "DataTable/load":
            return interceptLoadForSort<T>(state, action);
          case "DataTable/toggleSort":
            return handleToggleSort<T>(state, action);
          case "DataTable/setInitialSort":
            return handleInitialSort<T>(state, action);
          default:
            return state;
        }
      }
    };
  };
  factory.pluginName = SORTER_PLUGIN;
  return factory;
};
