import { BannerStatus, IBannerContext } from "@plex/react-components";
import { Node, ReactFlowInstance, getRectOfNodes, Rect, Edge, UpdateNodeInternals, XYPosition } from "reactflow";
import { forceNodeUpdate } from "../../Util/NodeUtil";
import { camelCaseToSpacesRegEx } from "../../NodePropertiesForm/FormConstants";
import { getNewId } from "../../DesignerContainer/DesignerContainer";

const camelCaseToReadable = (string: string) => {
  let readableString = string.charAt(0).toUpperCase() + string.slice(1);
  readableString = readableString.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
  return readableString;
};

export const groupSelectedNodes = (
  reactFlowInstance: ReactFlowInstance,
  bannerContext: IBannerContext,
  updateNodeInternals: UpdateNodeInternals
) => {
  let selectedNodes = reactFlowInstance.getNodes().filter((n) => n.selected);
  let group: Node;
  let groups: any = selectedNodes.filter((n) => n.type === "flowGroup");
  let children = selectedNodes.filter((n) => n.type !== "flowGroup");

  if (groups.filter((g) => g.data.nodeProperties.isCollapsed === true).length !== 0) {
    return;
  }

  if (groups.length > 1) {
    bannerContext.addMessage("Cannot group, more than one group selected.", BannerStatus.warning);
    return;
  }
  let parentedChildren = children.filter(
    (n) =>
      n.data.nodeProperties.parentNode && (groups.length === 0 || n.data.nodeProperties.parentNode !== groups[0].id)
  );
  if (parentedChildren.length > 0) {
    bannerContext.addMessage("Cannot group, node is already grouped.", BannerStatus.warning);
    return;
  }
  if (selectedNodes.filter((n) => n.type === "start").length > 0) {
    bannerContext.addMessage("Cannot group, start node cannot be grouped.", BannerStatus.warning);
    return;
  }
  if (selectedNodes.filter((n) => n.type === "stop").length > 0) {
    bannerContext.addMessage("Cannot group, output node cannot be grouped.", BannerStatus.warning);
    return;
  }
  if (groups.length === 1) {
    group = groups[0];
  } else {
    let groupRect = getFitRect(children);
    group = {
      id: getNewId("flowGroup", reactFlowInstance),
      type: "flowGroup",
      position: { x: groupRect.x, y: groupRect.y },
      width: groupRect.width,
      height: groupRect.height,
      data: {
        nodeProperties: {
          type: "Group",
          name: "",
          groupWidth: groupRect.width,
          groupHeight: groupRect.height,
          lastGroupPosition: { x: groupRect.x + groupRect.width, y: groupRect.y + groupRect.height },
          isCollapsed: false,
          groupInput: [],
          groupOutput: []
        }
      },
      zIndex: -1001,
      selected: true
    };
  }

  let isNewGroup = false;
  let addingNodes = children.filter((n) => !n.data.nodeProperties.parentNode);
  reactFlowInstance.setNodes(
    reactFlowInstance.getNodes().filter((n) => addingNodes.filter((c) => c.id === n.id).length === 0)
  );
  addingNodes.forEach((c) => {
    addToParent(c, group);
    c.selected = false;
  });
  if (groups.length === 0) {
    addingNodes.push(group);
    isNewGroup = true;
  } else {
    fitGroup(group, reactFlowInstance);
  }
  reactFlowInstance.addNodes(addingNodes);
  addingNodes.forEach((n) => updateNodeInternals(n.id));
  rewireEdges(group, reactFlowInstance, updateNodeInternals);
  if (isNewGroup) {
    fitGroup(group, reactFlowInstance);
  }
};

const getSourceHandlePosition = (edge: Edge, reactFlowInstance: ReactFlowInstance) => {
  let node = reactFlowInstance.getNode(edge.source);
  if (node!.data.nodeProperties.inputs) {
    let inputName = edge.sourceHandle?.split("-")[0]!;
    let yOffset = Object.keys(node!.data.nodeProperties.inputs)
      .filter((inputName: string) => node!.data.nodeProperties.inputs[inputName].enabled)
      .indexOf(inputName);
    return { x: node!.position.x, y: node!.position.y + yOffset * 9 };
  }

  return { x: node!.position.x, y: node!.position.y };
};

export const rewireEdges = (
  group: Node,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  callback: (() => void) | undefined = undefined,
  deletingGroup: boolean = false
) => {
  let inputRewire = getInputRewireEdges(group, reactFlowInstance);
  let outputRewire = getOutputRewireEdges(group, reactFlowInstance);

  const performRewire = () => {
    let deletingEdges = inputRewire.deletingEdges.concat(outputRewire.deletingEdges);
    if (deletingGroup) {
      let groupEdges = reactFlowInstance.getEdges().filter((e) => e.source === group.id || e.target === group.id);
      deletingEdges.concat(groupEdges);
    }
    reactFlowInstance.deleteElements({ edges: deletingEdges });
    reactFlowInstance.addEdges(inputRewire.addingEdges.concat(outputRewire.addingEdges));

    group.data.nodeProperties.groupInput = group.data.nodeProperties.groupInput.filter(
      (i: any) => inputRewire.unusedRewiredInputs.indexOf(i) === -1
    );
    let inputIndex = -1;
    group.data.nodeProperties.groupInput.forEach((i: any) => (i.index = ++inputIndex));

    group.data.nodeProperties.groupOutput = group.data.nodeProperties.groupOutput.filter(
      (i: any) => outputRewire.unusedRewiredOutputs.indexOf(i) === -1
    );
    let outputIndex = -1;
    group.data.nodeProperties.groupOutput.forEach((i: any) => (i.index = ++outputIndex));

    forceNodeUpdate(group, updateNodeInternals, reactFlowInstance, callback);
  };

  forceNodeUpdate(group, updateNodeInternals, reactFlowInstance, performRewire);
};

export const getEdgeId = (edge: Edge) => {
  return "reactflow__edge-" + edge.source + edge.sourceHandle + "-" + edge.target + edge.targetHandle;
};

export const getInputRewireEdges = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let newEdges: Edge[] = [];
  let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === group.id);
  let inputEdgesToOutside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        children.filter((n) => e.source === n.id && n.type !== "flowGroup").length !== 0 &&
        children.concat([group]).filter((c) => c.id === e.target).length === 0
    );
  let inputEdgesToInside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        children.filter(
          (n) =>
            n.id === e.target &&
            e.source === group.id &&
            e.sourceHandle?.split("-")[0] !== "internal" &&
            e.sourceHandle !== "FlowInGroupInternalHandle"
        ).length !== 0
    );
  let internalOutputEdgesToInside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        inputEdgesToInside.filter(
          (ie) =>
            e.target === group.id &&
            (e.targetHandle === "FlowOutGroupInternalHandle" ||
              e.targetHandle ===
                "internal-" + ie.sourceHandle?.split("-")[0] + "-" + ie.sourceHandle?.split("-")[1] + "-output")
        ).length !== 0
    );
  let keepingInternalOutputEdgesToInside: Edge[] = [];
  let newInternalOutputEdgesToInside: Edge[] = [];
  internalOutputEdgesToInside.forEach((e) => {
    let movingEdge = inputEdgesToInside.filter(
      (ie) => ie.sourceHandle?.split("-")[0] === e.targetHandle?.split("-")[1]
    )[0];
    if (e.targetHandle === "FlowOutGroupInternalHandle") {
      movingEdge = inputEdgesToInside.filter(
        (ie) => ie.sourceHandle === "FlowInGroupExternalHandle" && e.targetHandle === "FlowOutGroupInternalHandle"
      )[0];
    }
    if (movingEdge) {
      let newEdge: Edge = {
        id: "",
        source: e.source,
        target: movingEdge.target,
        sourceHandle: e.sourceHandle,
        targetHandle: movingEdge.targetHandle,
        className: e.targetHandle === "FlowOutGroupInternalHandle" ? "control-flow-edge" : ""
      };
      newEdge.id = getEdgeId(newEdge);
      if (newInternalOutputEdgesToInside.filter((e) => e.id === newEdge.id).length === 0) {
        newInternalOutputEdgesToInside.push(newEdge);
      }
    } else {
      keepingInternalOutputEdgesToInside.push(e);
    }
  });
  let rewiredInputOutside: any = {};
  inputEdgesToOutside
    .sort((a, b) => getSourceHandlePosition(a, reactFlowInstance).y - getSourceHandlePosition(b, reactFlowInstance).y)
    .forEach((e) => {
      if (
        e.sourceHandle === "FlowOutGroupInternalHandle" ||
        e.sourceHandle === "FlowInGroupInternalHandle" ||
        e.sourceHandle === "FlowOutGroupExternalHandle"
      ) {
        return;
      }

      let isControlFlowEdge =
        e.targetHandle?.indexOf("FlowOutHandle") === 0 && e.sourceHandle?.indexOf("FlowInHandle") === 0;

      let propertyName = camelCaseToReadable(e.sourceHandle?.split("-")[0]!);
      let readablePropertyName = propertyName;
      let propertyType = e.sourceHandle?.split("-")[1];
      if (!rewiredInputOutside[e.targetHandle!]) {
        rewiredInputOutside[e.targetHandle!] = {
          index: group.data.nodeProperties.groupInput.length,
          name: readablePropertyName,
          type: propertyType,
          fromRewire: true
        };
        if (
          !isControlFlowEdge &&
          group.data.nodeProperties.groupInput.filter((i: any) => i.name === rewiredInputOutside[e.targetHandle!].name)
            .length === 0
        ) {
          group.data.nodeProperties.groupInput.push(rewiredInputOutside[e.targetHandle!]);
        }
      } else {
        propertyName = rewiredInputOutside[e.targetHandle!].name;
      }
      let edgeA: Edge = {
        id: "",
        source: e.source,
        sourceHandle: e.sourceHandle,
        target: group.id,
        targetHandle: isControlFlowEdge
          ? "FlowOutGroupInternalHandle"
          : "internal-" + propertyName + "-" + propertyType + "-output",
        className: isControlFlowEdge ? "control-flow-edge" : ""
      };
      edgeA.id = getEdgeId(edgeA);
      if (!reactFlowInstance.getEdge(edgeA.id) && newEdges.filter((e) => e.id === edgeA.id).length === 0) {
        newEdges.push(edgeA);
      }

      let edgeB: Edge = {
        id: "",
        source: group.id,
        sourceHandle: isControlFlowEdge ? "FlowInGroupExternalHandle" : propertyName + "-" + propertyType + "-input",
        target: e.target,
        targetHandle: e.targetHandle,
        className: isControlFlowEdge ? "control-flow-edge" : ""
      };
      edgeB.id = getEdgeId(edgeB);
      if (!reactFlowInstance.getEdge(edgeB.id) && newEdges.filter((e) => e.id === edgeB.id).length === 0) {
        newEdges.push(edgeB);
      }
    });

  let result = {
    addingEdges: newEdges.concat(newInternalOutputEdgesToInside),
    deletingEdges: inputEdgesToOutside
      .concat(inputEdgesToInside)
      .concat(
        internalOutputEdgesToInside.filter(
          (e) => keepingInternalOutputEdgesToInside.filter((ke) => ke.id === e.id).length === 0
        )
      ),
    unusedRewiredInputs: [] as any[]
  };

  let groupEdges = reactFlowInstance.getEdges().filter((e) => e.source === group.id || e.target === group.id);
  groupEdges = groupEdges.concat(result.addingEdges);
  groupEdges = groupEdges.filter((e) => result.deletingEdges.filter((de) => de.id === e.id).length === 0);
  result.unusedRewiredInputs = group.data.nodeProperties.groupInput.filter(
    (i: any) =>
      groupEdges.filter(
        (e) =>
          e.targetHandle === "internal-" + i.name + "-" + i.type + "-output" ||
          e.sourceHandle === i.name + "-" + i.type + "-input"
      ).length === 0
  );

  return result;
};

export const getOutputRewireEdges = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let newEdges: Edge[] = [];
  let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === group.id);
  let outputEdgesToOutside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        children.filter((n) => e.target === n.id && n.type !== "flowGroup").length !== 0 &&
        children.concat([group]).filter((c) => c.id === e.source).length === 0
    );
  let outputEdgesToInside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        children.filter(
          (n) =>
            n.id === e.source &&
            e.target === group.id &&
            e.targetHandle?.split("-")[0] !== "internal" &&
            e.targetHandle !== "FlowOutGroupInternalHandle"
        ).length !== 0
    );
  let internalOutputEdgesToInside = reactFlowInstance
    .getEdges()
    .filter(
      (e) =>
        outputEdgesToInside.filter(
          (ie) =>
            e.source === group.id &&
            (e.sourceHandle === "FlowInGroupInternalHandle" ||
              e.sourceHandle ===
                "internal-" + ie.targetHandle?.split("-")[1] + "-" + ie.targetHandle?.split("-")[2] + "-input")
        ).length !== 0
    );
  let keepingInternalOutputEdgesToInside: Edge[] = [];
  let newInternalOutputEdgesToInside: Edge[] = [];
  internalOutputEdgesToInside.forEach((e) => {
    let movingEdge = outputEdgesToInside.filter(
      (ie) => ie.targetHandle?.split("-")[1] === e.sourceHandle?.split("-")[1]
    )[0];
    if (e.sourceHandle === "FlowInGroupInternalHandle") {
      movingEdge = outputEdgesToInside.filter(
        (ie) => ie.targetHandle === "FlowOutGroupExternalHandle" && e.sourceHandle === "FlowInGroupInternalHandle"
      )[0];
    }
    if (movingEdge) {
      let newEdge: Edge = {
        id: "",
        source: movingEdge.source,
        target: e.target,
        sourceHandle: movingEdge.sourceHandle,
        targetHandle: e.targetHandle,
        className: e.sourceHandle === "FlowInGroupInternalHandle" ? "control-flow-edge" : ""
      };
      newEdge.id = getEdgeId(newEdge);
      if (newInternalOutputEdgesToInside.filter((e) => e.id === newEdge.id).length === 0) {
        newInternalOutputEdgesToInside.push(newEdge);
      }
    } else {
      keepingInternalOutputEdgesToInside.push(e);
    }
  });
  let rewiredOutputOutside: any = {};
  outputEdgesToOutside
    .sort((a, b) => getSourceHandlePosition(a, reactFlowInstance).y - getSourceHandlePosition(b, reactFlowInstance).y)
    .forEach((e) => {
      if (
        e.sourceHandle === "FlowOutGroupInternalHandle" ||
        e.sourceHandle === "FlowInGroupInternalHandle" ||
        e.sourceHandle === "FlowInGroupExternalHandle"
      ) {
        return;
      }

      let isControlFlowEdge =
        e.targetHandle?.indexOf("FlowOutHandle") === 0 && e.sourceHandle?.indexOf("FlowInHandle") === 0;

      let propertyName = camelCaseToReadable(e.sourceHandle?.split("-")[0]!);
      let readablePropertyName = propertyName;
      let propertyType = e.sourceHandle?.split("-")[1];
      if (!rewiredOutputOutside[e.sourceHandle!]) {
        rewiredOutputOutside[e.sourceHandle!] = {
          index: group.data.nodeProperties.groupOutput.length,
          name: readablePropertyName,
          type: propertyType,
          fromRewire: true
        };
        if (
          !isControlFlowEdge &&
          group.data.nodeProperties.groupOutput.filter(
            (i: any) => i.name === rewiredOutputOutside[e.sourceHandle!].name
          ).length === 0
        ) {
          group.data.nodeProperties.groupOutput.push(rewiredOutputOutside[e.sourceHandle!]);
        }
      } else {
        propertyName = rewiredOutputOutside[e.sourceHandle!].name;
      }

      let edgeA: Edge = {
        id: "",
        source: e.source,
        sourceHandle: e.sourceHandle,
        target: group.id,
        targetHandle: isControlFlowEdge
          ? "FlowOutGroupExternalHandle"
          : "external-" + propertyName + "-" + propertyType + "-output",
        className: isControlFlowEdge ? "control-flow-edge" : ""
      };
      edgeA.id = getEdgeId(edgeA);
      if (!reactFlowInstance.getEdge(edgeA.id) && newEdges.filter((e) => e.id === edgeA.id).length === 0) {
        newEdges.push(edgeA);
      }

      let edgeB: Edge = {
        id: "",
        source: group.id,
        sourceHandle: isControlFlowEdge
          ? "FlowInGroupInternalHandle"
          : "internal-" + propertyName + "-" + propertyType + "-input",
        target: e.target,
        targetHandle: e.targetHandle,
        className: isControlFlowEdge ? "control-flow-edge" : ""
      };
      edgeB.id = getEdgeId(edgeB);
      if (!reactFlowInstance.getEdge(edgeB.id) && newEdges.filter((e) => e.id === edgeB.id).length === 0) {
        newEdges.push(edgeB);
      }
    });

  let result = {
    addingEdges: newEdges.concat(newInternalOutputEdgesToInside),
    deletingEdges: outputEdgesToOutside
      .concat(outputEdgesToInside)
      .concat(
        internalOutputEdgesToInside.filter(
          (e) => keepingInternalOutputEdgesToInside.filter((ke) => ke.id === e.id).length === 0
        )
      ),
    unusedRewiredOutputs: [] as any[]
  };

  let groupEdges = reactFlowInstance.getEdges().filter((e) => e.source === group.id || e.target === group.id);
  groupEdges = groupEdges.concat(result.addingEdges);
  groupEdges = groupEdges.filter((e) => result.deletingEdges.filter((de) => de.id === e.id).length === 0);
  result.unusedRewiredOutputs = group.data.nodeProperties.groupOutput.filter(
    (i: any) =>
      groupEdges.filter(
        (e) =>
          e.targetHandle === "external" + i.name + "-" + i.type + "-output" ||
          e.sourceHandle === "internal-" + i.name + "-" + i.type + "-input"
      ).length === 0
  );

  return result;
};

export const ungroupSelectedNodes = (
  reactFlowInstance: ReactFlowInstance,
  bannerContext: IBannerContext,
  setNodes: React.Dispatch<React.SetStateAction<Node<any, string | undefined>[]>>,
  updateNodeInternals: UpdateNodeInternals
) => {
  let allNodes = reactFlowInstance.getNodes();
  let selectedNodes = allNodes.filter((n) => n.selected);
  let groupNodes = selectedNodes.filter((n) => n.type === "flowGroup");
  let ungroupingNodes = selectedNodes.filter((n) => n.type !== "flowGroup");
  let group: any = groupNodes[0];

  if (!group) {
    bannerContext.addMessage("Cannot ungroup, no group selected.", BannerStatus.warning);
    return;
  }

  if (group.data.nodeProperties.isCollapsed) {
    bannerContext.addMessage("Cannot ungroup, expand group first.", BannerStatus.warning);
    return;
  }

  allNodes
    .filter((n) => n.data.nodeProperties.parentNode === group.id)
    .forEach((c) => {
      if (ungroupingNodes.filter((u) => u.id === c.id).length === 0) {
        ungroupingNodes.push(c);
      }
    });

  let groupConnectedNodes = reactFlowInstance.getNodes().filter((n) => !n.data.nodeProperties.parentNode);
  groupConnectedNodes.forEach((n) => (n.data.nodeProperties.parentNode = group.id));
  rewireEdges(
    group,
    reactFlowInstance,
    updateNodeInternals,
    () => {
      groupConnectedNodes.forEach((n) => (n.data.nodeProperties.parentNode = ""));

      setNodes((nodes) =>
        nodes.filter((n) => n.id !== group.id && ungroupingNodes.filter((ug) => n.id === ug.id).length === 0)
      );
      ungroupingNodes.forEach((n) => {
        let parentGroup = allNodes.filter((g) => g.id === n.data.nodeProperties.parentNode)[0];
        if (parentGroup) {
          removeFromParent(n);
          n.selected = true;
        }
      });

      setTimeout(() => {
        let currentNodes = reactFlowInstance.getNodes();
        ungroupingNodes.forEach((ug) => currentNodes.push(ug));
        setNodes(currentNodes);
      });
    },
    true
  );
};

const addToParent = (node: Node, group: Node) => {
  node.data.nodeProperties.parentNode = group.id;
};

const removeFromParent = (node: Node) => {
  node.data.nodeProperties.parentNode = "";
};

const getFitRect = (nodes: Node[]): Rect => {
  const marginMin = 100;
  const propWidth = 100;
  const headerHeight = 56;
  const footerHeight = 10;
  let rect = getRectOfNodes(nodes);

  rect.x -= propWidth + marginMin;
  rect.width += (propWidth + marginMin) * 2;

  rect.y -= headerHeight + marginMin;
  rect.height += headerHeight + footerHeight + marginMin * 2;

  return rect;
};

export const fitGroup = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === group.id);
  let rect = getFitRect(children);
  group.data.nodeProperties.groupWidth = rect.width;
  group.data.nodeProperties.groupHeight = rect.height;

  // Force node to update by always making position a new value.
  group.position.x = rect.x + Math.random() * 0.00001;
  group.position.y = rect.y + Math.random() * 0.00001;
  group.data.nodeProperties.lastGroupPosition = { x: group.position.x, y: group.position.y };
};

export const addToGroup = (
  node: Node,
  group: Node,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  if (
    node.type !== "flowGroup" &&
    group.data.nodeProperties.isCollapsed !== true &&
    node.type !== "start" &&
    node.type !== "stop"
  ) {
    reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n) => n.id !== node.id));
    addToParent(node, group);
    reactFlowInstance.addNodes(node);
    updateNodeInternals(node.id);
  }
  setTimeout(() => {
    beforeChildPositionChange(group);
    fitGroup(group, reactFlowInstance);
    onChildPositionChange(group, reactFlowInstance);
  });
};

export const getIntersectingGroups = (node: Node, reactFlowInstance: ReactFlowInstance) => {
  return reactFlowInstance
    .getNodes()
    .filter(
      (n) => n.type === "flowGroup" && reactFlowInstance.isNodeIntersecting(getRectOfNodes([n]), getRectOfNodes([node]))
    );
};

export const deleteChildren = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === group.id);
  children.forEach((c) => (c.data.nodeProperties.parentNode = undefined));
};

const expandCollapseSelectedGroups = (
  isCollapsed: boolean | undefined = undefined,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  let selectedGroups = reactFlowInstance.getNodes().filter((n) => n.selected && n.type === "flowGroup");
  if (selectedGroups.length > 1) {
    return;
  }
  selectedGroups.forEach((g) => {
    let childNodes = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === g.id);
    if (g.data.nodeProperties.isCollapsed !== undefined && g.data.nodeProperties.isCollapsed === isCollapsed) {
      return;
    }

    if (isCollapsed === undefined) {
      isCollapsed = !g.data.nodeProperties.isCollapsed;
      if (childNodes.length === 0 && isCollapsed) {
        return;
      }
      g.data.nodeProperties.isCollapsed = isCollapsed;
    } else {
      g.data.nodeProperties.isCollapsed = isCollapsed;
    }

    if (childNodes.length === 0 && isCollapsed) {
      return;
    }

    g.position.x += Math.random() * 0.0001;
    updateNodeInternals(g.id);

    let childEdges = reactFlowInstance
      .getEdges()
      .filter(
        (e) =>
          childNodes.concat([g]).filter((n) => e.source === n.id).length !== 0 &&
          childNodes.concat([g]).filter((n) => e.target === n.id).length !== 0
      );

    childNodes.forEach((n) => (n.data.nodeProperties.isCollapsed = isCollapsed));

    if (isCollapsed === false) {
      let xOffset = g.data.nodeProperties.groupWidth * 0.5 - g.width! * 0.5;
      let yOffset = g.data.nodeProperties.groupHeight * 0.5 - g.height! * 0.5;
      let oldPositionX = g.position.x;
      g.position.x -= xOffset;
      g.position.y -= yOffset;
      g.data.nodeProperties.oldWidth = g.width;
      g.data.nodeProperties.oldHeight = g.height;
      updateGroupPosition(g, reactFlowInstance);
      reactFlowInstance
        .getNodes()
        .filter((n) => n.id !== g.id && !n.data.nodeProperties.parentNode)
        .forEach((n) => {
          if (n.position.x + (n.width ?? 0) * 0.5 < oldPositionX + (g.width ?? 0) * 0.5) {
            n.position.x -= xOffset;
          } else {
            n.position.x += xOffset;
          }
          if (n.type === "flowGroup") {
            updateGroupPosition(n, reactFlowInstance);
          }
        });
    } else {
      let oldPositionX = g.position.x;
      let xOffset = g.data.nodeProperties.groupWidth * 0.5 - (g.data.nodeProperties.oldWidth ?? 200) * 0.5;
      let yOffset = g.data.nodeProperties.groupHeight * 0.5 - (g.data.nodeProperties.oldHeight ?? 100) * 0.5;
      g.position.x += xOffset;
      g.position.y += yOffset;

      reactFlowInstance
        .getNodes()
        .filter((n) => n.id !== g.id && !n.data.nodeProperties.parentNode)
        .forEach((n) => {
          if (n.position.x + (n.width ?? 0) * 0.5 < oldPositionX + (g.width ?? 0) * 0.5) {
            n.position.x += xOffset;
          } else {
            n.position.x -= xOffset;
          }
          if (n.type === "flowGroup") {
            updateGroupPosition(n, reactFlowInstance);
          }
        });
    }

    reactFlowInstance.setNodes((nodes) => nodes.filter((n) => childNodes.filter((cn) => cn.id === n.id).length === 0));
    reactFlowInstance.setEdges((edges) => edges.filter((e) => childEdges.filter((ce) => ce.id === e.id).length === 0));

    if (isCollapsed) {
      childNodes.forEach((n) => (n.className += " collapsed-group-child "));
      childEdges.forEach((e) => (e.className += " collapsed-group-child "));
    } else {
      childNodes.forEach((n) => (n.className = n.className!.replace(" collapsed-group-child ", " ")));
      childEdges.forEach((e) => (e.className = e.className!.replace(" collapsed-group-child ", " ")));
    }

    setTimeout(() => {
      reactFlowInstance.setNodes((nodes) => nodes.concat(childNodes));
      reactFlowInstance.setEdges((edges) => edges.concat(childEdges));
    });
  });
};

export const beforeChildPositionChange = (group: Node) => {
  group.data.nodeProperties.groupResizeStartRightBounds = group.position.x + group.data.nodeProperties.groupWidth;
  group.data.nodeProperties.groupResizeStartLeftBounds = group.position.x;
};

export const onChildPositionChange = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let otherNodes = reactFlowInstance.getNodes().filter((n) => n.id !== group.id && !n.data.nodeProperties.parentNode);
  otherNodes.forEach((n) => {
    let rightGroupBounds = group.position.x + group.data.nodeProperties.groupWidth;
    if (rightGroupBounds - (n.position.x + (n.width ?? 0)) > group.data.nodeProperties.groupWidth * 0.5) {
      n.position.x += group.position.x - group.data.nodeProperties.groupResizeStartLeftBounds;
    } else {
      n.position.x +=
        group.position.x + group.data.nodeProperties.groupWidth - group.data.nodeProperties.groupResizeStartRightBounds;
    }
    if (n.type === "flowGroup") {
      updateGroupPosition(n, reactFlowInstance);
    }
  });
};

export const updateGroupPosition = (group: Node, reactFlowInstance: ReactFlowInstance) => {
  let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === group.id);
  if (group.data.nodeProperties.lastGroupPosition) {
    children.forEach((n) => {
      n.position.x += group.position.x - group.data.nodeProperties.lastGroupPosition.x;
      n.position.y += group.position.y - group.data.nodeProperties.lastGroupPosition.y;
    });
  }
  group.data.nodeProperties.lastGroupPosition = { x: group.position.x, y: group.position.y };
};

export const expandSelectedGroups = (reactFlowInstance: ReactFlowInstance, updateNodeInternals: UpdateNodeInternals) =>
  expandCollapseSelectedGroups(false, reactFlowInstance, updateNodeInternals);

export const collapseSelectedGroups = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => expandCollapseSelectedGroups(true, reactFlowInstance, updateNodeInternals);

export const expandOrCollapseSelectedGroups = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => expandCollapseSelectedGroups(undefined, reactFlowInstance, updateNodeInternals);

export const getSavedGroupFromSelection = (reactFlowInstance: ReactFlowInstance, bannerContext: IBannerContext) => {
  let selectedGroup: any = reactFlowInstance.getNodes().filter((n) => n.selected && n.type === "flowGroup")[0];
  if (selectedGroup) {
    if (!selectedGroup.data.nodeProperties.name.trim()) {
      bannerContext.addMessage("Cannot export group, group must have a name.", BannerStatus.warning);
      return;
    }

    let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === selectedGroup.id);
    let groupEdges = reactFlowInstance
      .getEdges()
      .filter(
        (e) =>
          children.concat([selectedGroup]).filter((c) => e.source === c.id).length !== 0 &&
          children.concat([selectedGroup]).filter((c) => e.target === c.id).length !== 0
      );
    return {
      group: selectedGroup,
      children: children,
      edges: groupEdges
    };
  }

  return null;
};

export const importGroup = (
  content: { group: Node; children: Node[]; edges: Edge[] },
  reactFlowInstance: ReactFlowInstance,
  position: XYPosition,
  updateNodeInternals: UpdateNodeInternals
) => {
  let newNodes = reactFlowInstance.getNodes().concat([]);
  let groupNodes = content.children.concat([content.group]);
  let updatedEdgeSources: number[] = [];
  let updatedEdgeTargets: number[] = [];
  let newGroupId = content.group.id;
  groupNodes.forEach((n: Node) => {
    let oldId = n.id;
    n.id = getNewId(n.type!, reactFlowInstance);
    n.data.id = n.id;
    if (n.type === "flowGroup") {
      newGroupId = n.id;
      n.position = position;
    }
    newNodes.push(n);
    let index = 0;
    content.edges.forEach((e: Edge) => {
      if (e.source === oldId && updatedEdgeSources.indexOf(index) === -1) {
        e.source = n.id;
        e.id = getEdgeId(e);
        updatedEdgeSources.push(index);
      }
      if (e.target === oldId && updatedEdgeTargets.indexOf(index) === -1) {
        e.target = n.id;
        e.id = getEdgeId(e);
        updatedEdgeTargets.push(index);
      }
      index++;
    });
  });
  content.children.forEach((c: any) => (c.data.nodeProperties.parentNode = newGroupId));
  let settingNodes = reactFlowInstance.getNodes();
  settingNodes.filter((n) => n.id !== content.group.id).forEach((n) => (n.selected = false));
  settingNodes = settingNodes.concat(content.group).concat(content.children);
  reactFlowInstance.setNodes(settingNodes);
  setTimeout(() => {
    reactFlowInstance.setEdges((edges) => edges.concat(content.edges));

    let groupNode = reactFlowInstance.getNode(content.group.id)!;
    if (!groupNode.data.nodeProperties.isCollapsed) {
      expandCollapseSelectedGroups(true, reactFlowInstance, updateNodeInternals);
    }
  }, 100);

  return content.group;
};

export const getSavedGroups = () => {
  let savedGroupsJson = localStorage.getItem("savedGroups");
  let savedGroups;

  if (!savedGroupsJson) {
    savedGroups = [];
  } else {
    savedGroups = JSON.parse(savedGroupsJson);
  }

  return savedGroups;
};

export const getSavedGroup = (name: string) => {
  return getSavedGroups().filter((g: any) => g.group.data.nodeProperties.name === name)[0];
};

export const saveSelectedGroup = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext
) => {
  let selectedGroup: any = reactFlowInstance.getNodes().filter((n) => n.selected && n.type === "flowGroup")[0];
  if (selectedGroup) {
    if (!selectedGroup.data.nodeProperties.name.trim()) {
      bannerContext.addMessage("Cannot save group, group must have name.", BannerStatus.warning);
      return;
    }

    let children = reactFlowInstance.getNodes().filter((n) => n.data.nodeProperties.parentNode === selectedGroup.id);
    let groupEdges = reactFlowInstance
      .getEdges()
      .filter(
        (e) =>
          children.concat([selectedGroup]).filter((c) => e.source === c.id).length !== 0 &&
          children.concat([selectedGroup]).filter((c) => e.target === c.id).length !== 0
      );
    let savedGroup = {
      group: selectedGroup,
      children: children,
      edges: groupEdges
    };

    let savedGroups = getSavedGroups();
    let savedGroupIndex = savedGroups.indexOf(
      savedGroups.filter(
        (g: any) =>
          g.group.data.nodeProperties.name.toLowerCase().trim() ===
          savedGroup.group.data.nodeProperties.name.toLowerCase().trim()
      )[0]
    );
    if (savedGroupIndex === -1) {
      savedGroups.push(savedGroup);
    } else {
      savedGroups[savedGroupIndex] = savedGroup;
    }
    localStorage.setItem("savedGroups", JSON.stringify(savedGroups));
    forceNodeUpdate(selectedGroup, updateNodeInternals, reactFlowInstance);
  }
};

export const handleGroupNodeDrag = (node: Node, reactFlowInstance: ReactFlowInstance) => {
  if (!node.data.nodeProperties.parentNode && node.type !== "flowGroup") {
    return;
  }
  let expandedGroupNodes = reactFlowInstance
    .getNodes()
    .filter((n: Node<any>) => n.type === "flowGroup" && n.data.nodeProperties.isCollapsed === false);
  expandedGroupNodes.forEach((g: Node<any>) => {
    if (node.id !== g.id && !node.data.nodeProperties.parentNode) {
      let rightGroupBounds = g.position.x + g.data.nodeProperties.groupWidth;
      let leftGroupBounds = g.position.x - (node.width ?? 0);
      if (rightGroupBounds - (node.position.x + (node.width ?? 0)) > g.data.nodeProperties.groupWidth * 0.5) {
        if (node.position.x > leftGroupBounds) {
          node.position.x = Math.min(leftGroupBounds, node.position.x);
        }
      } else {
        if (node.position.x < rightGroupBounds) {
          node.position.x = Math.max(rightGroupBounds, node.position.x);
        }
      }
    }
  });

  if (node.type === "flowGroup") {
    updateGroupPosition(node, reactFlowInstance);
  }
  if (node.data.nodeProperties.parentNode) {
    let group = reactFlowInstance.getNode(node.data.nodeProperties.parentNode);
    fitGroup(group!, reactFlowInstance);
  }
};

export const deleteSavedGroup = (groupName: string, reactFlowInstance: ReactFlowInstance) => {
  let savedGroups = getSavedGroups();
  savedGroups = savedGroups.filter((g: any) => g.group.data.nodeProperties.name !== groupName);
  localStorage.setItem("savedGroups", JSON.stringify(savedGroups));

  reactFlowInstance.setNodes((nodes) => nodes);
};
