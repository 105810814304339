import React, { FunctionComponent } from "react";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeProps } from "reactflow";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { SortLabel } from "./SortLabel";
import { getSchema } from "../DataSchemas";
import { IDocumentListSchema, IDocumentObjectSchema } from "../../FlowDocument/FlowDocumentModel";

const listInputName = "list";
const resultOutputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ListSortNode",
  category: NodeTypeCategory.lists,
  controlOutputs: [],
  dataInputs: [{ enabled: true, type: DataType.OBJECTLIST, name: listInputName }],
  dataOutputs: [{ enabled: true, type: DataType.OBJECTLIST, name: resultOutputName }],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [
          DataType.OBJECT,
          DataType.STRING,
          DataType.BOOLEAN,
          DataType.INTEGER,
          DataType.DECIMAL,
          DataType.DATETIME,
          DataType.DATE,
          DataType.TIME
        ],
        dataProperties: [listInputName, resultOutputName]
      }
    },
    {
      name: "orderBy",
      label: "Order By",
      propertyType: NodeConfigPropertyType.DataSource
    },
    {
      name: "descending",
      label: "Descending",
      propertyType: NodeConfigPropertyType.Boolean
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any, nodeProperties: any) => {
    let outputList = Array.from(input.list as object[]);
    if (outputList?.length > 1 && nodeProperties.orderBy) {
      const listSchema = getSchema(input.list.schemaId)! as unknown as IDocumentListSchema;
      const listItemSchema = getSchema(listSchema.listItemSchema)! as unknown as IDocumentObjectSchema;
      const sortPropertyType: string = listItemSchema.properties[nodeProperties.orderBy]!.schema!;
      outputList.sort((a, b) => {
        let sortOrder = 1;
        if (nodeProperties.descending?.value) {
          sortOrder = -1;
        }
        const isDateType =
          sortPropertyType === "date" || sortPropertyType === "datetime" || sortPropertyType === "time";
        const propA = isDateType
          ? new Date(a[nodeProperties.orderBy as keyof typeof a])
          : a[nodeProperties.orderBy as keyof typeof a];
        const propB = isDateType
          ? new Date(b[nodeProperties.orderBy as keyof typeof b])
          : b[nodeProperties.orderBy as keyof typeof b];
        const result = propA < propB ? -1 : propA > propB ? 1 : 0;
        return result * sortOrder;
      });
    }
    return { output: { result: outputList }, activeControlHandleIds: [] };
  }
});

export const ListSortNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  data.nodeProperties.label = "Sort " + (data.nodeProperties.descending ? "Descending" : "Ascending");
  return (
    <BasicNodeLayout id={id} data={data}>
      <SortLabel data={data} />
    </BasicNodeLayout>
  );
};
