import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { IWithChildren } from "@components/Common.types";
import styles from "./MainMenu.module.scss";

export interface IMainMenuItemIconProps extends IWithChildren {
  /**
   * Element id
   */
  id?: string;
}

export const MainMenuItemIcon: FunctionComponent<IMainMenuItemIconProps> = ({ id, children }) => {
  return (
    <div id={id} className={clsx(styles.menuItemIcon)}>
      {children}
    </div>
  );
};

MainMenuItemIcon.displayName = "MainMenuItemIcon";
