import React, { FunctionComponent } from "react";
import { Dropdown } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { upperSnakeToReadable } from "../FlowDocument/DocumentProcessor";
import { IEnumOptions } from "../NodeTypes/Base";

export const operatorLabels: { [key: string]: string } = {
  EQUAL: "=",
  NOT_EQUAL: "≠",
  LESS: "<",
  GREATER: ">",
  LESS_EQUAL: "≤",
  GREATER_EQUAL: "≥",
  ADD: "+",
  DIVIDE: "÷",
  SUBTRACT: "-",
  MODULUS: "MOD",
  MULTIPLY: "×"
};

export const getOptionLabel = (value: string) => operatorLabels[value] ?? (value ? upperSnakeToReadable(value) : "");

export const EnumFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const [state, setState] = React.useState({
    description: props.node?.data.nodeProperties.name,
    comparisonOperator: props.node?.data.nodeProperties[props.name]
  });

  const enumOptions = props.options as IEnumOptions;
  let operators: { key: string; value: string | undefined }[] = Object.keys(enumOptions.values).map((key: string) => {
    const enumValue = enumOptions.values[key];
    let enumLabel = upperSnakeToReadable(enumOptions.values[key]);

    if (operatorLabels[enumValue]) {
      enumLabel = operatorLabels[enumValue]!;
    }
    return { key: enumValue, value: enumLabel };
  });
  if (operators.length > 0 && !props.node!.data.nodeProperties[props.name]) {
    props.node!.data.nodeProperties[props.name] = operators[0]?.key;
    props.forceNodeUpdate();
  }

  return operators.length === 0 ? null : (
    <Dropdown
      key={`${props.node!.type} Dropdown`}
      items={operators}
      keySelector={(item: { [key: string]: string }) => item.key}
      displaySelector={(item: { [key: string]: string }) => item.value}
      selected={[{ key: props.node?.data.nodeProperties[props.name] || "", value: "" }]}
      onSelectionChanged={(values) => {
        props.node!.data.nodeProperties[props.name] = values[0].key;
        setState({ ...state, comparisonOperator: values[0].key });
        props.forceNodeUpdate();
      }}
    />
  );
};
