import React, { PropsWithChildren, MouseEvent, useState, useEffect, createElement, FC, ReactNode } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { isPromise } from "@utils";
import { InlineOverlay } from "@components/Overlay";
import { MaybeAsync } from "@components/Common.types";
import { Clickable } from "../Link";
import { useTree, useTreeNode } from "./TreeContext";
import { TreeList } from "./TreeList";
import { ITreeLeafProps, ItemMovedArgs } from "./Tree.types";
import styles from "./Tree.module.scss";

interface ITreeBranchProps<T> extends PropsWithChildren<ITreeLeafProps> {
  data: MaybeAsync<T[]> | ((node: T, depth: number) => MaybeAsync<T[]>);
  expanded?: boolean;
  openIcon?: ReactNode;
  closeIcon?: ReactNode;
}

// eslint-disable-next-line complexity, func-style
function HtmlTreeBranch<T>({
  data,
  onClick,
  onDoubleClick,
  expanded: expandedProps = false,
  children,
  dragDrop,
  openIcon,
  closeIcon,
  onDragOver,
  onDragStart,
  onDragEnd,
  onDragLeave,
  onDragEnter,
  ...other
}: PropsWithChildren<ITreeBranchProps<T>>) {
  const { depth, keySelector, selectable, selectedKeys, toggleSelection, onItemMoved } = useTree<T>();
  const node = useTreeNode<T>();
  const [items, setItems] = useState<T[]>(Array.isArray(data) ? data : []);
  const [loading, setLoading] = useState(false);

  const [expanded, setExpanded] = useState(expandedProps);
  const selected = selectedKeys.includes(keySelector(node));

  const openIconNode = openIcon || "►";
  const closeIconNode = closeIcon || "▼";

  useEffect(() => {
    if (expanded) {
      const pendingData = typeof data === "function" ? data(node, depth) : data;
      if (isPromise<T[]>(pendingData)) {
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        pendingData.then(setItems).finally(() => setLoading(false));
      } else {
        setLoading(false);
        setItems(Array.isArray(pendingData) ? pendingData : []);
      }
    }
  }, [data, node, depth, expanded]);

  const handleClick = React.useCallback(
    (e: MouseEvent<HTMLElement>) => {
      setExpanded(v => !v);
      onClick?.(e);
      if (selectable) {
        toggleSelection(node);
      }
    },
    [onClick, node, selectable, toggleSelection]
  );

  const handleDragOver = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragOver?.(e);
      e.preventDefault();
    },
    [onDragOver, dragDrop, node]
  );

  const handleDragEnter = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragEnter?.(e);
      e.preventDefault();
    },
    [onDragEnter, dragDrop, node]
  );

  const handleDragStart = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("branch", JSON.stringify(node));
      onDragStart?.(e);
    },
    [onDragStart, node, dragDrop]
  );

  const handleDragLeave = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragLeave?.(e);
    },
    [onDragLeave, node, dragDrop]
  );

  const handleDrop = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      onDragEnd?.(e);
      const sourceNode = e.dataTransfer.getData("node") === "" ? {} : JSON.parse(e.dataTransfer.getData("node"));
      const branchNode = e.dataTransfer.getData("branch") === "" ? {} : JSON.parse(e.dataTransfer.getData("branch"));
      const itemMovedArgs: ItemMovedArgs<T> = {
        sourceNode,
        branchNode,
        destinationNode: node
      };
      onItemMoved?.(itemMovedArgs);
      e.dataTransfer.clearData();
      e.preventDefault();
    },
    [node, onItemMoved, onDragEnd, dragDrop]
  );

  return (
    <li
      className={styles.leaf}
      draggable={dragDrop}
      {...other}
      onDragStart={handleDragStart}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <InlineOverlay show={expanded && loading}>
        <Clickable
          as="a"
          className={clsx(styles.title, selected && styles.selected)}
          onClick={handleClick}
          onDoubleClick={onDoubleClick}
        >
          {expanded ? closeIconNode : openIconNode}
          {children}
        </Clickable>
      </InlineOverlay>
      {expanded && <TreeList data={items} depth={depth + 1} />}
    </li>
  );
}

const XmlTreeBranch: FC<ITreeBranchProps<unknown>> = ({ data, expanded = false, children }) => {
  const { depth } = useTree<unknown>();
  const items = Array.isArray(data) ? data : [];

  return createElement(
    "plex-control-tree-node",
    { opened: expanded },
    <>
      {createElement("name", {}, children)}
      <TreeList data={items} depth={depth + 1} />
    </>
  );
};

export const TreeBranch = withPrinting(HtmlTreeBranch, XmlTreeBranch);
