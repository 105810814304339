import React, { FunctionComponent, useState, ChangeEvent, KeyboardEvent } from "react";
import { useLocalization } from "@plex/culture-react";
import { TextInput } from "@components/Input";
import { CancelLink } from "@components/Link";
import { Button } from "@components/Button";
import { AdvancedSearchState, AdvancedSearchMethods } from "./AdvancedSearch";
import { TextQuery, ITextQuery } from "./TextQuery";
import styles from "./PickerModal.module.scss";

interface IPickerSearchAdvancedInputProps {
  onSearch: (query: ITextQuery) => void;
  onModeToggle: () => void;
}

export const PickerSearchAdvancedInput: FunctionComponent<IPickerSearchAdvancedInputProps> = ({
  onSearch,
  onModeToggle
}) => {
  const { t } = useLocalization();
  const [state, setState] = useState<AdvancedSearchState>({});

  const handleSearch = () => {
    onSearch(TextQuery.advanced(state));
  };

  const onEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    handleSearch();

    // Stop event propagation, to avoid conflicts with consumers that have event listeners on the container of Picker
    e.stopPropagation();
  };

  const setter = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setState(x => ({ ...x, [key]: value }));
  };

  return (
    <div className={styles.advancedSection}>
      <ul>
        {AdvancedSearchMethods.map(m => {
          return (
            <li key={m.key} className={styles.searchMethod}>
              <label>
                {t(m.messageId)}
                <TextInput
                  className={styles.searchInput}
                  value={state[m.key] || ""}
                  onChange={setter(m.key)}
                  onEnterKey={onEnterKey}
                />
              </label>
            </li>
          );
        })}
      </ul>
      <div className={styles.advancedActions} data-testid="picker-advanced-search-button">
        <CancelLink onClick={onModeToggle} className={styles.cancelLink} data-testid="picker-advanced-search-cancel" />
        <Button onClick={handleSearch} data-testid="picker-advanced-search-button">
          {t("ui-common-filter-action-search")}
        </Button>
      </div>
    </div>
  );
};
