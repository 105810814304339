import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import {
  Form,
  Button,
  Tabs,
  DataTableDef,
  SelectionMode,
  Link,
  Dialog,
  DataTablePlugins
} from "@plex/react-components";
import { Node, useReactFlow, useUpdateNodeInternals } from "reactflow";
import ReactJson from "react-json-view";
import {
  IExecutionError,
  INodeTrace,
  getReplayState,
  playReplay,
  updateBreakpointNodeId,
  setReplayPaused,
  stepBack,
  stepForward,
  skipToFirst,
  skipToLast,
  stopReplay,
  latestTraceMatchesNodeReplayIo,
  displaySelectedNodeSnapshotData
} from "../Runtime/RuntimePlayback";
import { useViewController } from "../ViewContext";
import { setNodeSelection } from "../Util/NodeUtil";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";
import "./TestPropertiesForm.scss";

export interface ITestPropertiesForm {
  testSettings: ITestProperties;
  setTestPropertiesState: React.Dispatch<React.SetStateAction<ITestProperties>>;
}

export interface IReplay {
  nodeTraces: INodeTrace[];
  traceIndex: number;
  isPaused: boolean;
  isStopped: boolean;
  isStopping: boolean;
  breakPointNodeId?: string;
  executionId?: string;
}

export interface ITestProperties {
  node?: Node;
  runtimeNodeDelayMs: number;
  isValid: boolean;
  errors: IExecutionError[];
  replay: IReplay;
  executionTime: number;
  nodeTraceCount: number;
}

interface ITableViewProperties {
  propertyName: string;
  propertyValue: any;
}

interface DataStackItem {
  label: string;
  data: Record<string, any>;
}

const LOADING_DATA_TEXT = "Loading...";
const NO_RECORDS = "No Records Were Found";
const LOADING_JSON = { Status: LOADING_DATA_TEXT };

export const TestPropertiesForm: FunctionComponent<ITestPropertiesForm> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const viewController = useViewController();
  const [tabIndex, setTabIndex] = useState(0);
  const functionSubscriber = usefunctionSubscriber();
  const replayState = getReplayState();

  const createSettingsSection = () => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleNodeDelayChange = (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseFloat(e.currentTarget.value);
      if (!value) {
        value = 25;
      }
      props.setTestPropertiesState({ ...props.testSettings, runtimeNodeDelayMs: value });
      globalThis.testPropertiesState = { ...props.testSettings, runtimeNodeDelayMs: value };

      setTooltipVisible(true);

      return e;
    };

    const handleMouseUp = () => {
      setTooltipVisible(false);
    };

    useEffect(() => {
      window.addEventListener("mouseup", handleMouseUp);
      return () => {
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }, []);

    return (
      <Form.Section className={props.testSettings.node ? "" : "plex-fd-no-selection-form-section"} title="Settings">
        {props.testSettings.replay.executionId && (
          <Form.Row>
            <Form.FieldPair labelText="Execution Time" className="plex-fd-execution-time-field-pair">
              <div>{Number(props.testSettings.executionTime.toFixed(2))} sec</div>
            </Form.FieldPair>
          </Form.Row>
        )}

        <Form.Row>
          <Form.FieldPair labelText="Node Delay" className="node-delay-field-pair">
            <div className="plex-fd-slider-container">
              <input
                name="RuntimeNodeDelayMs"
                value={props.testSettings.runtimeNodeDelayMs}
                type="range"
                min={25}
                max={1000}
                step={25}
                onChange={handleNodeDelayChange}
                onMouseDown={() => setTooltipVisible(true)}
                id="delaySlider"
              />

              {tooltipVisible && (
                <div
                  className="plex-fd-replay-slider-tooltip"
                  style={{
                    left: `calc(${(props.testSettings.runtimeNodeDelayMs / 1000) * 100}%)`
                  }}
                >
                  {props.testSettings.runtimeNodeDelayMs} ms
                </div>
              )}
            </div>
          </Form.FieldPair>
        </Form.Row>
      </Form.Section>
    );
  };

  const showErrorsSection = () => {
    return props.testSettings.errors.length > 0;
  };

  const createErrorsSection = () => {
    return (
      <Form.Section className={props.testSettings.node ? "" : "plex-fd-no-selection-form-section"} title="Errors">
        {props.testSettings.errors.length === 0 ? (
          <div />
        ) : (
          props.testSettings.errors.map((error: IExecutionError, errorIndex: number) => {
            return (
              <Form.Row key={"run-error-display-" + errorIndex}>
                <div onClick={() => onErrorClick(error)} className="plex-fd-validation-error-message">
                  {error.message}
                </div>
              </Form.Row>
            );
          })
        )}
      </Form.Section>
    );
  };

  const onErrorClick = (error: IExecutionError) => {
    if (error.nodeId) {
      const viewport = reactFlowInstance.getViewport();
      reactFlowInstance.fitView({
        nodes: [{ id: error.nodeId }],
        minZoom: viewport.zoom,
        maxZoom: viewport.zoom,
        duration: 500
      });
      setNodeSelection([error.nodeId], reactFlowInstance);
    }
  };

  const noTraces = replayState.nodeTraces.length === 0;
  const isStopped = replayState.isStopped;
  const isPaused = replayState.isPaused;
  const breakpointActiveNodeId = replayState.breakPointNodeId;
  const replayControlButtonSize = "xs";

  const removeUnderscore = (obj: any) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeUnderscore(item));
    } else if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = key
          ?.split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join("");
        acc[newKey] = removeUnderscore(obj[key]);
        return acc;
      }, {});
    } else {
      return obj;
    }
  };

  const getTestInput = () => {
    if (!replayState.isStopped && !latestTraceMatchesNodeReplayIo(props.testSettings.node!)) {
      displaySelectedNodeSnapshotData(
        props.testSettings.node!,
        functionSubscriber,
        reactFlowInstance,
        updateNodeInternals
      );
      return [false, LOADING_JSON];
    }

    return [true, removeUnderscore(props.testSettings.node!.data.nodeProperties.testInput)];
  };

  const getTestOutput = () => {
    if (!replayState.isStopped && !latestTraceMatchesNodeReplayIo(props.testSettings.node!)) {
      return [false, LOADING_JSON];
    }

    let output = props.testSettings.node!.data.nodeProperties.testOutput;

    if (props.testSettings.node!.type === "flowGroup") {
      output = props.testSettings.node!.data.nodeProperties.testGroupOutput;
    }

    return [true, removeUnderscore(output)];
  };

  const _treeView = () => {
    const [isInputLoaded, inputResult] = getTestInput();
    const [isOutputLoaded, outputResult] = getTestOutput();

    const loadingJSON = { Status: LOADING_DATA_TEXT };

    return (
      <>
        <Form.Section title="Input" className="plex-fd-test-form-input-section">
          <div className="plex-fd-test-tree-tab-view-div plex-fd-test-json-view-form-section plex-fd-test-json-view-form-section-input plex-fd-test-scroll-container">
            {!isInputLoaded ? (
              <ReactJson src={loadingJSON} quotesOnKeys={false} displayDataTypes={false} name={false} />
            ) : (
              <ReactJson src={inputResult} quotesOnKeys={false} displayDataTypes={false} name={false} />
            )}
          </div>
        </Form.Section>
        <Form.Section title="Output">
          <div className="plex-fd-test-tree-tab-view-div plex-fd-test-json-view-form-section plex-fd-test-json-view-form-section-output plex-fd-test-scroll-container">
            {!isOutputLoaded ? (
              <ReactJson src={loadingJSON} quotesOnKeys={false} displayDataTypes={false} name={false} />
            ) : (
              <ReactJson src={outputResult} quotesOnKeys={false} displayDataTypes={false} name={false} />
            )}
          </div>
        </Form.Section>
      </>
    );
  };

  const _rowTableView = ({ data }) => {
    const rows = data ?? [];

    const tableData = rows.map((item, index) => ({
      No: index + 1,
      ...item
    }));

    const columnHeaders = ["No", ...Object.keys(rows[0] || {})];

    return (
      <div className="plex-fd-test-table-view-dialog-modal-rows-output plex-fd-test-scroll-container">
        <DataTableDef
          data={tableData}
          keySelector={(row: any) => row.No}
          selectionMode={SelectionMode.none}
          emptyText={NO_RECORDS}
          virtualized={true}
          virtualizedRowCount={50}
          plugins={[DataTablePlugins.FixedLayout(), DataTablePlugins.ColumnResizer()]}
        >
          {columnHeaders.map((header, idx) => (
            <DataTableDef.Column key={idx} id={header} title={header} sortable>
              {(row: any) => row[header]}
            </DataTableDef.Column>
          ))}
        </DataTableDef>
      </div>
    );
  };

  const _objectTableView = ({ data }) => {
    const tableData = Object.keys(data).map((key) => ({
      propertyName: key,
      propertyValue: data[key]
    }));

    return (
      <div className="plex-fd-test-table-view-dialog-modal-output">
        <DataTableDef
          data={tableData}
          keySelector={(row: any) => row.propertyName}
          selectionMode={SelectionMode.none}
          emptyText={NO_RECORDS}
          plugins={[DataTablePlugins.FixedLayout(), DataTablePlugins.ColumnResizer()]}
        >
          <DataTableDef.Column id="1" title="Name" sortable>
            {(row: any) => row.propertyName}
          </DataTableDef.Column>
          <DataTableDef.Column id="2" title="Value" sortable>
            {(row: any) => {
              if (row.propertyValue === null) {
                return "null";
              } else if (typeof row.propertyValue === "object" && row.propertyValue !== null) {
                return "{ ... }";
              } else {
                return String(row.propertyValue);
              }
            }}
          </DataTableDef.Column>
        </DataTableDef>
      </div>
    );
  };

  const _tableView = ({ title, className, data, renderBreadcurmb = false }) => {
    const [isLoaded, values] = data;

    const [dataStack, setDataStack] = useState<DataStackItem[]>([{ label: "Root", data: values }]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalData, setModalData] = useState(null);

    if (!values) {
      return <div style={{ padding: "10px" }}>No data available for {title}</div>;
    }
    const currentData = dataStack[dataStack.length - 1]?.data;

    const handleLinkClick = (row: ITableViewProperties) => {
      if (currentData) {
        let newData;
        if (row.propertyName.includes("[") && row.propertyName.includes("]")) {
          const [arrayKey, index] = row.propertyName.match(/([^\[\]]+)/g) || [];
          if (arrayKey && index) {
            newData = currentData[index];
          }
        } else {
          newData = currentData[row.propertyName];
        }

        if (newData && typeof newData === "object" && newData !== null) {
          setDataStack([...dataStack, { label: row.propertyName, data: newData }]);
        }
      }
    };

    const handleDummyClick = () => {
      setDataStack(dataStack.slice(0, -1));
    };

    const handleBreadcrumbClick = (index: number) => {
      setDataStack(dataStack.slice(0, index + 1));
    };

    const _renderBreadcrumbs = () => (
      <div className="div-testpropertiesform-breadcrumbs">
        {dataStack.map((item, index) => (
          <span key={index} style={{ padding: "3px" }} onClick={() => handleBreadcrumbClick(index)}>
            {item.label} {index < dataStack.length - 1 && ">"}
          </span>
        ))}
      </div>
    );

    const generateTableData = (data: Record<string, any>): ITableViewProperties[] => {
      return Object.keys(data).map((key) => {
        if (Array.isArray(data[key])) {
          return {
            propertyName: key,
            propertyValue: data[key],
            isArray: true
          };
        } else {
          return {
            propertyName: key,
            propertyValue: data[key]
          };
        }
      });
    };

    const generateArrayTableData = (array: any[]): ITableViewProperties[] => {
      return array.map((item, index) => ({
        propertyName: `rows[${index}]`,
        propertyValue: item
      }));
    };

    const openModal = (title, data) => {
      setModalTitle(title);
      setModalData(data);
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
      setModalData(null);
    };

    if (!currentData) {
      return <div style={{ padding: "10px" }}>{LOADING_DATA_TEXT}</div>;
    }

    const tableData = Array.isArray(currentData) ? generateArrayTableData(currentData) : generateTableData(currentData);

    if (dataStack.length > 1) {
      tableData.unshift({ propertyName: "Back", propertyValue: "Back" });
    }

    return (
      <>
        <Form.Section title={title} className={className}>
          {renderBreadcurmb ? <_renderBreadcrumbs /> : <></>}
          {!isLoaded ? (
            <div style={{ marginLeft: "18px" }}> {LOADING_DATA_TEXT}</div>
          ) : (
            <div className="plex-fd-test-scroll-container plex-fd-test-table-view">
              <DataTableDef<ITableViewProperties>
                data={tableData}
                keySelector={(row: any) => row.propertyName}
                selectionMode={SelectionMode.single}
                emptyText={NO_RECORDS}
              >
                <DataTableDef.Column<ITableViewProperties> id="1" title="Name" sortable>
                  {(row) => row.propertyName}
                </DataTableDef.Column>
                <DataTableDef.Column<ITableViewProperties> id="2" title="Value" sortable>
                  {(row: any) => {
                    if (row.propertyName === "Back") {
                      return <Link onClick={handleDummyClick}>...</Link>;
                    } else if (row.isArray || (typeof row.propertyValue === "object" && row.propertyValue !== null)) {
                      return (
                        <>
                          {renderBreadcurmb ? (
                            <Link onClick={() => handleLinkClick(row)}>{row.isArray ? "[ ... ]" : "{ ... }"}</Link>
                          ) : (
                            <></>
                          )}
                          <Button
                            style={{ marginLeft: "20px" }}
                            size="xs"
                            onClick={() => openModal(row.propertyName, row.propertyValue)}
                          >
                            Open
                          </Button>
                        </>
                      );
                    } else if (row.propertyValue === null) {
                      return "null";
                    } else {
                      return String(row.propertyValue);
                    }
                  }}
                </DataTableDef.Column>
              </DataTableDef>
            </div>
          )}
        </Form.Section>

        {/* Render Dialog */}
        {isModalOpen && (
          <Dialog show closeButton onHide={closeModal} title={modalTitle}>
            <Dialog.Body className="plex-fd-test-table-view-dialog-modal-body plex-fd-test-scroll-container">
              {Array.isArray(modalData) ? <_rowTableView data={modalData} /> : <_objectTableView data={modalData} />}
            </Dialog.Body>
            <Dialog.Footer>
              <Button className="plex-fd-test-table-view-dialog-model-footer" onClick={closeModal}>
                Close
              </Button>
            </Dialog.Footer>
          </Dialog>
        )}
      </>
    );
  };

  const _renderPropertiesViewComponent = () => {
    if (!props.testSettings.node) {
      return <></>;
    }

    if (viewController.experimentalModeState) {
      return (
        <div>
          <Tabs
            className="plex-fd-test-view-tabs"
            selectedIndex={tabIndex}
            onSelect={(index, _prev, _e) => {
              setTabIndex(index);
            }}
          >
            <Tabs.TabList>
              <Tabs.Tab>JSON</Tabs.Tab>
              <Tabs.Tab>Table</Tabs.Tab>
            </Tabs.TabList>

            <Tabs.TabPanel className="plex-fd-test-tree-view-tab-list">
              <_treeView />
            </Tabs.TabPanel>
            <Tabs.TabPanel className="plex-fd-test-table-view-tab-list">
              <_tableView
                key="input-table"
                title="Input"
                className="plex-fd-test-form-input-section"
                data={getTestInput()}
              />
              <_tableView key="output-table" title="Output" className="" data={getTestOutput()} />
            </Tabs.TabPanel>
          </Tabs>
        </div>
      );
    }

    return (
      <div className="plex-fd-node-input-output-log-section">
        <_treeView />
      </div>
    );
  };

  return (
    <Form className="plex-fd-trace-form">
      <div className="plex-fd-test-log-container">
        {createSettingsSection()}
        {showErrorsSection() ? createErrorsSection() : <div />}

        <div className="plex-fd-replay-controls-container">
          <Button
            key="first-replay-button"
            className={`flow-replay-control ${isStopped ? "btn-first-disabled" : "btn-first-enabled"}`}
            size={replayControlButtonSize}
            disabled={isStopped}
            title="First"
            onClick={() => skipToFirst(reactFlowInstance, updateNodeInternals, viewController.bannerContext)}
          />

          <Button
            key="previous-replay-button"
            className={`flow-replay-control ${
              !isPaused || isStopped ? "btn-previous-disabled" : "btn-previous-enabled"
            }`}
            size={replayControlButtonSize}
            disabled={!isPaused || isStopped}
            title="Previous"
            onClick={() =>
              stepBack(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="play-pause-replay-button"
            className={`flow-replay-control
              ${noTraces ? "btn-play-disabled" : ""}
              ${noTraces || !(isPaused || isStopped) ? "" : "btn-play-enabled"}
              ${noTraces || isPaused || isStopped ? "" : "btn-pause-enabled"}
              `}
            size={replayControlButtonSize}
            title={noTraces ? "Play" : isPaused ? "Play" : "Pause"}
            onClick={() => {
              if (isPaused) {
                Promise.resolve()
                  .then(() => {
                    if (!isStopped) {
                      stepForward(
                        reactFlowInstance,
                        updateNodeInternals,
                        viewController.bannerContext,
                        functionSubscriber
                      );
                    }
                  })
                  .then(() => {
                    setReplayPaused(false);
                    playReplay(
                      reactFlowInstance,
                      updateNodeInternals,
                      viewController.bannerContext,
                      functionSubscriber
                    );
                  });
              } else {
                setReplayPaused(true);
              }
            }}
            disabled={noTraces}
          />

          <Button
            key="stop-replay-button"
            className={`flow-replay-control ${noTraces || isStopped ? "btn-stop-disabled" : "btn-stop-enabled"}`}
            size={replayControlButtonSize}
            disabled={noTraces || isStopped}
            title="Stop"
            onClick={() => stopReplay(reactFlowInstance)}
          />

          <Button
            key="next-replay-button"
            className={`flow-replay-control ${!isPaused || isStopped ? "btn-next-disabled" : "btn-next-enabled"}`}
            size={replayControlButtonSize}
            disabled={!isPaused || isStopped}
            title="Next"
            onClick={() =>
              stepForward(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="last-replay-button"
            className={`flow-replay-control ${isStopped ? "btn-last-disabled" : "btn-last-enabled"}`}
            size={replayControlButtonSize}
            disabled={isStopped}
            title="Last"
            onClick={() =>
              skipToLast(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="breakpoint-replay-button"
            className={`flow-replay-control ${
              isStopped || !props.testSettings.node?.selected
                ? "btn-breakpoint-disabled"
                : breakpointActiveNodeId
                ? "btn-breakpoint-pressed"
                : "btn-breakpoint-enabled"
            }`}
            size={replayControlButtonSize}
            disabled={isStopped || !props.testSettings.node?.selected}
            title="Stop At"
            onClick={() => updateBreakpointNodeId(reactFlowInstance, updateNodeInternals)}
          />
        </div>

        <_renderPropertiesViewComponent />
      </div>
    </Form>
  );
};
