import React, { ChangeEvent } from "react";
import { Button } from "../Button";
import { TextInput, PasswordInput } from "../Input";
import { TextArea } from "../TextArea";
import { CheckList } from "../SelectionList";
import { Dropdown } from "../Dropdown";
import { PromptType, IDialogPromptProps } from "./DialogPrompt.types";
import styles from "./Dialog.module.scss";

// eslint-disable-next-line func-style
export function DialogPrompt<T = string>({
  answers,
  keySelector,
  displaySelector,
  selectedItems,
  onSelectionChanged,
  displayType
}: IDialogPromptProps<T>) {
  const onCheckListSelect = (values: T[]) => {
    onSelectionChanged(values);
  };

  const onTextChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // TODO: there should be a way to base the type off the enum and limit text
    // based inputs to strings.
    onSelectionChanged([(e.currentTarget.value as unknown) as T]);
  };

  const onTextAreaChangeHandler = (value: string | null) => {
    onSelectionChanged([(value as unknown) as T]);
  };

  const onDropDownSelect = (values: T[]) => {
    onSelectionChanged(values);
  };

  const onButtonSelect = (value: string) => {
    const selectedValue = answers.find(x => displaySelector(x) === value);
    onSelectionChanged(selectedValue ? [selectedValue] : []);
  };

  const displayPrompts = () => {
    switch (displayType) {
      case PromptType.textbox:
        return <TextInput autoFocus value={displaySelector(selectedItems[0])} onChange={onTextChangeHandler} />;
      case PromptType.password:
        return (
          <PasswordInput
            autoFocus
            value={displaySelector(selectedItems[0])}
            onChange={onTextChangeHandler}
            allowVisibilityToggle
          />
        );
      case PromptType.textarea:
        return <TextArea value={displaySelector(selectedItems[0])} onValueChange={onTextAreaChangeHandler} />;
      case PromptType.checkboxes:
        return (
          <CheckList<T>
            name="prompt"
            items={answers}
            keySelector={keySelector}
            selected={selectedItems}
            displaySelector={displaySelector}
            onSelectionChanged={onCheckListSelect}
          />
        );
      case PromptType.list:
        return (
          <Dropdown
            items={answers}
            keySelector={keySelector}
            displaySelector={displaySelector}
            selected={selectedItems}
            onSelectionChanged={onDropDownSelect}
          />
        );
      case PromptType.buttons:
      default:
        return (
          <div className={styles.confirmFooterActions}>
            {answers.map(item => {
              return (
                <Button key={keySelector(item)} onClick={() => onButtonSelect(displaySelector(item))} size="sm">
                  {displaySelector(item)}
                </Button>
              );
            })}
          </div>
        );
    }
  };

  return displayPrompts();
}
