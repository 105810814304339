import React, { forwardRef } from "react";
import clsx from "clsx";
import { TextInput, IInputProps } from "../Input";
import styles from "./Filter.module.scss";

export const FilterTextInput = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const { className, ...other } = props;
  const classes = clsx(className, styles.filterItemInput);

  return <TextInput data-testid="plex-filter-text-input" className={classes} {...other} ref={ref} />;
});

FilterTextInput.displayName = "FilterTextInput";
