import { useEffect, useState } from "react";
import { useKeyPress, useReactFlow, isNode, Node, useUpdateNodeInternals } from "reactflow";
import { useViewController } from "../ViewContext";
import { deleteNodes } from "../Util/NodeUtil";

export const useKeypressDelete = (Id: string, isOver: boolean, targetIsNode: boolean) => {
  const viewController = useViewController();
  const [objId] = useState(Id);
  const deletePressed = useKeyPress("Delete");
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (deletePressed) {
      if (isOver && !targetIsNode) {
        let currentEdges = reactFlowInstance.getEdges();
        currentEdges = currentEdges.filter(function (obj) {
          return obj.id !== objId;
        });
        reactFlowInstance.setEdges(currentEdges);
      } else {
        let selectedNodes = reactFlowInstance.getNodes().filter((n) => n.selected);
        if (
          selectedNodes.some((n: Node<any>) => globalThis.nodeTypeDefinitions.getDefinition(n.type!)!.confirmDeletion)
        ) {
          globalThis.setConfirmDeletionState(true);
        } else {
          deleteNodes(selectedNodes, updateNodeInternals, reactFlowInstance, viewController);
        }
      }
    }
  }, [deletePressed, isOver, reactFlowInstance, deleteNodes, isNode, objId]);
};
