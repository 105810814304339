import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "IfNode",
  category: NodeTypeCategory.logic,
  controlInputs: [{ name: "evaluate" }],
  controlOutputs: [{ name: "then" }, { name: "else" }],
  dataInputs: [{ name: "condition", type: DataType.BOOLEAN, hideLabel: true }],
  dataOutputs: [],
  size: NodeSize.Small,
  evaluate: (input) => {
    const controlId: string = input.condition ? "then" : "else";
    return { output: {}, activeControlHandleIds: [controlId] };
  }
});

export const IfNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
