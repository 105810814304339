import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import styles from "./Dialog.module.scss";

export const DialogFooter: FunctionComponent<ICommonProps> = ({ className, children, ...other }) => {
  const classes = clsx(styles.modalFooter, className);
  return (
    <footer {...other} className={classes}>
      <div className={styles.footerActions}>{children}</div>
    </footer>
  );
};
