import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ArrowDown2Icon, ArrowLeft2Icon } from "@plex/icons";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, IWithChildren } from "../Common.types";
import { useToggle } from "../../hooks/use-toggle";
import styles from "./Form.module.scss";
import { XmlControlSection } from "../XmlControlSection";

export enum ColumnType {
  single = "single",
  double = "double"
}

export interface ISectionProps extends ICommonProps, IWithChildren {
  /** The section title */
  title?: string;
  /** Sets the initial collapsed state of the section */
  isCollapsed?: boolean;
  /**
   * Indicates how many columns are rendered
   * @default "single"
   */
  layout?: ColumnType;
}

const HtmlFormSection: FunctionComponent<ISectionProps> = ({
  id,
  children,
  title,
  isCollapsed = false,
  className,
  layout = ColumnType.double,
  style,
  ...other
}) => {
  const [collapsed, toggleCollapsed] = useToggle(isCollapsed);
  const closeDataId = `rowSection-collapse-${id ?? ""}`;
  const sectionContainerId = `rowSection-container-${id ?? ""}`;
  const sectionContentId = `rowSection-container-content-${id ?? ""}`;
  const layoutClass = layout === ColumnType.double ? styles.formSectionDouble : styles.formSectionAuto;
  const collapsedStyle = collapsed ? styles.collapsed : "";
  return (
    <div id={id} className={clsx(styles.formSectionCollapsible, layoutClass, className)} {...other}>
      <fieldset className={styles.formSection} data-testid={sectionContainerId} style={style}>
        <legend
          className={styles.formLegendContainer}
          onClick={toggleCollapsed}
          role="presentation"
          data-testid={closeDataId}
        >
          <span className={styles.formLegend}>{title}</span>
          <i className={styles.formCollapseIcon}>
            {collapsed && <ArrowLeft2Icon />}
            {!collapsed && <ArrowDown2Icon />}
          </i>
        </legend>
        <div className={collapsedStyle} data-testid={sectionContentId}>
          {children}
        </div>
      </fieldset>
    </div>
  );
};

const XmlFormSection: FunctionComponent<ISectionProps> = ({ title, children }) => {
  const sectionTitle = React.createElement("section-title", { key: 1 }, title?.toUpperCase());
  const sectionElements = React.createElement("section-elements", { key: 2 }, children);
  const section = React.createElement("plex-form-section", {}, [sectionTitle, sectionElements]);
  const sectionColumns = React.createElement("plex-form-columns", {}, section);
  return React.createElement(XmlControlSection, {}, sectionColumns);
};

export const FormSection = withPrinting(HtmlFormSection, XmlFormSection);
