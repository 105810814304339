import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const sourcePropertyName = "source";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConvertToStringNode",
  label: "To Text",
  category: NodeTypeCategory.transformations,
  controlOutputs: [],
  dataInputs: [{ name: sourcePropertyName, type: DataType.DECIMAL, hideLabel: true }],
  dataOutputs: [{ name: "result", type: DataType.STRING }],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [
          DataType.DECIMAL,
          DataType.INTEGER,
          DataType.BOOLEAN,
          DataType.DATE,
          DataType.DATETIME,
          DataType.UUID
        ],
        dataProperties: [sourcePropertyName]
      }
    }
  ],
  evaluate: (input: any) => {
    return {
      output: { result: input.source.toString() },
      activeControlHandleIds: []
    };
  }
});

export const ConvertToStringNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
