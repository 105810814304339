import React, { FunctionComponent } from "react";
import { ErrorMessage } from "formik";
import { ValidationMessage } from "../ValidationMessage";

interface IFormikValidationMessageProps {
  /**
   * The name of the component.
   */
  name: string;
}

export const FormikValidationMessage: FunctionComponent<IFormikValidationMessageProps> = ({ name, ...other }) => {
  return <ErrorMessage name={name}>{m => <ValidationMessage message={m} {...other} />}</ErrorMessage>;
};
