import { RefObject, useEffect } from "react";

/**
 * Add listener of events, fired outside the React component.
 * @param wrapper React component
 * @param clickHandler outside click handler
 */
export const useOutsideClick = (wrapper: RefObject<HTMLLIElement>, clickHandler: EventListener) => {
  // eslint-disable-next-line func-style, react-hooks/exhaustive-deps
  function handleClickOutside(e: Event) {
    if (wrapper.current && !wrapper.current.contains(e.target as Node)) {
      clickHandler(e);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, [handleClickOutside]);
};
