import { CSSProperties, ReactNode } from "react";

export interface ICommonProps {
  /** The ID to apply to the element */
  id?: string;
  /** The style to apply to the element */
  style?: CSSProperties;
  /** The class name to apply to the element */
  className?: string;
}

export interface IWithChildren {
  children?: ReactNode;
}

/** A type representing a CSS length value */
export type CssLength = number | string;

/** An item or a promise wrapping that item */
export type MaybeAsync<T> = T | Promise<T>;

/** Function used to return a value */
export type ValueAccessor<T, V = unknown> = (row: T) => V;

/** Function which handles a value being changed */
export type ValueChangeHandler<T = string> = (value: T | null) => void;

/** Function used to compare two objects and return a number used */
export type Comparer<T> = (a: T, b: T) => number;

export enum RequiredState {
  required = "required",
  group = "group"
}
