import React, { useCallback, useEffect, useMemo, useState, useRef, useLayoutEffect } from "react";
import ReactFlow, {
  useReactFlow,
  useNodesState,
  useEdgesState,
  Connection,
  Node,
  Edge,
  Background,
  Controls,
  BackgroundVariant,
  useUpdateNodeInternals,
  MiniMap,
  OnConnectStartParams,
  NodeProps,
  ReactFlowProps,
  NodeChange
} from "reactflow";
import {
  PersistentBanner,
  BannerProvider,
  BannerConsumer,
  BannerPlaceholder,
  BannerStatus,
  IBannerContext
} from "@plex/react-components";

import { DesignerContainer, createNewNodeMeta } from "./DesignerContainer/DesignerContainer";
import { IFlowRunResponse, simulateFlow } from "./Runtime/RuntimeExecution";

import { AddNodeMenu, IAddNodeMenuProps } from "./AddNodeMenu/AddNodeMenu";
import { FlowInputManagementController, FlowInputManagement } from "./FlowInputManagement";

import SwitchEdge from "./EdgeTypes/SwitchEdge";

import {
  deleteChildren,
  onChildPositionChange,
  beforeChildPositionChange,
  handleGroupNodeDrag
} from "./NodeTypes/GroupNode/groupUtil";
import { AddMenuNodePopulater } from "./AddNodeMenu/AddMenuNodePopulation";
import { handleAnchorDrag, handleAnchorNodeDrop } from "./Util/AnchorUtil";
import { useSingleton, ViewControllerProvider } from "./ViewContext";

import * as CommonNodeImports from "./NodeTypes/Common";
import * as ConditionNodeImports from "./NodeTypes/Condition";
import * as DateNodeImports from "./NodeTypes/Date";
import * as StringNodeImports from "./NodeTypes/String";
import * as MathNodeImports from "./NodeTypes/Math";
import * as ConstantNodeImports from "./NodeTypes/ConstantNode";

import { getMissingInputErrorMessages, validateOrder } from "./Runtime/RuntimeValidation";
import { forceNodeUpdate } from "./Util/NodeUtil";
import { IReplay, ITestProperties } from "./FlowDocument/TestPropertiesForm";
import { convertToDocument, verifyAndLoadDocument } from "./FlowDocument/DocumentProcessor";
import {
  playReplay,
  getReplayState,
  stopReplay,
  setNodeDelayMs,
  IExecutionSummary,
  displaySelectedNodeSnapshotData
} from "./Runtime/RuntimePlayback";
import { NodePopupContainer } from "./NodePopup/NodePopupContainer";
import { NodePopup } from "./NodePopup/NodePopup";
import { BaseNodePropertiesForm } from "./NodePropertiesForm/BaseNodePropertiesForm";
import { getPrimitiveSchemas, setSchemas } from "./NodeTypes/DataSchemas";
import { IDocumentSchema, IFlowDocumentModel, IFlowMetaData } from "./FlowDocument/FlowDocumentModel";
import { INodeTypeDefinition } from "./NodeTypes/Base";

import "./NodeTypes/PlexOpenApi/PlexOpenApi.scss";
import "./PlexFlowNode.scss";
import "./NodeTypes/Base/BaseNode.scss";
import "./EdgeTypes/SwitchEdge.scss";
import "./NodeTypes/Common/CommonNodes.scss";
import "./NodeTypes/Condition/ConditionNode.scss";
import "./NodeTypes/ConstantNode/ConstantLabel.scss";
import { usefunctionSubscriber } from "../components/FunctionSubscriberContext/FunctionSubscriberContext";
import { ConfirmationDialog } from "./Dialogs/ConfirmationDialog";
import {
  checkAndRemoveUnmappable,
  handleEdgeConnection,
  updateEdgeValidationStyling,
  updateLinkedSchemas
} from "./Util/EdgeUtil";
import { download, openFlow } from "./FlowDocument/DocumentExport";

const ReactFlowDefault = "default" in ReactFlow ? ReactFlow.default : ReactFlow;
const Noop = () => {};

const nodeTypeImports: { [nodeTypeName: string]: React.FunctionComponent<NodeProps> }[] = [
  CommonNodeImports,
  ConditionNodeImports,
  DateNodeImports,
  StringNodeImports,
  MathNodeImports,
  ConstantNodeImports
];

const nodeTypes: any = {};
nodeTypeImports.forEach((imports) => {
  Object.keys(imports).forEach((key) => {
    let nodeTypeId = globalThis.nodeTypeDefinitions
      .getDefinitions()
      .filter((d: INodeTypeDefinition) => d.componentName === key)[0]!.id;
    nodeTypes[nodeTypeId] = imports[key];
  });
});

export interface IPlexFlowParams {
  plexFlowDesignerController?: any;
}

export const PlexFlow: React.FC<IPlexFlowParams> = ({ plexFlowDesignerController }) => {
  const functionSubscriber = usefunctionSubscriber();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodes, _setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowEdgeConnectionInProgress = useRef<boolean>(false);
  const reactFlowCanvasClickStatus = useRef<boolean>(false);
  const flowInputController = useSingleton(() => new FlowInputManagementController());
  const [readOnlyState, setReadOnlyState] = useState<boolean>(false);
  const [experimentalModeState, setExperimentalModeState] = useState<boolean>(false);
  const [nodeSelectionListCollapseState, setNodeSelectionListCollapseState] = useState<boolean>(false);
  AddMenuNodePopulater._activeNodes = nodes;
  AddMenuNodePopulater._reactFlowEdgeSetter = setEdges;
  AddMenuNodePopulater._reactFlowInstance = reactFlowInstance;
  AddMenuNodePopulater._reactFlowUpdateNodeInternals = updateNodeInternals;
  const [flowInputModalState, setFlowInputModalState] = useState<any>({ show: false });
  const [documentPropertiesState, setDocumentPropertiesState] = useState({
    name: "",
    summary: ""
  } as IFlowMetaData);
  const [testPropertiesState, setTestPropertiesState] = useState({
    runtimeNodeDelayMs: 200,
    isValid: true,
    errors: [],
    replay: { nodeTraces: [], traceIndex: 0, isPaused: false, isStopped: true, isStopping: false },
    executionTime: 0,
    nodeTraceCount: 0
  } as ITestProperties);
  const [propertiesTabIndexState, setPropertiesTabIndexState] = useState(0);
  const [propertiesPopupState, setPropertiesPopupState] = useState(false);
  const [confirmDialogVisibleState, setConfirmDialogVisibleState] = useState(false);
  let [loadRequestedRequested, setLoadRequestedState] = useState<any>({ requested: false });
  let [loadCompletedState, setLoadCompletedState] = useState<any>({ completed: false });
  const { project } = useReactFlow();
  const connectingNodeInfo = useRef<OnConnectStartParams | null>(null);
  const [, setTriggerRender] = useState<number>(0);

  const addNodeMenuContainerRef = useRef<HTMLDivElement>(null);
  const [addNodeMenuState, setAddNodeMenuState] = useState<IAddNodeMenuProps>({});
  const [addNodeMenuContainerState, setAddNodeMenuContainerState] = useState({
    initialPosition: { x: 0, y: 0 },
    visible: false
  });

  const [canvasHiddenState, setCanvasHiddenState] = useState({ hidden: false });

  const validateEdges = useCallback(() => {
    // Need to validate control flow in timeout, because edge is added after
    updateEdgeValidationStyling();
  }, []);

  // Expose object to child components
  const controller = {
    flowInputController,
    setFlowInputModalState,
    experimentalModeState,
    validateEdges,
    nodeSelectionListCollapse: {
      nodeSelectionListCollapseState,
      setNodeSelectionListCollapseState
    },
    propertiesPopupState,
    bannerContext: {}
  };

  // Need to use globalThis to avoid re-renders caused by useContext in viewController
  globalThis.setPropertiesPopupState = setPropertiesPopupState;
  globalThis.confirmDialogVisibleState = confirmDialogVisibleState;
  globalThis.setConfirmDialogVisibleState = setConfirmDialogVisibleState;
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState = setTestPropertiesState;

  if (!loadRequestedRequested.requested) {
    setLoadRequestedState({ requested: true });

    // COMMENTING FOR TIME BEING
    // loadApiSpecs(() => {
    //   if (getSpecs()) {
    //     setLoadCompletedState({ completed: true });
    //   }
    // });
    // Bypass check if specs could be loaded,
    // we want to load regardless for testing purposes.
    setLoadCompletedState({ completed: true });
  }

  // For Jest testing
  if (!plexFlowDesignerController) {
    useEffect(() => {
      (window as any).setSelectedNodes = (nodeIds: string[]) => {
        const instanceNodes = reactFlowInstance.getNodes();
        instanceNodes.forEach((node) => (node.selected = nodeIds.indexOf(node.id) !== -1));
        reactFlowInstance.setNodes(instanceNodes);
      };

      (window as any).resetReactFlow = () => {
        reactFlowInstance.setEdges([]);
        reactFlowInstance.setNodes([]);
      };

      (window as any).getFlowDocument = () => convertToDocument(flowInputController?.flowInputPool, reactFlowInstance);
      (window as any).setPropertiesFormVisible = (visible: boolean) => globalThis.setPropertiesPopupState(visible);
    });
  }

  // If not in UX, auto-add start and flow input nodes
  if (!plexFlowDesignerController) {
    // Render the app once to add a start node
    useLayoutEffect(() => {
      setTimeout(() => {
        // If layout event is called again, prevent re-initializing.
        if (reactFlowInstance.getNodes().length > 0) {
          return;
        }

        setSchemas(
          getPrimitiveSchemas().map((schema: IDocumentSchema) => {
            return { ...schema, source: { sourceSystem: "", sourceId: "" } };
          })
        );

        const baseStartNode: any = createNewNodeMeta("start", null, null, null, null, null, 700, 200, "");
        reactFlowInstance.addNodes(baseStartNode);
        setTimeout(() => {
          // If layout event is called again, prevent re-initializing.
          if (reactFlowInstance.getNodes().length > 1) {
            return;
          }
          const flowInputNode: any = createNewNodeMeta("flowInputs", null, null, null, null, null, 700, 200);
          reactFlowInstance.addNodes(flowInputNode);
          setTimeout(
            () =>
              reactFlowInstance.setEdges(
                handleAnchorNodeDrop(
                  reactFlowInstance.getNode(flowInputNode.id)!,
                  reactFlowInstance.getEdges(),
                  reactFlowInstance,
                  updateNodeInternals
                )
              ),
            1
          );
        }, 100);
      }, 100);
    }, []);
  }

  const edgeTypes = useMemo(() => ({ switch: SwitchEdge, default: SwitchEdge }), []);

  const handleConnection = useCallback((params: Connection | Edge) => {
    handleEdgeConnection(params);
  }, []);

  const onConnect = useCallback(
    (params: Connection | Edge) => {
      // active plex-fd-wrapper flag to avoid next event been force to work
      reactFlowEdgeConnectionInProgress.current = true;
      handleConnection(params);
      forceNodeUpdate(reactFlowInstance.getNode(params.target ?? ""), updateNodeInternals, reactFlowInstance);
      forceNodeUpdate(reactFlowInstance.getNode(params.source ?? ""), updateNodeInternals, reactFlowInstance);
    },
    [handleConnection]
  );

  const onConnectStart = useCallback((_: React.MouseEvent | React.TouchEvent, params: OnConnectStartParams) => {
    connectingNodeInfo.current = params;
  }, []);

  const [formIdState, setIdState] = React.useState({ value: "" });

  const onConnectEnd = useCallback(
    (event: any) => {
      event.stopPropagation();
      const targetIsPane = event.target?.classList.contains("react-flow__pane");

      if (targetIsPane && reactFlowWrapper && !reactFlowEdgeConnectionInProgress.current) {
        const projectValue = reactFlowInstance.project({
          x: event.clientX - (reactFlowWrapper.current?.offsetLeft ?? 0),
          y: event.clientY - (reactFlowWrapper.current?.offsetTop ?? 0)
        });

        // center
        projectValue.x -= 50;
        projectValue.y -= 100;

        addNodeMenuState.forNodeId = connectingNodeInfo.current?.nodeId ?? undefined;
        addNodeMenuState.forNodeHandleId = connectingNodeInfo.current?.handleId ?? undefined;
        addNodeMenuState.forNodeType = reactFlowInstance.getNode(connectingNodeInfo.current?.nodeId ?? "")?.type ?? "";

        setAddNodeMenuState({ ...addNodeMenuState });

        addNodeMenuContainerState.initialPosition.x = projectValue.x;
        addNodeMenuContainerState.initialPosition.y = projectValue.y;
        addNodeMenuContainerState.visible = true;

        setAddNodeMenuContainerState({ ...addNodeMenuContainerState });
      }

      // reset flag
      reactFlowEdgeConnectionInProgress.current = false;
      // this means node popup is active
      reactFlowCanvasClickStatus.current = true;

      updateEdgeValidationStyling();
    },
    [project, experimentalModeState]
  );

  const showCanvas = () => {
    setTimeout(() => {
      canvasHiddenState.hidden = false;
      setCanvasHiddenState({ hidden: false });
    }, 150);
  };

  const hideCanvas = () => {
    canvasHiddenState.hidden = true;
    setCanvasHiddenState({ hidden: true });
  };

  const registerUxViewControllerFunctions = () => {
    // Register get/set flow document functions on plex flow designer framework controller, since we need to call from outside of React
    if (plexFlowDesignerController) {
      plexFlowDesignerController.loadFlow = (flowDocument: IFlowDocumentModel, flowMetaData: IFlowMetaData) => {
        verifyAndLoadDocument(
          flowDocument,
          flowInputController,
          reactFlowInstance,
          updateNodeInternals,
          showCanvas,
          hideCanvas,
          controller.bannerContext
        );

        if (flowMetaData) {
          documentPropertiesState.name = flowMetaData.name;
          documentPropertiesState.summary = flowMetaData.summary;
          setDocumentPropertiesState(flowMetaData);
        }
      };
      plexFlowDesignerController.getFlow = () =>
        convertToDocument(flowInputController?.flowInputPool, reactFlowInstance);
      plexFlowDesignerController.setReadOnly = (readOnly: boolean) => setReadOnlyState(readOnly);
      plexFlowDesignerController.importFlow = () =>
        openFlow(
          flowInputController,
          reactFlowInstance,
          updateNodeInternals,
          showCanvas,
          hideCanvas,
          controller.bannerContext
        );
      plexFlowDesignerController.exportFlow = () => download(flowInputController, reactFlowInstance);
      plexFlowDesignerController.setExperimentalMode = (mode: boolean) => setExperimentalModeState(mode);
      plexFlowDesignerController.validateFlow = () => validateFlow(controller.bannerContext);
      plexFlowDesignerController.setRunResult = (runResult: IExecutionSummary) =>
        setRunResult(runResult, controller.bannerContext);
      plexFlowDesignerController.addMessage = (message: string, status: BannerStatus) =>
        addMessage(message, status, controller.bannerContext);
      plexFlowDesignerController.resetBanner = () => resetBanner(controller.bannerContext);
    }

    return true;
  };

  const handleValidationResult = (
    validationResult: IFlowRunResponse,
    bannerContext: any,
    setTestPropertiesState: Function,
    testPropertiesState: any
  ) => {
    if (validationResult.missingRequiredInput.length > 0) {
      const errorMessages = getMissingInputErrorMessages(validationResult);
      const singleErrorMessage = getMissingInputErrorMessages(validationResult, true)[0];

      if (singleErrorMessage) {
        bannerContext?.addMessage(singleErrorMessage.message, BannerStatus.error);
      }

      setTestPropertiesState({
        ...testPropertiesState,
        errors: errorMessages,
        isValid: false
      });

      return false;
    }
    return true;
  };

  const handleSelectedNodeVisibility = () => {
    const selectedNodes = reactFlowInstance.getNodes().filter((n) => n.selected);
    const allEdges = reactFlowInstance.getEdges();

    const selectedNodeEdges = allEdges.filter(
      (e) =>
        selectedNodes.filter(
          (n) =>
            (n.id === e.source || n.id === e.target) &&
            !e.className?.includes("connected-node-selected") &&
            !e.className?.includes("hidden-anchored-edge")
        ).length > 0
    );
    const deSelectedNodeEdges = allEdges.filter(
      (e) =>
        e.className?.includes("connected-node-selected") &&
        !e.className?.includes("hidden-anchored-edge") &&
        !selectedNodes.some((n) => n.id === e.source || n.id === e.target)
    );

    const selectionsChanged = selectedNodeEdges.length > 0 || deSelectedNodeEdges.length > 0;

    if (selectionsChanged) {
      reactFlowInstance.setEdges((edges) => {
        edges.forEach((e) => {
          if (selectedNodeEdges.filter((se) => se.id === e.id).length > 0) {
            e.className = e.className ? (e.className += " connected-node-selected") : "connected-node-selected";
          }
          if (deSelectedNodeEdges.filter((de) => de.id === e.id).length > 0) {
            e.className = e.className?.replace("connected-node-selected", "")?.trim();
          }
        });
        return edges;
      });
    }
  };

  /** Validates the client-side designer document prior to sending to the interpreter api. */
  const validateFlow = (bannerContext: IBannerContext): Promise<Boolean> => {
    const orderErrors = validateOrder({
      nodes: reactFlowInstance.getNodes(),
      edges: reactFlowInstance.getEdges()
    });
    if (orderErrors.length > 0) {
      console.log(orderErrors);
      const errorMessage = "There are control flow order errors.";
      bannerContext?.addMessage(errorMessage, BannerStatus.error);

      setTestPropertiesState({
        ...testPropertiesState,
        errors: [{ message: errorMessage }],
        isValid: false
      });
      return Promise.resolve(false);
    }
    return new Promise((resolve) => {
      simulateFlow({
        input: {},
        nodes: reactFlowInstance.getNodes(),
        edges: reactFlowInstance.getEdges(),
        runtimeNodeDelayMs: testPropertiesState.runtimeNodeDelayMs,
        bannerContext: bannerContext,
        updateNodeInternals,
        validateOnly: true
      }).then((validationResult: IFlowRunResponse) => {
        const isValid = handleValidationResult(
          validationResult,
          bannerContext,
          setTestPropertiesState,
          testPropertiesState
        );
        resolve(isValid);
      });
    });
  };

  /** Accepts the run results from the interpreter api and either displays errors in the testProperties or replays the run */
  const setRunResult = (result: IExecutionSummary, bannerContext: IBannerContext) => {
    if (!result || result.errors == null) {
      bannerContext?.addMessage("An unexpected error occurred.", BannerStatus.error);
    }

    const newReplayState: IReplay = {
      ...testPropertiesState.replay,
      // Create an array with all the possible entries.
      // This means we need to see if an entry is undefined vs checking to see if it's in the array
      // It also means we'll be doing updates to the array when we load data in chunks
      // But it let's us assume array and entryIndex are the same value vs having to do find everywhere
      nodeTraces: [...Array(result.nodeTraceCount)],
      isPaused: false,
      isStopped: true,
      isStopping: false,
      traceIndex: 0,
      executionId: result.executionId
    };

    const newTestPropertiesState: ITestProperties = {
      ...testPropertiesState,
      replay: newReplayState,
      isValid: result.success,
      errors: result.errors,
      executionTime: result.executionTime,
      nodeTraceCount: result.nodeTraceCount
    };

    setTestPropertiesState(newTestPropertiesState);
    globalThis.testPropertiesState = newTestPropertiesState;
    globalThis.setTestPropertiesState(newTestPropertiesState);

    if (result.errors.length > 0) {
      bannerContext?.addMessage(result.errors[0]?.message, BannerStatus.error);
    }

    const handleRunResponse = () => {
      return new Promise(() => {
        if (result.success) {
          playReplay(reactFlowInstance, updateNodeInternals, controller.bannerContext, functionSubscriber);
        } else {
          const oldDelay = testPropertiesState.runtimeNodeDelayMs;
          setNodeDelayMs(0);
          playReplay(reactFlowInstance, updateNodeInternals, controller.bannerContext, functionSubscriber);
          setTimeout(() => setNodeDelayMs(oldDelay), 1);
        }
      });
    };
    if (!getReplayState().isStopped) {
      stopReplay(reactFlowInstance, handleRunResponse);
    } else {
      handleRunResponse();
    }
    setPropertiesTabIndexState(1);
  };

  const triggerRender = () => {
    // since we are updating some code using dom which make react confusing what to render and what not to
    setTriggerRender((rc) => rc + 1);
  };

  const addMessage = (message: string, status: BannerStatus, bannerContext: IBannerContext) => {
    bannerContext?.addMessage(message, status);
  };

  const resetBanner = (bannerContext: IBannerContext) => {
    bannerContext?.reset();
  };

  const reactFlowDivWrapper = reactFlowWrapper?.current?.getBoundingClientRect();

  const ReactFlowDefaultComponent = ReactFlowDefault as React.ComponentType<ReactFlowProps>;

  return loadCompletedState.completed ? (
    <div className={readOnlyState ? "designer-read-only-parent-container" : ""} style={{ width: "100%" }}>
      <PersistentBanner />
      <BannerProvider>
        <BannerConsumer>
          {(bannerContext: IBannerContext) => {
            controller.bannerContext = bannerContext;
            AddMenuNodePopulater._bannerContext = bannerContext;
            if (readOnlyState) {
              bannerContext?.addMessage("You are viewing a flow in read-only mode. Changes will not be saved.");
            }
            return (
              <div
                onMouseDown={
                  readOnlyState
                    ? (event: React.MouseEvent<HTMLDivElement>) =>
                        event.preventDefault ? event.preventDefault() : ((event as any).returnValue = false)
                    : undefined
                }
              >
                <ViewControllerProvider controller={controller}>
                  <FlowInputManagement
                    show={flowInputModalState.show}
                    onClose={() => {
                      setFlowInputModalState({ show: false });
                    }}
                  />
                  <DesignerContainer
                    documentProperties={{
                      document: documentPropertiesState,
                      setDocumentPropertiesState: setDocumentPropertiesState
                    }}
                    testProperties={{
                      testSettings: testPropertiesState,
                      setTestPropertiesState: setTestPropertiesState
                    }}
                    propertiesTabIndexState={propertiesTabIndexState}
                    setPropertiesTabIndexState={setPropertiesTabIndexState}
                    triggerRender={triggerRender}
                    readOnlyFlow={readOnlyState}
                  >
                    <BannerPlaceholder id="designerBanner" />
                    <div
                      className={"plex-fd-wrapper" + (canvasHiddenState.hidden ? " flow-canvas-hidden" : "")}
                      ref={reactFlowWrapper}
                    >
                      {(globalThis.reactFlowWrapper = reactFlowWrapper) ? <></> : <></>}
                      <ReactFlowDefaultComponent
                        proOptions={{ hideAttribution: true }}
                        defaultViewport={{ x: 0, y: 0, zoom: 1 }}
                        multiSelectionKeyCode={["Control"]}
                        deleteKeyCode={null}
                        nodes={nodes}
                        zoomOnDoubleClick={false}
                        onClick={() => {
                          if (reactFlowCanvasClickStatus.current) {
                            // reset it to avoid conflict
                            reactFlowCanvasClickStatus.current = false;
                          } else {
                            // hide pop-up
                            setAddNodeMenuContainerState({ ...addNodeMenuContainerState, visible: false });
                          }
                        }}
                        onNodeDrag={
                          readOnlyState
                            ? Noop
                            : (event, node) => {
                                handleGroupNodeDrag(node, reactFlowInstance);
                                handleAnchorDrag(node, reactFlowInstance);
                              }
                        }
                        onNodeDragStart={
                          readOnlyState
                            ? Noop
                            : (event, node) => {
                                if (node.data.nodeProperties.parentNode) {
                                  let expandedGroup = reactFlowInstance.getNode(node.data.nodeProperties.parentNode)!;
                                  beforeChildPositionChange(expandedGroup);
                                }
                              }
                        }
                        onNodeDragStop={
                          readOnlyState
                            ? Noop
                            : (event, node) => {
                                if (node.data.nodeProperties.parentNode) {
                                  let expandedGroup = reactFlowInstance.getNode(node.data.nodeProperties.parentNode)!;
                                  onChildPositionChange(expandedGroup, reactFlowInstance);
                                }
                                reactFlowInstance.setEdges(
                                  handleAnchorNodeDrop(
                                    node,
                                    reactFlowInstance.getEdges(),
                                    reactFlowInstance,
                                    updateNodeInternals
                                  )
                                );
                              }
                        }
                        onNodesChange={(changes) => {
                          // Prevent further logic if dragging or dimension update is triggered,
                          // since drag position update is handled in another event.
                          if (
                            changes.some(
                              (change: NodeChange) =>
                                change.type === "dimensions" || (change.type === "position" && change.dragging)
                            )
                          ) {
                            onNodesChange(changes);
                            return;
                          }

                          if (changes.some((change: NodeChange) => change.type === "remove")) {
                            let nodes = reactFlowInstance.getNodes();
                            changes.forEach((c) => {
                              if (c.type === "remove" && nodes.filter((n) => n.id === c.id && n.type === "flowGroup")) {
                                let group = nodes.filter((n) => n.id === c.id && n.type === "flowGroup")[0];
                                if (group) {
                                  deleteChildren(group, reactFlowInstance);
                                }
                              }
                            });
                          }

                          const movedNodes: Node<any>[] = reactFlowInstance
                            .getNodes()
                            .filter((n: Node<any>) =>
                              changes.some(
                                (change: NodeChange) =>
                                  change.type === "position" &&
                                  !change.dragging &&
                                  change.position &&
                                  change.id === n.id
                              )
                            );

                          if (movedNodes.length > 0) {
                            const movedAnchoredNodes = handleAnchorDrag(movedNodes[0]!, reactFlowInstance);
                            movedAnchoredNodes.forEach((n: Node<any>) => {
                              const change = changes.find((c: any) => c.id === n.id);
                              if (change) {
                                n.data.nodeProperties.lastPosition.x = (change as any).position.x;
                                n.data.nodeProperties.lastPosition.y = (change as any).position.y;
                              }
                              forceNodeUpdate(n, updateNodeInternals, reactFlowInstance);
                            });
                            if (movedAnchoredNodes.length > 0) {
                              changes = changes.filter((c: any) =>
                                movedAnchoredNodes.some((n: Node<any>) => n.id === c.id)
                              );
                            }
                          }
                          onNodesChange(changes);
                        }}
                        onNodesDelete={
                          readOnlyState
                            ? Noop
                            : (nodes) => {
                                nodes
                                  .filter((n) => n.type === "flowGroup")
                                  .forEach((g) => deleteChildren(g, reactFlowInstance));
                              }
                        }
                        edges={edges}
                        onEdgesChange={readOnlyState ? Noop : onEdgesChange}
                        onEdgeUpdateEnd={
                          readOnlyState
                            ? Noop
                            : (_, newConnection) => {
                                let existingEdge = reactFlowInstance.getEdge(newConnection.id);
                                forceNodeUpdate(
                                  reactFlowInstance.getNode(existingEdge?.target ?? ""),
                                  updateNodeInternals,
                                  reactFlowInstance
                                );
                                forceNodeUpdate(
                                  reactFlowInstance.getNode(existingEdge?.source ?? ""),
                                  updateNodeInternals,
                                  reactFlowInstance
                                );
                                if (existingEdge) {
                                  checkAndRemoveUnmappable(
                                    [{ edge: existingEdge, newSchema: undefined }],
                                    () => {
                                      let newEdges: Edge[] = [];
                                      setEdges((eds) => {
                                        newEdges = eds.filter((e) => e.id !== newConnection.id);
                                        return newEdges;
                                      });
                                      // New edge connection will be added to reactFlowInstance at a later point, so need a timeout.
                                      setTimeout(() => {
                                        updateLinkedSchemas(
                                          reactFlowInstance.getNodes(),
                                          reactFlowInstance.getEdges(),
                                          updateNodeInternals,
                                          reactFlowInstance
                                        );
                                      });
                                    },
                                    reactFlowInstance,
                                    updateNodeInternals
                                  );
                                }
                              }
                        }
                        onEdgeUpdate={
                          readOnlyState
                            ? Noop
                            : (oldConnection, newConnection) => {
                                let currentEdge = reactFlowInstance.getEdge(oldConnection.id);
                                forceNodeUpdate(
                                  reactFlowInstance.getNode(currentEdge?.target ?? ""),
                                  updateNodeInternals,
                                  reactFlowInstance
                                );
                                forceNodeUpdate(
                                  reactFlowInstance.getNode(currentEdge?.source ?? ""),
                                  updateNodeInternals,
                                  reactFlowInstance
                                );

                                !readOnlyState && onConnect(newConnection);
                              }
                        }
                        onEdgesDelete={Noop}
                        onConnect={readOnlyState ? Noop : onConnect}
                        onConnectStart={readOnlyState ? Noop : onConnectStart}
                        onConnectEnd={readOnlyState ? Noop : onConnectEnd}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onSelectionChange={(params: { nodes: Node[]; edges: Edge[] }) => {
                          if (params.nodes.length === 1) {
                            const selectedNode = params.nodes[0]!;
                            if (formIdState.value !== selectedNode.id) {
                              setIdState({ value: selectedNode.id });
                              setTestPropertiesState({ ...testPropertiesState, node: selectedNode });

                              displaySelectedNodeSnapshotData(
                                selectedNode,
                                functionSubscriber,
                                reactFlowInstance,
                                updateNodeInternals
                              );

                              setPropertiesPopupState(false);
                            }
                          }
                          if (params.nodes.length === 0) {
                            if (formIdState.value !== "") {
                              setIdState({ value: "" });
                              setTestPropertiesState({ ...testPropertiesState, node: undefined });
                            }
                          }
                          handleSelectedNodeVisibility();
                        }}
                        panOnDrag={true}
                        elementsSelectable={!readOnlyState}
                        nodesConnectable={!readOnlyState}
                        nodesDraggable={!readOnlyState}
                        onPaneClick={readOnlyState ? Noop : undefined}
                        onPaneScroll={readOnlyState ? Noop : undefined}
                        onPaneContextMenu={readOnlyState ? Noop : undefined}
                        onNodeClick={readOnlyState ? Noop : undefined}
                        disableKeyboardA11y={readOnlyState ? true : undefined}
                      >
                        <MiniMap pannable={true} position="bottom-left" />
                        {readOnlyState ? <></> : <Background variant={BackgroundVariant.Dots} />}
                        <Controls showInteractive={false} />
                        {registerUxViewControllerFunctions() ? <></> : <></>}
                      </ReactFlowDefaultComponent>
                      <NodePopupContainer selectedNodeId={formIdState.value} reactflowContainer={reactFlowDivWrapper!}>
                        <NodePopup key={`nodePopup${formIdState.value}`} nodeId={formIdState.value}>
                          <BaseNodePropertiesForm id={formIdState.value} />
                        </NodePopup>
                      </NodePopupContainer>
                      <NodePopupContainer
                        draggable={true}
                        visible={addNodeMenuContainerState.visible}
                        initialPosition={addNodeMenuContainerState.initialPosition}
                        reactflowContainer={reactFlowDivWrapper!}
                      >
                        <div ref={addNodeMenuContainerRef}>
                          <AddNodeMenu
                            forNodeId={addNodeMenuState.forNodeId}
                            forNodeType={addNodeMenuState.forNodeType}
                            forNodeHandleId={addNodeMenuState.forNodeHandleId}
                            addNodeMenuContainerRef={addNodeMenuContainerRef}
                            reactflowContainer={reactFlowDivWrapper}
                            onAdd={() => {
                              addNodeMenuContainerState.visible = false;
                            }}
                            onClose={() => {
                              setAddNodeMenuContainerState({ ...addNodeMenuContainerState, visible: false });
                            }}
                          />
                        </div>
                      </NodePopupContainer>
                      <ConfirmationDialog />
                    </div>
                  </DesignerContainer>
                </ViewControllerProvider>
              </div>
            );
          }}
        </BannerConsumer>
      </BannerProvider>
    </div>
  ) : (
    <></>
  );
};
