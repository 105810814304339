import React, { FC, MouseEvent, MouseEventHandler, ReactElement, forwardRef, PropsWithChildren } from "react";
import clsx from "clsx";
import { withPrinting, withNoPrinting } from "@plex/react-xml-renderer";
import { Checkbox } from "@components/Checkbox";
import { useUid } from "@hooks";
import { ITableCellProps, ITableHeaderCellProps, ITableSelectionCellProps } from "./DataTable.types";
import { useGridSelection } from "./Plugins/DataSelection.hooks";
import { useSort } from "./Plugins/DataSorter.hooks";
import { SortIndicator } from "./SortIndicator";
import { useDataRowContext } from "./DataTableBody";
import styles from "./DataTable.module.scss";

/** Component which renders a cell in a DataTable. */
const HtmlDataTableCell = forwardRef<HTMLTableCellElement, PropsWithChildren<ITableCellProps>>(
  ({ as: TagName = "td", children, className, align = "left", ...other }, ref) => {
    const classes = clsx(className, align !== "left" && (align === "center" ? styles.alignCenter : styles.alignRight));
    return (
      <TagName className={classes} ref={ref} {...other}>
        {children}
      </TagName>
    );
  }
);
HtmlDataTableCell.displayName = "HtmlDataTableCell";

const XmlDataTableCell: FC<ITableCellProps> = ({ align, rowSpan, colSpan, children }) => {
  let layout = {};

  if (colSpan) {
    layout = { ...layout, colspan: colSpan };
  }

  if (rowSpan) {
    layout = { ...layout, rowspan: rowSpan };
  }
  if (align) {
    layout = { ...layout, "text-align": align };
  }
  return React.createElement("grid-table-cell", { ...layout }, children);
};

export const DataTableCell = withPrinting(HtmlDataTableCell, XmlDataTableCell);

/** Component which renders a header cell in a DataTable. */
// eslint-disable-next-line func-style
function HtmlDataTableHeaderCell<T extends {}>({
  id,
  title,
  shortTitle,
  className,
  align,
  sortable,
  sortComparer,
  initialSortState,
  children,
  ...other
}: PropsWithChildren<ITableHeaderCellProps<T>>) {
  const renderId = useUid();
  const [sortState, sortToggle] = useSort(id || renderId, sortComparer, initialSortState);
  const displayTitle = shortTitle || title;
  const longTitle = displayTitle === title ? null : title;
  const content = (
    <>
      {displayTitle && (
        <abbr className={styles.headerText} title={longTitle || ""}>
          {displayTitle}
        </abbr>
      )}
      {children}
    </>
  );

  const onClick = sortable ? sortToggle : null;
  return (
    <DataTableCell
      id={id}
      data-testid="plex-grid-header-cell"
      as="th"
      align={align}
      className={clsx(styles.headerCell, sortable && styles.sortable, className)}
      onClick={onClick as MouseEventHandler<HTMLTableCellElement>}
      {...other}
    >
      {content}
      {sortable && <SortIndicator {...sortState} />}
    </DataTableCell>
  );
}

const XmlDataTableHeaderCell = <T extends {}>({
  align,
  rowSpan,
  colSpan,
  children
}: PropsWithChildren<ITableHeaderCellProps<T>>) => {
  const layout = {
    colspan: colSpan,
    rowspan: rowSpan,
    "text-align": align
  };
  return React.createElement("grid-table-cell", layout, children);
};
interface IDataTableHeaderCell {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  <T extends {}>(props: PropsWithChildren<ITableHeaderCellProps<T>>): ReactElement;
}

export const DataTableHeaderCell = withPrinting(
  HtmlDataTableHeaderCell,
  XmlDataTableHeaderCell
) as IDataTableHeaderCell;

/**
 * DataTable cell which includes a checkbox for row selection. Used in multiselect
 * scenarios.
 */
export const HtmlDataTableSelectionCell: FC<ITableSelectionCellProps> = ({ disabled, ...other }) => {
  const { rowInfo } = useDataRowContext();
  const { selectionState } = rowInfo;

  const toggleSelection = () => {
    if (disabled) {
      return;
    }

    selectionState.toggle();
  };

  const onCellClick = (e: MouseEvent<HTMLTableCellElement>) => {
    if (disabled || (e.target as HTMLElement).nodeName === "INPUT") {
      return;
    }

    selectionState.toggle();
  };

  return (
    <DataTableCell align="center" onClick={onCellClick} {...other}>
      <Checkbox
        data-testid="plex-grid-row-select"
        checked={selectionState.selected}
        onChange={toggleSelection}
        disabled={disabled}
      />
    </DataTableCell>
  );
};

const XmlDataTableSelectionCell: FC<ITableSelectionCellProps> = ({ align, colSpan, children }) => {
  let layout = {};

  if (colSpan) {
    layout = { ...layout, colspan: colSpan };
  }
  if (align) {
    layout = { ...layout, "text-align": align };
  }
  return React.createElement("plex-grid-row-select", { ...layout }, children);
};

export const DataTableSelectionCell = withPrinting(HtmlDataTableSelectionCell, XmlDataTableSelectionCell);

/**
 * DataTable header cell which includes a checkbox for toggling all selections.
 * Used in multiselect scenarios.
 */
export const HtmlDataTableHeaderSelectionCell = ({
  className,
  as = "th",
  align = "center",
  ...other
}: ITableCellProps) => {
  const [status, toggleAll] = useGridSelection();
  const checked = status !== "none";
  const indeterminate = status === "some";

  return (
    <DataTableCell
      as={as}
      className={clsx(styles.headerCell, styles.selectionCell, className)}
      align={align}
      {...other}
    >
      <Checkbox
        data-testid="plex-grid-header-select"
        checked={checked}
        indeterminate={indeterminate}
        onChange={toggleAll}
      />
    </DataTableCell>
  );
};

export const DataTableHeaderSelectionCell = withNoPrinting(HtmlDataTableHeaderSelectionCell);
