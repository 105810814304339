import React from "react";
import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import { PlexFlow } from "./PlexFlow";

export const ReactFlowExtBooter = () => {
  return (
    <div>
      <ReactFlowProvider>
        <PlexFlow />
      </ReactFlowProvider>
    </div>
  );
};
