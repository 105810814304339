import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import styles from "./MainMenu.module.scss";
import { MainMenuItemIcon } from "./MainMenu.ItemIcon";

export interface IMainMenuItemProps extends ICommonProps {
  /**
   * Element id
   */
  id: string;

  /**
   * Is the item hidden
   */
  hidden?: boolean;

  /**
   * Menu item text
   */
  text?: string;

  /**
   * Click handler for button
   */
  onClick?: (e: React.MouseEvent, props: unknown) => void;

  /**
   * Props, passed to component from outside, and returned through onClick
   */
  onClickProps?: unknown;

  /**
   * Item icon
   */
  icon?: React.ReactNode;

  selected?: boolean;
}

export const MainMenuItem: FunctionComponent<IMainMenuItemProps> = ({
  hidden,
  selected,
  text,
  onClick,
  onClickProps,
  icon,
  className,
  ...other
}) => {
  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick?.(e, onClickProps);
  };

  if (hidden) {
    return null;
  }

  return (
    <div
      className={clsx(selected ? styles.menuItemSelected : styles.menuItem, className)}
      onClick={clickHandler}
      {...other}
    >
      <div className={styles.menuItemButton}>
        {icon && <MainMenuItemIcon>{icon}</MainMenuItemIcon>}
        <span className={styles.menuItemText}>{text}</span>
      </div>
    </div>
  );
};

MainMenuItem.displayName = "MainMenuItem";
