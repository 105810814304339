import React, { FunctionComponent, KeyboardEvent, useEffect, useState } from "react";
import { useLocalization } from "@plex/culture-react";
import { PushpinIcon } from "@plex/icons";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { Link } from "../Link";
import { BannerPlaceholder } from "../Banner";
import { ICommonProps } from "../Common.types";
import { SearchButton } from "./SearchButton";
import styles from "./Filter.module.scss";
import { FilterState, FilterContext } from "./FilterState";
import { XmlControlSection } from "../XmlControlSection";

export interface IFilterSectionProps extends ICommonProps {
  /**
   * Indicates whether filter has default saved state, this is controlled value by onSaveDefaults
   * @default false
   * @requires onSaveDefaults
   * @requires onClearDefaults
   */
  hasDefaults?: boolean;
  /**
   * Callback triggered when Pin icon is clicked
   * @requires pinned
   */
  onPin: (value: boolean) => void;
  /**
   * Callback triggered when the 'Save As Default' is clicked
   * @requires hasDefaults
   */
  onSaveDefaults?: () => void;
  /**
   * Callback triggered when the 'Clear Defaults' is clicked
   * @requires hasDefaults
   */
  onClearDefaults?: () => void;
  /** Callback triggered when Search button is clicked */
  onSearch: () => void;
  /**
   * Indicates whether filter is pinned, this is controlled value by onPin
   * @requires onPin
   */
  pinned: boolean;
  /** Sets the initial searched state */
  isSearched?: boolean;
  /** Sets the initial collapsed state */
  isCollapsed?: boolean;
}

const HtmlFilterSection: FunctionComponent<IFilterSectionProps> = ({
  children,
  hasDefaults,
  className,
  onPin,
  onSaveDefaults,
  onClearDefaults,
  onSearch,
  pinned,
  isSearched,
  isCollapsed,
  ...other
}) => {
  const { t } = useLocalization();
  const [state, setState] = useState<FilterState>({
    searched: false,
    collapsed: false
  });

  useEffect(() => {
    const searched = isSearched || false;
    const collapsed = isCollapsed || false;
    setState({ searched, collapsed: collapsed && !pinned });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearched, isCollapsed]);

  const toggleCollapsed = () => {
    setState(c => ({ ...c, collapsed: !c.collapsed }));
  };

  const handleSearch = () => {
    const collapsed = pinned ? state.collapsed : true;
    setState(c => ({ ...c, collapsed, searched: true, timestamp: Date.now() }));
    onSearch();
  };

  const togglePin = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    onPin?.(!pinned);
  };

  const toggleSaveDefaults = () => {
    if (hasDefaults) {
      onClearDefaults?.();
    } else {
      onSaveDefaults?.();
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      handleSearch();

      // Prevent default to cancel useless form submit on enter
      e.preventDefault();
    }
  };

  return (
    <FilterContext.Provider value={state}>
      <form onKeyPress={handleKeyPress}>
        <div className={clsx(styles.base, className)} data-testid="plex-filter-content" {...other}>
          <div data-testid="plex-filter-header">
            <BannerPlaceholder />
          </div>
          <div className={styles.contentSection} data-testid="plex-filter-controls">
            {children}
          </div>
          <div className={styles.actionSection} data-testid="plex-filter-searchbar">
            <div className={styles.actions} data-testid="plex-filter-searchactions">
              <Link className={styles.actionLink} onClick={toggleCollapsed} data-testid="plex-filter-hide-show-link">
                {state.collapsed ? t("ui-common-filter-action-showFilters") : t("ui-common-filter-action-hideFilters")}
              </Link>
              {(onSaveDefaults || onClearDefaults) && (
                <Link
                  className={styles.actionLink}
                  onClick={toggleSaveDefaults}
                  data-testid="plex-filter-defaults-link"
                >
                  {hasDefaults ? t("ui-common-filter-action-clearDefaults") : t("ui-common-filter-action-saveDefaults")}
                </Link>
              )}
              {!state.collapsed && (
                <i
                  className={clsx(styles.pinIcon, pinned && styles.pinned)}
                  onClick={togglePin}
                  data-testid="plex-filter-pin-icon"
                >
                  <PushpinIcon />
                </i>
              )}
              <SearchButton onClick={handleSearch} data-testid="plex-filter-search-button" />
            </div>
          </div>
        </div>
      </form>
    </FilterContext.Provider>
  );
};

const XmlFilterSection: FunctionComponent<IFilterSectionProps> = ({ children }) => {
  const filterSection = React.createElement("plex-filter-panel", {}, children);
  return React.createElement(XmlControlSection, {}, filterSection);
};

export const FilterSection = withPrinting(HtmlFilterSection, XmlFilterSection);
