import React from "react";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { DateInput, IDateInputProps } from "../../DatePicker/DateInput";
import * as globalStyles from "../../../global-styles";
import { FormikField } from "./Formik.types";

export const FormikDateInput: FormikField<IDateInputProps> = ({ name, className, onValueChange, ...other }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.errors?.[name];
        return (
          <DateInput
            value={field.value}
            className={clsx(className, hasError && globalStyles.hasErrors)}
            onValueChange={date => {
              form.setFieldValue(field.name, date, true);
              form.setFieldTouched(field.name, true, false);
              onValueChange?.(date);
            }}
            {...other}
          />
        );
      }}
    </Field>
  );
};
