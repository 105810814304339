import { ReactText } from "react";
import { ValueAccessor } from "../Common.types";

export enum PromptType {
  buttons = "buttons",
  list = "list",
  textbox = "textbox",
  password = "password",
  checkboxes = "checkboxes",
  textarea = "textarea"
}

export interface IDialogPromptProps<T = string> {
  /** Possible answers */
  answers: T[];
  /** Function, which gets key from option item */
  keySelector: ValueAccessor<T, ReactText>;
  /**  Function, which gets value from option item */
  displaySelector: ValueAccessor<T, string>;
  /** Default answer */
  selectedItems: T[];
  /** Callback which is executed when selection is made. */
  onSelectionChanged: (items: T[]) => void;
  /** Indicates how the choices are displayed */
  displayType?: PromptType;
}
