import { ReactText, useEffect } from "react";
import { noop } from "@utils";
import { ColumnSortState, GridState } from "../DataTable.types";
import { useDataTableDispatcher, useDataTableSelector } from "../DataTableStateProvider";
import { Comparer } from "../../Common.types";
import { setInitialSort, toggleSort, GridStateWithSort, SortEntry, SortState } from "./DataSorter.actions";

const SORT_SELECTOR = <S>(s: GridState<S>): SortState<S> | undefined => (s as GridStateWithSort<S>).sort;
const EMPTY_COLUMN_STATE: ColumnSortState = { dir: null, order: 0 };

export const useCurrentSort = <T>(id?: ReactText): SortEntry<T> | null => {
  const state = useDataTableSelector(SORT_SELECTOR);

  if (!state || id == null) {
    return null;
  }

  const { sorts } = state;
  return sorts.find((x: SortEntry<T>) => x.id === id) || null;
};

export const useSort = <T>(
  id: ReactText,
  comparer?: Comparer<T>,
  initialSortState?: ColumnSortState
): [ColumnSortState, () => void] => {
  const dispatch = useDataTableDispatcher<T>();
  const state = useDataTableSelector(SORT_SELECTOR);

  useEffect(() => {
    if (!comparer || !initialSortState) {
      return;
    }

    const { order: index, dir } = initialSortState;
    dispatch(setInitialSort({ index, id, comparer, dir: dir || "asc" }));
  }, [id, comparer, initialSortState, dispatch]);

  if (!comparer || !state) {
    return [EMPTY_COLUMN_STATE, noop];
  }

  const sorter = () => dispatch(toggleSort({ id, comparer }));

  const { sorts, fixed } = state;
  const sortIndex = sorts.findIndex((x: SortEntry<T>) => x.id === id);
  if (sortIndex === -1) {
    return [EMPTY_COLUMN_STATE, sorter];
  }

  const sortEntry = sorts[sortIndex];
  return [
    {
      dir: sortEntry.reversed ? "desc" : "asc",
      order: fixed ? sortIndex + 1 : 0
    },
    sorter
  ];
};
