import { FunctionComponent } from "react";
import { createPortal } from "react-dom";

/**
 * Component which renders the children into the DOM outside of
 * the current tree into the document.body. This is a wrapper
 * around ReactDOM.createPortal.
 */
export const Portal: FunctionComponent = ({ children }) => {
  return createPortal(children, document.body);
};
