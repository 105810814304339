import React, { FunctionComponent, isValidElement, PropsWithChildren, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, RequiredState } from "../Common.types";
import { Text } from "../Text";
import * as globalStyles from "../../global-styles";
import styles from "./Form.module.scss";

export interface IFormFieldPair extends PropsWithChildren<ICommonProps> {
  /** Label text or component for the field */
  labelText?: string | ReactNode;
  /**
   * The field ID that should be associated to the label
   * @description By default the first Field item id is used
   */
  primaryFieldId?: string;
  /**
   * Determines whether the field should be marked as required.
   * This does not actually perform any validation, only displaying
   * the asterisk when true.
   */
  required?: RequiredState;
}

const getRequiredClasses = (required: RequiredState | undefined) => {
  switch (required) {
    case RequiredState.required:
      return globalStyles.isRequiredAfter;
    case RequiredState.group:
      return globalStyles.isRequiredGroupAfter;
    default:
      return undefined;
  }
};

const HtmlFormFieldPair: FunctionComponent<IFormFieldPair> = ({
  labelText,
  primaryFieldId,
  required,
  children,
  className,
  ...other
}) => {
  const firstChild: ReactElement | undefined = React.Children.toArray(children).filter(isValidElement)[0];
  const requiredClasses = getRequiredClasses(required);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!firstChild) {
    return null;
  }

  let labelElement: ReactNode | null;
  if (typeof labelText === "string") {
    // get the id of the first element to associate with the label
    const labelFor = primaryFieldId || firstChild.props.id;
    labelElement = <Text as="label" labelFor={labelFor} text={labelText} />;
  } else {
    labelElement = isValidElement(labelText) ? labelText : null;
  }

  return (
    <div data-testid="plex-control-group" className={clsx(styles.formFieldPair, className)} {...other}>
      <div data-testid="plex-control-label" className={clsx(styles.formLabel, required && requiredClasses)}>
        {labelElement}
      </div>
      <div data-testid="plex-controls" className={styles.formField}>
        {children}
      </div>
    </div>
  );
};

const XmlFormFieldPair: FunctionComponent<IFormFieldPair> = ({ labelText, children }) => {
  const label = React.createElement("plex-control-label", {}, labelText);
  const controls = React.createElement("plex-controls", {}, children);
  return (
    <>
      {label}
      {controls}
    </>
  );
};

export const FormFieldPair = withPrinting(HtmlFormFieldPair, XmlFormFieldPair);
