import React, { createElement } from "react";
import { usePrinting } from "@plex/react-xml-renderer";
import { useTree, TreeProvider, TreeNodeContext } from "./TreeContext";
import { TreeContextType } from "./Tree.types";
import styles from "./Tree.module.scss";

// eslint-disable-next-line func-style
export function TreeList<T>({
  data,
  depth,
  keySelector: propsKeySelector,
  render: propsRender,
  selectable: propsSelectable,
  selectedKeys: propsSelectedKeys,
  toggleSelection: propsToggleSelection,
  onItemMoved: propsOnItemMoved
}: Partial<TreeContextType<T>> & { data: T[] }) {
  const {
    keySelector = propsKeySelector,
    render = propsRender,
    selectable = propsSelectable,
    selectedKeys = propsSelectedKeys,
    toggleSelection = propsToggleSelection,
    onItemMoved = propsOnItemMoved
  } = useTree<T>();

  const child = data.map(row => (
    <TreeNodeContext.Provider key={keySelector!(row)} value={row}>
      {render!(row, depth!)}
    </TreeNodeContext.Provider>
  ));

  const printing = usePrinting();
  if (printing) {
    return (
      <TreeProvider
        depth={depth!}
        render={render!}
        keySelector={keySelector!}
        selectable={selectable!}
        selectedKeys={selectedKeys!}
        toggleSelection={toggleSelection!}
        onItemMoved={onItemMoved}
      >
        {createElement("plex-control-tree", {}, child)}
      </TreeProvider>
    );
  }

  return (
    <TreeProvider
      depth={depth!}
      render={render!}
      keySelector={keySelector!}
      selectable={selectable!}
      selectedKeys={selectedKeys!}
      toggleSelection={toggleSelection!}
      onItemMoved={onItemMoved}
    >
      <ul className={styles.branch}>{child}</ul>
    </TreeProvider>
  );
}
