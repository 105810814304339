type KnownSearchTypes = "all" | "exact" | "any" | "none" | "starts" | "ends";

type SearchMethod = {
  key: KnownSearchTypes;
  name: string;
  messageId: string;
  build: (text: string) => { searchText: string; displayText: string };
  buildOrder?: number;
};

export type AdvancedSearchState = { [key in KnownSearchTypes]?: string };

export const AdvancedSearchMethods: SearchMethod[] = [
  {
    key: "all",
    name: "__allWords",
    messageId: "ui-common-dataPicker-option-searchAllWords",
    build: (text: string) => {
      const textValue = text ? text.trim() : "";
      return {
        searchText: textValue.split(/\s+/g).join(" "),
        displayText: `all of (${textValue.split(/\s+/g).join(" ")})`
      };
    }
  },

  {
    key: "exact",
    name: "__exactPhrase",
    messageId: "ui-common-dataPicker-option-searchExactWords",
    build: (text: string) => {
      const textValue = text || "";
      return {
        searchText: `"${textValue.replace(/"/g, '\\"')}"`,
        displayText: `exactly (${textValue.replace(/"/g, '\\"')})`
      };
    }
  },

  {
    key: "any",
    name: "__anyWords",
    messageId: "ui-common-dataPicker-option-searchAnyWords",
    build: (text: string) => {
      const textValue = text ? text.trim() : "";
      return {
        searchText: textValue.split(/\s+/g).join(" OR "),
        displayText: `one of (${textValue.split(/\s+/g).join(" ")})`
      };
    }
  },

  {
    key: "none",
    name: "__noneWords",
    messageId: "ui-common-dataPicker-option-searchNoneWords",
    build: (text: string) => {
      const textValue = text ? text.trim() : "";
      return {
        searchText: `-${textValue.split(/\s+/g).join(" -")}`,
        displayText: `none of (${textValue.split(/\s+/g).join(" ")})`
      };
    }
  },

  {
    key: "starts",
    name: "__startsWith",
    messageId: "ui-common-dataPicker-option-searchStartsWith",
    build: (text: string) => {
      const textValue = text ? text.trim() : "";
      return {
        searchText: `^${textValue}`,
        displayText: `starts with (${textValue})`
      };
    },
    buildOrder: -1
  },

  {
    key: "ends",
    name: "__endsWith",
    messageId: "ui-common-dataPicker-option-searchEndsWith",
    build: (text: string) => {
      const textValue = text ? text.trim() : "";
      return {
        searchText: `${textValue}$`,
        displayText: `ends with (${textValue})`
      };
    },
    buildOrder: 99
  }
];

export class AdvancedSearch {
  methods: SearchMethod[] = [];

  public readonly state: AdvancedSearchState = {};

  constructor(state: AdvancedSearchState) {
    this.state = state;
  }

  build(displayText = false) {
    return this.getFilteredMethods()
      .map(m => {
        const text = this.state[m.key];
        return displayText ? m.build(text!).displayText : m.build(text!).searchText;
      })
      .join(" ");
  }

  private getFilteredMethods() {
    // only get search methods that have a value set
    this.methods = AdvancedSearchMethods.filter(x => !!this.state[x.key])
      // sort in build order - some are positional
      .sort((a, b) => (a.buildOrder ?? 0) - (b.buildOrder ?? 0));
    return this.methods;
  }
}
