import React, { FC } from "react";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const resultOutputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConstantNullNode",
  label: "Null",
  category: NodeTypeCategory.general,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: resultOutputName, type: DataType.STRING, enabled: true, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [resultOutputName] }
    }
  ],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: { result: null as unknown as string }, activeControlHandleIds: [] };
  }
});

export const ConstantNullNode: FC<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
