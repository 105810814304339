import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType, primitiveTypes } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const listInputName = "list";
const itemOutputName = "item";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ListItemNode",
  category: NodeTypeCategory.lists,
  controlOutputs: [],
  dataInputs: [{ enabled: true, type: DataType.OBJECTLIST, name: listInputName, required: false }],
  dataOutputs: [{ enabled: true, type: DataType.OBJECT, name: itemOutputName }],
  listToItem: true,
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataTypes: [DataType.OBJECT].concat(primitiveTypes), dataProperties: [listInputName, itemOutputName] }
    },
    {
      name: "index",
      label: "Select Item Position",
      propertyType: NodeConfigPropertyType.Position
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any, nodeProperties: any) => {
    let index: number = nodeProperties.index;
    if (index < 0) {
      index = input.list.length + nodeProperties.index;
    }
    const item = { item: (input.list as any)[index] };
    return {
      output: { item: item },
      activeControlHandleIds: []
    };
  }
});

export const ListItemNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const itemIndexOptions = [
    { key: 0, value: "First" },
    { key: -1, value: "Last" }
  ];
  const label =
    (itemIndexOptions.filter((option: any) => data.nodeProperties.index && option.key === data.nodeProperties.index)[0]
      ?.value ?? "First") + " Item";
  data.nodeProperties.label = label;
  return <BasicNodeLayout id={id} data={data} label={label} />;
};
