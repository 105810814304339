import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./Legend.module.scss";

export interface ILegendItemProps extends ICommonProps {
  /** Legend Item Text */
  text?: string;
  /** Legend Item Symbol */
  symbol?: string;
  /** Legend Item Color */
  color?: string;
}

export const LegendItem: FunctionComponent<ILegendItemProps> = ({
  className,
  color,
  style,
  symbol,
  text,
  ...other
}) => {
  const legendStyle = { ...style, backgroundColor: color };
  const symbolInfo = symbol ? symbol.trim() : "";

  return (
    <li {...other}>
      {symbolInfo === "" ? (
        <div className={clsx(styles.legendItemColor, className)} style={legendStyle}>
          &nbsp;
        </div>
      ) : (
        <div className={clsx(styles.legendItemColor, className)} style={legendStyle}>
          {symbolInfo}
        </div>
      )}
      <span className={styles.legendItemText}>{text}</span>
    </li>
  );
};
