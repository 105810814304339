import React, { FC, useEffect } from "react";
import { useUid } from "@hooks";
import { ICommonProps } from "../Common.types";
import { useBanner, useBannerMessages } from "./BannerContext";
import { Banner } from "./Banner";

/**
 * Placeholder for rendering an instance of a banner added with a @see BannerContext.
 */
export const BannerPlaceholder: FC<ICommonProps> = props => {
  const bctx = useBanner();
  const messages = useBannerMessages();

  const id = useUid();
  useEffect(() => bctx?.placeholderRegistry.set(id), [bctx, id]);

  const placeholder = bctx?.placeholderRegistry.get(id);
  if (placeholder?.active && messages.length > 0) {
    // only render last banner...
    const item = messages[messages.length - 1];
    return (
      <Banner
        status={item.status}
        close={() => {
          bctx?.removeMessage(item);
        }}
        {...props}
      >
        {item.content}
      </Banner>
    );
  }

  return null;
};
