import React, { FunctionComponent, useLayoutEffect, useRef } from "react";
import { Handle, Position, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { PlexOpenApiError } from "../PlexOpenApi/PlexOpenApiSpecs";
import { ErrorControlHandle } from "../PlexOpenApi/ErrorControlHandle";
import { IControlFlowParam } from "./NodeTypeDefinitions";
import { AnchorArea, AnchorProperties, getChain, isChainHead } from "../../Util/AnchorUtil";
import { camelCaseToSpacesRegEx } from "../../NodePropertiesForm/FormConstants";
import { hasNodeConnectionErrors } from "../../Util/EdgeUtil";

export interface IHandleContainerProps {
  id: string;
  data: any;
}

export const HandleContainer: FunctionComponent<IHandleContainerProps> = ({ id, data }) => {
  const inputContainerRef = useRef<HTMLTableCellElement>(null);
  const outputContainerRef = useRef<HTMLTableCellElement>(null);
  const nodeData = data;
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const getAnchorProperties = (inputName: string, isInput: boolean): AnchorProperties | undefined => {
    if (data.nodeProperties.anchoredNodes) {
      return data.nodeProperties.anchoredNodes.filter(
        (a: AnchorProperties) =>
          a.area === (isInput ? AnchorArea.Input : AnchorArea.Output) && a.propertyName === inputName
      )[0];
    }
    return undefined;
  };

  const renderAnchorContainer = (anchorProperties: AnchorProperties, isArray: boolean) => {
    if (anchorProperties) {
      if (!isChainHead(anchorProperties.nodeId, reactFlowInstance)) {
        return <></>;
      }

      const padding = 10;
      const chain = getChain(anchorProperties.nodeId, reactFlowInstance);
      const chainSpacing = 10;

      const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId);
      const parentOfAnchoredNode = reactFlowInstance.getNode(data.id);

      const edgeError = hasNodeConnectionErrors(anchoredNode?.data.id, parentOfAnchoredNode?.data.id);

      return (
        <>
          {(anchorProperties.area === AnchorArea.Input ? chain.reverse() : chain).map((chainedNode, chainIndex) => {
            const anchoredNode = reactFlowInstance.getNode(chainedNode.id);
            return (
              <div key={"anchor-container-" + chainIndex}>
                <div style={{ position: "relative" }}>
                  {chainIndex === 0 && anchorProperties.area === AnchorArea.Output ? (
                    <div
                      className={
                        "right-anchor-edge-horizontal" +
                        (isArray ? " right-anchor-edge-horizontal-list" : "") +
                        (edgeError ? " anchored-edge-error" : "")
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {anchorProperties.area === AnchorArea.Output ? (
                    <div
                      className={
                        "right-anchor-edge-vertical" +
                        (isArray ? " right-anchor-edge-vertical-list" : "") +
                        (edgeError ? " anchored-edge-error" : "")
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      height: anchoredNode?.height!,
                      width: anchoredNode?.width! - padding
                    }}
                  />

                  {chainIndex === chain.length - 1 && anchorProperties.area === AnchorArea.Input ? (
                    <div
                      className={
                        "left-anchor-edge-horizontal" +
                        (isArray ? " left-anchor-edge-horizontal-list" : "") +
                        (edgeError ? " anchored-edge-error" : "")
                      }
                    />
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      height: chain.length > 1 && chainIndex < chain.length - 1 ? chainSpacing : 0
                    }}
                  />

                  {anchorProperties.area === AnchorArea.Input ? (
                    <div
                      className={
                        "left-anchor-edge-vertical" +
                        (isArray ? " left-anchor-edge-vertical-list" : "") +
                        (edgeError ? " anchored-edge-error" : "")
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  useLayoutEffect(() => {
    if (inputContainerRef.current) {
      data.nodeProperties.inputContainerWidth = (inputContainerRef.current as any).clientWidth;
      data.nodeProperties.inputOutputHeight = Math.max(
        (inputContainerRef.current as any).clientHeight,
        (outputContainerRef.current as any).clientHeight
      );
      data.nodeProperties.outputHeight = (outputContainerRef.current as any).clientHeight;
    }
  });

  const getInputBubbles = () => {
    let anchoredInput = false;
    if (data.nodeProperties.anchoredToNodeId) {
      const anchorDestinationNode = reactFlowInstance.getNode(data.nodeProperties.anchoredToNodeId)!;
      const anchorProperties: AnchorProperties = anchorDestinationNode.data.nodeProperties.anchoredNodes.filter(
        (anchorProperties: AnchorProperties) => anchorProperties.nodeId === id
      )[0];
      if (anchorProperties.area === AnchorArea.Output) {
        anchoredInput = true;
      }
    }

    const inputs: any[] = [];
    Object.keys(data.nodeProperties.inputs).forEach((inputName: string, inputIndex) => {
      let input = data.nodeProperties.inputs[inputName];
      if (input.enabled === true) {
        let readableInputName = inputName;
        if (input.label) {
          readableInputName = input.label;
        } else if (readableInputName) {
          readableInputName = readableInputName.charAt(0).toUpperCase() + readableInputName.slice(1);
          readableInputName = readableInputName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
        }
        readableInputName = readableInputName.replaceAll("_", " ");
        inputs.push({
          required: input.required === true,
          readableName: readableInputName,
          name: inputName,
          inputIndex: inputIndex,
          type: input.type,
          isArray: input.type?.includes("List") ?? false,
          wireUp: null,
          hideLabel: input.hideLabel
        });
      }
    });

    const getInputContainerClassName = (input: any) => {
      let className = "";

      if (data.nodeProperties.anchoredToNodeId) {
        const chain = getChain(data.id, reactFlowInstance);
        if (input.inputIndex === 0 && chain.findIndex((n) => n.id === data.id) < chain.length - 1) {
          className += " base-node-chained-input-property";
        }
      }

      return className;
    };

    const getInputHandleClassName = (input: any, isAnchored: boolean) => {
      let isConnected =
        reactFlowInstance
          .getEdges()
          .filter((edge) => edge.source === data.id && edge.sourceHandle === input.name + "-" + input.type + "-input")
          .length > 0;
      let className: string;
      if (!isConnected && input.required === true) {
        className = "base-node-handle " + input.type + "-handle-type required-base-input";
      } else {
        className = "base-node-handle " + input.type + "-handle-type";
      }

      if (isAnchored) {
        className += " base-node-anchored-input-handle";
      }

      return className;
    };

    return (
      <div className={anchoredInput ? "anchored-input-container" : ""}>
        {inputs.map((input) => {
          const inputAnchorProperties: AnchorProperties = getAnchorProperties(input.name, true)!;
          return (
            <div key={input.name + "-input-container-parent"} className={getInputContainerClassName(input)}>
              {inputAnchorProperties ? renderAnchorContainer(inputAnchorProperties, input.isArray) : <></>}
              <div
                key={input.name + "-input-container"}
                style={{ position: "relative" }}
                data-testid={id + "-" + input.name + "-" + input.type + "-input"}
              >
                <Handle
                  key={input.name + "-input-handle"}
                  id={input.name + "-" + input.type + "-input"}
                  type="source"
                  position={Position.Left}
                  className={getInputHandleClassName(input, inputAnchorProperties ? true : false)}
                  style={{ top: "9px" }}
                  title={input.type}
                />
                {input.isArray ? <div className="input-list-handle-icon" /> : <></>}
                {input.hideLabel ? (
                  <div className="base-label-blank" />
                ) : (
                  <div
                    key={input.name + "label"}
                    className={
                      "base-input-container" +
                      (inputAnchorProperties
                        ? " anchored" + (inputAnchorProperties.isArray ? "-list" : "") + "-input"
                        : "")
                    }
                    title={input.readableName}
                  >
                    {input.readableName}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getOutputBubbles = () => {
    let isAnchoredFromOutput = false;
    let isAnchoredChainedFromInput = false;
    if (data.nodeProperties.anchoredToNodeId) {
      const anchorDestinationNode = reactFlowInstance.getNode(data.nodeProperties.anchoredToNodeId)!;
      const anchorProperties: AnchorProperties = anchorDestinationNode.data.nodeProperties.anchoredNodes.filter(
        (anchorProperties: AnchorProperties) => anchorProperties.nodeId === id
      )[0];
      if (anchorProperties.area === AnchorArea.Input) {
        isAnchoredFromOutput = true;
      }
      if (anchorProperties.area === AnchorArea.Output) {
        const chain = getChain(id, reactFlowInstance);
        if (chain.length > 1) {
          isAnchoredChainedFromInput = true;
        }
      }
    }

    const outputs = getReadableApiOutputs(nodeData);
    let successOutputs = outputs.filter((o) => !o.isErrorCode);
    let errorOutputs = outputs.filter((o) => o.isErrorCode);
    const updateHandles = () => {
      updateNodeInternals(id);
      return <></>;
    };

    const getContainerClassName = () => {
      let className = "";

      if (isAnchoredChainedFromInput && !data.nodeProperties.anchorChainOutputVisibility) {
        className += " anchored-chained-from-input-container";
      }

      if (isAnchoredFromOutput) {
        className += " anchored-from-output-container";
      }

      return className;
    };

    const getUnconnectedAnchors = () => {
      const unconnectedAnchors: AnchorProperties[] = data.nodeProperties.anchoredNodes?.filter(
        (anchorProperties: AnchorProperties) => {
          const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(
            reactFlowInstance.getNode(anchorProperties.nodeId)?.type!
          );
          return nodeDefinition.unconnectedAnchorLocation === AnchorArea.Output;
        }
      );

      return unconnectedAnchors?.map((anchorProperties: AnchorProperties, index) => {
        const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId);
        return (
          <div
            key={"unconnected-output-anchor-" + index}
            style={{ width: anchoredNode?.width ?? 0, height: (anchoredNode?.height ?? 0) + 10 }}
          />
        );
      });
    };

    return (
      <div style={{ textAlign: "right" }} className={getContainerClassName()}>
        {successOutputs.map((output: any) => {
          const outputAnchorProperties: AnchorProperties = getAnchorProperties(output.name, false)!;
          const getOutputClassName = () => {
            let className = "base-output-container";

            if (outputAnchorProperties) {
              className += " anchored" + (outputAnchorProperties.isArray ? "-list" : "") + "-output";
            }

            if (output.isArray) {
              className += " base-array-output-container";
            }

            return className;
          };
          return (
            <div
              key={output.name + "-output-container"}
              style={{ position: "relative" }}
              data-testid={id + "-" + output.name + "-" + output.type + "-output"}
            >
              <Handle
                id={output.name + "-" + output.type + "-output"}
                type="target"
                position={Position.Right}
                className={"base-node-handle " + output.type + "-handle-type"}
                style={{ left: "auto", top: "9px" }}
                title={output.type}
              />
              {output.isArray ? <div className="output-list-handle-icon" /> : <></>}
              {output.hideLabel ? (
                <br />
              ) : (
                <div key={output.name + "label"} className={getOutputClassName()} title={output.readableName}>
                  {output.readableName}
                </div>
              )}
              {outputAnchorProperties ? renderAnchorContainer(outputAnchorProperties, output.isArray) : <></>}
            </div>
          );
        })}
        {getUnconnectedAnchors()}
        {getErrorControlFlowHandles({ hasSuccessOutput: successOutputs.length > 0, errorOutputs: errorOutputs })}
        {updateHandles()}
      </div>
    );
  };

  const getControlFlowHandles = () => {
    const nodeType = data.nodeProperties.type;
    const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(nodeType);
    const getClassName = (handleId: string, input: boolean) => {
      let isConnected =
        reactFlowInstance
          .getEdges()
          .filter(
            (edge) =>
              (edge.source === data.id && edge.sourceHandle === handleId) ||
              (edge.target === data.id && edge.targetHandle === handleId)
          ).length > 0;
      let className: string;
      if (!isConnected) {
        className = `base-flow-node-handle required-base-control-flow-${input ? "input" : "output"}`;
      } else {
        className = "base-flow-node-handle ";
      }

      const isErrorControl =
        data.nodeProperties.stopType?.length > 0 && data.nodeProperties.stopType[0].value === "Failure";
      if (isErrorControl) {
        className += " base-error-control-handle";
      }

      return className;
    };

    const isErrorControl =
      data.nodeProperties.stopType?.length > 0 && data.nodeProperties.stopType[0].value === "Failure";
    return (
      <>
        {nodeDefinition.controlInputs.length === 0 ? (
          <></>
        ) : (
          <Handle
            key="FlowInHandle"
            id={`Flow${isErrorControl ? "Error" : ""}InHandle${nodeDefinition.controlInputs[0].name}`}
            type="source"
            position={Position.Left}
            className={getClassName(
              `Flow${isErrorControl ? "Error" : ""}InHandle${nodeDefinition.controlInputs[0].name}`,
              true
            )}
            style={{ right: "auto", top: "21px" }}
          />
        )}
        {nodeData.nodeProperties.controlOutputs.map((o: IControlFlowParam, index: number) => {
          return (
            <div key={"FlowOut" + o.name + "HandleContainer"}>
              <Handle
                key={"FlowOut" + o.name + "Handle"}
                id={"FlowOutHandle" + o.name}
                type="target"
                position={Position.Right}
                className={getClassName("FlowOutHandle" + o.name, false)}
                style={{ left: "-auto", top: 21 * (index + 1) + "px" }}
              />
              {nodeData.nodeProperties.controlOutputs.length > 1 ? (
                <div
                  key={o.name + "FlowLabel"}
                  style={{
                    textAlign: "right",
                    position: "absolute",
                    left: "-auto",
                    right: "12px",
                    top: 21 * (index + 1) - 11 + "px"
                  }}
                >
                  {o.label}
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </>
    );

    return <></>;
  };

  const getErrorControlFlowHandles = (props: any) => {
    const { hasSuccessOutput, errorOutputs } = props;
    if (nodeData.nodeProperties?.errorTypes?.length > 0) {
      return nodeData.nodeProperties.errorTypes.map((x: PlexOpenApiError, index: any) => {
        const dataHandleOutput = errorOutputs.filter((output: any) => output.name === x.errorResponse);

        let topPositionPerc = hasSuccessOutput ? 65 : 50;
        if (index > 0) {
          topPositionPerc = topPositionPerc + 50 * index;
        }

        const handleKey = `FlowErrorHandle_${x.errorType}`;

        return (
          <div key={"error_type_" + x.errorType + "_container"}>
            <ErrorControlHandle
              key={index + "_" + id + "_ErrorHandle"}
              text={x.errorType}
              handleKey={handleKey}
              topPosition={topPositionPerc}
              dataHandleOutput={dataHandleOutput}
              index={index + 1}
            />
            {props.errorOutputs
              .filter((o: any) => o.channel === x.errorType)
              .map((output: any) => (
                <div key={output.name + "error-output-container"} style={{ position: "relative" }}>
                  <Handle
                    id={output.name + "-" + output.type + "-output"}
                    type="target"
                    position={Position.Right}
                    className={"base-node-handle " + output.type + "-handle-type"}
                    style={{ left: "auto", top: "9px" }}
                  />
                  {output.isArray ? <div className="output-list-handle-icon" /> : <></>}
                  {output.hideLabel ? (
                    <br />
                  ) : (
                    <div
                      key={output.name + "label"}
                      className="plex-fd-output-container"
                      title={output.readableName}
                      style={output.isArray ? { marginRight: "8px", position: "absolute" } : { position: "absolute" }}
                    >
                      {output.readableName}
                    </div>
                  )}
                </div>
              ))}
          </div>
        );
      });
    }

    return <></>;
  };

  const getReadableApiOutputs = (nodeData: any) => {
    let outputs: any[] = [];
    Object.keys(nodeData.nodeProperties.outputs).forEach((outputName: string) => {
      let output = nodeData.nodeProperties.outputs[outputName];
      if (output.enabled === true) {
        let readableOutputName = outputName;
        if (readableOutputName) {
          readableOutputName = readableOutputName.charAt(0).toUpperCase() + readableOutputName.slice(1);
          readableOutputName = readableOutputName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
        }
        readableOutputName = readableOutputName.replaceAll("_", " ");
        if (output.label) {
          readableOutputName = output.label;
        }
        outputs.push({
          readableName: readableOutputName,
          name: outputName,
          isErrorCode: output.isErrorCode,
          channel: output.channel,
          isArray: output.type?.includes("List") ?? false,
          type: output.type,
          wireUp: null,
          hideLabel: output.hideLabel
        });
      }
    });

    return outputs;
  };

  const hasInputsAndOutputs =
    Object.keys(nodeData.nodeProperties.inputs).some((i) => nodeData.nodeProperties.inputs[i].enabled) &&
    Object.keys(nodeData.nodeProperties.outputs).some((o) => nodeData.nodeProperties.outputs[o].enabled);

  return (
    <>
      {getControlFlowHandles()}
      <table className="input-output-table">
        <tbody>
          <tr>
            <td ref={outputContainerRef} key="outputTd" style={{ verticalAlign: "top" }}>
              {getOutputBubbles()}
              <div
                className={
                  !nodeData.nodeProperties.anchoredToNodeId && hasInputsAndOutputs
                    ? "base-node-input-output-spacing"
                    : "base-node-output-bottom-spacing"
                }
              />
            </td>
          </tr>
          <tr>
            <td ref={inputContainerRef} key="inputTd" style={{ verticalAlign: "bottom" }}>
              {getInputBubbles()}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
