import React, { FunctionComponent } from "react";
import { getSchema } from "../DataSchemas";
import { IDocumentListSchema, IDocumentObjectSchema } from "../../FlowDocument/FlowDocumentModel";

export const SortLabel: FunctionComponent<any> = (props) => {
  const { data } = props;

  const getSortOrderDescription = () => {
    const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(data.nodeProperties.type);
    const input = data.nodeProperties.inputs[nodeDefinition?.dataInputs[0]?.name ?? ""];
    let fieldLabel: string = data.nodeProperties.orderBy?.replaceAll("_", " ") ?? "";
    if (input?.schemaId) {
      const listSchema = getSchema(input.schemaId);
      if ((listSchema as unknown as IDocumentListSchema).listItemSchema) {
        const objectSchema = getSchema((listSchema as unknown as IDocumentListSchema).listItemSchema);
        if ((objectSchema as unknown as IDocumentObjectSchema).properties) {
          const schemaProperty = (objectSchema as unknown as IDocumentObjectSchema).properties[
            data.nodeProperties.orderBy
          ];
          if (schemaProperty?.label) {
            fieldLabel = schemaProperty.label;
          }
        }
      }
    }
    return data.nodeProperties.orderBy ? `${fieldLabel}` : "";
  };

  return <div className="plex-fd-expression-label">{getSortOrderDescription()}</div>;
};
