import React, { FunctionComponent } from "react";
import { Form } from "@plex/react-components";
import { IFlowMetaData } from "./FlowDocumentModel";
import "./DocumentPropertiesForm.scss";

export interface IDocumentPropertiesForm {
  document: IFlowMetaData;
  setDocumentPropertiesState: React.Dispatch<React.SetStateAction<IFlowMetaData>>;
}

export const DocumentPropertiesForm: FunctionComponent<IDocumentPropertiesForm> = (props) => {
  return (
    <Form className="flow-properties-form">
      <Form.Section title="Detail">
        <Form.Row>
          <Form.FieldPair labelText="Name" className="center-align">
            <div>{props.document.name || ""}</div>
          </Form.FieldPair>
        </Form.Row>
        <Form.Row>
          <Form.FieldPair labelText="Summary" className="center-align">
            <div>{props.document.summary || ""}</div>
          </Form.FieldPair>
        </Form.Row>
      </Form.Section>
    </Form>
  );
};
