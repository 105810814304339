import React, { FC } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { useFixedColumnSizes } from "./DataTableFixedLayout.hooks";
import styles from "../DataTable.module.scss";

const RESIZE_HANDLE_SIZE = 6;

export type ColumnResizeArgs = { index: number; diffX: number };
export type ColumnResizeHandler = (event: ColumnResizeArgs) => void;

interface IColumnResizeHandleProps {
  position: number;
  onMove: (diffX: number) => void;
}

const ColumnResizeHandle: FC<IColumnResizeHandleProps> = ({ position, onMove }) => {
  const handleRef = React.useRef<HTMLDivElement>(null);
  const x = position - RESIZE_HANDLE_SIZE / 2;

  const dragStopHandler: DraggableEventHandler = (_e, data) => {
    const diffX = data.x - x;
    if (diffX !== 0) {
      onMove(diffX);
    }
  };

  return (
    <Draggable
      axis="x"
      bounds={`.${styles.gridContainer}`}
      defaultClassNameDragging={styles.colResizing}
      position={{ x, y: 0 }}
      onStop={dragStopHandler}
      nodeRef={handleRef}
    >
      <div className={styles.colResizeHandle} style={{ width: RESIZE_HANDLE_SIZE }} ref={handleRef}>
        <div className={styles.colResizeIndicator} />
      </div>
    </Draggable>
  );
};

interface IColumnResizeHandlesPlaceholderProps {
  onColumnResize: ColumnResizeHandler;
}

export const ColumnResizeHandlesPlaceholder: FC<IColumnResizeHandlesPlaceholderProps> = ({ onColumnResize }) => {
  const columnSizes = useFixedColumnSizes();
  let position = 0;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnSizes.map((width, index) => {
        position += width;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ColumnResizeHandle key={index} position={position} onMove={diffX => onColumnResize({ index, diffX })} />
        );
      })}
    </>
  );
};
