import { LocalDate, TemporalAdjusters, DayOfWeek, nativeJs } from "js-joda";
import { MIN_SQL_DATETIME, MAX_SQL_DATETIME } from "@plex/culture-core";
import { CalendarInterval } from "./CalendarInterval";
import { getFirstDayOfWeek } from "./date-utils";
import { IIntervalOptions, RangeFilterFactory, RangeFilterFactoryGroup } from "./DatePicker.types";

const LastNDays = (days: number, messageId: string): RangeFilterFactory => {
  return {
    messageId,
    create: (from: LocalDate) => {
      const rangeStart = from.minusDays(days - 1);
      return CalendarInterval.of(rangeStart, from);
    }
  };
};

// days
export const CurrentDay: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-today",
  create: (from: LocalDate) => CalendarInterval.of(from, from)
};

export const PreviousDay: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-yesterday",
  create: (from: LocalDate) => {
    const yesterday = from.minusDays(1);
    return CalendarInterval.of(yesterday, yesterday);
  }
};

// weeks
export const CurrentWeek: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-currentWeek",
  create: (from: LocalDate, { locale, weekStart }: IIntervalOptions) => {
    const start = getFirstDayOfWeek(from, locale!, weekStart);
    const end = start.plusDays(6);
    return CalendarInterval.of(start, end);
  }
};

export const NextWeek: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-nextWeek",
  create: (from: LocalDate, { locale, weekStart }: IIntervalOptions) => {
    const start = getFirstDayOfWeek(from, locale!, weekStart).plusDays(7);
    const end = start.plusDays(6);
    return CalendarInterval.of(start, end);
  }
};

export const PreviousWeek: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-previousWeek",
  create: (from: LocalDate, { locale, weekStart }: IIntervalOptions) => {
    const start = getFirstDayOfWeek(from, locale!, weekStart).minusDays(7);
    const end = start.plusDays(6);
    return CalendarInterval.of(start, end);
  }
};

export const PreviousWeekMonday: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-previous2Weeks",
  create: (from: LocalDate) => {
    const monday = from.with(TemporalAdjusters.previous(DayOfWeek.MONDAY));
    const weekEnd = monday.plusDays(6);
    return CalendarInterval.of(monday, weekEnd);
  }
};

// months
export const CurrentMonth: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-currentMonth",
  create: (from: LocalDate) => {
    const monthStart = from.with(TemporalAdjusters.firstDayOfMonth());
    const monthEnd = from.with(TemporalAdjusters.lastDayOfMonth());
    return CalendarInterval.of(monthStart, monthEnd);
  }
};

export const PreviousMonth: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-previousMonth",
  create: (from: LocalDate) => {
    const monthEnd = from.with(TemporalAdjusters.firstDayOfMonth()).minusDays(1);
    const monthStart = monthEnd.with(TemporalAdjusters.firstDayOfMonth());
    return CalendarInterval.of(monthStart, monthEnd);
  }
};

export const MonthToDate: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-monthToDate",
  create: (from: LocalDate) => {
    const monthStart = from.with(TemporalAdjusters.firstDayOfMonth());
    return CalendarInterval.of(monthStart, from);
  }
};

const NextNDays = (days: number, messageId: string): RangeFilterFactory => {
  return {
    messageId,
    create: (from: LocalDate) => {
      const rangeEnd = from.plusDays(days - 1);
      return CalendarInterval.of(from, rangeEnd);
    }
  };
};

export const Next30Days = NextNDays(30, "ui-common-datePicker-option-next30Days");

export const Last7Days = LastNDays(7, "ui-common-datePicker-option-last7Days");

export const Last30Days = LastNDays(30, "ui-common-datePicker-option-last30Days");

export const Last60Days = LastNDays(60, "ui-common-datePicker-option-last60Days");

export const Last90Days = LastNDays(90, "ui-common-datePicker-option-last90Days");

export const Last365Days = LastNDays(365, "ui-common-datePicker-option-last365Days");

export const YearToDate: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-yearToDate",
  create: (from: LocalDate) => {
    const yearStart = from.with(TemporalAdjusters.firstDayOfYear());
    return CalendarInterval.of(yearStart, from);
  }
};

export const CurrentYear: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-currentYear",
  create: (from: LocalDate) => {
    const yearStart = from.with(TemporalAdjusters.firstDayOfYear());
    const yearEnd = from.with(TemporalAdjusters.lastDayOfYear());
    return CalendarInterval.of(yearStart, yearEnd);
  }
};

export const PreviousYear: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-previousYear",
  create: (from: LocalDate) => {
    const yearEnd = from.with(TemporalAdjusters.firstDayOfYear()).minusDays(1);
    const yearStart = yearEnd.with(TemporalAdjusters.firstDayOfYear());
    return CalendarInterval.of(yearStart, yearEnd);
  }
};

export const OPEN_START_DATE = LocalDate.from(nativeJs(MIN_SQL_DATETIME));
export const OPEN_END_DATE = LocalDate.from(nativeJs(MAX_SQL_DATETIME));

export const OpenStartDate: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-openStart",
  create: (from: LocalDate) => CalendarInterval.of(OPEN_START_DATE, from)
};

export const OpenEndDate: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-openEnd",
  create: (from: LocalDate) => CalendarInterval.of(from, OPEN_END_DATE)
};

export const EmptyRange: RangeFilterFactory = {
  messageId: "ui-common-datePicker-option-customRange",
  create: () => null
};

export const withOpenEndedDates = (groups: RangeFilterFactoryGroup[]): RangeFilterFactoryGroup[] => {
  return [
    {
      messageId: "ui-common-datePicker-heading-open",
      intervals: [OpenStartDate, OpenEndDate]
    },
    ...groups
  ];
};

export const StandardRangeFilters: () => RangeFilterFactoryGroup[] = () => [
  {
    messageId: "ui-common-datePicker-heading-days",
    intervals: [EmptyRange, CurrentDay, PreviousDay]
  },
  {
    messageId: "ui-common-datePicker-heading-weeks",
    intervals: [CurrentWeek, NextWeek, PreviousWeek, PreviousWeekMonday, Last7Days]
  },
  {
    messageId: "ui-common-datePicker-heading-months",
    intervals: [MonthToDate, CurrentMonth, PreviousMonth, Next30Days, Last30Days, Last60Days, Last90Days]
  },
  {
    messageId: "ui-common-datePicker-heading-years",
    intervals: [YearToDate, CurrentYear, PreviousYear, Last365Days]
  }
];
