import React, { FC } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./Overlay.module.scss";

interface ISpinnerProps extends ICommonProps {
  /** Spinner Type */
  type?: "gear" | "spin" | "darkspin";
}

export const Spinner: FC<ISpinnerProps> = ({ type, className, ...other }) => {
  let spinnerClass = styles.darkSpinnerIcon;
  switch (type) {
    case "gear":
      spinnerClass = styles.spinnerGears;
      break;
    case "spin":
      spinnerClass = styles.spinnerIcon;
      break;
    default:
      spinnerClass = styles.darkSpinnerIcon;
      break;
  }

  return <div className={clsx(spinnerClass, className)} {...other} />;
};
