import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DateRangePeriod, FirstDayOfWeek } from "../../FlowDocument/FlowDocumentModel";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

const beginOutputName = "begin";
const endOutputName = "end";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "DateRangeNode",
  category: NodeTypeCategory.dates,
  label: "Date Range",
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [
    { name: beginOutputName, type: DataType.DATETIME },
    { name: endOutputName, type: DataType.DATETIME }
  ],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [DataType.DATETIME, DataType.DATE],
        dataProperties: [beginOutputName, endOutputName]
      }
    },
    {
      name: "period",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: DateRangePeriod }
    },
    {
      name: "firstDayOfWeek",
      label: "First Day of Week",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: FirstDayOfWeek }
    }
  ],
  evaluate: (input: any, nodeProperties: any) => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const DateRangeNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const label = getOptionLabel(data.nodeProperties.period ?? DateRangePeriod.Today);

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
