import React, { forwardRef } from "react";
import { IDateFormatOptions } from "@plex/culture-core";
import { Temporal } from "js-joda";
import { ICommonInputProps } from "../Input";
import { ITimePickerProps, DateChangeHandler } from "./DatePicker.types";
import { DateTimeInput } from "./DateTimeInput";

export interface ITimeInputProps extends ITimePickerProps, ICommonInputProps {
  /** The formatting options for the selected date */
  formatOptions?: IDateFormatOptions | string;
}

/**
 * This component renders an input with a calendar button that a user can use to
 * enter or select individual time values.
 */
export const TimeInput = forwardRef<HTMLInputElement, ITimeInputProps>(({ value, onValueChange, ...others }, ref) => {
  return (
    <DateTimeInput
      showDate={false}
      ref={ref}
      value={value as Temporal}
      onValueChange={onValueChange as DateChangeHandler<Temporal>}
      {...others}
    />
  );
});

TimeInput.displayName = "TimeInput";
