import { useEffect, useRef, useLayoutEffect, ReactText, FC, ReactElement, PropsWithChildren } from "react";
import { returnTrue, noop } from "@utils";
import { hasSameValues } from "@arrays-immutable";
import {
  SelectionMode,
  DataTablePluginFactory,
  IDataTableProps,
  GridAction,
  GridState,
  DataTablePluginComponentProps
} from "../DataTable.types";
import { useDataTableStore, useDataTableDispatcher } from "../DataTableStateProvider";
import {
  handleSelectionToggle,
  SelectionState,
  GridStateWithSelections,
  handleSelectionModeChange,
  updateSelectionMode,
  handleSelectionsUpdate,
  updateSelections,
  handleSelectAllToggle,
  getSelectionStatus
} from "./DataSelection.actions";
import { GridSelectionProps } from "./Plugins.types";

export const SELECTIONS_PLUGIN = "Selections";

const EMPTY_SELECTIONS: unknown[] = [];

// eslint-disable-next-line func-style
function createInitialState<T>(
  { keySelector, data: dataOrPromise }: Partial<IDataTableProps<T>>,
  { selected = EMPTY_SELECTIONS as T[], mode = SelectionMode.none }: Partial<GridSelectionProps<T>>
): SelectionState {
  if (selected === EMPTY_SELECTIONS && mode !== SelectionMode.none) {
    // eslint-disable-next-line no-console
    console.warn(
      "Selections should be controlled when the `DataTable` allows selections. Be sure to pass the selected items to the `selected` property."
    );
  }

  const selectedKeys = selected.map(keySelector as (value: T, index: number, array: T[]) => ReactText);
  const data: T[] = Array.isArray(dataOrPromise) ? dataOrPromise : [];
  return {
    mode,
    selectedKeys,
    previousKeys: selectedKeys.length === data.length ? [] : selectedKeys,
    status: getSelectionStatus(selectedKeys, data)
  };
}

// eslint-disable-next-line func-style
function DataSelectionRender<T = unknown>({
  mode = SelectionMode.none,
  onSelectionChanged = noop,
  selected,
  keySelector,
  children
}: PropsWithChildren<GridSelectionProps<T> & DataTablePluginComponentProps>) {
  const keySelectorRef = useRef(keySelector);
  const onChangedRef = useRef(onSelectionChanged);
  const { getState, subscribe } = useDataTableStore();
  const dispatch = useDataTableDispatcher();

  useLayoutEffect(() => {
    keySelectorRef.current = keySelector;
    onChangedRef.current = onSelectionChanged;
  });

  useEffect(() => {
    dispatch(updateSelectionMode({ mode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, dispatch]);

  useEffect(() => {
    const keys = (selected || []).map(keySelectorRef.current);
    const state = getState() as GridStateWithSelections;
    if (!hasSameValues(keys, state.selections.selectedKeys)) {
      dispatch(updateSelections({ selectedKeys: keys }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, getState, dispatch]);

  useEffect(() => {
    return subscribe(state => {
      const { data: all, selections } = state as GridStateWithSelections<T>;
      const { selectedKeys } = selections;
      const existingKeys = (selected || []).map(keySelectorRef.current);

      if (!hasSameValues(selectedKeys, existingKeys)) {
        const selectedRows = selectedKeys.map(key => all.find(x => x.key === key)?.value).filter(Boolean) as T[];

        onChangedRef.current(selectedRows);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, subscribe]);

  return children as ReactElement;
}

export const DataSelection = <T>(args: GridSelectionProps<T>) => {
  const { canSelectRow = returnTrue } = args;

  const factory: DataTablePluginFactory<T, GridSelectionProps<T>> = ({ keySelector, data }) => ({
    args,
    component: DataSelectionRender as FC<GridSelectionProps<T> & DataTablePluginComponentProps<T>>,

    reducer: (gridState: GridState<T>, action: GridAction<T>) => {
      const state = gridState as GridStateWithSelections<T>;
      switch (action.type) {
        case "DataTable/init":
        case "DataTable/load":
          return {
            ...state,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            selections: state.selections || createInitialState({ keySelector, data }, args)
          };
        case "DataTable/toggleSelect":
          return handleSelectionToggle(state, { ...action, canSelectRow });
        case "DataTable/toggleSelectAll":
          return handleSelectAllToggle(state, { ...action, canSelectRow });
        case "DataTable/updateSelectionMode":
          return handleSelectionModeChange(state, action);
        case "DataTable/updateSelections":
          return handleSelectionsUpdate(state, action);
        default:
          return state;
      }
    }
  });

  factory.pluginName = SELECTIONS_PLUGIN;
  return factory;
};
