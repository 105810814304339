/* eslint-disable no-invalid-this */
import React, { ReactNode, ReactElement } from "react";
import clsx from "clsx";
import { TranslatedText } from "@plex/culture-react";
import { IWithChildren, ICommonProps } from "@components/Common.types";
import { renderChildrenOfType } from "../../utils";
import styles from "./MainMenu.module.scss";
import { MainMenuGroup } from "./MainMenu.Group";
import { MainMenuItem } from "./MainMenu.Item";
import { MainMenuItemIcon } from "./MainMenu.ItemIcon";

export interface IMainMenuProps extends ICommonProps {
  /**
   * Is menu hidden
   */
  hidden?: boolean;

  /**
   * Id of selected menu group/item
   */
  selectedItemId?: string;
}

export interface IMainMenuState {
  activeNodeId: string;
  breadcrumbs: IMainMenuBreadcrumb[];
}

export interface IMainMenuInterface extends React.Component<IMainMenuProps, IMainMenuState>, IWithChildren {
  Group?: typeof MainMenuGroup;
  Item?: typeof MainMenuItem;
}

interface IMainMenuBreadcrumb {
  nodeId: string;
  active: boolean;
  text?: string;
}

export class MainMenu extends React.PureComponent<IMainMenuProps, IMainMenuState> implements IMainMenuInterface {
  public static Group = MainMenuGroup;

  public static Item = MainMenuItem;

  public static ItemIcon = MainMenuItemIcon;

  private readonly RootGroupId = "mainMenuRootGroup";

  private readonly AllowedChildrenTypes = [MainMenuGroup.displayName!, MainMenuItem.displayName!];

  public constructor(props: IMainMenuProps) {
    super(props);

    const { selectedItemId, children } = this.props;

    const buildBreadcrumbsForPreselectedItem = (id: string) => {
      const breadcrumbs: Array<{
        nodeId: string;
        nodeType: string;
        bc: IMainMenuBreadcrumb[];
      }> = [];

      const traverseNode = (node: ReactNode, path: IMainMenuBreadcrumb[]) => {
        if (!React.isValidElement(node)) {
          return;
        }

        if (node.props.hidden) {
          return;
        }

        const nodeType = ((node.type as unknown) as { [key: string]: string }).displayName;
        path.push({
          active: false,
          nodeId: node.props.id,
          text: node.props.text
        });
        breadcrumbs.push({
          bc: path,
          nodeType,
          nodeId: node.props.id
        });
        if (nodeType === MainMenuGroup.displayName && node.props.children) {
          renderChildrenOfType(node.props.children, this.AllowedChildrenTypes).forEach((c: ReactNode) => {
            traverseNode(c, path.slice());
          });
        }
      };

      renderChildrenOfType(children, this.AllowedChildrenTypes).forEach((c: ReactNode) => {
        traverseNode(c, []);
      });

      return breadcrumbs.find((b: { nodeId: string; bc: IMainMenuBreadcrumb[] }) => {
        return b.nodeId === id;
      });
    };

    let breadcrumbs: IMainMenuBreadcrumb[] = [
      {
        active: true,
        nodeId: this.RootGroupId
      }
    ];
    let activeNodeId = this.RootGroupId;

    if (selectedItemId) {
      const preSelectedBreadcrumbs = buildBreadcrumbsForPreselectedItem(selectedItemId);
      if (preSelectedBreadcrumbs) {
        if (preSelectedBreadcrumbs.nodeType === MainMenuItem.displayName) {
          breadcrumbs = breadcrumbs.concat(preSelectedBreadcrumbs.bc.slice(0, preSelectedBreadcrumbs.bc.length - 1));
        } else {
          breadcrumbs = breadcrumbs.concat(preSelectedBreadcrumbs.bc);
        }
      }
    }

    activeNodeId = breadcrumbs[breadcrumbs.length - 1].nodeId;

    breadcrumbs = breadcrumbs.map((b: IMainMenuBreadcrumb, index: number) => {
      return {
        ...b,
        active: index === breadcrumbs.length - 1
      };
    });

    this.state = { activeNodeId, breadcrumbs };
  }

  public render = () => {
    const renderActiveNode = () => {
      const findNode = (children: ReactNode, id: string, hidden?: boolean): ReactElement | null => {
        let result: ReactNode | null = null;
        const filteredChildren = renderChildrenOfType(children, this.AllowedChildrenTypes);
        for (let i = 0; i < filteredChildren.length; i++) {
          const elem = filteredChildren[i] as React.ReactElement;
          if (elem.props.id === id) {
            result = React.cloneElement(elem, { hidden: hidden || elem.props.hidden });
          } else if (elem.props.children) {
            result = findNode(elem.props.children, id, elem.props.hidden);
          }

          if (result !== null && React.isValidElement(result)) {
            return result;
          }
        }
        return null;
      };

      const nodeClickHandler = (nodeId: string, text: string) => (e: React.MouseEvent) => {
        e.preventDefault();

        const { children } = this.props;

        const node = findNode(children, nodeId);
        if (!node) {
          return;
        }

        const nodeType = (node.type as unknown) as { [key: string]: string };

        if (nodeType.displayName === MainMenuGroup.displayName) {
          let { breadcrumbs } = this.state;
          breadcrumbs = breadcrumbs.map((b: IMainMenuBreadcrumb) => {
            return { ...b, active: false };
          });
          breadcrumbs.push({ active: true, nodeId, text });
          this.setState({ activeNodeId: nodeId, breadcrumbs });
        }

        if (node.props.onClick) {
          node.props.onClick(e, node.props.onClickProps);
        }
      };

      const { activeNodeId } = this.state;
      const { children, selectedItemId } = this.props;

      let activeNode = findNode(children, activeNodeId);

      if (!activeNode || activeNode.props.hidden) {
        activeNode = null;
      }

      if (activeNodeId === this.RootGroupId || !activeNode) {
        // render root nodes
        return React.Children.toArray(children)
          .filter(React.isValidElement)
          .map((c: ReactElement) => {
            return React.cloneElement(c, {
              onClick: nodeClickHandler(c.props.id, c.props.text)
            });
          });
      }
      return (
        <>
          {React.cloneElement(activeNode, {
            expanded: true,
            onClick: nodeClickHandler(activeNode.props.id, activeNode.props.text)
          })}
          <div>
            {activeNode.props.children &&
              React.Children.toArray(activeNode.props.children).map((c: React.ReactElement) => {
                return React.cloneElement(c, {
                  selected: c.props.id === selectedItemId,
                  onClick: nodeClickHandler(c.props.id, c.props.text)
                });
              })}
          </div>
        </>
      );
    };

    const renderBreadcrumbsPanel = () => {
      const breadcrumbClickHandler = (b: IMainMenuBreadcrumb) => (e: React.MouseEvent) => {
        e.preventDefault();

        if (!b.active) {
          let { breadcrumbs } = this.state;
          breadcrumbs = breadcrumbs.slice(0, breadcrumbs.indexOf(b) + 1);
          breadcrumbs.splice(breadcrumbs.indexOf(b), 1, { ...b, active: true });
          this.setState({ activeNodeId: b.nodeId, breadcrumbs });
        }
      };

      const { breadcrumbs } = this.state;

      return (
        <div className={clsx(styles.menuBreadcrumbPanel)}>
          <ul className={clsx(styles.menuBreadcrumbContent)}>
            {breadcrumbs.map((b: IMainMenuBreadcrumb) => {
              return (
                <li className={clsx(styles.menuBreadcrumbItemContainer)} key={b.nodeId}>
                  <div className={clsx(styles.menuBreadcrumbItem)} onClick={breadcrumbClickHandler(b)}>
                    <span
                      className={clsx(
                        b.active ? styles.menuBreadcrumbItemContent : styles.menuBreadcrumbItemContentHistory
                      )}
                    >
                      {b.nodeId === this.RootGroupId ? (
                        <TranslatedText messageId="ui-common-mainMenu-title-rootGroupTitle" />
                      ) : (
                        b.text
                      )}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    const { hidden, id, ...other } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <div id={id} className={clsx(styles.menuContainer, other.className)} style={other.style}>
        <div className={clsx(styles.menuBase)}>
          <div>
            {renderBreadcrumbsPanel()}
            {renderActiveNode()}
          </div>
        </div>
      </div>
    );
  };
}
