import React, { FunctionComponent } from "react";
import { InputSize, TextInput } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface ITextInputProps extends INodeConfigPropertiesProps {
  label: string;
  characterLength?: number;
}

export const TextFormField: FunctionComponent<ITextInputProps> = (props) => {
  const [state, setState] = React.useState({ value: props.node?.data.nodeProperties[props.name] || "" });

  const handleChange = (e) => {
    let newValue = e.currentTarget.value;
    if (newValue.trim() === "" && newValue !== "") {
      return;
    }
    props.node!.data.nodeProperties[props.name] = newValue;
    setState({ value: props.node!.data.nodeProperties[props.name] });
    props.forceNodeUpdate();
  };

  return (
    <TextInput
      key={`${props.sectionName} ${props.label} Input`}
      value={state.value}
      size={InputSize.large}
      maxLength={props.characterLength}
      onChange={handleChange}
    />
  );
};
