import React from "react";
import { TabPanel as TabPanelItem } from "react-tabs";
import clsx from "clsx";
import styles from "./Tabs.module.scss";
import { ITabsElement, TabsRole, ITabPanelProps } from "./Tabs.types";

/** Component which renders data content for a tab. */
const TabPanel: ITabsElement = ({ keepMounted = false, children, className, ...other }: ITabPanelProps) => {
  const tabPanelClassName = clsx(styles.tabPanel, className);
  return (
    <TabPanelItem
      data-testid="plex-tab-panel-item"
      forceRender={keepMounted}
      selectedClassName={styles.tabPanelSelected}
      className={tabPanelClassName}
      {...other}
    >
      {children}
    </TabPanelItem>
  );
};

TabPanel.displayName = TabsRole.TabPanel;
TabPanel.tabsRole = TabsRole.TabPanel;

export { TabPanel };
