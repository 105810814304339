import React, { FunctionComponent, createElement } from "react";
import clsx from "clsx";
import { Eye4Icon, Eye5Icon } from "@plex/icons";
import { withPrinting } from "@plex/react-xml-renderer";
import { useToggle } from "../../hooks";
import { TextInput } from "./TextInput";
import { IPasswordInputProps } from "./Input.types";
import styles from "./Input.module.scss";

export const HtmlPasswordInput: FunctionComponent<IPasswordInputProps> = ({
  className,
  disabled,
  allowVisibilityToggle,
  ...other
}) => {
  const [hidden, toggleHidden] = useToggle(true);
  const inputClasses = clsx(className, styles.passwordInput, allowVisibilityToggle && styles.passwordInputWithToggle);
  const toggleClasses = clsx(styles.passwordToggle, disabled && styles.passwordToggleDisabled);

  return (
    <div className={styles.password}>
      <TextInput className={inputClasses} type={hidden ? "password" : "text"} disabled={disabled} {...other} />
      {allowVisibilityToggle && (
        <i className={toggleClasses} onClick={toggleHidden}>
          {hidden ? <Eye4Icon /> : <Eye5Icon />}
        </i>
      )}
    </div>
  );
};

const XmlPasswordInput: FunctionComponent<IPasswordInputProps> = () => {
  return createElement("plex-control-input", {});
};

export const PasswordInput: FunctionComponent<IPasswordInputProps> = withPrinting(HtmlPasswordInput, XmlPasswordInput);
