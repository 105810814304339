/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/promise-function-async */
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { YearMonth, Year } from "js-joda";
import { MonthValue } from "@plex/culture-core";
import { DateLiteral } from "@plex/culture-react";
import { PREV_SYMBOL, NEXT_SYMBOL } from "./DatePicker.types";
import styles from "./DatePicker.module.scss";

export interface IMonthViewProps {
  monthValue: MonthValue;
  year: number;
  onMonthChange: (month: YearMonth) => void;
  onHeaderClick?: () => void;
}

export const MonthView: FunctionComponent<IMonthViewProps> = ({ monthValue, year, onMonthChange, onHeaderClick }) => {
  const [currentYear, setYear] = useState(() => Year.of(year));
  const selectedMonth = useMemo<YearMonth>(() => YearMonth.of(year, monthValue), [year, monthValue]);

  const prevYear = useCallback(() => setYear(y => (y as any).minusYears(1)), []);
  const nextYear = useCallback(() => setYear(y => (y as any).plusYears(1)), []);

  const monthValues = useMemo<YearMonth[]>(() => {
    const values = [];
    for (let i = 1; i < 13; i++) {
      values.push(YearMonth.of(currentYear.value(), i));
    }
    return values;
  }, [currentYear]);

  return (
    <div className={styles.gridContainer} data-testid="plex-datetimepicker-container-month-view">
      <table className={styles.grid}>
        <thead>
          <tr>
            <th className={styles.headerButton} onClick={prevYear} data-testid="plex-datetimepicker-header-button-prev">
              {PREV_SYMBOL}
            </th>
            <th
              className={styles.headerButton}
              colSpan={5}
              onClick={onHeaderClick}
              data-testid="plex-datetimepicker-header-button-title"
            >
              <DateLiteral value={currentYear} options="yyyy" />
            </th>
            <th className={styles.headerButton} onClick={nextYear} data-testid="plex-datetimepicker-header-button-next">
              {NEXT_SYMBOL}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={7} style={{ padding: 0 }} data-testid="plex-datetimepicker-month">
              {monthValues.map(m => (
                <span
                  key={m.toString()}
                  className={clsx(styles.largeSelect, selectedMonth.equals(m) && styles.active)}
                  onClick={() => onMonthChange(m)}
                >
                  <DateLiteral value={m} options="MMM" />
                </span>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
