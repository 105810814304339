import React, { FunctionComponent } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { EdgeDetail } from "../../Util/EdgeUtil";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "NumberModulusNode",
  category: NodeTypeCategory.numbers,
  isExperimental: true,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.INTEGER, hideLabel: true },
    { name: "right", type: DataType.INTEGER, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.INTEGER }],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return {
      output: { result: (input.left as number) % (input.right as number) },
      activeControlHandleIds: []
    };
  }
});

export const NumberModulusNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const reactFlowInstance = useReactFlow();
  const edgeDetail = new EdgeDetail(id, reactFlowInstance);

  const labelLeft = edgeDetail.getInputTargetLabel("left");
  const labelRight = edgeDetail.getInputTargetLabel("right");
  const label = `${labelLeft} % ${labelRight}`;

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
