import React, { FunctionComponent, FormEvent, FormEventHandler, createElement } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./Radio.module.scss";

export interface IRadioProps extends ICommonProps {
  /**
   * The name attribute
   */
  name?: string;
  /**
   * Sets the radio button as disabled
   * Specifically, disabled inputs do not receive the click event, and disabled inputs are not submitted with the form.
   * @default false
   */
  disabled?: boolean;
  /**
   * Sets the radio button as not editable
   * @default false
   */
  readOnly?: boolean;
  /**
   * Indicates whether or not the radio button is checked
   */
  checked?: boolean;
  /**
   * The string to use as the value of the radio button when submitting the form, if the radio button is currently toggled on
   */
  value?: string;
  /**
   * Event triggered when the value changes
   */
  onChange?: FormEventHandler<HTMLInputElement>;
  /**
   * Event triggered when the value changes
   */
  onSelected?: (value?: unknown) => void;
  /**
   * Indicates whether or not the radio button is checked by default
   */
  defaultChecked?: boolean;
}

/** Renders a standard radio button */
const HtmlRadio: FunctionComponent<IRadioProps> = React.forwardRef<HTMLInputElement, IRadioProps>(
  ({ className, onChange, onSelected, readOnly, disabled, checked, value, ...other }, ref) => {
    const classes = clsx(styles.base, className, readOnly && styles.readonly);
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
      onChange?.(e);
      if (onSelected) {
        let currentValue: unknown = e.currentTarget.checked;
        if (value !== undefined) {
          currentValue = value;
        }

        onSelected(currentValue);
      }
    };

    return (
      <input
        type="radio"
        checked={checked}
        className={classes}
        readOnly={readOnly}
        disabled={disabled}
        ref={ref}
        onChange={handleChange}
        {...other}
      />
    );
  }
);

HtmlRadio.displayName = "Radio";

const XmlRadio: FunctionComponent<IRadioProps> = ({ checked, defaultChecked }) => {
  const checkedNode = React.createElement("checked", {}, String((checked || defaultChecked) ?? "false"));
  return createElement("plex-control-radiobutton", {}, checkedNode);
};

export const Radio = withPrinting(HtmlRadio, XmlRadio);
