import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { IWithChildren, ICommonProps } from "@components/Common.types";
import styles from "./ListView.module.scss";

export interface IListViewItemProps extends IWithChildren, ICommonProps {
  /** Handler for click events */
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  /**
   * When true adds a mouseover effect to show that the item is selectable.
   * @default false;
   */
  selectable?: boolean;
  /**
   * When true the style of the item is adjusted to indicate it is selected.
   * @default false;
   */
  selected?: boolean;
}

export const ListViewItem: FunctionComponent<IListViewItemProps> = ({
  selectable = false,
  selected = false,
  className,
  children,
  ...other
}) => {
  const listviewItemClassName = clsx(
    styles.listviewItem,
    selectable && styles.listviewItemSelectable,
    selected && styles.listviewItemSelected,
    className
  );
  return (
    <li className={listviewItemClassName} {...other}>
      {children}
    </li>
  );
};
