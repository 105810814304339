export enum NodeConfigPropertyType {
  Text,
  Boolean,
  Integer,
  Decimal,
  EnabledInputs,
  EnabledOutputs,
  ViewDataSourceDetails,
  Enum,
  DataSource,
  StandardObject,
  DataOutputs,
  DataInputs,
  DataPropertyCount,
  DataType,
  DataValue,
  Position,
  NoInput
}

export const writesDocument = (type: NodeConfigPropertyType) => {
  if (type === NodeConfigPropertyType.EnabledInputs || type === NodeConfigPropertyType.EnabledOutputs) {
    return false;
  }

  return true;
};
