import React, { ReactElement, FunctionComponent } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./Form.module.scss";

const HtmlFormHeader: FunctionComponent<ICommonProps> = ({ children, className, ...other }) => {
  return (
    <div className={clsx(styles.formHeader, className)} data-testid="plex-form-header" {...other}>
      {children}
    </div>
  );
};

const XmlFormHeader: FunctionComponent<ICommonProps> = ({ children }) => {
  return children as ReactElement;
};
export const FormHeader = withPrinting(HtmlFormHeader, XmlFormHeader);
