import React, { FunctionComponent } from "react";
import { ArrowUp2Icon, ArrowDown2Icon } from "@plex/icons";
import styles from "./DataTable.module.scss";
import { ColumnSortState } from "./DataTable.types";

/** Component which renders the sort direction and order. */
export const SortIndicator: FunctionComponent<ColumnSortState> = ({ dir, order }) => {
  if (!dir) {
    return null;
  }

  return (
    <div className={styles.sortWrapper}>
      <i className={styles.sortIcon}>{dir === "asc" ? <ArrowUp2Icon key="up" /> : <ArrowDown2Icon key="down" />}</i>
      {order > 0 && <sub className={styles.sortOrder}>{order}</sub>}
    </div>
  );
};
