import React, { FunctionComponent } from "react";
import { useReactFlow } from "reactflow";
import { EdgeDetail } from "../../Util/EdgeUtil";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

export interface IComparisonLabelProps {
  nodeId: string;
  comparisonOperator: string;
}

export const ComparisonLabel: FunctionComponent<IComparisonLabelProps> = ({ nodeId, comparisonOperator }) => {
  const reactFlowInstance = useReactFlow();
  const edgeDetail = new EdgeDetail(nodeId, reactFlowInstance);

  const labelA = edgeDetail.getInputTargetLabel("left");
  const labelB = edgeDetail.getInputTargetLabel("right");
  const label = `${labelA} ${getOptionLabel(comparisonOperator)} ${labelB}`;

  return <div className="plex-fd-expression-label">{label}</div>;
};
