import React, { FC } from "react";
import { ConstantLabel } from "./ConstantLabel";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConstantDecimalNode",
  category: NodeTypeCategory.text,
  isExperimental: true,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: "result", type: DataType.DECIMAL, enabled: true, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "literal",
      label: "Value",
      propertyType: NodeConfigPropertyType.DataValue
    }
  ],
  size: NodeSize.Small,
  evaluate: (input, nodeProperties) => {
    return { output: { result: nodeProperties.literal }, activeControlHandleIds: [] };
  }
});

export const ConstantDecimalNode: FC<NodeProps> = ({ id, data }) => {
  return (
    <BasicNodeLayout id={id} data={data} className="single-type-constant-node">
      <ConstantLabel data={data} />
    </BasicNodeLayout>
  );
};
