import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { TimeUnit } from "../../FlowDocument/FlowDocumentModel";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "DateOffsetNode",
  category: NodeTypeCategory.dates,
  label: "Date Add",
  controlOutputs: [],
  dataInputs: [
    { name: "date", type: DataType.DATETIME },
    { name: "offset", type: DataType.DECIMAL }
  ],
  dataOutputs: [{ name: "result", type: DataType.DATETIME }],
  nodeConfigProperties: [
    {
      name: "unit",
      label: "Offset Type",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: TimeUnit }
    }
  ],
  evaluate: (input: any, nodeProperties: any) => {
    function addYears(date: Date, years: number): Date {
      const resultDate = new Date(date);

      resultDate.setFullYear(resultDate.getFullYear() + years);

      // Check if the resultDate is a leap year
      const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      };

      // if the initial date is a leap year
      if (isLeapYear(date.getFullYear())) {
        // If it's a leap year, set the date to February 28
        resultDate.setMonth(1); // February
        resultDate.setDate(28);
      }

      return resultDate;
    }

    function addMonths(date: Date, months: number): Date {
      var currentMonth = date.getMonth() + date.getFullYear() * 12;
      date.setMonth(date.getMonth() + months);
      var diff = date.getMonth() + date.getFullYear() * 12 - currentMonth;

      // If don't get the right number, set date to last day of previous month
      if (diff != months) {
        date.setDate(0);
      }
      return date;
    }

    function addTime(date: Date, time: number, unit: string) {
      const newDate = new Date(date);
      let millisecondsInUnit: number = 0;

      switch (unit) {
        case TimeUnit.Minutes:
          millisecondsInUnit = 60 * 1000;
          break;
        case TimeUnit.Hours:
          millisecondsInUnit = 60 * 60 * 1000;
          break;
        case TimeUnit.Days:
          millisecondsInUnit = 24 * 60 * 60 * 1000;
          break;
        case TimeUnit.Weeks:
          millisecondsInUnit = 7 * 24 * 60 * 60 * 1000;
          break;
      }

      const millisecondsToAdd = time * millisecondsInUnit;
      newDate.setTime(newDate.getTime() + millisecondsToAdd);
      return newDate;
    }

    const dateString = (input.date as string).toString(); // returns a date string
    const resultingDate = new Date(dateString); // getting the date after offset

    const offsetAmount = input.offset as number;
    const timeUnitToOffsetBy: string = nodeProperties.unit ? nodeProperties.unit[0].value : "MINUTES";

    // Store DateTime in ISO format with UTC Time
    let dateAfterOffset = new Date();

    switch (timeUnitToOffsetBy) {
      case TimeUnit.Minutes:
      case TimeUnit.Hours:
      case TimeUnit.Days:
      case TimeUnit.Weeks:
        dateAfterOffset = addTime(resultingDate, offsetAmount, timeUnitToOffsetBy);
        break;
      case TimeUnit.Months:
        dateAfterOffset = addMonths(resultingDate, offsetAmount);
        break;
      case TimeUnit.Years:
        dateAfterOffset = addYears(resultingDate, offsetAmount);
        break;
    }

    // Converting ISO UTC time to Local ISOO time
    const updatedLocalDateTime = new Date(dateAfterOffset);
    updatedLocalDateTime.setTime(updatedLocalDateTime.getTime() - updatedLocalDateTime.getTimezoneOffset() * 60 * 1000);

    // Convert the updated Date object back to a string in the desired format
    const updatedDateString = updatedLocalDateTime.toISOString().slice(0, 23); // Format: YYYY-MM-DDTHH:MM:SS.SSS

    return {
      output: { OffsetResult: updatedDateString },
      activeControlHandleIds: []
    };
  }
});

export const DateOffsetNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const label = getOptionLabel(data.nodeProperties.unit ?? TimeUnit.Minutes);

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
