/* eslint-disable complexity */
/* eslint-disable func-style */
import React, { PropsWithChildren, useEffect } from "react";
import { getScrollableContainer, debounceRender } from "@utils";
import {
  DataTablePluginFactory,
  GridState,
  GridAction,
  VirtualizedWindow,
  DataTablePluginComponentProps
} from "../DataTable.types";
import { useDataTableDispatcher, useDataTableSelector } from "../DataTableStateProvider";
import { DataVirtualizationOptions, WindowedGridState } from "./Plugins.types";

export const VIRTUALIZATION_PLUGIN = "Virtualization";

const SCROLL_DEBOUNCE_MS = 150;
const DEFAULT_WINDOW_SIZE = 200;
const OVER_SCAN = 10;
const MOVING_UP = "UP";
const MOVING_DOWN = "DOWN";

// eslint-disable-next-line func-style
function calculateRange(start: number, end: number, displayCount: number, dataLength: number): VirtualizedWindow {
  const topHeight = 0;
  const bottomHeight = 0;
  const virtualizedRange = { start, end, topHeight, bottomHeight };
  // Check if start or end of range is less than 0
  if (virtualizedRange.start < 0 || virtualizedRange.end < 0) {
    virtualizedRange.start = 0;
    virtualizedRange.end = displayCount;
  }
  // Check if start or end of range is greater than data length
  if (virtualizedRange.start > dataLength || virtualizedRange.end > dataLength) {
    virtualizedRange.start = dataLength < displayCount ? start : dataLength - displayCount;
    virtualizedRange.end = dataLength;
  }
  // Check if difference between start and end of range does not equal visible row count
  if (virtualizedRange.start - virtualizedRange.end !== displayCount) {
    virtualizedRange.end = virtualizedRange.start + displayCount;
  }

  return virtualizedRange;
}

const isDocument = (el: unknown): el is Document => {
  return el === document;
};

function DataVirtualizationRender<T = unknown>({
  rowCount: displayCount = DEFAULT_WINDOW_SIZE,
  children,
  tableRef
}: PropsWithChildren<DataVirtualizationOptions & DataTablePluginComponentProps<T>>) {
  const dispatch = useDataTableDispatcher();
  const data = useDataTableSelector(x => x.data);

  useEffect(() => {
    const recordCount = data.length;
    if (recordCount < displayCount || !tableRef.current) {
      return undefined;
    }

    let mounted = true;

    const scrollParent = getScrollableContainer(tableRef.current);

    // below logic is to calculate table height for displayCount after subtracting the height of virtual rows,
    // if useEffect is triggered while scrolling
    const tableChildren = tableRef.current.children;
    let tableBody = null;
    let virtualRowsCollection;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (tableChildren) {
      tableBody = tableRef.current.tBodies[0];
      // get existing virtual rows by class name
      for (let index = 0; index < tableChildren.length; index++) {
        if (tableChildren[index].querySelectorAll(".virtual-row").length) {
          virtualRowsCollection = tableChildren[index].querySelectorAll(".virtual-row");
        }
      }
    }

    let virtualHeight = 0;
    if (virtualRowsCollection && virtualRowsCollection.length > 0) {
      for (let index = 0; index < virtualRowsCollection.length; index++) {
        virtualHeight += virtualRowsCollection[index].clientHeight;
      }
    }

    // get original table height for only displayCount records by subtracting total height of virtual rows
    const tableHeight = tableBody
      ? tableBody.clientHeight - virtualHeight
      : tableRef.current.clientHeight - virtualHeight;
    const rowHeight = tableHeight / displayCount;
    const VISIBLE_ROWS = displayCount / 2;
    const BUFFER = VISIBLE_ROWS / 2;
    let endRowPosition = VISIBLE_ROWS;
    let startRowPosition = 0;
    let oldScrollY = 0;
    let scrollDirection = MOVING_DOWN;
    const handleScroll = () => {
      if (!mounted || !tableRef.current) {
        return;
      }

      const scrollTop = isDocument(scrollParent) ? document.documentElement.scrollTop : scrollParent.scrollTop;
      // Estimate current row
      // for scrollTop and rowHeight are zero lets see the row to 0
      let row;
      let start;
      // Move virtualized range based on row
      if (scrollTop === 0 && rowHeight === 0) {
        row = 0;
        start = 0;
      } else {
        row = rowHeight > 0 ? Math.floor(scrollTop / rowHeight) : Math.floor(rowHeight);
        start = row < displayCount ? row : row - displayCount / 2;
      }

      const end = row + displayCount / 2;

      // TODO: recordCount != total row count - this will lead to unexpected
      // results when group headers/footers are included, when rows are hidden
      // or a record renders multiple rows
      const winRange = calculateRange(start, end, displayCount, recordCount);
      const currRow = Math.ceil(scrollTop / rowHeight);
      if (oldScrollY < scrollTop) {
        // while scrolling downwards
        oldScrollY = scrollTop;
        if (scrollDirection === MOVING_UP) {
          // first downward scroll while moving upwards
          winRange.start = currRow - VISIBLE_ROWS < 0 ? 0 : currRow - VISIBLE_ROWS;
          winRange.end = winRange.start + displayCount > recordCount ? recordCount : winRange.start + displayCount;
          endRowPosition = winRange.end;
          scrollDirection = MOVING_DOWN;
        } else {
          if (endRowPosition > currRow + BUFFER || endRowPosition >= recordCount) {
            return;
          }
          winRange.start = currRow - OVER_SCAN < 0 ? 0 : currRow - OVER_SCAN;
          winRange.end = winRange.start + displayCount > recordCount ? recordCount : winRange.start + displayCount;
          endRowPosition = winRange.end;
        }
      } else if (oldScrollY > scrollTop) {
        // while scrolling upwards
        oldScrollY = scrollTop;
        if (scrollDirection === MOVING_DOWN) {
          // first upward scroll while moving downwards
          winRange.start = currRow - VISIBLE_ROWS < 0 ? 0 : currRow - VISIBLE_ROWS;
          winRange.end = winRange.start + displayCount > recordCount ? recordCount : winRange.start + displayCount;
          startRowPosition = winRange.start;
          scrollDirection = MOVING_UP;
        } else {
          if (startRowPosition < currRow - BUFFER || currRow - BUFFER <= 0) {
            return;
          }

          const tempEnd = currRow + VISIBLE_ROWS > recordCount ? recordCount : currRow + VISIBLE_ROWS;

          if (tempEnd < recordCount) {
            if (currRow - VISIBLE_ROWS <= 0) {
              winRange.start = 0;
            } else {
              winRange.start = currRow - VISIBLE_ROWS;
            }
          } else if (tempEnd === recordCount) {
            winRange.start = tempEnd - displayCount;
          } else {
            winRange.start = tempEnd - VISIBLE_ROWS;
          }

          winRange.end = winRange.start + displayCount;

          startRowPosition = winRange.start;
        }
      } else if (oldScrollY > 0 && oldScrollY === scrollTop) {
        // on subsequent re-rendering, old and new scroll values are same, hence do nothing
        return;
      }

      winRange.topHeight = Math.ceil((winRange.start <= 0 ? 0 : winRange.start) * rowHeight);
      winRange.bottomHeight = Math.ceil((recordCount - winRange.end) * rowHeight);

      winRange.virtualizedTopRow = <VirtualizedRow heightValue={winRange.topHeight} />;
      winRange.virtualizedBottomRow = <VirtualizedRow heightValue={winRange.bottomHeight} />;

      dispatch({ type: "DataTable/updateVirtualizedWindow", winRange });
    };

    const onScroll = debounceRender(handleScroll, SCROLL_DEBOUNCE_MS);
    const options: AddEventListenerOptions = { passive: true };
    scrollParent.addEventListener("scroll", onScroll, options);

    // establish initial values
    handleScroll();

    return () => {
      mounted = false;
      onScroll.cancel();
      scrollParent.removeEventListener("scroll", onScroll, options);
    };
  }, [dispatch, displayCount, data, tableRef]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

function VirtualizedRow({ heightValue }: { heightValue: number }) {
  if (heightValue <= 0) {
    return null;
  }
  return (
    <tr className="virtual-row">
      <td style={{ height: heightValue }} />
    </tr>
  );
}

export function DataVirtualization<T>(args: DataVirtualizationOptions) {
  const factory: DataTablePluginFactory<T, DataVirtualizationOptions> = () => {
    return {
      args,
      component: DataVirtualizationRender,

      reducer: (gridState: GridState<T>, action: GridAction<T>) => {
        const state = gridState as WindowedGridState<T>;
        switch (action.type) {
          case "DataTable/updateVirtualizedWindow":
            if (
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              state.winRange?.start === action.winRange?.start &&
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              state.winRange?.end === action.winRange?.end &&
              state.winRange.topHeight === action.winRange.topHeight &&
              state.winRange.bottomHeight === action.winRange.bottomHeight
            ) {
              // small optimization to prevent unnecessary renders
              return state;
            }

            return { ...state, winRange: action.winRange };
          case "DataTable/load": {
            const displayCount = args?.rowCount || DEFAULT_WINDOW_SIZE;
            if (state.data.length > displayCount && !state.winRange) {
              return { ...state, winRange: { start: 0, end: displayCount } };
            }

            return state;
          }
          default:
            return state;
        }
      }
    };
  };

  factory.pluginName = VIRTUALIZATION_PLUGIN;
  return factory;
}
