import React, { FunctionComponent, useState, KeyboardEvent } from "react";
import { useLocalization } from "@plex/culture-react";
import { TextInput } from "@components/Input";
import { Button } from "@components/Button";
import styles from "./FilePickerSearchInput.module.scss";

interface IFilePickerSearchInputProps {
  initialSearchText: string;
  onSearch: (searchText: string) => void;
}

export const FilePickerSearchInput: FunctionComponent<IFilePickerSearchInputProps> = ({
  initialSearchText,
  onSearch
}) => {
  const { t } = useLocalization();
  const [text, setText] = useState(initialSearchText || "");

  const handleSearch = () => {
    onSearch(text.toLowerCase());
  };

  const onEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    handleSearch();

    // Stop event propagation, to avoid conflicts with consumers that have event listeners on the container of Picker
    e.stopPropagation();
  };

  return (
    <div className={styles.searchSection} data-testid="plex-file-picker-search">
      <div className={styles.searchInput}>
        <TextInput
          value={text}
          onChange={e => setText(e.currentTarget.value)}
          onEnterKey={onEnterKey}
          maxLength={200}
          placeholder={t("ui-common-dataPicker-info-newSearch")}
          autoFocus
          data-testid="plex-file-picker-search-input"
        />
      </div>
      <div className={styles.searchButton}>
        <Button data-testid="plex-file-picker-search-button" onClick={handleSearch}>
          {t("ui-common-filter-action-search")}
        </Button>
      </div>
    </div>
  );
};
