import React, { FunctionComponent } from "react";
import { InputSize, NumericInput } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { INumericFormatOptions } from "@plex/culture-core";

export interface INumericInputProps extends INodeConfigPropertiesProps {
  label: string;
  numericOptions?: INumericFormatOptions;
}

export const NumericFormField: FunctionComponent<INumericInputProps> = (props) => {
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  return (
    <NumericInput
      key={`${props.sectionName} ${props.label} Input`}
      numericValue={props.node?.data.nodeProperties[props.name] ?? ""}
      size={InputSize.medium}
      numericOptions={props.numericOptions}
      onValueChange={(number) => {
        props.node!.data.nodeProperties[props.name] = number;
        setState({ value: props.node!.data.nodeProperties[props.name] });
        props.forceNodeUpdate();
      }}
    />
  );
};
