import React, { FunctionComponent, useReducer, useEffect, useMemo } from "react";
import { LocalDate, Year, YearMonth } from "js-joda";
import { MonthValue } from "@plex/culture-core";
import { returnTrue } from "../../utils";
import { YearView } from "./YearView";
import { MonthView } from "./MonthView";
import { CalendarView } from "./CalendarView";
import { CalendarInterval } from "./CalendarInterval";
import { isLocalDate } from "./date-utils";
import { ICommonDatePickerProps, DatePickerModes } from "./DatePicker.types";

interface IDatePickerState {
  selectedMonth: MonthValue;
  selectedYear: number;
  mode: DatePickerModes;
}

export interface IDatePickerProps extends ICommonDatePickerProps {
  range?: CalendarInterval;
  validateDate?: (date: LocalDate) => boolean;
  disabled?: boolean;
  showing?: boolean;
}

const modeOrder: DatePickerModes[] = ["day", "month", "year"];
type Action =
  | { type: "year"; year: Year }
  | { type: "month"; month: YearMonth }
  | { type: "day"; day: LocalDate }
  | { type: "toggle" }
  | { type: "reset" };

/**
 * This component coordinates the different views when selecting a date.
 */
export const DatePicker: FunctionComponent<IDatePickerProps> = ({
  value,
  onValueChange,
  range,
  validateDate = returnTrue,
  weekStart,
  disabled,
  showing = true,
  defaultInputMode = "day"
}) => {
  const reducer = (state: IDatePickerState, action: Action): IDatePickerState => {
    switch (action.type) {
      case "year":
        return {
          ...state,
          mode: defaultInputMode === "year" ? defaultInputMode : "month",
          selectedYear: action.year.value()
        };
      case "month":
        return {
          mode: defaultInputMode === "month" ? defaultInputMode : "day",
          selectedYear: action.month.year(),
          selectedMonth: action.month.monthValue()
        };
      case "day":
        return {
          mode: "day",
          selectedYear: action.day.year(),
          selectedMonth: action.day.monthValue()
        };
      case "toggle": {
        let index = modeOrder.indexOf(state.mode);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return { ...state, mode: modeOrder[++index] || "year" };
      }
      case "reset":
        return { ...state, mode: defaultInputMode };
      default:
        return state;
    }
  };

  const createInitialState = (selectedDate: LocalDate | null): IDatePickerState => {
    const dt = selectedDate || LocalDate.now();
    return {
      mode: defaultInputMode,
      selectedYear: dt.year(),
      selectedMonth: dt.monthValue()
    };
  };

  const [{ mode, selectedYear, selectedMonth }, dispatch] = useReducer(
    reducer,
    createInitialState(value ?? LocalDate.now())
  );

  const selectedYearMonth = useMemo(() => YearMonth.of(selectedYear, selectedMonth), [selectedYear, selectedMonth]);

  useEffect(() => {
    if (value) {
      switch (defaultInputMode) {
        case "day":
          dispatch({ day: value, type: "day" });
          break;
        case "month":
          dispatch({ month: isLocalDate(value) ? YearMonth.from(value) : value, type: "month" });
          break;
        case "year":
          dispatch({ year: isLocalDate(value) ? Year.from(value) : value, type: "year" });
          break;
        default:
          dispatch({ day: value, type: "day" });
          break;
      }
    }
  }, [defaultInputMode, value]);

  useEffect(() => {
    if (!showing) {
      dispatch({ type: "reset" });
    }
  }, [showing]);

  const yearChangeHandler = (year: Year) => {
    if (defaultInputMode === "year") {
      onValueChange?.(LocalDate.of(year.value(), 1, 1));
    } else {
      dispatch({ year, type: "year" });
    }
  };

  const monthChangeHandler = (month: YearMonth) => {
    if (defaultInputMode === "month") {
      onValueChange?.(LocalDate.of(month.year(), month.monthValue(), 1));
    } else {
      dispatch({ month, type: "month" });
    }
  };

  const dateChangeHandler = (day: LocalDate | null) => {
    dispatch({ day: day!, type: "day" });
    onValueChange?.(day);
  };

  const headerClickHandler = () => {
    if (disabled) {
      return;
    }
    dispatch({ type: "toggle" });
  };

  switch (mode) {
    case "year":
      return <YearView year={selectedYear} onYearChange={yearChangeHandler} />;
    case "month":
      return (
        <MonthView
          year={selectedYear}
          monthValue={selectedMonth}
          onMonthChange={monthChangeHandler}
          onHeaderClick={headerClickHandler}
        />
      );
    default:
      return (
        <CalendarView
          month={selectedYearMonth}
          weekStart={weekStart}
          value={value}
          range={range}
          onValueChange={dateChangeHandler}
          onHeaderClick={headerClickHandler}
          validateDate={validateDate}
          disabled={disabled}
        />
      );
  }
};
