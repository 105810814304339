import React, { useRef, useImperativeHandle, useMemo, useContext } from "react";
import clsx from "clsx";
import { noop } from "@utils";
import { useToggle } from "@hooks";
import { ICommonProps, IWithChildren } from "../Common.types";
import { useOutsideClick } from "./useOutsideClick";
import styles from "./ActionBar.module.scss";

type DropdownContextState = {
  menu: boolean;
  visible: boolean;
  close: () => void;
  toggle: () => void;
  level?: number;
};

export const DropdownContext = React.createContext<DropdownContextState>({
  menu: false,
  visible: false,
  level: 0,
  close: noop,
  toggle: noop
});

export interface IActionBarDropdown extends ICommonProps, IWithChildren {}

const ActionBarDropdown = React.forwardRef<HTMLLIElement, IActionBarDropdown>(({ children, ...other }, ref) => {
  const parentContext = useContext(DropdownContext);
  const [menuVisible, toggleMenu] = useToggle(false);

  const classes = clsx(
    !parentContext.menu && styles.btnBase,
    parentContext.menu && styles.menuBtnBase,
    menuVisible && styles.toggled
  );

  const outsideClickHandler = () => {
    if (menuVisible) {
      toggleMenu(false);
    }
  };

  const wrapperRef = useRef<HTMLLIElement>(null);
  useImperativeHandle<HTMLLIElement | null, HTMLLIElement | null>(ref, () => wrapperRef.current);
  useOutsideClick(wrapperRef, outsideClickHandler);

  const context = useMemo<DropdownContextState>(
    () => ({
      menu: true,
      close: () => {
        toggleMenu(false);
      },
      toggle: toggleMenu,
      visible: menuVisible,
      level: parentContext.level ? parentContext.level + 1 : 1
    }),
    [menuVisible, toggleMenu, parentContext]
  );

  return (
    <DropdownContext.Provider value={context}>
      <li data-testid="plex-action-dropdown" ref={wrapperRef} className={classes} {...other}>
        {children}
      </li>
    </DropdownContext.Provider>
  );
});

ActionBarDropdown.displayName = "ActionBarDropdown";
export { ActionBarDropdown };
