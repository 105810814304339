import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ListItemFirstNode",
  label: "First",
  category: NodeTypeCategory.lists,
  isExperimental: true,
  controlOutputs: [],
  dataInputs: [{ enabled: true, type: DataType.OBJECTLIST, name: "list", required: false }],
  dataOutputs: [{ enabled: true, type: DataType.OBJECT, name: "item" }],
  listToItem: true,
  size: NodeSize.Small,
  evaluate: (input) => {
    const item = { item: (input.list as any)[0] };
    return {
      output: { item: item },
      activeControlHandleIds: []
    };
  }
});

export const ListItemFirstNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
