import React, { FunctionComponent } from "react";
import { useLocalization } from "@plex/culture-react";
import { Button } from "../Button";
import styles from "./Filter.module.scss";

interface ISearchButtonProps {
  onClick: () => void;
}

export const SearchButton: FunctionComponent<ISearchButtonProps> = ({ onClick }) => {
  const { t } = useLocalization();
  return (
    <Button className={styles.searchButton} onClick={onClick}>
      {t("ui-common-filter-action-search")}
    </Button>
  );
};
