import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./ValidationMessage.module.scss";

interface IValidationMessageProps extends ICommonProps {
  /**
   * The message to display to the user.
   */
  message: string;
  /**
   * The id of the element which is causing the validation message.
   */
  labelFor?: string;
}

export const ValidationMessage: FunctionComponent<IValidationMessageProps> = ({
  message,
  labelFor,
  className,
  ...other
}) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <label className={clsx(styles.validationMessage, className)} htmlFor={labelFor} {...other}>
        {message}
      </label>
    </div>
  );
};
