import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  KeyboardEvent,
  KeyboardEventHandler,
  forwardRef,
  useImperativeHandle,
  FunctionComponent,
  createElement
} from "react";
import clsx from "clsx";
import { Locale, INumericFormatOptions } from "@plex/culture-core";
import { useLocalization } from "@plex/culture-react";
import { withPrinting } from "@plex/react-xml-renderer";
import { TextInput } from "./TextInput";
import styles from "./Input.module.scss";
import { INumericInputProps } from "./Input.types";

const isPercentFormat = (options: string | INumericFormatOptions) => {
  return (
    options === "p" || (options && typeof options === "object" && options.format && options.format.startsWith("p"))
  );
};

const parserFactory = (locale: Locale, options?: string | INumericFormatOptions) => {
  if (options && isPercentFormat(options)) {
    // HACK: to account for floating point precision, limit to max precise value
    return (value: string) => parseFloat((locale.numbers.parse(value) / 100).toFixed(15));
  }

  return (value: string) => locale.numbers.parse(value);
};

const formatterFactory = (locale: Locale, options?: string | INumericFormatOptions) => {
  return (value: number | undefined) => {
    return Number.isNaN(value as number) ? "" : locale.numbers.format(value as number, options);
  };
};

const controlChars = new Set(["a", "A", "c", "C", "x", "X", "v", "V"]);
const specialChars = new Set([
  "Home",
  "End",
  "ArrowLeft",
  "ArrowRight",
  "Backspace",
  "Tab",
  "Enter",
  "Delete",
  "Insert"
]);

const isAllowedKeystroke = (e: KeyboardEvent) => {
  if (e.ctrlKey || e.metaKey) {
    if (controlChars.has(e.key)) {
      return true;
    }
  }

  return specialChars.has(e.key);
};

const createKeyHandler = (locale: Locale, onKeyPress?: KeyboardEventHandler) => {
  return (e: KeyboardEvent) => {
    switch (true) {
      case isAllowedKeystroke(e):
        break;
      default:
        if (e.key.length !== 1 || !locale.numbers.isValidNumericDigit(e.key)) {
          e.preventDefault();
          return;
        }

        break;
    }

    onKeyPress?.(e);
  };
};

/**
 * An input component which limits user input to numeric values. On keypress non-numeric
 * values are prevented from being entered for the current locale. On change, the numeric
 * value is formatted given values on the "numericOptions" prop.
 *
 * When using you should manage the state of the numeric value. Example:
 *
 * ~~~js
 * const [numericValue, setNumericValue] = useState(0);
 * return <NumericInput numericValue={numericValue} onValueChange={setNumericValue} />
 * ~~~
 *
 */
const HtmlNumericInput = forwardRef<HTMLInputElement, INumericInputProps>(
  ({ className, numericValue, numericOptions, onKeyPress, onValueChange, ...other }, ref) => {
    const { locale } = useLocalization();
    const parseValue = useCallback(parserFactory(locale, numericOptions), [locale, numericOptions]);
    const formatValue = useCallback(formatterFactory(locale, numericOptions), [locale, numericOptions]);

    const [inputValue, setInputValue] = useState<string>(() => formatValue(numericValue));

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    useEffect(() => {
      const changeHandler = (e: Event) => {
        let parsedValue: number | null = parseValue((e.currentTarget as HTMLInputElement).value);
        const displayValue = formatValue(parsedValue);
        setInputValue(displayValue);

        if (onValueChange) {
          if (Number.isNaN(parsedValue)) {
            parsedValue = null;
          } else {
            // We need to parse the value again to account for rounding/truncation.
            // We may be able to skip this step if we look at options and only perform
            // the reparsing when the options require it.
            parsedValue = parseValue(displayValue);
          }

          // We could potentially optimize here by not calling the callback if the value
          // hasn't changed.
          onValueChange(parsedValue);
        }
      };

      const input = inputRef.current;
      if (input) {
        input.addEventListener("change", changeHandler);
        return () => input.removeEventListener("change", changeHandler);
      }

      return undefined;
    }, [onValueChange, formatValue, parseValue]);

    // If the passed in numericValue changes or if the locale changes,
    // we want to make sure to update the internal state to reflect that.
    // (`formatValue` will change when the locale changes.)
    useLayoutEffect(() => {
      setInputValue(formatValue(numericValue));
    }, [numericValue, formatValue]);

    const keyHandler = createKeyHandler(locale, onKeyPress);
    const classes = clsx(styles.numeric, className);

    return (
      <TextInput
        onKeyPress={keyHandler}
        className={classes}
        keyboard="numeric"
        value={inputValue}
        ref={inputRef}
        onChange={e => setInputValue(e.currentTarget.value)}
        {...other}
      />
    );
  }
);
HtmlNumericInput.displayName = "NumericInput";

const XmlNumericInput: FunctionComponent<INumericInputProps> = ({ numericValue }) => {
  return createElement("plex-control-numberbox", {}, numericValue);
};

export const NumericInput = withPrinting(HtmlNumericInput, XmlNumericInput);
