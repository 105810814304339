import React, { FC } from "react";
import { ConstantLabel } from "./ConstantLabel";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "NowConstantNode",
  category: NodeTypeCategory.dates,
  label: "Now",
  isExperimental: true,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: "result", type: DataType.STRING, enabled: true, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "constant",
      label: "Value",
      propertyType: NodeConfigPropertyType.DataValue
    }
  ],
  size: NodeSize.Small,
  evaluate: (input, nodeProperties) => {
    return { output: { result: nodeProperties.constant }, activeControlHandleIds: [] };
  }
});

/**
 * @deprecated Replaced by DateTimeNowNode
 */
export const NowConstantNode: FC<NodeProps> = ({ id, data }) => {
  const kind = "now";
  return (
    <BasicNodeLayout id={id} data={data} className="single-type-constant-node">
      <ConstantLabel data={data} kind={kind} />
    </BasicNodeLayout>
  );
};
