import React, { forwardRef } from "react";
import { IDateRangePickerProps, ITemporalInputProps } from "./DatePicker.types";
import { DateTimeRangeInput } from "./DateTimeRangeInput";

interface IDateRangeInputProps extends ITemporalInputProps, IDateRangePickerProps {}

export const DateRangeInput = forwardRef<HTMLInputElement, IDateRangeInputProps>((props, ref) => {
  return <DateTimeRangeInput showTime={false} ref={ref} {...props} />;
});

DateRangeInput.displayName = "DateRangeInput";
