import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { ComparisonLabel } from "./ComparisonLabel";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { StringCompareOperation } from "../../FlowDocument/FlowDocumentModel";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CompareStringNode",
  category: NodeTypeCategory.logic,
  label: "Compare Text",
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.STRING, hideLabel: true },
    { name: "right", type: DataType.STRING, hideLabel: true, required: false }
  ],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  nodeConfigProperties: [
    {
      name: "operation",
      label: "Comparison",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: StringCompareOperation }
    },
    {
      name: "ignoreCase",
      label: "Ignore Case",
      propertyType: NodeConfigPropertyType.Boolean
    }
  ],
  evaluate: (input, nodeProperties) => {
    let result = false;
    let left: string = input.left as string;
    let right: string = input.right as string;

    if (nodeProperties.ignoreCase) {
      left = left.toLocaleLowerCase();
      right = right.toLocaleLowerCase();
    }

    switch (nodeProperties.operation as StringCompareOperation) {
      case StringCompareOperation.Equal:
        result = left === right;
        break;
      case StringCompareOperation.NotEqual:
        result = left !== right;
        break;
      case StringCompareOperation.Contains:
        result = (left as string).indexOf(right as string) !== -1;
        break;
      case StringCompareOperation.StartsWith:
        result = (left as string).indexOf(right as string) === 0;
        break;
      case StringCompareOperation.EndsWith:
        result = (left as string).indexOf(right as string) === (left as string).length - (right as string).length;
        break;
    }
    return {
      output: {
        Result: result
      },
      activeControlHandleIds: []
    };
  }
});

export const CompareStringNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return (
    <BasicNodeLayout id={id} data={data}>
      <ComparisonLabel nodeId={id} comparisonOperator={data.nodeProperties.operation} />
    </BasicNodeLayout>
  );
};
