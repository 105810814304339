import React, {
  FormEventHandler,
  KeyboardEventHandler,
  KeyboardEvent,
  FormEvent,
  useCallback,
  FocusEventHandler,
  forwardRef,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  FunctionComponent,
  createElement,
  useState
} from "react";
import clsx from "clsx";
import { useLocalization } from "@plex/culture-react";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, ValueChangeHandler } from "../Common.types";
import styles from "./TextArea.module.scss";

export interface ITextAreaProps extends ICommonProps {
  /** Sets the autofocus attribute on the element */
  autoFocus?: boolean;
  /**
   * The value indicating whether element will automatically expand and shrink vertically depending on the amount and size of content entered in its editing area
   * @default false
   * @requires autoGrowMaxHeight
   */
  autoGrow?: boolean;
  /**
   * Element max height in px when autoGrow = true
   * @requires autoGrow
   */
  autoGrowMaxHeight?: number;
  /**
   * Sets the element as disabled
   * @default false
   */
  disabled?: boolean;
  /** The name attribute to apply to the element */
  name?: string;
  /** Event triggered on blur */
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  /**
   * Event triggered when the value changes
   * @param {string} value - Normalized value (replaced textarea line break '\n' symbol with '\r\n')
   */
  onChange?: (value: string, event: FormEvent<HTMLTextAreaElement>) => void;
  /** Event triggered when [ENTER] key is pressed */
  onEnterKey?: KeyboardEventHandler<HTMLTextAreaElement>;
  /** Event triggered when a key is down */
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  /** Event triggered when symbol key is pressed */
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
  /**
   * Event triggered when value is changed
   * @param {string} value - Normalized value (replaced textarea line break '\n' symbol with '\r\n')
   */
  onValueChange?: ValueChangeHandler<string>;
  /** The short hint is displayed in the text area before the user enters a value */
  placeholder?: string;
  /**
   * Specifies that a text area should be read-only
   * @default false
   */
  readOnly?: boolean;
  /** The tab index of the element */
  tabIndex?: number;
  /** The value */
  value?: string;
  /** The Text limit */
  maxLength?: number;
}

const EOL_PATTERN = /\r?\n/g;
const CRLF = "\r\n";

const normalizeLineBreakSymbols = (text: string): string => {
  const value = text && String(text);

  if (value && value.search(EOL_PATTERN) !== -1) {
    return value.replace(EOL_PATTERN, CRLF);
  }

  return value;
};

const HtmlTextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      autoGrow,
      autoGrowMaxHeight,
      className,
      style,
      onChange,
      onEnterKey,
      onKeyPress,
      onValueChange,
      readOnly,
      maxLength,
      ...other
    },
    ref
  ) => {
    const elementRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useLocalization();
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(ref, () => elementRef.current);

    const onChangeHandler: FormEventHandler<HTMLTextAreaElement> = useCallback(
      e => {
        if (onChange || onValueChange) {
          const normalizedValue = normalizeLineBreakSymbols(e.currentTarget.value);
          onChange?.(normalizedValue, e);
          onValueChange?.(normalizedValue);
        }

        if (autoGrow && autoGrowMaxHeight) {
          e.currentTarget.style.overflowY = e.currentTarget.scrollHeight > autoGrowMaxHeight ? "scroll" : "hidden";
          e.currentTarget.style.height = "auto";
          e.currentTarget.style.height = `${String(e.currentTarget.scrollHeight)}px`;
        }
      },
      [onChange, onValueChange, autoGrow, autoGrowMaxHeight]
    );

    useLayoutEffect(() => {
      if (autoGrow && autoGrowMaxHeight && elementRef.current) {
        elementRef.current.style.maxHeight = `${String(autoGrowMaxHeight)}px`;
        elementRef.current.style.resize = "none";

        elementRef.current.style.overflowY = elementRef.current.scrollHeight > autoGrowMaxHeight ? "scroll" : "hidden";
        elementRef.current.style.height = "auto";
        elementRef.current.style.height = `${String(elementRef.current.scrollHeight)}px`;
      }
    }, [autoGrow, autoGrowMaxHeight]);

    const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        onEnterKey?.(e);
      }

      onKeyPress?.(e);
    };

    const [collapsed, setCollapsed] = useState(false);

    if (readOnly) {
      const value = other.value;
      if (!value || value.length <= maxLength!) {
        return (
          <span className={clsx(styles.plexReadonlyText, styles.plexReadonlyXlarge)} style={style}>
            {value}
          </span>
        );
      } else if (value.length > maxLength!) {
        return (
          <div className={clsx(styles.plexModuleCollapse, className)}>
            <span
              className={clsx(
                styles.plexReadonlyText,
                styles.plexReadonlyXlarge,
                collapsed ? styles.collapsing : styles.collapse
              )}
              aria-expanded={collapsed}
              style={style}
            >
              {value}
            </span>
            <div>
              <a
                role="button"
                className={clsx(!collapsed && styles.collapsed)}
                data-toggle="collapse"
                onClick={() => setCollapsed(x => !x)}
                aria-expanded={collapsed}
              >
                <span className={styles.ifCollapsed}>{t("ui-common-textArea-showMore")}</span>
                <span className={styles.ifNotCollapsed}>{t("ui-common-textArea-showLess")}</span>
              </a>
            </div>
          </div>
        );
      }
    }

    return (
      <textarea
        className={clsx(styles.base, className)}
        onChange={onChangeHandler}
        onKeyPress={handleKeyPress}
        ref={elementRef}
        style={style}
        {...other}
      />
    );
  }
);
HtmlTextArea.displayName = "TextArea";

const XmlTextArea: FunctionComponent<ITextAreaProps> = ({ value }) => {
  return createElement("plex-control-textarea", {}, value);
};

export const TextArea = withPrinting(HtmlTextArea, XmlTextArea);
