import React, { forwardRef, MouseEventHandler, PropsWithChildren } from "react";
import clsx from "clsx";
import { withNoPrinting } from "@plex/react-xml-renderer";
import { ICommonProps } from "../Common.types";
import { Clickable, ClickableComponentType } from "../Link";
import styles from "./Button.module.scss";

export type ButtonSize = "xs" | "sm" | "lg";
export type ButtonVariance = "primary" | "link";

export interface IButtonProps extends PropsWithChildren<ICommonProps> {
  /**
   * Specify how the button should look
   */
  variance?: ButtonVariance;
  /**
   * The href for the link - will cause the button to render as an anchor
   */
  href?: string;
  /**
   * Click event handler
   * @default null
   */
  onClick?: MouseEventHandler;
  /**
   * Sets the button as disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Sets the react component for rendering this  button. Most common values are 'button' and 'a'
   * @default button
   */
  as?: ClickableComponentType;
  /** Sets the size of the button */
  size?: ButtonSize;
  /** The tab index attribute */
  tabIndex?: number;
  /**
   * Sets the button type in case of render as button
   */
  type?: "button" | "submit" | "reset";
}

/**
 * Renders a standard button
 */
// eslint-disable-next-line react/display-name
const HtmlButton = forwardRef<HTMLElement, IButtonProps>(
  ({ variance: buttonType = "primary", className, disabled, size, type = "button", children, ...other }, ref) => {
    const classes = clsx(styles.base, styles[buttonType], size && styles[size], className, disabled && styles.disabled);

    return (
      <Clickable type={type} disabled={disabled} className={classes} role="button" ref={ref} {...other}>
        {children}
      </Clickable>
    );
  }
);
export const Button = withNoPrinting(HtmlButton);
Button.displayName = "Button";
