import React, { FunctionComponent, useEffect, useState } from "react";
import { DateTimeInput, TimePrecision } from "@plex/react-components";
import { DateTimeFormatStyle } from "@plex/culture-core";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface IDateTimeInputProps extends INodeConfigPropertiesProps {
  label: string;
  formatOptions: DateTimeFormatStyle;
  precision: TimePrecision;
}

export const DateTimeFormField: FunctionComponent<IDateTimeInputProps> = (props) => {
  const [value, setValue] = useState(props.node?.data.nodeProperties[props.name] || "");

  useEffect(() => {
    setValue(props.node?.data.nodeProperties[props.name] || "");
  }, [props.node?.data.nodeProperties[props.name]]);

  const handleValueChange = (dt: any) => {
    if (dt) {
      const newValue = new Date(dt).toISOString().slice(0, 19);
      setValue(newValue);
      props.node!.data.nodeProperties[props.name] = newValue;
    } else {
      setValue("");
      props.node!.data.nodeProperties[props.name] = null;
    }

    props.forceNodeUpdate();
  };

  return (
    <DateTimeInput
      key={`${props.sectionName} ${props.label} Input`}
      disabled={false}
      value={value}
      onValueChange={handleValueChange}
      use24HourTime={false}
      formatOptions={props.formatOptions}
      precision={props.precision}
    />
  );
};
