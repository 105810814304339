import React, { FC } from "react";
import { TranslatedText } from "@plex/culture-react";
import styles from "./Wizard.module.scss";

/** Wizard component which displays list of pages */
export const WizardPageList: FC = ({ children }) => {
  return (
    <div data-testid="plex-wizard-page-list" className={styles.pageList}>
      <fieldset data-testid="plex-wizard-page-list-content" className={styles.pageListContent}>
        <legend>
          <TranslatedText messageId="ui-common-wizard-title-step" />
          &nbsp;
          <TranslatedText messageId="ui-common-wizard-title-page" />
        </legend>

        <ol data-testid="plex-wizard-page-list-pages">{children}</ol>
      </fieldset>
    </div>
  );
};
