import React, { FunctionComponent } from "react";
import { TextArea } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface ITextAreaInputProps extends INodeConfigPropertiesProps {
  label: string;
}

export const TextAreaFormField: FunctionComponent<ITextAreaInputProps> = (props) => {
  const initialValue = props.node?.data.nodeProperties[props.name] || "";

  const [state, setState] = React.useState({ value: initialValue });

  return (
    <TextArea
      key={`${props.sectionName} ${props.label} Input`}
      value={state.value}
      autoGrow
      onChange={(value, e) => {
        const newValue = value || "";
        if (newValue.trim() === "" && newValue !== "") {
          return;
        }
        props.node!.data.nodeProperties[props.name] = newValue;
        setState({ value: newValue });
        props.forceNodeUpdate();
      }}
      style={{
        minHeight: 24,
        minWidth: 200
      }}
    />
  );
};
