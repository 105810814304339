import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import { ICommonProps, IWithChildren } from "@components/Common.types";
import { DragDropIcon } from "@plex/icons";
import styles from "./SideMenu.module.scss";

export interface ISideMenuItem extends ICommonProps, IWithChildren {
  /* Id of the element */
  id?: string;

  /* Menu item text */
  text?: string;

  /* Menu item title */
  title?: string;

  /* Menu item icon */
  icon?: React.ReactNode;

  /* Disable drag-n-drop for item by setting this to FALSE */
  draggable?: boolean;

  /* Is the item selected */
  selected?: boolean;

  /* Is the item selected */
  disabled?: boolean;

  /** Click handler for item */
  onClick?: (e: React.MouseEvent, props: unknown) => void;

  /* Props, passed to component from outside, and returned through onClick */
  onClickProps?: unknown;

  /* Handler of wheel event. This is an internal property - please don't pass it from outside, it can break the functionality. */
  onWheel?: (e: React.WheelEvent) => void;

  /* Is the item collapsed. This is an internal property - please don't pass it from outside, it can break the functionality. */
  collapsed?: boolean;

  /* Is the item dragging. This is an internal property - please don't pass it from outside, it can break the functionality. */
  isDragging?: boolean;

  /** Indicates the index of the menu item. [Supplied internally] */
  index?: number;

  /** The HREF attribute to apply to the linked item. */
  href?: string;
}

const SideMenuItemContent = React.forwardRef<HTMLLIElement, ISideMenuItem & { isDraggable?: boolean }>(
  (
    {
      children,
      text,
      title,
      icon,
      selected,
      disabled,
      onClick,
      onClickProps,
      collapsed,
      id,
      isDragging,
      isDraggable,
      className,
      href,
      ...other
    },
    ref
  ) => {
    const showDragIcon = isDraggable && !collapsed;

    const clickHandler = (e: React.MouseEvent) => {
      e.preventDefault();
      if (onClick && !disabled) {
        onClick(e, onClickProps);
      }
    };

    const hrefIfEnabled = disabled ? undefined : href;

    return (
      <li id={id} title={title} ref={ref} {...other}>
        <a
          href={hrefIfEnabled}
          onClick={clickHandler}
          className={clsx(
            styles.sideMenuItem,
            disabled && styles.sideMenuItemDisabled,
            selected && styles.sideMenuItemSelected,
            isDragging && styles.sideMenuItemDragging,
            className
          )}
        >
          <div className={styles.sideMenuItemIconContainer}>
            {icon && <i className={styles.sideMenuItemIcon}>{icon}</i>}
          </div>

          {!!text && (
            <span hidden={collapsed} className={styles.sideMenuItemText}>
              {text}
            </span>
          )}

          {children}

          {showDragIcon && (
            <span className={styles.sideMenuItemDragDropIcon}>
              <DragDropIcon />
            </span>
          )}
        </a>
      </li>
    );
  }
);
SideMenuItemContent.displayName = "SideMenuItemContent";

const SideMenuItem: FC<ISideMenuItem> = ({ id, draggable, index, children, ...other }) => {
  if (draggable) {
    return (
      <Draggable key={id} draggableId={String(id)} index={index!}>
        {(providedDraggable, snapshot) => (
          <SideMenuItemContent
            id={id}
            isDragging={snapshot.isDragging}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            ref={providedDraggable.innerRef}
            {...other}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            isDraggable
          >
            {children}
          </SideMenuItemContent>
        )}
      </Draggable>
    );
  }

  return (
    <SideMenuItemContent id={id} {...other}>
      {children}
    </SideMenuItemContent>
  );
};

export { SideMenuItem };
