import React from "react";
import { TabList as TabListElement } from "react-tabs";
import clsx from "clsx";
import { ITabsElement, TabsRole, ITabListProps } from "./Tabs.types";
import styles from "./Tabs.module.scss";

/** Component which renders a tab list. */
const TabList: ITabsElement = ({ children, className, ...other }: ITabListProps) => {
  return (
    <TabListElement data-testid="plex-tab-list" className={clsx(styles.tabList, className)} {...other}>
      {children}
    </TabListElement>
  );
};

TabList.displayName = TabsRole.TabList;
TabList.tabsRole = TabsRole.TabList;

export { TabList };
