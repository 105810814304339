import React, {
  useState,
  FocusEvent,
  KeyboardEvent,
  useMemo,
  useEffect,
  forwardRef,
  FormEvent,
  createElement,
  FunctionComponent
} from "react";
import { nativeJs, LocalDateTime, LocalDate, LocalTime, TemporalAdjuster, Temporal } from "js-joda";
import { CalendarIcon } from "@plex/icons";
import { useLocalization } from "@plex/culture-react";
import { withPrinting } from "@plex/react-xml-renderer";
import { useToggle } from "../../hooks";
import { IconButton, OkButton } from "../Button";
import { CancelLink } from "../Link";
import { Chip, ChipInput } from "../Chip";
import { Popover } from "../Popover";
import { AdornedInput } from "../Input";
import { DateTimePicker } from "./DateTimePicker";
import { isLocalDate, isLocalDateTime, isLocalTime } from "./date-utils";
import {
  ICalendarProps,
  ITimeProps,
  ITemporalInputProps,
  DateChangeHandler,
  DatePickerModes
} from "./DatePicker.types";

interface IDateTimeInputProps extends ITemporalInputProps, ICalendarProps, ITimeProps {
  /**
   * The current selected @see LocalDate instance.
   */
  value?: Temporal;
  /**
   * Function which receives the date when the user makes a selection. This should
   * be used to update local state.
   */
  onValueChange?: DateChangeHandler<Temporal>;

  // for internal use only
  showDate?: boolean;
  showTime?: boolean;
  defaultInputMode?: DatePickerModes;
}

const incrementDateKeyMap: { [key: string]: number } = {
  "+": 1,
  "-": -1,
  ArrowUp: 1,
  ArrowDown: -1
};

/**
 * This component renders an input with a calendar button that a user can use to
 * enter or select individual date values.
 */
const HtmlDateTimeInput = forwardRef<HTMLInputElement, IDateTimeInputProps>(
  (
    {
      weekStart, // date props
      formatOptions,

      use24HourTime, // time props
      precision,
      minuteStep,

      showDate = true, // internal props
      showTime = true,

      disabled, // input props
      onKeyDown,
      value = null,
      onValueChange,
      defaultInputMode = "day",
      ...other
    },
    ref
  ) => {
    const { locale } = useLocalization();
    const [popoverVisible, togglePopover, hidePopover, showPopover] = useToggle(false);
    const [inputValue, setInputValue] = useState("");
    const [pickerValue, setPickerValue] = useState(value);
    const formattedDate = useMemo(() => value && locale.dates.format(value, formatOptions), [
      value,
      locale,
      formatOptions
    ]);

    useEffect(() => {
      setPickerValue(value);
    }, [value]);

    useEffect(() => {
      if (popoverVisible) {
        // Even if value is null, picker should always have a value.
        // If the value is null, use the current date/time.
        setPickerValue(v => v || LocalDateTime.now());
      }
    }, [popoverVisible, showDate, showTime]);

    const clearPicker = () => {
      hidePopover();
      setInputValue("");
    };

    const handleValueChange = (dateAndOrTime: Temporal | null) => {
      onValueChange?.(dateAndOrTime);
      clearPicker();
    };

    const cancelPick = () => {
      setPickerValue(value);
      clearPicker();
    };

    const handleDateChange = (d: LocalDate | null) => {
      if (!showDate || d == null) {
        return;
      }

      if (!showTime) {
        // if the we're not showing time we can commit and close the picker
        // when a date is selected
        handleValueChange(d);
      }

      if (isLocalDate(pickerValue)) {
        setPickerValue(d);
      } else if (isLocalDateTime(pickerValue)) {
        setPickerValue(pickerValue.with((d as unknown) as TemporalAdjuster));
      } else if (isLocalDate(d) && isLocalTime(value)) {
        setPickerValue(LocalDateTime.of(d, value));
      }
    };

    const handleTimeChange = (d: LocalTime | null) => {
      if (!showTime || d == null) {
        return;
      }

      if (isLocalTime(pickerValue)) {
        setPickerValue(d);
      } else if (isLocalDateTime(pickerValue)) {
        setPickerValue(pickerValue.with((d as unknown) as TemporalAdjuster));
      } else if (isLocalDate(value) && isLocalTime(d)) {
        setPickerValue(LocalDateTime.of(value, d));
      }
    };

    const handleUserChange = (text: string) => {
      const parsedDate = locale.dates.parse(text, formatOptions);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const localDate = parsedDate && LocalDateTime.from(nativeJs(parsedDate));
      handleValueChange(localDate);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!localDate) {
        showPopover();
      }
    };

    const handleUserText = (e: FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget.value) {
        handleUserChange(e.currentTarget.value);
      }
    };

    // eslint-disable-next-line complexity
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (popoverVisible) {
        return;
      }

      if (e.key === "Enter" && e.currentTarget.value) {
        handleUserChange(e.currentTarget.value);
        e.preventDefault();
        return;
      }

      if (!e.currentTarget.value && isLocalDate(value) && e.key in incrementDateKeyMap) {
        if (defaultInputMode === "day") {
          handleValueChange(value.plusDays(incrementDateKeyMap[e.key]));
        }

        if (defaultInputMode === "month") {
          handleValueChange(value.plusMonths(incrementDateKeyMap[e.key]));
        }

        if (defaultInputMode === "year") {
          handleValueChange(value.plusYears(incrementDateKeyMap[e.key]));
        }
        e.preventDefault();
        return;
      }

      if (!e.currentTarget.value && formattedDate && e.key === "Backspace") {
        handleValueChange(null);
        setInputValue(formattedDate);
        e.preventDefault();
        return;
      }

      onKeyDown?.(e);
    };

    const calendarButton = (
      <IconButton disabled={disabled} onClick={togglePopover}>
        <CalendarIcon />
      </IconButton>
    );

    // We probably could always render the popover, even if disabled, but there's no
    // need to.
    const buttonWithPopover = disabled ? (
      calendarButton
    ) : (
      <Popover visible={popoverVisible} anchor={calendarButton} hideOnClick onHidden={hidePopover}>
        <Popover.Body>
          <DateTimePicker
            onDateChange={handleDateChange}
            onTimeChange={handleTimeChange}
            value={pickerValue}
            weekStart={weekStart}
            use24HourTime={use24HourTime}
            precision={precision}
            minuteStep={minuteStep}
            showing={popoverVisible}
            showDate={showDate}
            showTime={showTime}
            defaultInputMode={defaultInputMode}
          />
        </Popover.Body>
        <Popover.Footer date-testid="plex-datetimepicker-buttons">
          <CancelLink onClick={cancelPick} data-testid="plex-datetimepicker-cancel-link" />
          <OkButton onClick={() => handleValueChange(pickerValue)} data-testid="plex-datetimepicker-ok-button" />
        </Popover.Footer>
      </Popover>
    );

    return (
      <AdornedInput
        as={ChipInput}
        adornment={buttonWithPopover}
        disabled={disabled}
        value={inputValue}
        onChange={(e: FormEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
        onBlur={handleUserText}
        onKeyDown={handleKeyDown}
        ref={ref}
        {...other}
      >
        {value && (
          <Chip closable={!disabled} onClose={() => handleValueChange(null)}>
            {formattedDate}
          </Chip>
        )}
      </AdornedInput>
    );
  }
);
HtmlDateTimeInput.displayName = "DateTimeInput";

const XmlDateTimeInput: FunctionComponent<IDateTimeInputProps> = ({ value }) => {
  return createElement("plex-control-datepicker", {}, String(value || ""));
};

export const DateTimeInput = withPrinting(HtmlDateTimeInput, XmlDateTimeInput);
