import React, {
  ReactElement,
  createElement,
  FunctionComponent,
  useState,
  useLayoutEffect,
  forwardRef,
  PropsWithChildren
} from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { isPromise, mergeIfDifferent } from "@utils";
import { ICommonProps } from "@components/Common.types";
import { GridState, IDataTableProps } from "./DataTable.types";
import { DataTableStateProvider, useDataTableSelector } from "./DataTableStateProvider";
import { DataTableBody } from "./DataTableBody";
import { DataTableHeader } from "./DataTableHeader";
import { DataTableRow, DataTableHeaderRow } from "./DataTableRow";
import {
  DataTableCell,
  DataTableHeaderCell,
  DataTableSelectionCell,
  DataTableHeaderSelectionCell
} from "./DataTableCell";
import { DataTableCaption } from "./DataTableCaption";
import styles from "./DataTable.module.scss";
import {
  DataTableGroup,
  DataTableGroupBody,
  DataTableGroupHeaderRow,
  DataTableGroupFooterRow
} from "./DataTableGrouping";
import { DataTableFooter, DataTableFooterRow } from "./DataTableFooter";
import { DataTableColGroup } from "./DataTableColGroup";
import { useInXmlControlSection } from "../XmlControlSection";
import { RankableDataTableHeaderCell, RankableDataTableRow } from "./RankableComponents";

export interface IDataTable {
  <T extends {}>(props: PropsWithChildren<IDataTableProps<T>>): ReactElement;
  /** The caption section used to display an empty message */
  Caption: typeof DataTableCaption;
  /** The header section */
  Header: typeof DataTableHeader;
  /** The body section */
  Body: typeof DataTableBody;
  /** A header row - should be used within DataTable.Header */
  HeaderRow: typeof DataTableHeaderRow;
  /** A body row - should be used within DataTable.Body */
  Row: typeof DataTableRow;
  /** A body row that can be dragged to support ranking - should be used within DataTable.Body */
  RankableRow: typeof RankableDataTableRow;
  /** A header cell which includes a placeholder for the rankable cell. Should be placed in first position of header. */
  HeaderRankableCell: typeof RankableDataTableHeaderCell;
  /** A cell - should be used within DataTable.HeaderRow */
  HeaderCell: typeof DataTableHeaderCell;
  /** A cell - should be used within DataTable.Row */
  Cell: typeof DataTableCell;
  /**
   * A selection cell for multiple selection mode
   * Should be used within DataTable.row
   */
  SelectionCell: typeof DataTableSelectionCell;
  /**
   * A selection cell for multiple selection mode
   * Should be used within a DataTable.HeaderRow
   */
  HeaderSelectionCell: typeof DataTableHeaderSelectionCell;
  /**
   * A component which defines a grouping of data.
   * Should be used instead of DataTable.Body, with a
   * child DataTable.GroupBody. (Groups can be nested.)
   */
  GroupBy: typeof DataTableGroup;
  /**
   * Component which renders the rows of a group. Should
   * be used inside of a DataTable.GroupBy component.
   */
  GroupBody: typeof DataTableGroupBody;
  /**
   * Component which renders a group header row. Should
   * be used inside of a DataTable.GroupBy component.
   */
  GroupHeaderRow: typeof DataTableGroupHeaderRow;
  /**
   * Component which renders a group footer row. Should
   * be used inside of a DataTable.GroupBy component.
   */
  GroupFooterRow: typeof DataTableGroupFooterRow;
  /**
   * Component which renders a footer (tfoot).
   */
  Footer: typeof DataTableFooter;
  /**
   * Component which renders a footer row. Should
   * be used inside of a Datatable.Footer component.
   */
  FooterRow: typeof DataTableFooterRow;
}

const TABLE_STYLE_SELECTOR = (s: GridState<unknown>) => s.tableStyle;
const Table = forwardRef<HTMLTableElement, PropsWithChildren<ICommonProps>>(
  ({ id, style, children, className }, ref) => {
    const stateStyle = useDataTableSelector(TABLE_STYLE_SELECTOR);
    const tableStyle = mergeIfDifferent(style, stateStyle);

    return (
      <table id={id} ref={ref} className={clsx(styles.grid, className)} style={tableStyle} data-testid="plex-grid">
        <DataTableColGroup />
        {children}
      </table>
    );
  }
);
Table.displayName = "Table";

/** Component which renders data within a table. */
const HtmlDataTable = <T extends {}>({
  actionSection,
  id,
  containerClassName,
  className,
  style,
  children,
  ...other
}: PropsWithChildren<IDataTableProps<T>>): ReactElement => {
  const containerClasses = clsx(
    styles.gridContainer,
    actionSection && styles.gridWithActionSection,
    containerClassName
  );

  return (
    <>
      <div className={containerClasses} data-testid="plex-grid-container">
        <DataTableStateProvider<T> {...other}>
          <Table id={id} style={style} className={className}>
            {children}
          </Table>
        </DataTableStateProvider>
      </div>
      {actionSection}
    </>
  );
};

const XmlDataTable = <T extends {}>({
  children,
  data,
  keySelector
}: PropsWithChildren<IDataTableProps<T>>): ReactElement => {
  const insideControlSection = useInXmlControlSection();
  const [localData, setLocalData] = useState(isPromise(data) ? [] : data);

  useLayoutEffect(() => {
    if (isPromise(data)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      data.then(setLocalData);
    }
  }, [data]);

  let table: React.ReactNode;
  if (localData.length === 0) {
    // only show "plex-grid-table" node when data exists
    table = children;
  } else {
    table = createElement("plex-grid-table", {}, children);
  }

  let grid = createElement("plex-grid", {}, table);
  if (insideControlSection) {
    grid = createElement("plex-control-grid", {}, grid);
  }

  return (
    <DataTableStateProvider<T> data={data} keySelector={keySelector}>
      {grid}
    </DataTableStateProvider>
  );
};

export const DataTable = withPrinting(HtmlDataTable, XmlDataTable as FunctionComponent) as IDataTable;

// namespace child components
DataTable.Caption = DataTableCaption;
DataTable.Header = DataTableHeader;
DataTable.Body = DataTableBody;
DataTable.HeaderRow = DataTableHeaderRow;
DataTable.Row = DataTableRow;
DataTable.RankableRow = RankableDataTableRow;
DataTable.HeaderRankableCell = RankableDataTableHeaderCell;
DataTable.HeaderCell = DataTableHeaderCell;
DataTable.Cell = DataTableCell;
DataTable.SelectionCell = DataTableSelectionCell;
DataTable.HeaderSelectionCell = DataTableHeaderSelectionCell;
DataTable.GroupBy = DataTableGroup;
DataTable.GroupBody = DataTableGroupBody;
DataTable.GroupHeaderRow = DataTableGroupHeaderRow;
DataTable.GroupFooterRow = DataTableGroupFooterRow;
DataTable.Footer = DataTableFooter;
DataTable.FooterRow = DataTableFooterRow;
