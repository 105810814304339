import React from "react";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { chainHandlers } from "@utils";
import { TextInput } from "../../Input/TextInput";
import { IInputProps } from "../../Input/Input.types";
import * as globalStyles from "../../../global-styles";
import { FormikField } from "./Formik.types";

export const FormikTextInput: FormikField<IInputProps> = ({ name, onChange, onBlur, className, ...other }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.errors?.[name];
        return (
          <TextInput
            name={field.name}
            value={field.value}
            className={clsx(className, hasError && globalStyles.hasErrors)}
            onChange={chainHandlers(field.onChange, onChange)}
            onBlur={chainHandlers(field.onBlur, onBlur)}
            {...other}
          />
        );
      }}
    </Field>
  );
};
