import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { ZoomInIcon, ZoomOutIcon } from "@plex/icons";
import { TranslatedText, useLocalization } from "@plex/culture-react";
import styles from "./ImageResizerWidget.module.scss";

const resizeOptions = { percentResize: 100, percentIncrement: 5 };

export interface IImageResizerWidgetProps {
  /** Image Reference DOM object */
  imageRef?: React.RefObject<HTMLImageElement | undefined>;
  /** Flag to disable show/hide resize widget image control */
  disabled?: boolean;
}

type Dimensions = {
  height: number;
  width: number;
};

export const ImageResizerWidget: FunctionComponent<IImageResizerWidgetProps> = ({ imageRef, disabled = false }) => {
  let layout = disabled ? {} : ({ position: "absolute" } as React.CSSProperties);
  const [imageRefState, setImageRefState] = useState<HTMLImageElement | undefined | null>();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [percent, setPercent] = useState(resizeOptions.percentResize);
  const [originalSizes, setOriginalSizes] = useState<Dimensions>({ height: 0, width: 0 });
  const resizeWidgetRef = useRef<HTMLDivElement>(null);
  const [showControl, setShowControl] = useState(false);
  const { t } = useLocalization();

  useEffect(() => {
    const imageReference = imageRef?.current;
    const resizeWidgetReference = resizeWidgetRef?.current;

    const initalizeValues = () => {
      if (imageReference) {
        setOriginalSizes({
          height: imageReference.naturalHeight,
          width: imageReference.naturalWidth
        });

        setHeight(imageReference.naturalHeight);
        setWidth(imageReference.naturalWidth);
        setPercent(resizeOptions.percentResize);
      }
    };

    const handleImageLoadEvent = () => {
      initalizeValues();
    };

    const handleImageMouseEnterEvent = () => {
      if (!showControl) {
        setShowControl(true);
      }
    };

    const handleImageMouseLeaveEvent = () => {
      setShowControl(false);
    };

    const handleDivMouseEnterEvent = () => {
      setShowControl(true);
    };

    if (imageReference && !imageRefState && resizeWidgetReference) {
      // re-intialize when an image gets loaded
      imageReference.addEventListener("load", handleImageLoadEvent);

      if (disabled) {
        setShowControl(true);
      } else {
        imageReference.addEventListener("mouseenter", handleImageMouseEnterEvent);

        imageReference.addEventListener("mouseleave", handleImageMouseLeaveEvent);

        resizeWidgetReference.addEventListener("mouseenter", handleDivMouseEnterEvent);
      }

      setImageRefState(imageReference);
      initalizeValues();
    }

    // clean up
    return () => {
      if (imageRefState && resizeWidgetReference) {
        imageRefState.removeEventListener("load", handleImageLoadEvent);

        if (!disabled) {
          imageRefState.removeEventListener("mouseenter", handleImageMouseEnterEvent);

          imageRefState.removeEventListener("mouseleave", handleImageMouseLeaveEvent);

          resizeWidgetReference.removeEventListener("mouseenter", handleDivMouseEnterEvent);
        }
      }
    };
  }, [imageRef, imageRefState, showControl, resizeWidgetRef, disabled]);

  const calculateHeight = (currentPerc: number) => {
    return Math.round((currentPerc * originalSizes.height) / 100);
  };

  const calculateWidth = (currentPerc: number) => {
    return Math.round((currentPerc * originalSizes.width) / 100);
  };

  const handleZoomInEvent = () => {
    if (imageRefState) {
      const newPerc = percent + resizeOptions.percentIncrement;
      setPercent(newPerc);
      const newHeight = calculateHeight(newPerc);
      const newWidth = calculateWidth(newPerc);
      imageRefState.height = newHeight;
      imageRefState.width = newWidth;
      setHeight(newHeight);
      setWidth(newWidth);
    }
  };

  const handleZoomOutEvent = () => {
    if (imageRefState) {
      const newPerc = percent - resizeOptions.percentIncrement;
      setPercent(newPerc);
      const newHeight = calculateHeight(newPerc);
      const newWidth = calculateWidth(newPerc);
      imageRefState.height = newHeight;
      imageRefState.width = newWidth;
      setHeight(newHeight);
      setWidth(newWidth);
    }
  };

  const handlePercChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.currentTarget.value);
    if (typeof val === "number" && imageRefState && !isNaN(val) && isFinite(val) && Math.sign(val) === 1) {
      setPercent(val);
      const newHeight = calculateHeight(val);
      const newWidth = calculateWidth(val);
      imageRefState.height = newHeight;
      imageRefState.width = newWidth;
      setHeight(newHeight);
      setWidth(newWidth);
    }
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.currentTarget.value);
    if (typeof val === "number" && imageRefState && !isNaN(val) && isFinite(val) && Math.sign(val) === 1) {
      const newPerc = Math.round((val / originalSizes.height) * 100);
      imageRefState.height = val;
      setHeight(val);
      setPercent(newPerc);
    }
  };

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.currentTarget.value);
    if (typeof val === "number" && imageRefState && !isNaN(val) && isFinite(val) && Math.sign(val) === 1) {
      const newPerc = Math.round((val / originalSizes.width) * 100);

      imageRefState.width = val;
      setWidth(val);
      setPercent(newPerc);
    }
  };

  if (!showControl) {
    layout = { ...layout, display: "none" };
  }

  return (
    <div style={layout} ref={resizeWidgetRef}>
      <div className={styles.imageResizerWidget}>
        <a title={t("ui-common-fileUpload-imageResizerWidgetZoomIn")} onClick={handleZoomInEvent}>
          <ZoomInIcon className={styles.zoomInButtonColor} />
        </a>
        <a title={t("ui-common-fileUpload-imageResizerWidgetZoomOut")} onClick={handleZoomOutEvent}>
          <ZoomOutIcon className={styles.zoomOutButtonColor} />
        </a>
        <input
          type="text"
          value={percent}
          onChange={handlePercChange}
          className={styles.imageResizerWidgetTextControl}
        />
        <label className={styles.imageResizerWidgetLabelControl}>%,&nbsp;</label>
        <label className={styles.imageResizerWidgetLabelControl}>
          <TranslatedText messageId="ui-common-fileUpload-imageResizerWidgetSize" /> :&nbsp;
        </label>
        <input
          type="text"
          value={width}
          onChange={handleWidthChange}
          className={styles.imageResizerWidgetTextControl}
        />
        <label className={styles.imageResizerWidgetLabelControl}> x </label>
        <input
          type="text"
          value={height}
          onChange={handleHeightChange}
          className={styles.imageResizerWidgetTextControl}
        />
      </div>
    </div>
  );
};
