import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { CommentBox } from "./CommentBox";
import { AnchorArea } from "../../Util/AnchorUtil";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CommentNode",
  category: NodeTypeCategory.general,
  unconnectedAnchorLocation: AnchorArea.Top,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [],
  nodeConfigProperties: [{ name: "comment", propertyType: NodeConfigPropertyType.Text, outsideFormEdit: true }],
  size: NodeSize.Large,
  confirmDeletion: true,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CommentNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return (
    <BasicNodeLayout id={id} data={data}>
      <CommentBox id={id} nodeProperties={data.nodeProperties} />
    </BasicNodeLayout>
  );
};
