import { createContext } from "react";

export type PrintContextValue = {
  isPrinting: boolean;
  waitFor: (promise: Promise<never>) => void;
};

// Any additional context can be provided as well
export const PrintContext = createContext<PrintContextValue>({
  isPrinting: false,
  waitFor: () => {}
});
