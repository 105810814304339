import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType, primitiveTypes } from "../TypeDefinitions";
import { loopIterationStart } from "../../Runtime/RuntimeExecution";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const listInputName = "list";
const itemOutputName = "item";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ForEachNode",
  category: NodeTypeCategory.lists,
  label: "Loop",
  controlInputs: [{ name: "begin" }],
  controlOutputs: [{ name: "done" }, { name: "each", startsLoop: true }],
  dataInputs: [{ name: listInputName, type: DataType.OBJECTLIST }],
  dataOutputs: [{ name: itemOutputName, type: DataType.OBJECT }],
  listToItem: true,
  size: NodeSize.Small,
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataTypes: [DataType.OBJECT].concat(primitiveTypes), dataProperties: [listInputName, itemOutputName] }
    }
  ],
  evaluate: function (
    input: { [name: string]: string | number | boolean | object | object[] },
    nodeProperties?: any
  ): { output: { [name: string]: string | number | boolean | object | object[] }; activeControlHandleIds: string[] } {
    let activeControl = "each";

    loopIterationStart();

    if (!nodeProperties.itemIndex) {
      nodeProperties.itemIndex = 0;
    }

    if (nodeProperties.breakLoop || nodeProperties.itemIndex >= (input.list as object[]).length) {
      activeControl = "done";
      delete nodeProperties.itemIndex;
    }

    return {
      output: { item: (input.list as object[])[nodeProperties.itemIndex++] as unknown as any },
      activeControlHandleIds: [activeControl]
    };
  }
});

export const ForEachNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
