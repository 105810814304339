import React, { FunctionComponent } from "react";
import { useReactFlow } from "reactflow";
import { Dropdown } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export const StopTypeFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });
  if (props.node?.data.nodeProperties.type === "stop") {
    return (
      <div key="Stop Type Div" style={{ display: "flex" }}>
        <Dropdown
          style={{ verticalAlign: "top", marginRight: "10px" }}
          items={[
            { key: "Success", value: "Success" },
            { key: "Failure", value: "Failure" }
          ]}
          keySelector={(item: { [key: string]: string }) => item.key}
          displaySelector={(item: { [key: string]: string }) => item.value}
          selected={props.node!.data.nodeProperties[props.name]}
          onSelectionChanged={(values) => {
            let hadValue =
              props.node!.data.nodeProperties[props.name] && props.node!.data.nodeProperties[props.name].length > 0;
            const valueChanged =
              props.node!.data.nodeProperties[props.name] &&
              props.node!.data.nodeProperties[props.name][0].key !== values[0].key;
            props.node!.data.nodeProperties[props.name] = values;
            if (hadValue) {
              if (valueChanged) {
                reactFlowInstance.setEdges((edges) =>
                  edges.filter(
                    (e) =>
                      e.source !== props.node!.id ||
                      (e.sourceHandle?.indexOf("FlowInHandle") !== 0 &&
                        e.sourceHandle?.indexOf("FlowErrorInHandle") !== 0)
                  )
                );
              }
              setState(props.node!.data.nodeProperties[props.name]);
            }
            props.forceNodeUpdate();
          }}
        />
      </div>
    );
  }

  return <></>;
};
