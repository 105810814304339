import React, { FunctionComponent, useState, useRef, useMemo, ChangeEvent, DragEvent } from "react";
import { useLocalization } from "@plex/culture-react";
import { ICommonProps } from "../Common.types";
import styles from "./FileUpload.module.scss";
import { Button } from "../Button";
import { ImageResizerWidget } from "./ImageResizerWidget";

const BYTES_IN_MEGABYTE = 1048576;
const UPLOAD_SIZE_LIMIT = 1073741824;

export interface IFileUploadProps extends ICommonProps {
  /** Name of the input element */
  name?: string;
  /** Does the component allow for multiple files */
  allowMultiple?: boolean;
  /** The maximum file size in Megabytes */
  maxFileSizeMByte?: number;
  /** The allowed file types */
  allowedFileTypes?: String[];
  /** The Callback fired when files are successfully Uploaded */
  onFilesUploaded: (fileList: File[]) => void;
  /** The Callback fired when files are not successfully Uploaded */
  onInvalidFilesUploaded: (fileList: File[]) => void;
  /** The Callback fired when files are have more than max file size limit */
  onMaxFileSizeUploadLimit: (fileList: File[]) => void;
  /** Should a Preview been shown? */
  previewImage?: boolean;
}

export const FileUpload: FunctionComponent<IFileUploadProps> = ({
  name,
  allowMultiple = true,
  maxFileSizeMByte = UPLOAD_SIZE_LIMIT,
  previewImage = false,
  allowedFileTypes = [],
  onFilesUploaded,
  onInvalidFilesUploaded,
  onMaxFileSizeUploadLimit,
  ...other
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useLocalization();
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [maxFileSizeUploadVoliation, setMaxFileSizeUploadVoliation] = useState(false);
  const [draggingClass, setDraggingClass] = useState(styles.fileDragDropArea);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewedFile, setPreviewedFile] = useState<File[]>([]);
  const imageRef = useRef<HTMLImageElement>(null);

  const fileTypes = useMemo(() => {
    if (allowedFileTypes.length > 0) {
      const typeStr: string[] = [];
      allowedFileTypes.forEach(fileType => {
        typeStr.push(fileType.replace("*", ""));
      });
      return typeStr;
    } else {
      return [];
    }
  }, [allowedFileTypes]);

  const fileTypeRegex = useMemo(() => {
    if (fileTypes.length > 0) {
      return new RegExp("([a-zA-Z0-9-_(s*):])+(" + fileTypes.join("|") + ")$", "i");
    } else {
      return /(.*?)$/;
    }
  }, [fileTypes]);

  const dragOver = (e: DragEvent) => {
    setDraggingClass(styles.fileDragDropAreaDragging);
    e.preventDefault();
  };

  const dragLeave = (e: DragEvent) => {
    setDraggingClass(styles.fileDragDropArea);
    e.preventDefault();
  };

  const forwardClickToInputElement = () => {
    fileInputRef.current?.click();
  };

  const resetComponent = () => {
    setImagePreviewUrl("");
    setFileUploaded(false);
    setSelectedFiles([]);
    setPreviewedFile([]);
  };

  const uploadImagePreview = () => {
    if (previewedFile.length > 0) {
      onFilesUploaded(previewedFile);
    }
    resetComponent();
  };

  const validateMaxFileSize = (file: File) => {
    const mbSize = Math.round(file.size / BYTES_IN_MEGABYTE);
    if (file.size > UPLOAD_SIZE_LIMIT) {
      return true;
    } else if (mbSize > maxFileSizeMByte) {
      return true;
    }

    return false;
  };

  const validateFileExtension = (file: File) => {
    if (allowedFileTypes.length > 0) {
      // check file type allowed "*.csv", "*.doc"
      if (!fileTypeRegex.exec(file.name)) {
        return false;
      }
    }
    return true;
  };

  const handleFileUploads = (uploadedFiles: File[]) => {
    const names: string[] = [];
    const validatedFiles: File[] = [];
    const invalidFiles: File[] = [];
    const maxLimitReachFiles: File[] = [];
    uploadedFiles.forEach(file => {
      const fileAllowed = validateFileExtension(file);
      const isOverSizeFile = validateMaxFileSize(file);
      let limitReached = false;

      // only allow 1 file if allowMultiple is false
      if (!allowMultiple && validatedFiles.length > 0) {
        limitReached = true;
      }

      if (fileAllowed && !isOverSizeFile) {
        if (!limitReached) {
          names.push(file.name);
          validatedFiles.push(file);
        }
      } else {
        if (isOverSizeFile) {
          maxLimitReachFiles.push(file);
        }
        names.push(file.name);
        invalidFiles.push(file);
      }

      if (previewImage && validatedFiles.length > 0) {
        setImagePreviewUrl(URL.createObjectURL(validatedFiles[0]));
        setPreviewedFile(validatedFiles);
      }
    });

    if (invalidFiles.length > 0) {
      setFileUploaded(false);
    } else if (validatedFiles.length > 0) {
      setFileNames(names);
      setFileUploaded(names.length > 0);
    }

    if (!previewImage) {
      if (maxLimitReachFiles.length > 0) {
        onMaxFileSizeUploadLimit(maxLimitReachFiles);
        setMaxFileSizeUploadVoliation(true);
        setFileNames(names);
      } else if (invalidFiles.length > 0) {
        onInvalidFilesUploaded(invalidFiles);
      } else if (validatedFiles.length > 0) {
        onFilesUploaded(validatedFiles);
      }
    }
    // If any file fails don't fire event for files uploaded but rather invalid files uploaded
  };

  const fileSelected = (ie: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles: File[] = ie.target.files == null ? [] : Array.from(ie.target.files);
    handleFileUploads(uploadedFiles);
  };

  const fileDrop = (e: DragEvent) => {
    e.preventDefault();
    setDraggingClass(styles.fileDragDropArea);
    const uploadedFiles: File[] = e.dataTransfer.files == null ? [] : Array.from(e.dataTransfer.files);
    handleFileUploads(uploadedFiles);
  };

  return (
    <div {...other} className={styles.fileUpload}>
      <input
        className={styles.fileInputHidden}
        name={name}
        value={selectedFiles}
        ref={fileInputRef}
        data-testid="choose-file"
        multiple={allowMultiple}
        accept={fileTypes.join(",")}
        type="file"
        onChange={fileSelected}
      />
      <div className={styles.fileUploadSection}>
        <input
          data-testid="file-upload-input"
          type="button"
          value={t("ui-common-fileUpload-fileSelect")}
          onClick={forwardClickToInputElement}
        />
        {!imagePreviewUrl && (fileUploaded || maxFileSizeUploadVoliation) ? (
          fileNames.map(item => {
            return <div key={item}>{item}</div>;
          })
        ) : (
          <span className={styles.filenames}>{t("ui-common-fileUpload-noFileSelected")}</span>
        )}
        {imagePreviewUrl && (
          <Button data-testid="upload-file" onClick={uploadImagePreview} disabled={false}>
            {t("ui-common-fileUpload-fileUpload")}
          </Button>
        )}
      </div>
      <div
        data-testid="fileDrop"
        className={draggingClass}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <div>{t("ui-common-fileUpload-draggableArea")}</div>
      </div>
      {imagePreviewUrl && (
        <div>
          {fileUploaded && (
            <div>
              {fileNames[0]}{" "}
              <a data-testid="remove-file" className={styles.fileUploadRemoveLink} onClick={resetComponent}>
                {t("ui-common-fileUpload-fileRemove")}
              </a>
            </div>
          )}
          <div>
            <ImageResizerWidget imageRef={imageRef} />
            <img data-testid="file-image" src={imagePreviewUrl} className="image-upload" ref={imageRef} />
          </div>
        </div>
      )}
    </div>
  );
};
