import React, { cloneElement, FC, forwardRef, isValidElement, Ref } from "react";
import clsx from "clsx";
import {
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { forkRef, mergeIfDifferent } from "@utils";
import { ITableRowProps } from "./DataTable.types";
import { DataTableRow } from "./DataTableRow";
import styles from "./DataTable.module.scss";
import { DataTableCell, DataTableHeaderCell } from "./DataTableCell";
import { useDataRowContext } from "./DataTableBody";
import { useFixedColumnSizes } from "./Plugins/DataTableFixedLayout.hooks";
import { useIsRankingRecord } from "./Plugins/DataRanking.hooks";
import { ICommonProps } from "../Common.types";

const RankableHandle: FC<Partial<DraggableProvidedDragHandleProps>> = props => (
  <div className={styles.rowRankingHandle} {...props} />
);

interface IRankableHeaderCellProps extends ICommonProps {
  /** The column span for the header cell */
  colSpan?: number;
  /** The row span for the header cell */
  rowSpan?: number;
}

export const RankableDataTableHeaderCell: FC<IRankableHeaderCellProps> = ({ className, ...other }) => {
  return (
    <DataTableHeaderCell className={clsx(styles.rowRankingCell, className)} {...other}>
      <RankableHandle />
    </DataTableHeaderCell>
  );
};

export const RankableDataTableRow = forwardRef<HTMLTableRowElement, ITableRowProps>(
  ({ className, children, ...other }, ref) => {
    const rowContext = useDataRowContext<unknown>();
    const columnSizes = useFixedColumnSizes();

    const rankingKey = String(rowContext.rowInfo.key);
    const ranking = useIsRankingRecord(rankingKey);

    let cells = children;
    if (ranking && columnSizes.length > 0) {
      // Apply width to columns - the draging plugin will set them as fixed,
      // which will cause them to use their inherited width. (This is why the
      // plugin requires fixed layout.)
      cells = React.Children.toArray(children).map((cell, i) => {
        // need to offset by one to skip the drag handle cell
        const width = columnSizes[i + 1];
        if (width && isValidElement(cell)) {
          const style = mergeIfDifferent(cell.props.style, { width });
          return cloneElement(cell, { style });
        }
        return cell;
      });
    }

    return (
      <Draggable key={rowContext.rowInfo.key} draggableId={rankingKey} index={rowContext.index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <DataTableRow
            // eslint-disable-next-line @typescript-eslint/unbound-method
            ref={forkRef(ref, provided.innerRef as Ref<HTMLTableRowElement>)}
            className={clsx(className, snapshot.isDragging && styles.rowIsRanking)}
            {...other}
            {...provided.draggableProps}
          >
            <DataTableCell className={styles.rowRankingCell}>
              <RankableHandle {...provided.dragHandleProps} />
            </DataTableCell>
            {React.Children.map(cells, child => {
              return isValidElement(child)
                ? // we may want to enable opting out of this behavior - for now, allow overrides
                  cloneElement(child, { ...provided.dragHandleProps, ...child.props })
                : child;
            })}
          </DataTableRow>
        )}
      </Draggable>
    );
  }
);
RankableDataTableRow.displayName = "RankableDataTableRow";
