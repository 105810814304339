import React, { FunctionComponent, useState } from "react";
import { useLocalization } from "@plex/culture-react";
import clsx from "clsx";
import { ICommonProps, MaybeAsync } from "../Common.types";
import { Image } from "../Image";
import { useAsync } from "../../hooks";
import { IImage } from "./ImagePicker.types";
import styles from "./ImagePicker.module.scss";

export interface IImagePickListProps extends ICommonProps {
  selectedImage: IImage;
  data: MaybeAsync<IImage[]>;
  onSelectionChanged: (image: IImage) => void;
}

export const ImageModalBody: FunctionComponent<IImagePickListProps> = ({
  selectedImage,
  onSelectionChanged,
  data,
  ...other
}) => {
  const { t } = useLocalization();
  const [showSelected, setShowSelected] = useState(false);
  const [images] = useAsync(data, []);

  const handleSelection = (image: IImage) => {
    onSelectionChanged(image);
    setShowSelected(!!image.key);
  };

  return (
    <div {...other}>
      <div className={styles.picklist}>
        {showSelected && (
          <div className={styles.picklistSelected}>
            <div className={styles.picklistPaddedText}>{t("ui-common-imagePicker-heading-selectedImage")}</div>
            <div className={styles.picklistSelectWrapper}>
              <div className={styles.picklistSelect}>
                {selectedImage.imageUrl && <Image src={selectedImage.imageUrl} alt={selectedImage.alternateText} />}
              </div>
            </div>
          </div>
        )}
        <div>
          <div className={styles.picklistPaddedText}>{t("ui-common-imagePicker-heading-selectImage")}</div>
          <div className={styles.picklistItemRow}>
            {images.map(item => {
              const isSelected = item.key === selectedImage.key;
              return (
                <div
                  key={item.key}
                  className={clsx(styles.picklistItemSelect, isSelected && styles.picklistItemSelected)}
                >
                  <div
                    className={clsx(styles.picklistItem, styles.picklistItemHover)}
                    onClick={() => handleSelection(item)}
                  >
                    {item.imageUrl && <Image src={item.imageUrl} alt={item.alternateText} />}
                    {item.name && <span>{item.name}</span>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
