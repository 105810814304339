import React, { FunctionComponent, MouseEventHandler } from "react";
import { ArrowsExpandIcon, CloseIcon } from "@plex/icons";
import clsx from "clsx";
import { ICommonProps, IWithChildren } from "../Common.types";
import { ResizableContainer, IResizeInfo } from "../ResizableContainer/ResizableContainer";
import styles from "./SidePanel.module.scss";

export interface ISidePanelProps extends ICommonProps, IWithChildren {
  /* Side panel title */
  title: React.ReactNode;

  /* Handler for close button events */
  onClose?: MouseEventHandler;

  /* Handler for collapse button events */
  onCollapse?: MouseEventHandler;

  /** Invoke when component resized */
  onResize?: (resizeInfo: IResizeInfo) => void;

  /** The class name to apply to the resizable container */
  containerClassName?: string;

  /* The side of the screen that the component is attached to */
  mountSide?: MountSide;
}

export enum MountSide {
  left = "left",
  right = "right"
}

export const SidePanel: FunctionComponent<ISidePanelProps> = ({
  title,
  onClose,
  onCollapse,
  onResize,
  children,
  className,
  containerClassName,
  mountSide = MountSide.left,
  ...others
}) => {
  return (
    <ResizableContainer
      useCorner={false}
      disableBottom
      disableRight={mountSide === MountSide.right}
      disableLeft={mountSide === MountSide.left}
      onResize={onResize}
      className={clsx(styles.resizableContainer, containerClassName)}
    >
      <div className={clsx(styles.base, className)} {...others}>
        <header className={styles.header}>
          <div
            className={mountSide === MountSide.left ? styles.flippedCollapseIcon : styles.collapseIcon}
            onClick={onCollapse}
          >
            <ArrowsExpandIcon />
          </div>
          <div className={styles.headerTitle}>{title}</div>
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseIcon />
          </div>
        </header>
        <section className={styles.container}>
          <div className={styles.content}>{children}</div>
        </section>
      </div>
    </ResizableContainer>
  );
};
