import React, { FunctionComponent } from "react";
import { Handle, Position } from "reactflow";

type IErrorControlHandle = {
  text: string;
  handleKey: string;
  topPosition: number;
  dataHandleOutput: any;
  index: number;
};

export const ErrorControlHandle: FunctionComponent<IErrorControlHandle> = ({
  text,
  topPosition,
  handleKey,
  dataHandleOutput,
  index
}) => {
  const errorHandleTopPositionValue = `${topPosition}px`;
  // const errorDataTopPositionValue = `${topPosition + 17}px`
  // const output = dataHandleOutput?.length > 0 ? dataHandleOutput[0] : null;

  return (
    <div>
      <label style={{ color: "#ED1C24", fontSize: "smaller", marginTop: 24 * index - 14 + "px" }} title={text}>
        {text}
      </label>
      <Handle
        id={handleKey}
        position={Position.Right}
        type="target"
        className="api-error-flow-node-handle"
        style={{ right: "-8px", top: errorHandleTopPositionValue }}
      />
    </div>
  );
};
