import React, { FunctionComponent } from "react";
import { Checkbox } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface ICheckboxProps extends INodeConfigPropertiesProps {
  label: string;
}

export const CheckboxFormField: FunctionComponent<ICheckboxProps> = (props) => {
  let [, setState] = React.useState(false);

  return (
    <Checkbox
      key={`${props.sectionName} ${props.label} Input`}
      checked={props.node?.data.nodeProperties[props.name]}
      onValueChange={(checked) => {
        props.node!.data.nodeProperties[props.name] = checked;
        setState(checked);
        props.forceNodeUpdate();
      }}
    />
  );
};
