import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import { Portal } from "../Dialog/Portal";
import { Spinner } from "./Spinner";
import styles from "./Overlay.module.scss";

interface IOverlayProps extends ICommonProps {
  /** Should the overlay spinner image show */
  showSpinner?: boolean;
  /** The message overlay */
  message?: ReactNode;
  /** The overlay theme */
  theme?: "dark" | "white";
}

let current: {} | null = null;

/** Component which renders an overlay of the whole window */
export const Overlay: FC<IOverlayProps> = ({ showSpinner = true, message, className, theme = "dark", ...other }) => {
  const [show, setShow] = useState(true);
  const pointerRef = useRef({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const pointer = pointerRef.current;
    if (current && pointer !== current) {
      setShow(false);
      return undefined;
    }

    current = pointer;
    return () => {
      if (current === pointer) {
        current = null;
      }
    };
  });

  if (!show) {
    return null;
  }

  const showMessage = !!message;
  const displayTheme = theme === "dark" && styles.overlayTransitionDark;
  return (
    <Portal>
      <div
        data-testid="overlay-wrapper"
        className={clsx(styles.overlayFull, styles.overlayFullShadow, displayTheme, className)}
        {...other}
      />
      {(showMessage || showSpinner) && (
        <div data-testid="overlay-wrapper-ui" className={styles.overlayFull} {...other}>
          <div>
            {showMessage && (
              <div data-testid="plex-waiting-message" className={styles.overlayFullCenter}>
                <div className={styles.overlayMessage}>{message}</div>
              </div>
            )}
            {showSpinner && (
              <div data-testid="plex-waiting-spinner" className={styles.overlayFullCenter}>
                <Spinner type="gear" />
              </div>
            )}
          </div>
        </div>
      )}
    </Portal>
  );
};
