import React, { useContext, useMemo, createContext, ReactElement, cloneElement, createElement, memo } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { useUid } from "@hooks";
import { noop } from "@utils";
import { IWithChildren } from "../Common.types";
import { ITableBodyProps, RowInfo, GridRecord, RowValueRenderer } from "./DataTable.types";
import { useRowSelection } from "./Plugins/DataSelection.hooks";
import { useDataTableData } from "./DataTable.hooks";
import { useVirtualizedWindow } from "./Plugins/DataVirtualization.hooks";
import { useIsRankingEnabled } from "./Plugins/DataRanking.hooks";

type DataRowState<T> = {
  row: T;
  index: number;
  rowInfo: RowInfo;
};

interface IDataRowContextProps<T> extends IWithChildren {
  record: GridRecord<T>;
}

export const DataRowContext = createContext({
  row: null,
  index: -1,
  rowInfo: {
    key: -1,
    selectionState: {
      selected: false,
      toggle: noop
    }
  }
} as DataRowState<unknown>);

// eslint-disable-next-line func-style
export function useDataRowContext<T>() {
  return useContext(DataRowContext) as DataRowState<T>;
}

// eslint-disable-next-line func-style
export function DataRowContextProvider<T>({ record, children }: IDataRowContextProps<T>) {
  const [selected, toggleSelection] = useRowSelection<T>(record);

  const value = useMemo(() => {
    return {
      row: record.value,
      index: record.index,
      rowInfo: {
        key: record.key,
        selectionState: {
          selected,
          toggle: toggleSelection
        }
      }
    };
  }, [record, selected, toggleSelection]);

  return <DataRowContext.Provider value={value}>{children}</DataRowContext.Provider>;
}

// eslint-disable-next-line func-style
function HtmlDataTableBody<T extends {}>({ children, ...other }: ITableBodyProps<T>) {
  const data = useDataTableData<T>();
  const win = useVirtualizedWindow<T>();
  const ranking = useIsRankingEnabled();
  const droppableId = useUid();

  const indexOffset = win?.start || 0;
  const windowedData = win ? data.slice(win.start, win.end) : data;
  const virtualizedTopRow = win?.virtualizedTopRow;
  const virtualizedBottomRow = win?.virtualizedBottomRow;

  if (ranking) {
    return (
      <Droppable droppableId={String(droppableId)}>
        {(droppableProvided: DroppableProvided) => (
          // eslint-disable-next-line @typescript-eslint/unbound-method
          <tbody {...other} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            <MemoizedTableBody
              data={windowedData}
              indexOffset={indexOffset}
              renderer={children as RowValueRenderer<unknown>}
            />
            {droppableProvided.placeholder}
          </tbody>
        )}
      </Droppable>
    );
  }

  return (
    <tbody {...other}>
      {virtualizedTopRow}
      <MemoizedTableBody
        data={windowedData}
        indexOffset={indexOffset}
        renderer={children as RowValueRenderer<unknown>}
      />
      {virtualizedBottomRow}
    </tbody>
  );
}

const MemoizedTableBody = memo(
  ({
    data,
    indexOffset,
    renderer
  }: {
    data: Array<GridRecord<unknown>>;
    indexOffset: number;
    renderer: RowValueRenderer<unknown>;
  }) => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {data.map((record, index) => (
          <DataTableRow key={record.key} record={record} rowIndex={index + indexOffset}>
            {renderer}
          </DataTableRow>
        ))}
      </>
    );
  },
  (a, b) => a.data === b.data && a.indexOffset === b.indexOffset
);
MemoizedTableBody.displayName = "MemoizedTableBody";

interface IMemoizedDataTableRowProps<T> {
  record: GridRecord<T>;
  rowIndex: number;
  children: RowValueRenderer<T>;
}

// eslint-disable-next-line func-style
function DataTableRow<T>({ record, rowIndex, children }: IMemoizedDataTableRowProps<T>) {
  return (
    <DataRowContextProvider key={record.key} record={record}>
      <DataRowContext.Consumer>
        {({ rowInfo }) => {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <>{children(record.value, rowIndex, rowInfo)}</>;
        }}
      </DataRowContext.Consumer>
    </DataRowContextProvider>
  );
}

const XmlDataTableBody = <T extends {}>({ children }: ITableBodyProps<T>) => {
  const data = useDataTableData<T>();
  if (data.length === 0) {
    return null;
  }

  const newChildren = data.map(record => {
    return cloneElement(
      children(record.value, record.index, {
        key: record.key,
        selectionState: {
          selected: false,
          toggle: noop
        }
      }) as ReactElement,
      { key: record.key }
    );
  });

  return createElement("grid-table-body", {}, newChildren);
};

type IDataTableBody = <T extends {}>(props: ITableBodyProps<T>) => ReactElement;

export const DataTableBody = withPrinting(HtmlDataTableBody, XmlDataTableBody) as IDataTableBody;
