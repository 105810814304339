import React, { FC, forwardRef } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import { useDataTableData } from "./DataTable.hooks";
import { ICommonProps, IWithChildren } from "../Common.types";

/** Component which renders a DataTable header section. */
const HtmlDataTableHeader = forwardRef<HTMLTableSectionElement, ICommonProps & IWithChildren>((props, ref) => (
  <thead ref={ref} {...props} />
));
HtmlDataTableHeader.displayName = "HtmlDataTableHeader";

const XmlDataTableHeader: FC = ({ children }) => {
  const data = useDataTableData();
  if (data.length === 0) {
    return null;
  }

  return React.createElement("grid-table-header", {}, children);
};

export const DataTableHeader = withPrinting(HtmlDataTableHeader, XmlDataTableHeader);
