import { uid } from "@hooks";
import React, { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import {
  IBannerContext,
  BannerContext,
  BannerStatus,
  BannerInstance,
  MessageListener,
  PlaceholderInstance
} from "./BannerContext";
import { ICommonProps } from "../Common.types";

/**
 * Defines a context for rendering banners. This should be combined with a
 * @see BannerPlaceholder which will render any banners in the given context.
 * This should wrap page or dialog content.
 */
export const BannerProvider: FC<PropsWithChildren<ICommonProps>> = ({ children }) => {
  const memoContext = useMemo<IBannerContext>(() => {
    let banners: BannerInstance[] = [];
    const listeners: MessageListener[] = [];
    const placeholders: PlaceholderInstance[] = [];

    const update = () => {
      listeners.forEach(func => func(banners));
    };

    return {
      get banners() {
        return banners;
      },
      reset() {
        if (banners.length > 0) {
          banners = [];
          update();
        }
      },
      addMessage(newMessage: ReactNode, newStatus: BannerStatus = BannerStatus.default) {
        const newBanner = {
          content: newMessage,
          status: newStatus
        };

        // Avoid memory leak by removing all other instances - If we
        // decide to support multiple banner instances this will need
        // to be changed
        banners = [newBanner];
        update();
        return newBanner;
      },
      removeMessage(banner: BannerInstance) {
        const index = banners.indexOf(banner);
        if (index !== -1) {
          // Adding/removing items in banners array doesn't re-render the
          // BannerPlaceholder component because the array technically
          // stayed the same. Creating new array would be recognized as
          // state change and will re-render BannerPlacholder component.
          const newBanners = [...banners];
          newBanners.splice(index, 1);
          banners = [...newBanners];
          update();
        }
      },
      addListener(listener: MessageListener) {
        listeners.push(listener);
        listener(banners);

        return () => {
          const index = listeners.indexOf(listener);
          if (index !== -1) {
            listeners.splice(index, 1);
          }
        };
      },
      placeholderRegistry: {
        set(id: uid) {
          const placeholder = {
            uid: id,
            // First placheholder would be registered as active
            active: placeholders.length === 0
          };

          placeholders.push(placeholder);

          return () => {
            const index = placeholders.indexOf(placeholder);
            if (index !== -1) {
              placeholders.splice(index, 1);
            }
          };
        },
        get(id: uid) {
          const placeholder = placeholders.find(x => x.uid === id);
          return placeholder || null;
        }
      }
    };
  }, []);

  return <BannerContext.Provider value={memoContext}>{children}</BannerContext.Provider>;
};
