import { RefObject, useState, useLayoutEffect } from "react";
import { getScrollableContainer } from "@utils";

export const useScrollableContainer = (ref: RefObject<HTMLElement>) => {
  const [container, setContainer] = useState<HTMLElement | Document>();

  useLayoutEffect(() => {
    if (ref.current) {
      setContainer(getScrollableContainer(ref.current));
    }
  }, [ref]);

  return container || null;
};
