import { createContext, ReactNode, useContext, MouseEventHandler, useState, useEffect, PropsWithChildren } from "react";
import { uid } from "@hooks";
import { ICommonProps } from "../Common.types";

export interface IBannerProps extends PropsWithChildren<ICommonProps> {
  /** The banner status */
  status?: BannerStatus;
  /** Callback executed when close button is clicked */
  close?: MouseEventHandler;
  /** Scroll page to banner when it appears
   * @default false
   */
  scroll?: boolean;
  /** Scroll offset
   * @default 0
   */
  scrollOffset?: number;
}

export type MessageListener = (items: BannerInstance[]) => void;
export type MessageUnlistener = () => void;

export type PlaceholderInstance = {
  uid: uid;
  active: Boolean;
};

export interface IPlaceholderRegistry {
  /** Register banner placeholder */
  set: (id: uid) => void;
  /** Get banner placeholder */
  get: (id: uid) => PlaceholderInstance | null;
}

export interface IBannerContext {
  /** Adds a message to the banner context */
  addMessage: (message: ReactNode, status: BannerStatus) => void;
  /** Remove a message from the banner context */
  removeMessage: (bnrItem: BannerInstance) => void;
  /** Remove all messages */
  reset: () => void;
  /** Banner collection */
  readonly banners: BannerInstance[];
  /** Adds a listener to banner updates */
  addListener: (listener: MessageListener) => MessageUnlistener;
  /** Banner placeholder registry */
  placeholderRegistry: IPlaceholderRegistry;
}

export type BannerInstance = {
  content: ReactNode;
  status: BannerStatus;
};

export enum BannerStatus {
  default = "default",
  success = "success",
  warning = "warning",
  error = "error",
  information = "info"
}

export const BannerContext = createContext<IBannerContext | null>(null);

/**
 * Provides a @see IBannerContext for adding or removing banner instances.
 * Use @see BannerPlaceHolder for displaying the banners within the context.
 */
export const useBanner = () => {
  const bannerContext = useContext(BannerContext);
  return bannerContext;
};

const NO_MESSAGES: BannerInstance[] = [];

/**
 * Hook which provides an updated list of current banner messages.
 */
export const useBannerMessages = () => {
  const bannerContext = useBanner();
  const [messages, setMessages] = useState<BannerInstance[]>(NO_MESSAGES);

  useEffect(() => {
    bannerContext?.addListener(setMessages);
  }, [bannerContext]);

  return messages.length === 0 ? NO_MESSAGES : messages;
};

export const BannerConsumer = BannerContext.Consumer;
