import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { loopIterationStop } from "../../Runtime/RuntimeExecution";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ForEachNextNode",
  category: NodeTypeCategory.lists,
  innerLoop: true,
  label: "Loop Next",
  controlInputs: [{ name: "next" }],
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [],
  size: NodeSize.Small,
  evaluate: () => {
    loopIterationStop();
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const ForEachNextNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
