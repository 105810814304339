import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";
import { IWithChildren, ICommonProps } from "../Common.types";
import styles from "./ListView.module.scss";
import { ListViewItem } from "./ListViewItem";

export enum ListAlignment {
  vertical = "vertical",
  horizontal = "horizontal"
}

export interface IListViewProps extends IWithChildren, ICommonProps {
  /**
   * The alignment of the items. When Vertical it will be a single column list, when
   * Horizontal it will distribute items into the number of columns according to the
   * columns property.
   * @default: vertical
   */
  alignment?: ListAlignment;
  /**
   * The number of columns when alignment is horizontal.
   * @default 3
   */
  columns?: number;
}

export interface IListView {
  (props: IListViewProps): JSX.Element;
  Item: typeof ListViewItem;
}

const renderChildrenWithStyle = (nestedChildren: ReactNode, columnCount: number) => {
  return React.Children.map(nestedChildren, (child: ReactNode) => {
    if (React.isValidElement(child)) {
      const style = { ...child.props.style };
      style.width = `calc((100% / ${String(columnCount)}) - (var(--listview-padding) * 2))`;

      return React.cloneElement(child, { style });
    }
    return [];
  });
};

const getActualColumnCount = (align: ListAlignment, defaultColumns: number, childrenCount: number) => {
  if (align === ListAlignment.vertical) {
    return 1;
  }

  // Always let at least the first row be filled.
  const columnCount = Math.min(childrenCount, defaultColumns);

  return Math.max(columnCount, 1);
};

// ListViewComponent needs to be exported for storybook docs to show interface descriptions
export const ListViewComponent: FunctionComponent<IListViewProps> = ({
  alignment = ListAlignment.vertical,
  children,
  columns = 3,
  className,
  ...other
}) => {
  const cols = getActualColumnCount(alignment, columns, React.Children.count(children));
  const listviewClassName = clsx(
    styles.listview,
    alignment === ListAlignment.vertical ? styles.listviewVertical : styles.listviewHorizontal,
    className
  );
  return (
    <ul className={listviewClassName} {...other}>
      {renderChildrenWithStyle(children, cols)}
    </ul>
  );
};

const ListView = ListViewComponent as IListView;
ListView.Item = ListViewItem;

export { ListView };
