import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { NumericInput, INumericInputProps } from "../Input";
import styles from "./Filter.module.scss";

export const FilterNumericInput: FunctionComponent<INumericInputProps> = ({ className, ...other }) => {
  const classes = clsx(className, styles.filterItemInput);

  return <NumericInput data-testid="plex-filter-numeric-input" className={classes} {...other} />;
};
