import React, { FunctionComponent } from "react";
import { Node, ReactFlowInstance, UpdateNodeInternals, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Button } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { IDataParam, IDataPropertyCountOptions } from "../NodeTypes/Base";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { allEdgesDeletionMode, deleteEdgesFromNodes } from "../Util/EdgeUtil";

const dataPropertyIsInput = (name: string, nodeType: string) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(nodeType);
  const isInput = nodeDefinition?.dataInputs.some((input: IDataParam) => input.name === name);
  return isInput;
};

const removeDataPropertiesAboveCount = (
  node: Node<any>,
  configPropertyName: string,
  options: IDataPropertyCountOptions,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const count: number = node.data.nodeProperties[configPropertyName];
  const isInput = dataPropertyIsInput(options.cloneFrom, node.type!);
  const dataPropertyCollection = isInput ? "inputs" : "outputs";
  const deletedProperties: string[] = [];
  Object.keys(node.data.nodeProperties[dataPropertyCollection]).forEach((dataPropertyName: string) => {
    const nameParts = dataPropertyName.split("_");
    if (nameParts.length > 0) {
      const numberPart = nameParts[nameParts.length - 1]!;
      const cloneNamePart = dataPropertyName.replace("_" + numberPart, "");
      const dataPropertyNumber = parseInt(numberPart);
      if (cloneNamePart === options.cloneFrom && dataPropertyNumber > count) {
        delete node.data.nodeProperties[dataPropertyCollection][dataPropertyName];
        deletedProperties.push(dataPropertyName);
      }
    }
  });

  deleteEdgesFromNodes([node.id], reactFlowInstance, updateNodeInternals, {
    ...allEdgesDeletionMode,
    filteredProperties: deletedProperties
  });
};

export const addDataPropertiesFromCount = (
  node: Node<any>,
  configPropertyName: string,
  options: IDataPropertyCountOptions
) => {
  const count: number = node.data.nodeProperties[configPropertyName];
  const isInput = dataPropertyIsInput(options.cloneFrom, node.type!);
  const dataPropertyCollection = isInput ? "inputs" : "outputs";
  for (let i = 1; i < count; i++) {
    const name = `${options.cloneFrom}_${i + 1}`;
    const cloningProperty = node.data.nodeProperties[dataPropertyCollection][options.cloneFrom];
    node.data.nodeProperties[dataPropertyCollection][name] = { ...cloningProperty, name: name };
  }
};

export const DataPropertyCountFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  let [itemCountState, setItemCountState] = React.useState<number>(props.node!.data.nodeProperties[props.name] ?? 1);
  const incrementItemCount = () => {
    ++itemCountState;
    setItemCountState(itemCountState);
    props.node!.data.nodeProperties[props.name] = itemCountState;
    addDataPropertiesFromCount(props.node, props.name, props.options as IDataPropertyCountOptions);
    forceNodeUpdate(props.node, updateNodeInternals, reactFlowInstance);
  };
  const decrementItemCount = () => {
    itemCountState = Math.max(1, itemCountState - 1);
    setItemCountState(itemCountState);
    props.node!.data.nodeProperties[props.name] = itemCountState;
    removeDataPropertiesAboveCount(
      props.node,
      props.name,
      props.options as IDataPropertyCountOptions,
      reactFlowInstance,
      updateNodeInternals
    );
    addDataPropertiesFromCount(props.node, props.name, props.options as IDataPropertyCountOptions);
    forceNodeUpdate(props.node, updateNodeInternals, reactFlowInstance);
  };

  return (
    <div>
      <Button key="remove-item-button" title="Remove Item" onClick={decrementItemCount}>
        Remove Item
      </Button>
      <div>{itemCountState}</div>
      <Button key="add-item-button" title="Add Item" onClick={incrementItemCount}>
        Add Item
      </Button>
    </div>
  );
};
