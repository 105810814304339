import React, {
  FunctionComponent,
  ChangeEventHandler,
  useRef,
  useState,
  useEffect,
  useCallback,
  CSSProperties
} from "react";
import clsx from "clsx";
import { useLocalization } from "@plex/culture-react";
import { noop } from "@utils";
import { File7Icon, ArrowIcon } from "@plex/icons";
import { IFilePickerProps, isPreviewableItem, IFilePickerFile } from "./FilePicker";
import { FilePickerSearchInput } from "./FilePickerSearchInput";
import { Checkbox } from "../Checkbox/Checkbox";
import styles from "./FilePickerModal.module.scss";
import { ActionBar } from "../ActionBar";
import { ActionBarButton } from "../ActionBar/ActionBar.Button";
import { Dialog } from "../Dialog";
import { CancelLink } from "../Link";
import { OkButton } from "../Button";
import { FilePickerSearchState, FilePickerSearchAction } from "./FilePickerSearch";

export interface IFilePickerModalProps extends IFilePickerProps {
  searchState: FilePickerSearchState;
  searchDispatch: React.Dispatch<FilePickerSearchAction>;
  /** Function called when the File Picker dialog is closed. */
  onClose: () => void;
  /** Renders file picker modal in dialog. True by default. Debugging parameter used by storybook. */
  _embedModalInDialog?: boolean;
}

interface IFilePickerModalDialogProps {
  onClose: (cancel: boolean) => void;
  filePickerModal: React.ReactElement;
  showOkButton: boolean;
}

const DIALOG_MIN_WIDTH = 400;
const DIALOG_MIN_HEIGHT = 275;
const DIALOG_INITIAL_WIDTH = 800;
const DIALOG_INITIAL_HEIGHT = 600;

const displayNone: CSSProperties = { display: "none" };

export const FilePickerModalDialog: FunctionComponent<IFilePickerModalDialogProps> = ({
  onClose,
  filePickerModal,
  showOkButton
}) => {
  const { t } = useLocalization();

  return (
    <Dialog
      title={t("ui-common-filePicker-title-dialog")}
      backdrop="static"
      show
      minWidth={DIALOG_MIN_WIDTH}
      minHeight={DIALOG_MIN_HEIGHT}
      initialWidth={DIALOG_INITIAL_WIDTH}
      initialHeight={DIALOG_INITIAL_HEIGHT}
      onHide={() => onClose(true)}
      closeOnEscape
    >
      <Dialog.Body>{filePickerModal}</Dialog.Body>
      <Dialog.Footer>
        <CancelLink
          onClick={() => {
            onClose(true);
          }}
        />
        {showOkButton && (
          <OkButton
            onClick={() => {
              onClose(false);
            }}
          />
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

export const FilePickerModal: FunctionComponent<IFilePickerModalProps> = props => {
  const {
    selectedFiles,
    onSelectionChanged,
    onFileUpload,
    onSearch,
    _embedModalInDialog = true,
    onClose,
    searchState,
    searchDispatch,
    accept = "",
    multiSelect = false
  } = props;
  const { t } = useLocalization();
  const [uncommittedSelection, setUncommittedSelection] = useState<IFilePickerFile[]>(selectedFiles);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { files, searchTextCompleted, searchTextInProgress, initialized, searching, statusMessageId } = searchState;

  const handleSearch = useCallback(
    (newSearchText: string) => {
      if (searching || (newSearchText === searchTextInProgress && initialized)) {
        return;
      }

      searchDispatch({ type: "searching", searchText: newSearchText });
      onSearch({
        searchText: newSearchText,
        searchComplete: (newFiles, newRecordLimitExceeded) => {
          searchDispatch({ type: "load", files: newFiles, limitExceeded: newRecordLimitExceeded });
        },
        searchFailed: () => {}
      });
    },
    [onSearch, searchTextInProgress, initialized, searching, searchDispatch]
  );

  useEffect(() => {
    if (initialized === false) {
      handleSearch("");
    }
  }, [initialized, handleSearch]);

  const closeHandler = (canceled: boolean) => {
    if (canceled) {
      setUncommittedSelection(selectedFiles);
    } else {
      onSelectionChanged([...uncommittedSelection]);
    }

    onClose();
  };

  const changeSelected = (file: IFilePickerFile, currentlySelected: boolean) => {
    if (multiSelect === false) {
      setUncommittedSelection([file]);
      onSelectionChanged([file]);
      onClose();
      return;
    }

    if (currentlySelected) {
      setUncommittedSelection(original => original.filter(x => x.id !== file.id));
    } else {
      setUncommittedSelection(original => [...original, file]);
    }
  };

  const fileAcceptedHandler = (file: IFilePickerFile) => {
    if (multiSelect) {
      setUncommittedSelection(original => [...original, file]);
    } else {
      setUncommittedSelection([file]);
      onSelectionChanged([file]);
      onClose();
    }

    searchDispatch({ type: "file-accepted", file });
  };

  const forwardClickToInputElement = () => {
    fileInputRef.current?.click();
  };

  const fileSelected: ChangeEventHandler<HTMLInputElement> = ie => {
    const uploadedFiles: File[] = ie.target.files == null ? [] : Array.from(ie.target.files);
    onFileUpload({
      files: uploadedFiles,
      fileAccepted: fileAcceptedHandler,
      fileRejected: noop
    });
  };

  const renderItem = (item: IFilePickerFile, selected: boolean) => {
    return (
      <div className={styles.item} key={item.id}>
        <div
          className={clsx(styles.itemContent, selected ? styles.itemContentSelected : styles.itemContentUnselected)}
          onClick={() => changeSelected(item, selected)}
          data-testid={`file-picker-modal-item-content-${item.id.toString()}`}
        >
          {isPreviewableItem(item.contentType) ? (
            <img src={item.url} className={styles.itemImg} />
          ) : (
            <File7Icon className={styles.itemIcon} />
          )}
          {multiSelect && <Checkbox className={styles.itemCheckbox} checked={selected} />}
          <ArrowIcon className={styles.itemExpand} />
        </div>
        <div className={styles.itemLabel}>{item.name}</div>
      </div>
    );
  };

  const statusText =
    statusMessageId === "empty" ? "" : t(statusMessageId, { count: files.length, input: searchTextCompleted });

  const sortedFiles = [...files].sort((a: IFilePickerFile, b: IFilePickerFile) => {
    if (multiSelect === false) {
      const aIsSelected: boolean = selectedFiles.findIndex(e => e.id === a.id) > -1;
      const bIsSelected: boolean = selectedFiles.findIndex(e => e.id === b.id) > -1;
      if (aIsSelected && bIsSelected === false) {
        return -1;
      }

      if (bIsSelected && aIsSelected === false) {
        return 1;
      }
    }

    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  const modal = (
    <div className={styles.modalContainer} data-testid="file-picker-modal">
      <div className={styles.searchContainer}>
        <div className={styles.resultText} data-testid="plex-filePicker-statusText">
          {statusText}
        </div>
        <FilePickerSearchInput onSearch={handleSearch} initialSearchText={searchTextInProgress} />
      </div>

      <input
        style={displayNone}
        ref={fileInputRef}
        data-testid="file-picker-input"
        multiple={multiSelect}
        type="file"
        onChange={fileSelected}
        accept={accept}
      />

      <ActionBar>
        <ActionBarButton onClick={forwardClickToInputElement}>Upload File</ActionBarButton>
      </ActionBar>
      {multiSelect && (
        <div className={styles.filesSection}>
          <div className={styles.headerText}>{t("ui-common-filePicker-title-filesSelected")}</div>
          <div className={clsx(styles.filesBox, styles.filesBoxSelected)}>
            {uncommittedSelection.map(item => renderItem(item, true))}
          </div>
        </div>
      )}
      {multiSelect && (
        <div className={styles.filesSection}>
          <div className={styles.headerText}>{t("ui-common-filePicker-title-fileSelectionArea")}</div>
          <div className={clsx(styles.filesBox, styles.filesBoxAll)}>
            {sortedFiles.map(item => renderItem(item, uncommittedSelection.filter(u => u.id === item.id).length > 0))}
          </div>
        </div>
      )}
      {multiSelect === false && (
        <div className={styles.filesSection}>
          <div className={styles.headerText}>{t("ui-common-filePicker-title-fileSingleSelectionArea")}</div>
          <div className={clsx(styles.filesBox, styles.filesBoxAll)}>
            {sortedFiles.map(item => renderItem(item, uncommittedSelection.filter(u => u.id === item.id).length > 0))}
          </div>
        </div>
      )}
    </div>
  );

  return _embedModalInDialog ? (
    <FilePickerModalDialog onClose={closeHandler} filePickerModal={modal} showOkButton={multiSelect} />
  ) : (
    modal
  );
};
