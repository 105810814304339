import { Connection, Edge, Node, ReactFlowInstance, addEdge, UpdateNodeInternals } from "reactflow";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { IControlFlowParam, IDataParam, INodeProperty } from "../NodeTypes/Base";
import { camelCaseToSpacesRegEx } from "../NodePropertiesForm/FormConstants";
import { rewireEdges } from "../NodeTypes/GroupNode/groupUtil";
import { DocumentSchemaType, IDocumentListSchema, IDocumentObjectSchema } from "../FlowDocument/FlowDocumentModel";
import { forceNodeUpdate, getAvailableConfigDataTypes, updateNodeDataProperties } from "../Util/NodeUtil";
import {
  IDesignerSchema,
  getDataTypeFromSchema,
  getDataTypeFromSchemaInstance,
  getEmptySchema,
  getEmptySchemaId,
  getSchema,
  getSchemas
} from "../NodeTypes/DataSchemas";
import {
  DataType,
  getDataType,
  implicit,
  implicitSecondary,
  isObjectLike,
  isListType
} from "../NodeTypes/TypeDefinitions";
import { AddMenuNodePopulater, IDataTypeConfig } from "../AddNodeMenu/AddMenuNodePopulation";
import { validateOrder } from "../Runtime/RuntimeValidation";
import { AnchorProperties } from "./AnchorUtil";
import { openConfirmDialog } from "./DialogUtil";
import { IFlowOrderError } from "../Runtime/RuntimeExecution";

export interface IHandleInfo {
  handleId: string;
  name: string;
  readableName: string;
  type: string;
  node: Node<any>;
}

export interface IEdgeInfo {
  targetHandle: IHandleInfo;
  sourceHandle: IHandleInfo;
}

export class EdgeDetail {
  private readonly nodeId: string;
  private readonly reactFlowInstance: ReactFlowInstance;

  constructor(nodeId: string, reactFlowInstance: ReactFlowInstance) {
    this.nodeId = nodeId;
    this.reactFlowInstance = reactFlowInstance;
  }

  getInputTargetLabel(inputName: string): string {
    let inputTargetHandle = this.getInput(inputName)?.targetHandle;
    let inputLabel = inputTargetHandle?.readableName ?? "";

    if (inputTargetHandle) {
      let outputName = inputTargetHandle?.name;
      let outputLabel = inputTargetHandle.node.data.nodeProperties.outputs[outputName].label;

      if (outputLabel) {
        inputLabel = outputLabel;
      }
    }

    if (inputTargetHandle?.node.type === "constant") {
      switch (inputTargetHandle.type) {
        case "string":
          inputLabel = `"${inputTargetHandle.node.data.nodeProperties.literal}"`;
          break;
        default:
          inputLabel = `${inputTargetHandle.node.data.nodeProperties.literal}`;
          break;
      }
    }

    return inputTargetHandle?.type === "string" ? (inputLabel === "" ? '""' : inputLabel) : inputLabel;
  }

  getInput(inputName: string): IEdgeInfo | null {
    let inputEdge = this.reactFlowInstance
      .getEdges()
      .filter((e) => e.source === this.nodeId && this.getHandle(e.source, e.sourceHandle).name === inputName)[0];

    if (!inputEdge) {
      return null;
    }

    return {
      targetHandle: this.getHandle(inputEdge.target, inputEdge.targetHandle),
      sourceHandle: this.getHandle(inputEdge.source, inputEdge.sourceHandle)
    };
  }

  getOutput(outputName: string): IEdgeInfo | null {
    let outputEdge = this.reactFlowInstance
      .getEdges()
      .filter((e) => e.target === this.nodeId && this.getHandle(e.target, e.targetHandle).name === outputName)[0];

    if (!outputEdge) {
      return null;
    }

    return {
      targetHandle: this.getHandle(outputEdge.target, outputEdge.targetHandle),
      sourceHandle: this.getHandle(outputEdge.source, outputEdge.sourceHandle)
    };
  }

  private getHandle(nodeId: string, handleId: string | null | undefined): IHandleInfo {
    let targetHandleIdParts: any = handleId?.split("-") ?? [];
    let name: any = targetHandleIdParts[0];
    let readableName = getReadableName(name);
    let node = this.reactFlowInstance.getNode(nodeId);
    return {
      handleId: handleId ?? "",
      name: name,
      readableName: readableName,
      type: targetHandleIdParts[1],
      node: node!
    };
  }
}

let controlFlowErrors: IFlowOrderError[] = [];

export const hasNodeConnectionErrors = (fromNodeId: String, toNodeId: String): boolean => {
  return controlFlowErrors.some((error) => error.fromNode.data.id === fromNodeId && error.toNode.data.id === toNodeId);
};

const getReadableName = (name: string) => {
  if (name === null || name === undefined) {
    return "";
  }
  let readableName = name.replaceAll("_", " ");
  readableName = readableName.charAt(0).toUpperCase() + readableName.slice(1);
  readableName = readableName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
  return readableName;
};

export const updateEdgeValidationStyling = (currentEdges?: Edge[]) => {
  const reactFlowInstance = AddMenuNodePopulater._reactFlowInstance;

  setTimeout(() => {
    controlFlowErrors = validateOrder({
      nodes: reactFlowInstance.getNodes(),
      edges: currentEdges ?? reactFlowInstance.getEdges()
    });
    let errorEdges = (currentEdges ?? reactFlowInstance.getEdges()).filter((edge) =>
      controlFlowErrors.some(
        (error) =>
          (edge.source === error.fromNode.id && edge.target === error.toNode.id) ||
          (edge.source === error.toNode.id && edge.target === error.fromNode.id)
      )
    );

    // Reset styling on edges without errors
    let resetStyleEdges = (currentEdges ?? reactFlowInstance.getEdges()).filter(
      (edge) => !errorEdges.some((error) => error.id === edge.id)
    );
    resetStyleEdges.forEach((edge) => {
      edge.className = edge.className?.replaceAll("edge-flow-order-error", "");
    });

    if (controlFlowErrors.length > 0) {
      errorEdges.forEach((edge) => {
        edge.className += " edge-flow-order-error";
      });
    }

    reactFlowInstance.setEdges((edges) =>
      (currentEdges ?? edges)
        .filter(
          (e) => !errorEdges.some((error) => error.id === e.id) && !resetStyleEdges.some((error) => error.id === e.id)
        )
        .concat(errorEdges)
        .concat(resetStyleEdges)
    );
  }, 50);
};

export const handleEdgeConnection = (params: Connection | Edge) => {
  handleEdgeConnections([params]);
};

export type DataConnectionConfig = {
  target?: IDataTypeConfig;
  source?: IDataTypeConfig;
};

export interface IConnectionValidationResult {
  valid: boolean;
  onlyValidFromImplicitConversion: boolean;
  onlyValidFromSecondaryImplicitConversion: boolean;
  requiredDataConfig?: DataConnectionConfig;
}

export interface INodeRemapProperty {
  name: string;
  label: string;
  edges: (Edge | Connection)[];
}

export interface INodeRemap {
  nodeId: string;
  nodeLabel: string;
  remapProperties: INodeRemapProperty[];
}

export interface IConnectionRemap {
  nodes: INodeRemap[];
}

export const canDeleteEdge = (edge: Edge, reactFlowInstance: ReactFlowInstance, bannerContext: IBannerContext) => {
  const edges = reactFlowInstance.getEdges();
  const targetNode = reactFlowInstance.getNode(edge.target);
  const sourceNode = reactFlowInstance.getNode(edge.source);
  if (
    targetNode &&
    sourceNode &&
    (sourceNode.data.nodeProperties.schemaSourceNodeId || targetNode.data.nodeProperties.schemaLinkedNodes)
  ) {
    const schemaSourceNode = targetNode.data.nodeProperties.schemaLinkedNodes
      ? targetNode
      : reactFlowInstance.getNode(sourceNode.data.nodeProperties.schemaSourceNodeId);
    if (schemaSourceNode) {
      const objectOutputPropertyNodes: Node<any>[] = [];
      traverseSchemaLinkedOutputs({
        node: sourceNode,
        nodes: reactFlowInstance.getNodes(),
        edges: edges,
        onNodeVisit: (node) => {
          const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node!.type!)!;
          if (nodeDefinition.outputsSchemaProperties) {
            objectOutputPropertyNodes.push(node);
          }
        }
      });
      const hasPropertyNodesWithConnectedProperties = objectOutputPropertyNodes.some((n: Node<any>) =>
        edges.some((e: Edge) => e.target === n.id)
      );
      if (hasPropertyNodesWithConnectedProperties) {
        bannerContext.addMessage("Action aborted due to existing dependent connections.", BannerStatus.warning);
        return false;
      }
    }
  }

  return true;
};

export const getSchemaLinkedUnmappable = (
  edge: Edge | Connection,
  schemaMappingTo: IDesignerSchema,
  reactFlowInstance: ReactFlowInstance
) => {
  const unmappableOutputs: IConnectionRemap = { nodes: [] };
  const edges = reactFlowInstance.getEdges();
  const targetNode = reactFlowInstance.getNode(edge.target!);
  const sourceNode = reactFlowInstance.getNode(edge.source!);
  if (
    targetNode &&
    sourceNode &&
    (sourceNode.data.nodeProperties.schemaSourceNodeId || targetNode.data.nodeProperties.schemaLinkedNodes)
  ) {
    const schemaSourceNode = targetNode.data.nodeProperties.schemaLinkedNodes
      ? targetNode
      : reactFlowInstance.getNode(sourceNode.data.nodeProperties.schemaSourceNodeId);
    if (schemaSourceNode) {
      traverseSchemaLinkedOutputs({
        traverseAlongsideSchema: schemaMappingTo,
        node: sourceNode,
        nodes: reactFlowInstance.getNodes(),
        edges: edges,
        fromEdge: edge,
        onOutputVisit(output, node, edges, traversedAlongsideSchema) {
          const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
          if (nodeDefinition?.outputsSchemaProperties) {
            // If there are output edges, but no schema to map to.
            if (edges.length > 0 && traversedAlongsideSchema === undefined) {
              let nodeRemapIndex = unmappableOutputs.nodes.findIndex(
                (nodeRemap: INodeRemap) => nodeRemap.nodeId === node.id
              );
              if (nodeRemapIndex === -1) {
                unmappableOutputs.nodes.push({
                  nodeId: node.id,
                  nodeLabel: node.data.nodeProperties.name
                    ? node.data.nodeProperties.name
                    : node.data.nodeProperties.label,
                  remapProperties: []
                });
                nodeRemapIndex = unmappableOutputs.nodes.length - 1;
              }
              unmappableOutputs.nodes[nodeRemapIndex]!.remapProperties.push({
                name: output.name,
                label: output.label ?? getReadableName(output.name),
                edges: edges
              });
            }
          }
        }
      });
    }
  }

  return unmappableOutputs;
};

const getUnmappableEdges = (remap: IConnectionRemap, reactFlowInstance: ReactFlowInstance) => {
  const unmappableEdges: (Edge | Connection)[] = [];
  remap.nodes.forEach((nodeRemap: INodeRemap) =>
    nodeRemap.remapProperties.forEach((property: INodeRemapProperty) =>
      property.edges.forEach((unmappableEdge: Edge | Connection) => unmappableEdges.push(unmappableEdge))
    )
  );
  return unmappableEdges;
};

const removeUnmappableEdges = (
  unmappableEdges: (Edge | Connection)[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  let newEdges: Edge[] = [];
  reactFlowInstance.setEdges(
    (edges: Edge[]) =>
      (newEdges = edges.filter(
        (e: Edge) =>
          !unmappableEdges.some(
            (unmappableEdge: Edge | Connection) =>
              unmappableEdge.targetHandle &&
              unmappableEdge.sourceHandle &&
              unmappableEdge.targetHandle === e.targetHandle &&
              unmappableEdge.sourceHandle === e.sourceHandle
          )
      ))
  );
  const affectedNodes = reactFlowInstance
    .getNodes()
    .filter((n: Node<any>) => unmappableEdges.some((e: Edge) => e.source === n.id || e.target === n.id));
  affectedNodes.forEach((n: Node<any>) => {
    forceNodeUpdate(n, updateNodeInternals, reactFlowInstance);
  });

  return newEdges;
};

export interface INewEdgeSchema {
  edge: Edge | Connection;
  newSchema: IDesignerSchema | undefined;
}

const getEmptySchemaFromEdge = (edge: Edge | Connection, reactFlowInstance: ReactFlowInstance) => {
  const edgeDetail = new EdgeDetail(edge.target!, reactFlowInstance);
  const outputName = getNameFromDataHandleId(edge.targetHandle!);
  const newSchema = getEmptySchema(edgeDetail.getOutput(outputName)!.targetHandle.name) as unknown as IDesignerSchema;
  return newSchema;
};

export const checkAndRemoveUnmappable = (
  edgeSchemas: INewEdgeSchema[],
  confirmCallback: (newEdges: Edge[]) => void,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const remap: IConnectionRemap = { nodes: [] };
  edgeSchemas.forEach((edgeSchema: INewEdgeSchema) => {
    const newSchema = edgeSchema.newSchema ?? getEmptySchemaFromEdge(edgeSchema.edge, reactFlowInstance);
    const edgeRemap = getSchemaLinkedUnmappable(edgeSchema.edge, newSchema, reactFlowInstance);
    edgeRemap.nodes.forEach((nodeRemap: INodeRemap) => {
      let nodeRemapIndex = remap.nodes.findIndex(
        (findNodeRemap: INodeRemap) => nodeRemap.nodeId === findNodeRemap.nodeId
      );
      if (nodeRemapIndex === -1) {
        remap.nodes.push({ nodeId: nodeRemap.nodeId, nodeLabel: nodeRemap.nodeLabel, remapProperties: [] });
        nodeRemapIndex = remap.nodes.length - 1;
      }
      nodeRemap.remapProperties.forEach((nodeRemapProperty: INodeRemapProperty) => {
        if (
          !remap.nodes[nodeRemapIndex]!.remapProperties.some(
            (p: INodeRemapProperty) => p.name === nodeRemapProperty.name
          )
        )
          remap.nodes[nodeRemapIndex]!.remapProperties.push(nodeRemapProperty);
      });
    });
  });

  const unmappableEdges = getUnmappableEdges(remap, reactFlowInstance);
  if (unmappableEdges.length > 0) {
    openConfirmDialog(remap, () => {
      const newEdges = removeUnmappableEdges(unmappableEdges, reactFlowInstance, updateNodeInternals);
      confirmCallback(newEdges);
    });
  } else {
    confirmCallback(reactFlowInstance.getEdges());
  }
};

export const validateConnection = (
  params: Edge | Connection,
  sourceNodeType: string,
  targetNodeType: string,
  reactFlowInstance: ReactFlowInstance,
  skipLoopValidation: boolean = false
): IConnectionValidationResult => {
  let edgeIsValid = true;
  let onlyValidFromImplicitConversion = false;
  let onlyValidFromSecondaryImplicitConversion = false;
  let requiredDataConfig: DataConnectionConfig | undefined;

  if (params.source === params.target && sourceNodeType !== "flowGroup") {
    edgeIsValid = false;
  }

  let sourceHandleIsControlFlow = params.sourceHandle?.indexOf("FlowIn") === 0;
  let targetHandleIsControlFlow =
    params.targetHandle?.indexOf("FlowOut") === 0 || params.targetHandle?.indexOf("FlowError") === 0;
  let sourceHandleIsControlFlowError = params.sourceHandle?.indexOf("FlowError") === 0;
  let targetHandleIsControlFlowError = params.targetHandle?.indexOf("FlowError") === 0;

  if (targetHandleIsControlFlow) {
    let existingFlowOutputEdges = reactFlowInstance
      .getEdges()
      .filter(
        (e) =>
          e.targetHandle === params.targetHandle &&
          e.target === params.target &&
          params.targetHandle?.indexOf("FlowOutHandle") !== -1
      );
    if (existingFlowOutputEdges.length > 0) {
      edgeIsValid = false;
    }

    const targetNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(targetNodeType);
    const sourceNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(sourceNodeType);

    const controlOutputName = params.targetHandle?.replace("FlowOutHandle", "");
    const controlOutput = targetNodeDefinition?.controlOutputs.find(
      (output: IControlFlowParam) => output.name === controlOutputName
    );

    if (!skipLoopValidation) {
      const targetNode = reactFlowInstance.getNode(params.target!);
      if (targetNode) {
        const insideLoop = controlOutput?.startsLoop || targetNode.data.nodeProperties.insideLoopNodeId;
        if (sourceNodeDefinition?.innerLoop && !insideLoop) {
          edgeIsValid = false;
        }
      }
    }
  }

  if (sourceHandleIsControlFlow) {
    let existingControlInputEdges = reactFlowInstance
      .getEdges()
      .filter(
        (e) =>
          e.sourceHandle === params.sourceHandle &&
          e.source === params.source &&
          params.sourceHandle?.indexOf("FlowInHandle") !== -1
      );
    if (existingControlInputEdges.length > 0) {
      edgeIsValid = false;
    }
  }

  if (!sourceHandleIsControlFlowError && targetHandleIsControlFlow !== sourceHandleIsControlFlow) {
    edgeIsValid = false;
  }

  if (sourceHandleIsControlFlowError && targetHandleIsControlFlowError !== sourceHandleIsControlFlowError) {
    edgeIsValid = false;
  }

  if (params.targetHandle === "FlowOutHandleDone" && sourceNodeType !== "stop") {
    edgeIsValid = false;
  }

  const targetHandleIdParts = params.targetHandle!.split("-");
  if (!sourceHandleIsControlFlow && !targetHandleIsControlFlow) {
    const sourceHandleIdParts = params.sourceHandle!.split("-");
    const sourceHandleDataType = sourceHandleIdParts[sourceHandleIdParts.length - 2]!;
    const sourceHandleName = sourceHandleIdParts[0]!;
    const targetHandleName = targetHandleIdParts[0]!;
    const substringIndex = targetHandleIdParts.includes("error") ? 3 : 2;
    const targetHandleDataType = targetHandleIdParts[targetHandleIdParts.length - substringIndex]!;

    if (sourceHandleDataType !== targetHandleDataType) {
      const implicitConversion = implicit(getDataType(targetHandleDataType)!, getDataType(sourceHandleDataType)!);
      if (!implicitConversion) {
        const secondaryImplicitConversion = implicitSecondary(
          getDataType(targetHandleDataType)!,
          getDataType(sourceHandleDataType)!
        );
        if (!secondaryImplicitConversion) {
          if (params.target && params.source) {
            requiredDataConfig = getRequiredDataConfig(
              params.target,
              targetNodeType,
              targetHandleName,
              targetHandleDataType,
              params.source,
              sourceNodeType,
              sourceHandleName,
              sourceHandleDataType,
              reactFlowInstance
            );
          }

          if (!requiredDataConfig) {
            edgeIsValid = false;
          }
        } else {
          onlyValidFromSecondaryImplicitConversion = true;
        }
      } else {
        onlyValidFromImplicitConversion = true;
      }
    }

    let existingInputEdges = reactFlowInstance.getEdges().filter((edge) => {
      let edgeMatch = edge.sourceHandle === params.sourceHandle && edge.source === params.source;
      return edgeMatch;
    });
    if (existingInputEdges.length > 0) {
      edgeIsValid = false;
    }
  }

  return {
    valid: edgeIsValid,
    onlyValidFromImplicitConversion: onlyValidFromImplicitConversion,
    onlyValidFromSecondaryImplicitConversion: onlyValidFromSecondaryImplicitConversion,
    requiredDataConfig: requiredDataConfig
  };
};

const getRequiredDataConfig = (
  targetNodeId: string,
  targetNodeType: string,
  targetHandleName: string,
  targetHandleDataType: string,
  sourceNodeId: string,
  sourceNodeType: string,
  sourceHandleName: string,
  sourceHandleDataType: string,
  reactFlowInstance: ReactFlowInstance
) => {
  const targetHasDataConnections = reactFlowInstance
    .getEdges()
    .some(
      (e: Edge) =>
        e.targetHandle?.indexOf("FlowOutHandle") === -1 &&
        e.sourceHandle?.indexOf("FlowInHandle") === -1 &&
        (e.target === targetNodeId || e.source === targetNodeId)
    );
  const sourceHasDataConnections = reactFlowInstance
    .getEdges()
    .some(
      (e: Edge) =>
        e.targetHandle?.indexOf("FlowOutHandle") === -1 &&
        e.sourceHandle?.indexOf("FlowInHandle") === -1 &&
        (e.target === sourceNodeId || e.source === sourceNodeId)
    );

  const sourceHandleTypes = getAvailableConfigDataTypes(sourceNodeType, sourceHandleName);
  const targetHandleTypes = getAvailableConfigDataTypes(targetNodeType, targetHandleName);

  let matchingConfigs: DataConnectionConfig[] = [];
  let implicitConfigs: DataConnectionConfig[] = [];
  let secondaryImplicitConfigs: DataConnectionConfig[] = [];
  const sourceHasListOptions = sourceHandleTypes.some((config: IDataTypeConfig) => isListType(config.dataType));
  const sourceHandleIsList = isListType(sourceHandleDataType as DataType);
  const sourceConvertsToList = sourceHandleIsList && !sourceHasListOptions;
  const targetHasListOptions = targetHandleTypes.some((config: IDataTypeConfig) => isListType(config.dataType));
  const targetHandleIsList = isListType(targetHandleDataType as DataType);
  const targetConvertsToList = targetHandleIsList && !targetHasListOptions;
  sourceHandleTypes.forEach((sourceHandleType: IDataTypeConfig) => {
    const convertedSourceHandleDataType = `${sourceHandleType.dataType}${sourceConvertsToList ? "List" : ""}`;
    targetHandleTypes.forEach((targetHandleType: IDataTypeConfig) => {
      const convertedTargetHandleDataType = `${targetHandleType.dataType}${targetConvertsToList ? "List" : ""}`;
      const config = {
        target: targetHandleDataType === convertedTargetHandleDataType ? undefined : targetHandleType,
        source: sourceHandleDataType === convertedSourceHandleDataType ? undefined : sourceHandleType
      };
      const configIsPossible =
        (!config.target || targetHandleType.configProperty) && (!config.source || sourceHandleType.configProperty);
      const configBreaksConnections =
        (config.target && targetHasDataConnections) || (config.source && sourceHasDataConnections);

      if (configIsPossible && !configBreaksConnections) {
        if (convertedSourceHandleDataType === convertedTargetHandleDataType) {
          matchingConfigs.push(config);
        }
        if (implicit(getDataType(convertedTargetHandleDataType)!, getDataType(convertedSourceHandleDataType)!)) {
          implicitConfigs.push(config);
        }
        if (
          implicitSecondary(getDataType(convertedTargetHandleDataType)!, getDataType(convertedSourceHandleDataType)!)
        ) {
          secondaryImplicitConfigs.push(config);
        }
      }
    });
  });

  // Sort configs based on how much the config needs to change, to prefer config combinations involving fewer changes.
  const sortConfigs = (configs: DataConnectionConfig[]) =>
    configs.sort(
      (a: DataConnectionConfig, b: DataConnectionConfig) =>
        (a.source ? 1 : 0) + (a.target ? 1 : 0) - ((b.source ? 1 : 0) + (b.target ? 1 : 0))
    );

  matchingConfigs = sortConfigs(matchingConfigs);

  let requiredDataConfig: DataConnectionConfig | undefined;
  if (matchingConfigs.length > 0) {
    requiredDataConfig = matchingConfigs[0];
  } else {
    implicitConfigs = sortConfigs(implicitConfigs);
    if (implicitConfigs.length > 0) {
      requiredDataConfig = implicitConfigs[0];
    } else {
      secondaryImplicitConfigs = sortConfigs(secondaryImplicitConfigs);
      if (secondaryImplicitConfigs.length > 0) {
        requiredDataConfig = secondaryImplicitConfigs[0];
      }
    }
  }

  return requiredDataConfig;
};

// Expose handle so adding and removing edge could be used by Add node
export const handleEdgeConnections = (paramsArray: (Connection | Edge)[], loadingDocument: boolean = false) => {
  const reactFlowInstance = AddMenuNodePopulater._reactFlowInstance;
  const setEdges = AddMenuNodePopulater._reactFlowEdgeSetter;
  const updateNodeInternals = AddMenuNodePopulater._reactFlowUpdateNodeInternals;

  const newEdges: (Connection | Edge)[] = [];

  for (let i = 0; i < paramsArray.length; i++) {
    const params: Edge | Connection = paramsArray[i]!;

    let sourceNode = reactFlowInstance.getNode(params.source!)!;
    let targetNode = reactFlowInstance.getNode(params.target!)!;

    const validationResult: IConnectionValidationResult = validateConnection(
      params,
      sourceNode.type!,
      targetNode.type!,
      reactFlowInstance,
      loadingDocument
    );
    let edgeIsValid = validationResult.valid;

    if (!loadingDocument) {
      // Switch data types to accommodate connection
      if (validationResult.requiredDataConfig) {
        if (validationResult.requiredDataConfig.source) {
          const sourceHasConnections = reactFlowInstance
            .getEdges()
            .some(
              (e: Edge) =>
                e.sourceHandle?.indexOf("FlowInHandle") !== 0 &&
                (e.source === params.source || e.target == params.source)
            );
          if (sourceHasConnections) {
            edgeIsValid = false;
          } else {
            const dataConfig = validationResult.requiredDataConfig.source.configProperty;
            if (dataConfig) {
              sourceNode.data.nodeProperties[dataConfig.propertyName] = dataConfig.propertyValue;
              updateNodeDataProperties(sourceNode);
              updateNodeInternals(sourceNode.id);
              const sourceHandleName = getNameFromDataHandleId(params.sourceHandle!);
              params.sourceHandle = getInputHandle(sourceHandleName, dataConfig.propertyValue);
            }
          }
        }
        if (validationResult.requiredDataConfig.target) {
          const targetHasConnections = reactFlowInstance
            .getEdges()
            .some(
              (e: Edge) =>
                e.sourceHandle?.indexOf("FlowOutHandle") !== 0 &&
                (e.source === params.target || e.target === params.target)
            );
          if (targetHasConnections) {
            edgeIsValid = false;
          } else {
            const dataConfig = validationResult.requiredDataConfig.target.configProperty;
            if (dataConfig) {
              targetNode.data.nodeProperties[dataConfig.propertyName] = dataConfig.propertyValue;
              updateNodeDataProperties(targetNode);
              updateNodeInternals(targetNode.id);
              const targetHandleName = getNameFromDataHandleId(params.targetHandle!);
              params.targetHandle = getInputHandle(targetHandleName, dataConfig.propertyValue);
            }
          }
        }
      }
    }

    let sourceHandleIsControlFlow = params.sourceHandle?.indexOf("FlowIn") === 0;
    let targetHandleIsControlFlow =
      params.targetHandle?.indexOf("FlowOut") === 0 || params.targetHandle?.indexOf("FlowError") === 0;
    let sourceHandleIsControlFlowError = params.sourceHandle?.indexOf("FlowError") === 0;
    let targetHandleIsControlFlowError = params.targetHandle?.indexOf("FlowError") === 0;

    let targetHandleIdParts = params.targetHandle!.split("-");
    if (!sourceHandleIsControlFlow && !targetHandleIsControlFlow) {
      const substringIndex = targetHandleIdParts.includes("error") ? 3 : 2;
      let targetHandleDataType = targetHandleIdParts[targetHandleIdParts.length - substringIndex]!;

      if (
        edgeIsValid &&
        !loadingDocument &&
        (targetHandleDataType === DataType.OBJECT || targetHandleDataType === DataType.OBJECTLIST)
      ) {
        if (sourceNode.type === "objectProperties") {
          Object.keys(sourceNode.data.nodeProperties.outputs).forEach((outputName) => {
            let output = sourceNode.data.nodeProperties.outputs[outputName];
            output.enabled = false;
          });

          if (edgeIsValid) {
            newEdges.push(params);
          }
        }
      }
    }

    if (
      (sourceHandleIsControlFlow && targetHandleIsControlFlow) ||
      (sourceHandleIsControlFlowError && targetHandleIsControlFlowError)
    ) {
      if (edgeIsValid) {
        newEdges.push({
          source: params.source,
          target: params.target,
          sourceHandle: params.sourceHandle,
          targetHandle: params.targetHandle,
          className:
            targetHandleIsControlFlowError && sourceHandleIsControlFlowError
              ? "control-flow-error-edge"
              : "control-flow-edge"
        } as Edge<any>);
      }
    } else if (edgeIsValid) {
      newEdges.push(params);
    }
  }

  let newEdgesState: Edge[] = [];
  setEdges((edges: Edge[]) => {
    newEdgesState = edges;
    newEdges.forEach((newEdge: Connection | Edge) => (newEdgesState = addEdge(newEdge, newEdgesState)));
    return newEdgesState;
  });

  // Only do this if adding a single edge, so that when loading a flow this is not done,
  // since it is not needed there and cause problems if done there
  if (!loadingDocument) {
    let sourceNode = reactFlowInstance.getNode(paramsArray[0]?.source!)!;
    let targetNode = reactFlowInstance.getNode(paramsArray[0]?.target!)!;

    setTimeout(() => {
      if (sourceNode.data.nodeProperties.parentNode !== targetNode.data.nodeProperties.parentNode) {
        if (sourceNode.data.nodeProperties.parentNode) {
          rewireEdges(
            reactFlowInstance.getNode(sourceNode.data.nodeProperties.parentNode)!,
            reactFlowInstance,
            updateNodeInternals
          );
        }
        if (targetNode.data.nodeProperties.parentNode) {
          rewireEdges(
            reactFlowInstance.getNode(targetNode.data.nodeProperties.parentNode)!,
            reactFlowInstance,
            updateNodeInternals
          );
        }
      }
    }, 0);
  }

  if (!loadingDocument) {
    // Update schemas linked to a schema source,
    //  but skip here if loading a document since this step needs to happen after initialize.
    updateLinkedSchemas(reactFlowInstance.getNodes(), newEdgesState, updateNodeInternals, reactFlowInstance);
  }
  updateEdgeValidationStyling();
};

export const updateLinkedSchemas = (
  nodes: Node<any>[],
  edges: (Edge | Connection)[],
  updateNodeInternals: UpdateNodeInternals,
  reactFlowInstance: ReactFlowInstance
) => {
  const schemaSourceNodes = nodes.filter(
    (n: Node<any>) =>
      globalThis.nodeTypeDefinitions
        .getDefinition(n.type!)
        ?.nodeConfigProperties.some((p: INodeProperty) => p.schemaSourceSystemType)
  );
  schemaSourceNodes.forEach((schemaSourceNode: Node<any>) => {
    const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(schemaSourceNode.type!)!;
    const schemaSourceProperty = nodeDefinition.nodeConfigProperties.find(
      (nodeProperty: INodeProperty) => nodeProperty.schemaSourceSystemType
    );
    const schemaSourceSystemType = schemaSourceProperty?.schemaSourceSystemType!;
    const oldSchemaLinkedNodes: Node<any>[] = schemaSourceNode.data.nodeProperties.schemaLinkedNodes;
    schemaSourceNode.data.nodeProperties.schemaLinkedNodes = [];
    Object.keys(schemaSourceNode.data.nodeProperties.outputs).forEach((outputName: string) => {
      const output = schemaSourceNode.data.nodeProperties.outputs[outputName];
      const outputSchema = getSchema(output.schemaId);
      if (
        outputSchema?.schemaType === DocumentSchemaType.object ||
        outputSchema?.schemaType === DocumentSchemaType.list
      ) {
        let objectSchemaId: string | undefined;
        let objectListSchemaId: string | undefined;
        if (outputSchema.schemaType === DocumentSchemaType.object) {
          objectSchemaId = outputSchema.id;
          objectListSchemaId = getSchemas().find(
            (schema: IDesignerSchema) =>
              schema.schemaType === DocumentSchemaType.list &&
              (schema as unknown as IDocumentListSchema).listItemSchema === objectSchemaId
          )?.id;
        }
        if (outputSchema.schemaType === DocumentSchemaType.list) {
          const itemSchema = getSchema((outputSchema as unknown as IDocumentListSchema).listItemSchema);
          if (itemSchema?.schemaType === DocumentSchemaType.object) {
            objectSchemaId = itemSchema.id;
            objectListSchemaId = outputSchema.id;
          }
        }
        traverseSchemaLinkedOutputs({
          node: schemaSourceNode,
          nodes: nodes,
          edges: edges,
          outputsFilter: [outputName],
          onNodeVisit: (node) => {
            if (node.id !== schemaSourceNode.id) {
              node.data.nodeProperties.schemaSourceSystem = schemaSourceSystemType;
              node.data.nodeProperties.schemaSourceNodeId = schemaSourceNode.id;
              schemaSourceNode.data.nodeProperties.schemaLinkedNodes.push(node);
              node.data.nodeProperties.schemaLinkedInputs = [];
              node.data.nodeProperties.schemaLinkedOutputs = [];
            }
          },
          onInputVisit: (input, node) => {
            if (node.id !== schemaSourceNode.id) {
              const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;
              const newInputSchemaId = isListType(input.type) ? objectListSchemaId : objectSchemaId;
              if (!node.data.nodeProperties.schemaLinkedInputs.some((i: IDataParam) => i.name === input.name)) {
                node.data.nodeProperties.schemaLinkedInputs.push(input);
              }
              if (input.schemaId !== newInputSchemaId) {
                input.schemaId = newInputSchemaId;
                if (nodeDefinition.outputsSchemaProperties) {
                  const objectSchema = getSchema(input.schemaId!)! as unknown as IDocumentObjectSchema;
                  const oldOutputs = node.data.nodeProperties.outputs;
                  const enabledOldOutputs: string[] = [];
                  Object.keys(oldOutputs).forEach((oldOutputName: string) => {
                    const oldOutput = oldOutputs[oldOutputName];
                    if (oldOutput.enabled) {
                      enabledOldOutputs.push(oldOutput.name);
                    }
                  });
                  node.data.nodeProperties.outputs = {};
                  Object.keys(objectSchema.properties).forEach((outputName: string) => {
                    const edgeDetail = new EdgeDetail(node.id, reactFlowInstance);
                    const schemaProperty = objectSchema.properties[outputName]!;
                    node.data.nodeProperties.outputs[outputName] = {
                      name: outputName,
                      type: getDataTypeFromSchema(schemaProperty.schema),
                      schemaId: schemaProperty.schema,
                      required: schemaProperty.required,
                      enabled: reactFlowInstance
                        .getEdges()
                        .some((e: Edge) => e.targetHandle === edgeDetail.getOutput(outputName)?.targetHandle.handleId),
                      hideLabel: false,
                      label: schemaProperty.label
                    } as IDataParam;
                  });
                  forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
                }
              }
            }
          },
          onOutputVisit: (output, node) => {
            if (node.id !== schemaSourceNode.id) {
              const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;
              if (!nodeDefinition.outputsSchemaProperties && isObjectLike(output.type)) {
                const newOutputSchemaId = isListType(output.type) ? objectListSchemaId : objectSchemaId;
                output.schemaId = newOutputSchemaId;
                // Assign to output object on node, output param is a copy and not original object.
                node.data.nodeProperties.outputs[output.name].schemaId = newOutputSchemaId;
                node.data.nodeProperties.schemaLinkedOutputs.push(output);
              }
            }
          }
        });
      }
    });
    if (oldSchemaLinkedNodes) {
      const unlinkedNodes = oldSchemaLinkedNodes.filter(
        (n: Node<any>) =>
          !schemaSourceNode.data.nodeProperties.schemaLinkedNodes.some((ln: Node<any>) => ln.id === n.id)
      );
      unlinkedNodes.forEach((n: Node<any>) => {
        const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(n.type!)!;

        delete n.data.nodeProperties.schemaSourceSystem;
        delete n.data.nodeProperties.schemaSourceNodeId;

        if (nodeDefinition.outputsSchemaProperties) {
          // Unlinked node outputs are empty object,
          //  therefore remove all outputs when they derive from object schema properties.
          n.data.nodeProperties.outputs = {};
          forceNodeUpdate(n, updateNodeInternals, reactFlowInstance);
        }

        n.data.nodeProperties.schemaLinkedInputs?.forEach(
          (input: IDataParam) => (input.schemaId = getEmptySchemaId(input.type))
        );
        n.data.nodeProperties.schemaLinkedOutputs?.forEach(
          (output: IDataParam) => (output.schemaId = getEmptySchemaId(output.type))
        );

        delete n.data.nodeProperties.schemaLinkedOutputs;
        delete n.data.nodeProperties.schemaLinkedInputs;
      });
    }
  });
};

interface ITraverseSchemaLinkedOutputsRequest {
  node: Node<any>;
  nodes: Node<any>[];
  edges: (Edge | Connection)[];
  fromEdge?: Edge | Connection;
  onNodeVisit?: (node: Node<any>, traversedAlongsideSchema?: IDesignerSchema) => void;
  onInputVisit?: (input: IDataParam, node: Node<any>, traversedAlongsideSchema?: IDesignerSchema) => void;
  onOutputVisit?: (
    output: IDataParam,
    node: Node<any>,
    edges: (Edge | Connection)[],
    traversedAlongsideSchema?: IDesignerSchema
  ) => void;
  outputsFilter?: string[];
  traverseAlongsideSchema?: IDesignerSchema;
}

const traverseSchemaLinkedOutputs = (request: ITraverseSchemaLinkedOutputsRequest) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(request.node.type!)!;

  if (request.onNodeVisit) {
    request.onNodeVisit(request.node, request.traverseAlongsideSchema);
  }

  const inputName = getNameFromDataHandleId(request.fromEdge?.sourceHandle ?? "");
  const input = request.node.data.nodeProperties.inputs[inputName];

  if (request.fromEdge && request.onInputVisit && input) {
    request.onInputVisit(input, request.node, request.traverseAlongsideSchema);
  }

  Object.keys(request.node.data.nodeProperties.outputs).forEach((outputName: string) => {
    if (request.outputsFilter && !request.outputsFilter.includes(outputName)) {
      return;
    }
    const output = request.node.data.nodeProperties.outputs[outputName];
    const outputEdges = request.edges.filter(
      (e: Edge) => e.target === request.node.id && e.targetHandle === getOutputHandle(outputName, output.type)
    );
    let traversedSchema: IDesignerSchema | undefined = undefined;

    if (request.traverseAlongsideSchema) {
      if (input?.type === "object" && nodeDefinition.outputsSchemaProperties) {
        const objectSchema = request.traverseAlongsideSchema as unknown as IDocumentObjectSchema;
        const propertySchemaId = objectSchema?.properties[outputName]?.schema;
        if (propertySchemaId) {
          traversedSchema = getSchema(propertySchemaId);
        }
      }

      if (isListType(input?.type) && output?.type === "object") {
        const objectListSchema = request.traverseAlongsideSchema as unknown as IDocumentListSchema;
        if (objectListSchema) {
          const objectSchemaId = objectListSchema.listItemSchema;
          if (objectSchemaId) {
            traversedSchema = getSchema(objectSchemaId);
          }
        }
      }
    }

    // Verify data types match between output and the traversed schema.
    if (traversedSchema && output.type !== getDataTypeFromSchemaInstance(traversedSchema)) {
      traversedSchema = undefined;
    }

    if (request.onOutputVisit) {
      request.onOutputVisit({ ...output, name: outputName }, request.node, outputEdges, traversedSchema);
    }

    if (isObjectLike(output.type) && !nodeDefinition.outputsSchemaProperties) {
      outputEdges.forEach((e: Edge) => {
        const nextNode = request.nodes.find((n: Node<any>) => n.id === e.source)!;
        traverseSchemaLinkedOutputs({
          node: nextNode,
          fromEdge: e,
          nodes: request.nodes,
          edges: request.edges,
          onNodeVisit: request.onNodeVisit,
          onInputVisit: request.onInputVisit,
          onOutputVisit: request.onOutputVisit,
          traverseAlongsideSchema: traversedSchema
        });
      });
    }
  });
};

export const getDesignerDataEdge = (
  input: IDataParam,
  outputType: DataType,
  sourceNodeId: string,
  outputName: string,
  targetNodeId: string
) => {
  return {
    source: sourceNodeId,
    sourceHandle: getInputHandle(input.name, input.type),
    target: targetNodeId,
    targetHandle: getOutputHandle(outputName, outputType)
  };
};

export const getInputHandle = (name: string, type: string) => `${name}-${type}-input`;

export const getOutputHandle = (name: string, type: string) => `${name}-${type}-output`;

export const getDesignerControlEdge = (
  inputName: string,
  sourceNodeId: string,
  outputName: string,
  targetNodeId: string
) => {
  return {
    source: sourceNodeId,
    sourceHandle: "FlowInHandle" + inputName,
    target: targetNodeId,
    targetHandle: `FlowOutHandle${outputName}`
  };
};

export const getNameFromControlHandleId = (handleId: string) =>
  handleId.replace("FlowInHandle", "").replace("FlowOutHandle", "");

export const getNameFromDataHandleId = (handleId: string): string => {
  let handleIdParts: any = handleId?.split("-") ?? [];
  return handleIdParts[0];
};

export const dataHandleIsInput = (handleId: string) => {
  let handleIdParts: any = handleId?.split("-") ?? [];
  if (handleIdParts.length > 2 && handleIdParts[2] === "input") {
    return true;
  }

  return false;
};

export const dataHandleIsOutput = (handleId: string) => {
  let handleIdParts: any = handleId?.split("-") ?? [];
  if (handleIdParts.length > 2 && handleIdParts[2] === "output") {
    return true;
  }

  return false;
};

export interface IEdgeDeletionMode {
  inputEdges: boolean;
  outputEdges: boolean;
  controlEdges: boolean;
  filteredProperties?: string[];
}

export const allEdgesDeletionMode = { inputEdges: true, outputEdges: true, controlEdges: true };

export const deleteEdgesFromNodes = (
  nodeIds: string[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  mode: IEdgeDeletionMode = allEdgesDeletionMode,
  currentEdges?: Edge[]
): Edge[] => {
  const deletingEdges = getDeletingEdgesFromNodes(nodeIds, reactFlowInstance, mode, currentEdges);
  const connectedNodes = reactFlowInstance
    .getNodes()
    .filter(
      (n: Node<any>) =>
        !nodeIds.some((nodeId: string) => nodeId === n.id) &&
        deletingEdges.some((e) => e.source === n.id || e.target === n.id)
    );
  let newEdges: Edge[] = [];
  reactFlowInstance.setEdges((edges) => {
    newEdges = (currentEdges ?? edges).filter((e) => !deletingEdges.some((de: Edge<any>) => e.id === de.id));
    return newEdges;
  });
  connectedNodes.forEach((n: Node<any>) => forceNodeUpdate(n, updateNodeInternals, reactFlowInstance));

  // Need to remove the node from anchored nodes list on the parent
  reactFlowInstance.setNodes((nds) => {
    nds.forEach((updatedNode: Node<any>) => {
      const anchoredDeletingNode = connectedNodes.find(
        (connectedNode: Node<any>) =>
          connectedNode.data.nodeProperties.anchoredToNodeId === updatedNode.id &&
          deletingEdges.some(
            (e: Edge) =>
              (e.source === updatedNode.id && e.target === connectedNode.id) ||
              (e.target === updatedNode.id && e.source === connectedNode.id)
          )
      );
      if (anchoredDeletingNode) {
        delete anchoredDeletingNode.data.nodeProperties.anchoredToNodeId;
        updatedNode.data.nodeProperties.anchoredNodes = updatedNode.data.nodeProperties.anchoredNodes.filter(
          (anchor: AnchorProperties) => anchor.nodeId !== anchoredDeletingNode.id
        );
      }

      if (connectedNodes.some((c: Node<any>) => c.id === updatedNode.id)) {
        // This is an undoing of what docking does. The zIndex is modified as part of docking,
        // since the nodes need to sit on top of each other. Removing the zIndex resets this.
        updatedNode.zIndex = undefined;
      }
    });
    return nds;
  });

  updateEdgeValidationStyling(newEdges);

  return newEdges;
};

export const getDeletingEdgesFromNodes = (
  nodeIds: string[],
  reactFlowInstance: ReactFlowInstance,
  mode: IEdgeDeletionMode = allEdgesDeletionMode,
  currentEdges?: Edge[]
): Edge[] => {
  let deletingEdges = (currentEdges ?? reactFlowInstance.getEdges()).filter((e) =>
    nodeIds.some(
      (nodeId: string) =>
        ((mode.inputEdges && e.source === nodeId) || (mode.outputEdges && e.target === nodeId)) &&
        (mode.controlEdges ||
          (!mode.controlEdges && e.sourceHandle?.indexOf("FlowIn") !== 0 && e.targetHandle?.indexOf("FlowOut") !== 0))
    )
  );
  if (mode.filteredProperties) {
    deletingEdges = deletingEdges.filter((e: Edge<any>) =>
      mode.filteredProperties!.some(
        (propertyName: string) => e.sourceHandle?.includes(propertyName) || e.targetHandle?.includes(propertyName)
      )
    );
  }

  return deletingEdges;
};
