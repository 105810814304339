import { Comparer } from "@components/Common.types";

/** Pushes the items onto a new array with same items. */
export const push = <T>(arr: T[], ...items: T[]) => {
  return [...arr, ...items];
};

/** Removes the item at the given index, returning a new array. */
export const removeAt = <T>(arr: T[], index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

/**
 * Removes the last item from the array, returning a tuple
 * of the removed item and a new array without the item.
 */
export const pop = <T>(arr: T[]): [T | undefined, T[]] => {
  if (!arr.length) {
    // eslint-disable-next-line no-sparse-arrays
    return [, []];
  }

  const index = arr.length - 1;
  const item = arr[index];
  return [item, removeAt(arr, index)];
};

/**
 * Removes the first instance found of the given item
 * (if found) and returns a new array without the item.
 */
export const remove = <T>(arr: T[], item: T) => {
  const index = arr.indexOf(item);
  if (index === -1) {
    return arr.slice();
  }

  return removeAt(arr, index);
};

/** Joins two or more arrays into a new array. */
export const concat = <T>(...arrs: Array<T | ConcatArray<T>>) => {
  return ([] as T[]).concat(...arrs);
};

// node pre-v12 does not have a stable sort and we NEED to have stable results when running tests
// (All modern browsers have stable sorts, so this polyfill is only really needed during test runs)
const isProbablyBrowser = typeof process !== "undefined" && process.versions.node == null;
const stableSort = isProbablyBrowser
  ? // eslint-disable-next-line @typescript-eslint/unbound-method
    Array.prototype.sort
  : function stableSort<T>(this: T[], comparer?: Comparer<T>) {
      // eslint-disable-next-line no-nested-ternary, no-confusing-arrow
      const valueComparer = comparer || ((a: T, b: T) => (a > b ? 1 : a < b ? -1 : 0));
      // eslint-disable-next-line no-invalid-this
      return this.map((value, index) => ({ value, index }))
        .sort((a, b) => {
          const compareValue = valueComparer(a.value, b.value);
          if (compareValue !== 0) {
            return compareValue;
          }

          return a.index - b.index;
        })
        .map(x => x.value);
    };

/**
 * Sorts an array with the given comparer function,
 * returning a new array with the items sorted.
 */
export const sort = <T>(arr: T[], comparer?: Comparer<T>) => {
  return stableSort.call(arr.slice(), comparer);
};

/**
 * Creates a new array with the count removed from the array and optionally
 * replaced by specified items.
 */
export const splice = <T>(arr: T[], start: number, deleteCount: number, ...items: T[]) => {
  const before = arr.slice(0, start);
  const after = arr.slice(start + deleteCount);
  return [...before, ...items, ...after];
};

/**
 * Creates a new array with the element at the from index moved to the
 * toIndex position.
 */
export const move = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  const copy = arr.slice();
  copy.splice(fromIndex, 1);
  copy.splice(toIndex, 0, element);
  return copy;
};

/**
 * Compares two arrays and determines whether they have the same
 * values, using a strict equality check. This does not confirm
 * the order is the same. This also expects that there are no
 * duplicate values within the arrays.
 */
export const hasSameValues = <T>(a: T[], b: T[]) => {
  if (a === b) {
    return true;
  }

  if (a.length !== b.length) {
    return false;
  }

  return a.every(x => b.includes(x));
};

/**
 * Compares two arrays and determines whether they contain the same values
 * in the same order, using a strict equality check.
 */
export const areSame = <T>(a?: T[], b?: T[]) => {
  if (a === b) {
    return true;
  }

  if (a == null || b == null) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  return a.every((x, i) => x === b[i]);
};
