import React, { FunctionComponent } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import { ITableRowProps } from "./DataTable.types";
import styles from "./DataTable.module.scss";

export const HtmlDataTableFooter: FunctionComponent<ICommonProps> = ({ children, ...other }) => {
  return <tfoot {...other}>{children}</tfoot>;
};

const XmlDataTableFooter: FunctionComponent<ICommonProps> = ({ children, ...other }) => {
  return React.createElement("grid-table-footer", { ...other }, children);
};

export const HtmlDataTableFooterRow: FunctionComponent<ITableRowProps> = ({ children, className, ...other }) => {
  return (
    <tr className={clsx(styles.footerRow, className)} {...other}>
      {children}
    </tr>
  );
};

const XmlDataTableFooterRow: FunctionComponent<ITableRowProps> = ({ children }) => {
  return React.createElement("grid-table-row", {}, children);
};

export const DataTableFooterRow = withPrinting(HtmlDataTableFooterRow, XmlDataTableFooterRow);
export const DataTableFooter = withPrinting(HtmlDataTableFooter, XmlDataTableFooter);
