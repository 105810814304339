import React, { PropsWithChildren, MouseEvent, ReactElement, FC, createElement } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { Clickable } from "../Link";
import { useTree, useTreeNode } from "./TreeContext";
import { ITreeLeafProps, ItemMovedArgs } from "./Tree.types";
import styles from "./Tree.module.scss";

// eslint-disable-next-line func-style
function HtmlTreeLeaf<T>({
  onClick,
  onDoubleClick,
  children,
  dragDrop,
  onDragOver,
  onDragStart,
  onDragEnd,
  onDragLeave,
  onDragEnter,
  leafNodeObject,
  ...other
}: PropsWithChildren<ITreeLeafProps>): ReactElement {
  const node = useTreeNode<T>();
  const { selectable, selectedKeys, keySelector, toggleSelection, onItemMoved } = useTree();
  const selected = selectedKeys.includes(keySelector(node));

  const handleClick = React.useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onClick?.(e);
      if (selectable) {
        toggleSelection(node);
      }
    },
    [onClick, selectable, node, toggleSelection]
  );

  const handleDragOver = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragOver?.(e);
      e.preventDefault();
    },
    [onDragOver, dragDrop, node]
  );

  const handleDragEnter = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragEnter?.(e);
      e.preventDefault();
    },
    [onDragEnter, dragDrop, node]
  );

  const handleDragStart = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("node", JSON.stringify(node));
      onDragStart?.(e);
    },
    [onDragStart, node, dragDrop]
  );

  const handleDragLeave = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return;
      }
      e.dataTransfer.setData("dragnode", JSON.stringify(node));
      onDragLeave?.(e);
    },
    [onDragLeave, node, dragDrop]
  );

  const handleDrop = React.useCallback(
    (e: React.DragEvent<HTMLLIElement>) => {
      if (!dragDrop) {
        return false;
      }
      onDragEnd?.(e);
      const sourceNode = e.dataTransfer.getData("node") === "" ? {} : JSON.parse(e.dataTransfer.getData("node"));
      const branchNode = e.dataTransfer.getData("branch") === "" ? {} : JSON.parse(e.dataTransfer.getData("branch"));
      const itemMovedArgs: ItemMovedArgs<T> = {
        sourceNode,
        branchNode,
        destinationNode: leafNodeObject
      };
      onItemMoved?.(itemMovedArgs);
      e.dataTransfer.clearData();
      e.preventDefault();
      return true;
    },
    [dragDrop, onDragEnd, leafNodeObject, onItemMoved]
  );

  return (
    <li
      className={clsx(styles.leaf, selected && styles.selected)}
      draggable={dragDrop}
      {...other}
      onDragStart={handleDragStart}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <Clickable as="a" onClick={handleClick} onDoubleClick={onDoubleClick} className={styles.title}>
        {children}
      </Clickable>
    </li>
  );
}

const XmlTreeLeaf: FC = ({ children }) => {
  const child = createElement("name", {}, children);
  return createElement("plex-control-tree-node", { opened: false }, child);
};

export const TreeLeaf = withPrinting(HtmlTreeLeaf, XmlTreeLeaf);
