import React, { useRef, CSSProperties, ReactElement, FunctionComponent, createElement } from "react";
import { ICommonProps } from "@components/Common.types";
import { ReactComponentLike } from "prop-types";
import { withPrinting } from "@plex/react-xml-renderer";
import { useSticky } from "./use-sticky";
import { IStickyProps } from "./Sticky.types";

interface IStickyHeaderRowProps extends IStickyProps {
  /**
   * Determines the component to render the row as.
   * @default tr
   */
  as?: "tr" | ReactComponentLike;
}

/**
 * Component which can be used within a THEAD element to cause the
 * rendered row to be sticky. The `as` property can be used to specify
 * the component to be rendered. The default will be a TR element.
 * An offset property will determine any offset to applied when the
 * component is sticky. All other props will be passed directly into
 * the rendered component specified using the `as` prop.
 *
 * @deprecated Use the Sticky component around a thead based component instead.
 */
const HtmlStickyHeaderRow: FunctionComponent<IStickyHeaderRowProps> = ({
  offset = 0,
  as: Component = "tr",
  children,
  ...other
}) => {
  const trRef = useRef<HTMLTableRowElement>(null);
  const stickyStyle = useSticky(trRef, offset);

  return (
    <Component {...other} ref={trRef}>
      {React.Children.map(children, c => {
        if (!React.isValidElement(c)) {
          return c;
        }

        const style: CSSProperties = {
          ...(c.props as ICommonProps).style,
          ...stickyStyle
        };

        return React.cloneElement(c as ReactElement, { style });
      })}
    </Component>
  );
};

const XmlStickyHeaderRow: FunctionComponent<IStickyHeaderRowProps> = ({ children }) => {
  return createElement("grid-table-row", {}, children);
};

export const StickyHeaderRow = withPrinting(HtmlStickyHeaderRow, XmlStickyHeaderRow);
