import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "StringReplaceNode",
  label: "Replace Text",
  category: NodeTypeCategory.text,
  controlOutputs: [],
  dataInputs: [
    { name: "source", type: DataType.STRING },
    { name: "pattern", label: "Find", type: DataType.STRING },
    { name: "replacement", label: "Replace", type: DataType.STRING }
  ],
  dataOutputs: [{ name: "result", type: DataType.STRING }],
  evaluate: (input: any) => {
    return {
      output: { result: (input.source as string).replaceAll(input.pattern as string, input.replacement as string) },
      activeControlHandleIds: []
    };
  }
});

export const StringReplaceNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
