import React, { FunctionComponent, useState } from "react";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";

import {
  Dialog,
  Form,
  TextInput,
  InputSize,
  Dropdown,
  DataTableDef,
  SelectionMode,
  Button,
  NotifyDialog
} from "@plex/react-components";

import { primitiveTypesSelect } from "../NodeTypes/TypeDefinitions";
import { useViewController } from "../ViewContext";
import { IFlowInputProperties } from "./FlowInputManagementController";
import "./FlowInputManagement.scss";

export interface IFlowInputManagementFormParams {
  show: boolean;
  onClose: () => void;
}

export const FlowInputManagement: FunctionComponent<IFlowInputManagementFormParams> = (props) => {
  const { show, onClose } = props;
  return (
    <Dialog show={show} title="Flow Input Management" onHide={onClose}>
      <div style={{ height: "auto" }}>
        <FlowInputManagementPropertiesForm onClose={onClose} />
      </div>
    </Dialog>
  );
};

const FlowInputManagementPropertiesForm = (props: any) => {
  const { onClose } = props;
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [selectedType, setSelectedType] = useState({ key: "string", value: "string" });
  const [propertyName, setPropertyName] = useState<string>("");
  const [propertyValue, setPropertyValue] = useState<string>("");
  const [notifyModal, setNotifyModal] = useState<any>({ show: false });
  const { flowInputController } = useViewController();
  const [flowProperties, setFlowProperties] = useState<IFlowInputProperties[]>(flowInputController?.flowInputPool);

  const closeNotifyModal = () => {
    setNotifyModal({ show: false });
  };

  const renderNotifyModal = (title: string, message: string) => {
    return <NotifyDialog show={notifyModal.show} title={title} message={message} onHide={closeNotifyModal} />;
  };

  const checkForFlowInputNodesOutputInconsitency = (deletedOutput: IFlowInputProperties) => {
    const nodes: any[any] = reactFlowInstance.getNodes().filter((x) => x.type === "flowInputs");
    const edgesToDelete: any[any] = reactFlowInstance.getEdges().filter((x) => {
      const handleId = x.targetHandle;
      const value = handleId?.split("-") || [];
      if (value.length === 3 && value[0] === deletedOutput.propertyName) {
        return true;
      }

      return false;
    });

    if (nodes.length > 0) {
      nodes.forEach((node: any) => {
        if (Object.keys(node!.data.nodeProperties.outputs).length > 0) {
          const filteredOutputs = Object.values(node!.data.nodeProperties.outputs).filter(
            (obj: any) => obj.propertyName !== deletedOutput.propertyName
          );

          node!.data.nodeProperties.outputs = Object.assign(
            {},
            ...filteredOutputs.map((obj: any) => ({ [obj.propertyName]: obj }))
          );
        }
        const nodeIds = nodes.map((x: any) => x.id);

        // Delete edges since we are delete the given entry
        setTimeout(() => {
          reactFlowInstance.setEdges((edges) =>
            edges.filter((e) => edgesToDelete.filter((de: any) => e.id === de.id).length === 0)
          );
        }, 5);

        updateNodeInternals(nodeIds);
      });
    }
  };

  const handleRemove = React.useCallback(
    (input: IFlowInputProperties) => {
      flowInputController.deleteProperties(input);
      setFlowProperties(flowProperties.filter((v) => v.propertyName !== input.propertyName));
      checkForFlowInputNodesOutputInconsitency(input);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowProperties]
  );

  const handleAdd = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    let castValued: any = null;

    switch (selectedType.value) {
      case "integer":
        castValued = convertToNumber(propertyValue);
        break;

      case "decimal":
        castValued = convertToDecimal(propertyValue);
        break;

      case "boolean":
        castValued = convertToBoolean(propertyValue);
        break;

      default:
        castValued = propertyValue;
    }

    if (castValued) {
      // sync state
      setFlowProperties(
        flowProperties.concat({
          propertyName: propertyName!,
          propertyType: selectedType.value,
          propertyValue: castValued!
        })
      );

      // update storage
      const addedFlag = flowInputController.setProperties({
        propertyName: propertyName,
        propertyType: selectedType.value,
        propertyValue: castValued
      });

      // incase of duplicate re-sync the state
      if (!addedFlag) {
        setFlowProperties(flowInputController.flowInputPool);
        setNotifyModal({ show: true });
        // throw new Error("Duplicate item");
      }
    }

    if (castValued == null) {
      throw new Error("Invalid Type");
    }

    //flush out
    setPropertyName("");
    setPropertyValue("");
  };

  const convertToNumber = (value: any) => {
    if (/^[0-9]+$/.test(value)) {
      // if the value contains only numeric characters
      return parseInt(value, 10); // or parseFloat(value) if decimal numbers are allowed
    } else {
      return null;
    }
  };

  const convertToDecimal = (value: any) => {
    if (/^[0-9]+(\.[0-9]+)?$/.test(value)) {
      // If the value contains only numeric characters or decimal numbers
      return parseFloat(value).toFixed(5);
    } else {
      return null;
    }
  };

  const convertToBoolean = (value: any) => {
    if (value.toLowerCase() === "true") {
      return true;
    } else if (value.toLowerCase() === "false") {
      return false;
    } else {
      return null;
    }
  };

  return (
    <Form>
      {notifyModal.show && renderNotifyModal("Duplicate Item", "The given item already exists")}
      <Form.Row style={{ textAlign: "center", paddingTop: "10px" }}>
        {/* Name textbox */}
        <Form.FieldPair labelText="Name" style={{ textAlign: "left" }}>
          <TextInput
            key="property_name_text"
            style={{ width: "50%" }}
            value={propertyName}
            // value={"Property Name"}
            placeholder="Unique Name"
            size={InputSize.dynamic}
            type="text"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value;
              if (value === "" || value === undefined) {
                return;
              } else {
                setPropertyName(value);
              }
            }}
          />
        </Form.FieldPair>
      </Form.Row>
      {/* Type drop down */}
      <Form.Row style={{ textAlign: "center" }}>
        <Form.FieldPair labelText="Type" style={{ textAlign: "left" }}>
          <Dropdown
            items={primitiveTypesSelect}
            keySelector={(item: { [key: string]: string }) => item.key}
            displaySelector={(item: { [key: string]: string }) => item.value}
            selected={[selectedType]}
            style={{ width: "120%", paddingRight: "120px" }}
            onSelectionChanged={(values: any[]) => {
              if (values?.length === 1) {
                setSelectedType(values[0]);
              }
            }}
          />
        </Form.FieldPair>
      </Form.Row>
      {/* Type default value textbox */}
      <Form.Row style={{ textAlign: "center" }}>
        <Form.FieldPair labelText="Default Value" style={{ textAlign: "left" }}>
          <TextInput
            key="property_name_value"
            style={{ width: "50%" }}
            value={propertyValue}
            placeholder="Value"
            size={InputSize.dynamic}
            type="text"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value;
              if (value === "" || value === undefined) {
                return;
              } else {
                setPropertyValue(value);
              }
            }}
          />
        </Form.FieldPair>
      </Form.Row>
      {/* Add button */}
      <Form.Row style={{ textAlign: "center" }}>
        <Button variance="primary" as="button" type="button" onClick={handleAdd}>
          Add
        </Button>
      </Form.Row>
      {/* Grid */}
      <Form.Row className="flowinput-grid-form-row">
        <FlowInputGridComponent data={flowProperties} onRemove={handleRemove} />
      </Form.Row>
      {/* OK button */}
      <Form.Row style={{ textAlign: "right", paddingTop: "10px" }}>
        <Button variance={"primary"} as={"button"} type="button" onClick={onClose}>
          OK
        </Button>
      </Form.Row>
    </Form>
  );
};

const FlowInputGridComponent = React.memo((props: any) => {
  const { data, onRemove } = props;

  return (
    <DataTableDef<IFlowInputProperties>
      data={data}
      keySelector={(row: any) => row.propertyName}
      selectionMode={SelectionMode.none}
      className="flowinput-table-layout"
      emptyText="No records were found."
    >
      <DataTableDef.Column<IFlowInputProperties> id="1" title="Name" className="flowinput-grid-column" sortable>
        {(row: any) => row.propertyName}
      </DataTableDef.Column>
      <DataTableDef.Column<IFlowInputProperties> id="2" title="Type" className="flowinput-grid-column" sortable>
        {(row: any) => row.propertyType}
      </DataTableDef.Column>
      <DataTableDef.Column<IFlowInputProperties> id="3" title="Value" className="flowinput-grid-column" sortable>
        {(row: any) => row.propertyValue}
      </DataTableDef.Column>
      <DataTableDef.Column<IFlowInputProperties> id="4" title="Delete" className="flowinput-grid-column">
        {(row: any) => <DeleteImage onRemove={onRemove} row={row} />}
      </DataTableDef.Column>
    </DataTableDef>
  );
});

const DeleteImage = React.memo((props: any) => {
  const { onRemove, row } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={`flowinput-grid-icon ${isHovered ? "flowinput-grid-delete-icon-fade-in-out" : ""}`}
      src="delete-icon-grid.png"
      alt="delete-image"
      onClick={() => {
        onRemove(row);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
});

DeleteImage.displayName = "DeleteImage";
FlowInputGridComponent.displayName = "FlowInputGridComponent";
