import { useCallback, useRef, useLayoutEffect } from "react";

// based on https://github.com/facebook/react/issues/14099#issuecomment-440013892
/**
 * Returns the a stable callback which wraps the provided callback by reference
 * so that the callback associated with the most recent render is always used.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEventCallback = <T extends (...args: any[]) => ReturnType<T>>(callback: T) => {
  const ref = useRef<T>(callback);

  useLayoutEffect(() => {
    ref.current = callback;
  });

  return useCallback((...args) => ref.current?.(...args), []);
};
