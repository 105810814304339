import { useContext, useState, useLayoutEffect, useEffect } from "react";
import { PrintContext } from "./PrintContext";

const noop = () => {};

/**
 * This allows async processes to delay printing until the process has been completed.
 * This will execute as an effect with a function passed in as an argument that allows
 * you to mark the process as completed. When called outside of a printing context this
 * will run as a one-time effect.
 *
 * @param func Function to be executed
 */
export const usePrintAwaiter = (func: (resolve: () => void) => void) => {
  const [trigger, setTrigger] = useState<() => void>();
  const context = useContext(PrintContext);

  // Using a layout effect so any async promises are registered
  // before they are awaited.
  useLayoutEffect(() => {
    if (!context.isPrinting) {
      return;
    }

    context.waitFor(
      new Promise(resolve => {
        func(() => {
          setTrigger(() => resolve);
        });
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    if (context.isPrinting) {
      trigger?.();
    } else {
      func(noop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isPrinting, trigger]);
};
