/* eslint-disable no-invalid-this */
/* eslint-disable complexity */
import React, { createRef, PureComponent, SyntheticEvent } from "react";
import { CssLength, ICommonProps } from "@components/Common.types";
import clsx from "clsx";
import styles from "./ResizableContainer.module.scss";

export interface IResizeInfo {
  width: number;
  height: number;
  isResizing?: boolean;
}

export interface IResizableContainerProps extends ICommonProps {
  /** Minimum width */
  minWidth?: CssLength;
  /** Maximum width */
  maxWidth?: CssLength;
  /** Minimum height */
  minHeight?: CssLength;
  /** Maximum height */
  maxHeight?: CssLength;
  /** Use corner resize */
  useCorner?: boolean;
  /** Disable left resize */
  disableLeft?: boolean;
  /** Disable right resize */
  disableRight?: boolean;
  /** Disable bottom resize */
  disableBottom?: boolean;
  /** Invoke when component resized */
  onResize?: (resizeInfo: IResizeInfo) => void;
}

enum Side {
  S = 1,
  E,
  W,
  SW,
  SE
}

interface ISize {
  height?: number;
  width?: number;
}

interface IResizableContainerState {
  size: ISize;
  isResizing: boolean;
  resized: boolean;
  side?: Side;
}

const isMouseEvent = (e: unknown): e is React.MouseEvent => {
  return e && typeof e === "object" && (e as SyntheticEvent).type === "mousedown";
};

export class ResizableContainer extends PureComponent<IResizableContainerProps, IResizableContainerState> {
  private readonly containerRef = createRef<HTMLDivElement>();

  private previousPosition = {
    x: 0,
    y: 0
  };

  public constructor(props: IResizableContainerProps) {
    super(props);
    this.state = {
      size: {
        width: undefined,
        height: undefined
      },
      isResizing: false,
      resized: false
    };

    const { onResize = () => {} } = props;

    this.onResize = onResize;
  }

  public componentWillUnmount() {
    document.body.removeEventListener("mousemove", this.onMouseMove as (this: HTMLElement, ev: MouseEvent) => unknown);
    document.body.removeEventListener("touchmove", this.onMouseMove);
    document.body.removeEventListener("mouseup", this.onMouseUp);
    document.body.removeEventListener("touchend", this.onMouseUp);
    document.body.removeEventListener("mouseleave", this.onMouseUp);
  }

  public getRef = () => {
    return this.containerRef;
  };

  private readonly onResize: (resizeInfo: IResizeInfo) => void;

  private readonly onMouseDown = (e: Side, event: React.MouseEvent | React.TouchEvent) => {
    const bounds = this.getRef().current!.getBoundingClientRect();
    if (isMouseEvent(event)) {
      this.previousPosition.x = event.screenX;
      this.previousPosition.y = event.screenY;
    } else {
      this.previousPosition.x = event.touches[0].screenX;
      this.previousPosition.y = event.touches[0].screenY;
    }

    const size = { width: bounds.width, height: bounds.height };
    this.setState({ isResizing: true, resized: true, side: e, size });

    document.body.addEventListener("mousemove", this.onMouseMove);
    document.body.addEventListener("touchmove", this.onMouseMove);
    document.body.addEventListener("mouseup", this.onMouseUp);
    document.body.addEventListener("touchend", this.onMouseUp);
    document.body.addEventListener("mouseleave", this.onMouseUp);
  };

  private readonly onMouseUp = () => {
    this.setState({ isResizing: false });
    const { size } = this.state;

    this.onResize({
      width: size.width || 0,
      height: size.height || 0,
      isResizing: false
    });

    document.body.removeEventListener("mousemove", this.onMouseMove);
    document.body.removeEventListener("touchmove", this.onMouseMove);
    document.body.removeEventListener("mouseup", this.onMouseUp);
    document.body.removeEventListener("touchend", this.onMouseUp);
    document.body.removeEventListener("mouseleave", this.onMouseUp);
  };

  private readonly onMouseMove = (e: MouseEvent | TouchEvent) => {
    const { isResizing, size, side } = this.state;

    if (!isResizing) {
      return;
    }

    if (e.which === 1 || e.type === "touchmove") {
      let movementX = 0;
      let movementY = 0;
      if (e.type === "touchmove") {
        const event = e as TouchEvent;
        movementX = event.touches[0].screenX - this.previousPosition.x;
        movementY = event.touches[0].screenY - this.previousPosition.y;
        this.previousPosition = {
          x: event.touches[0].screenX,
          y: event.touches[0].screenY
        };
      } else {
        const event = e as MouseEvent;
        movementX = event.screenX - this.previousPosition.x;
        movementY = event.screenY - this.previousPosition.y;
        this.previousPosition = {
          x: event.screenX,
          y: event.screenY
        };
      }

      switch (side) {
        case Side.S:
          this.performResize(
            this.checkSize({
              ...size,
              height: size.height ? size.height + movementY : movementY
            })
          );
          return;
        case Side.E:
          this.performResize(
            this.checkSize({
              ...size,
              width: size.width ? size.width + movementX : movementX
            })
          );
          return;
        case Side.W:
          this.performResize(
            this.checkSize({
              ...size,
              width: size.width ? size.width - movementX : -movementX
            })
          );
          return;
        case Side.SW:
          this.performResize(
            this.checkSize({
              width: size.width ? size.width - movementX : -movementX,
              height: size.height ? size.height + movementY : movementY
            })
          );
          return;
        case Side.SE:
          this.performResize(
            this.checkSize({
              width: size.width ? size.width + movementX : movementX,
              height: size.height ? size.height + movementY : movementY
            })
          );
          break;

        default:
      }
    } else {
      this.onMouseUp();
    }
  };

  private readonly checkSize = (size: ISize): ISize => {
    const { isResizing, resized } = this.state;
    if (!isResizing && !resized) {
      return size;
    }

    const {
      maxWidth,
      minWidth,
      maxHeight,
      minHeight,
      disableLeft = false,
      disableRight = false,
      disableBottom = false
    } = this.props;
    const container = this.getRef().current;
    if (!container) {
      return size;
    }

    const resultSize = size;

    if (typeof minHeight === "number" && container.offsetHeight < minHeight) {
      resultSize.height = minHeight;
    }

    if (typeof maxHeight === "number" && container.offsetHeight > maxHeight) {
      resultSize.height = maxHeight;
    }

    if (typeof minWidth === "number" && container.offsetWidth < minWidth) {
      resultSize.width = minWidth;
    }

    if (typeof maxWidth === "number" && container.offsetWidth > maxWidth) {
      resultSize.width = maxWidth;
    }

    if ((!disableLeft || !disableRight) && disableBottom) {
      resultSize.height = undefined;
    }

    if (disableLeft && disableRight && !disableBottom) {
      resultSize.width = undefined;
    }

    return resultSize;
  };

  private readonly performResize = (size: ISize): void => {
    this.setState({
      size
    });

    const { isResizing } = this.state;

    this.onResize({
      width: size.width || 0,
      height: size.height || 0,
      isResizing
    });
  };

  public render = () => {
    const { size, isResizing } = this.state;
    const {
      children,
      maxWidth,
      minWidth,
      maxHeight,
      minHeight,
      useCorner = true,
      disableLeft = false,
      disableRight = false,
      disableBottom = false,
      className,
      style
    } = this.props;

    const combinedStyle = {
      ...style,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
      width: !isResizing && !size.width && style?.width ? style.width : size.width,
      height: size.height
    };

    const resizableClassName = clsx(styles.content, className, isResizing && styles.disableSelect);
    const resizableE = clsx(styles.resizableE, styles.resizable);
    const resizableS = clsx(styles.resizableS, styles.resizable);
    const resizableW = clsx(styles.resizableW, styles.resizable);
    const resizableSE = clsx(styles.resizableSe, styles.resizable);
    const resizableSW = clsx(styles.resizableSw, styles.resizable);

    return (
      <div style={combinedStyle} ref={this.containerRef} className={resizableClassName}>
        {children}
        {!disableLeft && (
          <div
            onMouseDown={this.onMouseDown.bind(this, Side.W)}
            onTouchStart={this.onMouseDown.bind(this, Side.W)}
            className={resizableW}
          />
        )}
        {!disableBottom && (
          <div
            onMouseDown={this.onMouseDown.bind(this, Side.S)}
            onTouchStart={this.onMouseDown.bind(this, Side.S)}
            className={resizableS}
          />
        )}
        {!disableRight && (
          <div
            onMouseDown={this.onMouseDown.bind(this, Side.E)}
            onTouchStart={this.onMouseDown.bind(this, Side.E)}
            className={resizableE}
          />
        )}
        {useCorner && !(disableBottom || disableLeft) && (
          <div
            onMouseDown={this.onMouseDown.bind(this, Side.SW)}
            onTouchStart={this.onMouseDown.bind(this, Side.SW)}
            className={resizableSW}
          />
        )}
        {useCorner && !(disableBottom || disableRight) && (
          <div
            onMouseDown={this.onMouseDown.bind(this, Side.SE)}
            onTouchStart={this.onMouseDown.bind(this, Side.SE)}
            className={resizableSE}
          />
        )}
      </div>
    );
  };
}
