import React, { FunctionComponent } from "react";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { Button } from "@plex/react-components";
import "./NodePropertiesForm.scss";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";

export const ViewDataSourceDetailsField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const dataSourceId = props.node?.data.nodeProperties?.dataSourceId ?? "";
  let buttonLabel: string = "";

  const functionSubscriber = usefunctionSubscriber();

  const handleOnClick = () => {
    if (functionSubscriber.navigateToDataSourcePage) {
      if (dataSourceId === null || dataSourceId === "") {
        functionSubscriber.navigateToDataSourcePage(null, true);
      } else {
        functionSubscriber.navigateToDataSourcePage({ DataSourceKey: dataSourceId }, true);
      }
    }
  };

  if (dataSourceId === null || dataSourceId === "") {
    buttonLabel = "Search Data Sources";
  } else {
    buttonLabel = "View Details";
  }

  return (
    <div style={{ marginTop: "-14px", position: "fixed" }}>
      <Button onClick={handleOnClick}>{buttonLabel}</Button>
    </div>
  );
};
