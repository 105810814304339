import React, { FunctionComponent, ChangeEvent, useState, useMemo, useEffect, useRef } from "react";
import { useLocalization } from "@plex/culture-react";
import { LocalDate } from "js-joda";
import clsx from "clsx";
import dropdownStyles from "../Dropdown/Dropdown.module.scss";
import { StandardRangeFilters, EmptyRange } from "./CalendarIntervals";
import { RangeFactory, IDateRangePickerProps } from "./DatePicker.types";
import styles from "./DatePicker.module.scss";

const standardRanges = StandardRangeFilters();

export const DateRangeSelector: FunctionComponent<IDateRangePickerProps> = ({
  weekStart,
  range,
  onRangeChange,
  rangeFilters,
  ...other
}) => {
  const { t, locale } = useLocalization();
  const [selectedRange, setSelectedRange] = useState(EmptyRange.messageId);
  const lastSelected = useRef(range || null);

  const ranges = useMemo(() => {
    if (rangeFilters == null) {
      return standardRanges;
    }

    // make sure to exclude empty groups
    return rangeFilters.filter(x => x.intervals.length > 0);
  }, [rangeFilters]);

  // todo: this is currently keyed off the message ID, however
  // these could technically be reused for different groups - we
  // should rely on something more unique...
  const rangeMap = useMemo(() => {
    const map = new Map<string, RangeFactory>();
    ranges.forEach(g => {
      g.intervals.forEach(r => {
        map.set(r.messageId, r.create);
      });
    });

    return map;
  }, [ranges]);

  useEffect(() => {
    if (lastSelected.current !== range) {
      setSelectedRange(EmptyRange.messageId);
    }
  }, [range]);

  // if there are no ranges, do not render this component
  if (ranges.length === 0) {
    return null;
  }

  const handleRangeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    lastSelected.current =
      rangeMap.get(e.currentTarget.value)?.(LocalDate.now(), {
        locale,
        weekStart
      }) || null;

    if (lastSelected.current) {
      onRangeChange(lastSelected.current);
    }

    setSelectedRange(e.currentTarget.value);
  };

  return (
    <select
      className={clsx(dropdownStyles.base, styles.rangeSelect)}
      value={selectedRange}
      onChange={handleRangeChange}
      {...other}
    >
      {ranges.map(g => (
        <optgroup key={g.messageId} label={t(g.messageId)}>
          {g.intervals.map(({ messageId }) => {
            return (
              <option key={messageId} value={messageId}>
                {t(messageId)}
              </option>
            );
          })}
        </optgroup>
      ))}
    </select>
  );
};
