import React, { FunctionComponent } from "react";
import { NodeSize } from "./NodeTypeDefinitions";
import { AnchorArea, AnchorProperties, onAnchorDropped } from "../../Util/AnchorUtil";
import { Edge, Node, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { forceNodeUpdate } from "../../Util/NodeUtil";

export interface INodeHeaderParams {
  nodeId: string;
  nodeProperties: any;
  label?: string;
  iconClassName: string;
}

export const NodeHeader: FunctionComponent<INodeHeaderParams> = (params) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const getClassName = () => {
    let className = "base-node-header-container";
    const nodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(params.nodeProperties.type)!;

    if (params.nodeProperties.anchoredToNodeId && nodeTypeDefinition.hideLabelOnAnchor) {
      className += " anchored-node-hide-label";
    }

    return className;
  };

  const getIconClassName = () => {
    let className = "base-node-icon " + params.iconClassName;

    if (params.nodeProperties.isEvaluating) {
      className += " evaluating";
    }

    return className;
  };

  const getStyle = () => {
    const style: any = { minHeight: minHeight + "px" };
    const anchoredNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(params.nodeProperties.type);
    if (anchoredNodeDefinition) {
      style.maxWidth = anchoredNodeDefinition.size as number;
    } else {
      style.maxWidth = NodeSize.Normal;
    }

    return style;
  };

  const getAnchor = () => {
    if (params.nodeProperties.anchoredToNodeId) {
      const destinationNode = reactFlowInstance.getNode(params.nodeProperties.anchoredToNodeId)!;
      const anchorProperties: AnchorProperties = destinationNode.data.nodeProperties.anchoredNodes.filter(
        (a: AnchorProperties) => a.nodeId === params.nodeId
      )[0];
      const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId)!;
      const anchoredNodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(anchoredNode.type!)!;
      if (anchorProperties.area === AnchorArea.Input) {
        return (
          <div
            onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
              const node = reactFlowInstance.getNode(params.nodeId)!;
              onAnchorDropped(
                node.data.nodeProperties.anchoredToNodeId,
                node.id,
                e.clientX,
                e.clientY,
                reactFlowInstance,
                updateNodeInternals
              );
            }}
            onMouseDown={(_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              const nodes = reactFlowInstance.getNodes();
              nodes.forEach((n: Node<any>) => (n.selected = false));
              const node = reactFlowInstance.getNode(params.nodeId)!;
              node.selected = true;
              reactFlowInstance.setNodes(nodes.filter((n: Node<any>) => n.id !== node.id).concat(node));
            }}
            draggable={true}
            className={
              "nodrag draggable " +
              anchorProperties.propertyType +
              "-anchor-bubble" +
              " node-" +
              (anchorProperties.isArray ? "array-" : "") +
              "input-anchor-bubble" +
              (anchoredNodeDefinition.unconnectedAnchorLocation ? " unconnected-anchored-node-bubble" : "")
            }
          >
            <div className="anchor-bubble-drag-area-left" />
          </div>
        );
      }

      const toggleChainOutputVisibility = () => {
        if (reactFlowInstance.getEdges().filter((e: Edge<any>) => e.target === params.nodeId).length > 1) {
          return;
        }

        if (params.nodeProperties.anchorChainOutputVisibility === undefined) {
          params.nodeProperties.anchorChainOutputVisibility = false;
        }
        params.nodeProperties.anchorChainOutputVisibility = !params.nodeProperties.anchorChainOutputVisibility;
        forceNodeUpdate(reactFlowInstance.getNode(params.nodeId), updateNodeInternals, reactFlowInstance);
      };

      const getChainOutputVisibilityButtonClass = () => {
        if (params.nodeProperties.anchorChainOutputVisibility) {
          return "anchor-hide-output-button";
        }

        return "anchor-show-output-button";
      };

      if (
        anchorProperties.area === AnchorArea.Output ||
        anchorProperties.area === AnchorArea.Bottom ||
        anchorProperties.area === AnchorArea.Top
      ) {
        return (
          <>
            <div
              onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
                const node = reactFlowInstance.getNode(params.nodeId)!;
                onAnchorDropped(
                  node.data.nodeProperties.anchoredToNodeId,
                  node.id,
                  e.clientX,
                  e.clientY,
                  reactFlowInstance,
                  updateNodeInternals
                );
              }}
              onMouseDown={(_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                const nodes = reactFlowInstance.getNodes();
                nodes.forEach((n) => (n.selected = false));
                const node = reactFlowInstance.getNode(params.nodeId)!;
                node.selected = true;
                reactFlowInstance.setNodes(nodes.filter((n: Node<any>) => n.id !== node.id).concat(node));
              }}
              draggable={true}
              className={
                "nodrag draggable " +
                anchorProperties.propertyType +
                "-anchor-bubble" +
                " node-" +
                (anchorProperties.isArray ? "array-" : "") +
                "output-anchor-bubble" +
                (anchoredNodeDefinition.unconnectedAnchorLocation ? " unconnected-anchored-node-bubble" : "")
              }
            >
              <div className="anchor-bubble-drag-area-right" />
            </div>
            {params.nodeProperties.anchoredNodes?.length > 0 &&
            reactFlowInstance.getEdges().filter((e: Edge<any>) => e.target === params.nodeId).length < 2 ? (
              <div
                className={getChainOutputVisibilityButtonClass()}
                title={params.nodeProperties.anchorChainOutputVisibility ? "Hide output" : "Show output"}
                onClick={(_) => toggleChainOutputVisibility()}
              />
            ) : (
              <></>
            )}
          </>
        );
      }
    }
    return <></>;
  };

  const minHeight =
    Object.keys(params.nodeProperties.outputs).length > 0 ? 21 * params.nodeProperties.controlOutputs.length + 8 : 0;

  return (
    <div className={getClassName()} style={getStyle()}>
      {getAnchor()}
      <div className={getIconClassName()} />
      <div>
        <div className={params.nodeProperties.controlOutputs.length > 1 ? "base-node-name-margin" : "base-node-name"}>
          {params.nodeProperties.name}
        </div>
        <div className={"base-node-action"}>
          {params.nodeProperties.name ? "" : params.label ?? params.nodeProperties.label}
        </div>
        {(window as any).debugControlOrderValidation ? (
          <div>
            {params.nodeProperties.controlPath} : {params.nodeProperties.controlOrder} :{" "}
            {params.nodeProperties.dataOrder}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
