import { LocalDate, TemporalAdjusters, DayOfWeek, LocalDateTime, LocalTime, ZonedDateTime } from "js-joda";
import { Locale, DayValue } from "@plex/culture-core";

/**
 * Gets the configured week start value - this will prefer a provided value, falling back
 * to the locale's default.
 */
export const getWeekStart = (locale: Locale, weekStart?: DayValue | DayOfWeek): DayValue => {
  if (weekStart == null) {
    return locale.dates.firstDayOfWeek();
  }

  if (typeof weekStart === "number") {
    return weekStart;
  }

  return weekStart.value();
};

/**
 * Gets the names of the days of the week. The first value in the array will be
 * the start of a week, based on the locale.
 */
export const getWeekDayNames = (locale: Locale, firstDayOfWeek: DayValue) => {
  const names = locale.dates.getDayNames("short");
  const headers = [];
  for (let i = 0; i < names.length; i++) {
    headers.push(names[(i + firstDayOfWeek - 1) % 7]);
  }

  return headers;
};

/**
 * Gets an array of LocalDate arrays. Each array will represent a week, and the LocalDate
 * instances in that array will represent the dates in that week. The returned array will
 * encompass 6 weeks, starting with the first day of the month provided. The dates will be
 * in sequence based on the locales week start.
 * @param firstDateOfMonth
 * @param firstDayOfWeek
 */
export const getMonthDaysByWeek = (firstDateOfMonth: LocalDate, firstDayOfWeek: DayValue) => {
  const daysFromWeekStart = (7 + firstDateOfMonth.dayOfWeek().value() - firstDayOfWeek) % 7;
  const firstDayOfCalendar = firstDateOfMonth.minusDays(daysFromWeekStart);
  let currentDate = firstDayOfCalendar;

  const daysModel: LocalDate[][] = [];

  // The picker in UX always renders 6 rows, even if the month doesn't break down
  // into 6 weeks. I believe this is likely so the picker always renders at the same height.
  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      week.push(currentDate);
      currentDate = currentDate.plusDays(1);
    }

    daysModel.push(week);
  }

  return daysModel;
};

/**
 * Gets the LocalDate from the given date which is the nearest
 * start of week, based on the provided locale.
 */
export const getFirstDayOfWeek = (date: LocalDate, locale: Locale, weekStart?: DayValue | DayOfWeek) => {
  const start = getWeekStart(locale, weekStart);
  const dow = DayOfWeek.values()[start - 1];
  return date.with(TemporalAdjusters.previousOrSame(dow));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLocalDate = (value: any): value is LocalDate => {
  return (
    value && typeof value === "object" && typeof value.hour !== "function" && typeof value.dayOfMonth === "function"
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLocalTime = (value: any): value is LocalTime => {
  return (
    value && typeof value === "object" && typeof value.hour === "function" && typeof value.dayOfMonth !== "function"
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLocalDateTime = (value: any): value is LocalDateTime => {
  return (
    value && typeof value === "object" && typeof value.hour === "function" && typeof value.dayOfMonth === "function"
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isZonedDateTime = (value: any): value is ZonedDateTime => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isLocalDateTime(value) && typeof (value as any).zone === "function";
};

export const getDatePart = (value: unknown) => {
  if (!value || isLocalTime(value)) {
    return null;
  }

  if (isLocalDateTime(value)) {
    return value.toLocalDate();
  }

  if (isLocalDate(value)) {
    return value;
  }

  return null;
};

export const getTimePart = (value: unknown) => {
  if (!value || isLocalDate(value)) {
    return null;
  }

  if (isLocalDateTime(value)) {
    return value.toLocalTime();
  }

  if (isLocalTime(value)) {
    return value;
  }

  return null;
};
