import React, { ReactText, useContext, FunctionComponent, useMemo } from "react";
import { usePrinting, withPrinting } from "@plex/react-xml-renderer";
import clsx from "clsx";
import { sort as arraySort } from "@arrays-immutable";
import { useEventCallback } from "@hooks";
import { ITableGroup, ITableBodyProps, ITableRowProps, GridRecord } from "./DataTable.types";
import { DataRowContextProvider, DataRowContext } from "./DataTableBody";
import styles from "./DataTable.module.scss";
import { useDataTableData } from "./DataTable.hooks";
import { createComparer, reduceComparer, reverseComparer } from "./data-sorting";
import { useVirtualizedWindow } from "./Plugins/DataVirtualization.hooks";
import { useCurrentSort } from "./Plugins/DataSorter.hooks";

type Group<T> = {
  key: ReactText;
  data: Array<GridRecord<T>>;
  window?: Array<GridRecord<T>>;
  renderIndex: number;
  prev?: Group<T>;
  next?: Group<T>;
};

const NO_GROUPS: Group<unknown> = { key: "", data: [], renderIndex: 0 };
const GroupDataContext = React.createContext(NO_GROUPS);

// eslint-disable-next-line func-style
export function useDataTableGroup<T>(): Group<T> | null {
  const context = useContext(GroupDataContext) as Group<T>;
  return context === NO_GROUPS ? null : context;
}

// eslint-disable-next-line func-style, complexity
export function DataTableGroup<T>({ children, sortColumnId, groupSelector, groupComparer, ...other }: ITableGroup<T>) {
  const printing = usePrinting();
  const allData = useDataTableData<T>();
  const parentData = useContext(GroupDataContext) as Group<T>;

  const outerGroup = parentData === NO_GROUPS;
  const nestedGroup = !outerGroup;

  // allow for nesting groups
  const localData = nestedGroup ? parentData.window || parentData.data : allData;
  let comparer = groupComparer ?? createComparer(groupSelector);
  let renderIndex = parentData.renderIndex;

  const sortInfo = useCurrentSort(sortColumnId);
  if (sortInfo?.reversed) {
    comparer = reverseComparer(comparer);
  }

  const groupedData = useMemo<Array<Group<T>>>(
    () =>
      arraySort(
        localData,
        reduceComparer(comparer, record => record.value)
      ).reduce<Array<Group<T>>>((groups, row) => {
        const key = String(groupSelector(row.value));

        let current = groups[groups.length - 1];
        if (groups.length === 0 || current.key !== key) {
          const prev = current;
          current = { key, prev, data: [], renderIndex };

          if (groups.length > 0) {
            prev.next = current;
          }

          groups.push(current);
        }

        renderIndex++;
        current.data.push(row);
        return groups;
      }, []),
    [comparer, groupSelector, localData, renderIndex]
  );

  const win = useVirtualizedWindow<T>();
  const virtualizedTopRow = win?.virtualizedTopRow;
  const virtualizedBottomRow = win?.virtualizedBottomRow;
  const content: JSX.Element[] = [];

  if (win && outerGroup) {
    // We limit the records only at the outer group. Nested groups will use the
    // subset of records provided.
    for (let i = 0; i < groupedData.length; i++) {
      let g = groupedData[i];
      if (g.renderIndex < win.start) {
        // We haven't reached the window yet.
        continue;
      }

      let window = g.data;
      const start = Math.max(g.renderIndex, win.start) - g.renderIndex;
      const end = Math.min(g.renderIndex + window.length, win.end) - g.renderIndex;
      const size = end - start;

      if (size < window.length) {
        window = window.slice(start, end);
        const key = `${String(g.key)}-${String(start)}-${String(end)}`;

        // We keep the data, but pass in a `window` collection. The idea
        // here is that the consumer still has access to the full data for
        // aggregates and other such use cases. Not sure if that is actually
        // useful in practice.
        g = { ...g, key, window, renderIndex: start + g.renderIndex };
      }

      content.push(
        <GroupDataContext.Provider key={g.key} value={g}>
          {typeof children === "function" ? children(window.map(x => x.value)) : children}
        </GroupDataContext.Provider>
      );

      if (end + g.renderIndex >= win.end) {
        // We know there are no more records to render so we can bail out.
        break;
      }
    }
  } else {
    content.push(
      ...groupedData.map(group => (
        <GroupDataContext.Provider key={group.key} value={group}>
          {typeof children === "function" ? children(group.data.map(x => x.value)) : children}
        </GroupDataContext.Provider>
      ))
    );
  }

  if (printing) {
    return React.createElement("grid-table-body", {}, content);
  } else {
    // spreading the props only really makes sense at the top level...
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return nestedGroup ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{content}</>
    ) : (
      <tbody {...other}>
        {virtualizedTopRow}
        {content}
        {virtualizedBottomRow}
      </tbody>
    );
  }
}

// eslint-disable-next-line func-style
export function DataTableGroupBody<T>({ children }: ITableBodyProps<T>) {
  const { data, window, renderIndex } = useContext(GroupDataContext) as Group<T>;
  const renderRows = useEventCallback((record: GridRecord<T>, index: number) => {
    return (
      <DataRowContextProvider key={record.key} record={record}>
        <DataRowContext.Consumer>
          {({ rowInfo }) => {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <>{children(record.value, renderIndex + index, rowInfo)}</>;
          }}
        </DataRowContext.Consumer>
      </DataRowContextProvider>
    );
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{(window || data).map(renderRows)}</>;
}

export const HtmlDataTableGroupHeaderRow: FunctionComponent<ITableRowProps> = ({ children, className, ...other }) => {
  return (
    <tr className={clsx(styles.groupHeaderRow, className)} {...other}>
      {children}
    </tr>
  );
};

const XmlDataTableGroupHeaderRow: FunctionComponent<ITableRowProps> = ({ children }) => {
  return React.createElement("grid-group-header-row", {}, children);
};

export const DataTableGroupHeaderRow = withPrinting(HtmlDataTableGroupHeaderRow, XmlDataTableGroupHeaderRow);

export const HtmlDataTableGroupFooterRow: FunctionComponent<ITableRowProps> = ({ children, className, ...other }) => {
  return (
    <tr className={clsx(styles.footerRow, className)} {...other}>
      {children}
    </tr>
  );
};

const XmlDataTableGroupFooterRow: FunctionComponent<ITableRowProps> = ({ children }) => {
  return React.createElement("grid-group-footer-row", {}, children);
};

export const DataTableGroupFooterRow = withPrinting(HtmlDataTableGroupFooterRow, XmlDataTableGroupFooterRow);
