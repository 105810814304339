import React, { FunctionComponent } from "react";
import { Edge, useReactFlow } from "reactflow";
import { Button, Dropdown, Form, InputSize, TextInput } from "@plex/react-components";
import { primitiveTypesSelect } from "../NodeTypes/TypeDefinitions";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { getSchemaId } from "../NodeTypes/DataSchemas";

export interface IFlowOutputFormSectionProps extends INodeConfigPropertiesProps {
  sectionType?: "input" | "output";
  onEdgeDelete: (edge: Edge) => void;
}

const groupNodeDataTypes = [
  { key: "uuid", value: "uuid" },
  { key: "string", value: "string" },
  { key: "integer", value: "integer" },
  { key: "decimal", value: "decimal" },
  { key: "boolean", value: "boolean" },
  { key: "date", value: "date" }
];

export const FlowOutputFormSection: FunctionComponent<IFlowOutputFormSectionProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const sectionTypeLabel = props.sectionType === "input" ? "Input" : "Output";

  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  const setOutputs = () => {
    if (!props.sectionType || props.sectionType === "output") {
      props.node!.data.nodeProperties.inputs = {};
      props.node!.data.nodeProperties[props.name].forEach((o: any) => {
        props.node!.data.nodeProperties.inputs[o.name] = {
          required: false,
          enabled: true,
          type: o.type,
          schemaId: getSchemaId(o.type)
        };
      });
    }
  };

  const addInputOutput = () => {
    let newInputOutput = {
      index: props.node!.data.nodeProperties[props.name]!.length,
      name: "",
      type: props.node!.data.nodeProperties.addType[0].value,
      schemaId: getSchemaId(props.node!.data.nodeProperties.addType[0].value),
      fromRewire: false
    };
    props.node!.data.nodeProperties[props.name]!.push(newInputOutput);
    setState({ value: props.node!.data.nodeProperties[props.name] });
    setOutputs();
    props.forceNodeUpdate();
  };

  const getHandle = (edge: Edge) => {
    return props.sectionType === "input" ? edge.targetHandle : edge.sourceHandle;
  };

  const getFormattedHandleName = (index: number) => {
    const extension = props.sectionType === "input" ? "-output" : "-input";
    return (
      props.node!.data.nodeProperties[props.name]![index].name +
      "-" +
      props.node!.data.nodeProperties[props.name]![index].type +
      extension
    );
  };

  const deleteInputOutput = (index: number) => {
    const inputOutput = props.node!.data.nodeProperties[props.name]![index];
    const formattedHandleName = getFormattedHandleName(index);
    let deletedEdge: any = reactFlowInstance.getEdges().filter((e) => getHandle(e) === formattedHandleName)[0];
    reactFlowInstance.setEdges(reactFlowInstance.getEdges().filter((e) => getHandle(e) !== formattedHandleName));
    props.node!.data.nodeProperties[props.name]!.splice(index, 1);
    props.node!.data.nodeProperties[props.name]!.forEach(
      (inputOutput: { index: number; name: string }, currentIndex: number) => {
        if (currentIndex >= index) {
          inputOutput.index--;
        }
      }
    );
    setState({ value: props.node!.data.nodeProperties[props.name] });
    if (props.sectionType === "input") {
      delete props.node!.data.nodeProperties.outputs[inputOutput.name];
    } else {
      delete props.node!.data.nodeProperties.inputs[inputOutput.name];
    }
    props.forceNodeUpdate();
    props.onEdgeDelete(deletedEdge);
  };

  if (!props.node!.data.nodeProperties[props.name]) {
    props.node!.data.nodeProperties[props.name] = [];
  }

  const options = props.node!.type === "flowGroup" ? groupNodeDataTypes : primitiveTypesSelect;

  return (
    <Form.Section title={props.sectionName} key={props.sectionName}>
      {props.node!.data.nodeProperties[props.name]!.map((inputOutput: { index: number; name: string }) => {
        return (
          <Form.Row key={inputOutput.index} style={{ textAlign: "center" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "60px", textAlign: "left", fontSize: "smaller", color: "gray", marginTop: "7px" }}>
                {props.node!.data.nodeProperties[props.name]![inputOutput.index].type}
              </div>
              <TextInput
                style={{ width: "75%" }}
                value={props.node!.data.nodeProperties[props.name]![inputOutput.index].name || ""}
                size={InputSize.large}
                onChange={(e) => {
                  reactFlowInstance.setEdges((edges) =>
                    edges.filter((e) => getHandle(e) !== getFormattedHandleName(inputOutput.index))
                  );
                  props.node!.data.nodeProperties[props.name]![inputOutput.index].name = e.currentTarget.value;
                  setOutputs();
                  setState({ value: props.node!.data.nodeProperties[props.name] });
                  setTimeout(() => {
                    props.forceNodeUpdate();
                  }, 100);
                }}
              />
              <div className="flow-input-output-delete" onClick={() => deleteInputOutput(inputOutput.index)} />
            </div>
          </Form.Row>
        );
      })}
      <Form.Row key={`Add ${sectionTypeLabel} Button`} style={{ textAlign: "center" }}>
        <div>
          <Dropdown
            style={{ verticalAlign: "top", marginRight: "10px" }}
            items={options}
            keySelector={(item: { [key: string]: string }) => item.key}
            displaySelector={(item: { [key: string]: string }) => item.value}
            selected={props.node!.data.nodeProperties.addType}
            onSelectionChanged={(values) => {
              let hadValue =
                props.node!.data.nodeProperties.addType && props.node!.data.nodeProperties.addType.length > 0;
              props.node!.data.nodeProperties.addType = values;
              if (hadValue) {
                setState(props.node!.data.nodeProperties.addType);
              }
            }}
          />
          <Button
            onClick={addInputOutput}
            style={{ verticalAlign: "top", marginLeft: "10px" }}
          >{`Add ${sectionTypeLabel}`}</Button>
        </div>
      </Form.Row>
    </Form.Section>
  );
};
