/* istanbul ignore file */
import { useEffect, useState, RefObject } from "react";
import { isDocument, getScrollableContainer } from "@utils";

type ViewportOptions = {
  ref: RefObject<HTMLElement>;
  container?: HTMLElement | Document;
  top?: number;
};

export const useInViewport = ({ ref, container, top = 0 }: ViewportOptions) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    // if the container is Document, setting to null so that
    // the IntersectionObserver will use the browser viewport
    const parent = container ?? getScrollableContainer(ref.current);
    const root = isDocument(parent) ? null : parent;

    const observer = new IntersectionObserver(
      entries => {
        setVisible(entries.some(x => x.isIntersecting));
      },
      { root, threshold: [1], rootMargin: `${String(top)}px 0px 0px 0px` }
    );

    if (ref.current.tagName === "TR") {
      // Handle TR tags differently - since the child cells are really what's visible,
      // not the row itself.
      Array.prototype.slice.call(ref.current.children).forEach(target => observer.observe(target));
    } else {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref, container, top]);

  return visible;
};
