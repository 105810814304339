import React, { ReactText, ReactNode } from "react";
import { useLocalization } from "@plex/culture-react";
import { Chip } from "@components/Chip";
import { ValueAccessor } from "@components/Common.types";
import { remove } from "@arrays-immutable";
import styles from "./PickerModal.module.scss";

interface IMultiSelectContainerProps<T> {
  selected: T[];
  keySelector: ValueAccessor<T, ReactText>;
  displaySelector: ValueAccessor<T, ReactNode>;
  onSelectionChanged?: (rows: T[]) => void;
}

// eslint-disable-next-line func-style
export function MultiSelectContainer<T>({
  selected,
  keySelector,
  displaySelector,
  onSelectionChanged
}: IMultiSelectContainerProps<T>) {
  const { t } = useLocalization();

  const handleRemovedRow = (row: T) => {
    onSelectionChanged?.(remove(selected, row));
  };

  return (
    <div data-testid="plex-picker-current-selection-container" className={styles.selectionSection}>
      <span className={styles.selectionTitle}>{t("ui-common-dataPicker-heading-currentSelection")}</span>
      <div className={styles.selectionContainer} data-testid="plex-picker-current-selection-content">
        {selected.length === 0 && (
          <span className={styles.selectionPlaceholder}>{t("ui-common-dataPicker-heading-selectFromItemsBelow")}</span>
        )}
        {selected.map(row => (
          <Chip
            key={keySelector(row)}
            align="horizontal"
            onClose={() => handleRemovedRow(row)}
            className={styles.selectionChip}
            closable
          >
            {displaySelector(row)}
          </Chip>
        ))}
      </div>
    </div>
  );
}
