import React, { useRef } from "react";

const UNASSIGNED: any | null = {};

export const ViewControllerContext = React.createContext<any>(undefined);

export const useViewController = () => {
  return React.useContext(ViewControllerContext);
};

export const useSingleton = (factory: any) => {
  const sharedDataRef = useRef(UNASSIGNED);

  if (sharedDataRef.current == UNASSIGNED) {
    sharedDataRef.current = factory();
  }

  return sharedDataRef.current;
};
