import React, { FunctionComponent } from "react";
import { TimeInput, TimePrecision } from "@plex/react-components";
import { DateTimeFormatStyle } from "@plex/culture-core";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export interface ITimeInputProps extends INodeConfigPropertiesProps {
  label: string;
  formatOptions: DateTimeFormatStyle;
  precision: TimePrecision;
}

export const TimeFormField: FunctionComponent<ITimeInputProps> = (props) => {
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  return (
    <TimeInput
      key={`${props.sectionName} ${props.label} Input`}
      value={props.node?.data.nodeProperties[props.name] || ""}
      onValueChange={(time: any) => {
        if (time) {
          props.node!.data.nodeProperties[props.name] = time._time.toString();
          setState({ value: props.node!.data.nodeProperties[props.name] });
        } else {
          props.node!.data.nodeProperties[props.name] = null;
          setState({ value: null });
        }
        props.forceNodeUpdate();
      }}
      formatOptions={props.formatOptions}
      use24HourTime={false}
      precision={props.precision}
      minuteStep={1}
    />
  );
};
