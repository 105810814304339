import { queryApiSpecs } from "./PlexOpenApiConnector";

export type PlexOpenApiCategory = {
  name: string;
  apis: any[];
};

export type PlexOpenApiSpecs = {
  Categories: PlexOpenApiCategory[];
};

export type PlexOpenApiError = {
  errorType: string;
  errorResponse: string;
};

let apiSpecs: any = undefined;

export const loadApiSpecs = (callback: () => void) => {
  return queryApiSpecs(callback, (specs: any) => (apiSpecs = specs));
};

const getType = (parameter: any) => {
  let property = parameter;

  if (parameter.type === "array") {
    if (parameter.items.$ref) {
      return "object";
    }

    property = parameter.items;
  }

  let type = property.type;

  if (property.format) {
    switch (property.format) {
      case "uuid":
        type = "uuid";
        break;
      case "date-time":
        type = "dateTime";
        break;
    }
  }

  switch (property.type) {
    case "number":
      type = "decimal";
      break;
  }

  return type;
};

export const getApiInputs = (category: string, apiId: string, apiAction: string) => {
  let parameters: any[] = [];
  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      let operation: any = null;
      Object.keys(api.paths).forEach((pathKey) => {
        let path = api.paths[pathKey];
        Object.keys(path).forEach((operationKey) => {
          if (path[operationKey].operationId === apiAction) {
            operation = path[operationKey];
          }
        });
      });

      if (!operation?.parameters) {
        return [];
      }

      operation.parameters.forEach((parameter: any) => {
        if (parameter.schema?.$ref) {
          let dtoName = parameter.schema.$ref.split("/")[2];
          let dto = api.definitions[dtoName];
          let isArray = parameter.schema.isArray;
          Object.keys(dto.properties).forEach((propertyName: any) => {
            let property = dto.properties[propertyName];
            parameters.push({
              name: propertyName,
              inBody: true,
              description: property.description,
              type: getType(property) + (isArray ? "List" : ""),
              format: property.format,
              required: property.required
            });
          });
        } else {
          let isArray = parameter.isArray;
          parameters.push({
            name: parameter.name,
            inBody: false,
            description: parameter.description,
            type: getType(parameter) + (isArray ? "List" : ""),
            format: parameter.format,
            required: parameter.required
          });
        }
      });
    }
    return {};
  });

  return parameters;
};

export const getApiOutputs = (
  category: string,
  apiId: string,
  apiAction: string,
  channel: string | undefined = undefined,
  objectPath: string[] | undefined = undefined
) => {
  let parameters: any[] = [];
  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      let operation: any = null;
      Object.keys(api.paths).forEach((pathKey) => {
        let path = api.paths[pathKey];
        Object.keys(path).forEach((operationKey) => {
          if (path[operationKey].operationId === apiAction) {
            operation = path[operationKey];
          }
        });
      });

      let response: any;
      if (channel) {
        response = operation?.responses[channel];
      } else {
        response = operation?.responses["200"];
        channel = "200";
        if (!response) {
          response = operation?.responses["201"];
          channel = "201";
          if (!response) {
            channel = undefined;
            return [];
          }
        }
      }

      if (response?.schema) {
        let dtoName: string;
        let dto: any;
        let isArray = false;

        if (response.schema.type === "array") {
          dtoName = response.schema.items.$ref.split("/")[2];
          isArray = true;
        } else {
          dtoName = response.schema.$ref.split("/")[2];
        }

        dto = api.definitions[dtoName];
        let currentObject = api.definitions[dtoName];

        objectPath?.forEach((propertyName) => {
          currentObject = currentObject.properties[propertyName];
          if (currentObject) {
            if (currentObject.type === "array") {
              dtoName = currentObject.items.$ref.split("/")[2];
              isArray = true;
            } else {
              dtoName = currentObject.$ref.split("/")[2];
              isArray = false;
            }

            dto = api.definitions[dtoName];
          }
        });

        if (objectPath) {
          Object.keys(dto.properties).forEach((propertyName: any) => {
            let property = dto.properties[propertyName];
            parameters.push({
              name: propertyName,
              channel: channel,
              isArray: property.type === "array",
              inBody: true,
              description: property.description,
              type: getType(property) + (property.type === "array" ? "List" : ""),
              format: property.format,
              required: property.required
            });
          });
        } else {
          const isErrorCode = channel?.indexOf("40") === 0;
          let readableDtoName = dtoName.replace(new RegExp("dto", "ig"), "").trim();
          parameters.push({
            name: readableDtoName,
            channel: channel,
            isErrorCode: isErrorCode,
            isArray: isArray,
            readableDtoName: dtoName,
            type: "object" + (isArray ? "List" : ""),
            required: response.schema.required
          });
        }
      }
    }

    return {};
  });

  return parameters;
};

export const getApiStatusCodes = (category: string, apiId: string, apiAction: string, isSuccessCodeType: boolean) => {
  if (apiId === "" || apiAction === "" || category === "") {
    return [];
  }

  let channel: string[] = [];

  apiSpecs.Categories.filter((c: any) => c.name === category)[0]?.apis?.map((api: any) => {
    if (api.id === apiId) {
      let operation: any = null;
      Object.keys(api.paths).forEach((pathKey) => {
        let path = api.paths[pathKey];
        Object.keys(path).forEach((operationKey) => {
          if (path[operationKey].operationId === apiAction) {
            operation = path[operationKey];
          }
        });
      });

      const statusPointer = isSuccessCodeType ? "20" : "40";
      const findOtherSuccessChannel =
        operation && Object.keys(operation?.responses).filter((x) => x.indexOf(statusPointer) === 0);
      if (findOtherSuccessChannel?.length > 0 && !channel.find((x) => x === findOtherSuccessChannel[0])) {
        channel.push(findOtherSuccessChannel[0]);
      }
    }
  });

  return channel;
};

export const getApiType = (category: string, apiId: string, apiAction: string) => {
  let apiType = "";
  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      Object.keys(api.paths).forEach((pathKey) => {
        let path = api.paths[pathKey];
        Object.keys(path).forEach((operationKey) => {
          if (path[operationKey].operationId === apiAction) {
            apiType = operationKey === "get" ? "read" : "modify";
          }
        });
      });
    }
    return {};
  });

  return apiType;
};

export const getApiPath = (category: string, apiId: string, apiAction: string) => {
  let path = "";
  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      Object.keys(api.paths).forEach((pathKey) => {
        let currentPath = api.paths[pathKey];
        Object.keys(currentPath).forEach((operationKey) => {
          if (currentPath[operationKey] && currentPath[operationKey].operationId === apiAction) {
            path = api.basePath + pathKey;
            return;
          }
        });
      });
    }
    return null;
  });

  return path;
};

export const getApiMethod = (category: string, apiId: string, apiAction: string) => {
  let method = "";
  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      Object.keys(api.paths).forEach((pathKey) => {
        let currentPath = api.paths[pathKey];
        Object.keys(currentPath).forEach((operationKey) => {
          if (currentPath[operationKey] && currentPath[operationKey].operationId === apiAction) {
            method = operationKey;
            return;
          }
        });
      });
    }
    return null;
  });

  return method;
};

export const getErrorTypes = (category: string, apiId: string, apiAction: string) => {
  const errorResData: PlexOpenApiError[] = [];

  apiSpecs.Categories.filter((c: any) => c.name === category)[0].apis.map((api: any) => {
    if (api.id === apiId) {
      Object.keys(api.paths).forEach((pathKey) => {
        let path = api.paths[pathKey];
        Object.keys(path).forEach((operationKey) => {
          if (path[operationKey].operationId === apiAction) {
            if (path[operationKey].responses) {
              let responses = path[operationKey].responses;
              Object.keys(responses).forEach((responseKey) => {
                if (responseKey.indexOf("40") === 0) {
                  let errorResponse = responses[responseKey]?.schema
                    ? responses[responseKey].schema.$ref?.split("/")[2]
                    : null;
                  errorResData.push({
                    errorType: responseKey,
                    errorResponse
                  });
                }
              });
            }
          }
        });
      });
    }
  });

  return errorResData;
};

export const getSpecs = () => apiSpecs;
