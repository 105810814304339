import React from "react";
import { ITextQuery, PickerInput } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { IDataParam } from "../NodeTypes/Base";
import { getNodeOutputs } from "../Util/NodeUtil";

export interface IDataInputColumn<T> {
  id: string;
  title: string;
  valueSelector: (row: T) => string;
}

export interface IDataInputFormProps<T> extends INodeConfigPropertiesProps {
  sectionName: string;
  label: string;
  dialogTitle: string;
  searchColumns: IDataInputColumn<T>[];
  disabled?: boolean;
  maxDisplay?: number;
  typesAllowed?: string[];
}

export function DataInputFormField<T extends IDataParam>(props: IDataInputFormProps<T>) {
  const getData = () => {
    let data = getNodeOutputs(props.node);
    if (props.typesAllowed && props.typesAllowed.length > 0) {
      return data.filter((item) => {
        return props.typesAllowed!.includes(item.type);
      });
    }
    return data ?? [];
  };

  let [{ pickerData }, setPickerState] = React.useState({
    selectedDataItem: getData().filter((i) => i.name === props.node!.data.nodeProperties[props.name])[0],
    pickerData: getData()
  });

  const handleSelection = (selectedItem: IDataParam[]) => {
    props.node!.data.nodeProperties[props.name] = selectedItem[0]?.name;
    setPickerState((x) => ({ ...x, selectedDataItem: selectedItem[0] }));
    props.forceNodeUpdate();
  };

  const handleSearch = (search: ITextQuery) => {
    let data = getData();
    if (search.query?.length > 0) {
      data = data.filter((x) => x.name?.toLowerCase().includes(search.query.toLowerCase()));
    }
    setPickerState((x) => ({ ...x, pickerData: data }));
  };

  const createSearchColumns = () => {
    return props.searchColumns.map((col, index) => {
      return (
        <PickerInput.Column<T> key={index} id={col.id} title={col.title} valueSelector={col.valueSelector} searchable>
          {col.valueSelector}
        </PickerInput.Column>
      );
    });
  };

  return (
    <PickerInput<T>
      key={`${props.sectionName} ${props.label} Data Input`}
      dialogTitle={props.dialogTitle}
      initialWidth={900}
      selected={
        pickerData.filter((i) => i.name === props.node!.data.nodeProperties[props.name]).length > 0
          ? [pickerData.filter((i) => i.name === props.node!.data.nodeProperties[props.name])[0]]
          : []
      }
      disabled={props.disabled}
      searchDisabled={false}
      onSelectionChanged={handleSelection}
      onSearch={handleSearch}
      keySelector={(row: T) => row.name}
      displaySelector={(row: T) => row.name}
      data={pickerData}
      isOpen={false}
      multiSelect={false}
      maxDisplay={props.maxDisplay ?? 500}
      recordLimitExceeded={false}
    >
      {createSearchColumns()}
    </PickerInput>
  );
}
