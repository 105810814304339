import React, { forwardRef, FC, ForwardRefExoticComponent, RefAttributes } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, IWithChildren } from "../Common.types";
import styles from "./Form.module.scss";
import { FormBody } from "./FormBody";
import { FormFooter } from "./FormFooter";
import { FormFieldPair } from "./FormFieldPair";
import { FormHeader } from "./FormHeader";
import { FormRow } from "./FormRow";
import { FormSection } from "./FormSection";
import { FormTitle } from "./FormTitle";
import { FormRowInfo } from "./FormRowInfo";

interface IFormProps extends ICommonProps, IWithChildren {
  embedded?: boolean;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

export interface IFormInterface extends ForwardRefExoticComponent<IFormProps & RefAttributes<HTMLFormElement>> {
  Body: typeof FormBody;
  FieldPair: typeof FormFieldPair;
  Footer: typeof FormFooter;
  Header: typeof FormHeader;
  Row: typeof FormRow;
  Section: typeof FormSection;
  Title: typeof FormTitle;
  RowInfo: typeof FormRowInfo;
}

const HtmlForm = forwardRef<HTMLFormElement, IFormProps>(({ id, children, className, embedded, ...other }, ref) => {
  const hasFooter = React.Children.toArray(children).some(x => React.isValidElement(x) && x.type === FormFooter);
  return (
    <div className={clsx(styles.form, hasFooter && styles.withFooter, embedded && styles.formEmbedded, className)}>
      <form id={id} data-testid="plex-form" ref={ref} {...other}>
        {children}
      </form>
    </div>
  );
});
HtmlForm.displayName = "HtmlForm";

const XmlForm: FC<IFormProps> = ({ children }) => {
  return React.createElement("plex-form", {}, children);
};

export const Form = withPrinting(HtmlForm, XmlForm) as IFormInterface;
Form.Body = FormBody;
Form.FieldPair = FormFieldPair;
Form.Footer = FormFooter;
Form.Header = FormHeader;
Form.Row = FormRow;
Form.Section = FormSection;
Form.Title = FormTitle;
Form.RowInfo = FormRowInfo;
