import React, { FC, useState } from "react";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";
import { AddMenuNodePopulater, IAutoConnectMeta } from "./AddMenuNodePopulation";
import { useViewController } from "../ViewContext";
import "./AddNodeMenu.scss";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { getNewId } from "../DesignerContainer/DesignerContainer";
import { handleEdgeConnection } from "..//Util/EdgeUtil";

export interface IAddNodeMenuProps {
  forNodeId?: string;
  forNodeType?: string;
  forNodeHandleId?: string;
  addNodeMenuContainerRef?: React.RefObject<HTMLDivElement>;
  reactflowContainer?: DOMRect;
  onAdd?: () => void;
  onClose?: () => void;
}

export const AddNodeMenu: FC<IAddNodeMenuProps> = ({
  forNodeId,
  forNodeType,
  forNodeHandleId,
  addNodeMenuContainerRef,
  reactflowContainer,
  onAdd,
  onClose
}) => {
  const reactFlowInstance = useReactFlow();
  const { project } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const [, setSearchFocusState] = useState(false);

  const { experimentalModeState } = useViewController();

  if (!forNodeId || !forNodeType || !forNodeHandleId) {
    return <div />;
  }

  const nodeMapperTypes: Map<string, IAutoConnectMeta> = AddMenuNodePopulater.getValidNodeTypesForGivenHandle(
    forNodeId,
    forNodeType,
    forNodeHandleId,
    experimentalModeState,
    reactFlowInstance
  );

  const handleItemHover = (itemId: string | null) => {
    setHoveredItemId(itemId);
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    // Prevent the wheel event from propagating up to the canvas
    event.stopPropagation();
  };

  const searchDropdown = () => {
    var input: any = document.getElementById("plex-fd-dropdown-search");
    var filter = input.value.toUpperCase();
    var dropdown: any = document.getElementById("plex-fd-dropdown-content");
    var options = dropdown.getElementsByTagName("a");
    for (var i = 0; i < options.length; i++) {
      var txtValue = options[i].textContent || options[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        options[i].style.display = "";
      } else {
        options[i].style.display = "none";
      }
    }
  };

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const itemId = e.currentTarget.id;

    const toNodeMeta: IAutoConnectMeta = nodeMapperTypes.get(itemId)!;
    const fromNode = reactFlowInstance.getNode(forNodeId)!;

    // Programtically removing adding and updating the node & edges
    // Why to use timeout ?
    // reactflow is stubborn since it using react rendering(which is async in nature) so its hard to chase a Node's state performing CRUDs
    setTimeout(() => {
      //const updatedNode =
      const containerRect = addNodeMenuContainerRef?.current?.getBoundingClientRect();
      const containerProject = project({ x: containerRect?.x ?? 0, y: containerRect?.y ?? 0 });
      const toNodeInit = {
        position: {
          x: containerProject.x - (reactflowContainer?.x ?? 0),
          y: containerProject.y - (reactflowContainer?.y ?? 0) + 35
        },
        id: getNewId(toNodeMeta.nodeType, reactFlowInstance),
        type: toNodeMeta.nodeType
      };

      const configProperties: any = {};
      if (toNodeMeta.configProperties?.configProperty) {
        configProperties[toNodeMeta.configProperties.configProperty.propertyName] =
          toNodeMeta.configProperties.configProperty.propertyValue;
      }

      const toNode = AddMenuNodePopulater.nodeCreatorAndLinker(toNodeInit, {}, false, configProperties);

      if (toNodeMeta.handleId) {
        setTimeout(() => {
          const edgeConnection = toNodeMeta.isOutput
            ? {
                target: toNode.id,
                targetHandle: toNodeMeta.handleId,
                source: fromNode!.id,
                sourceHandle: forNodeHandleId
              }
            : {
                target: fromNode.id,
                targetHandle: forNodeHandleId,
                source: toNode!.id,
                sourceHandle: toNodeMeta.handleId
              };
          handleEdgeConnection(edgeConnection);
          forceNodeUpdate(fromNode, updateNodeInternals, reactFlowInstance);
          forceNodeUpdate(toNode, updateNodeInternals, reactFlowInstance);
        }, 150);
      }
    }, 5); // TODO REMOVE

    setHoveredItemId(itemId);

    if (onAdd) {
      onAdd();
    }
  };

  if (nodeMapperTypes.size == 0) {
    return <div />;
  }

  return (
    <div className="plex-fd-dropdown" onWheelCapture={handleWheel}>
      <div className="plex-fd-anm-modalHeaderWrapper">
        <div className="plex-fd-anm-modalHeader">
          <div className="modalTitle">Quick Add Node</div>
          <div className="plex-fd-anm-close-button" onClick={onClose} />
        </div>
      </div>

      <div id="plex-fd-dropdown-content" className="plex-fd-dropdown-content">
        <div className="plex-fd-anm-search-box-container">
          <input
            type="text"
            id="plex-fd-dropdown-search"
            className="plex-fd-dropdown-search"
            onKeyUp={searchDropdown}
            placeholder="Search..."
            autoFocus={true}
            onFocus={() => setSearchFocusState(true)}
            onBlur={() => setSearchFocusState(false)}
          />

          <div className="plex-fd-anm-magnifying-glass-icon" />
        </div>

        {Array.from(nodeMapperTypes.entries()).map(([key, node]) => (
          <a
            href="#"
            className={`nodrag ${node.nodeType}-node-icon`}
            // Need draggable=true to enable onDragStart event, to disable drag propagation and stop further drag events.
            draggable={true}
            key={node.text}
            id={node.text}
            onClick={onClick}
            style={node.text === hoveredItemId ? { backgroundColor: "#c7d7db" } : {}}
            onMouseEnter={() => handleItemHover(node.text)}
            onMouseLeave={() => handleItemHover("")}
            onDragStart={(event: React.DragEvent<HTMLAnchorElement>) => {
              event.stopPropagation();
              event.preventDefault();
              return false;
            }}
          >
            {node.text}
          </a>
        ))}
      </div>
    </div>
  );
};
