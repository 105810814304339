import React, { FunctionComponent, ReactNode } from "react";
import { ITextQuery } from "./TextQuery";
import styles from "./PickerModal.module.scss";

interface IMatchingTextProps {
  query: ITextQuery;
}

export const MatchingText: FunctionComponent<IMatchingTextProps> = ({ query, children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!query || typeof children !== "string") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // We are not currently handling non-string children. Technically we
  // could drill into the children and pull out the string nodes, but
  // that could get very complex.
  const matches = query.getMatches(children);
  if (matches.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  let pos = 0;
  let index = 0;
  const elements: ReactNode[] = [];

  while (pos < children.length) {
    const match = matches[index++];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!match) {
      // no more matches - pull in rest of string
      elements.push(children.substring(pos, children.length));
      break;
    }

    if (match.start !== pos) {
      // pull in preceeding text
      elements.push(children.substring(pos, match.start));
    }

    // wrap match in mark element
    elements.push(
      <mark key={index} className={styles.highlightText}>
        {children.substring(match.start, match.end)}
      </mark>
    );
    pos = match.end;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{elements}</>;
};
