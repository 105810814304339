import { ReactText } from "react";
import { sort as arraySort } from "@arrays-immutable";
import { Comparer } from "../../Common.types";
import {
  GridState,
  GridRecord,
  ActionPayload,
  SortAction,
  SortInitialStateAction,
  LoadAction
} from "../DataTable.types";
import { reverseComparer, reduceComparer } from "../data-sorting";

export type SortEntry<T> = {
  id: ReactText;
  comparer: Comparer<T>;
  reversed?: boolean;
};

export type SortState<T> = {
  sorts: Array<SortEntry<T>>;
  fixed: boolean;
  unsorted: Array<GridRecord<T>>;
  overriden: boolean;
};

export type GridStateWithSort<T> = GridState<T> & {
  sort: SortState<T>;
};

const EMPTY_SORT_STATE: SortState<unknown> = {
  sorts: [],
  fixed: false,
  unsorted: [],
  overriden: false
};

// actions
export const toggleSort = <T>(action: ActionPayload<SortAction<T>>): SortAction<T> => ({
  type: "DataTable/toggleSort",
  ...action
});

export const setInitialSort = <T>(action: ActionPayload<SortInitialStateAction<T>>): SortInitialStateAction<T> => ({
  type: "DataTable/setInitialSort",
  ...action
});

// reducers
const makeAggregateComparator = <T>({ sorts }: SortState<T>): Comparer<T> | null => {
  if (sorts.length === 0) {
    return null;
  }

  if (sorts.length === 1) {
    return sorts[0].reversed ? reverseComparer(sorts[0].comparer) : sorts[0].comparer;
  }

  return (a: T, b: T) => {
    for (let i = 0; i < sorts.length; i++) {
      const { reversed, comparer } = sorts[i];
      const result = reversed ? comparer(b, a) : comparer(a, b);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
};

const applySort = <T>(data: Array<GridRecord<T>>, state: SortState<T>) => {
  const comparer = makeAggregateComparator(state);
  return comparer
    ? arraySort(
        data,
        reduceComparer(comparer, record => record.value)
      )
    : data;
};

const updateSortState = <T>(state: SortState<T>, action: SortAction<T>): SortState<T> => {
  const { id, comparer } = action;
  let { sorts, fixed } = state;

  if (fixed) {
    const sortIndex = sorts.findIndex(x => x.id === id);
    if (sortIndex === -1) {
      // adding a new sort order
      return { ...state, overriden: true, sorts: [...sorts, { id, comparer }] };
    }

    const sort = sorts[sortIndex];
    if (sort.reversed) {
      // removing sort order
      sorts.splice(sortIndex, 1);
    } else {
      // reversing sort order
      sorts.splice(sortIndex, 1, { id, comparer, reversed: true });
    }

    fixed = sorts.length > 0;
    return { ...state, fixed, overriden: true, sorts: [...sorts] };
  }

  if (sorts.length === 1 && sorts[0].id === id) {
    const { reversed } = sorts[0];
    if (reversed) {
      // toggle into fixed mode
      return { ...state, fixed: true, overriden: true, sorts: [{ id, comparer }] };
    }
    // reverse current sort order
    return { ...state, overriden: true, sorts: [{ id, comparer, reversed: true }] };
  }

  // set current sort order
  return { ...state, overriden: true, sorts: [{ id, comparer }] };
};

export const handleToggleSort = <T>(state: GridStateWithSort<T>, action: SortAction<T>): GridStateWithSort<T> => {
  const sort = updateSortState(state.sort, action);
  if (sort.sorts.length === 0) {
    return { ...state, sort, data: sort.unsorted };
  }
  return { ...state, sort, data: applySort(state.data, sort) };
};

export const handleInitialSort = <T>(
  state: GridStateWithSort<T>,
  action: SortInitialStateAction<T>
): GridStateWithSort<T> => {
  const { sorts, overriden } = state.sort;
  if (overriden) {
    // If user has initiated sort, ignore initial state
    return state;
  }

  const { id, dir, index, comparer } = action;
  const reversed = dir === "desc";
  sorts[index] = { id, comparer, reversed };

  return { ...state, sort: { ...state.sort, fixed: sorts.length > 1, sorts: sorts.slice() } };
};

export const interceptLoadForSort = <T>(state: GridStateWithSort<T>, _action: LoadAction<T>) => {
  const unsorted = state.data;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const sort = { ...(state.sort || EMPTY_SORT_STATE), unsorted };
  if (sort.sorts.length > 0) {
    return { ...state, sort, data: applySort(state.data, state.sort) };
  }
  return { ...state, sort };
};
