import React, {
  FocusEventHandler,
  ChangeEvent,
  FormEventHandler,
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  createElement,
  FunctionComponent
} from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, ValueChangeHandler } from "../Common.types";
import styles from "./Checkbox.module.scss";

export interface ICheckboxProps extends ICommonProps {
  /** The name attribute */
  name?: string;
  /**
   * Sets the checkbox as disabled
   * Specifically, disabled inputs do not receive the click event, and disabled inputs are not submitted with the form.
   * @default false
   */
  disabled?: boolean;
  /** The value used when checked (defaults to true) */
  checkedValue?: unknown;
  /** The read-only attribute on the checkbox. */
  readOnly?: boolean;
  /**
   * Indicates whether or not the checkbox is checked
   */
  checked?: boolean;
  /**
   * Indicates that the checkbox is in an indeterminate state
   */
  indeterminate?: boolean;
  /**
   * The string to use as the value of the checkbox when submitting the form, if the checkbox is currently toggled on
   */
  value?: string;
  /**
   * Event triggered when the value changes
   */
  onChange?: FormEventHandler<HTMLInputElement>;
  /**
   * Event triggered when the element loses focus
   */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /**
   * Event triggered when the value changes
   */
  onValueChange?: ValueChangeHandler<unknown>;
}

/**
 * Renders a standard checkbox
 *
 * @param props
 */
const HtmlCheckbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ className, readOnly, disabled, indeterminate, checked, onChange, checkedValue, onValueChange, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    const classes = clsx(styles.base, className, readOnly && styles.readonly);
    const inputChecked = checked || indeterminate;

    useLayoutEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      if (onValueChange) {
        let currentValue: unknown = e.currentTarget.checked;
        if (checkedValue !== undefined) {
          currentValue = currentValue ? checkedValue : null;
        }

        onValueChange(currentValue);
      }
    };

    return (
      <input
        type="checkbox"
        checked={!!inputChecked}
        className={classes}
        readOnly={readOnly}
        disabled={disabled}
        ref={inputRef}
        onChange={handleChange}
        {...props}
      />
    );
  }
);
HtmlCheckbox.displayName = "Checkbox";

const XmlCheckbox: FunctionComponent<ICheckboxProps> = ({ checked }) => {
  const checkedNode = React.createElement("checked", {}, String(checked ?? "false"));
  return createElement("plex-control-checkbox", {}, checkedNode);
};

export const Checkbox = withPrinting(HtmlCheckbox, XmlCheckbox);
