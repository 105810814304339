import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ObjectPropertiesNode",
  label: "Fields",
  category: NodeTypeCategory.general,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [{ name: "object", type: DataType.OBJECT, hideLabel: true, required: false }],
  dataOutputs: [],
  documentDataOutputList: "properties",
  outputsSchemaProperties: true,
  nodeConfigProperties: [
    {
      name: "outputs",
      sectionName: "Output",
      propertyType: NodeConfigPropertyType.EnabledOutputs
    }
  ],
  size: NodeSize.Small,
  evaluate: (input, nodeProperties) => {
    let output: any = {};
    Object.keys(nodeProperties.outputs).forEach((outputName) => {
      if (nodeProperties.outputs[outputName].enabled) {
        output[outputName] = input.object ? (input.object as any)[outputName] : null;
      }
    });
    return { output: output, activeControlHandleIds: [] };
  }
});

export const ObjectPropertiesNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
