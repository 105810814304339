import { IFilePickerFile } from "./FilePicker";

export type StatusMessageId =
  | "empty"
  | "ui-common-warn-recordLimitExceeded"
  | "ui-common-filePicker-info-allItemsShown"
  | "ui-common-filePicker-info-foundText";

export type FilePickerSearchState = {
  files: IFilePickerFile[]; // the list of files to display for selection
  searchTextCompleted: string; // the search text for the completed search
  searchTextInProgress: string; // the search text for the in progress search
  initialized: boolean; // true when the picker has started it's initial search
  searching: boolean; // true when the picker is searching
  statusMessageId: StatusMessageId; // the search status message to display to the user
};

export type FilePickerSearchAction =
  | { type: "load"; files: IFilePickerFile[]; limitExceeded: boolean }
  | { type: "searching"; searchText: string }
  | { type: "file-accepted"; file: IFilePickerFile };

export const createSearchReducer = () => {
  return (state: FilePickerSearchState, action: FilePickerSearchAction): FilePickerSearchState => {
    switch (action.type) {
      case "load": {
        const { files, limitExceeded } = action;
        const { searchTextInProgress } = state;

        let statusMessageId: StatusMessageId = "empty";
        if (limitExceeded) {
          statusMessageId = "ui-common-warn-recordLimitExceeded";
        } else {
          statusMessageId =
            searchTextInProgress === ""
              ? "ui-common-filePicker-info-allItemsShown"
              : "ui-common-filePicker-info-foundText";
        }

        return {
          ...state,
          files,
          searchTextCompleted: searchTextInProgress,
          statusMessageId,
          searching: false
        };
      }
      case "searching": {
        const { searchText } = action;
        return { ...state, searchTextInProgress: searchText, initialized: true, searching: true };
      }
      case "file-accepted": {
        const { file } = action;
        let { searchTextCompleted, files } = state;
        if (file.name.includes(searchTextCompleted)) {
          files = [...files, file];
        }

        return { ...state, files };
      }
      default:
        return state;
    }
  };
};

export const createInitialSearchState = (): FilePickerSearchState => {
  return {
    files: [],
    searchTextCompleted: "",
    searchTextInProgress: "",
    initialized: false,
    searching: false,
    statusMessageId: "empty"
  };
};
