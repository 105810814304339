import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const addressInputName = "address";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CallEmailSendNode",
  label: "Email",
  category: NodeTypeCategory.services,
  controlInputs: [{ name: "call" }],
  controlOutputs: [{ name: "completed" }],
  dataInputs: [
    { name: addressInputName, type: DataType.STRINGLIST },
    { name: "subject", type: DataType.STRING },
    { name: "body", type: DataType.STRING }
  ],
  dataOutputs: [],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [DataType.STRINGLIST, DataType.STRING],
        dataProperties: [addressInputName]
      }
    }
  ],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CallEmailSendNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
