import React, { FunctionComponent, createElement } from "react";
import { Checkmark3Icon } from "@plex/icons";
import { withPrinting } from "@plex/react-xml-renderer";
import styles from "./Checkbox.module.scss";

interface IBooleanProps {
  /** A truthy or falsy value */
  value?: unknown;
}

const True: FunctionComponent = props => (
  <i className={styles.trueIcon} {...props}>
    <Checkmark3Icon />
  </i>
);

const False: FunctionComponent = () => null;

/** Component which displays a read-only representation of a boolean value. */
const HtmlBoolean: FunctionComponent<IBooleanProps> = ({ value, ...other }) => {
  const Icon = value ? True : False;
  return <Icon {...other} />;
};

const XmlBoolean: FunctionComponent<IBooleanProps> = ({ value }) => {
  if (value) {
    return createElement("checkmark", {}, "true");
  }

  return null;
};

export const Boolean = withPrinting(HtmlBoolean, XmlBoolean);
