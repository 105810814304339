import React, { ReactText, ChangeEvent, ReactElement, createElement, FunctionComponent } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, ValueAccessor } from "../Common.types";
import { Radio } from "../Radio";
import { Checkbox } from "../Checkbox";
import styles from "./SelectionList.module.scss";

export enum Alignment {
  vertical = "vertical",
  horizontal = "horizontal"
}

interface ISelectionItemProps<T> {
  /**
   * Determines whether the item is checked
   */
  checked: boolean;
  /**
   * The name attribute
   */
  name: string;
  /**
   * Sets the checkbox as disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Sets the inner checkboxes as readonly
   * @default false
   */
  readOnly?: boolean;
  /**
   * Function, which gets value/key from option item
   */
  keySelector: ValueAccessor<T, ReactText>;
  /**
   * Items to select from
   */
  items: T[];
  /**
   * Selected items
   */
  selected: T[];
  /**
   * Event triggered when the value changes
   */
  onSelectionChanged: (rows: T[]) => void;
  /**
   * The value associated with the item
   */
  value: string;
}

export interface ISelectionListProps<T> extends ICommonProps, Omit<ISelectionItemProps<T>, "checked" | "value"> {
  /**
   * The tab index of the element
   * */
  tabIndex?: number;
  /**
   * Indicates whether the options should be aligned horizontally or vertically.
   */
  alignment?: Alignment;
  /**
   * Allow to select multiple options
   */
  multiSelect?: boolean;
  /**
   * Function, which gets display value from option item
   */
  displaySelector: ValueAccessor<T, string>;
}

export interface ISelectionList {
  <T extends {}>(props: ISelectionListProps<T>): ReactElement;
  displayName: string;
}

// eslint-disable-next-line func-style
function MultiSelectItem<T>({
  name,
  keySelector,
  disabled,
  readOnly,
  items,
  selected = [],
  onSelectionChanged,
  value,
  checked
}: ISelectionItemProps<T>) {
  const handleSelection = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onSelectionChanged) {
      let values: T[];
      if (e.currentTarget.checked) {
        values = [...selected, items.find(x => String(keySelector(x)) === e.currentTarget.value)].filter(
          Boolean
        ) as T[];
      } else {
        values = selected.filter(x => String(keySelector(x)) !== e.currentTarget.value);
      }

      onSelectionChanged(values);
    }
  };

  return (
    <Checkbox
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      readOnly={readOnly}
      className={styles.base}
      onChange={handleSelection}
    />
  );
}

// eslint-disable-next-line func-style
function SingleSelectItem<T>({
  name,
  keySelector,
  disabled,
  readOnly,
  items,
  onSelectionChanged,
  value,
  checked
}: ISelectionItemProps<T>) {
  const handleSelection = (e: unknown) => {
    if (!disabled && onSelectionChanged) {
      const selectedValue = items.find(x => String(keySelector(x)) === e);
      onSelectionChanged(selectedValue === undefined ? [] : [selectedValue]);
    }
  };

  const stringValue = String(value);
  return (
    <Radio
      name={name}
      value={stringValue}
      checked={checked}
      disabled={disabled}
      readOnly={readOnly}
      className={styles.base}
      onSelected={handleSelection}
    />
  );
}

// eslint-disable-next-line func-style
function HtmlSelectionList<T>({
  disabled,
  readOnly,
  items,
  multiSelect,
  keySelector,
  displaySelector,
  selected = [],
  onSelectionChanged,
  className,
  alignment = Alignment.vertical,
  name,
  ...other
}: ISelectionListProps<T>): ReactElement {
  const classes = clsx(
    styles.base,
    alignment === Alignment.vertical ? styles.selectionlistVertical : styles.selectionlistHorizontal,
    className,
    disabled && styles.disabled,
    readOnly && styles.readonly
  );

  const Component = multiSelect ? MultiSelectItem : SingleSelectItem;
  const selectedValues = selected.map(row => String(keySelector(row)));

  return (
    <div data-testid={multiSelect ? "plex-checklist" : "plex-radiolist"} className={classes} {...other}>
      {// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      items?.map(item => {
        const value = String(keySelector(item));
        const checked = selectedValues.includes(value);

        return (
          <label key={value} className={styles.base}>
            <Component
              value={value}
              name={name}
              keySelector={keySelector}
              disabled={disabled}
              readOnly={readOnly}
              items={items}
              selected={selected}
              checked={checked}
              onSelectionChanged={onSelectionChanged}
            />
            <span className={styles.labelText}>{displaySelector(item)}</span>
          </label>
        );
      })}
    </div>
  );
}

const XmlSelectionList = <T extends {}>({ displaySelector, selected }: ISelectionListProps<T>): ReactElement => {
  return createElement("plex-control-selectionlist", {}, selected.map(displaySelector).join(","));
};

export const SelectionList = withPrinting<ISelectionListProps<unknown>>(
  HtmlSelectionList,
  XmlSelectionList as FunctionComponent
) as ISelectionList;
