import React, { forwardRef, useRef, useImperativeHandle, FunctionComponent } from "react";
import clsx from "clsx";
import { useScrollIntoView } from "@hooks";
import { BannerStatus, IBannerProps } from "./BannerContext";
import styles from "./Banner.module.scss";

/** Renders a banner with the given message and status. */
export const Banner: FunctionComponent<IBannerProps> = forwardRef<HTMLDivElement, IBannerProps>(
  ({ className, status, close, children, scroll = false, scrollOffset = 0, ...other }, ref) => {
    const elementRef = useRef<HTMLDivElement>(null);
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => elementRef.current);

    let messageStyle = styles.bannerInfo;
    switch (status) {
      case BannerStatus.error:
        messageStyle = styles.bannerError;
        break;
      case BannerStatus.warning:
        messageStyle = styles.bannerWarning;
        break;
      case BannerStatus.success:
        messageStyle = styles.bannerSuccess;
        break;
      default:
        messageStyle = styles.bannerInfo;
        break;
    }

    useScrollIntoView(elementRef, scroll, scrollOffset);

    return (
      <div
        data-testid="plex-banner"
        ref={elementRef}
        className={clsx(styles.bannerWidth, styles.bannerMargin, messageStyle, className)}
        {...other}
      >
        <button data-testid="plex-banner-close" type="button" className={styles.bannerCloseButton} onClick={close}>
          ×
        </button>
        {children}
      </div>
    );
  }
);

Banner.displayName = "Banner";
