import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./ActionBar.module.scss";

export interface IIconProps extends ICommonProps {
  /** Icon alignment (left or right) */
  alignment?: "left" | "right";
}

export const ActionBarIcon: FunctionComponent<IIconProps> = ({ className, alignment, children, ...other }) => {
  return (
    <i
      data-testid="plex-action-icon"
      className={clsx(alignment === "right" ? styles.btnIconRight : styles.btnIconLeft, className)}
      {...other}
    >
      {children}
    </i>
  );
};

ActionBarIcon.displayName = "ActionBarIcon";
