import { ReactText } from "react";
import { remove as arrayRemove, push as arrayPush } from "@arrays-immutable";
import { returnTrue } from "@utils";
import {
  SelectAllAction,
  SelectionStatus,
  GridState,
  SelectRowAction,
  SelectionMode,
  ActionPayload,
  SelectionModeChangeAction,
  UpdateSelections
} from "../DataTable.types";

export type SelectionState = {
  mode: SelectionMode;
  status: SelectionStatus;
  selectedKeys: ReactText[];
  previousKeys: ReactText[];
};

export type GridStateWithSelections<T = unknown> = GridState<T> & {
  selections: SelectionState;
};

// actions
export const toggleSelection = <T>(payload: ActionPayload<SelectRowAction<T>>): SelectRowAction<T> => ({
  type: "DataTable/toggleSelect",
  ...payload
});
export const toggleSelectAll = <T>(payload: ActionPayload<SelectAllAction<T>>): SelectAllAction<T> => ({
  type: "DataTable/toggleSelectAll",
  ...payload
});
export const updateSelectionMode = (payload: ActionPayload<SelectionModeChangeAction>): SelectionModeChangeAction => ({
  type: "DataTable/updateSelectionMode",
  ...payload
});
export const updateSelections = (payload: ActionPayload<UpdateSelections>): UpdateSelections => ({
  type: "DataTable/updateSelections",
  ...payload
});

// reducers
export const getSelectionStatus = <T>(selectedKeys: ReactText[], data: T[]): SelectionStatus => {
  if (selectedKeys.length === 0) {
    return "none";
  }

  if (selectedKeys.length === data.length) {
    return "all";
  }

  return "some";
};

export const handleSelectionToggle = <T>(
  gridState: GridStateWithSelections<T>,
  { row, canSelectRow = returnTrue }: SelectRowAction<T>
): GridStateWithSelections<T> => {
  if (!canSelectRow(row.value)) {
    return gridState;
  }

  const { selections: state, data } = gridState;
  const { mode } = state;
  switch (mode) {
    case SelectionMode.single: {
      let selectedKeys: ReactText[];
      if (state.selectedKeys[0] === row.key) {
        selectedKeys = [];
      } else {
        selectedKeys = [row.key];
      }

      return { ...gridState, selections: { ...state, selectedKeys } };
    }

    case SelectionMode.multiple: {
      let selectedKeys: ReactText[];
      if (state.selectedKeys.includes(row.key)) {
        selectedKeys = arrayRemove(state.selectedKeys, row.key);
      } else {
        selectedKeys = arrayPush(state.selectedKeys, row.key);
      }

      return {
        ...gridState,
        selections: {
          ...state,
          status: getSelectionStatus(selectedKeys, data),
          previousKeys: selectedKeys,
          selectedKeys
        }
      };
    }

    default:
      return gridState;
  }
};

export const handleSelectAllToggle = <T>(
  gridState: GridStateWithSelections<T>,
  { canSelectRow = returnTrue }: SelectAllAction<T>
): GridStateWithSelections<T> => {
  const { data } = gridState;
  const { selections: state } = gridState;

  if (state.status === "all") {
    return { ...gridState, selections: { ...state, status: "none", selectedKeys: [] } };
  }

  // If there are no previous selections, we skip and go right to selecting all rows.
  if (state.status === "none" && state.previousKeys.length > 0 && state.previousKeys.length < data.length) {
    return {
      ...gridState,
      selections: { ...state, status: "some", selectedKeys: state.previousKeys }
    };
  }

  if (canSelectRow === returnTrue) {
    return {
      ...gridState,
      selections: { ...state, status: "all", selectedKeys: data.map(x => x.key) }
    };
  }

  const selectedKeys = data.filter(x => canSelectRow(x.value)).map(x => x.key);
  return { ...gridState, selections: { ...state, status: "all", selectedKeys } };
};

export const handleSelectionModeChange = <T>(
  state: GridStateWithSelections<T>,
  { mode }: SelectionModeChangeAction
): GridStateWithSelections<T> => {
  const { selectedKeys } = state.selections;

  // Make sure that selections are not in an invalid state when selection mode changes
  if (
    (mode === SelectionMode.none && selectedKeys.length > 0) ||
    (mode === SelectionMode.single && selectedKeys.length > 1)
  ) {
    return {
      ...state,
      selections: { ...state.selections, mode, selectedKeys: [], previousKeys: [] }
    };
  }

  return state;
};

export const handleSelectionsUpdate = <T>(
  state: GridStateWithSelections<T>,
  { selectedKeys }: UpdateSelections
): GridStateWithSelections<T> => {
  return { ...state, selections: { ...state.selections, selectedKeys } };
};
