import React from "react";
import { Tab as TabItem } from "react-tabs";
import clsx from "clsx";
import styles from "./Tabs.module.scss";
import { ITabsElement, TabsRole, ITabProps } from "./Tabs.types";

/** Component which renders a tab name. */
const Tab: ITabsElement = ({ disabled = false, className, children, ...other }: ITabProps) => {
  const tabClassName = clsx(disabled && styles.tabDisabled, styles.tab, className);
  return (
    <TabItem
      data-testid="plex-tab-list-item"
      disabled={disabled}
      selectedClassName={styles.tabSelected}
      className={tabClassName}
      {...other}
    >
      {children}
    </TabItem>
  );
};

Tab.displayName = TabsRole.Tab;
Tab.tabsRole = TabsRole.Tab;

export { Tab };
