import React, {
  FunctionComponent,
  createRef,
  useEffect,
  useState,
  useRef,
  ReactNode,
  ReactElement,
  RefObject,
  forwardRef,
  useImperativeHandle,
  ForwardRefExoticComponent,
  RefAttributes
} from "react";
import clsx from "clsx";
import { useLocalization } from "@plex/culture-react";
import { ICommonProps } from "@components/Common.types";
import { withNoPrinting } from "@plex/react-xml-renderer";
import styles from "./ActionBar.module.scss";
import { ActionBarButton } from "./ActionBar.Button";
import { ActionBarDropdown } from "./ActionBar.Dropdown";
import { ActionBarIcon } from "./ActionBar.Icon";
import { ActionBarDivider } from "./ActionBar.Divider";
import { ActionBarDropdownItems } from "./ActionBar.DropdownItems";
import { ActionBarDropdownButton } from "./ActionBar.DropdownButton";

export interface IActionBar
  extends ForwardRefExoticComponent<React.PropsWithChildren<ICommonProps> & RefAttributes<HTMLDivElement>> {
  Button: typeof ActionBarButton;
  Dropdown: typeof ActionBarDropdown;
  Icon: typeof ActionBarIcon;
  Divider: typeof ActionBarDivider;
  DropdownItems: typeof ActionBarDropdownItems;
  DropdownButton: typeof ActionBarDropdownButton;
  displayName?: string;
}

const MoreMenu: FunctionComponent = ({ children }) => {
  const { t } = useLocalization();
  return (
    <ActionBar.Dropdown>
      <ActionBar.DropdownButton>{t("ui-common-actionBar-option-more")}</ActionBar.DropdownButton>
      <ActionBar.DropdownItems>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{children}</>
      </ActionBar.DropdownItems>
    </ActionBar.Dropdown>
  );
};

// eslint-disable-next-line react/display-name
const HtmlActionBar = forwardRef<HTMLDivElement, ICommonProps>(({ className, children, ...other }, ref) => {
  const elements: Array<RefObject<HTMLElement>> = [];
  const [moreElements, setMoreElements] = useState<ReactElement[]>([]);
  const [moreIndex, setMoreIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [elementsWidth, setElementsWidth] = useState<number[]>([]);
  const [loaded, setLoaded] = useState(false);
  useImperativeHandle(ref, () => containerRef.current!);

  const checkForReorder = () => {
    if (!containerRef.current) {
      return;
    }
    const containerWidth = parseFloat(window.getComputedStyle(containerRef.current).getPropertyValue("width"));

    let index = 0;
    let computed = 0;

    for (let i = 0; i < elementsWidth.length; i++) {
      computed += elementsWidth[i];
      if (computed > containerWidth) {
        index = i - 1;
        setMoreIndex(index);
        break;
      }
    }

    if (index) {
      const visibleItems = React.Children.toArray(children).slice(index) as ReactElement[];
      setMoreElements(visibleItems);
      return;
    }

    setMoreIndex(0);
  };

  const renderUsual = () => {
    return React.Children.toArray(children).slice(0, moreIndex);
  };

  const addChild = (childElems: ReactNode | ReactNode[]) => {
    return React.Children.toArray(childElems).map(child => {
      const refItem = createRef<HTMLElement>();
      elements.push(refItem);
      return React.cloneElement(child as ReactElement, { ref: refItem });
    });
  };

  // used to set initial elements width before any 'more' button content appears. Should be call only once
  /* eslint-disable  react-hooks/exhaustive-deps */
  useEffect(() => {
    setElementsWidth(
      elements.map(el => {
        if (el.current) {
          const style = window.getComputedStyle(el.current);
          const marginLeft = parseFloat(style.marginLeft);
          const marginRight = parseFloat(style.marginRight);
          const width = parseFloat(style.width);
          return marginLeft + width + marginRight;
        }

        return 0;
      })
    );
  }, []);

  useEffect(() => {
    if (loaded) {
      return;
    }

    if (elementsWidth.length) {
      checkForReorder();
      setLoaded(true);
    }
  }, [elementsWidth, loaded]);

  useEffect(() => {
    window.addEventListener("resize", checkForReorder);
    return () => window.removeEventListener("resize", checkForReorder);
  }, [elementsWidth, moreIndex]);

  if (moreIndex) {
    return (
      <div
        data-testid="plex-actions-wrapper"
        ref={containerRef}
        className={clsx(styles.base, styles.actionsWrapper, className)}
        {...other}
      >
        <ul data-testid="plex-actions" role="menubar">
          {addChild(renderUsual())}
          <MoreMenu>{moreElements}</MoreMenu>
        </ul>
      </div>
    );
  }

  return (
    <div
      data-testid="plex-actions-wrapper"
      ref={containerRef}
      className={clsx(styles.base, styles.actionsWrapper, className)}
      {...other}
    >
      <ul data-testid="plex-actions" role="menubar">
        {addChild(children)}
      </ul>
    </div>
  );
});

export const ActionBar = withNoPrinting(HtmlActionBar) as IActionBar;
ActionBar.displayName = "ActionBar";
ActionBar.Button = ActionBarButton;
ActionBar.Dropdown = ActionBarDropdown;
ActionBar.Icon = ActionBarIcon;
ActionBar.Divider = ActionBarDivider;
ActionBar.DropdownItems = ActionBarDropdownItems;
ActionBar.DropdownButton = ActionBarDropdownButton;
