import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConvertBooleanToStringNode",
  label: "Boolean To String",
  category: NodeTypeCategory.text,
  isExperimental: true,
  controlOutputs: [],
  dataInputs: [{ name: "source", type: DataType.BOOLEAN, hideLabel: true }],
  dataOutputs: [{ name: "result", type: DataType.STRING }],
  evaluate: (input: any) => {
    return {
      output: { result: input.source.toString() },
      activeControlHandleIds: []
    };
  }
});

export const ConvertBooleanToStringNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
