import React, { forwardRef } from "react";
import { useLocalization } from "@plex/culture-react";
import { Button, IButtonProps } from "./Button";

export const OkButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <Button {...props} ref={ref}>
      {t("ui-common-action-ok")}
    </Button>
  );
});
OkButton.displayName = "OkButton";

export const ApplyButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <Button {...props} ref={ref}>
      {t("ui-common-action-apply")}
    </Button>
  );
});
ApplyButton.displayName = "ApplyButton";

export const SubmitButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  return <Button as="button" type="submit" ref={ref} {...props} />;
});
SubmitButton.displayName = "SubmitButton";

export const FinishButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <Button {...props} ref={ref}>
      {t("ui-common-action-finish")}
    </Button>
  );
});
FinishButton.displayName = "FinishButton";

export const PreviousButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <Button {...props} ref={ref}>
      &lt;&nbsp;{t("ui-common-action-previous")}
    </Button>
  );
});
PreviousButton.displayName = "PreviousButton";

export const NextButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <Button {...props} ref={ref}>
      {t("ui-common-action-next")}&nbsp;&gt;
    </Button>
  );
});
NextButton.displayName = "NextButton";
