import {
  FunctionComponent,
  NamedExoticComponent,
  createElement,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  ReactElement,
  PropsWithChildren
} from "react";
import { usePrinting } from "./use-printing";
import { setRef } from "./set-ref";

/**
 * HOC which toggles logic between the default and print
 * renders based on whether the component is being rendered in
 * print mode.
 *
 * @param {DefaultComponent} Component
 * @param {PrintComponent} XmlComponent
 */
export function withPrinting<P>(
  component: FunctionComponent<P>,
  xmlComponent: FunctionComponent<P>
): FunctionComponent<P>;
export function withPrinting<P, R>(
  component: ForwardRefExoticComponent<P & RefAttributes<R>>,
  xmlComponent: FunctionComponent<P>
): ForwardRefExoticComponent<P & RefAttributes<R>>;
// eslint-disable-next-line func-style
export function withPrinting<P, R>(
  component: (props: PropsWithChildren<P>) => ReactElement | null,
  xmlComponent: FunctionComponent<P>
): unknown {
  if (typeof component === "function") {
    const fc = component as FunctionComponent<P>;
    const FC: FunctionComponent<P> = (props: P) => {
      if (usePrinting()) {
        return createElement(xmlComponent, props);
      }
      return createElement(fc, props);
    };
    FC.displayName = `withPrinting(${fc.displayName || fc.name})`;
    return FC;
  }

  const c = component as NamedExoticComponent<P>;
  const C = forwardRef<R, P>((props, ref) => {
    if (usePrinting()) {
      return createElement(xmlComponent, props);
    }

    return createElement(c, {
      ...props,
      ref: (node: R) => {
        setRef(node, ref);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setRef(node, (c as any).ref);
      }
    });
  });
  C.displayName = `withPrinting(${c.displayName || c.name})`;
  return C;
}
