export interface IFlowInputProperties {
  propertyName: string;
  propertyType: string;
  propertyValue: any;
}

export class FlowInputManagementController {
  private flowInputPool: IFlowInputProperties[];

  constructor() {
    this.flowInputPool = [];
  }

  public getProperties() {
    return this.flowInputPool;
  }

  public intialize(flowInputs: any[]) {
    this.flowInputPool = flowInputs;
  }

  public setProperties(input: IFlowInputProperties) {
    if (
      input.propertyName === "" ||
      input.propertyName == null ||
      input.propertyValue === "" ||
      input.propertyValue == null
    ) {
      throw new Error("Invalid Item");
    }

    // Check if the input already exists
    const existingIndex = this.flowInputPool.findIndex((item) => item.propertyName === input.propertyName);
    if (existingIndex !== -1) {
      // Input already exists, handle the duplicate item error
      return false;
      // throw new Error("Duplicate item");
    }

    // Add the input to the flowInputPool array
    this.flowInputPool.push(input);

    return true;
  }

  public deleteProperties(input: IFlowInputProperties) {
    const index = this.flowInputPool.findIndex((item) => item.propertyName === input.propertyName);

    if (index !== -1) {
      // Remove the item from the local storage
      this.flowInputPool.splice(index, 1);
    }
  }
}
