import React, { FC } from "react";

export const ConstantLabel: FC<any> = (props) => {
  const { data, kind } = props;

  const getValueDisplay = () => {
    switch (data.nodeProperties.outputs.result.type) {
      case "boolean":
        return data.nodeProperties.literal === "" || !data.nodeProperties.literal ? "False" : "True";
      case "date":
        return data.nodeProperties.literal?.toString();
      default:
        return data.nodeProperties.literal;
    }
  };

  const nodeType = kind ?? data.nodeProperties.outputs.result.type;

  return (
    <div>
      <div className="value-node-type">{nodeType}</div>
      <div className="value-node-value">{getValueDisplay()}</div>
    </div>
  );
};
