import React from "react";
import { PrintProvider } from "./PrintProvider";
import { xmlReconciler } from "./xml-reconciler";

export const ReactXMLRenderer = {
  renderXML(node: Node | JSX.Element, callback: (xml: Document, clearContainer: () => void) => void) {
    const xmlDoc = document.implementation.createDocument(null, "plex-document", null);
    const container = xmlReconciler.createContainer(
      xmlDoc.documentElement,
      0,
      null,
      false,
      false,
      "",
      (): void => {},
      null
    );

    const clearContainer = () => {
      // updating container with null triggers clearContainer, removeChildFromContainer
      // and removeChild functions from HostConfig as per container structure
      xmlReconciler.updateContainer(null, container, null, () => {});
    };

    const wrappedNode = React.createElement(
      PrintProvider,
      {
        // We may end up wanting to put a timeout on this and return the
        // document as is if the timeout is exceeded.
        onPrintReady: () => callback(xmlDoc, clearContainer)
      },
      node
    );

    xmlReconciler.updateContainer(wrappedNode, container, null, () => {});
  }
};
