import React, { forwardRef } from "react";
import { ICommonDatePickerProps, ITemporalInputProps } from "./DatePicker.types";
import { DateInput } from "./DateInput";

interface IDateInputProps extends ITemporalInputProps, ICommonDatePickerProps {}

/**
 * This component renders an input with a calendar button that a user can use to
 * enter or select a month value.
 */
export const MonthInput = forwardRef<HTMLInputElement, IDateInputProps>((props, ref) => {
  return <DateInput defaultInputMode="month" ref={ref} {...props} />;
});

MonthInput.displayName = "MonthInput";
