import React, {
  ReactText,
  useMemo,
  useLayoutEffect,
  ChangeEvent,
  FocusEvent,
  forwardRef,
  Ref,
  ReactElement,
  createElement,
  FC
} from "react";
import clsx from "clsx";
import { sizeStyle } from "@components/inputs-styles";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonInputProps, InputSize } from "../Input";
import { ValueAccessor } from "../Common.types";
import styles from "./Dropdown.module.scss";

export interface IDropdownProps<T>
  extends Omit<ICommonInputProps, "maxLength" | "onEnterKey" | "onBlur" | "onKeyDown" | "onKeyPress"> {
  /**
   * Allow to select multiple options
   */
  multiSelect?: boolean;

  /**
   * When true, this will include an option with a null value and placeholder text if specified at the top of the list
   */
  includeEmpty?: boolean;

  /**
   * Option items
   */
  items: T[];

  /**
   * Function, which gets key from option item
   */
  keySelector: ValueAccessor<T, ReactText>;

  /**
   * Function, which gets value from option item
   */
  displaySelector: ValueAccessor<T, string>;

  /**
   * Selected option items array
   */
  selected: T[];

  /**
   * onSelect event handler
   */
  onSelectionChanged: (items: T[]) => void;

  /**
   * Event triggered when the element loses focus
   */
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
}

export interface IDropdown {
  <T>(props: IDropdownProps<T>, ref: Ref<HTMLSelectElement>): ReactElement;
  displayName: string;
}

const HtmlDropdown = forwardRef(function Dropdown<T>(
  {
    id,
    name,
    disabled,
    placeholder,
    size = InputSize.dynamic,
    multiSelect,
    includeEmpty,
    items = [],
    keySelector,
    displaySelector,
    selected = [],
    onSelectionChanged,
    className,
    ...other
  }: IDropdownProps<T>,
  ref: Ref<HTMLSelectElement>
): ReactElement {
  const selectedValue = useMemo(() => {
    if (multiSelect) {
      return selected.map((item: T) => String(keySelector(item)));
    }

    if (selected.length > 0) {
      return String(keySelector(selected[0]));
    }

    if (!includeEmpty && items.length > 0) {
      return String(keySelector(items[0]));
    }

    return "";
  }, [multiSelect, selected, includeEmpty, items, keySelector]);

  // Using layout effect to avoid race conditions with consumer
  // asyncronously setting selected values.
  useLayoutEffect(() => {
    if (includeEmpty || multiSelect || selected.length > 0) {
      return;
    }

    if (items.length > 0) {
      onSelectionChanged([items[0]]);
    }
  }, [includeEmpty, items, multiSelect, onSelectionChanged, selected]);

  const changeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    if (disabled) {
      return;
    }

    const values = Array.from(e.target.selectedOptions).map(x => x.value);

    const lastSelected = items.filter((item: T) => {
      return values.includes(String(keySelector(item)));
    });

    onSelectionChanged(lastSelected);
  };

  const classes = clsx(styles.base, styles.controlSelect, sizeStyle(size), className);

  return (
    <div className={styles.selectWrapper}>
      <select
        data-testid="plex-dropdown"
        id={id}
        name={name}
        disabled={disabled}
        multiple={multiSelect}
        value={selectedValue}
        onChange={changeHandler}
        className={classes}
        ref={ref}
        {...other}
      >
        {includeEmpty && <option>{placeholder}</option>}
        {items.map(item => {
          const key = keySelector(item);
          return (
            <option key={key} value={String(key)}>
              {displaySelector(item)}
            </option>
          );
        })}
      </select>
    </div>
  );
});
HtmlDropdown.displayName = "Dropdown";

const XmlDropdown = <T extends {}>({ displaySelector, selected }: IDropdownProps<T>): ReactElement => {
  return createElement("plex-control-select", {}, selected.map(displaySelector).join(","));
};

export const Dropdown = withPrinting<IDropdownProps<unknown>>(HtmlDropdown, XmlDropdown as FC) as IDropdown;
