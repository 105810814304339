import React, { FunctionComponent, PropsWithChildren } from "react";
import { useViewController } from "../ViewContext";
import { useReactFlow } from "reactflow";
import "./NodePopup.scss";

export interface INodePopupProps extends PropsWithChildren {
  nodeId: string;
}

export const NodePopup: FunctionComponent<INodePopupProps> = ({ nodeId, children }) => {
  const { propertiesPopupState } = useViewController();
  const reactFlowInstance = useReactFlow();
  const node = reactFlowInstance.getNode(nodeId);

  return (
    <div className="plex-fd-node-pop-up-size" hidden={!propertiesPopupState}>
      <div className="plex-fd-modalHeaderWrapper">
        <div className="plex-fd-modalHeader">
          <div className="modalTitle">{node?.data.nodeProperties.label}</div>
        </div>
        <div
          className="plex-fd-close-button"
          onClick={() => globalThis.setPropertiesPopupState((current: boolean) => (current = !current))}
        />
      </div>
      {children}
    </div>
  );
};
