import React, { SyntheticEvent, ReactNode, EventHandler, MutableRefObject, Ref, ReactElement } from "react";

// eslint-disable-next-line func-style
export function capitalize(value: string): string {
  if (!value) {
    return value;
  }

  return value[0].toUpperCase() + value.substr(1);
}

export const returnTrue = () => true;

export const noop = () => {};

type EventHandlerOrIgnore<E extends SyntheticEvent> = EventHandler<E> | false | null | undefined;
const isEventHandler = <E extends SyntheticEvent>(func: unknown): func is EventHandler<E> => {
  return !!func && typeof func === "function";
};

export const chainHandlers = <E extends SyntheticEvent>(
  ...handlers: Array<EventHandlerOrIgnore<E>>
): EventHandler<E> => {
  if (handlers.length <= 1) {
    return handlers[0] as EventHandler<E>;
  }

  const funcs = handlers.filter(isEventHandler);
  if (funcs.length <= 1) {
    return funcs[0];
  }

  return (e: E) => {
    let i = 0;
    while (!e.defaultPrevented && i < funcs.length) {
      funcs[i++](e);
    }
  };
};

// eslint-disable-next-line func-style
export function isPrimitive(value: unknown): boolean {
  const type = typeof value;
  return value === null || (type !== "object" && type !== "function");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPromise = <T>(obj: any): obj is Promise<T> => {
  return obj && typeof obj === "object" && typeof obj.then === "function";
};

/**
 * Renders only the components of allowed types.
 * @param children child React components
 * @param types allowed types
 */
export const renderChildrenOfType = (children: ReactNode, types: string[]): ReactNode[] => {
  // TODO: REMOVE! Parents should not care about their children
  return React.Children.toArray(children).filter((c: ReactNode) => {
    if (React.isValidElement(c) && c.type) {
      const filtered = types.filter((t: string) => {
        const type = (c.type as unknown) as { [key: string]: string };
        return type.displayName && type.displayName.toUpperCase() === t.toUpperCase();
      });

      if (filtered.length > 0) {
        return c;
      }
    }

    return false;
  });
};

/**
 * Gets the first scrollable parent element found. May be the document if the source element
 * is not within a scrollable container.
 */
export const getScrollableContainer = (element: HTMLElement): HTMLElement | Document => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === "absolute";
  const overflowRegex = /(auto|scroll)/;

  if (style.position === "fixed") {
    return document;
  }

  let parent: HTMLElement | null = element;
  while ((parent = parent?.parentElement)) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === "static") {
      continue;
    }

    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent === document.body ? document : parent;
    }
  }

  return document;
};

export const isDocument = (node: unknown): node is Document => {
  return node === document;
};

type Callback = () => void;
interface IDebouncedFunction extends Callback {
  cancel: () => void;
}

const throttledRequestAnimation = (callback: Callback) => {
  // this insures that the throttled function will only run once per frame
  let timeout: number | null = null;
  let triggered = false;

  const debounced: IDebouncedFunction = () => {
    if (!triggered) {
      triggered = true;
      timeout = window.requestAnimationFrame(() => {
        triggered = false;
        callback();
      });
    }
  };

  debounced.cancel = () => window.cancelAnimationFrame(timeout as number);
  return debounced;
};

/**
 * Debounces a function, preventing it from being called multiple
 * times within the number of milliseconds provided. Returned function
 * includes a `cancel` method attached which can be used to stop pending
 * execution.
 */
export const debounce = (callback: Callback, ms: number) => {
  let timeout: NodeJS.Timeout;
  const debounced: IDebouncedFunction = () => {
    clearTimeout(timeout);
    setTimeout(callback, ms);
  };

  debounced.cancel = () => clearTimeout(timeout);
  return debounced;
};

/**
 * Debounced a function and also limits the calls to once per animation frame.
 * Returned function includes a `cancel` method attached which can be used to
 * stop pending execution.
 */
export const debounceRender = (callback: Callback, ms: number) => {
  const inner = throttledRequestAnimation(callback);
  const outer = debounce(inner, ms);

  const { cancel } = outer;
  outer.cancel = () => {
    inner.cancel();
    cancel();
  };

  return outer;
};

/**
 * Merges two objects together, only if they are different. If
 * either object is null/undefined the other object is returned. If
 * both objects have the same values, the first item is returned.
 */
export const mergeIfDifferent = <T>(source?: Partial<T>, more?: Partial<T>) => {
  if (!source) {
    return more;
  }

  if (!more || source === more) {
    return source;
  }

  if (Object.keys(more).some(key => source[key as keyof T] !== more[key as keyof T])) {
    return { ...source, ...more };
  }

  return source;
};

const isMutableRef = <T>(ref: unknown): ref is MutableRefObject<T> => {
  return !!ref && typeof ref === "object" && "current" in (ref as object);
};

type Referable<T> = {
  ref: Ref<T>;
};

const isReferable = <T>(obj: unknown): obj is Referable<T> => {
  return !!obj && typeof obj === "object" && "ref" in (obj as object);
};

export const setRef = <T>(ref: Ref<T>, value: T) => {
  if (typeof ref === "function") {
    ref(value);
  } else if (isMutableRef<T>(ref)) {
    ref.current = value;
  }
};

/**
 * Utility to copy reference value to all references provided, wrapping all
 * refs in a single function ref.
 */
export const forkRef = <T>(...refs: Array<Ref<T>>) => {
  return (value: T) => {
    refs.forEach(ref => setRef(ref, value));
  };
};

/**
 * Utility which preserves the ref on a component but also assigns the ref to
 * a ref you provide. Returns a function which can be used when cloning an
 * element.
 */
export const cloneRef = <T>(child: unknown, ref: Ref<T>) => {
  return (node: T) => {
    if (isReferable<T>(child)) {
      setRef(child.ref, node);
    }

    setRef(ref, node);
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const cloneElementWithRef = (element: ReactElement | ReactNode, props?: object) => {
  const child = React.Children.only(element) as ReactElement;
  if (!(props as any)?.ref) {
    return React.cloneElement(child, props);
  }

  return React.cloneElement(child, {
    ...props,
    ref: (node: HTMLElement) => {
      setRef((child as any).ref, node);
      setRef((props as any).ref, node);
    }
  });
};
/* eslint-enable @typescript-eslint/no-explicit-any */
