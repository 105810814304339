/* eslint-disable func-style */
import React, { useCallback, ReactElement, PropsWithChildren } from "react";
import { useDataTableDispatcher } from "../DataTableStateProvider";
import { ColumnResizeHandler, ColumnResizeHandlesPlaceholder } from "./ColumnResizeHandlesPlaceholder";
import { useFixedColumnSizes } from "./DataTableFixedLayout.hooks";
import { updateColumnWidth } from "./DataColumnResizer.actions";
import { assertPrerequisitePlugins } from "./plugin-utils";
import { FIXED_LAYOUT_PLUGIN } from "./DataTableFixedLayout";
import { DataColumnResizerProps } from "./Plugins.types";
import { DataTablePluginComponentProps, DataTablePluginFactory } from "../DataTable.types";

export const COLUMN_RESIZER_PLUGIN = "ColumnResizer";

function DataColumnResizerRender<T = unknown>({
  onColumnResize,
  children,
  tableRef
}: PropsWithChildren<DataColumnResizerProps & DataTablePluginComponentProps<T>>) {
  const dispatch = useDataTableDispatcher();
  const columnSizes = useFixedColumnSizes();

  const handleColumnResize: ColumnResizeHandler = useCallback(
    e => {
      dispatch(updateColumnWidth(tableRef.current!, columnSizes, e, onColumnResize));
    },
    [columnSizes, dispatch, onColumnResize, tableRef]
  );

  if (columnSizes.length === 0) {
    return children as ReactElement;
  }

  return (
    <>
      <ColumnResizeHandlesPlaceholder onColumnResize={handleColumnResize} />
      {children}
    </>
  );
}

export function DataColumnResizer<T>(args: DataColumnResizerProps = {}) {
  const factory: DataTablePluginFactory<T, DataColumnResizerProps> = ({ plugins }) => {
    assertPrerequisitePlugins(plugins!, [FIXED_LAYOUT_PLUGIN], COLUMN_RESIZER_PLUGIN);

    return {
      args,
      component: DataColumnResizerRender
    };
  };

  factory.pluginName = COLUMN_RESIZER_PLUGIN;
  return factory;
}
