import React, { ReactElement } from "react";
import clsx from "clsx";
import { ICommonProps, IWithChildren } from "../Common.types";
import styles from "./Legend.module.scss";
import { LegendItem } from "./LegendItem";

export enum alignments {
  vertical = "vertical",
  horizontal = "horizontal"
}

export interface ILegendProps extends ICommonProps, IWithChildren {
  /** Legend title */
  title?: string;
  /** Legend alignment (horizontal/vertical) */
  alignment?: alignments;
}

export interface ILegend {
  (props: ILegendProps): ReactElement;
  Item: typeof LegendItem;
}

export const Legend: ILegend = ({ title, alignment, className, children, ...other }) => {
  const alignClass = alignment === alignments.vertical ? styles.legendItemVert : styles.legendItemHoriz;
  const titleClass = alignment === alignments.vertical ? styles.legendTitleVert : styles.legendTitleHoriz;

  return (
    <div data-testid="plex-legend" className={clsx(styles.legend, className)} {...other}>
      <div data-testid="plex-legend-title" className={titleClass}>
        {title}
      </div>
      <ul data-testid="plex-legend-content" className={alignClass}>
        {children}
      </ul>
    </div>
  );
};

Legend.Item = LegendItem;
