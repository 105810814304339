import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { CancelLink } from "@components/Link";
import { OkButton, ApplyButton } from "@components/Button";
import { ICommonProps } from "@components/Common.types";
import { withNoPrinting } from "@plex/react-xml-renderer";
import styles from "./ActionSection.module.scss";

export interface IActionSectionProps extends ICommonProps {
  includeBorder?: boolean;
  /** Action Container Class Name */
  actionClassName?: string;
}

export interface IDefaultActionSectionProps extends IActionSectionProps {
  /** Triggered on Cancel link click. */
  onCancel?: () => void;
  /** Triggered on Apply button click. */
  onApply?: () => void;
  /** Triggered on Ok button click. */
  onOk?: () => void;
}

/** Component which renders an action section. */
const HtmlActionSection: FunctionComponent<IActionSectionProps> = ({
  children,
  className,
  actionClassName,
  includeBorder,
  ...other
}) => {
  return includeBorder ? (
    <footer
      data-testid="plex-form-footer"
      className={clsx(styles.actionSection, styles.actionSectionWrapper, className)}
      {...other}
    >
      <div className={clsx(styles.actionsInner, actionClassName)}>{children}</div>
    </footer>
  ) : (
    <div
      data-testid="plex-grid-actions"
      className={clsx(styles.actionSection, styles.actionsInner, className)}
      {...other}
    >
      {children}
    </div>
  );
};

export const ActionSection = withNoPrinting(HtmlActionSection);

/** Component which renders an action section with Cancel link, Apply and Ok buttons. */
export const DefaultActionSection: FunctionComponent<IDefaultActionSectionProps> = ({
  onCancel,
  onApply,
  onOk,
  ...other
}) => {
  return (
    <ActionSection {...other}>
      {onCancel && <CancelLink onClick={onCancel} />}
      {onApply && <ApplyButton onClick={onApply} size="sm" />}
      {onOk && <OkButton onClick={onOk} size="sm" />}
    </ActionSection>
  );
};
