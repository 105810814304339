import React from "react";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { chainHandlers } from "@utils";
import { Dropdown, IDropdownProps } from "../../Dropdown";
import * as globalStyles from "../../../global-styles";
import { FormikFieldProps } from "./Formik.types";

export const FormikDropdown = function FormikDropdown<T>({
  name,
  className,
  onSelectionChanged,
  onBlur,
  ...other
}: FormikFieldProps<IDropdownProps<T>>) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.errors?.[name];
        return (
          <Dropdown
            name={field.name}
            className={clsx(className, hasError && globalStyles.hasErrors)}
            selected={field.value}
            onSelectionChanged={items => {
              form.setFieldValue(field.name, items, true);
              onSelectionChanged(items);
            }}
            onBlur={chainHandlers(field.onBlur, onBlur)}
            {...other}
          />
        );
      }}
    </Field>
  );
};
