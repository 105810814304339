/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { Year } from "js-joda";
import { DateLiteral } from "@plex/culture-react";
import { PREV_SYMBOL, NEXT_SYMBOL } from "./DatePicker.types";
import styles from "./DatePicker.module.scss";

export interface IYearViewProps {
  year: number;
  onYearChange: (year: Year) => void;
  onHeaderClick?: () => void;
}

const isInDecade = (year: Year, start: Year, end: Year) => {
  return year.value() >= start.value() && year.value() < end.value();
};

const getDecadeStart = (year: number) => Year.of(year - (year % 10));

export const YearView: FunctionComponent<IYearViewProps> = ({ year, onYearChange, onHeaderClick }) => {
  const currentYear = useMemo<Year>(() => Year.of(year), [year]);
  const [decadeStart, setDecadeStart] = useState<Year>(() => getDecadeStart(year));
  const decadeEnd = useMemo<Year>(() => (decadeStart as any).plusYears(10), [decadeStart]);

  const yearValues = useMemo<Year[]>(() => {
    let y = (decadeStart as any).minusYears(1);
    const values = [];
    for (let i = 0; i < 12; i++) {
      values.push(y);
      y = y.plusYears(1);
    }
    return values;
  }, [decadeStart]);

  const prevDecade = useCallback(() => setDecadeStart(y => (y as any).minusYears(10)), []);
  const nextDecade = useCallback(() => setDecadeStart(y => (y as any).plusYears(10)), []);

  const yearChangeHandler = useCallback(
    (y: Year) => {
      setDecadeStart(getDecadeStart(y.value()));
      onYearChange(y);
    },
    [onYearChange]
  );

  return (
    <div className={styles.gridContainer} data-testid="plex-datetimepicker-container-year-view">
      <table className={styles.grid} data-testid="plex-datetimepicker-grid">
        <thead>
          <tr>
            <th
              className={styles.headerButton}
              onClick={prevDecade}
              data-testid="plex-datetimepicker-header-button-prev"
            >
              {PREV_SYMBOL}
            </th>
            <th
              className={styles.headerButton}
              colSpan={5}
              onClick={onHeaderClick}
              data-testid="plex-datetimepicker-header-button-title"
            >
              <DateLiteral value={decadeStart} options="yyyy" />
              -
              <DateLiteral value={decadeEnd} options="yyyy" />
            </th>
            <th
              className={styles.headerButton}
              onClick={nextDecade}
              data-testid="plex-datetimepicker-header-button-next"
            >
              {NEXT_SYMBOL}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={7} style={{ padding: 0 }} data-testid="plex-datetimepicker-year">
              {yearValues.map(y => (
                <span
                  key={y.value()}
                  onClick={() => yearChangeHandler(y)}
                  className={clsx(styles.largeSelect, {
                    [styles.inactive]: !isInDecade(y, decadeStart, decadeEnd),
                    [styles.active]: (y as any).equals(currentYear)
                  })}
                >
                  <DateLiteral value={y} options="yyyy" />
                </span>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
