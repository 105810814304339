import React, {
  ReactText,
  ReactNode,
  FocusEvent,
  useReducer,
  KeyboardEvent,
  forwardRef,
  Ref,
  ReactElement,
  ChangeEvent,
  createElement,
  SyntheticEvent
} from "react";
import { Search3Icon } from "@plex/icons";
import { InlineOverlay } from "@components/Overlay";
import { CancelLink } from "@components/Link";
import { MaybeAsync, ValueAccessor } from "@components/Common.types";
import { ColumnDefType, IDataColumnDefProps, DataColumnDef } from "@components/DataTable";
import { remove, pop } from "@arrays-immutable";
import { useWhenChanged } from "@hooks";
import { isPromise } from "@utils";
import { withPrinting } from "@plex/react-xml-renderer";
import { Dialog } from "../Dialog";
import { IconButton, OkButton } from "../Button";
import { AdornedInput, ICommonInputProps } from "../Input";
import { ChipInput, Chip } from "../Chip";
import { PickerModal } from "./PickerModal";
import { ITextQuery, TextQuery } from "./TextQuery";

export interface IPickerInputProps<T> extends ICommonInputProps {
  /**
   * A function that is called against each record to use as a unique
   * identifier for the record.
   */
  keySelector: ValueAccessor<T, ReactText>;
  /**
   * A function that is called against each record to generate the
   * chip's display content.
   */
  displaySelector: ValueAccessor<T, ReactNode>;
  /**
   * The search data provided to the picker.
   */
  data?: MaybeAsync<T[]>;
  /**
   * The selected items
   */
  selected: T[];
  /**
   * Callback which is executed when a change is made to the
   * current picked selections.
   */
  onSelectionChanged: (rows: T[]) => void;
  /**
   * Callback which is executed when a search is invoked.
   */
  onSearch: (query: ITextQuery, searchedColumns: Array<IDataColumnDefProps<T>>) => void;
  /** Indicates that the picker supports multiple selections */
  multiSelect?: boolean;
  /**
   * The max number of picked items to display.
   * @default 5
   */
  maxDisplay?: number;
  /** Setting this value will force picker to open or close */
  isOpen?: boolean;
  /**
   * Determines whether to enable autopick functionality
   * @default true
   */
  autoPick?: boolean;
  /**
   * The search textbox text. If the picker is uncontrolled this only
   * sets the initial text.
   */
  searchText?: string;
  /**
   * If set, the text changes made from the search textbox are handled
   * by the consumer, turning the textbox into a controlled input. This
   * is required when `autoPick` is turned off.
   */
  onSearchTextChange?: (value: string) => void;
  /** A callback executed when pick is cancelled */
  onCancel?: () => void;
  /** The title for the picker dialog */
  dialogTitle: string;
  /** Indicates that the search data only contains a subset of total data */
  recordLimitExceeded?: boolean;

  /** Setting this value will either display the error banner with the provided error message */
  errorMessage?: string;

  children: ColumnDefType<T> | Array<ColumnDefType<T>>;

  // The initial height of the picker dialog
  initialHeight?: number;
  // The initial width of the picker dialog
  initialWidth?: number;

  // Sets the search disabled
  searchDisabled?: boolean;
}

enum Status {
  closed,
  opening,
  opened
}

type PickerState<T> = {
  data: MaybeAsync<T[]>;
  status: Status;
  selected: T[];
  uncommitted: T[];
  multiSelect: boolean;
  searchText: string;
};

type Action<T> =
  | { type: "searching"; searchText: string; open?: boolean }
  | { type: "open" }
  | { type: "select-rows"; rows: T[] }
  | { type: "cancel" }
  | { type: "commit" }
  | ({ type: "update" } & Partial<PickerState<T>>);

// eslint-disable-next-line func-style
function createReducer<T>() {
  // eslint-disable-next-line complexity
  return (state: PickerState<T>, action: Action<T>): PickerState<T> => {
    switch (action.type) {
      case "searching": {
        if (state.status === Status.closed) {
          // If the user did not search with text, we can go ahead and open the picker.
          // Otherwise we wait for the initial search to see if we can auto-pick
          const status = action.open || !action.searchText ? Status.opened : Status.opening;
          return { ...state, status };
        }

        return state;
      }

      case "select-rows": {
        if (!state.multiSelect && state.status !== Status.closed) {
          // When a selection is made for non-multi picker, the picker should
          // pick the result and close.
          return {
            ...state,
            status: Status.closed,
            selected: action.rows,
            uncommitted: action.rows,
            searchText: ""
          };
        }

        return { ...state, uncommitted: action.rows };
      }

      case "open":
        return { ...state, status: Status.opened };

      case "cancel":
        return {
          ...state,
          searchText: "",
          data: [],
          status: Status.closed,
          uncommitted: state.selected
        };

      case "commit":
        return {
          ...state,
          searchText: "",
          data: [],
          status: Status.closed,
          selected: state.uncommitted
        };

      case "update": {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type: _, ...actionState } = action;
        return { ...state, ...actionState };
      }

      default:
        return state;
    }
  };
}

// eslint-disable-next-line func-style
function createInitialState<T>({
  selected,
  multiSelect = false,
  searchText = ""
}: IPickerInputProps<T>): PickerState<T> {
  return {
    selected,
    multiSelect,
    searchText,
    data: [],
    uncommitted: selected,
    status: Status.closed
  };
}

export interface IPickerInput {
  <T extends {}>(props: IPickerInputProps<T>): ReactElement;
  Column: typeof DataColumnDef;
  displayName: string;
}

const HtmlPickerInput = forwardRef(<T extends {}>(props: IPickerInputProps<T>, ref: Ref<HTMLInputElement>) => {
  const {
    displaySelector,
    keySelector,
    selected,
    onSelectionChanged,
    data,
    disabled,
    maxDisplay = 5,
    isOpen: propsOpen,
    autoPick = true,
    onSearch,
    onBlur,
    multiSelect,
    searchText: propsSearchText = "",
    onSearchTextChange,
    onKeyDown,
    dialogTitle,
    recordLimitExceeded,
    onCancel,
    errorMessage = "",
    children,
    initialHeight,
    initialWidth,
    searchDisabled = false,
    ...other
  } = props;

  const [
    { status, uncommitted, selected: innerSelected, data: innerData, searchText: innerText },
    dispatch
  ] = useReducer(createReducer<T>(), createInitialState(props));

  const minWidth = 410;
  const minHeight = 375;
  const update = (state: Partial<PickerState<T>>) => dispatch({ type: "update", ...state });

  let searchText = innerText;
  const setSearchText = onSearchTextChange || ((text: string) => update({ searchText: text }));
  if (onSearchTextChange) {
    // The consumer is managing the input state
    searchText = propsSearchText;
  } else if (!autoPick) {
    // eslint-disable-next-line no-console
    console.warn("The picker is currently uncontrolled with autopick disabled. This is likely a bug.");
  }

  const handleUserSelections = (rows: T[]) => {
    dispatch({ type: "select-rows", rows });

    if (!multiSelect) {
      onSelectionChanged(rows);
    }
  };

  const handleUserDeselection = (row: T) => {
    const rows = remove(selected, row);
    handleUserSelections(rows);
    onSelectionChanged(rows);
  };

  const handleOpen = (forceOpen = false) => {
    if (!searchDisabled) {
      if (status === Status.closed) {
        dispatch({ type: "searching", searchText, open: forceOpen });
        onSearch(TextQuery.simple(searchText), []);
      } else if (status === Status.opening) {
        dispatch({ type: "open" });
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (status !== Status.closed) {
      return;
    }

    if ((e.key === "Backspace" || e.key === "ArrowLeft") && !searchText && selected.length > 0) {
      const [row] = pop(selected);
      const text = displaySelector(row!);

      if (typeof text === "string") {
        update({ searchText: text });
      }

      handleUserDeselection(row!);
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && searchText) {
      handleOpen();
      e.preventDefault();
      return;
    }

    onKeyDown?.(e);
  };

  const handleCommit = (rows: T[] | SyntheticEvent) => {
    dispatch({ type: "commit" });
    if (Array.isArray(rows)) {
      onSelectionChanged(rows);
    } else {
      onSelectionChanged(uncommitted);
    }
  };

  const handleCancel = () => {
    dispatch({ type: "cancel" });
    onSelectionChanged(selected);
    onCancel?.();
  };

  const handleSearchData = (rows: T[]) => {
    // If only one record came in with auto-pick
    // enabled, we can set the selection without
    // showing the modal.
    if (!autoPick || rows.length !== 1) {
      dispatch({ type: "open" });
    } else {
      handleUserSelections(rows);
      handleCommit(rows);
    }
  };

  const handleBlur = autoPick
    ? (e: FocusEvent<HTMLInputElement>) => {
        if (searchText) {
          handleOpen();
        }

        onBlur?.(e);
      }
    : onBlur;

  // Let developer force open then modal
  useWhenChanged(() => {
    if (propsOpen && status !== Status.opened) {
      handleOpen(true);
    } else if (propsOpen === false && status !== Status.closed) {
      handleCancel();
    }
  }, [propsOpen]);

  useWhenChanged(() => {
    update({ data });

    if (status === Status.opening) {
      if (isPromise<T[]>(data)) {
        data.then(handleSearchData, () => update({ data: [] }));
      } else if (Array.isArray(data)) {
        handleSearchData(data);
      }
    }
  }, [data]);

  useWhenChanged(() => {
    // if a new version of selected is provided, be sure to update state
    if (uncommitted !== selected || selected !== innerSelected) {
      update({ uncommitted: selected, selected });
    }
  }, [selected]);

  useWhenChanged(() => {
    if (onSearchTextChange && innerText !== searchText) {
      onSearchTextChange(innerText || "");
    }
  }, [innerText]);

  return (
    <>
      <InlineOverlay show={status === Status.opening}>
        <AdornedInput
          as={ChipInput}
          adornment={
            <IconButton
              disabled={disabled || searchDisabled}
              onClick={() => handleOpen(true)}
              data-testid="plex-picker-icon"
            >
              <Search3Icon />
            </IconButton>
          }
          value={searchText}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          maxDisplay={maxDisplay}
          ref={ref}
          {...other}
        >
          {selected.map(row => (
            <Chip key={keySelector(row)} closable={!disabled} onClose={() => handleUserDeselection(row)}>
              {displaySelector(row)}
            </Chip>
          ))}
        </AdornedInput>
      </InlineOverlay>
      {status === Status.opened && (
        <Dialog
          data-testid="plex-picker-modal"
          onHide={handleCancel}
          title={dialogTitle}
          backdrop="static"
          closeOnEscape
          show
          initialHeight={initialHeight}
          initialWidth={initialWidth}
          minHeight={minHeight}
          minWidth={minWidth}
        >
          <Dialog.Body>
            <PickerModal<T>
              data={innerData}
              keySelector={keySelector}
              displaySelector={displaySelector}
              onSearch={onSearch}
              multiSelect={multiSelect}
              selected={uncommitted}
              onSelectionChanged={handleUserSelections}
              searchText={searchText}
              recordLimitExceeded={recordLimitExceeded}
              errorMessage={errorMessage}
              allowColumnSearch
            >
              {children}
            </PickerModal>
          </Dialog.Body>
          <Dialog.Footer data-testid="modal-footer">
            <CancelLink onClick={handleCancel} data-testid="plex-picker-cancel-link" />
            {multiSelect && <OkButton onClick={handleCommit} data-testid="plex-picker-ok-button" />}
          </Dialog.Footer>
        </Dialog>
      )}
    </>
  );
});
HtmlPickerInput.displayName = "PickerInput";

const XmlPickerInput = <T extends {}>({ displaySelector, selected }: IPickerInputProps<T>) => {
  return createElement("plex-control-picker", {}, selected.map(displaySelector).join(","));
};

export const PickerInput = withPrinting(HtmlPickerInput, XmlPickerInput) as IPickerInput;
PickerInput.Column = DataColumnDef;
