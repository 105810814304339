import React, { FC } from "react";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeProps } from "reactflow";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "DateTimeNowNode",
  label: "Now",
  category: NodeTypeCategory.dates,
  hideLabelOnAnchor: false, // this shows the icon and label. Using css to hide the icon portion
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: "result", label: "Now", type: DataType.DATETIME, enabled: true, hideLabel: true }],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: { result: new Date(Date.now()) }, activeControlHandleIds: [] };
  }
});

export const DateTimeNowNode: FC<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
