import { ReactText, ReactNode, MouseEvent, CSSProperties, RefObject, FC } from "react";
import { ICommonProps, Comparer, MaybeAsync, ValueAccessor } from "../Common.types";
import { Action, Reducer } from "../StoreProvider";

export type HorizontalAlignment = "left" | "right" | "center";

export type RowInfo = {
  key: ReactText;
  selectionState: {
    selected: boolean;
    toggle: () => void;
  };
};

/** Render function used to render a cell in a DataTableBody */
export type RowValueRenderer<T> = (row: T, index?: number, rowInfo?: RowInfo) => ReactNode;

// Actions types
export type InitDataTable = { type: "DataTable/init" };
export type LoadAction<T> = {
  type: "DataTable/load";
  data: T[];
  keySelector: ValueAccessor<T, ReactText>;
};
export type ResetAction = { type: "DataTable/reset"; clearResizing: boolean };
export type LoadingAction = { type: "DataTable/loading" };
export type SortAction<T> = { type: "DataTable/toggleSort"; id: ReactText; comparer: Comparer<T> };
export type SortInitialStateAction<T> = {
  type: "DataTable/setInitialSort";
  id: ReactText;
  comparer: Comparer<T>;
  index: number;
  dir: SortDirection;
};
export type SelectRowAction<T> = {
  type: "DataTable/toggleSelect";
  row: GridRecord<T>;
  canSelectRow?: (row: T) => boolean;
};
export type SelectAllAction<T> = {
  type: "DataTable/toggleSelectAll";
  canSelectRow?: (row: T) => boolean;
};
export type SelectionModeChangeAction = {
  type: "DataTable/updateSelectionMode";
  mode: SelectionMode;
};
export type UpdateSelections = { type: "DataTable/updateSelections"; selectedKeys: ReactText[] };
export type UpdateVirtualizedWindow = {
  type: "DataTable/updateVirtualizedWindow";
  winRange: VirtualizedWindow;
};
export type UpdateColumnWidths = {
  type: "DataTable/updateColumnWidths";
  sizes: number[];
  resized: boolean;
  tableWidth?: number;
};
export type RankingRecord = {
  type: "DataTable/rankingRecord";
  key: string | null;
};

export type GridAction<T> =
  | InitDataTable
  | LoadAction<T>
  | ResetAction
  | LoadingAction
  | SortAction<T>
  | SortInitialStateAction<T>
  | SelectRowAction<T>
  | SelectAllAction<T>
  | SelectionModeChangeAction
  | UpdateSelections
  | UpdateVirtualizedWindow
  | UpdateColumnWidths
  | RankingRecord;

export type ActionPayload<A extends Action> = Omit<A, "type">;

export type DataTableReducer<T> = Reducer<GridState<T>, GridAction<T>>;
export type DataTablePluginComponentProps<T = unknown> = {
  tableRef: RefObject<HTMLTableElement>;
} & Pick<IDataTableProps<T>, "data" | "keySelector">;
export type DataTablePlugin<T, P = {}> = {
  reducer?: DataTableReducer<T>;
  component?: FC<P & DataTablePluginComponentProps<T>>;
  args?: P;
};
export type DataTablePluginFactory<T, P = {}> = {
  (props: IDataTableProps<T> & P): DataTablePlugin<T, P>;
  pluginName: string;
};

// Sort types
export type SortDirection = "asc" | "desc";
export type ColumnSortState = { dir: SortDirection | null; order: number };

// Selection types
export type SelectionStatus = "all" | "some" | "none";
export enum SelectionMode {
  none = "none",
  single = "single",
  multiple = "multiple"
}

export type GridRecord<T> = {
  key: ReactText;
  index: number;
  value: T;
};

export type VirtualizedWindow = {
  start: number;
  end: number;
  topHeight: number;
  bottomHeight: number;
  virtualizedTopRow?: JSX.Element;
  virtualizedBottomRow?: JSX.Element;
};

export type GridState<T> = {
  data: Array<GridRecord<T>>;
  loading: boolean;
  tableStyle?: CSSProperties;
};

// Table types
export interface IDataTableProps<T> extends ICommonProps {
  /** The data to supply to the table - can be a Promise for async usage */
  data: MaybeAsync<T[]>;
  /**
   * Function run against a row of data which returns a
   * unique identifier for the row.
   */
  keySelector: ValueAccessor<T, ReactText>;
  /** Action section.
   * @example <ActionSection>{children}</ActionSection>
   * @example <DefaultActionSection />
   */
  actionSection?: ReactNode;
  /**
   * A collection of plugins to apply to the DataTable component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plugins?: Array<DataTablePluginFactory<T, any> | false>;

  children: ReactNode;

  /** The class name to apply to the table's container */
  containerClassName?: string;
}

export type ColumnDefType<T> = { props: IDataColumnDefProps<T> };

type ColumnDefChildType<T> = ColumnDefType<T> | boolean | null;

export interface IDataTableDefProps<T> extends ICommonProps {
  /** The data to supply to the table - can be a Promise for async usage */
  data: MaybeAsync<T[]>;
  /** The rows which are selected */
  selected?: T[];
  /** Callback invoked whenever selection changes */
  onSelectionChanged?: (items: T[]) => void;
  /** Determines the selection mode for the table */
  selectionMode?: SelectionMode;
  /**
   * Callback to execute against a row of data to determine whether it can
   * be selected.
   */
  canSelectRow?: (row: T) => boolean;
  /**
   * Function run against a row of data which returns a
   * unique identifier for the row.
   */
  keySelector: ValueAccessor<T, ReactText>;
  /** Text to display when the DataTable has no data */
  emptyText?: string;
  /** Action section.
   * @example <ActionSection>{children}</ActionSection>
   * @example <DefaultActionSection />
   */
  actionSection?: ReactNode;

  /** Determines whether the table is virtualized */
  virtualized?: boolean;

  /** Determines how many rows to render if table is virtualized. Defaults to 200 */
  virtualizedRowCount?: number;

  children: ColumnDefChildType<T> | Array<ColumnDefChildType<T>>;

  /** The class name to apply to the table's container */
  containerClassName?: string;
}

// Section types
export interface IDataTableCaptionProps {
  /** The caption title to render in the table */
  captionTitle?: string;
  /** The caption text to render in the table */
  captionText?: string;
}

export interface ITableBodyProps<T> {
  /**
   * Function run against a row which returns text or a renderable
   * component.
   */
  children: RowValueRenderer<T>;
}

type GroupRenderer<T> = (rows: T[]) => ReactNode | ReactNode[];

export interface ITableGroup<T> {
  /** Function which returns a unique identifier to use to group a record */
  groupSelector: ValueAccessor<T, ReactText>;
  /**
   * Comparer used to sort groups. If none is provided the group will be
   * sorted by the value in the `groupSelector` prop.
   */
  groupComparer?: Comparer<T>;
  /**
   * A column ID which when assigned will trigger the group sort to honor
   * the associated column sort.
   */
  sortColumnId?: ReactText;
  /**
   * Function run against a row which returns text or a renderable
   * component.
   */
  children: GroupRenderer<T>;
}

// Row types
export interface ITableRowProps {
  /** The classname to apply to the DataTableRow */
  className?: string;
  /** Indicates the alt-row styling should be used */
  alt?: boolean;
  /** Indicates that the row is selectable */
  selectable?: boolean;
  /** Indicates that the row is selected */
  selected?: boolean;
  /** Css been apply to the DataTableRow */
  style?: CSSProperties;
}

// Cell types
export interface ITableSelectionCellProps extends ITableCellProps {
  /** Indicates the selection is disabled for the record */
  disabled?: boolean;
}

export interface IDataColumnDefProps<T> {
  /** The identifier for the column */
  id: string;
  /**
   * The title of the table header - if `shortTitle` is provided this will render
   * as a hover hint
   */
  title?: string;
  /** Shortened text to use for the header title */
  shortTitle?: string;
  /** Class to apply to the header cell */
  className?: string;
  /** Alignment of header cell text */
  align?: HorizontalAlignment;
  /** The column span for the header cell */
  colSpan?: number;
  /** The row span for the header cell */
  rowSpan?: number;
  /**
   * Indicates whether the column is sortable. This requires the `sortComparer`
   * property to be supplied and will make the header toggle sorting on click.
   */
  sortable?: boolean;
  /** Indicates whether the column can be in searches */
  searchable?: boolean;
  /** A compare function to use to sort the data */
  sortComparer?: Comparer<T>;
  /**
   * The raw value for the a data cell - will be used to create a `sortComparer`
   * when the column is sortable and no `sortComparer` is defined.
   */
  valueSelector?: ValueAccessor<T>;
  /** Handler for click events */
  onClick?: (e: MouseEvent<HTMLTableCellElement>) => void;
  /** Render function to render a cell of data */
  children: RowValueRenderer<T>;
}

export interface ITableHeaderCellProps<T> extends ICommonProps {
  /**
   * The title of the table header - if `shortTitle` is provided this will render
   * as a hover hint
   */
  title?: string;
  /** Shortened text to use for the header title */
  shortTitle?: string;
  /** Alignment of header cell text */
  align?: HorizontalAlignment;
  /** The column span for the header cell */
  colSpan?: number;
  /** The row span for the header cell */
  rowSpan?: number;
  /**
   * Indicates whether the column is sortable. This requires the `sortComparer`
   * property to be supplied and will make the header toggle sorting on click.
   */
  sortable?: boolean;
  /** A compare function to use to sort the data. */
  sortComparer?: Comparer<T>;
  /** Indicated whether the column is used for the initial sort direction */
  initialSortState?: ColumnSortState;
}

export interface ITableCellProps {
  /** The ID for the table cell */
  id?: string;
  /** The HTML tag to use to render the table cell */
  as?: "td" | "th";
  /** The classname to apply to the cell */
  /** Class to apply to the cell */
  className?: string;
  /** Alignment of cell text */
  align?: HorizontalAlignment;
  /** The column span for the cell */
  colSpan?: number;
  /** The row span for the cell */
  rowSpan?: number;
  /** CSS style to apply to the cell */
  style?: CSSProperties;
  /** Handler for click events */
  onClick?: (e: MouseEvent<HTMLTableCellElement>) => void;
}
