import React, { ChangeEvent, FocusEvent, FunctionComponent, useState, createElement } from "react";
import { SketchPicker } from "react-color";
import { Checkboard } from "react-color/lib/components/common";
import clsx from "clsx";
import { ArrowDown2Icon } from "@plex/icons";
import { CancelLink } from "@components/Link";
import { OkButton } from "@components/Button";
import { withPrinting } from "@plex/react-xml-renderer";
import { TinyColor } from "@ctrl/tinycolor";
import { TextInput, InputSize } from "../Input";
import styles from "./ColorPicker.module.scss";

interface IColorPickerProps {
  /**
   * Sets the button as disabled
   * @default false
   */
  disabled?: boolean;
  /** Event triggered when the cancel button is clicked */
  onCancelClick?: () => void;
  /** Event triggered when the ok button is clicked */
  onValueChange?: (colorHex: string) => void;
  /**
   * An empty string, 3 character, or 6 character hex color representation.
   * @default ""
   * @example "" or "#747" or "#c23e3e"
   */
  value?: string;
}

const threeCharHex = /^#[0-9a-fA-F]{3}$/;
const sixCharHex = /^#[0-9a-fA-F]{6}$/;

const isValidHex = (color: string) => {
  return threeCharHex.test(color) || sixCharHex.test(color);
};

const getHexColor = (color: string) => {
  if (color) {
    const tinyColor = new TinyColor(color);
    return tinyColor.isValid ? tinyColor.toHexString() : color;
  }

  return color;
};

const isEmptyOrValidHex = (color: string) => {
  const hexColor = getHexColor(color);
  return color === "" || isValidHex(hexColor);
};

const HtmlColorPicker: FunctionComponent<IColorPickerProps> = ({
  disabled = false,
  onCancelClick,
  onValueChange,
  value = ""
}) => {
  const [pickerVisible, setPickerVisibility] = useState(false);
  const validColorHex = isEmptyOrValidHex(value) ? getHexColor(value) : "";
  const [color, setColor] = useState(validColorHex);
  const [prevValidColor, setPrevValidColor] = useState(validColorHex);

  const setPrevValidColorWrapper = (colorHex: string) => {
    setPrevValidColor(colorHex);
  };
  const iconClickHandler = () => {
    if (!disabled) {
      setPickerVisibility(true);
    }
  };
  const coverClickHandler = () => {
    setPickerVisibility(false);
  };
  const onPickedColorChange = (e: { hex: string }) => {
    setColor(e.hex);
    setPrevValidColorWrapper(e.hex);
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColor(e.currentTarget.value);
  };
  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (isEmptyOrValidHex(e.currentTarget.value)) {
      setPrevValidColorWrapper(e.currentTarget.value);
    } else {
      setColor(prevValidColor);
    }
  };

  const cancelClickHandler = () => {
    if (onCancelClick) {
      onCancelClick();
    }

    setColor(validColorHex);
    setPrevValidColorWrapper(validColorHex);
    coverClickHandler();
  };

  const okClickHandler = () => {
    if (onValueChange) {
      onValueChange(color);
    }
    coverClickHandler();
  };

  const colorPickerButtonColor = isEmptyOrValidHex(color) ? getHexColor(color) : "";
  const iconColorStyle = { backgroundColor: colorPickerButtonColor };

  const iconClass = clsx(styles.icon, !disabled && styles.iconEnabled, disabled && styles.iconDisabled);

  const sketchPickerProps = {
    className: styles.sketchPicker
  };

  return (
    <div>
      <TextInput
        value={color}
        disabled={disabled}
        onChange={onInputChange}
        onBlur={onInputBlur}
        size={InputSize.small}
      />
      <div className={styles.pickerWrapper}>
        <div className={iconClass} onClick={iconClickHandler} data-testid="sp-replacer">
          <div className={styles.iconColor} style={iconColorStyle}>
            {colorPickerButtonColor === "" ? <Checkboard size={6} /> : null}
          </div>
          <div>
            <ArrowDown2Icon className={styles.iconDownArrow} />
          </div>
        </div>
        {pickerVisible ? (
          <div className={styles.popover} data-testid="color-picker-popover">
            <div className={styles.cover} onClick={cancelClickHandler} data-testid="color-picker-cover" />
            <div className={styles.container}>
              <SketchPicker
                {...sketchPickerProps}
                data-testid="sp-picker-container"
                color={color}
                onChange={onPickedColorChange}
                disableAlpha
              />
              <div className={styles.actions} data-testid="color-picker-actions">
                <CancelLink onClick={cancelClickHandler} className={styles.cancelLink} data-testid="sp-cancel" />
                <OkButton onClick={okClickHandler} data-testid="sp-choose" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const XmlColorPicker = ({ value }: IColorPickerProps) => {
  return createElement("plex-control-colorpicker", {}, String(value || ""));
};

export const ColorPicker = withPrinting(HtmlColorPicker, XmlColorPicker);
