/* eslint-disable complexity */
import React, { isValidElement, ReactText, useReducer, ReactNode, useEffect } from "react";
import { useLocalization } from "@plex/culture-react";
import { Banner, BannerStatus } from "@components/Banner";
import { MaybeAsync, ValueAccessor } from "@components/Common.types";
import { push, remove, hasSameValues } from "@arrays-immutable";
import { isPromise, noop } from "@utils";
import { SelectionMode, ColumnDefType, IDataColumnDefProps } from "../DataTable";
import { Checkbox } from "../Checkbox";
import { ITextQuery, isAggregateSearch } from "./TextQuery";
import { PickerResultSection } from "./PickerResultSection";
import { PickerSearchInput } from "./PickerSearchInput";
import { PickerSearchAdvancedInput } from "./PickerSearchAdvancedInput";
import { MultiSelectContainer } from "./MultiSelectContainer";
import styles from "./PickerModal.module.scss";

interface IPickerModalProps<T> {
  data: MaybeAsync<T[]>;
  searchText?: string;
  selected?: T[];
  onSelectionChanged?: (rows: T[]) => void;
  onSearch: (query: ITextQuery, searchedColumns: Array<IDataColumnDefProps<T>>) => void;
  allowColumnSearch?: boolean;
  children: ColumnDefType<T> | Array<ColumnDefType<T>>;
  keySelector: ValueAccessor<T, ReactText>;
  displaySelector: ValueAccessor<T, ReactNode>;
  multiSelect?: boolean;
  recordLimitExceeded?: boolean;
  errorMessage?: string;
}

// eslint-disable-next-line func-style
function filterWithin<T>(data: T[], query: ITextQuery, columns: Array<IDataColumnDefProps<T>>) {
  return data.filter((row, index) => {
    const values = columns.map(col => {
      const value = col.children(row, index);
      return value == null ? "" : String(value);
    });

    return query.isMatch(values);
  });
}

type Action<T> =
  | { type: "loading" }
  | { type: "load"; source: T[]; limitExceeded: boolean }
  | { type: "searching"; columns: Array<IDataColumnDefProps<T>>; query: ITextQuery; errorText: string }
  | { type: "select-column"; column: IDataColumnDefProps<T> }
  | { type: "deselect-column"; column: IDataColumnDefProps<T> }
  | { type: "toggle-advanced-mode" }
  | { type: "toggle-search-within" }
  | { type: "error-shown" };

const isSameSearch = (
  nextSearch: ITextQuery,
  priorSearch: ITextQuery,
  nextColumns: ReactText[],
  priorColumns: ReactText[]
) => {
  if (!hasSameValues(priorColumns, nextColumns)) {
    return false;
  }

  if ((isAggregateSearch(priorSearch) && nextSearch.isEmpty()) || priorSearch.equals(nextSearch)) {
    return true;
  }

  if (isAggregateSearch(priorSearch) && priorSearch.right.equals(nextSearch)) {
    return true;
  }

  return false;
};

// eslint-disable-next-line func-style
function createReducer<T>() {
  return (state: PickerState<T>, action: Action<T>): PickerState<T> => {
    switch (action.type) {
      case "loading":
        return { ...state, loading: true };

      case "load": {
        const { source, limitExceeded } = action;
        const searched = state.searched || source.length > 0;

        let statusMessageId: StatusMessage = null;
        if (searched) {
          statusMessageId = limitExceeded
            ? "ui-common-warn-recordLimitExceeded"
            : "ui-common-dataPicker-info-allItemsShown";
        }

        return {
          ...state,
          loading: false,
          filteredSource: source,
          source,
          searched,
          statusMessageId
        };
      }

      case "searching": {
        let { query, columns, errorText } = action;
        let { source, filteredSource, searchedColumns: priorColumns, query: priorQuery } = state;

        const searchedColumns = columns.map(x => x.id);

        // verify if the search is actually different than the last search
        if (
          (state.searchWithin || state.advancedMode) &&
          priorQuery &&
          isSameSearch(query, priorQuery, searchedColumns, priorColumns)
        ) {
          // ignore search
          return state;
        }

        if (state.searchWithin) {
          query = priorQuery ? priorQuery.and(query) : query;
          filteredSource = filterWithin(filteredSource, query, columns);
        } else if (state.advancedMode) {
          filteredSource = filterWithin(source, query, columns);
        }

        return {
          ...state,
          filteredSource,
          query,
          searchedColumns,
          searched: true,
          errorText
        };
      }

      case "select-column": {
        const { selectedColumns } = state;
        return { ...state, selectedColumns: push(selectedColumns, action.column.id) };
      }

      case "deselect-column": {
        const { selectedColumns } = state;
        return { ...state, selectedColumns: remove(selectedColumns, action.column.id) };
      }

      case "toggle-advanced-mode":
        return { ...state, advancedMode: !state.advancedMode };

      case "toggle-search-within":
        return { ...state, searchWithin: !state.searchWithin };

      case "error-shown":
        return { ...state, errorShown: true };
      default:
        return state;
    }
  };
}

type StatusMessage = "ui-common-warn-recordLimitExceeded" | "ui-common-dataPicker-info-allItemsShown" | null;

type PickerState<T> = {
  source: T[];
  filteredSource: T[];
  selected: T[];
  loading: boolean;
  searched: boolean;
  selectedColumns: ReactText[];
  searchedColumns: ReactText[];
  query: ITextQuery | null;
  advancedMode: boolean;
  searchWithin: boolean;
  statusMessageId: StatusMessage;
  errorShown: boolean;
  errorText: string;
};

// eslint-disable-next-line func-style
function createInitialState<T>(
  { data, recordLimitExceeded, selected = [] }: IPickerModalProps<T>,
  selectedColumns: string[]
): PickerState<T> {
  const source = Array.isArray(data) ? data : [];

  let statusMessageId: StatusMessage = null;
  if (source.length > 0) {
    statusMessageId = recordLimitExceeded
      ? "ui-common-warn-recordLimitExceeded"
      : "ui-common-dataPicker-info-allItemsShown";
  }

  return {
    selected,
    source,
    statusMessageId,
    selectedColumns,
    filteredSource: source,
    loading: isPromise(data),
    searched: false,
    searchedColumns: [],
    query: null,
    advancedMode: false,
    searchWithin: false,
    errorShown: false,
    errorText: ""
  };
}

// eslint-disable-next-line func-style
export function PickerModal<T>(props: IPickerModalProps<T>) {
  const {
    data,
    onSearch = noop,
    searchText,
    allowColumnSearch = true,
    keySelector,
    displaySelector,
    multiSelect,
    selected = [],
    onSelectionChanged = noop,
    recordLimitExceeded,
    children,
    errorMessage = ""
  } = props;

  const { t } = useLocalization();
  const columns = React.Children.map(children, c => isValidElement(c) && c.props).filter(Boolean) as Array<
    IDataColumnDefProps<T>
  >;
  const searchableColumns = columns.filter(x => x.searchable);

  const [
    {
      searched,
      loading,
      source,
      filteredSource,
      selectedColumns,
      query: search,
      searchedColumns,
      advancedMode,
      searchWithin,
      statusMessageId,
      errorShown,
      errorText
    },
    dispatch
  ] = useReducer(
    createReducer<T>(),
    createInitialState<T>(
      props,
      searchableColumns.map(c => c.id)
    )
  );

  useEffect(() => {
    let mounted = true;

    const dispatchIfMounted = (payload: Action<T>) => {
      if (mounted) {
        dispatch(payload);
      }
    };

    if (isPromise<T[]>(data)) {
      dispatch({ type: "loading" });
      data.then(
        results => dispatchIfMounted({ type: "load", source: results, limitExceeded: !!recordLimitExceeded }),
        () => dispatchIfMounted({ type: "load", source: [], limitExceeded: false })
      );
    } else if (Array.isArray(data)) {
      dispatch({ type: "load", source: data, limitExceeded: !!recordLimitExceeded });
    }

    return () => {
      mounted = false;
    };
  }, [data, dispatch, recordLimitExceeded]);

  const handleSearch = (query: ITextQuery) => {
    const cols = selectedColumns.map(id => columns.find(x => x.id === id)).filter(Boolean) as Array<
      IDataColumnDefProps<T>
    >;

    const error = cols.length === 0 ? t("ui-common-dataPicker-info-selectSearchField") : "";
    dispatch({ type: "searching", query, columns: cols, errorText: error });

    if (!searchWithin) {
      onSearch(query, cols);
    }
  };

  const toggleAdvancedMode = () => dispatch({ type: "toggle-advanced-mode" });
  const toggleColumnHandler = (column: IDataColumnDefProps<T>, isSelected: boolean) => {
    if (isSelected) {
      dispatch({ type: "select-column", column });
    } else {
      dispatch({ type: "deselect-column", column });
    }
  };

  const handleGridSelection = (rows: T[]) => {
    let selectedRows = rows;

    if (multiSelect) {
      // we may want to memoize these...
      const dataKeys = filteredSource.map(keySelector).map(String);
      const selectedMap = new Map<string, T>(selected.map(x => [String(keySelector(x)), x]));

      // keep any selections that aren't currently displayed
      const keeperKeys = Array.from(selectedMap.keys()).filter(key => !dataKeys.includes(key));
      selectedRows = [...rows, ...keeperKeys.map(key => selectedMap.get(key))] as T[];
    }

    onSelectionChanged(selectedRows);
  };

  let emptyText = "";
  if (!searched) {
    emptyText = t("ui-common-dataPicker-info-startSearch");
  } else if (!loading && filteredSource.length === 0) {
    emptyText = t("ui-common-dataPicker-info-refineSearch");
  }

  let statusText: ReactNode = "";
  if (search?.displayText) {
    statusText = t("ui-common-dataPicker-info-foundText", {
      count: filteredSource.length,
      input: search.displayText
    });
  } else if (statusMessageId) {
    statusText = t(statusMessageId, { count: source.length });
  }

  const showColumns = allowColumnSearch && columns.length > 1;
  return (
    <div className={styles.modalContainer}>
      <div className={styles.searchContainer}>
        {errorMessage && !errorShown && (
          <Banner status={BannerStatus.error} close={() => dispatch({ type: "error-shown" })}>
            {errorMessage}
          </Banner>
        )}
        {multiSelect && (
          <MultiSelectContainer
            selected={selected}
            keySelector={keySelector}
            displaySelector={displaySelector}
            onSelectionChanged={onSelectionChanged}
          />
        )}
        <div className={styles.resultText} data-testid="plex-picker-results">
          {statusText}
        </div>
        {advancedMode ? (
          <PickerSearchAdvancedInput onSearch={handleSearch} onModeToggle={toggleAdvancedMode} />
        ) : (
          <PickerSearchInput searchText={searchText || ""} onSearch={handleSearch} onModeToggle={toggleAdvancedMode} />
        )}
        <p className={styles.errorText}>{errorText}</p>
        <div className={styles.columnList}>
          {showColumns && (
            <div className={styles.searchColumns}>
              <span className={styles.searchInText}>{t("ui-common-dataPicker-heading-searchIn")}</span>
              {searchableColumns.map(c => {
                return (
                  <label key={c.id}>
                    <input
                      type="checkbox"
                      checked={selectedColumns.includes(c.id)}
                      onChange={e => toggleColumnHandler(c, e.currentTarget.checked)}
                    />
                    <span className={styles.labelText}>{c.title}</span>
                  </label>
                );
              })}
            </div>
          )}
          <label className={styles.searchWithin} data-testid="plex-picker-search-within">
            <Checkbox
              checked={searchWithin}
              className={styles.searchWithinSelection}
              onChange={() => dispatch({ type: "toggle-search-within" })}
            />
            <span className={styles.labelText}>{t("ui-common-dataPicker-option-searchWithin")}</span>
          </label>
        </div>
      </div>
      <PickerResultSection<T>
        emptyText={emptyText}
        data={filteredSource}
        keySelector={keySelector}
        query={search}
        selectedColumns={searchedColumns}
        selectionMode={multiSelect ? SelectionMode.multiple : SelectionMode.single}
        selected={selected}
        onSelectionChanged={handleGridSelection}
      >
        {children}
      </PickerResultSection>
    </div>
  );
}
