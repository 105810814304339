import React, { FC, useState, useEffect } from "react";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";
import { TextArea } from "@plex/react-components";
import { forceNodeUpdate } from "../../Util/NodeUtil";

export interface ICommentBoxParams {
  id: string;
  nodeProperties: { comment: string };
}

export const CommentBox: FC<ICommentBoxParams> = ({ id, nodeProperties }) => {
  const reactFlowInstance = useReactFlow();
  const node = reactFlowInstance.getNode(id);
  const [currentNode, setCurrentNode] = useState(node);
  const [commentText, setCommentText] = useState(nodeProperties.comment);
  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    setCommentText(nodeProperties.comment);
  });

  return (
    <div className="commentContainer">
      <TextArea
        autoGrow
        autoGrowMaxHeight={400}
        placeholder="Add Comment"
        style={{ minWidth: "100%" }}
        value={commentText}
        onChange={(e: any) => {
          if (currentNode) {
            nodeProperties.comment = e;
            setCurrentNode({ ...currentNode });
            setCommentText(e);
            forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
          }
        }}
      />
    </div>
  );
};
