import React, { FunctionComponent, ChangeEvent } from "react";
import { LocalTime, ChronoUnit, ChronoField } from "js-joda";
import { useLocalization } from "@plex/culture-react";
import dropdownStyles from "../Dropdown/Dropdown.module.scss";
import styles from "./DatePicker.module.scss";
import { ITimePickerProps } from "./DatePicker.types";

const range = (n: number, offset = 0) => [...Array(n)].map((_, k) => k + offset);
const hours12 = range(12, 1);
const hours24 = range(24);
const minutesOrSeconds = range(60);
const milliseconds = range(1000);
const AM = -12;
const PM = 12;

// eslint-disable-next-line complexity
export const TimePicker: FunctionComponent<ITimePickerProps> = ({
  value,
  precision = "minutes",
  use24HourTime,
  onValueChange,
  disabled,
  minuteStep = 1
}) => {
  const { locale } = useLocalization();
  const timeValue = value || LocalTime.now().truncatedTo(ChronoUnit.MINUTES);

  const minuteSteps = minuteStep === 1 ? minutesOrSeconds : minutesOrSeconds.filter(x => x % minuteStep === 0);

  let showAmPm = !use24HourTime;
  if (use24HourTime == null) {
    showAmPm = !locale.dates.uses24HourCycle();
  }

  const hourValue = use24HourTime ? timeValue.hour() : timeValue.get(ChronoField.HOUR_OF_AMPM);
  const amPmValues = use24HourTime ? [] : locale.dates.getDayPeriods(["am", "pm"]);
  showAmPm = showAmPm && amPmValues.length === 2;

  const handleSelectWith = (setter: (value: number) => LocalTime) => (e: ChangeEvent<HTMLSelectElement>) => {
    const parsedValue = parseInt(e.currentTarget.value, 10);
    onValueChange?.(setter(parsedValue));
  };

  const handleHours = handleSelectWith(v => {
    let realValue = v;

    if (showAmPm) {
      // normalize value for am/pm values
      const isPm = timeValue.hour() >= PM;
      if (v === 12) {
        realValue = isPm ? 12 : 0;
      } else if (isPm) {
        realValue += PM;
      }
    }

    return timeValue.withHour(realValue);
  });
  const handleMinutes = handleSelectWith(v => timeValue.withMinute(v));
  const handleSeconds = handleSelectWith(v => timeValue.withSecond(v));
  const handleMilliseconds = handleSelectWith(v => timeValue.withNano(v * 1_000_000));
  const handleAmPm = handleSelectWith(v => timeValue.plusHours(v));
  const timeSeparator = locale.numbers.getSymbol(":");

  return (
    <div className={styles.timeContainer}>
      <select
        disabled={disabled}
        value={hourValue}
        onChange={handleHours}
        className={dropdownStyles.base}
        data-testid="select-hours"
      >
        {(use24HourTime ? hours24 : hours12).map(h => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </select>
      <label>{timeSeparator}</label>
      <select
        value={timeValue.minute()}
        onChange={handleMinutes}
        className={dropdownStyles.base}
        data-testid="select-minutes"
        disabled={disabled}
      >
        {minuteSteps.map(m => (
          <option key={m} value={m}>
            {String(m).padStart(2, "0")}
          </option>
        ))}
      </select>
      {precision !== "minutes" && (
        <>
          <label>{timeSeparator}</label>
          <select
            value={timeValue.second()}
            onChange={handleSeconds}
            className={dropdownStyles.base}
            data-testid="select-seconds"
            disabled={disabled}
          >
            {minutesOrSeconds.map(s => (
              <option key={s} value={s}>
                {String(s).padStart(2, "0")}
              </option>
            ))}
          </select>
        </>
      )}
      {precision === "milliseconds" && (
        <>
          <label>{locale.numbers.getSymbol(".")}</label>
          <select
            value={timeValue.get(ChronoField.MILLI_OF_SECOND)}
            onChange={handleMilliseconds}
            className={dropdownStyles.base}
            data-testid="select-milliseconds"
            disabled={disabled}
          >
            {milliseconds.map(ms => (
              <option key={ms} value={ms}>
                {ms}
              </option>
            ))}
          </select>
        </>
      )}
      {showAmPm && (
        <select
          value={timeValue.hour() >= PM ? PM : AM}
          onChange={handleAmPm}
          className={dropdownStyles.base}
          data-testid="select-ampm"
          disabled={disabled}
        >
          <option value={AM}>{amPmValues[0]}</option>
          <option value={PM}>{amPmValues[1]}</option>
        </select>
      )}
    </div>
  );
};
