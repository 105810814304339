import React, { FunctionComponent } from "react";
import { useReactFlow } from "reactflow";
import { Button, Checkbox, Form } from "@plex/react-components";
import { IFlowInputProperties } from "../FlowInputManagement/FlowInputManagementController";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { getSchemaId } from "../NodeTypes/DataSchemas";

export interface IFlowInputFormSectionProps extends INodeConfigPropertiesProps {
  viewController: any;
}

export const FlowInputFormSection: FunctionComponent<IFlowInputFormSectionProps> = (props) => {
  const reactFlowInstance = useReactFlow();

  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  const outputs: IFlowInputProperties[] = props.viewController.flowInputController?.flowInputPool || [];

  return (
    <Form.Section title={props.sectionName} key={props.sectionName} style={{ textAlign: "center" }}>
      <Button style={{ margin: "10px" }} onClick={() => props.viewController.setFlowInputModalState({ show: true })}>
        Manage Inputs
      </Button>
      <div className="input-select-section extraction-section" style={{ flexGrow: 1 }}>
        {outputs.map((output: IFlowInputProperties, index: number) => {
          if (!props.node!.data.nodeProperties[props.name][output.propertyName]) {
            props.node!.data.nodeProperties[props.name][output.propertyName] = {
              ...output,
              enabled: false,
              type: output.propertyType,
              schemaId: getSchemaId(output.propertyType)
            };
          }

          return (
            <Form.FieldPair labelText={output.propertyName} key={output.propertyName}>
              <div style={{ display: "flex", marginTop: "8px" }}>
                <Checkbox
                  checked={props.node?.data.nodeProperties[props.name][output.propertyName].enabled === true}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    props.node!.data.nodeProperties[props.name][output.propertyName].enabled = e.currentTarget.checked;
                    if (e.currentTarget.checked === false) {
                      let deletedEdges = reactFlowInstance
                        .getEdges()
                        .filter(
                          (e) =>
                            e.target === props.node!.id &&
                            e.targetHandle === output.propertyName + "-" + output.propertyType + "-output"
                        );
                      reactFlowInstance.setEdges((edges) =>
                        edges.filter((e) => deletedEdges.filter((de) => de.id === e.id).length === 0)
                      );
                      props.viewController.validateEdges(deletedEdges[0]);
                    }
                    setState({ value: props.node!.data.nodeProperties[props.name][output.propertyName].enabled });
                    props.forceNodeUpdate();
                  }}
                />
                <div style={{ verticalAlign: "center", marginLeft: "20px", color: "gray", fontSize: "smaller" }}>
                  {output.propertyType}
                </div>
              </div>
            </Form.FieldPair>
          );
        })}
      </div>
    </Form.Section>
  );
};
