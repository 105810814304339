import React, { FC, ReactElement, ReactText, useMemo } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import { useAsync } from "@hooks";
import { ItemMovedHandler } from "./Tree.types";
import { MaybeAsync, ICommonProps, ValueAccessor } from "../Common.types";
import { TreeLeaf } from "./TreeLeaf";
import { TreeBranch } from "./TreeBranch";
import { TreeList } from "./TreeList";
import styles from "./Tree.module.scss";

interface ITreeProps<T> extends ICommonProps {
  /** Data associates with each of the root nodes */
  data: MaybeAsync<T[]>;
  /** Determines whether the tree's nodes are selectable */
  selectable?: boolean;
  /** An array of selected nodes */
  selected?: T[];
  /** Function which returns a unique value for a given node */
  keySelector: ValueAccessor<T, ReactText>;
  /**
   * Function which is called when a user selects or deselects
   * a node
   */
  onSelectionChanged?: (items: T[]) => void;
  /** Render function to determine how each node is rendered */
  children: (item: T, depth: number) => ReactElement;
  /** Item Move Handler */
  onItemMoved?: ItemMovedHandler<T>;
}

interface ITree {
  <T>(props: ITreeProps<T>): ReactElement;
  Leaf: typeof TreeLeaf;
  Branch: typeof TreeBranch;
}

// eslint-disable-next-line func-style
function HtmlTree<T>({
  data,
  keySelector,
  selectable,
  selected,
  onSelectionChanged,
  children: render,
  onItemMoved,
  ...other
}: ITreeProps<T>) {
  const [items] = useAsync(data, []);
  const selectedKeys = useMemo(() => selected?.map(keySelector) || [], [selected, keySelector]);

  if (typeof render !== "function") {
    throw new Error(`Property "children" must be a render function but got "${typeof render}" instead.`);
  }

  const handleSelection = (item: T) => {
    if (!onSelectionChanged) {
      return;
    }

    // If we need to handle multiple selections, logic here
    // would change.
    if (!selectedKeys.includes(keySelector(item))) {
      onSelectionChanged([item]);
    }
  };

  return (
    <div className={styles.base} {...other}>
      <TreeList<T>
        data={items}
        depth={0}
        render={render}
        keySelector={keySelector}
        selectable={!!selectable}
        selectedKeys={selectedKeys}
        toggleSelection={handleSelection}
        onItemMoved={onItemMoved}
      />
    </div>
  );
}

const XmlTree: FC<ITreeProps<unknown>> = ({ data, keySelector, selectable, selected, children: render }) => {
  const [items] = useAsync(data, []);
  const selectedKeys = selected?.map(keySelector) || [];

  return (
    <TreeList
      data={items}
      depth={0}
      render={render}
      keySelector={keySelector}
      selectable={!!selectable}
      selectedKeys={selectedKeys}
    />
  );
};

const Tree = withPrinting(HtmlTree, XmlTree) as ITree;
Tree.Leaf = TreeLeaf;
Tree.Branch = TreeBranch;
export { Tree };
