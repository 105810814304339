import { splice } from "@arrays-immutable";
import { UpdateColumnWidths, GridState, ResetAction } from "../DataTable.types";
import { ColumnResizeArgs } from "./ColumnResizeHandlesPlaceholder";
import { ColumnResizedHandler, ColumnWidths } from "./Plugins.types";

const MIN_COLUMN_SIZE = 24;

export type GridStateWithSizes<T> = GridState<T> & {
  columnSizes?: number[];
  resized?: boolean;
  tableWidth?: number;
};

const findCompleteRowInSection = (section: HTMLTableSectionElement | null) => {
  if (!section) {
    return null;
  }

  let i = 0;
  while (i < section.rows.length) {
    const row = section.rows[i];
    let hasAllCells = true;

    let span = 1;
    for (let j = 0; j < row.cells.length; j++) {
      const cell = row.cells[j];
      span = Math.max(span, cell.rowSpan);
      hasAllCells = hasAllCells && cell.colSpan === 1;
    }

    if (hasAllCells) {
      return row;
    }

    i += span;
  }

  return null;
};

const findCompleteRowInSections = (sections: HTMLTableSectionElement[]) => {
  for (let i = 0; i < sections.length; i++) {
    const row = findCompleteRowInSection(sections[i]);
    if (row) {
      return row;
    }
  }
  return null;
};

export const getSampleRow = (table: HTMLTableElement): HTMLTableRowElement | null => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return findCompleteRowInSection(table.tHead) || findCompleteRowInSections(Array.from(table.tBodies || []));
};

export const getMinWidth = (element: HTMLElement) => {
  const style = window.getComputedStyle(element);
  const cssWidth = parseInt(style.getPropertyValue("min-width"), 10);
  const firstInput = element.querySelector("input, select, textarea, button");
  const elementWidth = firstInput?.getBoundingClientRect().width;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return Math.max(cssWidth, elementWidth || MIN_COLUMN_SIZE);
};

export const updateColumnWidths = (props: Omit<UpdateColumnWidths, "type">): UpdateColumnWidths => ({
  type: "DataTable/updateColumnWidths",
  ...props
});

export const reset = (clearResizing = false): ResetAction => ({
  type: "DataTable/reset",
  clearResizing
});

export const updateColumnWidth = (
  table: HTMLTableElement,
  columnSizes: ColumnWidths,
  { index, diffX }: ColumnResizeArgs,
  handler?: ColumnResizedHandler
): UpdateColumnWidths => {
  const currentWidth = columnSizes[index];

  const refRow = getSampleRow(table);
  const width = Math.max(getMinWidth(refRow!.cells[index]), currentWidth + diffX);
  const actualDiff = width - currentWidth;
  const tableWidth = table.offsetWidth + actualDiff;
  const sizes = splice(columnSizes, index, 1, width);

  handler?.(sizes);

  return updateColumnWidths({ sizes, resized: true, tableWidth });
};
