import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Play4Icon } from "@plex/icons";
import { IWithChildren, ICommonProps } from "@components/Common.types";
import styles from "./MainMenu.module.scss";
import { MainMenuItemIcon } from "./MainMenu.ItemIcon";

export interface IMainMenuGroupProps extends IWithChildren, ICommonProps {
  /**
   * Element id
   */
  id: string;

  /**
   * Is the group hidden
   */
  hidden?: boolean;

  /**
   * Menu item text
   */
  text: string;

  /**
   * Click handler for button
   */
  onClick?: (e: React.MouseEvent, props: unknown) => void;

  /**
   * Props, passed to component from outside, and returned through onClick
   */
  onClickProps?: unknown;

  /**
   * Item icon
   */
  icon?: React.ReactNode;

  expanded?: boolean;
}

export const MainMenuGroup: FunctionComponent<IMainMenuGroupProps> = ({
  hidden,
  text,
  onClick,
  onClickProps,
  expanded,
  icon,
  className,
  ...other
}) => {
  const renderExpanded = () => {
    return (
      <div className={clsx(styles.menuItemContainer, className)} {...other}>
        <div className={styles.menuGroupSelected}>
          <div className={styles.menuItemButton}>
            {icon && <MainMenuItemIcon>{icon}</MainMenuItemIcon>}
            <span className={styles.menuItemText}>{text}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderCollapsed = () => {
    const clickHandler = (e: React.MouseEvent) => {
      e.preventDefault();
      onClick?.(e, onClickProps);
    };

    return (
      <div className={clsx(styles.menuGroup, className)} onClick={clickHandler} {...other}>
        <div className={styles.menuItemButton}>
          {icon && <MainMenuItemIcon>{icon}</MainMenuItemIcon>}
          <span className={styles.menuItemText}>{text}</span>
          <i className={styles.menuGroupArrow}>
            <Play4Icon />
          </i>
        </div>
      </div>
    );
  };

  if (hidden) {
    return null;
  }

  return expanded ? renderExpanded() : renderCollapsed();
};

MainMenuGroup.displayName = "MainMenuGroup";
