import React from "react";
import { SelectionList, ISelectionListProps } from "./SelectionList";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICheckListProps<T> extends Omit<ISelectionListProps<T>, "multiSelect"> {}

// eslint-disable-next-line func-style
export function CheckList<T>({ ...props }: ICheckListProps<T>) {
  return <SelectionList multiSelect {...props} />;
}
