import React, { FunctionComponent } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import { Text } from "../Text";
import styles from "./DataGrid.module.scss";

export interface IDataGridItemProps {
  /**
   * Indicates how many columns will be occupied by this element.
   * @default 1
   */
  cellSpan?: number;
  /** The label text. */
  labelText?: string;
}

const HtmlDataGridItem: FunctionComponent<IDataGridItemProps> = ({ labelText, children, cellSpan = 1 }) => {
  const hasData = labelText || children;

  return (
    <div className={styles.tableCell} style={{ flexGrow: cellSpan }}>
      {hasData && (
        <>
          <div className={styles.tableHeader}>{labelText && <Text className={styles.label} text={labelText} />}</div>
          <div className={styles.tableData}>{children}</div>
        </>
      )}
    </div>
  );
};

const XmlDataGridItem: FunctionComponent<IDataGridItemProps> = ({ children, labelText }) => {
  const label = (
    <>
      {labelText && <Text text={labelText} />} {}
    </>
  );
  const labelComponent = React.createElement("grid-table-cell", { key: 0, "text-align": "right" }, label);

  const child = React.createElement("grid-table-cell", { key: 1 }, children);
  return (
    <>
      {[labelComponent, child]}
      {}
    </>
  );
};

export const DataGridItem = withPrinting(HtmlDataGridItem, XmlDataGridItem);
