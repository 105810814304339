import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import styles from "./Filter.module.scss";

export interface IFilterItemFieldProps<T = unknown> {
  /** The class name to apply to the element */
  className?: string;
  /**
   * The original child value, is used for the filter's collapse/expand purpose
   * @requires value
   */
  originalValue?: T;
  /**
   * The child value, is used for the filter's collapse/expand purpose
   * @description will not collapse if the value is defined, or if it is different from the originalValue
   * @requires originalValue
   */
  value?: T;
  /** This function is used for the filter's collapse/expand purpose, instead of originalValue/value props */
  shouldCollapse?: () => boolean;
}

const HtmlFilterItemField: FunctionComponent<IFilterItemFieldProps<unknown>> = ({ children, className }) => {
  const classes = clsx(className, styles.filterItemControl);
  return <div className={classes}>{children}</div>;
};

const XmlFilterItemField: FunctionComponent<IFilterItemFieldProps> = ({ children }) => {
  return React.createElement("plex-controls", {}, children);
};

export const FilterItemField = withPrinting(HtmlFilterItemField, XmlFilterItemField);
