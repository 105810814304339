import { useState, useCallback } from "react";

type DispatchToggle = (override?: boolean | unknown) => void;
type Dispatch = () => void;

/**
 * Hook which supplies easy to use state mutators for use with boolean values.
 * Returns a tuple with the current value, a toggle function, a function to set
 * the value to false, and a function to set the value to true.
 */
export const useToggle = (initialValue: boolean): [boolean, DispatchToggle, Dispatch, Dispatch] => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(
    // eslint-disable-next-line no-confusing-arrow
    override => setValue(v => (typeof override === "boolean" ? override : !v)),
    []
  );

  const setFalse = useCallback(() => setValue(false), []);
  const setTrue = useCallback(() => setValue(true), []);
  return [value, toggle, setFalse, setTrue];
};
