import React, { ReactText, ReactNode, cloneElement } from "react";
import { IWithChildren, ValueAccessor } from "@components/Common.types";
import { DataTableDef, SelectionMode, ColumnDefType, IDataColumnDefProps } from "../DataTable";
import { ITextQuery } from "./TextQuery";
import { MatchingText } from "./MatchingText";
import styles from "./PickerModal.module.scss";

interface IPickerResultSectionProps<T> extends IWithChildren {
  data: T[];
  emptyText: string;
  keySelector: ValueAccessor<T, ReactText>;
  selectionMode: SelectionMode.multiple | SelectionMode.single;
  selected: T[];
  onSelectionChanged: (rows: T[]) => void;
  query?: ITextQuery | null;
  selectedColumns: ReactText[];
}

// eslint-disable-next-line func-style
function maybeWrapText<T>(
  child: ReactNode,
  query: ITextQuery | null | undefined,
  selectedColumns: ReactText[]
): ReactNode {
  if (!query || !React.isValidElement(child)) {
    return child;
  }

  const columnDef = child.props as IDataColumnDefProps<T>;
  if (!selectedColumns.includes(columnDef.id)) {
    return child;
  }

  if (typeof columnDef.children === "function") {
    return cloneElement(child, {}, function WrapMatchingText(row: T, index: number) {
      return <MatchingText query={query}>{columnDef.children(row, index)}</MatchingText>;
    });
  }

  return child;
}

// eslint-disable-next-line func-style
export function PickerResultSection<T>({
  data,
  emptyText,
  keySelector,
  selectionMode,
  selected,
  onSelectionChanged,
  query,
  selectedColumns,
  children
}: IPickerResultSectionProps<T>) {
  return (
    <div className={styles.resultsSection}>
      <DataTableDef<T>
        emptyText={emptyText}
        data={data}
        keySelector={keySelector}
        selectionMode={selectionMode}
        selected={selected}
        onSelectionChanged={onSelectionChanged}
        containerClassName={styles.resultsGrid}
      >
        {React.Children.map(children, c => maybeWrapText<T>(c, query, selectedColumns) as ColumnDefType<T>)}
      </DataTableDef>
    </div>
  );
}
