import React from "react";

type XmlSectionProps = object;

const XmlSectionContext = React.createContext<XmlSectionProps | null>(null);

const EMPTY_CONTEXT: XmlSectionProps = {};

/**
 * This component is only relevant when rendering controls as XML and let's
 * components in the tree know that they are being rendered inside a "control section",
 * which can be a Filter, Form or similar component.
 */
export const XmlControlSection: React.FC = ({ children }) => {
  return <XmlSectionContext.Provider value={EMPTY_CONTEXT}>{children}</XmlSectionContext.Provider>;
};

export const useInXmlControlSection = () => !!React.useContext(XmlSectionContext);
