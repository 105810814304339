import React, { useImperativeHandle, useRef, useCallback, useMemo, ReactElement } from "react";
import clsx from "clsx";
import { useLocalization, DateLiteral } from "@plex/culture-react";
import { DayValue } from "@plex/culture-core";
import { LocalDate, YearMonth, DayOfWeek } from "js-joda";
import { getMonthDaysByWeek, getWeekStart } from "../DatePicker/date-utils";
import { ICalendarDayInterface, CalendarDay } from "./CalendarDay";
import styles from "./Calendar.module.scss";

export interface ICalendarPropsInterface {
  /**
   * Current month to be displayed
   */
  currentMonth?: YearMonth;
  /**
   * Renders a custom header in the top of
   */
  header?: ReactElement;
  /**
   * Allows one or several days to be selected
   */
  selectionMode?: "multiple" | "single" | "none";
  /**
   * Collection of selected days
   */
  selected?: LocalDate[];
  /**
   * Default month to be shown
   */
  defaultDate?: YearMonth;

  /**
   * First day of the week
   */
  weekStart?: DayValue | DayOfWeek;

  /**
   * Callback that emits with selected values
   */
  onSelectionChanged?: (rows?: LocalDate[]) => void;
  /**
   * Handler called when any day is clicked
   */
  onClick?: (date: LocalDate) => void;

  // data?: ICalendarDayData[];
  /**
   *A custom template for a day to be rendered
   */
  cellTemplate?: (day: ICalendarDayInterface) => JSX.Element;
}

export const Calendar = React.forwardRef<HTMLDivElement, ICalendarPropsInterface>(
  (
    {
      currentMonth,
      header,
      weekStart,
      selected = [],
      onSelectionChanged,
      defaultDate,
      // data,
      cellTemplate,
      selectionMode = "multiple"
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => containerRef.current!);
    const { locale } = useLocalization();
    const firstDayOfWeek = useMemo(() => getWeekStart(locale, weekStart), [locale, weekStart]);
    const weekHeaders = locale.dates.getDayNames("wide");

    if (firstDayOfWeek === 7) {
      /* Sunday shift */
      const sunday = weekHeaders.pop()!;
      weekHeaders.unshift(sunday);
    }

    const weeks = useMemo(() => {
      const firstDay =
        currentMonth?.atDay(1) ||
        defaultDate?.atDay(1) ||
        YearMonth.of(LocalDate.now().year(), LocalDate.now().monthValue()).atDay(1);

      return getMonthDaysByWeek(firstDay, firstDayOfWeek);
    }, [currentMonth, defaultDate, firstDayOfWeek]);

    const getActiveMonth = useMemo(() => {
      if (currentMonth) {
        return currentMonth.monthValue();
      }
      if (defaultDate) {
        return defaultDate.monthValue();
      }
      return LocalDate.now().monthValue();
    }, [currentMonth, defaultDate]);

    const dateHeader = <DateLiteral value={currentMonth?.atDay(1) || defaultDate?.atDay(1)} options="MMMM yyyy" />;

    const handleSelection = useCallback(
      (item: { date: LocalDate; selected: boolean }) => {
        if (selectionMode === "none") {
          return;
        }

        if (selectionMode === "single") {
          onSelectionChanged?.(item.selected ? [item.date] : []);
          return;
        }

        if (item.selected) {
          onSelectionChanged?.([...selected, item.date]);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const index = selected?.findIndex(x => x.isEqual(item.date));
        if (index > -1) {
          const reducedSelected = [...selected];
          reducedSelected.splice(index, 1);
          onSelectionChanged?.(reducedSelected);
        }
      },
      [onSelectionChanged, selected, selectionMode]
    );

    return (
      <div className={styles.calendar} ref={containerRef}>
        <table className={styles.calendarGrid}>
          <thead>
            {header && (
              <tr>
                <th colSpan={7}>{header}</th>
              </tr>
            )}
            <tr className="calendar-header">
              <th colSpan={7}>{dateHeader}</th>
            </tr>
            <tr>
              {weekHeaders.map((week, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <th key={index} className={styles.calendarMonthHeader}>
                    {week}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  {week.map(day => {
                    const isDisabled = getActiveMonth !== day.month().value();
                    const dayData = {
                      date: day,
                      // data: data && data.find(dat => day.isEqual(dat.date) || undefined),
                      selectable: selectionMode !== "none",
                      onSelected: handleSelection,
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      selected: !!selected?.find(x => day.isEqual(x))
                    };
                    return (
                      <td key={day.toString()} className={clsx(styles.calendarDay, isDisabled && styles.disabled)}>
                        {cellTemplate?.(dayData) || <CalendarDay {...dayData} />}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
);

Calendar.displayName = "Calendar";
