import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../../../components/NodeTypes/Base";
import { DataType } from "../../../components/NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const inputName = "input";
const outputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CoalesceNode",
  label: "Coalesce",
  category: NodeTypeCategory.transformations,
  controlOutputs: [],
  dataInputs: [{ name: inputName, hideLabel: true, type: DataType.STRING }],
  dataOutputs: [{ name: outputName, type: DataType.STRING }],
  size: NodeSize.Small,
  documentDataInputListName: "inputs",
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [inputName, outputName] }
    },
    {
      name: "itemCount",
      label: "Items",
      propertyType: NodeConfigPropertyType.DataPropertyCount,
      options: { cloneFrom: inputName }
    }
  ],
  evaluate: (input: any) => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CoalesceNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
