import React, { forwardRef } from "react";
import { TextInput } from "./TextInput";
import { InputSize, IAdornedInputProps } from "./Input.types";
import styles from "./Input.module.scss";

/**
 * Wraps an input component with additional adornment (usually an IconButton).
 * The input will render as a TextInput unless a component (or string) is passed
 * to the `as` property. All props will be passed to the input component.
 */
export const AdornedInput = forwardRef<HTMLInputElement, IAdornedInputProps>((props, ref) => {
  const { adornment, as: Input = TextInput, size = InputSize.dynamic, ...other } = props;

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputWrapper}>
        <Input size={size} {...other} ref={ref} />
      </div>
      <div className={styles.inputAdornment}>{adornment}</div>
    </div>
  );
});

AdornedInput.displayName = "AdornedInput";
