import React, {
  ReactNode,
  ReactElement,
  MouseEventHandler,
  MouseEvent,
  FC,
  useState,
  useRef,
  useEffect,
  useCallback
} from "react";
import { ArrowsCollapseIcon, ArrowsExpandIcon, ArrowUpIcon, ArrowDownIcon } from "@plex/icons";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import clsx from "clsx";
import { ICommonProps, IWithChildren } from "../Common.types";
import { ISideMenuItem, SideMenuItem } from "./SideMenu.Item";
import styles from "./SideMenu.module.scss";

type ItemMovedArgs = {
  id: string;
  oldIndex: number;
  newIndex: number;
};

type ItemMovedHandler = (args: ItemMovedArgs) => void;

export interface ISideMenuProps extends ICommonProps, IWithChildren {
  /** Sets the collapsed state */
  collapsed?: boolean;

  /** Disable collapsing by setting this to FALSE */
  collapsing?: boolean;

  onCollapseChanged?: (collapsed: boolean) => void;

  /** Disable scrolling by setting this to FALSE */
  scrolling?: boolean;

  /** Disable drag-n-drop by setting this to FALSE */
  dragDrop?: boolean;

  /**
   * Callback which will fire when menu item is moved.
   * Consumer should handle moving item within children.
   */
  onItemMoved?: ItemMovedHandler;
}

export interface ISideMenuComposition {
  Item: typeof SideMenuItem;
}

interface ICollapseButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  collapsed: boolean;
}

const CollapseButton: FC<ICollapseButtonProps> = ({ onClick, collapsed }) => {
  return (
    <div className={styles.sideMenuHeader}>
      <div className={styles.sideMenuHeaderIcon} onClick={onClick} data-testid="plex-sidetabs-menu-toggle-button">
        {collapsed ? <ArrowsExpandIcon /> : <ArrowsCollapseIcon />}
      </div>
    </div>
  );
};

interface IScrollButtonProps {
  collapsing?: boolean;
  canScroll: boolean;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onMouseUp: MouseEventHandler<HTMLDivElement>;
}

const ScrollUpButton: FC<IScrollButtonProps> = ({ collapsing, canScroll, onMouseDown, onMouseUp }) => {
  const className = collapsing === false ? styles.sideMenuScrollButtonTop : styles.sideMenuScrollButtonTopMargin;
  return (
    <div className={className} hidden={!canScroll} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <div className={styles.sideMenuScrollButtonBg} />
      <i className={styles.sideMenuScrollButtonIcon}>
        <ArrowUpIcon />
      </i>
    </div>
  );
};

const ScrollDownButton: FC<IScrollButtonProps> = ({ canScroll, onMouseDown, onMouseUp }) => {
  return (
    <div
      className={styles.sideMenuScrollButtonBottom}
      hidden={!canScroll}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <div className={styles.sideMenuScrollButtonBg} />
      <i className={styles.sideMenuScrollButtonIcon}>
        <ArrowDownIcon />
      </i>
    </div>
  );
};

const SideMenuBody: FC<ISideMenuProps> = ({ id, dragDrop, onItemMoved, children }) => {
  if (!dragDrop) {
    return <ul className={styles.sideMenuContent}>{children}</ul>;
  }

  const onDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    onItemMoved?.({
      id: dropResult.draggableId,
      oldIndex: dropResult.source.index,
      newIndex: dropResult.destination.index
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${id ?? ""}-droppable`}>
        {providedDroppable => (
          <ul
            className={styles.sideMenuContent}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {children}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const findSelectedItem = (children: ReactNode): string => {
  const items = React.Children.toArray(children).filter(React.isValidElement);
  const selectedItem = (items.find(x => (x as ReactElement<ISideMenuItem>).props.selected) ?? items[0]) as
    | ReactElement<ISideMenuItem>
    | undefined;
  return selectedItem?.props.id || "";
};

export const SideMenu: FC<ISideMenuProps> & ISideMenuComposition = ({
  id,
  scrolling = true,
  collapsing = true,
  dragDrop = true,
  collapsed: controlledCollapsed,
  onCollapseChanged,
  onItemMoved,
  className,
  children,
  ...other
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [uncontrolledCollapse, setCollapsed] = useState(false);
  const [scrollableDown, setScrollableDown] = useState(false);
  const [scrollableUp, setScrollableUp] = useState(false);
  const scrollBtnIntervalHandlerRef = useRef<NodeJS.Timeout>();
  const [selectedId, setSelectedId] = useState<string>(() => findSelectedItem(children));

  const handleCollapsing = onCollapseChanged ?? setCollapsed;
  const collapsed = controlledCollapsed ?? uncontrolledCollapse;

  const switchScrollButtons = useCallback(() => {
    const clientHeight = containerRef.current?.clientHeight || 0;
    const scrollTop = containerRef.current?.scrollTop || 0;
    const scrollHeight = containerRef.current?.scrollHeight || 0;

    if (scrollHeight > clientHeight) {
      setScrollableUp(scrollTop > 0);
      setScrollableDown(Math.ceil(scrollHeight - scrollTop) !== clientHeight);
    } else {
      setScrollableUp(false);
      setScrollableDown(false);
    }
  }, []);

  const scrollElement = (delta: number) => {
    const scrollHeight = containerRef.current?.scrollHeight || 0;
    const clientHeight = containerRef.current?.clientHeight || 0;
    const bottomScrollTop = scrollHeight - clientHeight;

    const scrolltopComputed = Math.min(Math.max(containerRef.current!.scrollTop + delta, 0), bottomScrollTop);
    containerRef.current!.scrollTop = scrolltopComputed;
  };

  const performScrolling = (direction: "down" | "up") => {
    const defaultScrollHeight = 100;
    if (direction === "up") {
      scrollElement(defaultScrollHeight * -1);
    } else {
      scrollElement(defaultScrollHeight);
    }
  };

  const cancelScroll = useCallback(() => {
    clearInterval(scrollBtnIntervalHandlerRef.current!);
  }, []);

  const onScrollBtnMouseDownHandler = (direction: "down" | "up") => () => {
    cancelScroll();
    scrollBtnIntervalHandlerRef.current = setInterval(() => performScrolling(direction), 100);
  };

  const onWheelHandler = (e: React.WheelEvent) => {
    cancelScroll();
    scrollElement(e.deltaY || 0);
  };

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    window.addEventListener("resize", switchScrollButtons);
    switchScrollButtons();

    return () => {
      cancelScroll();
      window.removeEventListener("resize", switchScrollButtons);
    };
  }, [switchScrollButtons, cancelScroll]);

  const itemClickHandlerFactory = ({ id: itemId, onClick, onClickProps }: ISideMenuItem) => (
    e: MouseEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    setSelectedId(itemId!);
    onClick?.(e, onClickProps);
  };

  const items = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        key: child.props.id,
        onClick: itemClickHandlerFactory(child.props),
        selected: child.props.id === selectedId,
        draggable: dragDrop && child.props.draggable !== false,
        onWheel: onWheelHandler,
        collapsed,
        index
      });
    }
    return child;
  });

  return (
    <div className={clsx(styles.sideMenuContainer, collapsed && styles.collapsed, className)} {...other}>
      {collapsing && <CollapseButton collapsed={collapsed} onClick={() => handleCollapsing(!collapsed)} />}
      {scrolling && (
        <ScrollUpButton
          canScroll={scrollableUp}
          collapsing={collapsing}
          onMouseDown={onScrollBtnMouseDownHandler("up")}
          onMouseUp={cancelScroll}
        />
      )}
      <div className={styles.sideMenu} ref={containerRef} onWheel={onWheelHandler} onScroll={switchScrollButtons}>
        <SideMenuBody id={id} dragDrop={dragDrop} onItemMoved={onItemMoved}>
          {items}
        </SideMenuBody>
      </div>
      {scrolling && (
        <ScrollDownButton
          canScroll={scrollableDown}
          onMouseDown={onScrollBtnMouseDownHandler("down")}
          onMouseUp={cancelScroll}
        />
      )}
    </div>
  );
};

SideMenu.Item = SideMenuItem;
