import React, { FC, ReactElement, createElement } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps } from "../Common.types";
import { ColumnType } from "./FormSection";
import styles from "./Form.module.scss";

export interface IBodyProps extends ICommonProps {
  /** Number of Columns in the form */
  layout?: ColumnType;
}

const HtmlFormBody: FC<IBodyProps> = ({ layout, children, className, ...other }) => {
  let columnLayout = layout === ColumnType.single ? styles.formColumnSingle : styles.formColumnDouble;
  if (React.Children.count(children) === 1) {
    columnLayout = styles.formColumnSingle;
  }

  return (
    <div
      className={clsx(styles.formColumn, columnLayout, className)}
      data-testid="plex-form-content-wrapper"
      {...other}
    >
      <div data-testid="plex-form-content">{children}</div>
    </div>
  );
};

const XmlFormBody: FC<IBodyProps> = ({ children, layout }) => {
  if (layout === ColumnType.double) {
    return createElement("plex-form-columns", {}, children);
  }
  return children as ReactElement;
};

export const FormBody = withPrinting(HtmlFormBody, XmlFormBody);
