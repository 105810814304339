import { DataTablePluginFactory } from "../DataTable.types";

export const assertCompatiblePlugins = <T>(
  plugins: Array<DataTablePluginFactory<T> | false>,
  disallowed: string[],
  pluginName: string
) => {
  const incompatible = plugins
    .filter(plugin => plugin && disallowed.includes(plugin.pluginName))
    .map(x => (x as DataTablePluginFactory<T>).pluginName);
  if (incompatible.length > 0) {
    throw new Error(`The following plugins are incompatible with the ${pluginName} plugin: ${incompatible.join(",")}`);
  }
};

export const assertPrerequisitePlugins = <T>(
  plugins: Array<DataTablePluginFactory<T> | false>,
  required: string[],
  pluginName: string
) => {
  const existing = plugins.filter(plugin => plugin && required.includes(plugin.pluginName));
  if (existing.length !== required.length) {
    throw new Error(`The following plugins are required when using the ${pluginName} plugin: ${required.join(",")}`);
  }
};
