import React, { FC, ReactElement } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import { Spinner } from "./Spinner";
import styles from "./Overlay.module.scss";

export interface IInlineOverlayProps extends ICommonProps {
  /** Should the overlay show */
  show: boolean;
  /** Should the spinner show */
  showSpinner?: boolean;
  /** Should the spinner show right centered or left */
  spinnerPosition?: "right" | "center" | "left";
  /** The overlay theme */
  theme?: "dark" | "white";
}

/** Component which renders an overlay of the child components */
export const InlineOverlay: FC<IInlineOverlayProps> = ({
  show,
  children,
  showSpinner = true,
  spinnerPosition = "right",
  theme = "dark",
  ...other
}) => {
  const displayTheme = theme === "dark" && styles.overlayTransitionDark;
  if (show) {
    let iconPosition = styles.overlayInlineSpinnerContainerRight;
    switch (spinnerPosition) {
      case "left":
        iconPosition = styles.overlayInlineSpinnerContainerLeft;
        break;
      case "center":
        iconPosition = styles.overlayInlineSpinnerContainerCenter;
        break;
      default:
        iconPosition = styles.overlayInlineSpinnerContainerRight;
        break;
    }
    return (
      <div className={styles.overlayInline} data-testid="plex-blocked-inline" {...other}>
        <div className={clsx(styles.overlayInlineOverlay, displayTheme)}>
          <div className={clsx(iconPosition, styles.overlayInlineSpinnerContainer, displayTheme)} />
        </div>
        {showSpinner && (
          <div
            className={clsx(styles.overlayInlineSpinnerAbsolute, iconPosition, styles.overlayInlineSpinnerContainer)}
          >
            <Spinner type="darkspin" />
          </div>
        )}
        {children}
      </div>
    );
  }

  return children as ReactElement;
};
