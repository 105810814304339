import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DataType } from "../../NodeTypes/TypeDefinitions";
import { camelCaseToReadable } from "../../Util/NodeUtil";

const resultOutputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConstantNode",
  label: "Literal",
  category: NodeTypeCategory.general,
  hideLabelOnAnchor: true,
  controlOutputs: [],
  dataInputs: [],
  dataOutputs: [{ name: resultOutputName, type: DataType.STRING, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [resultOutputName] }
    },
    {
      name: "literal",
      label: "Value",
      propertyType: NodeConfigPropertyType.DataValue
    }
  ],
  size: NodeSize.Small,
  evaluate: (input, nodeProperties) => {
    return { output: { result: nodeProperties.literal }, activeControlHandleIds: [] };
  }
});

export const ConstantNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const getValueDisplay = () => {
    if (!data.nodeProperties.outputs.result) {
      return "";
    }

    switch (data.nodeProperties.outputs.result.type) {
      case "boolean":
        return data.nodeProperties.literal ? "True" : "False";
      case "date":
        return data.nodeProperties.literal?.toString();
      default:
        return data.nodeProperties.literal;
    }
  };
  const label: string = camelCaseToReadable(
    data.nodeProperties.outputs.result?.type === undefined ? "uuid" : data.nodeProperties.outputs.result?.type
  );

  return (
    <BasicNodeLayout id={id} data={data} label={label}>
      <div className="value-node-container">
        <div className="value-node-value">{getValueDisplay()}</div>
      </div>
    </BasicNodeLayout>
  );
};
