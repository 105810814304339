import React, { ReactText, useState } from "react";
import { useLocalization } from "@plex/culture-react";
import { ValueAccessor } from "../Common.types";
import { OkButton } from "../Button";
import { CancelLink } from "../Link";
import { Dialog } from "./Dialog";
import { DialogPrompt } from "./DialogPrompt";
import { PromptType } from "./DialogPrompt.types";
import styles from "./Dialog.module.scss";

export interface IAskDialogProps<T = string> {
  /** Indicates whether the dialog should be rendered */
  show: boolean;
  /** The dialog title */
  title?: string;
  /** Question text */
  question: string;
  /** Possible answers */
  answers?: T[];
  /** Function, which gets key/value from option item    */
  keySelector: ValueAccessor<T, ReactText>;
  /**  Function, which gets display value from option item    */
  displaySelector: ValueAccessor<T, string>;
  /** Default answer */
  defaultAnswer?: T[] | T;
  /** Callback executed when the dialog is being closed */
  onHide: (items?: T[]) => void;
  /** Indicates how the choices are displayed */
  displayType?: PromptType;
}

// eslint-disable-next-line func-style
export function AskDialog<T = string>({
  show,
  title,
  question,
  answers = [],
  keySelector,
  displaySelector,
  defaultAnswer = [],
  onHide,
  displayType
}: IAskDialogProps<T>) {
  const { t } = useLocalization();
  const minWidth = 360;
  const minHeight = 215;

  const defaultAnswerArray = Array.isArray(defaultAnswer) ? defaultAnswer : [defaultAnswer];
  const [selectedItems, setSelectedItems] = useState(defaultAnswerArray);

  const handleCancel = () => {
    onHide(defaultAnswerArray);
  };

  const handleClose = () => {
    onHide();
  };

  const handleCommit = () => {
    onHide(selectedItems);
  };

  const handleSelectedChange = (items: T[]) => {
    setSelectedItems(items);
  };

  const handleSelectedChangeAndCommit = (items: T[]) => {
    onHide(items);
  };

  const getDisplayPrompt = (prompt?: PromptType) => {
    if (!prompt) {
      if (answers.length < 2) {
        return PromptType.textbox;
      }
      if (answers.length > 4) {
        return PromptType.list;
      }
      return PromptType.buttons;
    }
    return prompt;
  };

  const displayPrompt = getDisplayPrompt(displayType);

  const displayFooterPrompts = () => {
    if (displayPrompt === PromptType.buttons) {
      return (
        <DialogPrompt
          answers={answers}
          keySelector={keySelector}
          displaySelector={displaySelector}
          selectedItems={selectedItems}
          displayType={displayPrompt}
          onSelectionChanged={handleSelectedChangeAndCommit}
        />
      );
    }
    return (
      <>
        <CancelLink onClick={handleCancel} className={styles.cancelLink} data-testid="plex-cancel-link" />
        <OkButton onClick={handleCommit} className={styles.okButton} data-testid="plex-ok-button" size="sm" />
      </>
    );
  };

  return (
    <Dialog
      data-testid="plex-ask-modal"
      closeButton
      show={show}
      minWidth={minWidth}
      minHeight={minHeight}
      title={title || t("ui-common-dialog-title-question")}
      backdrop="static"
      onHide={handleClose}
    >
      <Dialog.Body>
        <div className={styles.notificationContent}>
          <span className={styles.notificationMessage} data-testid="plex-ask-question">
            {question}
          </span>
          {displayPrompt !== PromptType.buttons && (
            <div data-testid="plex-ask-prompt">
              <DialogPrompt
                answers={answers}
                keySelector={keySelector}
                displaySelector={displaySelector}
                selectedItems={selectedItems}
                displayType={displayPrompt}
                onSelectionChanged={handleSelectedChange}
              />
            </div>
          )}
        </div>
      </Dialog.Body>
      <Dialog.Footer>{displayFooterPrompts()}</Dialog.Footer>
    </Dialog>
  );
}
