import React, { FC, MouseEvent } from "react";
import clsx from "clsx";
import { Clickable } from "../Link";
import styles from "./Wizard.module.scss";

interface IWizardPageLinkProps {
  /** Indicates that the link is disabled */
  disabled?: boolean;
  /** Indicates that the link is for the active page */
  active?: boolean;
  /** Event which occurs when link is clicked */
  onClick?: () => void;
}

/** Component for links displayed in Wizard PageList */
export const WizardPageLink: FC<IWizardPageLinkProps> = ({ active, disabled, onClick, children }) => {
  const textOnly = active || disabled;

  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    if (active || disabled || e.target !== e.currentTarget) {
      // ignore clicks when triggered by a different component
      return;
    }

    onClick?.();
  };

  return (
    <li
      data-testid="plex-wizard-page"
      onClick={handleClick}
      className={clsx(styles.pageLink, active && styles.active, disabled && styles.disabled)}
    >
      {textOnly ? (
        children
      ) : (
        <Clickable as="a" onClick={onClick}>
          {children}
        </Clickable>
      )}
    </li>
  );
};
