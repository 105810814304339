import {
  FunctionComponent,
  NamedExoticComponent,
  createElement,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  ReactElement,
  PropsWithChildren
} from "react";
import { usePrinting } from "./use-printing";
import { setRef } from "./set-ref";

/**
 * HOC which wraps a component and only renders during standard
 * rendering, returning null when rendered during XML rendering.
 *
 * @param {DefaultComponent} Component
 */
export function withNoPrinting<P>(component: FunctionComponent<P>): FunctionComponent<P>;
export function withNoPrinting<P, R>(
  component: ForwardRefExoticComponent<P & RefAttributes<R>>
): ForwardRefExoticComponent<P & RefAttributes<R>>;
// eslint-disable-next-line func-style
export function withNoPrinting<P, R>(component: (props: PropsWithChildren<P>) => ReactElement | null): unknown {
  if (typeof component === "function") {
    const fc = component as FunctionComponent<P>;
    const FC: FunctionComponent<P> = (props: P) => {
      if (usePrinting()) {
        return null;
      }
      return createElement(fc, props);
    };
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    FC.displayName = `withNoPrinting(${fc.displayName || fc.name})`;
    return FC;
  }

  const c = component as NamedExoticComponent<P>;
  const C = forwardRef<R, P>((props, ref) => {
    if (usePrinting()) {
      return null;
    }

    return createElement(c, {
      ...props,
      ref: (node: R) => {
        setRef(node, ref);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setRef(node, (c as any).ref);
      }
    });
  });
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  C.displayName = `withNoPrinting(${c.displayName || c.name})`;
  return C;
}
