import React, { MouseEventHandler, forwardRef, FC, createElement } from "react";
import clsx from "clsx";
import { useLocalization } from "@plex/culture-react";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps, IWithChildren } from "../Common.types";
import { Clickable, ClickableComponentType } from "./Clickable";
import styles from "./Link.module.scss";

interface ILinkProps extends ICommonProps, IWithChildren {
  /**
   * A URL to navigate to when the link is clicked. An empty link will not self navigate.
   */
  href?: string;
  /**
   * When disabled navigation to the given href and the onClick will no longer work.
   * @default false
   */
  disabled?: boolean;
  /**
   * The action to perform when the link is clicked.
   * @default null
   */
  onClick?: MouseEventHandler<HTMLElement>;
  /**
   * Sets the react component for rendering this  button. Most common values are 'button' and 'a'
   * @default a
   */
  as?: ClickableComponentType;
}

const HtmlLink = forwardRef<HTMLElement, ILinkProps>(({ disabled = false, children, className, ...other }, ref) => {
  const classes = clsx(styles.base, disabled && styles.disabled, className);
  return (
    <Clickable className={classes} disabled={disabled} role="link" ref={ref} {...other}>
      {children}
    </Clickable>
  );
});
HtmlLink.displayName = "Link";

const XmlLink: FC<ILinkProps> = ({ children }) => {
  return createElement("plex-control-link", {}, children);
};

export const CancelLink = forwardRef<HTMLElement, ILinkProps>((props, ref) => {
  const { t } = useLocalization();
  return (
    <HtmlLink ref={ref} {...props}>
      {t("ui-common-action-cancel")}
    </HtmlLink>
  );
});
CancelLink.displayName = "CancelLink";

export const Link = withPrinting(HtmlLink, XmlLink);
