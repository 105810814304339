import { Node, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { DocumentSchemaSource, IDataParam, INodeProperty, INodeTypeDefinition, IoType } from "./Base";
import {
  addObjectListSchema,
  addObjectSchema,
  getDocumentObjectSchemaFromProperties,
  getEmptySchemaId,
  getObjectSchemaIdWithSource,
  getSchemaId,
  setInputsFromSchema
} from "./DataSchemas";
import { DataType } from "./TypeDefinitions";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { updateLinkedSchemas } from "../Util/EdgeUtil";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { DocumentSchemaType, IDocumentSchema } from "../FlowDocument/FlowDocumentModel";
import { upperSnakeToCamelCase } from "../FlowDocument/DocumentProcessor";
import { convertKeysToCamelCaseNested } from "../Util/KeyFormatter";

export interface IStandardObject {
  id: string;
  name: string;
  identifiers: IDataParam[];
  fields: IDataParam[];
  filters: IDataParam[];
  searchResult: IDataParam[];
}

interface IAddIo {
  newPropertyValue?: { id: string; friendlyName: string };
  node: Node<any>;
  propertyName: string;
  schemaSourceType: DocumentSchemaSource;
  ioType: IoType;
  standardObject: IStandardObject;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const getStandardObjectSchemaIds = (node: Node<any>) => {
  return [
    getSchemaId(DataType.OBJECT, node, ["identifiers"]),
    getSchemaId(DataType.OBJECT, node, ["filters"]),
    getSchemaId(DataType.OBJECT, node, ["fields"]),
    getSchemaId(DataType.OBJECT, node, ["searchResult"]),
    getSchemaId(DataType.OBJECTLIST, node, ["searchResult"])
  ];
};

export interface ICreateIoParam {
  newPropertyValue?: { id: string; friendlyName: string };
  standardObject?: IStandardObject;
  node: Node<any>;
  schemaSystemProperty: INodeProperty;
  bannerContext: IBannerContext;
  plexGetCustomFieldsSchema:
    | ((standardObjectId: string, standardObjectFriendlyName: string) => Promise<any>)
    | undefined;
  plexShowOverlay: ((status: boolean) => void) | undefined;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const createIo = ({
  standardObject,
  node,
  schemaSystemProperty,
  reactFlowInstance,
  updateNodeInternals
}: ICreateIoParam) => {
  const id = node.data.nodeProperties[schemaSystemProperty.name];

  if (!id) {
    resetIo(node, reactFlowInstance, updateNodeInternals);
    return;
  }

  switch (schemaSystemProperty.schemaSourceSystemType) {
    case DocumentSchemaSource.customFieldsV1:
      if (standardObject && schemaSystemProperty.schemaSystem?.ioType !== undefined) {
        addCustomFieldsIoV1({
          node,
          propertyName: schemaSystemProperty.name,
          schemaSourceType: schemaSystemProperty.schemaSourceSystemType!,
          ioType: schemaSystemProperty.schemaSystem.ioType,
          standardObject: standardObject,
          reactFlowInstance,
          updateNodeInternals
        });
      }
      break;
  }
};

export interface ILoadCustomFieldsResult {
  outputSchema: IDocumentSchema;
  standardObject: IStandardObject;
}

export const loadIo = ({
  newPropertyValue,
  node,
  schemaSystemProperty,
  bannerContext,
  plexGetCustomFieldsSchema,
  plexShowOverlay,
  reactFlowInstance,
  updateNodeInternals
}: ICreateIoParam) => {
  let promise: Promise<any | undefined | void> = Promise.resolve(undefined);
  const id = newPropertyValue!.id;
  const friendlyName = newPropertyValue!.friendlyName;

  if (schemaSystemProperty) {
    plexShowOverlay && plexShowOverlay(true);
    switch (schemaSystemProperty.schemaSourceSystemType) {
      case DocumentSchemaSource.customFieldsV1:
        if (plexGetCustomFieldsSchema) {
          promise = plexGetCustomFieldsSchema(id, friendlyName)
            .then((res) => {
              const result: any = JSON.parse(res.Data.Schema);
              const resultData: any = convertKeysToCamelCaseNested(result);
              const toDataParam = (soParam: {
                key: string;
                primitiveType: string;
                friendlyName: string;
              }): IDataParam => {
                return {
                  name: soParam.key,
                  type: upperSnakeToCamelCase(soParam.primitiveType) as DataType,
                  schemaId: upperSnakeToCamelCase(soParam.primitiveType),
                  label: soParam.friendlyName
                };
              };
              const standardObject: IStandardObject = {
                id: id,
                name: friendlyName,
                identifiers: resultData.identifiers
                  .filter((param: any) => param.primitiveType)
                  .map((param: any) => toDataParam(param)),
                fields: resultData.fields
                  .filter((param: any) => param.primitiveType)
                  .map((param: any) => toDataParam(param)),
                filters: resultData.filters
                  .filter((param: any) => param.primitiveType)
                  .map((param: any) => toDataParam(param)),
                searchResult: resultData.identifiers
                  .concat(resultData.fields)
                  .filter((param: any) => param.primitiveType)
                  .map((param: any) => toDataParam(param))
              };
              if (schemaSystemProperty.schemaSystem?.ioType !== undefined) {
                const outputSchema = addCustomFieldsSchemaV1({
                  newPropertyValue,
                  node,
                  propertyName: schemaSystemProperty.name,
                  schemaSourceType: schemaSystemProperty.schemaSourceSystemType!,
                  ioType: schemaSystemProperty.schemaSystem.ioType,
                  standardObject: standardObject,
                  reactFlowInstance,
                  updateNodeInternals
                });
                return Promise.resolve({
                  outputSchema: outputSchema,
                  standardObject: standardObject
                } as ILoadCustomFieldsResult);
              }

              plexShowOverlay && plexShowOverlay(false);
            })
            .catch((e) => {
              bannerContext.addMessage("An error occurred retrieving custom fields metadata.", BannerStatus.error);
              console.log(e);

              plexShowOverlay && plexShowOverlay(false);
            });
        }
    }
  }

  return promise;
};

export const resetIo = (
  node: Node<any>,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

  node!.data.nodeProperties.inputs = {};

  nodeDefinition.dataOutputs.forEach(
    (dataOutput: IDataParam) =>
      (node!.data.nodeProperties.outputs[dataOutput.name].schemaId = getEmptySchemaId(
        node!.data.nodeProperties.outputs[dataOutput.name].type
      ))
  );

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

const addCustomFieldsIoV1 = ({
  node,
  propertyName,
  ioType,
  standardObject,
  reactFlowInstance,
  updateNodeInternals
}: IAddIo) => {
  resetIo(node, reactFlowInstance, updateNodeInternals);

  if (node.data.nodeProperties[propertyName]) {
    const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

    let outputName: string | undefined;
    const schemaSourceId: string = node.data.nodeProperties.standardObjectId;
    const schemaSource: {
      sourceSystem: DocumentSchemaSource;
      sourceId: any;
    } = { sourceSystem: DocumentSchemaSource.customFieldsV1, sourceId: schemaSourceId };

    const identifiersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["identifiers"], schemaSource);
    const filtersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["filters"], schemaSource);
    const fieldsSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["fields"], schemaSource);
    const searchResultIdPart = "searchResult";
    const searchResultListSchemaId = getObjectSchemaIdWithSource(
      DataType.OBJECTLIST,
      [searchResultIdPart],
      schemaSource
    );

    const identifiersSchema = getDocumentObjectSchemaFromProperties(identifiersSchemaId, standardObject.identifiers);
    const filtersSchema = getDocumentObjectSchemaFromProperties(filtersSchemaId, standardObject.filters);
    const fieldsSchema = getDocumentObjectSchemaFromProperties(fieldsSchemaId, standardObject.fields);

    switch (ioType) {
      case IoType.get:
        setInputsFromSchema(node, identifiersSchema, true);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = fieldsSchemaId;
        }
        break;
      case IoType.search:
        setInputsFromSchema(node, filtersSchema);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = searchResultListSchemaId;
        }
        break;
      case IoType.update:
        setInputsFromSchema(node, identifiersSchema, true);
        setInputsFromSchema(node, fieldsSchema);
        break;
    }
  }

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

const addCustomFieldsSchemaV1 = ({ newPropertyValue, node, propertyName, ioType, standardObject }: IAddIo) => {
  let outputSchema: IDocumentSchema | undefined = undefined;
  if (newPropertyValue?.id) {
    const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

    const standardObjectProperty: INodeProperty = nodeDefinition.nodeConfigProperties.filter(
      (p: INodeProperty) => p.name === propertyName
    )[0]!;
    const schemaSourceSystem: string | undefined = standardObjectProperty.schemaSourceSystemType;
    const schemaSourceId: string = newPropertyValue?.id;
    const schemaSource: {
      sourceSystem: DocumentSchemaSource;
      sourceId: any;
    } = { sourceSystem: DocumentSchemaSource.customFieldsV1, sourceId: schemaSourceId };

    const identifiersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["identifiers"], schemaSource);
    const filtersSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["filters"], schemaSource);
    const fieldsSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, ["fields"], schemaSource);
    const searchResultIdPart = "searchResult";
    const searchResultSchemaId = getObjectSchemaIdWithSource(DataType.OBJECT, [searchResultIdPart], schemaSource);
    const searchResultListSchemaId = getObjectSchemaIdWithSource(
      DataType.OBJECTLIST,
      [searchResultIdPart],
      schemaSource
    );

    const identifiersSchema = getDocumentObjectSchemaFromProperties(identifiersSchemaId, standardObject.identifiers);
    const filtersSchema = getDocumentObjectSchemaFromProperties(filtersSchemaId, standardObject.filters);
    const fieldsSchema = getDocumentObjectSchemaFromProperties(fieldsSchemaId, standardObject.fields);
    const searchResultSchema = getDocumentObjectSchemaFromProperties(searchResultSchemaId, standardObject.searchResult);

    const searchResultSchemaList = {
      id: searchResultListSchemaId,
      schemaType: DocumentSchemaType.list,
      listItemSchema: searchResultSchemaId
    };

    switch (ioType) {
      case IoType.get:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(fieldsSchema, schemaSourceSystem!, schemaSourceId);
        outputSchema = fieldsSchema;
        break;
      case IoType.search:
        addObjectSchema(filtersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(searchResultSchema, schemaSourceSystem!, schemaSourceId);
        addObjectListSchema(searchResultSchemaList, schemaSourceSystem!, schemaSourceId);
        outputSchema = searchResultSchemaList;
        break;
      case IoType.update:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        addObjectSchema(fieldsSchema, schemaSourceSystem!, schemaSourceId);
        break;
    }
  }

  return outputSchema;
};
