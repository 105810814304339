import React, { FunctionComponent } from "react";
import { ConfirmDialog } from "@plex/react-components";
import { useReactFlow } from "reactflow";
import "./ConfirmationDialog.scss";
import { setNodeSelection } from "../Util/NodeUtil";
import { IConnectionRemap, INodeRemap, INodeRemapProperty } from "../Util/EdgeUtil";

export const ConfirmationDialog: FunctionComponent = () => {
  const reactFlowInstance = useReactFlow();

  const renderQuestion = (question: string | IConnectionRemap) => {
    const stringQuestion = question as string;
    if (typeof question === "string") {
      return stringQuestion;
    }

    const remapQuestion = question as IConnectionRemap;
    if (remapQuestion) {
      const selectNode = (nodeId: string) => {
        const viewport = reactFlowInstance.getViewport();
        reactFlowInstance.fitView({
          nodes: [{ id: nodeId }],
          minZoom: viewport.zoom,
          maxZoom: viewport.zoom,
          duration: 500
        });
        setNodeSelection([nodeId], reactFlowInstance);
      };
      return (
        <>
          Connections to the following outputs will be deleted, do you want to continue?
          <br />
          <div className="node-remap-text-tip">(click below to navigate to node)</div>
          <br />
          {remapQuestion.nodes.map((nodeRemap: INodeRemap, index: number) => {
            return (
              <div
                onClick={() => selectNode(nodeRemap.nodeId)}
                key={"node-remap-node-label" + index}
                className="node-remap-node-container"
              >
                <div className="node-remap-node-label">
                  Node: {nodeRemap.nodeLabel}
                  {nodeRemap.remapProperties.map((remapProperty: INodeRemapProperty, propertyIndex: number) => {
                    return (
                      <div className="node-remap-property-label" key={"node-remap-property-label" + propertyIndex}>
                        {"• " + remapProperty.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return <></>;
  };

  return (
    <ConfirmDialog
      show={globalThis.confirmDialogVisibleState}
      title="Question"
      question={renderQuestion(globalThis.confirmDialogQuestion)}
      answers={["Yes", "No"]}
      defaultAnswer="No"
      onHide={(answer: string) => {
        if (answer === "Yes" && globalThis.confirmDialogOnConfirm) {
          globalThis.confirmDialogOnConfirm();
        }
        globalThis.confirmDialogQuestion = undefined;
        globalThis.confirmDialogOnConfirm = undefined;
        globalThis.setConfirmDialogVisibleState(false);
      }}
    />
  );
};
