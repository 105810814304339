import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "StopNode",
  category: NodeTypeCategory.general,
  controlInputOnly: true,
  controlOutputs: [],
  dataInputs: [],
  documentDataInputListName: "inputs",
  dataOutputs: [],
  nodeConfigProperties: [
    {
      name: "flowOutput",
      sectionName: "Flow Output",
      propertyType: NodeConfigPropertyType.DataInputs
    }
  ],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const StopNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const forFailure = data.nodeProperties.stopType?.length > 0 && data.nodeProperties.stopType[0].value === "Failure";
  const className = forFailure ? "failure" : "";
  return <BasicNodeLayout id={id} data={data} className={className} />;
};
