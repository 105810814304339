import React from "react";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { chainHandlers } from "@utils";
import { NumericInput } from "../../Input/NumericInput";
import { INumericInputProps } from "../../Input/Input.types";
import * as globalStyles from "../../../global-styles";
import { FormikField } from "./Formik.types";

export const FormikNumericInput: FormikField<INumericInputProps> = ({
  name,
  className,
  onValueChange,
  onBlur,
  ...other
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.errors?.[name];
        return (
          <NumericInput
            name={field.name}
            className={clsx(className, hasError && globalStyles.hasErrors)}
            numericValue={field.value}
            onValueChange={val => {
              // Since the input's value is a formatted number
              // we can't use the change event from the input.
              // This is a standard work around for custom components.
              form.setFieldValue(field.name, val, true);
              onValueChange?.(val);
            }}
            onBlur={chainHandlers(field.onBlur, onBlur)}
            {...other}
          />
        );
      }}
    </Field>
  );
};
