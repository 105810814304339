import React from "react";
import { Tabs as TabsElement } from "react-tabs";
import { Tab } from "./Tab";
import { TabList } from "./TabList";
import { TabPanel } from "./TabPanel";
import styles from "./Tabs.module.scss";
import { ITabsProps } from "./Tabs.types";

export interface ITabs {
  (props: ITabsProps): JSX.Element;
  /** The tab to render its name. */
  Tab: typeof Tab;
  /** The panel to render content of a tab. */
  TabPanel: typeof TabPanel;
  /** The tab list section. */
  TabList: typeof TabList;
}

/** Component which renders data within tabs. */
const Tabs: ITabs = ({ keepMounted = false, selectedIndex, children, onSelect, ...other }: ITabsProps) => {
  return (
    <TabsElement
      data-testid="plex-tabs"
      forceRenderTabPanel={keepMounted}
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      className={styles.reactTabs}
      {...other}
    >
      {children}
    </TabsElement>
  );
};

Tabs.Tab = Tab;
Tabs.TabPanel = TabPanel;
Tabs.TabList = TabList;

export { Tabs };
