import React, { FunctionComponent, MouseEventHandler } from "react";
import { ArrowsExpandIcon, CloseIcon } from "@plex/icons";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import { MountSide } from "../SidePanel";
import styles from "./SideTab.module.scss";

export interface ISideTabProps extends ICommonProps {
  /* tab title */
  title: React.ReactNode;

  /* Handler for close button events */
  onClose?: MouseEventHandler;

  /* Handler for expand button events */
  onExpand?: MouseEventHandler;

  /* Class name applied to the wrapping div */
  className?: string;

  /* The side of the screen that the component is attached to */
  mountSide?: MountSide;
}

export const SideTab: FunctionComponent<ISideTabProps> = ({
  title,
  onClose,
  onExpand,
  className,
  mountSide = MountSide.left
}) => {
  return (
    <div className={clsx(styles.base, className)}>
      <div className={mountSide === MountSide.left ? styles.expandIcon : styles.flippedExpandIcon} onClick={onExpand}>
        <ArrowsExpandIcon />
      </div>
      <div>{title}</div>
      <div className={styles.closeIcon} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  );
};
