import React, { FunctionComponent, useState } from "react";
import { useLocalization } from "@plex/culture-react";
import clsx from "clsx";
import { CancelLink } from "../Link";
import { ICommonProps, MaybeAsync } from "../Common.types";
import { useToggle } from "../../hooks/use-toggle";
import { Dialog } from "../Dialog";
import { OkButton } from "../Button";
import { IconPickerModal } from "./IconPickerModal";
import { IIcon, IconColor } from "./IconPicker.types";
import styles from "./IconPicker.module.scss";

export interface IIconPickerProps extends ICommonProps {
  /** The currently selected icon */
  selectedIcon?: IIcon;
  /**
   * Callback which is executed when the icon
   * selection has changed
   */
  onSelectionChanged: (icon: IIcon) => void;
  /** The collection of icons to select from */
  data: MaybeAsync<IIcon[]>;
  /**
   * Colors to allow for backgrounds - if none is supplied
   * the standard color palette will be used
   */
  colors?: IconColor[];
}

const DIALOG_MIN_WIDTH = 400;
const DIALOG_MIN_HEIGHT = 275;
const EMPTY_ICON: IIcon = { key: "", node: null };

export const IconPicker: FunctionComponent<IIconPickerProps> = ({
  selectedIcon,
  data,
  colors,
  onSelectionChanged,
  ...other
}) => {
  const { t } = useLocalization();
  const [openDialog, toggleOpen] = useToggle(false);
  const [uncommittedIcon, setUncommittedIcon] = useState(selectedIcon || EMPTY_ICON);
  const currentIcon = selectedIcon || EMPTY_ICON;

  const handleDialogOk = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    onSelectionChanged?.(uncommittedIcon);
    toggleOpen();
  };

  const handleOpenDialog = () => {
    toggleOpen();
    setUncommittedIcon(currentIcon);
  };

  return (
    <div {...other}>
      <div className={styles.iconSelectRow}>
        <div className={styles.iconSelectContainer} onClick={handleOpenDialog} data-testid="plex-icon-picker-icon">
          <div className={styles.iconSelect}>
            <div
              className={clsx(styles.iconSize, currentIcon.color?.className)}
              style={{ backgroundColor: currentIcon.color?.color }}
            >
              {currentIcon.node || "\u00A0"}
            </div>
          </div>
        </div>
      </div>
      {openDialog && (
        <Dialog
          onHide={toggleOpen}
          title={t("ui-common-iconPicker-title-dialog")}
          backdrop="static"
          closeOnEscape
          show
          minHeight={DIALOG_MIN_HEIGHT}
          minWidth={DIALOG_MIN_WIDTH}
        >
          <Dialog.Body>
            <IconPickerModal
              selectedIcon={uncommittedIcon}
              colors={colors}
              data={data}
              onSelectionChanged={setUncommittedIcon}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <CancelLink onClick={toggleOpen} />
            <OkButton onClick={handleDialogOk} />
          </Dialog.Footer>
        </Dialog>
      )}
    </div>
  );
};
