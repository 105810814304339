import React, { useEffect, useMemo, useRef, FunctionComponent } from "react";
import { PrintContext, PrintContextValue } from "./PrintContext";

interface IPrintProviderProps {
  onPrintReady: () => void;
}

export const PrintProvider: FunctionComponent<IPrintProviderProps> = ({ onPrintReady, children }) => {
  const promises = useRef<Array<Promise<never>>>([]);
  const handlePrintReadyRef = useRef(onPrintReady);

  // eslint-disable-next-line no-void
  useEffect(() => void (handlePrintReadyRef.current = onPrintReady));

  const value = useMemo<PrintContextValue>(() => {
    return {
      isPrinting: true,
      waitFor(promise: Promise<never>) {
        promises.current.push(promise);
      }
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all(promises.current).finally(() => handlePrintReadyRef.current());
  }, []);

  return <PrintContext.Provider value={value}>{children}</PrintContext.Provider>;
};
