import React, { FunctionComponent, useEffect, useMemo, useState, MouseEventHandler } from "react";
import clsx from "clsx";
import { LocalDate, YearMonth } from "js-joda";
import { DateLiteral, useLocalization } from "@plex/culture-react";
import { returnTrue } from "../../utils";
import { CalendarInterval } from "./CalendarInterval";
import { ICommonDatePickerProps, PREV_SYMBOL, NEXT_SYMBOL } from "./DatePicker.types";
import { getWeekStart, getWeekDayNames, getMonthDaysByWeek } from "./date-utils";
import styles from "./DatePicker.module.scss";

interface ICalendarViewProps extends ICommonDatePickerProps {
  month?: YearMonth;
  onHeaderClick?: MouseEventHandler;
  range?: CalendarInterval;
  validateDate?: (dt: LocalDate) => boolean;
  disabled?: boolean;
}

const getInitialMonth = (month?: YearMonth, date?: LocalDate) => {
  if (month) {
    return month;
  }

  const dt = date || LocalDate.now();
  return YearMonth.of(dt.year(), dt.monthValue());
};

export const CalendarView: FunctionComponent<ICalendarViewProps> = ({
  month,
  onHeaderClick,
  onValueChange,
  value,
  range,
  validateDate = returnTrue,
  weekStart,
  disabled
}) => {
  const { locale } = useLocalization();
  const [selectedMonth, setSelectedMonth] = useState(() => getInitialMonth(month, value!));

  const firstDateOfMonth = useMemo(() => selectedMonth.atDay(1), [selectedMonth]);
  const firstDayOfWeek = useMemo(() => getWeekStart(locale, weekStart), [locale, weekStart]);
  const weekDayNames = useMemo(() => getWeekDayNames(locale, firstDayOfWeek), [locale, firstDayOfWeek]);
  const weekDates = useMemo(() => getMonthDaysByWeek(firstDateOfMonth, firstDayOfWeek), [
    firstDateOfMonth,
    firstDayOfWeek
  ]);

  useEffect(() => {
    setSelectedMonth(month!);
  }, [month]);

  const handleSelectedDay = (date: LocalDate) => {
    if (disabled || !validateDate(date)) {
      return;
    }

    onValueChange?.(date);
  };

  return (
    <div className={styles.gridContainer} data-testid="plex-datetimepicker-container-calendar-view">
      {disabled && <div className={styles.disabledOverlay} />}
      <table className={styles.grid} data-testid="plex-datetimepicker-grid">
        <thead>
          <tr>
            <th
              className={styles.headerButton}
              onClick={() => setSelectedMonth(m => m.minusMonths(1))}
              data-testid="plex-datetimepicker-header-button-prev"
            >
              {PREV_SYMBOL}
            </th>
            <th
              className={styles.headerButton}
              colSpan={5}
              onClick={onHeaderClick}
              data-testid="plex-datetimepicker-header-button-title"
            >
              <DateLiteral value={firstDateOfMonth} options="yyyy MMMM" />
            </th>
            <th
              className={styles.headerButton}
              onClick={() => setSelectedMonth(m => m.plusMonths(1))}
              data-testid="plex-datetimepicker-header-button-next"
            >
              {NEXT_SYMBOL}
            </th>
          </tr>
          <tr>
            {weekDayNames.map((day: string, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={i} className={styles.dayHeader}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weekDates.map((week: LocalDate[], i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              {week.map(date => (
                <td
                  data-testid="plex-datetimepicker-day"
                  key={date.toString()}
                  className={clsx({
                    [styles.day]: true,
                    [styles.inactive]: date.monthValue() !== selectedMonth.monthValue(),
                    [styles.active]: date.equals(value),
                    [styles.inRange]: range?.contains(date),
                    [styles.invalidRange]: !validateDate(date)
                  })}
                >
                  <button type="button" onClick={() => handleSelectedDay(date)}>
                    {date.dayOfMonth()}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
