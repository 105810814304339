import React, { FC, useState, useEffect, useCallback, useLayoutEffect } from "react";
import { Form, InputSize, TextInput, Popover, Dropdown } from "@plex/react-components";
import { Node, useReactFlow } from "reactflow";

import { BaseInputOutput, Condition } from "../NodeTypes/TypeDefinitions";
import "./SwitchInputOutputCondition.scss";

export type SwitchInputOutputConditionProp = BaseInputOutput & {
  node: Node;
};

type Selection = { key: string; value: string };
type UICondition = Condition & {
  prefixCriteria: Selection[];
  suffixCriteria: Selection[];
};

export const SwitchInputOutputCondition: FC<SwitchInputOutputConditionProp> = (params) => {
  const defaultCondition: UICondition = {
    condition: "==",
    outputValue: "",
    inputValue: "",
    linker: "",
    prefixCriteria: [{ key: "==", value: "==" }],
    suffixCriteria: [{ key: "", value: "" }]
  };

  const reactFlowInstance = useReactFlow();
  const [currentNode, setCurrentNode] = useState(params.node);
  const [defConditions, setDefConditions] = useState<Condition[]>(params.conditions ?? []);
  const [inputID, setInputId] = useState(params.uniqueId);

  const uiConditions = useCallback(() => {
    let mapedUI: UICondition[] | undefined = defConditions?.map((x) => {
      const mymap: UICondition = { ...x, prefixCriteria: [], suffixCriteria: [] };
      if (x.condition != "") {
        mymap.prefixCriteria = [{ key: x.condition, value: x.condition }];
      }
      if (x.linker != "") {
        mymap.suffixCriteria = [{ key: x.linker, value: x.linker }];
      }
      return mymap;
    });
    if (mapedUI.length == 0) {
      return [defaultCondition];
    }
    return mapedUI;
  }, [defConditions]);

  const updateNodesWithConditions = (tConditions: Condition[]) => {
    //update current node property conditions
    let currentNodes = reactFlowInstance.getNodes();
    const forceUpdate: any[] = [];
    currentNodes.forEach((findNode) => {
      if (findNode.id == currentNode.id) {
        findNode!.position.x += 0.0001;
        if (findNode.data.nodeProperties.inputs) {
          findNode.data.nodeProperties.inputs.forEach((curInput: BaseInputOutput) => {
            if (curInput.uniqueId == params.uniqueId) {
              curInput.conditions = [];
              tConditions.forEach((cond: Condition) => {
                curInput.conditions?.push(cond);
              });
            }
          });
        }
        if (findNode.data.nodeProperties.outputs) {
          findNode.data.nodeProperties.outputs.forEach((curInput: BaseInputOutput) => {
            if (curInput.uniqueId == params.uniqueId) {
              curInput.conditions = [];
              tConditions.forEach((cond: Condition) => {
                curInput.conditions?.push(cond);
              });
            }
          });
        }
      }
      forceUpdate.push(findNode);
    });
    reactFlowInstance.setNodes(forceUpdate);
    setCurrentNode({ ...currentNode });
  };

  const updateConditionsById = () => {
    //update current node property conditions
    var conditionsFound = false;
    params.node.data.nodeProperties.inputs?.forEach((curInput: BaseInputOutput) => {
      if (curInput.uniqueId == params.uniqueId) {
        setDefConditions(curInput.conditions ?? []);
        conditionsFound = true;
      }
    });
    params.node.data.nodeProperties.outputs?.forEach((curInput: BaseInputOutput) => {
      if (curInput.uniqueId == params.uniqueId) {
        if (!conditionsFound) {
          setDefConditions(curInput.conditions ?? []);
        }
      }
    });
  };

  useEffect(() => {
    var mapedUI = uiConditions().map((x) => {
      const mymap: UICondition = { ...x, prefixCriteria: [], suffixCriteria: [] };
      return mymap;
    });
    updateNodesWithConditions(mapedUI);
  }, [defConditions]);

  //Cause Node Update to refresh screen
  const [visible, setVisible] = useState(false);
  const anchor = <a onClick={() => setVisible((v) => !v)}>Switch Help</a>;
  const content = (
    <div style={{ padding: "10px" }}>
      The results of the switch are based upon the input and output conditions.
      <br />
      If a given input's conditions are true - it can be passed to the proper output..
      <br />
      if the output conditions are true then the output value is returned
      <br />
    </div>
  );
  // const [tabIndex, setTabIndex] = useState(0);

  const updateConditions = (condition: UICondition, indx: number) => {
    var conditions = uiConditions();
    conditions.splice(indx, 1, condition);
    const mynewCon = conditions.map((x) => {
      return x;
    });
    setDefConditions(mynewCon);
  };

  const removeConditiion = (indx: number) => {
    var conditions = uiConditions();
    conditions.splice(indx, 1);
    const mynewCon = conditions.map((x) => {
      return x;
    });
    setDefConditions(mynewCon);
  };

  const addNewCondition = (condition: UICondition, indx: number) => {
    var conditions = uiConditions();
    conditions.splice(indx, 1, condition);
    const mynewCon = conditions.map((x) => {
      return x;
    });
    mynewCon.push(defaultCondition);
    setDefConditions(mynewCon);
  };

  useLayoutEffect(() => {
    if (inputID != params.uniqueId) {
      updateConditionsById();
      setInputId(params.uniqueId);
    }
  }, [params]);

  return (
    <Form className="switch-input-properties-form">
      <div className="switchHelpText" style={{ float: "right" }}>
        <Popover anchor={anchor} visible={visible}>
          {content}
        </Popover>
      </div>
      <div> &nbsp;</div>
      <div className="container">
        <div className="containerItem">Name:</div>
        <div className="containerItem">{params.name}</div>
      </div>
      <div className="inputContainer">
        {uiConditions().map((property: UICondition, indx: number) => {
          return (
            <Form.Row key={"cond_" + indx}>
              <Dropdown
                items={[
                  { key: "==", value: "==" },
                  { key: "!=", value: "!=" },
                  { key: ">", value: ">" },
                  { key: "<", value: "<" },
                  { key: ">=", value: ">=" },
                  { key: "<=", value: "<=" }
                ]}
                keySelector={(item: { [key: string]: string }) => item.key}
                displaySelector={(item: { [key: string]: string }) => item.value}
                selected={property.prefixCriteria}
                onSelectionChanged={(values: any) => {
                  property.condition = values[0].value;
                  property.prefixCriteria = [{ key: values[0].key, value: values[0].value }];
                  updateConditions(property, indx);
                }}
                includeEmpty={true}
              />
              <TextInput
                className="conditionInput"
                value={property.outputValue}
                size={InputSize.medium}
                onChange={(e) => {
                  property.outputValue = e.currentTarget.value;
                  updateConditions(property, indx);
                }}
              />
              <Dropdown
                items={[
                  { key: "AND", value: "AND" },
                  { key: "OR", value: "OR" }
                ]}
                keySelector={(item: { [key: string]: string }) => item.key}
                displaySelector={(item: { [key: string]: string }) => item.value}
                selected={property.suffixCriteria}
                onSelectionChanged={(values: any) => {
                  if (values.length == 0 && uiConditions().length > 1) {
                    // Remove Condition from the list
                    removeConditiion(indx);
                  } else if (property.linker != values[0].value) {
                    if (property.linker == "") {
                      // Add a new row to the Conditions
                      property.linker = values[0].value;
                      property.suffixCriteria = [{ key: values[0].key, value: values[0].value }];
                      addNewCondition(property, indx);
                    } else {
                      property.linker = values[0].value;
                      property.suffixCriteria = [{ key: values[0].key, value: values[0].value }];
                      updateConditions(property, indx);
                    }
                  }
                }}
                includeEmpty={true}
              />
            </Form.Row>
          );
        })}
      </div>
    </Form>
  );
};
