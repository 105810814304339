import React, { FunctionComponent } from "react";
import { useLocalization } from "@plex/culture-react";
import clsx from "clsx";
import { ICommonProps, MaybeAsync } from "../Common.types";
import { useAsync } from "../../hooks";
import { IconColor, IIcon } from "./IconPicker.types";
import styles from "./IconPicker.module.scss";

// TODO: move this into brand library
const StandardPalette = [
  "#0096d5", // primaryBlue
  "#2e2f3b", // accentNavyBlue
  "#57626e", // primaryGray
  "#e3e8e8",
  "#e2870f", // presentationOrange
  "#b6be00", // presentationGreen
  "#93d3df", // accentLightBlue
  "#0971a1", // primaryMediumBlue
  "#334069" // accentDarkBlue
];

const StandardIconColors: IconColor[] = StandardPalette.map(color => ({
  color
}));

export interface IIconPickListProps extends ICommonProps {
  selectedIcon: IIcon;
  colors?: IconColor[];
  data: MaybeAsync<IIcon[]>;
  onSelectionChanged: (icon: IIcon) => void;
}

interface IIconEntryProps {
  selectedIcon: IIcon;
  icon: IIcon;
  onSelected: (icon: IIcon) => void;
}

const IconEntry: FunctionComponent<IIconEntryProps> = ({ icon, selectedIcon, onSelected, children }) => {
  return (
    <div className={clsx(styles.picklistItemSelect, icon.key === selectedIcon.key && styles.picklistItemSelected)}>
      <div className={styles.picklistItem}>
        <i onClick={() => onSelected(icon)}>
          {children}
          <span className={styles.picklistItemName}>{icon.name}</span>
        </i>
      </div>
    </div>
  );
};

interface IColorEntryProps {
  color: IconColor;
  selectedColor?: IconColor;
  onSelected: (color: IconColor) => void;
}

const ColorEntry: FunctionComponent<IColorEntryProps> = ({ color, selectedColor, onSelected }) => {
  const key = color.className || color.color;
  const selectedKey = selectedColor?.className || selectedColor?.color;

  return (
    <div
      className={clsx(
        styles.picklistBackgroundItem,
        color.className,
        selectedKey && key === selectedKey && styles.picklistItemSelected
      )}
      style={{ backgroundColor: color.color }}
      onClick={() => onSelected(color)}
    />
  );
};

export const IconPickerModal: FunctionComponent<IIconPickListProps> = ({
  selectedIcon,
  colors,
  data,
  onSelectionChanged,
  ...other
}) => {
  const { t } = useLocalization();
  const [icons] = useAsync(data, []);
  const colorSelections = colors || StandardIconColors;

  const handleIconSelection = (icon: IIcon) => {
    onSelectionChanged({ ...icon, color: selectedIcon.color });
  };

  const handleColorSelection = (color: IconColor) => {
    onSelectionChanged({ ...selectedIcon, color });
  };

  return (
    <div {...other}>
      <div className={styles.picklist}>
        {(selectedIcon.key || selectedIcon.color) && (
          <div className={styles.picklistSelected}>
            <div className={styles.picklistPaddedText}>{t("ui-common-iconPicker-heading-selectedIcon")}</div>
            <div
              className={clsx(styles.picklistSelectWrapper, selectedIcon?.color?.className)}
              style={{ backgroundColor: selectedIcon?.color?.color }}
            >
              <div className={styles.picklistSelect} data-testid="plex-icon-picker-selected-image">
                {selectedIcon.node}
              </div>
            </div>
          </div>
        )}
        <div>
          <div className={styles.picklistPaddedText}>{t("ui-common-iconPicker-heading-selectIcon")}</div>
          <div className={styles.picklistItemRow}>
            {icons.map(icon => (
              <IconEntry key={icon.key} icon={icon} selectedIcon={selectedIcon} onSelected={handleIconSelection}>
                {icon.node}
              </IconEntry>
            ))}
          </div>
        </div>
        <div>
          <div className={styles.picklistPaddedText}>{t("ui-common-iconPicker-heading-selectIconBackground")}</div>
          <div className={styles.picklistBackgroundRow}>
            {colorSelections.map(color => (
              <ColorEntry
                key={color.className || color.color}
                color={color}
                selectedColor={selectedIcon.color}
                onSelected={handleColorSelection}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
