import { Node, Edge } from "reactflow";
import {
  IFlowOrderError,
  IFlowRunResponse,
  getControlOutputEdges,
  getDataInputs,
  getDataOutputs
} from "./RuntimeExecution";

export interface IFlowValidateRequest {
  nodes: Node[];
  edges: Edge[];
}

export const validateOrder = (request: IFlowValidateRequest): IFlowOrderError[] => {
  const orderErrors: IFlowOrderError[] = [];
  let latestControlPathId: number = 0;
  let visitedNodeIds: string[] = [];
  // TODO Some problem with bundling at time of rollup
  // let pathAncestors: { [pathId: string]: { ancestorPaths: { pathId: number; endControlOrder: number }[] } } = {};
  let pathAncestors: any = {};

  const visitNode = (
    fromNode: Node | null,
    node: Node,
    fromControl: boolean,
    controlPath: number,
    controlOnly: boolean,
    loopStartedFromNode?: string
  ) => {
    let dataInputs = getDataInputs(node.id, request.nodes, request.edges);
    let dataOutputs = getDataOutputs(node.id, request.nodes, request.edges);
    let controlOutputEdges = getControlOutputEdges(node.id, node.type!, request.nodes, request.edges);
    let outputData: any = {};
    const direction = fromControl ? 1 : -1;

    if (fromControl) {
      if (node.data.nodeProperties.controlPath === undefined) {
        node.data.nodeProperties.controlPath = controlPath;
        node.data.nodeProperties.insideLoopNodeId =
          loopStartedFromNode ?? fromNode?.data.nodeProperties.insideLoopNodeId;
      }
      if (controlOnly) {
        // Check if control is going to an ancestor, which would form a erroneous cycle in the graph.
        const isAncestor =
          fromNode?.data.nodeProperties.controlPath !== node?.data.nodeProperties.controlPath &&
          pathAncestors[fromNode?.data.nodeProperties.controlPath] &&
          pathAncestors[fromNode?.data.nodeProperties.controlPath].ancestorPaths.filter(
            (path) => path.pathId === node?.data.nodeProperties.controlPath
          ).length !== 0;
        const isAlreadyOnPath =
          fromNode?.data.nodeProperties.controlPath === node?.data.nodeProperties.controlPath &&
          fromNode?.data.nodeProperties.controlOrder > node?.data.nodeProperties.controlOrder;
        if (isAncestor || isAlreadyOnPath) {
          orderErrors.push({ fromNode: fromNode ?? node, toNode: node });
          return;
        }

        if (node.data.nodeProperties.controlOrder === undefined) {
          if (node.data.nodeProperties.controlPath === fromNode?.data.nodeProperties.controlPath) {
            node.data.nodeProperties.controlOrder = direction + (fromNode?.data.nodeProperties.controlOrder ?? 0);
          } else {
            // Reset control order when branching onto a different control path.
            node.data.nodeProperties.controlOrder = 1;
          }
        }
      }
      // Data order will reset when control order changes, since data order is a child of control order.
      node.data.nodeProperties.dataOrder = 0;
    } else {
      const fromControlPath: number = fromNode?.data.nodeProperties.controlPath ?? 1;

      if (dataOutputs.length > 1) {
        // Do not process this node now, wait until all the other data output nodes are processed first.
        // This way we have all of their control paths to find the first common ancestor path.
        if (
          dataOutputs.filter((dataOutput) => dataOutput.sourceNode.data.nodeProperties.controlPath === undefined)
            .length !== 0
        ) {
          node.data.nodeProperties.validationWaitingOnInput = true;
          return;
        }

        node.data.nodeProperties.validationWaitingOnInput = false;

        // Find first common path and assign it
        const sourceAncestors: any = dataOutputs.map(
          (output) => pathAncestors[output.sourceNode.data.nodeProperties.controlPath]
        );
        let commonAncestor: { pathId: number; endControlOrder: number } | null = null;
        for (let ancestorIndex = sourceAncestors[0].ancestorPaths.length - 1; ancestorIndex >= 0; ancestorIndex--) {
          const ancestor = sourceAncestors[0].ancestorPaths[ancestorIndex];
          const commonAncestors = sourceAncestors.filter(
            (ancestors) => ancestors.ancestorPaths.filter((path) => path.pathId === ancestor.pathId).length !== 0
          );
          if (commonAncestors.length === sourceAncestors.length) {
            commonAncestor = ancestor;
            break;
          }
        }

        if (commonAncestor === null) {
          // This should not happen, because we start from a single common node and path which is the start node.
          throw new Error("Paths do not derive from any common ancestor.");
        }

        const outputToCommonPath = dataOutputs
          .filter((o) => o.sourceNode.data.nodeProperties.controlPath === commonAncestor?.pathId)
          .sort(
            (a, b) => a.sourceNode.data.nodeProperties.controlOrder - b.sourceNode.data.nodeProperties.controlOrder
          )[0];

        if (outputToCommonPath) {
          if (node.data.nodeProperties.controlPath === undefined) {
            node.data.nodeProperties.controlPath = outputToCommonPath.sourceNode.data.nodeProperties.controlPath;
            node.data.nodeProperties.insideLoopNodeId =
              loopStartedFromNode ?? outputToCommonPath.sourceNode?.data.nodeProperties.insideLoopNodeId;
          }
          if (node.data.nodeProperties.controlOrder === undefined) {
            node.data.nodeProperties.controlOrder = outputToCommonPath.sourceNode.data.nodeProperties.controlOrder;
            node.data.nodeProperties.insideLoopNodeId =
              loopStartedFromNode ?? outputToCommonPath.sourceNode?.data.nodeProperties.insideLoopNodeId;
          }
        } else {
          node.data.nodeProperties.controlPath = commonAncestor.pathId;
          node.data.nodeProperties.controlOrder = commonAncestor.endControlOrder;
          node.data.nodeProperties.insideLoopNodeId =
            loopStartedFromNode ?? fromNode?.data.nodeProperties.insideLoopNodeId;
        }
      } else {
        if (node.data.nodeProperties.controlPath === undefined) {
          node.data.nodeProperties.controlPath = fromControlPath;
          node.data.nodeProperties.insideLoopNodeId =
            loopStartedFromNode ?? fromNode?.data.nodeProperties.insideLoopNodeId;
        }

        // Control order will remain the same when not moving from control.
        if (node.data.nodeProperties.controlOrder === undefined) {
          node.data.nodeProperties.controlOrder = fromNode!.data.nodeProperties.controlOrder;
        }
      }

      // Check if node was an ancestor.
      if (
        fromNode &&
        fromNode?.data.nodeProperties.controlPath !== node.data.nodeProperties.controlPath &&
        (!pathAncestors[fromNode?.data.nodeProperties.controlPath] ||
          pathAncestors[fromNode?.data.nodeProperties.controlPath].ancestorPaths.filter(
            (path) => path.pathId === node.data.nodeProperties.controlPath
          ).length === 0)
      ) {
        orderErrors.push({ fromNode: fromNode ?? node, toNode: node });
        return;
      }
      if (
        node.data.nodeProperties.controlPath === fromNode?.data.nodeProperties.controlPath &&
        node.data.nodeProperties.controlOrder > fromNode?.data.nodeProperties.controlOrder
      ) {
        orderErrors.push({ fromNode: fromNode ?? node, toNode: node });
        return;
      }
      if (
        node.data.nodeProperties.controlPath === fromNode?.data.nodeProperties.controlPath &&
        node.data.nodeProperties.controlOrder === fromNode?.data.nodeProperties.controlOrder &&
        node.data.nodeProperties.dataOrder > fromNode?.data.nodeProperties.dataOrder
      ) {
        orderErrors.push({ fromNode: fromNode ?? node, toNode: node });
        return;
      }
      if (node.data.nodeProperties.dataOrder === undefined) {
        node.data.nodeProperties.dataOrder = direction + (fromNode?.data.nodeProperties.dataOrder ?? 0);
      }
    }

    // If we have already visited this node, it would just return the evaluation result when running.
    if (visitedNodeIds.indexOf(node.id) !== -1) {
      return;
    }

    visitedNodeIds.push(node.id);

    // Visit each node connected to data inputs
    if (!controlOnly) {
      for (let i = 0; i < dataInputs.length; i++) {
        let dataInput: any = dataInputs[i];
        visitNode(node, dataInput.targetNode, false, controlPath, controlOnly);
      }
    }

    // Need to update path end order when we reach the end without any branches, or add path for start.
    if (controlOnly && controlOutputEdges.length == 0) {
      const nextControlOrder = node.data.nodeProperties.controlOrder + 1;
      if (controlPath === 1) {
        pathAncestors["1"] = {
          ancestorPaths: [{ pathId: 1, endControlOrder: nextControlOrder }]
        };
      } else {
        pathAncestors[controlPath].ancestorPaths[pathAncestors[controlPath].ancestorPaths.length - 1].endControlOrder =
          nextControlOrder;
      }
    }

    // Visit each control path
    for (let i = 0; i < controlOutputEdges.length; i++) {
      let controlEdge: Edge & {
        originalTargetHandle: string;
        startsLoop: boolean;
      } = controlOutputEdges[i]!;
      let sourceNode = request.nodes.filter((n) => n.id === controlEdge.source)[0];
      if (sourceNode) {
        const nextControlOrder = node.data.nodeProperties.controlOrder + 1;
        let nextNodeControlPath = controlPath;

        // Branch off to a new path if there are multiple control edges
        if (controlOnly && controlOutputEdges.length > 1) {
          nextNodeControlPath = ++latestControlPathId;
        }
        if (nextNodeControlPath !== controlPath) {
          // Record path ancestry for new path to be parent ancestry plus current path
          if (!pathAncestors["1"]) {
            pathAncestors["1"] = { ancestorPaths: [{ pathId: 1, endControlOrder: nextControlOrder }] };
          }
          if (!pathAncestors[nextNodeControlPath]) {
            pathAncestors[nextNodeControlPath] = { ancestorPaths: [] };
          }
          let parentPathAncestorIds = pathAncestors[controlPath]?.ancestorPaths ?? [
            { pathId: controlPath, endControlOrder: nextControlOrder }
          ];
          pathAncestors[controlPath].ancestorPaths[
            pathAncestors[controlPath].ancestorPaths.length - 1
          ].endControlOrder = nextControlOrder;
          parentPathAncestorIds = parentPathAncestorIds.concat([{ pathId: nextNodeControlPath, endControlOrder: -1 }]);
          pathAncestors[nextNodeControlPath].ancestorPaths =
            pathAncestors[nextNodeControlPath].ancestorPaths.concat(parentPathAncestorIds);
        }
        visitNode(
          node,
          sourceNode,
          true,
          nextNodeControlPath,
          controlOnly,
          controlEdge.startsLoop ? node.id : undefined
        );
      }
    }

    return outputData;
  };

  const startNode = request.nodes.filter((n) => n.type === "start")[0];

  if (startNode) {
    request.nodes.forEach((n) => {
      delete n.data.nodeProperties.controlPath;
      delete n.data.nodeProperties.controlOrder;
      delete n.data.nodeProperties.dataOrder;
      delete n.data.nodeProperties.insideLoopNodeId;
    });
    pathAncestors = {};
    latestControlPathId = 0;
    visitedNodeIds = [];
    visitNode(null, startNode, true, ++latestControlPathId, true);
    latestControlPathId = 0;
    visitedNodeIds = [];
    visitNode(null, startNode, true, ++latestControlPathId, false);
  }

  const waitingNodes = request.nodes.filter((n) => n.data.nodeProperties.validationWaitingOnInput === true);
  waitingNodes.forEach((node) => {
    if (node !== null && node.data.nodeProperties.validationWaitingOnInput === true) {
      const outputs = getDataOutputs(node.id, request.nodes, request.edges);
      const unvisitedOutput = outputs.filter((o) => o.sourceNode.data.nodeProperties.controlPath === undefined)[0];
      if (unvisitedOutput) {
        orderErrors.push({ fromNode: unvisitedOutput.sourceNode, toNode: node });
      }
    }
  });

  return orderErrors;
};

export const getMissingInputErrorMessages = (
  validationResult: IFlowRunResponse,
  consolidateToSingle: boolean = false
) => {
  if (validationResult.missingRequiredInput.length > 0) {
    const missingInputsByNode = validationResult.missingRequiredInput.reduce(
      (acc, { node, inputName }) => {
        const nodeId = node.id;
        if (!acc[nodeId]) {
          acc[nodeId] = [];
        }
        acc[nodeId]?.push(inputName);
        return acc;
      },
      {} as { [label: string]: string[] }
    );

    const numberOfNodes = Object.keys(missingInputsByNode).length;
    const nodeMessages = Object.entries(missingInputsByNode).map(([nodeId, inputNames]) => {
      const node = validationResult.missingRequiredInput.find((r) => r.node.id === nodeId)?.node;
      const label = node?.data.nodeProperties.name ?? node?.data.nodeProperties.label;
      return {
        message: `The "${label}" node requires connections to the following handles: ${inputNames.join(", ")}.`,
        nodeId: nodeId
      };
    });

    if (consolidateToSingle) {
      if (numberOfNodes > 1) {
        const labels: string[] = [];
        Object.keys(missingInputsByNode).forEach((nodeId) => {
          const node = validationResult.missingRequiredInput.find((r) => r.node.id === nodeId)?.node!;
          const label = node.data.nodeProperties.name ?? node.data.nodeProperties.label;
          if (!labels.includes(label)) {
            labels.push(label);
          }
        });
        return [
          {
            message: `The following nodes require connections to input or output handles: ${labels.join(", ")}`,
            nodeId: validationResult.missingRequiredInput[0]?.node.id
          }
        ];
      }

      return [{ message: nodeMessages.map((nm) => nm.message).join(", "), nodeId: nodeMessages[0]?.nodeId }];
    }

    return nodeMessages;
  }

  return [];
};
