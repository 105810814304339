import React, { FunctionComponent } from "react";
import { Edge, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Checkbox, Form } from "@plex/react-components";
import { camelCaseToSpacesRegEx } from "./FormConstants";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { IDataParam } from "../NodeTypes/Base";
import "./NodePropertiesForm.scss";
import { forceNodeUpdate } from "../Util/NodeUtil";

export interface IOutputsFormSectionProps extends INodeConfigPropertiesProps {
  onEdgeDelete: (edge: Edge) => void;
}

export const EnableOutputsFormSection: FunctionComponent<IOutputsFormSectionProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  const outputs: { [name: string]: IDataParam } = props.node?.data.nodeProperties.outputs;

  if (!outputs || Object.keys(outputs).length === 0) {
    return <></>;
  }

  return (
    <Form.Section title={props.sectionName} key={props.sectionName}>
      <div className="input-select-section enable-io-formsection flowinput-scroll-container" style={{ flexGrow: 1 }}>
        {Object.keys(outputs).map((outputName: string) => {
          const output: IDataParam = outputs[outputName]!;
          const readableType = output.type?.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5")?.toLowerCase();
          let readableOutputName = outputName;
          readableOutputName = readableOutputName.charAt(0).toUpperCase() + readableOutputName.slice(1);
          readableOutputName = readableOutputName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");

          readableOutputName = readableOutputName.replaceAll("_", " ");

          // Use label if available
          if (output.label) {
            readableOutputName = output.label;
          }

          return (
            <Form.FieldPair labelText={readableOutputName} key={outputName}>
              <div style={{ display: "flex", marginTop: "8px" }}>
                <Checkbox
                  checked={props.node?.data.nodeProperties.outputs[outputName].enabled === true}
                  disabled={false}
                  onChange={(e) => {
                    props.node!.data.nodeProperties.outputs[outputName].enabled = e.currentTarget.checked;
                    if (e.currentTarget.checked === false) {
                      let deletedEdges = reactFlowInstance
                        .getEdges()
                        .filter(
                          (e) =>
                            e.target === props.node!.id && e.targetHandle === outputName + "-" + output.type + "-output"
                        );
                      reactFlowInstance.setEdges((edges) =>
                        edges.filter((e) => deletedEdges.filter((de) => de.id === e.id).length === 0)
                      );
                      props.onEdgeDelete(deletedEdges[0]!);
                      deletedEdges.forEach((e: Edge<any>) => {
                        forceNodeUpdate(reactFlowInstance.getNode(e.source)!, updateNodeInternals, reactFlowInstance);
                      });
                    }
                    setState({ value: props.node!.data.nodeProperties.outputs[outputName].enabled });
                    props.forceNodeUpdate();
                  }}
                />
                <div className="enable-io-formsection-readabletype">{readableType}</div>
              </div>
            </Form.FieldPair>
          );
        })}
      </div>
    </Form.Section>
  );
};
