import React, { forwardRef, RefObject } from "react";
import clsx from "clsx";
import { ReactComponentLike } from "prop-types";
import { withPrinting } from "@plex/react-xml-renderer";
import { ICommonProps } from "../Common.types";
import styles from "./Text.module.scss";

export interface ITextProps extends ICommonProps {
  /** The associated element ID */
  labelFor?: string;
  /** The element text */
  text?: string;
  /**
   * The type of element to use to render the text.
   * If not provided, the text will render as a <label>
   * if the `labelFor` property is set, otherwise it
   * will render as a <span>.
   */
  as?: ReactComponentLike;
}

const HtmlText = forwardRef<HTMLElement, ITextProps>(({ className, labelFor, text, as, ...other }, ref) => {
  let classes = clsx(className, styles.base);

  if (labelFor || as === "label") {
    return (
      <label htmlFor={labelFor} className={classes} ref={ref as RefObject<HTMLLabelElement>} {...other}>
        {text}
      </label>
    );
  }

  classes = clsx(classes, styles.readonlyLabel);
  const Component = as || "span";

  return (
    <Component className={classes} ref={ref} {...other}>
      {text}
    </Component>
  );
});
HtmlText.displayName = "Text";

const XmlText = ({ text }: ITextProps) => {
  return React.createElement("plex-control-label", {}, String(text || ""));
};

export const Text = withPrinting(HtmlText, XmlText);
