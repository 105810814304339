import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { ComparisonLabel } from "./ComparisonLabel";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { NumberCompareOperation } from "../../FlowDocument/FlowDocumentModel";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CompareDateNode",
  category: NodeTypeCategory.logic,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.DATETIME, hideLabel: true },
    { name: "right", type: DataType.DATETIME, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  nodeConfigProperties: [
    {
      name: "operation",
      label: "Comparison",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: NumberCompareOperation }
    },
    {
      name: "schema",
      label: "Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: {
        dataTypes: [DataType.DATETIME, DataType.DATE, DataType.TIME],
        dataProperties: ["left", "right"]
      }
    }
  ],
  evaluate: (input, nodeProperties) => {
    let result = false;
    const leftTime = new Date(input.left as string).getTime();
    const rightTime = new Date(input.right as string).getTime();
    switch (nodeProperties.operation as NumberCompareOperation) {
      case NumberCompareOperation.Equal:
        result = leftTime === rightTime;
        break;
      case NumberCompareOperation.NotEqual:
        result = leftTime !== rightTime;
        break;
      case NumberCompareOperation.Less:
        result = leftTime < rightTime;
        break;
      case NumberCompareOperation.LessOrEqual:
        result = leftTime <= rightTime;
        break;
      case NumberCompareOperation.Greater:
        result = leftTime > rightTime;
        break;
      case NumberCompareOperation.GreaterOrEqual:
        result = leftTime >= rightTime;
        break;
    }
    return {
      output: {
        result: result
      },
      activeControlHandleIds: []
    };
  }
});

export const CompareDateNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return (
    <BasicNodeLayout id={id} data={data}>
      <ComparisonLabel nodeId={id} comparisonOperator={data.nodeProperties.operation} />
    </BasicNodeLayout>
  );
};
