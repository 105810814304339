import React, { FunctionComponent } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { EdgeDetail } from "../../Util/EdgeUtil";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { NumberArithmeticOperation } from "../../FlowDocument/FlowDocumentModel";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "NumberArithmeticNode",
  label: "Math",
  category: NodeTypeCategory.numbers,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.DECIMAL, hideLabel: true },
    { name: "right", type: DataType.DECIMAL, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.DECIMAL }],
  nodeConfigProperties: [
    {
      name: "operation",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: NumberArithmeticOperation }
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return {
      output: { result: (input.left as number) + (input.right as number) },
      activeControlHandleIds: []
    };
  }
});

export const NumberArithmeticNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const reactFlowInstance = useReactFlow();
  const edgeDetail = new EdgeDetail(id, reactFlowInstance);

  const labelLeft = edgeDetail.getInputTargetLabel("left");
  const labelRight = edgeDetail.getInputTargetLabel("right");
  const label = `${labelLeft} ${getOptionLabel(data.nodeProperties.operation) ?? ""} ${labelRight}`;

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
