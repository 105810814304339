import React, { FunctionComponent, PropsWithChildren } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import { BannerStatus } from "./BannerContext";
import styles from "./Banner.module.scss";

export interface IPersistentBannerProps extends PropsWithChildren<ICommonProps> {
  /** The banner status */
  status?: BannerStatus;
}

/** Renders a banner with the given message and status. */
export const PersistentBanner: FunctionComponent<IPersistentBannerProps> = ({
  className,
  status,
  children,
  ...other
}) => {
  let messageStyle = styles.bannerInfo;
  switch (status) {
    case BannerStatus.error:
      messageStyle = styles.bannerError;
      break;
    case BannerStatus.warning:
      messageStyle = styles.bannerWarning;
      break;
    case BannerStatus.success:
      messageStyle = styles.bannerSuccess;
      break;
    default:
      messageStyle = styles.bannerInfo;
      break;
  }
  return (
    <div className={clsx(styles.bannerPersistentMessage, messageStyle, className)} {...other}>
      {children}
    </div>
  );
};
