import { BaseInputOutput, DataType, SourceAPI, isObjectLike } from "../TypeDefinitions";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { getSchemaId } from "../../NodeTypes/DataSchemas";

export type DataSourceApiSpecs = {
  Categories: DataSourceApiCategory[];
};

export type DataSourceApiCategory = {
  name: string;
  apis: SourceAPI[];
};

let dataSourceApiSpecs: any = {
  Categories: [
    {
      name: "Commands",
      apis: []
    } as DataSourceApiCategory,
    {
      name: "Query",
      apis: []
    } as DataSourceApiCategory
  ]
};

const newID = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const getDsApiInputs = (category: string, apiTitle: string, apiId: string) => {
  let parameters: BaseInputOutput[] = [];
  dataSourceApiSpecs.Categories.filter((c) => c.name === category)[0].apis.map((api) => {
    if (api.id === apiId) {
      parameters = api.inputs.map((input) => {
        return {
          uniqueId: "",
          name: input.name,
          type: input.type,
          required: input.required,
          nullable: input.nullable,
          conditions: undefined
        } as BaseInputOutput;
      });
    }
  });
  parameters = parameters.map((p) => {
    return {
      name: p.name,
      type: p.type,
      schemaId: getSchemaId(p.type),
      isArray: p.isArray,
      required: p.required,
      enabled: false,
      nullable: p.nullable,
      conditions: [],
      uniqueId: newID(),
      expressions: undefined
    };
  });
  return parameters;
};

export const getDsApiOutputs = (
  category: string,
  apiId: string,
  columnProperties = false,
  outputProperties = false
) => {
  let parameters: BaseInputOutput[] = [];
  dataSourceApiSpecs.Categories.filter((c) => c.name === category)[0].apis.map((api) => {
    if (api.id === apiId) {
      if (columnProperties) {
        parameters = api.columns.map((output) => {
          return {
            uniqueId: "",
            name: output.name,
            type: output.type,
            required: output.required,
            nullable: output.nullable,
            conditions: undefined
          } as BaseInputOutput;
        });
      } else if (outputProperties) {
        parameters = api.outputs.map((output) => {
          return {
            uniqueId: "",
            name: output.name,
            type: output.type,
            required: output.required,
            nullable: output.nullable,
            conditions: undefined
          } as BaseInputOutput;
        });
      } else {
        if (api.outputs?.length > 0) {
          parameters = [
            {
              uniqueId: "",
              name: "outputs",
              type: "object",
              required: false,
              nullable: false,
              conditions: undefined
            } as BaseInputOutput
          ];
        }

        if (api.columns.length > 0 && parameters.filter((p) => p.name === "rows").length === 0) {
          parameters = parameters.concat([
            {
              uniqueId: "",
              name: "rows",
              type: "objectList",
              isArray: true,
              required: false,
              nullable: false,
              conditions: undefined
            } as BaseInputOutput
          ]);
        }
      }
    }
  });

  parameters = parameters.map((p) => {
    return {
      name: p.name,
      type: p.type,
      schemaId: !isObjectLike(p.type as DataType) ? getSchemaId(p.type) : "",
      isArray: p.isArray,
      required: false,
      enabled: false,
      nullable: p.nullable,
      conditions: [],
      uniqueId: newID(),
      expressions: undefined
    };
  });

  return parameters;
};

export interface IDsApiCredentials {
  userName: string;
  apiToken: string;
}

export const getDsApis = async (
  searchName: string
): Promise<{
  count: number;
  limitExceeded: boolean;
  items: [
    {
      id: string;
      name: string;
    }
  ];
}> => {
  const response = await fetch("/flows-api/designer/metadata/data-sources/search", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name: searchName
    } as any)
  });

  const results = await response.json();
  return results;
};

export const loadDsApi = async (id: string, bannerContext?: IBannerContext) => {
  if (!dataSourceApiSpecs.Categories[0].apis.filter((api) => api.id === id)[0]) {
    let result: any;
    try {
      const response = await fetch("/flows-api/designer/metadata/data-sources/schema/" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      result = await response.json();
      result.id = id;
      result.inputs = result.inputs.filter((i: any) => i.name !== "Plexus_Customer_No" && i.name !== "PCN");

      localStorage.setItem("dsApi_" + id, JSON.stringify(result));
    } catch {
      const localStorageDsApi = localStorage.getItem("dsApi_" + id);
      if (localStorageDsApi) {
        result = JSON.parse(localStorageDsApi);
      } else {
        bannerContext?.addMessage("An error occurred retrieving data source metadata.", BannerStatus.error);
      }
    }
    if (result) {
      dataSourceApiSpecs.Categories[0].apis.push(result);
    }
  }
};

export const setResultDataSourceApiSpec = (result: any) => {
  if (!dataSourceApiSpecs.Categories[0].apis.filter((api) => api.id === result.id)[0]) {
    dataSourceApiSpecs.Categories[0].apis.push(result);
  }
};

export const getDsApiType = (category: string, apiAction: string) => {
  let apiType = "read";
  dataSourceApiSpecs.Categories.filter((c) => c.name === category)[0].apis.map((api) => {
    if (api.name === apiAction && category == "Commands") {
      apiType = "modify";
    }
  });
  return apiType;
};

export const getDsApiId = (category: string, apiAction: string): string | undefined => {
  let id: string | undefined = undefined;
  dataSourceApiSpecs.Categories.filter((c) => c.name === category)[0].apis.map((api) => {
    if (api.name === apiAction) {
      id = api.id;
    }
  });
  return id;
};
