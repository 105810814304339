import React, { FC } from "react";
import styles from "./Wizard.module.scss";

/** Content for Wizard - should container PageList and Page components */
export const WizardContent: FC = ({ children }) => {
  return (
    <div data-testid="plex-wizard-content" className={styles.wizardContent}>
      {children}
    </div>
  );
};
