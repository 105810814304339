import React, { useContext, MouseEvent, MouseEventHandler } from "react";
import clsx from "clsx";
import { ICommonProps, IWithChildren } from "../Common.types";
import { Clickable } from "../Link";
import styles from "./ActionBar.module.scss";
import { DropdownContext } from "./ActionBar.Dropdown";

export interface IButtonProps extends ICommonProps, IWithChildren {
  /** The href for the link */
  href?: string;
  /** Mouse click handler for button */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /** If the button is disabled */
  disabled?: boolean;
  /** Display text */
  text?: string;
  /** Tooltip text */
  title?: string;
}

const ActionBarButton = React.forwardRef<HTMLLIElement, IButtonProps>(
  ({ href, onClick, className, disabled, text, id, children, ...other }, ref) => {
    const parentContext = useContext(DropdownContext);
    const classes = clsx(parentContext.menu && styles.menuBtnBase, !parentContext.menu && styles.btnBase, className);

    const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
      onClick?.(e);
      if (parentContext.visible) {
        parentContext.close();
      }
    };

    return (
      <li data-testid="plex-action-button" role="menuitem" ref={ref} className={classes} {...other}>
        <Clickable
          id={id}
          href={href}
          onClick={clickHandler}
          className={clsx(disabled && styles.disabled)}
          disabled={disabled}
          role="link"
        >
          {children}
          {!!text && text}
        </Clickable>
      </li>
    );
  }
);

ActionBarButton.displayName = "ActionBarButton";
export { ActionBarButton };
