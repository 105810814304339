import React, { FunctionComponent, useState, useReducer } from "react";
import { useLocalization } from "@plex/culture-react";
import { File7Icon, Search3Icon, CloseIcon, ArrowIcon } from "@plex/icons";
import { noop } from "@utils";
import { IconButton } from "@components/Button";
import clsx from "clsx";
import styles from "./FilePicker.module.scss";
import { FilePickerModal } from "./FilePickerModal";
import { FilePickerDragDropArea } from "./FilePickerDragDropArea";
import { createSearchReducer, createInitialSearchState } from "./FilePickerSearch";

export interface IFilePickerProps {
  /** The files which are selected. */
  selectedFiles: IFilePickerFile[];
  /**
   * Function is executed when a change occurs to the selected files.
   *
   * @remarks
   * When the selection dialog is closed this will be called when the user uploads a file.
   * When the selection dialog is open this will be called when the user clicks "OK", canceling
   * or closing the selection dialog will discard the changes to the selection.
   *
   * @param selectedFiles should be used as the new value for the selectedFiles prop.
   */
  onSelectionChanged: (selectedFiles: IFilePickerFile[]) => void;
  /** Function is executed when the picker dialog is opened for the first time or when the user initiates a search. */
  onSearch: (e: IFilePickerSearchEvent) => void;
  /** Function is executed when the user requests a file be added. */
  onFileUpload: (e: IFilePickerUploadEvent) => void;
  /**
   * Provides hints for browsers to guide users towards selecting the correct file types.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|accept}
   */
  accept?: string;
  /** Indicates that the picker supports multiple selections */
  multiSelect?: boolean;
}

export interface IFilePickerFile {
  /** A unique identifier for the file. */
  id: React.ReactText;
  /** The text that the user will use to identify and search for files. */
  name: string;
  /** The content type of the file. Used to determine the correct way to render the file, if at all. */
  contentType: string;
  /** A url which returns the content of the file. */
  url: string;
  /** A url which returns a smaller version of the file. */
  thumbnailUrl: string;
}

export interface IFilePickerUploadEvent {
  /** Files which the user intendeds to add. */
  files: File[];
  /**
   * The consumer should call this function for each file that is accepted. The new files should not
   * be added to selectedFiles prop as a result of this event. The onSelectionChanged function will
   * be called when it time for the files to be considered selected.
   */
  fileAccepted: (file: IFilePickerFile) => void;
  /**
   * The consumer should call this function for each file that is rejected. The reason is displayed alongside a generic
   * file rejection message.
   */
  fileRejected: (file: File, reason: string) => void;
}

export interface IFilePickerSearchEvent {
  /**
   * The text the user provided for their search. This text will be all lower case. The search should
   * not be case senstive. The search should return the file if the searchText is found anywhere in
   * the name property of IFilePickerFile.
   */
  searchText: string;
  /**
   * The consumer should call this function to provide an updated list of files for the given searchText.
   *
   * @param {IFilePickerFile[]} files The files to be displayed for selection, include only the selected files which match the {@link IFilePickerSearchEvent.searchText}.
   * @param {boolean} recordLimitExceeded Indiciates that the search results were truncated, and that a more data is available to more restrictive searchText.
   */
  searchComplete: (files: IFilePickerFile[], recordLimitExceeded: boolean) => void;
  /**
   * The consumer should call this function when the search fails for any reason.
   *
   * @param message The message to be displayed to the user.
   */
  searchFailed: (message: string) => void;
}

export const isPreviewableItem = (type: string) => {
  return type.startsWith("image");
};

interface IFilePreviewProps {
  file: IFilePickerFile;
  onDeselect: () => void;
  onExpand: () => void;
}

const FilePreview: FunctionComponent<IFilePreviewProps> = ({ file, onDeselect, onExpand }) => {
  return (
    <div className={styles.previewItem} key={file.id} data-testid="file-picker-preview-item">
      <div className={styles.previewItemContent}>
        {isPreviewableItem(file.contentType) ? (
          <img src={file.url} />
        ) : (
          <File7Icon className={styles.previewItemContentIcon} />
        )}
        <span onClick={onDeselect}>
          <CloseIcon className={clsx(styles.previewItemButton, styles.buttonDeselect)} />
        </span>
        <span onClick={onExpand}>
          <ArrowIcon className={clsx(styles.previewItemButton, styles.buttonExpand)} />
        </span>
      </div>
      <div className={styles.previewItemText}>{file.name}</div>
    </div>
  );
};

export const FilePicker: FunctionComponent<IFilePickerProps> = ({ ...props }) => {
  const { t } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { selectedFiles, onFileUpload, onSelectionChanged, multiSelect = false } = props;

  const [searchState, searchDispatch] = useReducer(createSearchReducer(), createInitialSearchState());

  const openFilePickerModal = () => {
    setIsDialogOpen(true);
  };

  const filePickerCloseHandler = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <FilePickerDragDropArea
          text={t("ui-common-filePicker-action-dropToUpload")}
          onFileUpload={filesToUpload => {
            // This local copy of selectedFiles is needed because fileAccepted events can happen synchronously,
            // not allowing the component to re-render with an updated selectedFiles prop.
            let currentSelected = [...selectedFiles];

            onFileUpload({
              files: filesToUpload,
              fileAccepted: file => {
                if (multiSelect) {
                  currentSelected = [...currentSelected, file];
                  onSelectionChanged([...currentSelected]);
                } else {
                  onSelectionChanged([file]);
                }

                searchDispatch({ type: "file-accepted", file });
              },
              fileRejected: noop
            });
          }}
          onClick={noop}
          multiple={multiSelect}
        >
          {selectedFiles.length > 0 && (
            <div className={styles.previewArea}>
              {selectedFiles.map(item => (
                <FilePreview
                  file={item}
                  onDeselect={() => {
                    onSelectionChanged(selectedFiles.filter(x => x !== item));
                  }}
                  onExpand={noop}
                  key={item.id}
                />
              ))}
              {Array.from(
                { length: selectedFiles.length % 3 === 0 ? 0 : 3 - (selectedFiles.length % 3) },
                (_, index) => {
                  return (
                    <div
                      className={styles.previewItem}
                      key={`placeholder_${index.toString()}`}
                      data-testid="file-picker-preview-item-placeholder"
                    />
                  );
                }
              )}
            </div>
          )}
        </FilePickerDragDropArea>

        <div className={styles.searchAdornment}>
          <IconButton onClick={openFilePickerModal} data-testid="plex-picker-icon">
            <Search3Icon />
          </IconButton>
        </div>
      </div>
      {isDialogOpen && (
        <FilePickerModal
          searchState={searchState}
          searchDispatch={searchDispatch}
          {...props}
          onClose={filePickerCloseHandler}
        />
      )}
    </>
  );
};
