import React, { FunctionComponent, useMemo } from "react";
import { LocalDate, LocalTime, Temporal } from "js-joda";
import { DatePicker } from "./DatePicker";
import { TimePicker } from "./TimePicker";
import { getDatePart, getTimePart } from "./date-utils";
import { ITimeProps, ICalendarProps, DateChangeHandler, DatePickerModes } from "./DatePicker.types";
import styles from "./DatePicker.module.scss";
import { CalendarInterval } from "./CalendarInterval";

interface IDateTimePickerProps extends ITimeProps, ICalendarProps {
  disabled?: boolean;
  value: Temporal | null;
  onDateChange?: DateChangeHandler<LocalDate>;
  onTimeChange?: DateChangeHandler<LocalTime>;
  validateDate?: (value: LocalDate) => boolean;
  range?: CalendarInterval;
  showing?: boolean;
  showDate?: boolean;
  showTime?: boolean;
  defaultInputMode?: DatePickerModes;
}

export const DateTimePicker: FunctionComponent<IDateTimePickerProps> = ({
  value,
  disabled,
  // date props
  weekStart,
  range,
  validateDate,
  onDateChange,
  // time props
  precision,
  use24HourTime,
  minuteStep,
  onTimeChange,
  // internal props
  showing = true,
  showDate = true,
  showTime = true,
  defaultInputMode = "day"
}) => {
  if (!showDate && !showTime) {
    // eslint-disable-next-line no-console
    console.warn("Not showing date OR time picker. This is likely a bug.");
  }

  const datePart = useMemo(() => getDatePart(value), [value]);
  const timePart = useMemo(() => getTimePart(value), [value]);

  return (
    <div className={styles.calendarContainer}>
      {showDate && (
        <DatePicker
          value={datePart}
          disabled={disabled}
          onValueChange={onDateChange}
          weekStart={weekStart}
          validateDate={validateDate}
          range={range}
          showing={showing}
          defaultInputMode={defaultInputMode}
        />
      )}
      {showTime && (
        <TimePicker
          value={timePart}
          onValueChange={onTimeChange}
          precision={precision}
          use24HourTime={use24HourTime}
          minuteStep={minuteStep}
          disabled={disabled}
        />
      )}
    </div>
  );
};
