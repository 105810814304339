import React, { FC, createElement } from "react";
import { withPrinting } from "@plex/react-xml-renderer";
import styles from "./DataTable.module.scss";
import { IDataTableCaptionProps } from "./DataTable.types";

const XmlDataTableCaption: FC<IDataTableCaptionProps> = ({ captionTitle, captionText }) => {
  if (!captionText) {
    return null;
  }

  // caption title only shows when printing...
  return createElement(
    "plex-grid-caption",
    {},
    <>
      {!!captionTitle && createElement("plex-grid-title", {}, captionTitle)}
      {createElement("plex-grid-message", {}, captionText)}
    </>
  );
};

/**
 * Component which renders the caption text for a DataTable. Should be rendered when
 * the table has no data with a user hint.
 */
const HtmlDataTableCaption: FC<IDataTableCaptionProps> = ({ captionText }) => {
  return captionText ? (
    <caption data-testid="plex-grid-caption" className={styles.emptyMessage}>
      {captionText}
    </caption>
  ) : null;
};

export const DataTableCaption = withPrinting(HtmlDataTableCaption, XmlDataTableCaption);
