import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DatePart } from "../../FlowDocument/FlowDocumentModel";
import { getOptionLabel } from "../../NodePropertiesForm/EnumFormField";

const inputName = "source";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "DatePartNode",
  category: NodeTypeCategory.dates,
  controlOutputs: [],
  dataInputs: [{ name: inputName, type: DataType.DATETIME, hideLabel: true }],
  dataOutputs: [{ name: "result", type: DataType.INTEGER, hideLabel: true }],
  nodeConfigProperties: [
    {
      name: "unit",
      propertyType: NodeConfigPropertyType.Enum,
      options: { values: DatePart }
    },
    {
      name: "inputSchema",
      label: "Input Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [inputName], dataTypes: [DataType.DATETIME, DataType.DATE, DataType.TIME] }
    }
  ],
  evaluate: () => {
    return {
      output: {},
      activeControlHandleIds: []
    };
  }
});

export const DatePartNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const label = getOptionLabel(data.nodeProperties.unit ?? DatePart.Second);

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
