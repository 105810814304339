import {
  useRef,
  useMemo,
  useDebugValue,
  createElement,
  useContext,
  ReactNode,
  Context,
  createContext,
  ReactElement,
  Consumer,
  useEffect
} from "react";
import { useForceRerender } from "../hooks";

export type Action = { type: string };
export type Reducer<S, A extends Action> = (state: S, action: A) => S;
type StateFactory<S> = () => S;
type Listener<S> = (state: S) => void;
type Unsubscribe = () => void;
export type Selector<S, T> = (state: S) => T;
export type Dispatcher<A extends Action> = (action: A) => void;
type StateGetter<S> = () => S;
type Subscribe<S> = (callback: Listener<S>) => Unsubscribe;
type Equality<T> = (a: T, b: T) => boolean;

export type StateContextValue<S> = {
  getState: StateGetter<S>;
  subscribe: Subscribe<S>;
};

type DispatcherContextValue<A extends Action> = {
  dispatch: Dispatcher<A>;
};

export type StoreContextValue<S, A extends Action> = StateContextValue<S> & DispatcherContextValue<A>;

export type StoreContextType<S, A extends Action> = Context<StoreContextValue<S, A>>;

interface IStoreProviderProps<S, A extends Action> {
  reducer: Reducer<S, A>;
  initialState: S | StateFactory<S>;
  children: ReactNode | ReactNode[];
}

const useContextWithValidation = <T>(context: Context<T>, message: string) => {
  const value = useContext(context);
  if (value == null) {
    throw new Error(message);
  }
  return value;
};

const DefaultStoreContext = createContext(null);

type StoreComponents<S, A extends Action> = {
  Provider: (props: IStoreProviderProps<S, A>) => ReactElement;
  Consumer: Consumer<StoreContextValue<S, A>>;
  useStore: () => StateContextValue<S>;
  useDispatch: () => Dispatcher<A>;
  useSelector: <T>(selector: Selector<S, T>, equals?: Equality<T>) => T;
};

export const createStore = <S, A extends Action>(
  context: StoreContextType<S, A> = (DefaultStoreContext as unknown) as StoreContextType<S, A>
): StoreComponents<S, A> => {
  const Provider = ({ reducer, initialState, children }: IStoreProviderProps<S, A>) => {
    const initialStateRef = useRef(initialState);
    const reducerRef = useRef(reducer);
    reducerRef.current = reducer;

    const value = useMemo<StoreContextValue<S, A>>(() => {
      let state: S =
        typeof initialStateRef.current === "function"
          ? (initialStateRef.current as StateFactory<S>)()
          : initialStateRef.current;

      const listeners: Array<Listener<S>> = [];

      return {
        dispatch: (action: A) => {
          const nextState = reducerRef.current(state, action);
          if (nextState !== state) {
            state = nextState;
            listeners.forEach(cb => cb(nextState));
          }
        },
        getState: () => state,
        subscribe: (callback: Listener<S>) => {
          listeners.push(callback);
          return () => {
            const index = listeners.indexOf(callback);
            if (index !== -1) {
              listeners.splice(index, 1);
            }
          };
        }
      };
    }, []);

    return createElement(context.Provider, { value }, children);
  };

  const useStore = () => {
    return useContextWithValidation(context, "`useStore` must be called within a StoreProvider") as StateContextValue<
      S
    >;
  };

  const useDispatch = () => {
    return (useContextWithValidation(
      context,
      "`useDispatch` must be called within a StoreProvider"
    ) as DispatcherContextValue<A>).dispatch;
  };

  const UNSET = {};
  const useSelector = <T>(selector: Selector<S, T>, equals: Equality<T> = Object.is) => {
    const { getState, subscribe } = useStore();
    const trigger = useForceRerender();
    const equalsRef = useRef(equals);
    const selectorRef = useRef(selector);
    const latestState = useRef<S>(UNSET as S);
    const latestValue = useRef<T>(UNSET as T);

    let returnValue = latestValue.current;
    const currentState = getState();

    if (latestState.current !== currentState) {
      const nextValue = selector(currentState);
      if (returnValue === UNSET || !equals(nextValue, returnValue)) {
        returnValue = latestValue.current = nextValue;
      }
    }

    latestState.current = currentState;
    equalsRef.current = equals;
    selectorRef.current = selector;

    useDebugValue(returnValue);

    useEffect(() => {
      let unsubscribing = false;

      const onChange = () => {
        if (unsubscribing) {
          return;
        }

        const state = getState();
        if (state === latestState.current) {
          return;
        }

        const nextValue = selectorRef.current(state);
        if (equalsRef.current(nextValue, latestValue.current)) {
          return;
        }

        latestState.current = state;
        latestValue.current = nextValue;
        trigger();
      };

      const unsubscribe = subscribe(onChange);
      onChange();

      return () => {
        unsubscribing = true;
        unsubscribe();
      };
    }, [subscribe, getState, trigger]);

    return returnValue;
  };

  return {
    Consumer: context.Consumer,
    Provider,
    useDispatch,
    useSelector,
    useStore
  };
};

export const combineReducers = <S, A extends Action>(...reducers: Array<Reducer<S, A>>) => {
  return (state: S, action: A) => {
    return reducers.reduce((s, reducer) => reducer(s, action), state);
  };
};
