import React, { FunctionComponent, ReactElement, ReactChild, useEffect, useRef, useCallback } from "react";
import Tippy from "@tippy.js/react";
import "./Popover.theme.scss";
import Popper from "popper.js";
import { ICommonProps } from "../Common.types";
import styles from "./Popover.module.scss";

const PopoverBody: FunctionComponent = ({ children }) => children as ReactElement;
const PopoverFooter: FunctionComponent = props => {
  return <footer className={styles.actions} {...props} />;
};

interface IPopoverImpl extends FunctionComponent<IPopoverProps> {
  Body: typeof PopoverBody;
  Footer: typeof PopoverFooter;
}

interface IPopoverProps extends ICommonProps {
  /** Indicates whether the popover should hide when clicked */
  hideOnClick?: boolean;
  /** Controls visibility of the popover */
  visible?: boolean;
  /** The react element that the popover should be anchored */
  anchor: ReactElement;
  /** The CSS class to apply to the popover */
  className?: string;
  /** A callback that is called when the popover is hidden */
  onHidden?: () => void;
  /** Indicates whether the popover should show an arrow */
  arrow?: boolean;
  /** the distance offset */
  offset?: string;
  /** Popover Theme */
  theme?: string;
  /** Maximum Width */
  maxWidth?: string;
  /** Offset Distance */
  distance?: number;
  /** Child components */
  children: ReactChild | ReactChild[];
  /** Popover Placement */
  placement?: Popper.Placement;
  /** Specifies which type of events will trigger a tooltip to show */
  trigger?: string;
}

export const Popover: IPopoverImpl = ({
  anchor,
  hideOnClick,
  visible,
  children,
  arrow,
  offset,
  theme,
  maxWidth,
  distance,
  placement,
  trigger,
  ...other
}) => {
  const didHideRecently = useRef(false);

  useEffect(() => {
    if (!hideOnClick) {
      return;
    }

    const clickHandler = (e: MouseEvent) => {
      if (didHideRecently.current) {
        didHideRecently.current = false;
        e.stopPropagation();
        e.preventDefault();
      }
    };

    // Tippy v4 registers a capturing click event listener on document. We register a capturing
    // event listener on document.body so we can stop propagation of the click event if it caused
    // the popover to hide.
    document.body.addEventListener("click", clickHandler, true);

    // eslint-disable-next-line consistent-return
    return () => document.body.removeEventListener("click", clickHandler, true);
  }, [hideOnClick]);

  const onHide = useCallback(() => {
    // re-renders due to state updates (syncing the state controlling the visible prop and the
    // actual visible state when changed by Tippy itself, e.g. hideOnClick) can cause Tippy to call
    // onHide twice.
    if (!didHideRecently.current) {
      didHideRecently.current = true;

      // Schedule a macrotask to clear the didHideRecently flag. If it was a click event that
      // triggered onHide, it should be guaranteed that the next event our listener observes will be
      // that event. Otherwise, there's a small chance that there's a click event queued ahead of
      // the timer callback, which would get nullified, but it's unlikely, and until the upgrade to
      // Tippy v6 this is probably the best we can do.
      setTimeout(() => {
        didHideRecently.current = false;
      }, 0);
    }
  }, []);

  return (
    <Tippy
      arrow={arrow !== false}
      className={styles.base}
      distance={distance ?? 3}
      offset={offset ?? "0"}
      hideOnClick={hideOnClick}
      interactive
      maxWidth={maxWidth ?? "80vw"}
      placement={placement ?? "bottom-end"}
      theme={theme ?? "plex-light"}
      content={children as ReactElement}
      visible={visible}
      trigger={trigger ?? "mouseenter focus"}
      boundary="viewport"
      onHide={onHide}
      {...other}
    >
      {anchor}
    </Tippy>
  );
};

Popover.Body = PopoverBody;
Popover.Footer = PopoverFooter;
Popover.Body.displayName = "PopoverBody";
Popover.Footer.displayName = "PopoverFooter";
