import React, { FunctionComponent } from "react";
import { ConfirmDialog } from "@plex/react-components";
import { deleteNodes } from "../Util/NodeUtil";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";
import { useViewController } from "../ViewContext";

export const DeletionConfirmationForm: FunctionComponent = () => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const viewController = useViewController();
  let selectedNodes: any = reactFlowInstance.getNodes().filter((n) => n.selected);

  return (
    <ConfirmDialog
      show={globalThis.confirmDeletionState}
      title="Question"
      question="Are you sure you want to delete this node?"
      answers={["Yes", "No"]}
      defaultAnswer="No"
      onHide={(answer: string) => {
        globalThis.setConfirmDeletionState(false);
        if (answer === "Yes") {
          deleteNodes(selectedNodes, updateNodeInternals, reactFlowInstance, viewController);
        }
      }}
    />
  );
};
