import React, { FunctionComponent, useState, KeyboardEvent } from "react";
import { useLocalization } from "@plex/culture-react";
import { TextInput } from "@components/Input";
import { Button } from "@components/Button";
import { Link } from "@components/Link";
import { TextQuery, ITextQuery } from "./TextQuery";
import styles from "./PickerModal.module.scss";

interface IPickerSearchInputProps {
  searchText: string;
  onSearch: (query: ITextQuery) => void;
  onModeToggle: () => void;
}

export const PickerSearchInput: FunctionComponent<IPickerSearchInputProps> = ({
  searchText,
  onSearch,
  onModeToggle
}) => {
  const { t } = useLocalization();
  const [text, setText] = useState(searchText || "");

  const handleSearch = () => {
    onSearch(TextQuery.simple(text));
  };

  const onEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    handleSearch();

    // Stop event propagation, to avoid conflicts with consumers that have event listeners on the container of Picker
    e.stopPropagation();
  };

  return (
    <div className={styles.searchSection} data-testid="plex-picker-search">
      <div className={styles.searchInput}>
        <TextInput
          value={text}
          onChange={e => setText(e.currentTarget.value)}
          onEnterKey={onEnterKey}
          maxLength={200}
          placeholder={t("ui-common-dataPicker-info-newSearch")}
          autoFocus
          data-testid="plex-picker-search-input"
        />
      </div>
      <div className={styles.searchButton}>
        <Button data-testid="plex-picker-search-button" onClick={handleSearch}>
          {t("ui-common-filter-action-search")}
        </Button>
        <Link onClick={onModeToggle} className={styles.advancedLink} data-testid="plex-picker-advanced-search-link">
          {t("ui-common-dataPicker-option-advancedSearch")}
        </Link>
      </div>
    </div>
  );
};
