import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "StartNode",
  category: NodeTypeCategory.general,
  controlOutputOnly: true,
  controlOutputs: [{ name: "control" }],
  dataInputs: [],
  dataOutputs: [],
  size: NodeSize.Small,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: ["control"] };
  }
});

export const StartNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
