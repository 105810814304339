import { LocalDate, DayOfWeek, LocalTime, Temporal } from "js-joda";
import { Locale, DayValue, IDateFormatOptions } from "@plex/culture-core";
import { ICommonInputProps } from "../Input";
import { CalendarInterval } from "./CalendarInterval";

export type DateChangeHandler<T extends Temporal> = (date: T | null) => void;
export type RangeChangeHandler = (range: CalendarInterval | null) => void;
export type TimePrecision = "minutes" | "seconds" | "milliseconds";
export type DatePickerModes = "day" | "month" | "year";

export interface ICalendarProps {
  /**
   * Determines the first day of the week. If not assigned the default for the culture
   * will be used. (Value should be 1 - Monday through 7 - Sunday)
   */
  weekStart?: DayValue | DayOfWeek;
}

export interface ITimeProps {
  /**
   * Determines the precision of the time value
   * @default "minute"
   */
  precision?: TimePrecision;
  /**
   * Determines whether to use AM/PM. If unset it will
   * use the current locale's default.
   */
  use24HourTime?: boolean;
  /**
   * Determines what increment the minute portion should
   * be broken down into.
   * @default 1
   */
  minuteStep?: number;
}

export interface ITemporalInputProps extends ICommonInputProps {
  /** The formatting options for the selected date */
  formatOptions?: IDateFormatOptions | string;
}

export interface ICommonDatePickerProps extends ICalendarProps {
  /** The current selected @see LocalDate instance */
  value?: LocalDate | null;
  /**
   * Function which receives the date when the user makes a selection. This should
   * be used to update local state.
   */
  onValueChange?: DateChangeHandler<LocalDate>;
  /** Sets @see DatePicker default input view mode */
  defaultInputMode?: DatePickerModes;
}

export interface ITimePickerProps extends ITimeProps {
  /** The current time value */
  value?: LocalTime | null;
  /* disable the timepicker */
  disabled?: boolean;
  /** Callback executed when the time value changes. */
  onValueChange?: DateChangeHandler<LocalTime>;
}

export interface IDateRangePickerProps extends ICalendarProps {
  /** The selected date range */
  range?: CalendarInterval;
  /**
   * Function which receives the date range when the user makes a selection. This should
   * be used to update local state.
   */
  onRangeChange: RangeChangeHandler;
  /** Predefined ranges which a user can use to select a range */
  rangeFilters?: RangeFilterFactoryGroup[];
}

export interface IIntervalOptions {
  locale?: Locale;
  weekStart?: DayValue | DayOfWeek;
}

export type RangeFactory = (from: LocalDate, options: IIntervalOptions) => CalendarInterval | null;

export type RangeFilterFactory = {
  messageId: string;
  create: RangeFactory;
};

export type RangeFilterFactoryGroup = {
  messageId: string;
  intervals: RangeFilterFactory[];
};

export const PREV_SYMBOL = "«";
export const NEXT_SYMBOL = "»";
