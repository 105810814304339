import { Node, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { IReplay, ITestProperties } from "../FlowDocument/TestPropertiesForm";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { FunctionSubscriberContextProps } from "../FunctionSubscriberContext/FunctionSubscriberProvider";
import { setNodeSelection } from "../Util/NodeUtil";

export interface INodeTrace2 {
  id: string;
  snapshot: INodeTraceSnapshot2;
  entryIndex: number;
  hasSnapshotInput: boolean;
  hasSnapshotOutput: boolean;
  jsonSnapshot: IJsonSnapshot;
}

export interface INodeTraceSnapshot2 {
  inputs: { [name: string]: any };
  outputs: { [name: string]: any };
}

export interface ITraceSpan2 {
  duration: number; // duration in seconds
}

export interface IExecutionSummary {
  executionId: string;
  success: boolean;
  errors: IExecutionError[];
  trace: never; // todo: this stopgap can be removed once IRunResult is obsolete
  executionTime: number;
}

export interface IExecutionError {
  code?: string;
  message: string;
  nodeId?: string;
}

export interface IJsonSnapshot {
  entryIndex: number;
  inputs: string;
  outputs: string;
}

export const playReplay = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>,
  internalCall: boolean = false
) => {
  const testState: ITestProperties = getTestState();
  let replayState: IReplay = testState.replay;

  if (replayState.traceIndex > replayState.nodeTraces.length - 1) {
    exitReplay();
    return;
  }
  const nodeTrace: INodeTrace2 = replayState.nodeTraces[replayState.traceIndex]!;
  const node = reactFlowInstance.getNode(nodeTrace.id);

  if (node) {
    if (replayState.isStopped) {
      reactFlowInstance.getNodes().forEach((n: Node<any>) => {
        resetReplayTrace(n);
      });
      setReplayStopped(false);
    }

    return Promise.resolve()
      .then(() => {
        node.data.nodeProperties.isCurrentTrace = true;
        setNodeEvaluating(node, true, updateNodeInternals);
      })
      .then(() => timeout(getTestState().runtimeNodeDelayMs))
      .then(() =>
        continueReplay(
          node,
          nodeTrace,
          internalCall,
          reactFlowInstance,
          updateNodeInternals,
          bannerContext,
          functionSubscriber
        )
      );
  } else {
    exitReplay();
    bannerContext.addMessage("Cannot continue replay, node is missing.", BannerStatus.warning);
  }
};

const exitReplay = () => {
  setReplayTraceIndex(0);
  setReplayPaused(true);
  setReplayStopped(true);
  setReplayStopping(false);
};

const continueReplay = (
  node: Node<any>,
  nodeTrace: INodeTrace2,
  internalCall: boolean,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  {
    const testState = getTestState();
    const replayState = testState.replay;
    replayTraceUpdate(
      replayState.traceIndex + 1,
      replayState.nodeTraces,
      reactFlowInstance,
      updateNodeInternals,
      functionSubscriber,
      replayState.executionId
    );
    setNodeEvaluating(node, false, updateNodeInternals);

    if (!replayState.isPaused) {
      if (!replayState.isStopping && replayState.breakPointNodeId && nodeTrace.id === replayState.breakPointNodeId) {
        setReplayPaused(true);
        setNodeSelection([node.id], reactFlowInstance);
      } else {
        if (replayState.traceIndex < replayState.nodeTraces.length - 1) {
          node.data.nodeProperties.isCurrentTrace = false;
          setReplayTraceIndex(replayState.traceIndex + 1);
          playReplay(reactFlowInstance, updateNodeInternals, bannerContext, functionSubscriber, true);
        } else {
          if (testState.isValid || !internalCall) {
            node.data.nodeProperties.isCurrentTrace = false;
            setReplayTraceIndex(0);
            setReplayPaused(true);
            setReplayStopped(true);
            setReplayStopping(false);
            displayLastNodeIndexSnapshotData(reactFlowInstance, updateNodeInternals, replayState, functionSubscriber);
          } else {
            setReplayPaused(true);
          }
        }
      }
    }
  }
};

const setNodeEvaluating = (node: Node<any>, evaluating: boolean, updateNodeInternals: UpdateNodeInternals) => {
  node.data.nodeProperties.isEvaluating = evaluating;
  node.position.x += evaluating ? 0.0001 : -0.0001;
  updateNodeInternals(node.id);
};

export const stopReplay = (reactFlowInstance: ReactFlowInstance, callback?: () => void) => {
  const replayState = getReplayState();

  if (replayState.isStopped) {
    return;
  }

  reactFlowInstance.getNodes().forEach((n: Node<any>) => resetReplayTrace(n));

  setReplayPaused(true);
  setReplayStopped(true);
  setReplayTraceIndex(0);

  if (callback) {
    callback();
  }
};

export const setReplayStopped = (isStopped: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isStopped: isStopped };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayStopping = (isStopping: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isStopping: isStopping };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const stepForward = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  let replayState: IReplay = getTestState().replay;

  if (replayState.traceIndex > replayState.nodeTraces.length - 2 || !replayState.isPaused) {
    return;
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = oldTraceIndex + 1;

  const oldTrace: INodeTrace2 | undefined = replayState.nodeTraces[oldTraceIndex];
  const newTrace: INodeTrace2 | undefined = replayState.nodeTraces[newTraceIndex];
  const oldTraceNode = oldTrace ? reactFlowInstance.getNode(oldTrace.id) : undefined;
  const newTraceNode = newTrace ? reactFlowInstance.getNode(newTrace.id) : undefined;
  if (oldTraceNode) {
    oldTraceNode.data.nodeProperties.isCurrentTrace = false;
  }
  if (newTraceNode) {
    newTraceNode.data.nodeProperties.isCurrentTrace = true;
  } else {
    bannerContext.addMessage("Cannot step forward, node is missing.", BannerStatus.warning);
  }
  replayTraceUpdate(
    newTraceIndex,
    replayState.nodeTraces,
    reactFlowInstance,
    updateNodeInternals,
    functionSubscriber,
    replayState.executionId
  );

  setReplayTraceIndex(newTraceIndex);
};

export const stepBack = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const replayState: IReplay = getReplayState();

  if (!replayState.isPaused || replayState.traceIndex === 0) {
    return;
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = oldTraceIndex - 1;
  const oldTrace: INodeTrace2 | undefined = replayState.nodeTraces[oldTraceIndex];
  const newTrace: INodeTrace2 | undefined = replayState.nodeTraces[newTraceIndex];
  const oldTraceNode = oldTrace ? reactFlowInstance.getNode(oldTrace.id) : undefined;
  const newTraceNode = newTrace ? reactFlowInstance.getNode(newTrace.id) : undefined;
  if (oldTraceNode) {
    resetReplayTrace(oldTraceNode!);
    oldTraceNode.data.nodeProperties.isCurrentTrace = false;
  }
  if (newTraceNode) {
    newTraceNode.data.nodeProperties.isCurrentTrace = true;
  } else {
    bannerContext.addMessage("Cannot step backward, node is missing.", BannerStatus.warning);
  }

  setReplayTraceIndex(newTraceIndex);

  if (oldTrace) {
    const latestTrace = getLatestNodeTrace(oldTrace.id);
    if (latestTrace) {
      replayTraceUpdate(
        latestTrace?.entryIndex,
        replayState.nodeTraces,
        reactFlowInstance,
        updateNodeInternals,
        functionSubscriber,
        replayState.executionId
      );
    }
  }
};

export const skipToFirst = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext
) => {
  const replayState: IReplay = getReplayState();

  // reset the currentTrace flag and snapshot data for all nodes
  reactFlowInstance.getNodes().forEach((n: Node<any>) => resetReplayTrace(n));

  // only set the first node's isCurrentTrace if paused
  //   Unpaused will immediately move to the next node without clearing this
  if (replayState.isPaused) {
    const firstTrace: INodeTrace2 = replayState.nodeTraces[0]!;
    const firstNode = firstTrace ? reactFlowInstance.getNode(firstTrace.id) : undefined;

    if (firstNode) {
      firstNode.data.nodeProperties.isCurrentTrace = true;
      updateNodeInternals(firstNode!.id);
    } else {
      bannerContext.addMessage("Cannot skip to the first node, node is missing.", BannerStatus.warning);
    }
  }

  setReplayTraceIndex(0);
};

export const skipToLast = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  bannerContext: IBannerContext,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const replayState: IReplay = getReplayState();

  const lastTraceIndex = replayState.nodeTraces.length - 1;
  if (replayState.traceIndex === lastTraceIndex) {
    return;
  }

  const oldTraceIndex = replayState.traceIndex;
  const newTraceIndex = lastTraceIndex;

  const oldTrace: INodeTrace2 | undefined = replayState.nodeTraces[oldTraceIndex];
  const newTrace: INodeTrace2 | undefined = replayState.nodeTraces[newTraceIndex];

  const oldTraceNode = oldTrace ? reactFlowInstance.getNode(oldTrace.id) : undefined;
  const newTraceNode = newTrace ? reactFlowInstance.getNode(newTrace.id) : undefined;

  if (oldTraceNode) {
    resetReplayTrace(oldTraceNode!);
    oldTraceNode.data.nodeProperties.isCurrentTrace = false;
  }
  if (newTraceNode) {
    newTraceNode.data.nodeProperties.isCurrentTrace = true;
  } else {
    bannerContext.addMessage("Cannot step to the last node, node is missing.", BannerStatus.warning);
  }

  setReplayTraceIndex(newTraceIndex);

  // if we skip to last while running, displaying the last data is handled by continueReplay().
  // If paused display explicitly
  if (replayState.isPaused) {
    displayLastNodeIndexSnapshotData(reactFlowInstance, updateNodeInternals, replayState, functionSubscriber);
  }
};

export const setNodeDelayMs = (nodeDelayMs: number) => {
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    runtimeNodeDelayMs: nodeDelayMs
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayPaused = (isPaused: boolean) => {
  const replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, isPaused: isPaused };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const updateReplayState = (replayState: IReplay, traceIndex: number) => {
  const newReplayState: IReplay = { ...replayState, traceIndex: traceIndex };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const setReplayTraceIndex = (traceIndex: number) => {
  let replayState: IReplay = getReplayState();
  const newReplayState: IReplay = { ...replayState, traceIndex: traceIndex };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const updateBreakpointNodeId = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const selectedNode = reactFlowInstance.getNodes().find((n: Node<any>) => n.selected);
  let selectedNodeId = selectedNode?.id;
  let replayState: IReplay = getReplayState();

  if (selectedNode) {
    selectedNode.data.nodeProperties.isBreakpoint = true;
    forceNodeUpdate(selectedNode, updateNodeInternals, reactFlowInstance);
  }

  if (replayState.breakPointNodeId) {
    const oldSelectedNode = reactFlowInstance.getNode(replayState.breakPointNodeId);
    if (oldSelectedNode) {
      delete oldSelectedNode.data.nodeProperties.isBreakpoint;
      forceNodeUpdate(oldSelectedNode, updateNodeInternals, reactFlowInstance);
    }

    if (replayState.breakPointNodeId === selectedNodeId) {
      selectedNodeId = undefined;
    }
  }

  const newReplayState: IReplay = { ...replayState, breakPointNodeId: selectedNodeId };
  const testPropertiesState: ITestProperties = {
    ...globalThis.testPropertiesState,
    replay: newReplayState
  };
  globalThis.testPropertiesState = testPropertiesState;
  globalThis.setTestPropertiesState(testPropertiesState);
};

export const getReplayState = (): IReplay => globalThis.testPropertiesState.replay;

const getTestState = (): ITestProperties => globalThis.testPropertiesState;

const replayTraceUpdate = (
  traceIndex: number,
  nodeTraces: INodeTrace2[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  functionSubscriber: Partial<FunctionSubscriberContextProps>,
  executionId?: string
) => {
  const nodeTrace: INodeTrace2 | undefined = nodeTraces[traceIndex];
  if (nodeTrace) {
    const node = reactFlowInstance.getNode(nodeTrace.id);
    if (node) {
      // CORE-1991 - if we have an executionId we know we're using the summary and need to grab snapshot data
      if (executionId) {
        if (node.selected) {
          const traces = getSelectedTracesWithoutSnapshotData(nodeTraces, nodeTrace, node);

          setTraceSnapshots(executionId, traces, functionSubscriber).then(() => {
            // if there's a transient api/db call failure, this may be null
            if (nodeTrace.jsonSnapshot) {
              node.data.nodeProperties.testInput = nodeTrace.jsonSnapshot.inputs;
              node.data.nodeProperties.testOutput = nodeTrace.jsonSnapshot.outputs;
              node.data.nodeProperties.testIoTraceIndex = traceIndex;
              forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
            }
          });
        } else {
          if (nodeTrace.jsonSnapshot) {
            node.data.nodeProperties.testInput = nodeTrace.jsonSnapshot.inputs;
            node.data.nodeProperties.testOutput = nodeTrace.jsonSnapshot.outputs;
            node.data.nodeProperties.testIoTraceIndex = traceIndex;
            forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
          }
        }
      } else {
        node.data.nodeProperties.testInput = nodeTrace.snapshot.inputs;
        node.data.nodeProperties.testOutput = nodeTrace.snapshot.outputs;
        node.data.nodeProperties.testIoTraceIndex = traceIndex;
        forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
      }
    }
  }
};

const getLatestNodeTrace = (nodeId: string): INodeTrace2 | undefined => {
  const replay = getReplayState();
  let traceIndex = replay.traceIndex;
  if (replay.isStopped) {
    traceIndex = replay.nodeTraces.length - 1;
  }

  for (let i = traceIndex + 1; i--; i >= 0) {
    if (replay.nodeTraces[i]?.id === nodeId) {
      return replay.nodeTraces[i];
    }
  }

  return undefined;
};

export const latestTraceMatchesNodeReplayIo = (node: Node<any>): boolean => {
  const trace = getLatestNodeTrace(node!.id);
  return node!.data.nodeProperties.testIoTraceIndex === trace?.entryIndex;
};

const Large_Snapshot_Node_Types = ["callDataSource", "forEach", "listSort", "objectProperties"];

const getSelectedTracesWithoutSnapshotData = (
  nodeTraces: INodeTrace2[],
  currentTrace: INodeTrace2,
  selectedNode: Node<any>
): INodeTrace2[] => {
  let batchSize: number = 10;
  if (selectedNode.type && Large_Snapshot_Node_Types.includes(selectedNode.type)) {
    batchSize = 3;
  }
  const selectedTraces = nodeTraces.filter((t) => t.id == currentTrace.id && !t.jsonSnapshot);
  if (selectedTraces.length == 0) {
    return [];
  }

  const idx = selectedTraces.findIndex((t) => t.entryIndex == currentTrace.entryIndex);

  let start: number;
  let end: number;
  /*
    If we have an index, we know where we are in the cursor and can grab current +/-x
    We have an index: at the start of a replay, when a node is selected mid-replay
    and when we click previous/next to a node that isn't already populated
    Otherwise, just grab the next x
  */
  if (idx > -1) {
    start = Math.max(0, idx - batchSize);
    end = Math.min(selectedTraces.length - 1, idx + batchSize);
  } else {
    start = 0;
    end = batchSize;
  }

  return selectedTraces.slice(start, end);
};

export const displayLastNodeIndexSnapshotData = (
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  replay: IReplay,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
) => {
  const lastEntries = getLastTracesPerNode(replay.nodeTraces, reactFlowInstance.getNodes());
  setTraceSnapshots(replay.executionId!, lastEntries, functionSubscriber).then(() => {
    for (const nodeTrace of lastEntries) {
      const node = reactFlowInstance.getNode(nodeTrace.id);
      if (node) {
        setInputOutput(node, nodeTrace, reactFlowInstance, updateNodeInternals);
      }
    }
  });
};

const getLastTracesPerNode = (nodeTraces: INodeTrace2[], nodes: Node<any>[]): INodeTrace2[] => {
  const entries: INodeTrace2[] = [];
  nodes.forEach((n: Node<any>) => {
    const arr = nodeTraces.filter((t) => t.id == n.id);
    const last = arr[arr.length - 1];
    if (last) {
      entries.push(last);
    }
  });
  return entries;
};

export const displaySelectedNodeSnapshotData = (
  selectedNode: Node,
  functionSubscriber: Partial<FunctionSubscriberContextProps>,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const replay = getReplayState();
  if (replay && replay.executionId && replay.isPaused) {
    // Grab traces for entryIndexes prior to the current
    const selectedTraces = replay.nodeTraces.filter(
      (t) => t.id == selectedNode.id && t.entryIndex <= replay.traceIndex
    );
    if (selectedTraces.length > 0) {
      const lastTrace = selectedTraces[selectedTraces.length - 1]!;
      setTraceSnapshots(replay.executionId, [lastTrace], functionSubscriber).then(() => {
        setInputOutput(selectedNode, lastTrace, reactFlowInstance, updateNodeInternals);
      });
    }
  }
};

const setInputOutput = (
  node: Node,
  nodeTrace: INodeTrace2,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  // CORE-1991 - Check old snapshot property first
  // They are currently mutually exclusive but when we fully move over we can remove the exists check
  if (nodeTrace.snapshot) {
    node.data.nodeProperties.testInput = nodeTrace.snapshot.inputs;
    node.data.nodeProperties.testOutput = nodeTrace.snapshot.outputs;
    node.data.nodeProperties.testIoTraceIndex = nodeTrace.entryIndex;
    forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
    return;
  }

  if (nodeTrace.jsonSnapshot) {
    node.data.nodeProperties.testInput = nodeTrace.jsonSnapshot.inputs;
    node.data.nodeProperties.testOutput = nodeTrace.jsonSnapshot.outputs;
    node.data.nodeProperties.testIoTraceIndex = nodeTrace.entryIndex;
    forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
  }
};

const resetReplayTrace = (node: Node<any>) => {
  delete node.data.nodeProperties.testInput;
  delete node.data.nodeProperties.testOutput;
  delete node.data.nodeProperties.isCurrentTrace;
  delete node.data.nodeProperties.testIoTraceIndex;
};

const timeout = (ms: number) => {
  if (ms === 0) {
    return new Promise<void>((resolve) => resolve());
  }
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/** Retrieve the run's trace entries from ux action */
export const getTraceEntries = (
  executionId: string,
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<INodeTrace2[]> => {
  const batchSize: number = 600;
  let iteration = 0;
  const traceEntries: INodeTrace2[] = [];
  return new Promise<INodeTrace2[]>(function (resolve) {
    const iterateFn = () => {
      let offset = iteration * batchSize;
      if (functionSubscriber.plexGetTraceEntries) {
        functionSubscriber.plexGetTraceEntries(executionId, offset, batchSize).then((response: any) => {
          const entries = response.Data;
          traceEntries.push(...entries);

          // If we just returned a full batch, check for more
          if (entries.length == batchSize) {
            iteration++;
            iterateFn();
          } else {
            resolve(traceEntries);
          }
        });
      } else {
        console.warn("plexGetTraceEntries does not exist");
      }
    };
    iterateFn();
  });
};

/** Set the snapshot data from ux action */
export const setTraceSnapshots = (
  executionId: string,
  traceEntries: INodeTrace2[],
  functionSubscriber: Partial<FunctionSubscriberContextProps>
): Promise<void> => {
  const entryIndexes: number[] = [];
  for (const traceEntry of traceEntries) {
    // if we have already set the data, no need to look it up again
    if (traceEntry.jsonSnapshot && getLatestNodeTrace(traceEntry.id)?.entryIndex === traceEntry.entryIndex) {
      continue;
    }

    // return defaults if we have no data to go lookup
    if (!traceEntry.hasSnapshotInput && !traceEntry.hasSnapshotOutput) {
      traceEntry.jsonSnapshot = {
        entryIndex: traceEntry.entryIndex,
        inputs: {},
        outputs: {}
      } as IJsonSnapshot;
      continue;
    }

    // gather indexes we need to query for
    entryIndexes.push(traceEntry.entryIndex);
  }

  if (entryIndexes.length == 0) {
    return Promise.resolve();
  }

  const promise: Promise<void> = new Promise((resolve) => {
    if (functionSubscriber.plexGetTraceSnapshots) {
      functionSubscriber.plexGetTraceSnapshots(executionId, entryIndexes).then((response: any) => {
        var snapshots: IJsonSnapshot[] = response.Data;
        for (const snapshot of snapshots) {
          if (snapshot) {
            // nulls to empty json
            snapshot.inputs = JSON.parse(snapshot.inputs ?? "{}");
            snapshot.outputs = JSON.parse(snapshot.outputs ?? "{}");

            const traceEntry = traceEntries.find((t) => t.entryIndex == snapshot.entryIndex);
            if (traceEntry) {
              traceEntry.jsonSnapshot = snapshot;
            }
          }
        }

        resolve();
      });
    } else {
      console.warn("plexGetTraceSnapshots does not exist");
      resolve();
    }
  });

  return promise;
};
