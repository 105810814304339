/* eslint-disable max-classes-per-file */
import { concat } from "@arrays-immutable";
import { Match, Matcher, createSimple, createAdvanced } from "./TextMatcherFactory";
import { AdvancedSearchState, AdvancedSearch } from "./AdvancedSearch";

/** This will merge overlapping matches into a single match. */
// eslint-disable-next-line func-style
function merge(arr: Match[], pos: Match) {
  let i = arr.length;
  while (i--) {
    const current = arr[i];
    if (current === pos) {
      continue;
    }

    // end is within
    if (pos.end <= current.end && pos.end > current.start) {
      current.start = Math.min(pos.start, current.start);
      return true;
    }

    // start is within
    if (pos.start >= current.start && pos.start < current.end) {
      current.end = Math.max(pos.end, current.end);
      return true;
    }

    if (pos.start < current.start && pos.end > current.end) {
      current.start = Math.min(pos.start, current.start);
      current.end = Math.max(pos.end, current.end);
      return true;
    }
  }

  // if we get here there are no overlaps
  return false;
}

export interface ITextQuery {
  readonly query: string;
  readonly displayText: string;
  readonly matchers: Matcher[];
  and: (query: ITextQuery) => ITextQuery;
  isMatch: (strings: string | string[]) => boolean;
  isEmpty: () => boolean;
  getMatches: (text: string) => Match[];
  equals: (other: ITextQuery) => boolean;
}

export interface IAggregateTextQuery extends ITextQuery {
  readonly left: ITextQuery;
  readonly right: ITextQuery;
}

export interface IAdvancedQuery extends ITextQuery {
  readonly searchFields: Map<string, string>;
}

const EMPTY_QUERY: ITextQuery = {
  query: "",
  displayText: "",
  matchers: [],
  and: (query: ITextQuery) => query,
  isMatch: () => true,
  isEmpty: () => true,
  getMatches: () => [],
  equals: (other: ITextQuery) => other === EMPTY_QUERY
};

export class TextQuery implements ITextQuery {
  readonly query: string;

  readonly displayText: string;

  readonly matchers: Matcher[];

  private readonly cache: { [key: string]: boolean } = {};

  constructor(text: string, matchers: Matcher[], displayText?: string) {
    this.query = text;
    this.displayText = displayText || text;
    this.matchers = matchers;
  }

  public and(query: ITextQuery): ITextQuery {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new AndTextQuery(this, query);
  }

  // eslint-disable-next-line complexity
  public isMatch(strings: string | string[]) {
    const joinedString = Array.isArray(strings) ? strings.join(" ") : strings;
    if (joinedString in this.cache) {
      return this.cache[joinedString];
    }

    const notExpectedMatchers = this.matchers.filter(m => !m.expected);
    for (let i = 0; i < notExpectedMatchers.length; i++) {
      const matcher = notExpectedMatchers[i];
      if (matcher.patterns.first.test(joinedString)) {
        return (this.cache[joinedString] = false);
      }
    }

    const expectedMatchers = this.matchers.filter(m => m.expected);
    let result = false;

    for (let i = 0; i < strings.length; i++) {
      result = true;
      const string = strings[i];

      for (let j = 0; j < expectedMatchers.length; j++) {
        const matcher = expectedMatchers[j];

        if (!matcher.patterns.first.test(string)) {
          result = false;
          break;
        }
      }

      if (result) {
        break;
      }
    }

    if (!result) {
      result = true;

      for (let j = 0; j < expectedMatchers.length; j++) {
        const matcher = expectedMatchers[j];
        if (!matcher.patterns.first.test(joinedString)) {
          result = false;
          break;
        }
      }
    }

    return (this.cache[joinedString] = result);
  }

  public isEmpty() {
    return this.matchers.length === 0;
  }

  public getMatches(text: string) {
    const matches: Match[] = [];
    this.matchers.forEach(matcher => {
      let match: RegExpExecArray | null;

      if (matcher.expected) {
        const regExp = matcher.patterns;
        while ((match = regExp.all.exec(text))) {
          // Prevent browsers from getting stuck in an infinite loop
          if (match.index === regExp.all.lastIndex) {
            regExp.all.lastIndex++;
          }

          const current = {
            start: match.index,
            end: match.index + match[0].length
          };

          if (match[0].length && !merge(matches, current)) {
            matches.push(current);
          }
        }
      }
    });

    return matches.sort((a, b) => a.start - b.start);
  }

  public equals(other: ITextQuery | null) {
    if (this === other) {
      return true;
    }

    return !!other && this.query === other.query;
  }

  static simple(text: string): ITextQuery {
    if (!text) {
      return TextQuery.empty();
    }

    const matchers = createSimple(text);
    return new TextQuery(text, matchers, text);
  }

  static advanced(state: AdvancedSearchState): IAdvancedQuery {
    const builder = new AdvancedSearch(state);
    const query = builder.build();
    if (!query) {
      return { ...EMPTY_QUERY, searchFields: new Map() };
    }

    const matchers = createAdvanced(query);
    const displayText = builder.build(true);
    const searchFields = builder.methods.map(m => {
      return {
        key: m.name,
        text: builder.state[m.key] ?? ""
      };
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new AdvancedQuery(query, matchers, displayText, new Map(searchFields.map(key => [key.key, key.text])));
  }

  static empty(): ITextQuery {
    return EMPTY_QUERY;
  }
}

class AndTextQuery extends TextQuery implements IAggregateTextQuery {
  public left: ITextQuery;

  public right: ITextQuery;

  constructor(left: ITextQuery, right: ITextQuery) {
    super(
      `${left.query} AND ${right.query}`,
      concat(left.matchers, right.matchers),
      `${left.displayText} ${right.displayText}`
    );
    this.left = left;
    this.right = right;
  }
}

export const isAggregateSearch = (search: ITextQuery): search is IAggregateTextQuery => {
  return "right" in search;
};

class AdvancedQuery extends TextQuery implements IAdvancedQuery {
  public searchFields: Map<string, string>;
  constructor(query: string, matchers: Matcher[], displayText: string, searchFields: Map<string, string>) {
    super(query, matchers, displayText);
    this.searchFields = searchFields;
  }
}
