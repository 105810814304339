import React, { useMemo, createContext, useContext, PropsWithChildren } from "react";
import { TreeContextType } from "./Tree.types";

const TreeContext = createContext({});
export const TreeNodeContext = createContext({});

// eslint-disable-next-line func-style
export function useTree<T>() {
  return useContext(TreeContext) as TreeContextType<T>;
}

// eslint-disable-next-line func-style
export function useTreeNode<T>() {
  return useContext(TreeNodeContext) as T;
}

// eslint-disable-next-line func-style
export function TreeProvider<T>({
  depth,
  keySelector,
  render,
  selectable,
  selectedKeys,
  toggleSelection,
  onItemMoved,
  children
}: PropsWithChildren<TreeContextType<T>>) {
  const context = useMemo(
    () => ({ depth, keySelector, render, selectable, selectedKeys, toggleSelection, onItemMoved }),
    [depth, keySelector, render, selectable, selectedKeys, toggleSelection, onItemMoved]
  );
  return <TreeContext.Provider value={context}>{children}</TreeContext.Provider>;
}
