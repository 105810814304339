import React, { FunctionComponent, createElement, isValidElement, ReactElement, PropsWithChildren } from "react";
import clsx from "clsx";
import { withPrinting } from "@plex/react-xml-renderer";
import { FormFieldPair } from "@components/Form/FormFieldPair";
import { FormRowInfo } from "@components/Form/FormRowInfo";
import { ICommonProps } from "../Common.types";
import styles from "./Form.module.scss";

export interface IFormRowProps extends PropsWithChildren<ICommonProps> {
  /** Should Hover Style apply */
  hover?: boolean;
}

const HtmlFormRow: FunctionComponent<IFormRowProps> = ({ children, className, style, hover, ...props }) => {
  const hoverClass = hover || hover == null ? styles.formRowHover : styles.formRowInternal;

  return (
    <div data-testid="plex-form-row" className={clsx(styles.formRow, className)} style={style} {...props}>
      <div className={clsx(hoverClass)}>{children}</div>
    </div>
  );
};

const isFormRow = (child: ReactElement) => {
  return child.type === FormFieldPair || child.type === FormRowInfo;
};

const XmlFormRow: FunctionComponent<IFormRowProps> = ({ children }) => {
  if (
    React.Children.toArray(children)
      .filter(isValidElement)
      .every(isFormRow)
  ) {
    return createElement("plex-formrow", {}, children);
  }

  const controls = createElement("plex-controls", {}, children);
  return createElement("plex-formelementrow", {}, controls);
};

export const FormRow = withPrinting(HtmlFormRow, XmlFormRow);
