/* eslint-disable react/jsx-no-useless-fragment */
import React, { isValidElement, cloneElement, ReactNode, FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import { withPrinting } from "@plex/react-xml-renderer";
import { Text } from "../Text";
import styles from "./Filter.module.scss";

export interface IFilterItemLabelProps extends ICommonProps {
  requiredClasses?: string;
  labelFor?: string;
  labelText?: string | ReactNode;
}

const LabelElement: FunctionComponent<IFilterItemLabelProps> = ({ requiredClasses, labelFor, labelText }) => {
  let labelElement: ReactNode | null;
  if (typeof labelText === "string") {
    labelElement = <Text as="label" className={requiredClasses} labelFor={labelFor} text={labelText} />;
  } else {
    labelElement = isValidElement(labelText)
      ? cloneElement(labelText, {
          className: clsx(labelText.props.className, requiredClasses)
        })
      : labelText || null;
  }
  return <>{labelElement}</>;
};

const HtmlFilterItemLabel: FunctionComponent<IFilterItemLabelProps> = ({ requiredClasses, labelFor, labelText }) => {
  return (
    <div data-testid="plex-control-label" className={styles.filterItemLabel}>
      <LabelElement requiredClasses={requiredClasses} labelFor={labelFor} labelText={labelText} />
    </div>
  );
};

const XmlFilterItemLabel: FunctionComponent<IFilterItemLabelProps> = ({ requiredClasses, labelFor, labelText }) => {
  return <LabelElement requiredClasses={requiredClasses} labelFor={labelFor} labelText={labelText} />;
};

export const FilterItemLabel = withPrinting(HtmlFilterItemLabel, XmlFilterItemLabel);
