import { LocalDate, Clock, LocalDateTime, ZonedDateTime } from "js-joda";
import { isLocalDateTime, isZonedDateTime } from "./date-utils";
import { OPEN_END_DATE, OPEN_START_DATE } from "./CalendarIntervals";

type IntervalBoundary = LocalDate | LocalDateTime | ZonedDateTime;

const nullOrOpenStart = (value: IntervalBoundary | null) => value == null || value === OPEN_START_DATE;
const nullOrOpenEnd = (value: IntervalBoundary | null) => value == null || value === OPEN_END_DATE;

/**
 * This class represents an interval between two @see LocalDate instances.
 */
export class CalendarInterval {
  public static of(inclusiveStart?: IntervalBoundary, inclusiveEnd?: IntervalBoundary) {
    return new CalendarInterval(inclusiveStart || null, inclusiveEnd || null);
  }

  public static empty() {
    return new CalendarInterval(null, null);
  }

  public static today(clock?: Clock) {
    return new CalendarInterval(LocalDate.now(clock), LocalDate.now(clock));
  }

  private readonly _startDate: LocalDate | null;

  private readonly _endDate: LocalDate | null;

  private readonly _start: IntervalBoundary | null;

  private readonly _end: IntervalBoundary | null;

  private constructor(start: IntervalBoundary | null, end: IntervalBoundary | null, withStartDate = false) {
    if (isLocalDateTime(start) || isZonedDateTime(start)) {
      this._startDate = start.toLocalDate();
    } else {
      this._startDate = start;
    }

    if (isLocalDateTime(end) || isZonedDateTime(end)) {
      this._endDate = end.toLocalDate();
    } else {
      this._endDate = end;
    }

    this._start = start;
    this._end = end;

    if (this.isNegative() && withStartDate) {
      this._end = this._start;
      this._endDate = this._startDate;
    }
  }

  public start(): IntervalBoundary | null {
    return this._start;
  }

  public end(): IntervalBoundary | null {
    return this._end;
  }

  /**
   * Determines whether the provided @see LocalDate instance is within
   * the calendar interval. If either the start or end of the interval is null
   * `false` will be returned.
   */
  public contains(date: LocalDate) {
    if (this.isZero()) {
      return false;
    }

    if (this._startDate!.isEqual(date) || this._endDate!.isEqual(date)) {
      return true;
    }

    return this._startDate!.isBefore(date) && this._endDate!.isAfter(date);
  }

  public isUnboundedStart() {
    return !nullOrOpenStart(this._start) && nullOrOpenEnd(this._end);
  }

  public isUnboundedEnd() {
    return !nullOrOpenEnd(this._end) && nullOrOpenStart(this._start);
  }

  public isEmpty() {
    return nullOrOpenStart(this._start) && nullOrOpenEnd(this._end);
  }

  public isZero() {
    return nullOrOpenStart(this._start) || nullOrOpenEnd(this._end);
  }

  /**
   * Determines whether the calendar interval creates a negative range.
   * (ie, the start date is after the end date)
   */
  public isNegative() {
    if (this.isZero()) {
      return false;
    }

    return this._startDate!.isAfter(this._endDate!);
  }

  // eslint-disable-next-line complexity
  public equals(other: CalendarInterval) {
    if (this.isEmpty() && other.isEmpty()) {
      return true;
    }

    if (this.isEmpty() || other.isEmpty()) {
      return false;
    }

    if (this.isZero() || other.isZero()) {
      if (nullOrOpenStart(this._startDate) && nullOrOpenStart(other._startDate)) {
        return this._end!.equals(other.end());
      }

      if (nullOrOpenEnd(this._endDate) && nullOrOpenEnd(other._endDate)) {
        return this._start!.equals(other.start());
      }

      return false;
    }

    return this._start!.equals(other.start()) && this._end!.equals(other.end());
  }

  public hashCode() {
    // eslint-disable-next-line no-bitwise
    return (this._start?.hashCode() ?? 0) ^ (this._end?.hashCode() ?? 0);
  }

  /** Determines whether the provided date is before the current start date. */
  public isBefore(date: LocalDate) {
    if (this._startDate == null) {
      return false;
    }

    return this._startDate.isAfter(date);
  }

  /** Creates a new interval with the provided start date. */
  public withStart(inclusiveStart: LocalDate | LocalDateTime | null) {
    return new CalendarInterval(inclusiveStart, this._end, true);
  }

  /** Creates a new interval with the provided end date. */
  public withEnd(inclusiveEnd: LocalDate | LocalDateTime | null) {
    return new CalendarInterval(this._start, inclusiveEnd);
  }

  public toString() {
    return `${String(this._start || "<unassigned>")} - ${String(this._end || "<unassigned>")}`;
  }
}
