import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "NotNode",
  category: NodeTypeCategory.logic,
  controlOutputs: [],
  dataInputs: [{ name: "source", type: DataType.BOOLEAN, hideLabel: true }],
  dataOutputs: [{ name: "result", type: DataType.BOOLEAN }],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return { output: { result: !input.source }, activeControlHandleIds: [] };
  }
});

export const NotNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
