import styles from "./Input/Input.module.scss";
import { InputSize } from "./Input/Input.types";

export const sizeStyle = (size: InputSize) => {
  switch (size) {
    case InputSize.mini:
      return styles.sizeMini;
    case InputSize.tiny:
      return styles.sizeTiny;
    case InputSize.small:
      return styles.sizeSmall;
    case InputSize.medium:
      return styles.sizeMedium;
    case InputSize.large:
      return styles.sizeLarge;
    case InputSize.xlarge:
      return styles.sizeXlarge;
    default:
      return styles.sizeDynamic;
  }
};
