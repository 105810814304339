import { Node, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { DocumentSchemaSource, IDataParam, INodeProperty, INodeTypeDefinition, IoType } from "./Base";
import {
  addObjectListSchema,
  addObjectSchema,
  getDocumentObjectSchemaFromProperties,
  getEmptySchemaId,
  getSchemaId,
  setInputsFromSchema
} from "./DataSchemas";
import { DataType } from "./TypeDefinitions";
import { IBannerContext, BannerStatus } from "@plex/react-components";
import { updateLinkedSchemas } from "../Util/EdgeUtil";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { DocumentSchemaType } from "../FlowDocument/FlowDocumentModel";
import { upperSnakeToCamelCase } from "../FlowDocument/DocumentProcessor";

interface IStandardObject {
  id: string;
  name: string;
  identifiers: IDataParam[];
  data: IDataParam[];
  filters: IDataParam[];
  searchResult: IDataParam[];
}

interface IAddIo {
  node: Node<any>;
  propertyName: string;
  schemaSourceType: DocumentSchemaSource;
  ioType: IoType;
  standardObject: IStandardObject;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const getStandardObjectSchemaIds = (node: Node<any>) => {
  return [
    getSchemaId(DataType.OBJECT, node, ["identifiers"]),
    getSchemaId(DataType.OBJECT, node, ["filters"]),
    getSchemaId(DataType.OBJECT, node, ["data"]),
    getSchemaId(DataType.OBJECT, node, ["searchResult"]),
    getSchemaId(DataType.OBJECTLIST, node, ["searchResult"])
  ];
};

export interface ICreateIoParam {
  node: Node<any>;
  schemaSystemProperty: INodeProperty;
  bannerContext: IBannerContext;
  plexFetchStandardObjectDetail: ((id: string) => Promise<any>) | undefined;
  plexShowOverlay: ((status: boolean) => void) | undefined;
  reactFlowInstance: ReactFlowInstance;
  updateNodeInternals: UpdateNodeInternals;
}

export const createIo = ({
  node,
  schemaSystemProperty,
  bannerContext,
  plexFetchStandardObjectDetail,
  plexShowOverlay,
  reactFlowInstance,
  updateNodeInternals
}: ICreateIoParam) => {
  const id = node.data.nodeProperties[schemaSystemProperty.name];

  if (!id) {
    resetIo(node, reactFlowInstance, updateNodeInternals);
    return;
  }

  if (schemaSystemProperty) {
    plexShowOverlay && plexShowOverlay(true);
    switch (schemaSystemProperty.schemaSourceSystemType) {
      case DocumentSchemaSource.customFieldsV1:
        //TODO: use real data
        const fake = fakeData();
        //plexFetchStandardObjectDetail(id)
        fake
          .plexFetchStandardObjectDetail(id)
          .then((res) => {
            const result: any = JSON.parse(res.Data.StandardObject);
            const toDataParam = (soParam: { key: string; primitiveType: string; friendlyName: string }): IDataParam => {
              return {
                name: soParam.key,
                type: upperSnakeToCamelCase(soParam.primitiveType) as DataType,
                schemaId: upperSnakeToCamelCase(soParam.primitiveType),
                label: soParam.friendlyName
              };
            };
            const standardObject: IStandardObject = {
              id: result.id,
              name: result.name,
              identifiers: result.identifiers
                .filter((param: any) => param.primitiveType)
                .map((param: any) => toDataParam(param)),
              data: result.data.filter((param: any) => param.primitiveType).map((param: any) => toDataParam(param)),
              filters: result.filters
                .filter((param: any) => param.primitiveType)
                .map((param: any) => toDataParam(param)),
              searchResult: result.identifiers
                .concat(result.data)
                .filter((param: any) => param.primitiveType)
                .map((param: any) => toDataParam(param))
            };
            if (schemaSystemProperty.schemaSystem?.ioType !== undefined) {
              addCustomFieldsIoV1({
                node,
                propertyName: schemaSystemProperty.name,
                schemaSourceType: schemaSystemProperty.schemaSourceSystemType!,
                ioType: schemaSystemProperty.schemaSystem.ioType,
                standardObject: standardObject,
                reactFlowInstance,
                updateNodeInternals
              });
            }

            plexShowOverlay && plexShowOverlay(false);
          })
          .catch((e) => {
            bannerContext.addMessage("An error occurred retrieving data source metadata.", BannerStatus.error);
            console.log(e);

            plexShowOverlay && plexShowOverlay(false);
          });
    }
  }
};

export const resetIo = (
  node: Node<any>,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

  node!.data.nodeProperties.inputs = {};

  nodeDefinition.dataOutputs.forEach(
    (dataOutput: IDataParam) =>
      (node!.data.nodeProperties.outputs[dataOutput.name].schemaId = getEmptySchemaId(
        node!.data.nodeProperties.outputs[dataOutput.name].type
      ))
  );

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

const addCustomFieldsIoV1 = ({
  node,
  propertyName,
  ioType,
  standardObject,
  reactFlowInstance,
  updateNodeInternals
}: IAddIo) => {
  resetIo(node, reactFlowInstance, updateNodeInternals);

  if (node.data.nodeProperties[propertyName]) {
    const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;

    let outputName: string | undefined;
    const standardObjectProperty: INodeProperty = nodeDefinition.nodeConfigProperties.filter(
      (p: INodeProperty) => p.name === propertyName
    )[0]!;
    const schemaSourceSystem: string | undefined = standardObjectProperty.schemaSourceSystemType;
    const schemaSourceId: string = node.data.nodeProperties.standardObjectName;

    const identifiersSchemaId = getSchemaId(DataType.OBJECT, node, ["identifiers"]);
    const filtersSchemaId = getSchemaId(DataType.OBJECT, node, ["filters"]);
    const dataSchemaId = getSchemaId(DataType.OBJECT, node, ["data"]);
    const searchResultIdPart = "searchResult";
    const searchResultSchemaId = getSchemaId(DataType.OBJECT, node, [searchResultIdPart]);
    const searchResultListSchemaId = getSchemaId(DataType.OBJECTLIST, node, [searchResultIdPart]);

    const identifiersSchema = getDocumentObjectSchemaFromProperties(identifiersSchemaId, standardObject.identifiers);
    const filtersSchema = getDocumentObjectSchemaFromProperties(filtersSchemaId, standardObject.filters);
    const dataSchema = getDocumentObjectSchemaFromProperties(dataSchemaId, standardObject.data);
    const searchResultSchema = getDocumentObjectSchemaFromProperties(searchResultSchemaId, standardObject.searchResult);

    const searchResultSchemaList = {
      id: searchResultListSchemaId,
      schemaType: DocumentSchemaType.list,
      listItemSchema: searchResultSchemaId
    };

    switch (ioType) {
      case IoType.get:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        setInputsFromSchema(node, identifiersSchema, true);
        addObjectSchema(dataSchema, schemaSourceSystem!, schemaSourceId);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = dataSchemaId;
        }
        break;
      case IoType.search:
        addObjectSchema(filtersSchema, schemaSourceSystem!, schemaSourceId);
        setInputsFromSchema(node, filtersSchema);
        addObjectSchema(searchResultSchema, schemaSourceSystem!, schemaSourceId);
        addObjectListSchema(searchResultSchemaList, schemaSourceSystem!, schemaSourceId);
        outputName = nodeDefinition.dataOutputs[0]?.name;
        if (outputName) {
          node.data.nodeProperties.outputs[outputName].schemaId = searchResultListSchemaId;
        }
        break;
      case IoType.update:
        addObjectSchema(identifiersSchema, schemaSourceSystem!, schemaSourceId);
        setInputsFromSchema(node, identifiersSchema, true);
        addObjectSchema(dataSchema, schemaSourceSystem!, schemaSourceId);
        setInputsFromSchema(node, dataSchema);
        break;
    }
  }

  updateLinkedSchemas(
    reactFlowInstance.getNodes(),
    reactFlowInstance.getEdges(),
    updateNodeInternals,
    reactFlowInstance
  );
  setTimeout(() => forceNodeUpdate(node, updateNodeInternals, reactFlowInstance), 80);
};

export const fakeData = () => {
  const fake: any = {};
  //TODO: Remove, for testing.
  fake.plexFetchStandardObjects = () =>
    Promise.resolve({
      Data: [
        { name: "Part", id: "123456" },
        { name: "Container", id: "7654" }
      ]
    });
  fake.plexFetchStandardObjectDetail = (id) =>
    Promise.resolve({
      Data: {
        StandardObject: JSON.stringify(
          id === "123456"
            ? {
                name: "Part",
                identifiers: [{ key: "Part_Key", primitiveType: "INTEGER", friendlyName: "Part Key" }],
                filters: [{ key: "Color", primitiveType: "STRING", friendlyName: "Color" }],
                data: [
                  { key: "Color", primitiveType: "STRING", friendlyName: "Color" },
                  { key: "Shape", primitiveType: "STRING", friendlyName: "Shape" }
                ]
              }
            : {
                name: "Container",
                identifiers: [{ key: "Container_Key", primitiveType: "INTEGER", friendlyName: "Container Key" }],
                filters: [
                  { key: "Volume_Feet_Squared_Min", primitiveType: "DECIMAL", friendlyName: "Volume Min (Ft^2)" },
                  { key: "Volume_Feet_Squared_Max", primitiveType: "DECIMAL", friendlyName: "Volume Max (Ft^2)" }
                ],
                data: [
                  { key: "Volume_Feet_Squared", primitiveType: "DECIMAL", friendlyName: "Volume (Ft^2)" },
                  { key: "Weight_Limit_Pounds", primitiveType: "DECIMAL", label: "Weight Limit (lbs)" },
                  { key: "Category", primitiveType: "STRING", friendlyName: "Category" },
                  { key: "Material", primitiveType: "STRING", friendlyName: "Material" }
                ]
              }
        )
      }
    });

  return fake;
};
