import React, { FunctionComponent } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { BasicNodeLayout, NodeTypeCategory } from "../Base";
import { DataType } from "../TypeDefinitions";
import { EdgeDetail } from "../../Util/EdgeUtil";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "StringConcatenateNode",
  label: "Concatenate",
  category: NodeTypeCategory.text,
  controlOutputs: [],
  dataInputs: [
    { name: "left", type: DataType.STRING, hideLabel: true },
    { name: "right", type: DataType.STRING, hideLabel: true }
  ],
  dataOutputs: [{ name: "result", type: DataType.STRING }],
  evaluate: (input: any) => {
    return {
      output: { result: (input.left as string) + (input.right as string) },
      activeControlHandleIds: []
    };
  }
});

export const StringConcatenateNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  const reactFlowInstance = useReactFlow();
  const edgeDetail = new EdgeDetail(id, reactFlowInstance);
  const label = `<${edgeDetail.getInputTargetLabel("left")}><${edgeDetail.getInputTargetLabel("right")}>`;

  return (
    <BasicNodeLayout id={id} data={data}>
      <div className="plex-fd-expression-label">{label}</div>
    </BasicNodeLayout>
  );
};
