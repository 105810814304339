import React from "react";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { chainHandlers } from "@utils";
import { Checkbox, ICheckboxProps } from "../../Checkbox";
import * as globalStyles from "../../../global-styles";
import { FormikField } from "./Formik.types";

export const FormikCheckbox: FormikField<ICheckboxProps> = ({ name, className, onChange, onBlur, ...other }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.errors?.[name];
        return (
          <Checkbox
            name={field.name}
            className={clsx(className, hasError && globalStyles.hasErrors)}
            checked={field.value}
            onChange={chainHandlers(field.onChange, onChange)}
            onBlur={chainHandlers(field.onBlur, onBlur)}
            {...other}
          />
        );
      }}
    </Field>
  );
};
