import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { ICommonProps } from "../Common.types";
import styles from "./Aside.module.scss";

export const Aside: FunctionComponent<ICommonProps> = ({ id, className, children, ...props }) => {
  const classes = clsx(styles.base, className);

  return (
    <aside data-testid="plex-aside" id={id} className={classes} {...props}>
      {children}
    </aside>
  );
};
