export type Matcher = {
  patterns: { first: RegExp; all: RegExp };
  terms: string[];
  expected: boolean;
};

export type Match = {
  start: number;
  end: number;
};

// note: this is mostly based on google's search operators.
// see: https://support.google.com/websearch/answer/136861?hl=en
const wildcardRgx = /\s[*%]\s/g;
const insideWildcardRgx = /(^|[^\s\\])[*%]+/g;
const repeatedWildcardsRgx = /([*%])\1/g;
const specialCharsOnly = /^[%*$^]$/;

// advanced mode
const termsRgx = /(\s?-+|\s|^)(?:"((?:\\.|[^"])+?)"|(\S+))/g;
const negativeRgx = /^\s?-+$/;
const orRgx = /^or$/i;
const andRgx = /^and$/i;

// we don't want to escape every special character - some are allowed
const escapeRgx = /[[\]{}.\\?+()|]/g;

const createRegExp = (term: string) => {
  return {
    first: new RegExp(`(${term})`, "i"),
    all: new RegExp(`(${term})`, "gi")
  };
};

const sanitize = (term: string) => {
  return term
    .replace(escapeRgx, "\\$&")
    .replace(specialCharsOnly, "\\$&")
    .replace(repeatedWildcardsRgx, "\\$&")
    .replace(wildcardRgx, " .* ")
    .replace(insideWildcardRgx, "$1\\S*");
};

const createMatcher = (term: string, expected: boolean): Matcher => {
  const sanitizedTerm = sanitize(term);
  return {
    patterns: createRegExp(sanitizedTerm),
    terms: [sanitizedTerm],
    expected
  };
};

// eslint-disable-next-line func-style
function appendToMatcher(matcher: Matcher, term: string) {
  matcher.terms.push(sanitize(term));
  const rgxText = `(${matcher.terms.join("|")})`;

  // eslint-disable-next-line no-param-reassign
  matcher.patterns = createRegExp(rgxText);
}

export const createAdvanced = (query: string): Matcher[] => {
  const matchers = [];

  termsRgx.lastIndex = 0;
  let term: RegExpExecArray | null;
  while ((term = termsRgx.exec(query))) {
    const current = term[2] || term[3];
    const expected = !negativeRgx.test(term[1]);

    // Right now if there is a trailing AND or OR, they will be ignored -
    // we *could* treat them like search terms
    if (andRgx.test(current)) {
      // ignore `and`s - `and` is the default behavior
      continue;
    } else if (orRgx.test(current) && matchers.length > 0 && (term = termsRgx.exec(query))) {
      appendToMatcher(matchers[matchers.length - 1], term[2] || term[3]);
    } else {
      matchers.push(createMatcher(current, expected));
    }
  }

  return matchers;
};

export const createSimple = (query: string) => [createMatcher(query, true)];
