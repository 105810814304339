import React, { FunctionComponent, MouseEventHandler } from "react";
import { CloseIcon } from "@plex/icons";
import clsx from "clsx";
import { ICommonProps } from "@components/Common.types";
import styles from "./Chip.module.scss";

type Alignment = "horizontal" | "vertical";

export interface IChipProps extends ICommonProps {
  /** When true a close icon will be displayed for Chip */
  closable?: boolean;
  /** Callback to execute when a close has been requests */
  onClose?: MouseEventHandler;
  /** The title to use for the Chip */
  title?: string;
  /** Callback executed when a Chip is clicked */
  onClick?: MouseEventHandler;
  /**
   * Indicates whether the multiple chips should be aligned
   * horizontally or vertically.
   */
  align?: Alignment;
}

export const Chip: FunctionComponent<IChipProps> = ({
  className,
  closable,
  onClose,
  title,
  align = "vertical",
  onClick,
  children,
  ...other
}) => {
  const classes = clsx(
    styles.base,
    align === "vertical" ? styles.alignVertical : styles.alignHorizontal,
    className,
    onClick && styles.consolidated
  );

  const titleOrText = title == null && typeof children === "string" ? children : title;

  return (
    <div data-testid="plex-picker-item" title={titleOrText} className={classes} onClick={onClick} {...other}>
      {closable && (
        <i className={styles.close} onClick={onClose} tabIndex={-1} data-testid="plex-picker-item-remove">
          <CloseIcon />
        </i>
      )}
      <span className={styles.text} data-testid="plex-picker-item-text">
        {children}
      </span>
    </div>
  );
};
