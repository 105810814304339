import React, { FunctionComponent, useState, DragEventHandler, useRef } from "react";
import clsx from "clsx";
import { CloudUploadIcon } from "@plex/icons";
import { IWithChildren } from "@components/Common.types";
import styles from "./FilePicker.module.scss";

export interface IFilePickerDragDropAreaProps extends IWithChildren {
  text: string;
  onFileUpload: (fileList: File[]) => void;
  onClick: () => void;
  multiple: boolean;
}

export const FilePickerDragDropArea: FunctionComponent<IFilePickerDragDropAreaProps> = ({
  text,
  onFileUpload,
  onClick,
  children,
  multiple
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const elementCount = useRef<number>(0);

  const getDropItemsIsValid = (e: React.DragEvent<HTMLDivElement>): boolean => {
    return multiple || e.dataTransfer.items.length === 1;
  };

  const setDropEffect = (e: React.DragEvent<HTMLDivElement>) => {
    if (getDropItemsIsValid(e) === false) {
      e.dataTransfer.dropEffect = "none";
    }
  };

  const dragEnterHandler: DragEventHandler<HTMLDivElement> = e => {
    if (elementCount.current === 0 && getDropItemsIsValid(e)) {
      setIsDragging(true);
    }

    setDropEffect(e);
    elementCount.current++;
    e.preventDefault();
  };

  const dragLeaveHandler: DragEventHandler<HTMLDivElement> = e => {
    elementCount.current--;
    if (elementCount.current === 0) {
      setIsDragging(false);
    }

    e.preventDefault();
  };

  const dragOverHandler: DragEventHandler<HTMLDivElement> = e => {
    setDropEffect(e);
    e.preventDefault();
  };

  const dropHandler: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    setIsDragging(false);
    elementCount.current = 0;
    const uploadedFiles: File[] = e.dataTransfer.files == null ? [] : Array.from(e.dataTransfer.files);
    if (multiple || uploadedFiles.length === 1) {
      onFileUpload(uploadedFiles);
    }
  };

  return (
    <div
      data-testid="file-picker-drag-drop-area"
      className={clsx(
        styles.dragDropSection,
        isDragging ? styles.dragDropSectionDragOver : styles.dragDropSectionNormal
      )}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
      onClick={onClick}
    >
      <div className={clsx(styles.dragDropTextSection, styles.dragDropTextSectionInputHeight)}>
        <span>
          <span className={styles.dragDropTextItem}>
            <CloudUploadIcon width="20px" height="20px" style={{ marginTop: "3px", marginRight: "4px" }} />
          </span>
          <span className={styles.dragDropTextItem}>{text}</span>
        </span>
      </div>
      <div>{children}</div>
    </div>
  );
};
