import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, DocumentSchemaSource, IoType, NodeSize, NodeTypeCategory } from "../Base";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";
import { DataType } from "../TypeDefinitions";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "CallCustomFieldsGetNode",
  label: "Get Custom Fields",
  category: NodeTypeCategory.services,
  size: NodeSize.Small,
  controlInputs: [{ name: "call" }],
  controlOutputs: [{ name: "completed", label: "" }],
  dataInputs: [],
  documentDataInputListName: "identifiers",
  dataOutputs: [{ name: "fields", type: DataType.OBJECT }],
  nodeConfigProperties: [
    {
      name: "standardObjectId",
      label: "Standard Object",
      propertyType: NodeConfigPropertyType.StandardObject,
      schemaSourceSystemType: DocumentSchemaSource.customFieldsV1,
      schemaSystem: { createIo: true, ioType: IoType.get },
      options: { customFieldsOnly: true }
    },
    {
      name: "standardObjectName",
      label: "Standard Object Name",
      propertyType: NodeConfigPropertyType.NoInput,
      schemaSourceSystemType: DocumentSchemaSource.customFieldsV1
    },
    {
      name: "identifiers",
      sectionName: "Identifiers",
      propertyType: NodeConfigPropertyType.EnabledInputs
    }
  ],
  skipEvaluate: true,
  evaluate: () => {
    return { output: {}, activeControlHandleIds: [] };
  }
});

export const CallCustomFieldsGetNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  let label = "Get Custom Fields";
  if (data.nodeProperties.standardObjectName) {
    label = `Get ${data.nodeProperties.standardObjectName} Custom Fields`;
    data.nodeProperties.label = label;
  }
  return <BasicNodeLayout id={id} data={data} label={label} />;
};
