/* eslint-disable func-style */
import React, { ReactElement, createContext, useRef, ReactNode, PropsWithChildren, createElement } from "react";
import { cloneElementWithRef, isPromise } from "@utils";
import {
  Dispatcher,
  StateContextValue,
  Selector,
  createStore,
  combineReducers,
  Reducer,
  StoreContextType
} from "../StoreProvider";
import {
  GridAction,
  GridState,
  IDataTableProps,
  GridRecord,
  DataTablePlugin,
  InitDataTable,
  DataTablePluginFactory
} from "./DataTable.types";

const INIT_TABLE: InitDataTable = { type: "DataTable/init" };

type StoreConsumerProps<T> = Pick<IDataTableProps<T>, "data" | "keySelector"> & { plugins: Array<DataTablePlugin<T>> };

const DataTableStoreContext = (createContext(null) as unknown) as StoreContextType<
  GridState<unknown>,
  GridAction<unknown>
>;

DataTableStoreContext.displayName = "DataTableStoreContext";
const { Provider, useDispatch, useStore, useSelector } = createStore(DataTableStoreContext);

/**
 * Hook to retrieve the data table dispatcher function. Typically used by DataTable Plugin
 * implementations.
 */
export function useDataTableDispatcher<T>() {
  return useDispatch() as Dispatcher<GridAction<T>>;
}

/**
 * Hook to retrieve the data table store. Typically used by DataTable Plugin implementations.
 */
export function useDataTableStore<T>() {
  return useStore() as StateContextValue<GridState<T>>;
}

/**
 * Hook to retrieve a particular part of the data table state. Typically used by DataTable Plugin
 * implementations.
 */
export function useDataTableSelector<S, T>(selector: Selector<GridState<S>, T>, equals?: (a: T, b: T) => boolean): T {
  return useSelector<T>(selector as Selector<unknown, T>, equals);
}

function createInitialState<T>({ data: dataOrPromise = [], keySelector }: Partial<IDataTableProps<T>>): GridState<T> {
  const initialData = Array.isArray(dataOrPromise) ? dataOrPromise : [];
  const loading = isPromise(dataOrPromise);
  const data: Array<GridRecord<T>> = initialData.map((value, index) => {
    const key = keySelector!(value);
    return {
      key,
      value,
      index
    };
  });

  return {
    data,
    loading
  };
}

export const DataTableStateProvider = function DataTableStateProvider<T>(props: PropsWithChildren<IDataTableProps<T>>) {
  const { plugins = [], keySelector, data, children } = props;
  const initialStateRef = useRef<GridState<T>>();

  const pluginInstances = plugins.filter(Boolean).map(plugin => (plugin as DataTablePluginFactory<T>)(props));

  const combinedReducer = combineReducers(
    ...(pluginInstances.map(plugin => plugin.reducer).filter(Boolean) as Array<Reducer<GridState<T>, GridAction<T>>>)
  );

  if (initialStateRef.current == null) {
    initialStateRef.current = combinedReducer(createInitialState<T>(props), INIT_TABLE);
  }

  return (
    <Provider
      reducer={combinedReducer as Reducer<GridState<unknown>, GridAction<unknown>>}
      initialState={initialStateRef.current}
    >
      <StoreConsumer<T> data={data} plugins={pluginInstances} keySelector={keySelector}>
        {children}
      </StoreConsumer>
    </Provider>
  );
};

function StoreConsumer<T>({ plugins, keySelector, data, children }: PropsWithChildren<StoreConsumerProps<T>>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const table = cloneElementWithRef(children, { ref: tableRef }) as ReactNode;

  // rendering these in a separate render context so that plugins can access the store and dispatch actions
  return plugins.reduce((child, plugin) => {
    return plugin.component
      ? createElement(plugin.component, { ...(plugin.args || {}), keySelector, data, tableRef }, child)
      : child;
  }, table) as ReactElement;
}
