import { GridRecord, GridState, ActionPayload, LoadAction, LoadingAction } from "../DataTable.types";

// actions
export const load = <T>(action: ActionPayload<LoadAction<T>>): LoadAction<T> => ({
  type: "DataTable/load",
  ...action
});

export const loading = (): LoadingAction => ({ type: "DataTable/loading" });

// reducers
export const handleLoad = <T>(state: GridState<T>, action: LoadAction<T>) => {
  const { data: unsorted, keySelector } = action;

  const records: Array<GridRecord<T>> = unsorted.map((value, index) => {
    const key = keySelector(value);
    return {
      key,
      value,
      index
    };
  });

  return {
    ...state,
    loading: false,
    data: records
  };
};

export const handleLoading = <T>(state: GridState<T>, _action: LoadingAction) => {
  return { ...state, loading: true };
};
