import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { ICommonProps } from "../Common.types";
import styles from "./Form.module.scss";

export const FormTitle: FunctionComponent<ICommonProps> = ({ children, className, ...other }) => {
  return (
    <div data-testid="plex-form-title" className={clsx(styles.formTitle, className)} {...other}>
      {children}
    </div>
  );
};
