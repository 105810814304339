import React, { FC } from "react";
import { withNoPrinting } from "@plex/react-xml-renderer";
import { useFixedColumnSizes } from "./Plugins/DataTableFixedLayout.hooks";

const HtmlDataTableColGroup: FC = () => {
  const sizes = useFixedColumnSizes();
  if (sizes.length === 0) {
    return null;
  }

  return (
    <colgroup>
      {sizes.map((width, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <col key={i} style={{ width }} />
      ))}
    </colgroup>
  );
};

export const DataTableColGroup = withNoPrinting(HtmlDataTableColGroup);
