import React, { FunctionComponent } from "react";
import { Dropdown } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";

export const ItemPositionFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const itemIndexOptions: any = [
    { key: 0, value: "First" },
    { key: -1, value: "Last" }
  ];
  const [_selectedOption, setSelectedOption] = React.useState<any>(itemIndexOptions[0]);

  return (
    <Dropdown
      key="First Last Dropdown"
      items={itemIndexOptions}
      keySelector={(item: { key: number; value: string }) => item.key}
      displaySelector={(item: { key: number; value: string }) => item.value}
      selected={[
        {
          key: props.node!.data.nodeProperties[props.name],
          value: itemIndexOptions.filter(
            (option: { key: number; value: string }) => option.key === props.node!.data.nodeProperties[props.name]
          )[0]?.value
        }
      ]}
      onSelectionChanged={(data) => {
        props.node!.data.nodeProperties[props.name] = data[0].key;
        setSelectedOption(data);
        props.forceNodeUpdate();
      }}
    />
  );
};
