import React, { FunctionComponent, useMemo } from "react";
import { LocalDate, LocalDateTime, LocalTime } from "js-joda";
import { DateRangeSelector } from "./DateRangeSelector";
import { OPEN_START_DATE, OPEN_END_DATE } from "./CalendarIntervals";
import { IDateRangePickerProps, ITimeProps } from "./DatePicker.types";
import styles from "./DatePicker.module.scss";
import { DateTimePicker } from "./DateTimePicker";
import { getTimePart, getDatePart } from "./date-utils";
import { CalendarInterval } from "./CalendarInterval";

export interface IDateRangePickerContentProps extends IDateRangePickerProps, ITimeProps {
  // internal props
  showing?: boolean;
  showTime?: boolean;
}

export const DateRangePicker: FunctionComponent<IDateRangePickerContentProps> = ({
  // date props
  weekStart,
  // time props
  precision,
  use24HourTime,
  minuteStep,
  // range props
  range,
  onRangeChange,
  rangeFilters,
  // internal props
  showing = true,
  showTime = true
}) => {
  const currentRange = range || CalendarInterval.empty();
  const startTime = useMemo(() => getTimePart(currentRange.start()), [currentRange]);
  const startDate = useMemo(() => getDatePart(currentRange.start()), [currentRange]);
  const endTime = useMemo(() => getTimePart(currentRange.end()), [currentRange]);
  const endDate = useMemo(() => getDatePart(currentRange.end()), [currentRange]);

  const handleDateChange = (time: LocalTime | null, date: LocalDate | null) => {
    if (!date) {
      return null;
    }

    if (time) {
      return LocalDateTime.of(date, time);
    }

    return date;
  };

  const handleTimeChange = (date: LocalDate | null, time: LocalTime | null) => {
    if (!time) {
      return null;
    }

    if (date) {
      return LocalDateTime.of(date, time);
    }

    // We currently are not supporting "TimeRange"...
    return null;
  };

  return (
    <div className={styles.rangeContainer}>
      <DateRangeSelector range={currentRange} onRangeChange={onRangeChange} rangeFilters={rangeFilters} />
      <div className={styles.rangeCalendars}>
        <DateTimePicker
          range={currentRange}
          value={currentRange.start()}
          onDateChange={dt => onRangeChange(currentRange.withStart(handleDateChange(startTime, dt)))}
          onTimeChange={tm => onRangeChange(currentRange.withStart(handleTimeChange(startDate, tm)))}
          weekStart={weekStart}
          disabled={currentRange.start() === OPEN_START_DATE}
          precision={precision}
          use24HourTime={use24HourTime}
          minuteStep={minuteStep}
          showing={showing}
          showDate
          showTime={showTime}
        />
        <DateTimePicker
          range={currentRange}
          value={currentRange.end()}
          onDateChange={dt => onRangeChange(currentRange.withEnd(handleDateChange(endTime, dt)))}
          onTimeChange={tm => onRangeChange(currentRange.withEnd(handleTimeChange(endDate, tm)))}
          validateDate={dt => !currentRange.isBefore(dt)}
          weekStart={weekStart}
          disabled={currentRange.end() === OPEN_END_DATE}
          precision={precision}
          use24HourTime={use24HourTime}
          minuteStep={minuteStep}
          showing={showing}
          showDate
          showTime={showTime}
        />
      </div>
    </div>
  );
};
