import { useCallback } from "react";
import { noop } from "@utils";
import { SelectionMode, SelectionStatus, GridRecord, GridState } from "../DataTable.types";
import { useDataTableDispatcher, useDataTableSelector } from "../DataTableStateProvider";
import { GridStateWithSelections, toggleSelection, toggleSelectAll } from "./DataSelection.actions";

/**
 * Hook which provides selection context and selection method for
 * selecting a single row.
 */
export const useRowSelection = <T>(row: GridRecord<T>): [boolean, () => void] => {
  const dispatch = useDataTableDispatcher<T>();
  const selector = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    state => !!(state as GridStateWithSelections<T>).selections?.selectedKeys.includes(row.key),
    [row.key]
  );
  const selected = useDataTableSelector(selector);
  const toggler = useCallback(() => dispatch(toggleSelection({ row })), [dispatch, row]);
  return [selected, toggler];
};

const SELECTIONS_SELECTOR = <S>(s: GridState<S>) => (s as GridStateWithSelections<S>).selections;

/**
 * Hook which provides selection context and selection method to toggle
 * all selections.
 */
export const useGridSelection = <T>(): [SelectionStatus, () => void] => {
  const dispatch = useDataTableDispatcher<T>();
  const state = useDataTableSelector(SELECTIONS_SELECTOR);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!state || state.mode !== SelectionMode.multiple) {
    return ["none", noop];
  }

  const toggler = () => dispatch(toggleSelectAll({}));
  return [state.status, toggler];
};
