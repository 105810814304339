import React, { Ref, ReactChildren } from "react";
import clsx from "clsx";
import * as styles from "./Dialog.module.scss";

interface IDialogFooterProps {
  className?: string;
  ref?: Ref<HTMLHeadingElement>;
  children?: ReactChildren | string;
}

export const DialogTitle = React.forwardRef<HTMLHeadingElement, IDialogFooterProps>(
  ({ children, className, ...other }, ref) => {
    const modalTitle = clsx(styles.modalTitle, className);
    return (
      <h3 data-testid="plex-dialog-title" ref={ref} {...other} className={modalTitle}>
        {children}
      </h3>
    );
  }
);

DialogTitle.displayName = "DialogTitle";
